import { Injectable } from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, filter, catchError, debounceTime, tap, finalize, takeUntil, take } from 'rxjs/operators';
import {
    HttpErrorResponse,
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { LoaderService } from './loader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private loaderService: LoaderService, private auth: AuthService, private router: Router) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);

        }
        // console.log(i, this.requests.length);
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        this.loaderService.isLoading.next(true);

        return new Observable(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            if (event.status === 401) {
                                if (!this.auth.isAuthenticated) {
                                    console.log('problem with request: ', req);

                                    console.log('|||=loader.interceptor -||| 401 - redirection to /');
                                    sessionStorage.removeItem('redirect_url');
                                    this.router.navigateByUrl('/');
                                }
                            }
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    error => {
                        this.removeRequest(req);
                        observer.error(error);
                        this.loaderService.isLoading.next(false); // Stop loader on error
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                        if (this.requests.length === 0) {
                            this.loaderService.isLoading.next(false); // Stop loader if no pending requests
                        }
                    }
                );

            // Teardown logic
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }

    //original implementation changed as on 03-2024
    // _intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     this.requests.push(req);
    //     this.loaderService.isLoading.next(true);

    //     return Observable.create(observer => {
    //         const subscription = next.handle(req)
    //             .subscribe(
    //                 event => {
    //                     if (event instanceof HttpResponse) {

    //                         //intercept http request and expose contents in event variable. handle events here. can use window.location.reload(true); where refresh needed during downtime.
    //                         // console.log("INTERRCEPT HTTP EVENT:", event);

    //                         if (event.status === 401) {
    //                             console.log('|||=loader.interceptor -||| event.status = 401.:');
    //                             //handle unathuroized event
    //                             if (!this.auth.isAuthenticated) {
    //                                 console.log('|||=loader.interceptor -||| logout and redirect====:');
    //                                 sessionStorage.removeItem('redirect_url');
    //                                 this.router.navigateByUrl('/');
    //                             }
    //                         }

    //                         this.removeRequest(req);
    //                         observer.next(event);
    //                     }
    //                 },
    //                 err => {
    //                     this.removeRequest(req); observer.error(err); },
    //                 () => { this.removeRequest(req); observer.complete(); });
    //         // teardown logic in case of cancelled requests
    //         return () => {
    //             this.removeRequest(req);
    //             subscription.unsubscribe();
    //         };
    //     });
    // }

    //first simple implementation
    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     let authReq = req;

    //     // const token = this.tokenService.getToken();
    //     // if (token != null) {
    //     //     authReq = this.addTokenHeader(req, token);
    //     // }

    //     return next.handle(authReq).pipe(catchError(error => {
    //         if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/signin') && error.status === 401) {
    //             return this.handle401Error(authReq, next);
    //         }

    //         return throwError(error);
    //     }));
    // }


}
