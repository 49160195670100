import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, FormControl, Validators } from '@angular/forms';

// for help : https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/#_custom_model_form_validator
@Directive({
  selector: '[validId]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: Auth0Validator, multi: true }
  ]
})
export class Auth0Validator implements Validator {

  validate(id: FormControl): ValidationErrors | null {
    return Auth0Validator.validateId(id);
  }
  

  static validateId(control: FormControl): ValidationErrors | null {

      if(control.value){
        console.log("validate ID", control.value);
        if(control.value.length != 'auth0|xxxxxxxxxxxxxxxxxxxxxxxx'.length || !control.value.startsWith('auth0')){
          console.log("return error");
            return {type:"Not a valid auth0 ID"}
        }

      }
      return null;
    // If no error, return null  

  }
}
