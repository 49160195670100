<div class="pip-modal-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <button class="pip-modal-close_btn"  mat-mini-fab color="primary" (click)="closeModal()"aria-label="close modal"> 
    <mat-icon svgIcon="clear"></mat-icon>
  </button> 
  <div class="pip-modal-header">
    <!-- <span class="pip-modal-close" (click)="closeModal()">X</span> -->
    <!-- <span class="pip-modal-title">Payment Terms</span> -->
    <!-- <span class="pip-modal-subtitle">This is a subtitle</span> -->
  </div>
  <div id="pipmodalbody" class="pip-modal-body pip-scroll-shadow" (scroll)="scrollEvent($event)">
    <div *ngIf="firstPayment">
      <h3>
        Payments
      </h3>
      <p>
        We will take your first payment today and set up recurring payments based on your choice of licences, contracts
        and packages. If required, subsequent monthly licence payments and charges for additional packages will be
        collected on your billing date.
      </p>
    </div>

    <div>

      <h3>
        Upgrades
      </h3>
      <p>
        Some licences and contracts can be upgraded at any time. See available upgrades below:
      </p>
      <ul>
        <ul>
          <li>
            1 Month (rolling) contracts can be upgraded to 12 Month contracts.
          </li>
          <li>
            PiPmobile users with Talk & Text licence numbers can be switched to Talk licence numbers but the Talk & Text licence costs remain for the duration of the contract period. <a href="./pricing" target="_blank">See more.</a>
          </li>
        </ul>
      </ul>
      <p>
        Cost differences will be reflected on your current invoice, which can be reviewed in the Billing section of the portal.  Payment for this invoice will be collected overnight.
      </p>
    </div>

  </div>
  <div class="pip-modal-footer">
    <!-- <button mat-flat-button color="primary" (click)="closeModal()">Close</button>  -->
  </div>
</div>
