//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil , first} from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { UserProfile} from '@app/models/user-profile.model';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { DdiNumber } from '@app/models/ddi.model';
import { DdiUpdate } from '@app/models/ddi.model';
//bottom sheet
import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UserProfileBottomSheetComponent} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.component';
import { DDIBottomSheetComponent } from '@app/components/bottom-sheet-modal/ddi-bsheet-modal/ddi-bsheet-modal.component';

import { InvitationService } from '@app/services/pipcall/invitation.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import { InviteUserModalComponentpipxt } from '@app/components/modals/invite-user-modal-pipxt/invite-user-modal.component'
import { NumberService} from '@app/services/pipcall/number.service';
import { CreateSipModalComponent } from '@app/components/modals/create-sip-modal/create-sip-modal.component';


@Component({
    selector: 'app-org-tabs-extensions',
    templateUrl: './org-pipoffice-extensions.component.html',
    styleUrls: ['./org-pipoffice-extensions.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OrgTabExtensionssComponents {

    @Input() viewAs: string; //REQUIRED
    @Input() organisation_id: string; //REQUIRED

    @Input() organisationProfile: OrganisationModel //optional
    @Input() orgDdiList: Array<DdiNumber>; //optional

    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }
    private breakpointSubscription: Subscription;
    dataSource: MatTableDataSource<DdiNumber>;
    displayCols: string[]; //set in ngOnInit
    displayedColumns: string[] = [ 'status',  'sip_extension_number',  'sip_extension_cli', 'sip_host_name', 'user_full_name',  'settings'];
    displayedColumnsMobile: string[] = [ 'status',  'sip_extension_number',  'user_full_name', 'settings'];
    displayedColumnsSySAdmin: string[] = [ 'status',  'sip_extension_number', 'sip_user_name', 'sip_extension_cli', 'sip_host_name', 'user_full_name', 'is_reserved',  'settings'];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public numberListSubscription: Subscription;

    //forms
    userAdminForm: FormGroup;

    public availableUserList:  UserProfile[]; //Filtered to users who have no existing number

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private bottomSheet: MatBottomSheet,
        private organisationService: OrganisationService,
        private ref: ChangeDetectorRef,
        private invitationService: InvitationService,
        private numberService: NumberService,
        private modalService: ModalService,
        private breakpointObserver: BreakpointObserver
    ) {


        this.dataSource = new MatTableDataSource();
        this.availableUserList = new Array<UserProfile>();
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("[extensions tab].ngOnChanges()", changes);
        // (changes['orgDdiList']?.currentValue !== changes['orgDdiList']?.previousValue) && !changes['orgDdiList']?.isFirstChange() ?  this.setDataSource(this.orgDdiList) : null;
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue && !changes['organisationProfile']?.isFirstChange() ?  this.getDetails() : this.ref.detectChanges(); // needs review.
    }



    ngOnInit() {
        this.pageStatus.isLoading = true;

        if ( this.viewAs === 'sysAdmin') {
            this.displayCols = [...this.displayedColumnsSySAdmin]
        } else { this.displayCols = [...this.displayedColumns] }

        // ! this.orgDdiList  ? this.orgDdiList = new Array<DdiNumber>() : null;
        console.log("[extensions tab].ngOnInit() orgDdiList", this.orgDdiList);

        this.dataSource.data = [];

        if ( !this.orgDdiList || this.orgDdiList === undefined || this.orgDdiList === null) {
            console.log("[extensions tab] no ddlist found");
            this.getDetails();
        } else {

            if ( !this.organisationProfile ) {
                console.log("[extensions tab] no organisationProfile found");
                this.getDetails();
            } else {
                this.setDataSource(this.orgDdiList);
            }
        }

        this.ref.detectChanges();

        this.breakpointSubscription = this.breakpointObserver
            .observe(['(max-width: 959px)']) // You can define more breakpoints here if needed
            .subscribe((state: BreakpointState) => {
                console.log("MOBILE BREAK POINT")
                if (state.matches) {
                    this.displayCols = this.displayedColumnsMobile;
                } else {
                    if ( this.viewAs === 'sysAdmin') {
                        this.displayCols = [...this.displayedColumnsSySAdmin]
                    } else { this.displayCols = [...this.displayedColumns] }
                }
            });

    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.sort.sort({ id: 'date', start: 'desc', disableClear: false });
    }

    setDataSource( numbers: Array<DdiNumber>) {
        console.log("[extensions tab].setDataSource()");
        if (!numbers || numbers?.length === 0) { return  this.getOrgUsers(); }

        this.dataSource.data = numbers.filter(item => item?.pip_variant === 'pipxt' && item?.sip_extension_cli !== null  && item?.sip_host_name !== null && item?.is_pipxt_extensionless !== true);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.getOrgUsers(); //get user list when data is refreshed.
        this.ref.detectChanges();
    }



    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getDetails() {
        //get profile if one was not passed

        console.log("[extensions tab].getDetails()")
        this.pageStatus.isLoading = true;

        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .switchMap((resp) => {
                console.log("[extensions tab].getDetails() resp", resp);
                this.organisationProfile = resp.body;
                return this.organisationService.getDdiListByOrganisation(this.organisationProfile.id);
            })
            .subscribe(resp => {
                console.log("[extensions tab].getDetails() org ddi resp", resp);
                this.orgDdiList = resp.body?.ddi_list ? resp.body?.ddi_list : [];
                this.setDataSource(this?.orgDdiList);
                this.pageStatus.isLoading = false;
                this.ref.detectChanges();
            },
            (err) => {
                this.pageStatus.isLoading = false
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })
    }

    getOrgUsers(organisation_id?: string) {
        this.availableUserList = [];
        this.organisationService.getOrgUsersById(this.organisation_id)
            .pipe(
                first()
            ).subscribe((resp) => {
                if ( resp.status === 200) {

                    if (resp?.body !== null) {
                        resp?.body?.user_list.forEach(user => {
                            if (user.organisation_list[0].ddi_list.filter(x => x.number != null).length === 0 ) {
                                this.availableUserList.push(user);
                            }
                        })
                    }
                    this.ref.detectChanges();
                }
            }),
        (err) => {
            this.ref.detectChanges();
            this.openSnackBar(`there was an error getting list of users [${err?.status}]`, "Dismiss");
        }
    }

    openRegisterSip(): void {
        console.log('register sip');
        const data = {viewAs: this.viewAs, organisation_id: this.organisation_id, enableOrganisationSearch: false}

        const dialogRef = this.dialog.open(CreateSipModalComponent, {
            panelClass: 'pipcall-modal-container',
            width: '600px',
            data
        });


        dialogRef.afterClosed().subscribe(result => {
            //if result is true, do something here
            //added to que
            setTimeout(() => {
                if (result === true) {
                    this.triggerUpdate();
                    //update values
                }
            }, 3000);
        });


    }

    openExtensionProfile(number: DdiNumber) {
        const data = {number: number, viewAs: this.viewAs, organisation_id: this.organisation_id, number_id: number?.number}

        const dialogRef = this.bottomSheet.open(DDIBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF Bottom sheet.", resp);

            if (resp) {
                this.getDetails();
                this.triggerUpdate();
                this.ref.detectChanges();
            }
        });
    }

    openUserProfile(user_id: string) {
        const data = {user: null, viewAs: this.viewAs, organisation_id: this.organisation_id, user_id : user_id}

        const dialogRef = this.bottomSheet.open(UserProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            if (resp) {
                console.log("AFTER DISMISS OF BS", resp);
                this.getDetails();
                this.triggerUpdate();
            }
        });
    }

    openInviteModal(number?: DdiNumber): void {
        number = number ? number : null;

        const data = {
            "organisation_id": this.organisation_id, "preallocated_number": number
        };

        const dialogRef = this.dialog.open(InviteUserModalComponentpipxt, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log("INVITE CLOSE", result);
            if (result === true) {
                this.triggerUpdate();
                this.ref.detectChanges();
            }
        });
    }

    alertRevokeInvitation(id: string) {

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'Remove this invitation',
            confirmButtonLabel: "Confirm",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.revokeInvitation(id);
                return;
            }
        });

    }

    revokeInvitation(id: string) {
        this.invitationService.deleteSingleInvitation(id).subscribe((resp) => {
            if (resp.status === 200 || resp.status === 201) {
                const index = this.dataSource.data.findIndex(val => val.invitation_id === id)
                this.dataSource.data[index].invitation_id = '';
                this.dataSource.data[index].invitation_email = '';
                this.openSnackBar("Invitation was revoked", "dismiss");
                return;
            }
            this.openSnackBar("sorry something went wrong with this request", "dismiss");

            console.log(this.dataSource);
        }, (err) => {
            console.log(err)
            this.openSnackBar("sorry something went wrong with this request", "dismiss");
        })
    }

    assignToUser(ddi: DdiNumber , user_id: string, name: string, organisation_id: string) {
        //add loading indicators for number status

        this.dataSource.data[this.dataSource.data.findIndex(i => i.number === ddi.number)].user_full_name = 'pending..';

        this.numberService.allocateDdi(
            ddi.number, user_id, organisation_id, name
        )
            .pipe(
                finalize(() => this.triggerUpdate())
            ).subscribe((resp) => {
                if (resp.status === 200) {
                    this.openSnackBar(`${ddi.sip_extension_number} was assigned to ${name}`, "dismiss");
                    this.availableUserList.splice(this.availableUserList.findIndex(n => n.id === user_id ), 1);
                }
            }, (err) => {
                this.openSnackBar('something went wrong', "dismiss");
                console.log(err) })

    }

    user_unlink(number: string) {

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'Unlink this user and number',
            confirmButtonLabel: "Unlink",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.numberService.deallocateDdi(number)
                    .pipe(
                        finalize(() => {
                            this.pageStatus.isLoading = false;
                            // this.getDetails();
                            this.triggerUpdate();
                        })
                    )
                    .subscribe((resp) => { }, (err) => { console.log(err); })
                return;
            }
        });


    }

    deleteNumber(ddi: DdiNumber) {
        console.log("DELETE NUMBER", ddi);
        //add confirm dialogue

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: `Delete extension ${ddi.sip_extension_number}?`,
            confirmButtonLabel: "Delete",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.numberService.deleteSingleDDi(ddi.number).subscribe((resp) => {
                    if (resp.status === 200 || resp.status === 201) {
                        this.triggerUpdate();
                        this.openSnackBar("Extension has been deleted", "dismiss");
                    }
                })
            }
        });


    }

    updateIsReservedState(row: DdiNumber, event) {
        const is_checked = event?.checked;

        const ddiUpdate: DdiUpdate = {
            pbx_platform_id: row.pbx_platform_id,
            provider:  row.provider,
            is_reserved:  is_checked,
            is_blocked:  row.is_blocked,
            is_sms_enabled:  row.is_sms_enabled,
            no_answer:  row.no_answer,
            cli_override:  row.cli_override,
            notes:  row.notes,
            label:  row.label,
            sort:  row.sort,
        }

        this.numberService.putDdiUpdate(row?.number , ddiUpdate).subscribe((resp) => {
            if (resp.status === 200 || resp.status === 201) {
                this.openSnackBar("Saved", "dismiss", 1000);
                this.orgDdiList[this.orgDdiList.findIndex(i => i.number === row.number)].is_reserved = is_checked;
                this.ref.detectChanges();
                // this.triggerUpdate();
            }
        }
        )
    }


    updateUserSelectedEvent(user: UserProfile) {
        console.log("=================///////////////////==================VALUE:", user.id);
        this.setFormValue(this.userAdminForm, 'user_id',   user.id);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string, duration?: number) {
        duration = duration ? duration : 3000;
        this.snackBar.open(message, action, {
            duration: duration,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
        if (this.breakpointSubscription) {
            this.breakpointSubscription.unsubscribe();
        }
    }

}
