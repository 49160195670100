
import { Pipe, PipeTransform } from '@angular/core';
import { NormalizeNumberService } from '../services/helpers/normalize-number.service';
import { AreaCodeHelper, RegionCodes} from '@app/services/helpers/number-validation-helper.service';
// https://libphonenumber.appspot.com/phonenumberparser


@Pipe({ name: 'normalizeTo00' })
export class NormalizeTo00 implements PipeTransform {
    constructor(private normalizeNumberService: NormalizeNumberService) { }

    transform(number: string, region?: string): string {
        const newFormat = AreaCodeHelper.normaliseNumberWith00(number, region);
        return newFormat;
    }
}



@Pipe({ name: 'normalizeToInternational' })
export class NormalizeToInternational implements PipeTransform {
    constructor(private normalizeNumberService: NormalizeNumberService) { }
    transform(number: string): string {
        return AreaCodeHelper.returnAsInternationalDisplay(number);
    }

}






// @Pipe({ name: 'normalizeToInternational' })
// export class NormalizeToInternational implements PipeTransform {
//     constructor(private normalizeNumberService: NormalizeNumberService) { }
//     transform(number: string, region: string): string {
//         // convert a 0044 style number to ++44.
//         const newFormat = this.normalizeNumberService.normaliseNumberWithPlus(number, region);
//         const _newFormat = newFormat?.replace('+44', '+44 '); //Add space
//         return _newFormat ? _newFormat : newFormat;
//     }
// }



// this.asYouType = new AsYouType('GB').input('07740352865');
// this.numberformat = format('+447740352865', 'GB', 'National'); //E164 //Original //International
// this.parse = parsePhoneNumber('+447740352865', 'GB')

// console.log("asYoutype",this.asYouType);
// console.log("numberformat",this.numberformat);
// console.log("country",this.parse.country);
// console.log("number",this.parse.number );
// console.log("valid",this.parse.isValid() );


//     import { parsePhoneNumber, ParseError } from 'libphonenumber-js'

// try {
//   const phoneNumber = parsePhoneNumber('Call: (213) 373-42-53 ext. 1234.', 'US')
// } catch (error) {
//   if (error instanceof ParseError) {
//     // Not a phone number, non-existent country, etc.
//     console.log(error.message)
//   } else {
//     throw error
//   }
// }
