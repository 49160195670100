//core
import { Component, OnInit, Input, EventEmitter, ElementRef, Output, ViewChild , ChangeDetectorRef, SimpleChanges} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';

import { Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from "rxjs";

import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { OrganisationProfileBottomSheetComponent } from '@app/components/bottom-sheet-modal/organisationprofile-bsheet-modal/organisationprofile-bsheet-modal.component';


//<cto-organisation [organisation_id]="row.organisation_id" [organisation_name]="organisation_name" [viewAs]="viewAs" [is_blocked]="row?.is_blocked" [organisation_state]="row?.organisation_state"  (triggerUpdate)="triggerUpdate()"></cto-organisation>
@Component({
    selector: 'cto-organisation',
    templateUrl: './cto-organisation.component.html',
    styleUrls: ['./cto-organisation.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class OrgClickComponent {
    @Input() organisation_id: string;
    @Input() organisation_name: string;
    @Input() organisation_state: string;
    @Input() viewAs: string;
    @Input() is_blocked?: boolean;
    @Input() hide_detail?: boolean; //force hide the second line detail
    @Input() version?: number; //if no version show default

    @Output()triggerUpdate = new EventEmitter();


    _destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet,
        private ref: ChangeDetectorRef
    ) {

    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        // changes['organisation_name']?.currentValue !== changes['organisation_name']?.previousValue ? this.getOrgUserList() : null;
    }

    openOrgDetails(organisation_name: string, organisation_id: string, viewAs: string) {
        console.log("OPEN BOTTOM SHEET--------");
        const data = {organisation_name: organisation_name, viewAs: viewAs, organisation_id: organisation_id}


        const dialogRef = this.bottomSheet.open(OrganisationProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("DISMISSED ORG")
            if (resp) {
                this.emitValue(resp);
            }
        });

    }

    emitValue(update: boolean) {
        console.log("EMIT VALUE");
        this.triggerUpdate.emit(update);
    }


    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }
}
