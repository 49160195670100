<ng-container [ngSwitch]="version">

  <ng-container *ngSwitchCase="1">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="cto-row-container">
      
      <div class="avatar-container av-size-30">
        <div class="avatar-icon-background" fxLayout="row" fxLayoutAlign="center center"
          [ngClass]="pip_variant === 'pipxt' ? 'pink' : 'accent'">
          <mat-icon *ngIf="pip_variant !== 'pipxt'" style="fill: white;color:white;height: 18px;width: 18px;"
            svgIcon="phone"></mat-icon>
          <mat-icon *ngIf="pip_variant === 'pipxt'" style="fill: white;color:white;height: 18px;width: 18px;"
            svgIcon="connect"></mat-icon>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start">

        <div class="hover-to-show cto-top-row-wrapper"  >
          <span  [matTooltip]="'SIP Caller Identity: ' + caller_identity">
            <a href="/admin/ddi/{{number_id}}" class="overflow-elipsis cto-max-width"
           >{{number_id | normalizeToInternational}}</a></span>

          <mat-icon (click)="openDDIProfile(pip_variant, number_id, organisation_id)" matSuffix svgIcon="view"
            (click)="$event.stopPropagation()" class="clickable action-icon" matTooltipPosition="below"
            matTooltipShowDelay="200" matTooltip="Quick view"></mat-icon>

          <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number_id)" class="clickable action-icon"
            matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy">
          </mat-icon>
        </div>

        <div *ngIf="display_cli && !hide_detail" class="cto-bottom-row-wrapper" style="padding-left: 10px;
        font-size: 0.8em;">
          <span class="cto-bottom-row-content cto-max-width">SIP CLI: {{caller_identity | normalizeToInternational}}&nbsp;</span>
        </div>

      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <!-- <div fxLayout="row" fxLayoutAlign="start center" class="hover-to-show-copy"
      *ngIf="simpleMode === null || simpleMode === undefined || simpleMode === false">


      <div class="avatar-container av-size-38" *ngIf="number_id">
        <div class="avatar-icon-background" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon style="fill: white;height: 22px;width: 22px;" svgIcon="phone"></mat-icon>
        </div>
      </div>


      <div fxLayout="column" style="padding-left:12px;">

        <div style="color: var(--c-url); white-space: nowrap;">

          <span (click)="openNumberProfile(number_id, viewAs, organisation_id)"
            class="clickable overflow-elipsis max-width-name" style="color: var(--c-url)">{{number_id |
            normalizeToInternational}}</span>

          <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
            class="icon-14" svgIcon="block" matTooltip="This number is blocked"></mat-icon>

          <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number_id)" class="clickable copy-icon"
            matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy">
          </mat-icon>

        </div>

        <div *ngIf="pbx_platform_id || provider">
          <span style="max-width: 230px;" class="max-width-name overflow-elipsis" style="opacity: 0.6;font-size:0.9em;"
            [matTooltip]="provider">{{provider}} |&nbsp;</span>

          <span style="max-width: 230px;" class="max-width-name overflow-elipsis" style="opacity: 0.6;font-size:0.9em;"
            [matTooltip]="pbx_platform_id">{{pbx_platform_id}}&nbsp;</span>
        </div>

      </div>


      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="simpleMode === true"
        (click)="openNumberProfile(number_id, viewAs, organisation_id)">
        {{number_id | normalizeToInternational}}
      </div>

    </div> -->

  </ng-container>

</ng-container>