<ng-container *ngIf="loadingPiPnumbers;else loaded">
  <div>loading PiPnumbers ....</div>
</ng-container>
<ng-template #loaded>



  <ng-container *ngIf="pipnumbers.length > 0;else nopips">

    <mat-form-field appearance="outline" class="custom-form-field-v3">
      <mat-select [(ngModel)]="selectedPipnumber" (selectionChange)="emitValue($event)"
        placeholder="Select a number">
        <mat-option *ngFor="let ddi of pipnumbers" [value]="ddi.number">
          <span *ngIf="ddi.pip_variant === 'pipmobile'">{{ddi?.number | normalizeToInternational}}</span>
          <span *ngIf="ddi.pip_variant === 'pipxt'"> Ext. {{ddi?.sip_extension_number}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

  </ng-container>

  <ng-template #nopips>
    <div><i [style.font-size.px]="10">no available numbers</i></div>
  </ng-template>




</ng-template>

