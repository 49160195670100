  <app-toolbar-v2></app-toolbar-v2>

  <div fxLayout="column">

    <div fxLayout="column" class="main-container" fxLayoutAlign="start center">

      <div fxFlex="fxFill" fxLayout="row" fxLayout.lt-lg="column" class="section-container max-width-1440">

        <div class="lr-content-wrapper" fxFlex="50%" fxFlex.lt-lg="100%" ngClass.lt-lg="remove-padding-bottom">

          <ng-container *ngIf="pageStatus.step === 0">

            <h4 class="title-font title-color" ngClass.lt-lg="text-center">Start your free 14-day trial</h4>

            <!-- <div class="font-16-copy"  ngClass.lt-lg="text-center">Create your account and experience the best of telecoms all in one app.</div> -->
            <div class="font-16-copy" ngClass.lt-lg="text-center">No credit card details needed</div>
            <div class="call-us-body"  ngClass.lt-lg="text-center">
              If you have any questions, please contact our sales team on <span style="font-size: 1.2em;white-space:nowrap;">0330 094 8086</span> or email at <a href="mailto:sales@pipcall.com">sales@pipcall.com</a>
            </div>
      
<!-- 
            <div class="list-item-point">

              <div class="list-item-title title-color">
                <div class="list-item-icon-container"><mat-icon svgIcon="phone"></mat-icon></div>
                Virtual Numbers <p class="font-16-copy" style="color:var(--c-body); font-weight: 400;">Choose a 07 or geographic number</p></div>

              <div class="list-item-title title-color">
                <div class="list-item-icon-container"><mat-icon svgIcon="get_app"></mat-icon></div>
                Advanced Apps <p class="font-16-copy" style="color:var(--c-body); font-weight: 400;">Download the app on your mobile phone</p></div>

              <div class="list-item-title title-color">
                <div class="list-item-icon-container"><mat-icon svgIcon="cloud"></mat-icon></div>
                Cloud System <p class="font-16-copy" style="color:var(--c-body); font-weight: 400;">Invite and manage your team members from the portal</p></div>

              <div class="list-item-title title-color">
                <div class="list-item-icon-container"><mat-icon svgIcon="styles"></mat-icon></div>
                Call Plans <p class="font-16-copy" style="color:var(--c-body); font-weight: 400;">Make calls and send SMS for free</p></div>

              <div class="list-item-title title-color">
                <div class="list-item-icon-container"><mat-icon svgIcon="voice_network"></mat-icon></div>Calling Technology <p class="font-16-copy" style="color:var(--c-body); font-weight: 400;">Make calls over the mobile voice network</p></div>


              <div class="list-item-title title-color">
                <div class="list-item-icon-container"><mat-icon svgIcon="ring_group"></mat-icon></div>Desk Phone Features <p class="font-16-copy" style="color:var(--c-body); font-weight: 400;">Request features like ring groups, call recording and call whispering during the trial (conditions apply)</p></div>


                <div class="call-us-body">
                  If you have any questions, please contact our sales team on <span style="font-size: 1.2em;white-space:nowrap;">0330 094 8086</span> or email at <a href="mailto:sales@pipcall.com">sales@pipcall.com</a>
                </div>
            </div> -->



            <!-- <div class="list-item-point">

              <div class="list-item-title title-color">Virtual Numbers
                <div class="list-item-icon-container">1</div>
              </div>
              <p class="font-16-copy" >Choose a 07 or geographic number</p>
            </div>

            <div class="list-item-point">
              <div class="list-item-title title-color">Advanced Apps
                <div class="list-item-icon-container">2</div>
              </div>
              <p class="font-16-copy" >Download the app on your mobile phone</p>
            </div> -->

          </ng-container>

          <ng-container *ngIf="pageStatus.step === 1">
            <h4 class="title-font title-color" ngClass.lt-lg="text-center">Verify your email</h4>
            <div class="font-16-copy" ngClass.lt-lg="text-center">We've emailed a verification code to&nbsp;<wbr>{{this.signUpForm.value.email}}. Enter it below. <br><span (click)="cancelVerification()" class="page-subtitle">Did not receive it? <a href="javascript:void(0)">&nbsp;check your details</a></span></div>
          </ng-container>

        </div>

        <div class="lr-content-wrapper" fxFlex="50%" fxFlex.lt-lg="100%" fxLayoutAlign="center">
          <div fxLayout="column" class="ct-fill">

            <ng-container *ngIf="pageStatus.step === 0">
                <!-- <h5 fxHide.gt-md class="title-font title-color" ngClass.lt-lg="text-center" style="margin-bottom: 20px;">Sign up</h5> -->

              <form [formGroup]="signUpForm" fxLayout="column" fxLayoutGap="6px"
                [ngClass]="{'readonly-block': pageStatus.isLoading || pageStatus.isSubmitting}">

                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="40px" fxLayoutGap.lt-md="20px" fxLayout.lt-sm="column"  fxLayoutAlign.lt-sm="start" fxLayoutGap.lt-sm="6px">

                  <mat-form-field appearance="outline" fxFlex="50%"  style="min-width: 50px;" class="custom-form-field-v3">
                    <input type="text" matInput placeholder="First Name" autocomplete="given-name"
                      formControlName="first_name" [readonly]="false">


                    <mat-error
                      *ngIf="signUpForm?.controls.first_name?.touched && signUpForm?.controls.first_name?.errors?.required">
                      First name
                      is required</mat-error>

                    <mat-label>First Name</mat-label>

                    <status-icons *ngIf="signUpForm?.controls.first_name?.touched"
                      [iconStatus]="signUpForm.get('first_name').status">
                    </status-icons>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="50%" style="min-width: 50px;" class="custom-form-field-v3">
                    <input type="text" matInput placeholder="Last name" autocomplete="family-name"
                      formControlName="last_name" [readonly]="false">

                    <mat-error
                      *ngIf="signUpForm?.controls.last_name?.touched && signUpForm?.controls.last_name?.errors?.required">
                      Last name
                      is required</mat-error>
                    <mat-label>Last Name</mat-label>
                    <status-icons *ngIf="signUpForm?.controls.last_name?.touched"
                      [iconStatus]="signUpForm.get('last_name').status">
                    </status-icons>
                  </mat-form-field>
                </div>

                <mat-form-field appearance="outline" class="custom-form-field-v3">

                  <input type="email" matInput placeholder="Email" autocomplete="email" formControlName="email">
                  <mat-icon matSuffix svgIcon="email_v2"></mat-icon>
                  <mat-error
                    *ngIf="signUpForm?.controls.email?.touched && signUpForm?.controls.email?.errors?.required">
                    Required
                    Field</mat-error>
                  <mat-error *ngIf="signUpForm?.controls.email?.touched && signUpForm?.controls.email?.errors?.email">
                    not a valid email</mat-error>
                  <mat-error *ngIf="signUpForm.get('email').status === 'PENDING'">
                    checking ...
                  </mat-error>
                  <mat-error *ngIf="signUpForm.get('email').errors && signUpForm.get('email').errors.emailTaken">
                    Email already in use.
                  </mat-error>
                  <mat-label>Email</mat-label>

                  <status-icons *ngIf="signUpForm?.controls.email?.touched"
                    [iconStatus]="signUpForm.get('email').status">
                  </status-icons>

                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100"  class="custom-form-field-v3">
                  <input autocomplete="off" type="text" matInput placeholder="44 " formControlName="contact_phone_number">
                  <mat-error
                    *ngIf="signUpForm.controls.contact_phone_number?.touched && signUpForm.controls.contact_phone_number?.errors?.required">
                    phone number is required</mat-error>
                  <mat-label>Contact number</mat-label>
                  <mat-icon class="phone-prefix" matPrefix>add</mat-icon>
                  <mat-icon svgIcon="info" matTooltipPosition="above" matTooltipShowDelay="100"
                  matTooltip="A contact number for the organisation, so we can get in touch. This will never be shared with 3rd parties." matSuffix>
                </mat-icon>
    
                  <status-icons [hasPrefix]="true"
                    *ngIf="signUpForm.controls.contact_phone_number?.touched"
                    [iconStatus]="signUpForm.controls.contact_phone_number?.status">
                  </status-icons>

                </mat-form-field>



                <mat-form-field appearance="outline" class="custom-form-field-v3">

                  <input type="{{pwType}}" matInput placeholder="Password" autocomplete="new-password"
                    formControlName="password"
                    title="Password strength: minimum length 8 characters and must include lower case (a-z), upper case (A-Z) and a number (0-9)">
                  <mat-icon matSuffix class="clickable" *ngIf="pwType != 'password'" (click)="pwType = 'password'"
                    title="hide password" (click)="$event.stopPropagation()" svgIcon="view"></mat-icon>
                  <mat-icon matSuffix class="clickable" *ngIf="pwType === 'password'" (click)="pwType = 'text'"
                    title="show password" (click)="$event.stopPropagation()" svgIcon="view_off"></mat-icon>

                  <mat-error
                    *ngIf="signUpForm?.controls.password?.touched && signUpForm?.controls.password?.errors?.required">
                    Required field
                  </mat-error>

                  <mat-error
                    *ngIf="signUpForm?.controls.password?.touched && signUpForm?.controls.password?.errors?.pattern">
                    min length 8 chars, include lower case (a-z), upper case (A-Z) and a number (0-9)
                  </mat-error>

                  <mat-label>Password</mat-label>

                  <status-icons *ngIf="signUpForm?.controls.password?.touched"
                    [iconStatus]="signUpForm.get('password').status">
                  </status-icons>

                </mat-form-field>

                <mat-form-field appearance="outline" class="custom-form-field-v3">

                  <input type="{{pwType}}" matInput placeholder="Confirm Password" autocomplete="new-password"
                    formControlName="passwordConfirm"
                    title="Password strength: minimum length 6 characters and must include lower case (a-z), upper case (A-Z) and a number (0-9)">


                  <mat-icon matSuffix class="clickable" *ngIf="pwType != 'password'" (click)="pwType = 'password'"
                    title="hide password" (click)="$event.stopPropagation()" svgIcon="view"></mat-icon>
                  <mat-icon matSuffix class="clickable" *ngIf="pwType === 'password'" (click)="pwType = 'text'"
                    title="show password" (click)="$event.stopPropagation()" svgIcon="view_off"></mat-icon>

                  <mat-error
                    *ngIf="signUpForm?.controls.passwordConfirm?.touched && signUpForm?.controls.passwordConfirm?.errors?.required">
                    Required field
                  </mat-error>

                  <mat-error
                    *ngIf="signUpForm?.controls.passwordConfirm?.touched && signUpForm?.controls.passwordConfirm?.errors?.MatchPassword">
                    Passwords do not match
                  </mat-error>

                  <mat-label>Confirm Password</mat-label>

                  <status-icons *ngIf="signUpForm?.controls.passwordConfirm?.touched"
                    [iconStatus]="signUpForm.get('passwordConfirm').status"></status-icons>

                </mat-form-field>

                <section class="bottom-options" >
                  <p class="font-14-copy" style="text-align: left; margin-bottom: 20px;"> By signing up to the PiPcall
                    Portal via this form you are creating a customer account with us, and your organisation will
                    therefore be bound by our <a class="nowrap-col" target="_blank"
                      href="https://www.pipcall.com/policies/corporatecustomercontract">Corporate Customer Contract</a>.
                    Your personal use of the PiPcall Portal will also be bound by our <a class="nowrap-col"
                      target="_blank" href="https://www.pipcall.com/policies/endusertermsandconditions">End-User Term
                      and Conditions</a>.</p>
                  <mat-checkbox class="font-14-copy" labelPosition="before" formControlName="termsconditions">
                    I agree to the statement above
                    <!-- I agree to <a href="https://www.pipcall.com/termsandconditions/" target="_blank">terms and conditions</a> & <a href="https://www.pipcall.com/privacypolicy/" target="_blank">privacy policy</a> -->
                  </mat-checkbox>

                </section>

              </form>

              <div class="text-right">
                <div class="error-response-box" *ngIf="pageStatus.isError"
                  [@enter]="{ value: '', params: { duration: 600, delay: 0 } }"
                  [@fadeOutLeftOnLeave]="{ value: '', params: { duration: 100, delay: 0, translate: '50%' } }">
                  <mat-icon class="clickable" (click)="pageStatus.isError = false">cancel</mat-icon>
                  {{pageStatus.errorMessage}}
                </div>

                <!-- {{signUpForm.valid}} <button (click)="debug()">debug values</button> <button (click)="next()">nextscreen</button> -->
                <button id="continueSignup" class="submit-button" mat-flat-button color="accent"
                  [disabled]="pageStatus.isLoading || !signUpForm.valid" (click)="submitSignupForm()">
                  <ng-container *ngIf="!pageStatus.isLoading;else spinning">Create Account</ng-container>
                  <ng-template #spinning><span class="loading-spinner"><i class="fas fa-spinner fa-spin"></i></span>
                  </ng-template>
                </button>

                <section>
                  <ul style="text-align: right;list-style: none;font-size: 12px;">
                    <li><a style="color:grey; text-decoration: none;" class="nowrap-col" target="_blank"
                        href="https://www.pipcall.com/policies/customerprivacypolicy">Customer Privacy Policy</a></li>
                    <li><a style="color:grey; text-decoration: none;" class="nowrap-col" target="_blank"
                        href="https://www.pipcall.com/policies/enduserprivacypolicy">End-User Privacy Policy</a> </li>
                  </ul>
                </section>

              </div>

            </ng-container>

            <ng-container *ngIf="pageStatus.step === 1">
              <app-verify-signup [email]="signUpForm.get('email').value" [signup_request_id]="signup_request_id"
                [password]="signUpForm.get('password').value" (cancel)="cancelVerification($event)" (verified)="verified($event)"></app-verify-signup>
            </ng-container>

          </div>


        </div>

      </div>

      <!-- <div fxFlex="fxFill" fxLayout="column" fxLayoutAlign="center center" class="section-container max-width-1440" >
                        <div class="vertical-line"></div>
                       <div class="title-color" >Learn more</div>
                    </div> -->

    </div>


  </div>

  <re-captcha [hidden]="true" size="invisible" badge="inline" #invisible (resolved)="updateRecaptcha($event)">
  </re-captcha>
