import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '../../models/response.model';
import { map, tap, filter, catchError, mergeMap, finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import {LicenseModel, Licensedata } from '@app/models/license.model'


export interface CartItem {
    type: string;
    id: string;
    license_id: string;
}

export interface User {
    type: 'User' | 'Invitation';
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    number: string;
    is_mobile_license: boolean;
}

export interface CheckoutResponse {
    invoice_total: number;
    stripe_client_secret: string;
}



@Injectable()
export class PaymentsService {

    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`
    public options;

    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    innit() {
        //setActiveLicenses
    }

    public getListLicenses(): Observable<responseModel> {
        console.log('[api] -> get license data')
        return this.httpProxy.get(this.ApiBaseUrl + '/licenses' + this.code, 'response', true);
    }

    public getAddOnPackages(): Observable<responseModel> {
        console.log('[api] -> get Add on packages')
        return this.httpProxy.get(this.ApiBaseUrl + '/add-on-packages' + this.code, 'response', true);
    }

    public getUnlicensedList(id: string): Observable<responseModel> {
        console.log('[api] -> get unlicensed user list')
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${id}/users/unlicensed` + this.code, 'response', true);
    }

    public postLicensesCart(orgid: string, cart: CartItem[]): Observable<responseModel> {
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${orgid}/users/licenses${this.code}`, cart, 'response', true);

    }


    public postUpdateDefaultCard(orgId: string, paymentMethodId: string): Observable<responseModel> {
        console.log('[api] -> Post update default card');
        const body = {
            payment_method_id: paymentMethodId
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${orgId}/cards/default${this.code}`, body, 'response', true);
    }

    public getDefaultCard(orgId: string): Observable<responseModel> {
        console.log('[api] -> get default payment card');
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${orgId}/cards/default${this.code}`, 'response', true);
    }

}

