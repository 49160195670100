import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//modules (defaults)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';

import { modalServiceModule} from '@app/services/modal-service/modal-service.module';
import { ValidatorsModule } from '@app/validators/validators.module';

//components (declarations)
import { ExpoSignupComponent } from './expo-signup.component';

//services (providers)
import { AppInsightService } from '../../services/helpers/app-insights.service';
import { SharedService} from '@app/services/shared-data.service/shared-data.service';
import { ExpoService } from '@app/services/pipcall/expo.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FlexLayoutModule,
        GravatarDirectiveModule,
        modalServiceModule,
        ValidatorsModule,
        StatusIconsModule
    ],
    declarations: [ExpoSignupComponent],
    providers: [HttpProxy, SharedService, AppInsightService, ExpoService],
    entryComponents: []
})
export class ExpoSignupModule {
}
