<div class="cc-page-container" [ngClass]="{'cc-container-large': cardSize === 'large'}">
  <div class="cc-main-card-container" [ngClass]="{'cc-card-large': cardSize === 'large'}">
    <div class="cc-card">

      <ng-container *ngIf="noInnerContainer; else defaultDisplay">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </ng-container>

      <ng-template #defaultDisplay>
        <div class="cc-content-container">
          <div class="cc-main-content">
            <div class="main-content-inner-wrap">
              <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
          </div>
        </div>
      </ng-template>



    </div>
  </div>
</div>


<ng-template #content>
  <ng-container *ngIf="showBrandLogo && !isFullScreen; then brandLogo;"></ng-container>
  <ng-content></ng-content>
  <ng-container *ngIf="showBrandLogo && isFullScreen; then brandLogo;"></ng-container>
  <ng-container *ngIf="showCopyRight">
    <div class="copyrightmessage">{{copyrightMessage}}</div>
  </ng-container>
</ng-template>

<ng-template #brandLogo>
  <div class="logo-container">
    <a class="show-on-light" href="/" title="home"> <img src="/assets/brand/pipcall-logo-black-flat.png"></a>
    <a class="show-on-dark" href="/" title="home"> <img src="/assets/brand/pipcall-logo-white-flat.png"></a>
  </div>
</ng-template>