/* eslint-disable max-len */
import { environment } from '../../../environments/environment';
import { Component, OnInit, isDevMode } from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FormGroup, Validators, FormBuilder, ValidationErrors, FormControl, Form, AbstractControl, FormsModule } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { finalize } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

//normalizing number
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInUpOnEnterAnimation, fadeInRightOnEnterAnimation, fadeOutLeftOnLeaveAnimation, fadeInLeftOnEnterAnimation, fadeOutRightOnLeaveAnimation } from 'angular-animations';
import { TitleCasePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { EmailMatchValidation } from '@app/validators/emailMatch.Validator';
import { PasswordMatchValidation } from '@app/validators/passwordMatch.Validator';
import { GA4Service } from '@app/services/google-analytics-service';

@Component({
    selector: 'app-signup-page',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        trigger('fadeInOut', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),
            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
        ]),
        fadeOutLeftOnLeaveAnimation({ duration: 250, translate: '50%' }),
        fadeInRightOnEnterAnimation({ duration: 300, delay: 400, translate: '50%' }),
        fadeOutRightOnLeaveAnimation({ duration: 250, translate: '50%' }),
        fadeInLeftOnEnterAnimation({ duration: 300, delay: 400, translate: '50%' }),
        fadeInUpOnEnterAnimation({ anchor: 'enter' }),
    ]
})
export class SignupComponent implements OnInit {


    public isDev: boolean;
    public appversion: string = environment.appversion;

    signUpForm: FormGroup;
    public pwType: 'password' | 'text' = 'password';

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        errorMessage: '',
        step: 0
    }


    public signup_request_id = '';
    isTouched = false;

    constructor(
        public dialog: MatDialog,
        private appInsightService: AppInsightService,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private cookieService: CookieService,
        private signupService: SignupService,
        private _fb: FormBuilder,
        private gA4Service: GA4Service
    ) {

        this.appTitleService.setTitle('PiPcall: Signup');
        this.appInsightService.logPageView('Signup: form');
    }

    get hasExistingToken(): boolean {
        // If a valid access token exists OR a valid refresh token exists, return true
        return this.cookieService.check('access_token') && this.auth.isAuthenticated() ? true : this.cookieService.check('refresh_token') ? true : false;
    }

    ngOnInit() {

        if (this.hasExistingToken) { this.navigateByUrl('/redirect'); }

        const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');


        this.signUpForm = this._fb.group({
            first_name: ['', [Validators.maxLength(18), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
            last_name: ['', [Validators.maxLength(18), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
            email: ['', [Validators.email, Validators.required], [ValidateEmailNotTaken.createValidator(this.signupService)]],
            contact_phone_number: ['44', [Validators.required, Validators.pattern('^([0-9]{12})$')]],
            password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
            passwordConfirm: ['', [Validators.required, PasswordMatchValidation.MatchPassword]],
            termsconditions: [false, Validators.requiredTrue],
            recaptcha: [null, Validators.required],
        });

        this.signUpForm.get('termsconditions').valueChanges
            .subscribe(val => {
                if (val) {
                    this.triggerRecaptcha();
                }

                if (!val) {
                    console.log("reset recaptcha");
                    grecaptcha.reset();
                }
            });

        this.signUpForm.get('password').valueChanges
            .subscribe(val => {
                this.signUpForm.updateValueAndValidity();
            });

        //signupform value changes
        this.signUpForm.valueChanges.subscribe(val => {
            if (this.isTouched) {return} else {
                this.isTouched = true;
                this.gA4Service.sign_up_start('pipxt');
            }
        });
    }

    ngAfterViewInit() {
    }


    navigateByUrl(url) {
        this.router.navigateByUrl(url);
    }

    submitSignupForm() {
        this.pageStatus.isError = false;
        console.log('submit form', this.signUpForm.value);

        //trim the space from first_name
        this.signUpForm.controls.first_name.patchValue(this.signUpForm.value.first_name.trim());
        this.signUpForm.controls.last_name.patchValue(this.signUpForm.value.last_name.trim());

        if (this.signUpForm.valid) {
            this.pageStatus.isLoading = true;

            this.signupService.postNewSignup(this.signUpForm.value)
                .pipe(
                    finalize(() => { this.pageStatus.isLoading = false })
                )
                .subscribe((resp) => {
                    this.appInsightService.logEvent('Form submitted | Signup', { 'email': this.signUpForm.value.email });

                    if (resp.status === 200 || resp.status === 201) {
                        this.signup_request_id = resp.body.organisation_signup_request_id;
                        if (this.signup_request_id) {
                            this.gA4Service.sign_up_pipxt_stage1_complete();
                            this.next();
                        } else {
                            this.openSnackBar("something went wrong, no sign up ID returned", "dismiss");
                        }
                    }
                }, (err) => {
                    console.log(err);
                    if (err.status === 500) { this.openSnackBar("Something went wrong", "dismiss") };
                    if (err.status === 412) {
                        //reset Terms and Conditions
                        this.signUpForm.controls.termsconditions.patchValue('');
                        this.signUpForm.updateValueAndValidity();

                        this.pageStatus.errorMessage = "Recaptcha has expired. Please agree to terms and conditions again.";
                        this.pageStatus.isError = true;
                    }
                })

        }
    }


    updateRecaptcha(event) {
        console.log("recaptcha event:", event);
        this.pageStatus.isError = false;

        this.signUpForm.controls.recaptcha.patchValue(event);
        this.signUpForm.updateValueAndValidity();

        if (!event) {
            //reset Terms and Conditions
            this.signUpForm.controls.termsconditions.patchValue('');
            this.signUpForm.updateValueAndValidity();
            this.pageStatus.errorMessage = "Recaptcha has expired. Please agree to terms and conditions again.";
            this.pageStatus.isError = true;
        }
    }

    triggerRecaptcha() {
        grecaptcha.execute();
    }

    next() {
        setTimeout(() => {
            this.pageStatus.step++;
        }, 180);
    }

    prev() {
        setTimeout(() => {
            this.pageStatus.step--;
        }, 180);
    }

    cancelVerification(event) {
        if (event) {
            //this.pageStatus.step = 0; //handle animation better by nulling first
            //reset Terms and Conditions
            this.signUpForm.controls.termsconditions.patchValue('');
            this.signUpForm.updateValueAndValidity();
            this.signup_request_id = ''; //clear signup_request_id
            grecaptcha.reset();
            this.signUpForm.controls.recaptcha.patchValue('');
            setTimeout(() => {
                this.pageStatus.step = 0;
            }, 180);
        }
    }

    verified(event) {
        if (event) {
            this.continueToPortal();
        }
    }
    continueToPortal(): void {
        if (this.auth.isAuthenticated()) {
            this.router.navigateByUrl('/redirect');
        } else {
            sessionStorage.setItem('redirect_url', '/redirect');
            this.router.navigateByUrl('/');
            // this.auth.login();
        }
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
