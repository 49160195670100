import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild , ChangeDetectorRef, Renderer2} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';

import { Subscription, Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { NumberService } from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';

import 'rxjs/add/operator/switchMap';
import { debounceTime, distinctUntilChanged, startWith, map, tap, first, delay, switchMap } from 'rxjs/operators';
import { merge, fromEvent , Subject} from "rxjs";
import { finalize } from 'rxjs/operators';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';

import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

import * as XLSX from 'xlsx';
import { ExcelService } from '@app/services/helpers/export-as-excel.service';
import { FormatDate } from '@app/pipes/formatDate';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service';


import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { CreateOrganisationModalComponent } from '@app/components/modals/create-organisation-modal/create-organisation-modal.component';

import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UpdateOrganisationNoteModalComponent } from '@app/components/modals/update-organisation-note-modal/update-organisation-note-modal.component';
import { animate, state, style, transition, trigger, query, stagger} from '@angular/animations';


import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
import { AvailableColumns } from '@app/components/advanced-search/table-toolbar-v2/table-toolbar.component';
import { ExpoService, ExpoDataModel } from '@app/services/pipcall/expo.service';
import { ExpoDashboardModal } from './dashboard-modal/dashboard-modal.component'

const listAnimation = trigger('listAnimation', [
    transition('* <=> *', [
        query(':enter',
            [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
            { optional: true }
        ),
        query(':leave',
            animate('200ms', style({ opacity: 0 })),
            { optional: true}
        )
    ])
]);

@Component({
    templateUrl: './expo-dashboard.component.html',
    styleUrls: ['./expo-dashboard.component.scss'],
    animations: [listAnimation
    ],
})
export class ExpoDashboardComponent implements OnInit {

    public viewAs = "sysAdmin"; //owner,admin or user. set to owner if matches on return of data.

    public pageStatus = {
        isLoading: true,
        isSubmitting: false
    }
    public queryParams: QueryParams = new QueryParams();

    signupData: ExpoDataModel[] = [];
    expoSubscription: Subscription;

    totalVerified = 0;
    progress: 'neutral' | 'cold' | 'warm' | 'hot' | 'complete' = 'neutral';

    //fallbackvalues
    dashboardConfig = {
        targetGoal: 50,
        adjustUsers: 0,
        pollingInterval: 120000,
        showNumberOfDays: 8
    }

    currentUser: any;
    // goal = 50;
    // adjustmentValue = 0;
    //pollingInterval = 120000;
    // dateRangeByDays = 8; // 7 days midnight to midnight

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private appInsightService: AppInsightService,
        public breakpointObserver: BreakpointObserver,
        public numberService: NumberService,
        public staticDataService: StaticDataService,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private appTitleService: AppTitleService,
        private excelService: ExcelService,
        private sidenavRightService: SidenavRightService,
        private sharedService: SharedService,
        private bottomSheet: MatBottomSheet,
        private ref: ChangeDetectorRef,
        private expoService: ExpoService,
        private renderer2: Renderer2,
        private http: HttpClient
    ) {
        this.appTitleService.setTitle("admin/expodashboard");
        this.appInsightService.logPageView('Admin: Expo dashboard');
    }


    ngOnInit() {
        this.getDashboardConfig();
        this.setQueryParams();
        this.pageStatus.isLoading = false;
        window.addEventListener('scroll', this.scroll, true); // third parameter
        this.renderer2.setStyle(document.body, 'background-color', '#2b2d42');

        this.sharedService.userProfileAsObservable.subscribe(userProfile => {
            this.currentUser = userProfile;
        });
    }

    getDashboardConfig() {
        // this.adjustmentValue = 0;
        this.http.get<any>('https://pipcallportalstorage.blob.core.windows.net/files/expo-dashboard-config.json' + '?timestamp=' + new Date().getTime()).subscribe(data => {
            // this.adjustmentValue = data['adjust-users'];
            //update dashboardConfig IF these values exist in the json file
            console.log('update dashboard config,', data)
            data['targetGoal'] ?  this.dashboardConfig.targetGoal = data['targetGoal'] : null;
            data['adjustUsers'] ?  this.dashboardConfig.adjustUsers = data['adjustUsers'] : null;
            data['pollingInterval'] ?  this.dashboardConfig.pollingInterval = data['pollingInterval'] : null;
            data['showNumberOfDays'] ?  this.dashboardConfig.showNumberOfDays = data['showNumberOfDays'] : null;
        });
        this.ref.detectChanges();
    }


    ngAfterViewInit() {
        // Execute getData initially
        this.getData(this.queryParams);
        //loop execute getData every 1 minute
        // Setup interval to execute getData every 1 minute

        setInterval(() => {
            this.getData(this.queryParams);
        }, this.dashboardConfig.pollingInterval);
    }



    setQueryParams() {
        this.queryParams.sort_by = 'created_at'
        this.queryParams.sort_direction = 'desc'
        this.queryParams.pageNumber = 1;
        this.queryParams.pageSize = 200;
        this.queryParams.searchString = `&state[notcontains]=Deleted`;
    }

    getData(queryParams?: QueryParams) {

        this.signupData = [];
        this.pageStatus.isSubmitting = true;

        this.queryParams = queryParams;

        this.getDashboardConfig();

        console.log("Get Expo signups, queryParams");
        this.expoSubscription = this.expoService.searchExpoSignups(queryParams)
            .pipe(
                map((resp) => this.filterData(resp?.body))
            )
            .subscribe((resp) => {
                console.log('====resp data==============', resp)
                this.signupData = [...resp];


                console.log('===================signupData=====', this.signupData)
                this.pageStatus.isSubmitting = false;
                this.expoSubscription.unsubscribe();
                this.checkProgress();
                this.ref.detectChanges();
            }
            , (err) => {
                this.totalVerified = 0;
                this.signupData = [];
                this.pageStatus.isSubmitting = false;
                this.expoSubscription.unsubscribe();
                this.openSnackBar(`An unspecified error occured [${err.status}]`, "Dismiss");
            });

    }

    scroll = (): void => {
        const scrollButton = this.elementRef.nativeElement.querySelector('.scroll-to-top-btn');
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollButton.classList.add('show');
        } else {
            scrollButton.classList.remove('show');
        }
    };
    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // this.expoService.searchExpoSignups(queryParams)
    //     .subscribe((resp) => {
    //         console.log('====resp data==============', resp.body)
    //         //this.dataSource = resp.body?.data; //set the data to match data from server

    //         //set datasource to data
    //         this.signupData = [...resp.body?.data]
    //         console.log('===================datasource signupData=====', this.signupData)
    //         this.queryParams.totalCount = resp.body?.pagination.totalCount;
    //         this.queryParams.pageCount = resp.body?.pagination.pageCount;
    //         this.pageStatus.isLoading = false;
    //         console.log('===================datasource2=====', this.signupData)
    //         this.ref.detectChanges();
    //     }, (err) => {
    //         console.log(err);
    //         this.signupData = [];
    //         this.queryParams.totalCount = 0;
    //         this.queryParams.pageCount = 0;
    //         this.pageStatus.isLoading = false;
    //     })
    // }

    filterData(resp: any) {
        console.log('filterData', resp);

        const users = [];
        this.totalVerified = 0 + this.dashboardConfig.adjustUsers;
        if (!resp.data) { return users; }

        resp.data.forEach((user) => {
            if (this.isFromDateRange(user) && user?.state !== "Deleted") {
                users.push(user);
                if (user?.state === 'Migrated') {
                    this.totalVerified++;
                }
            }

        });

        // return users;
        return users.sort((a, b) => {
            const dateA = new Date(a.created_at).getTime(); // Convert date to milliseconds
            const dateB = new Date(b.created_at).getTime(); // Convert date to milliseconds
            return dateB - dateA; // Compare dates in descending order
        });
    }


    checkProgress() {
        // Calculate the percentage achieved
        const percentageAchieved: number = (this.totalVerified / this.dashboardConfig.targetGoal) * 100;
        if (percentageAchieved >= 10 && percentageAchieved <= 29) {
            this.progress = 'cold';
        } else if (percentageAchieved >= 30 && percentageAchieved <= 69) {
            this.progress  = 'warm';
        } else if (percentageAchieved >= 70 && percentageAchieved <= 99) {
            this.progress  = 'hot';
        } else if (percentageAchieved === 100) {
            this.progress  = 'complete';
        } else {
            // Handle edge cases where percentageAchieved is less than 10 or greater than 100
            this.progress  = 'neutral';
        }
    }


    isFromLast10Minutes(timestamp) {
        const createdAtDate = new Date(timestamp);
        // Get the current time
        const currentTime = new Date();
        // Calculate the difference in milliseconds

        // Calculate the difference in milliseconds
        const differenceInMs = currentTime.getTime() - createdAtDate.getTime();

        // Check if the difference is less than or equal to 10 minutes (600,000 milliseconds)
        const isWithinLast10Minutes = differenceInMs <= 600000;
        return isWithinLast10Minutes ? true : false;
    }


    isFromDateRange(data: ExpoDataModel) {
        // Convert the timestamp string to a Date object
        const createdAtDate = new Date( data.created_at);
        // console.log('data was created at', createdAtDate)

        // Get the current date
        const currentDate = new Date();
        // console.log('current date', currentDate);

        // Set the time to just before midnight of today for comparison
        currentDate.setHours(23, 59, 59, 0);

        // Calculate the end date of the range by adding days to the current date
        const startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - this.dashboardConfig.showNumberOfDays);

        // Check if the createdAtDate is within the range
        const isWithinRange = createdAtDate >= startDate && createdAtDate <= currentDate;

        return isWithinRange;
    }

    // isFromToday(data: ExpoDataModel) {
    //     // Convert the timestamp string to a Date object
    //     const createdAtDate = new Date( data.created_at);

    //     // Get the current date
    //     const currentDate = new Date();

    //     // Check if the createdAtDate is from today
    //     const isToday = createdAtDate.toDateString() === currentDate.toDateString();

    //     return isToday ? true : false;
    // }


    triggerUpdate() {
        console.log("refresh data")
        this.refreshData();
    }


    refreshData() {
        this.getData(this.queryParams);
    }

    openDashModal(expoData: ExpoDataModel): void {

        const data = {
            "expoData": expoData
        };

        const dialogRef = this.dialog.open(ExpoDashboardModal, {
            panelClass: ['pipcall-modal-container'],
            width: '500px',
            data
        });

        dialogRef.componentInstance.updateEmit.subscribe((resp) => {
            console.log("update emit");
            if (resp === true) {
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            console.log("DASHBOARD CLOSED", result);
            if (result === true) {
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });
    }


    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }


    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        window.removeEventListener('scroll', this.scroll, true);
    }
}
