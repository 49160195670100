import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//tabs
import {LicenceDetailsTabOverviewModule} from '@app/components/tabs/licence-details-overview/licence-details-overview.module';
import {LicenceDetailsTabSystemModule} from '@app/components/tabs/licence-details-system/licence-details-system.module';
//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
// import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import {LicenseService} from '@app/services/pipcall/license.service'

//declarations
import { LicenceBottomSheetComponent } from './licence-bsheet-modal.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FlexLayoutModule,
        FormBasicControlsModule,
        LicenceDetailsTabOverviewModule,
        LicenceDetailsTabSystemModule,
        UniversalComponentsModule
    ],
    declarations: [LicenceBottomSheetComponent],
    providers: [HttpProxy, AppInsightService, AppTitleService, LicenseService, OrganisationService],
    exports: [],
    entryComponents: []
})
export class LicenceBottomSheetModule {
}
