//core
import { Component, Input, SimpleChanges, OnChanges  } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'status-icons',
    templateUrl: './status-icons.component.html',
    styleUrls: ['./status-icons.component.scss'],
})
export class StatusIconsComponent {

    @Input() iconStatus: string;
    @Input() hasPrefix: boolean;
    //null //pending //success //warning //error

    // @Input() touched: boolean;
    //true or false

    public changeLog;

    constructor() {
    }


    ngOnInit() {
    }



    // ngOnChanges(changes: SimpleChanges) {
    //     for (let propName in changes) {
    //         let chng = changes[propName];
    //         let cur  = JSON.stringify(chng.currentValue);
    //         let prev = JSON.stringify(chng.previousValue);
    //         console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`)

    //       }
    // }

}
