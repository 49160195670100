import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@app/material/material-essentials.module';


import { MAT_DIALOG_DATA, MatDialog } from '@app/material/material-essentials.module';
import { FormGroup, Validators, FormBuilder, FormControl, Form, AbstractControl } from '@angular/forms';

@Component({
    templateUrl: './card-container-template.component.html',
    styleUrls: ['./card-container-template.component.scss']
})
export class CardContainerTemplateComponent implements OnInit {


    constructor(
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder ) {

    }

    ngOnInit() {
    }

    ngAfterContentInit() {

    }



}
