import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule } from '@app/pipes/pipes.module'
//services
import { HttpProxy } from '@app/services/http-proxy.service';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import {  RestoreDeletedInvoiceModalComponent } from './restore-deleted-invoice-modal.component';
import { InvoiceService } from '@app/services/pipcall/invoice.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        CustomPipesModule,
        FlexLayoutModule,
        StatusIconsModule
    ],
    declarations: [RestoreDeletedInvoiceModalComponent],
    exports: [RestoreDeletedInvoiceModalComponent],
    providers: [HttpProxy, InvoiceService, ],
    entryComponents: [RestoreDeletedInvoiceModalComponent]
})
export class RestoreDeletedInvoiceModalModule { }
