import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule} from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module'

//services
import {SharedModule} from '@app/services/shared-services.module'
import {AreacodeListSelectComponent} from './ff-areacode-list-select.component';


@NgModule({
    imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    CustomPipesModule,
    RouterModule,
    SharedModule
    ],
    declarations: [ AreacodeListSelectComponent],
    exports: [AreacodeListSelectComponent],
    providers: [],
    entryComponents:[]
})
export class AreacodeListSelectModule { }