import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components
import { UpdateUserProfilePictureModalComponent } from './update-userprofile-picture-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout'

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule,
        FlexLayoutModule
    ],
    declarations: [UpdateUserProfilePictureModalComponent],
    exports: [UpdateUserProfilePictureModalComponent],
    providers: [AppInsightService, AuthService],
    entryComponents: [UpdateUserProfilePictureModalComponent]
})
export class UpdateUserProfilePictureModalModule { }
