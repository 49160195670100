import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
// import { responseModel } from '../../models/response.model';

export class DirectoryType {
    id: string;
    name: string;
}
export class OrganisationDirectory {
    id: string;
    organisation_id: string;
    directory_type_id: string;
    directory_type_name: string;
    priority: number;
    configuration_json: string;
    touched?: boolean;
}
@Injectable()
export class DirectoryService {
    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`
    public options;

    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }
    // Observable<HttpResponse<Array<Article>>>

    public getListOfDirectoryTypes(): Observable<HttpResponse<Array<DirectoryType>>> {
        console.log("[[api]] -> get list of directory types");
        return this.httpProxy.get(this.ApiBaseUrl + `/directory_types${this.code}`, 'response', true);
    }

    public getListOfDirectoryByOrg(organisation_id: string): Observable<HttpResponse<Array<OrganisationDirectory>>> {
        console.log("[[api]] -> get list of organisation directories", organisation_id);
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${organisation_id}/directories${this.code}`, 'response', true);
    }

    public updateOrganisationDirectory(id: string, priority: number, configuration_json: string) {
        const body = {
            priority: priority,
            configuration_json: configuration_json
        }
        console.log("[[api]] -> put organisation directory");
        return this.httpProxy.put(this.ApiBaseUrl + `/organisations/directories/${id}${this.code}`, body, 'response', true);
    }


    public postOrganisationDirectory(organisation_id: string, directory_type_id: string, priority: number, configuration_json: string) {
        const body = {
            priority: priority,
            configuration_json: configuration_json,
            directory_type_id: directory_type_id
        }
        console.log("[[api]] -> post new organisation directory");
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${organisation_id}/directories${this.code}`, body, 'response', true);
    }

    public deleteDirectoryConnection(id: string): Observable<HttpResponse<any>> {
        console.log("[[api]] -> delete directory");
        return this.httpProxy.delete(this.ApiBaseUrl + `/organisations/directories/${id}${this.code}`, 'response', true);
    }

}
