//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { AppInsightService } from '@app/services/helpers/app-insights.service';

import { PBXService } from '@app/services/pipcall/pbx.service';
import { NumberService } from '@app/services/pipcall/number.service';
import { MigratePBXModalModal } from './migrate-option-modal/migrate-pbx-modal.component';

@Component({

    selector: 'app-org-pbx-migration-panel-panel',
    templateUrl: './org-pbx-migration-panel.component.html',
    styleUrls: ['./org-pbx-migration-panel.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class  OrgPbxMigrationPanelComponent {


    @Input() viewAs: string;
    @Input() organisation_id: string;
    @Input() state: string;

    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        errorMessage: ''
    }
    pbxSettingForm: FormGroup;
    currentPBXhost = '';
    currentTenant = '';


    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private appInsightService: AppInsightService,
        private numberService: NumberService,
        private pbxService: PBXService,
        private fb: FormBuilder,
        public dialog: MatDialog,
    ) {

        this.pageStatus.isLoading = true;
        this.pbxSettingForm = this.fb.group({
            currentPBXhost: ['', Validators.required],
            currentTenant: ['', Validators.required],
            organisation_id: ['', Validators.required],
        });
    }

    get f() { return this.pbxSettingForm.controls; }

    ngOnInit() {
        this.pageStatus.isLoading = false;
        this.setCurrentPBXhost(this.organisation_id)
        this.setFormValue(this.pbxSettingForm, 'organisation_id', this.organisation_id);
    }

    ngOnChanges(changes: SimpleChanges) {
    }


    setCurrentPBXhost(organisation_id: string) {
        console.log('OrgPbxMigrationPanelComponent init================', this.organisation_id);
        this.pageStatus.isSubmitting = true;
        this.numberService.getOrganisationPBXhost(organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("===get PBX host for org result===", resp?.body.data);
                const ddiList = resp?.body.data;
                ddiList.length > 0 ? this.currentPBXhost = ddiList[0].sip_host_name :  this.currentPBXhost = '';
                ddiList.length > 0 ? this.currentTenant = ddiList[0].sip_user_name.slice(0 , 3) :  this.currentTenant = '';

                this.setFormValue(this.pbxSettingForm, 'currentPBXhost', this.currentPBXhost);
                this.setFormValue(this.pbxSettingForm, 'currentTenant', this.currentTenant);
                console.log("RESULT: ", this.currentPBXhost, this.currentTenant);
                this.ref.detectChanges();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    migratePBXOptions() {

        const dialogRef = this.dialog.open(MigratePBXModalModal, {
            hasBackdrop: true,
            disableClose: false,
            data: {organisation_id: this.f.organisation_id.value, currentPBXhost: this.f.currentPBXhost.value, currentTenant: this.f.currentTenant.value},
            panelClass: ['pipcall-modal-container']
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                console.log("SAVE DIALOG CLOSED");
                //do something
                this.triggerUpdate();
            }
        });
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }


    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
