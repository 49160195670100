import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule } from '@app/pipes/pipes.module';

//create modal components
import { OrgPipnumberSelectComponent} from './ff-org-pipnumber-select.component'


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomPipesModule
    ],
    declarations: [OrgPipnumberSelectComponent],
    exports: [OrgPipnumberSelectComponent],
    providers: [HttpProxy],
    entryComponents: []
})
export class OrgPipnumberSelectModule { }
