<ng-container *ngIf="user_id || userProfile; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">




      <ng-container *ngIf="usersPiPmobileList?.length > 0; else nonumbers">

        <div class="section-wrapper">

          <div class="section-content">

            <!-- add a number select option here if more then 1 number . ensure usage panel refresh-->

            <mat-form-field appearance="outline" fxFlex="auto" *ngIf="selectedPiPmobileNumber" class="custom-form-field-v3">
              <mat-select placeholder="number" [(ngModel)]="selectedPiPmobileNumber" >
                <mat-option *ngFor="let num of usersPiPmobileList"   [value]="num.number">
                  {{num.number | normalizeToInternational}}</mat-option>
                </mat-select>
                <mat-label>Number</mat-label>
              </mat-form-field>
              
              
            </div>
            
            
          </div>
          
          
          <div class="section-divider"></div>
          
          <app-number-usage-panel  [mode]="'user'" [organisation_id]="organisation_id" [service_identifier]="selectedPiPmobileNumber" 
          [viewAs]="viewAs">
        </app-number-usage-panel>
        
        <div class="section-divider"></div>
        

        
        <app-number-sms-usage-panel [mode]="'number'" [organisation_id]="organisation_id" [service_identifier]="selectedPiPmobileNumber" [viewAs]="viewAs"></app-number-sms-usage-panel>

      </ng-container>


      <ng-template #nonumbers>

        <div class="section-wrapper">

        <div class="section-title" fxLayoutAlign="space-between start">
          <div fxLayout="row" fxLayoutAlign="start center">Usage
          </div>
        </div>

        <div class="section-content">
          <p>The user has no active numbers with usage information.</p>
          <p *ngIf="organisationProfile?.is_pipxt_enabled"><strong>Note:</strong> Mobile+ users will not see usage in this panel.</p>
        </div>

      </div>
      </ng-template>

    
  </div>

  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>
