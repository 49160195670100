import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';



//tabs
import { OrgTabOverviewModule } from '@app/components/tabs/org-overview/org-overview.module';
import { OrgTabAminsModule } from '@app/components/tabs/org-admins/org-admins.module';
import { OrgTabBillingModule } from '@app/components/tabs/org-billing/org-billing.module';
import { OrgTabNumbersModule } from '@app/components/tabs/org-pipmobile-numbers/org-pipmobile-numbers.module';
import { OrgTabUserslistModule } from '@app/components/tabs/org-users-list/org-users-list.module';
import { OrgTabLicencesModule } from '@app/components/tabs/org-licences/org-licences.module';
import { OrgTabSytemModule } from '@app/components/tabs/org-system/org-system.module';
import { OrgTabExtensionsModule } from '@app/components/tabs/org-pipoffice-extensions/org-pipoffice-extensions.module';
import { OrgTabGetStartedModule  } from '@app/components/tabs/org-get-started/org-get-started.module';
import { OrganisationAdminControlsModule } from '@app/components/admin-controls/organisation-admin-controls/organisation-admin-controls.module'


//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

//declarations
import { OrganisationProfileBottomSheetComponent } from './organisationprofile-bsheet-modal.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FlexLayoutModule,
        FormBasicControlsModule,
        OrgTabOverviewModule,
        OrgTabAminsModule,
        OrgTabBillingModule,
        OrgTabNumbersModule,
        OrgTabUserslistModule,
        OrgTabLicencesModule,
        OrgTabSytemModule,
        OrgTabExtensionsModule,
        OrgTabGetStartedModule,
        OrganisationAdminControlsModule
    ],
    declarations: [OrganisationProfileBottomSheetComponent],
    providers: [HttpProxy, AppInsightService, AppTitleService, UserService, OrganisationService],
    exports: [OrganisationProfileBottomSheetComponent],
    entryComponents: []
})
export class OrganisationProfileBottomSheetModule {
}
