import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components

import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout'

import { AdminUpgradeToPipuserModuleComponent } from './admin-upgrade-to-pipuser-modal.component';
import { AdminUpgradeToPipuserdModule } from '@app/components/admin-upgrade-to-pipuser/admin-upgrade-to-pipuser.module';

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule,
        FlexLayoutModule,
        AdminUpgradeToPipuserdModule
    ],
    declarations: [AdminUpgradeToPipuserModuleComponent],
    exports: [AdminUpgradeToPipuserModuleComponent],
    providers: [AppInsightService, AuthService],
    entryComponents: [AdminUpgradeToPipuserModuleComponent]
})
export class AdminUpgradeToPipuserModulelModule { }
