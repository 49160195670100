
//core
import { Component, OnInit, Inject, isDevMode, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { HttpClient } from '@angular/common/http';
import { PBXService } from '@app/services/pipcall/pbx.service';
import { PBXHostListModel, TenantListModel, PBXServicePlan } from '@app/services/pipcall/pbx.service';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { OrganisationService} from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil, map } from 'rxjs/operators';

import { environment } from '@src/environments/environment';

@Component({
    templateUrl: './migrate-pbx-modal.component.html',
    styleUrls: ['./migrate-pbx-modal.component.scss'],
    animations: [
    ]
})
export class MigratePBXModalModal {



    // public organisationName: string;
    pbxHostList: PBXHostListModel[] = [];
    pbxServicePlanList: PBXServicePlan[] = [];

    newHostForm: FormGroup;

    allowedOriginHosts = ['mt4.teliqodirect.com'];
    allowedDestinationHosts = ['pbx01.pipoffice.com'];

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        errorMessage: ''
    }

    migrationAllowed = false;

    constructor(
        private dialogRef: MatDialogRef<MigratePBXModalModal>,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private pbxService: PBXService,
        private organisationService: OrganisationService,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public data: {'organisation_id': string, 'currentPBXhost': string, 'currentTenant': string }, //contains data injected into modal.
    ) {
        dialogRef.disableClose = true;
        this.newHostForm = this.fb.group({
            organisation_id: [data.organisation_id, Validators.required],
            new_pbx_id: ['', Validators.required],
            service_plan: ['', Validators.required],
        });
    }

    get f() { return this.newHostForm.controls; }

    ngOnInit() {
        this.f.new_pbx_id.valueChanges
            .subscribe((res) => {
                console.log("valuec changed")
                this.getPbxServicePlanList(res);
            }, (err) => {
                console.log(err);
                this.openSnackBar(`${err.error} [${err.status}]`, "dismiss");
            })
        this.migrationAllowed = this.allowedOriginHosts.includes(this.data.currentPBXhost) ? true : false;


    }

    ngAfterViewInit() {
        this.pbxService.getPBXList()
            .pipe(
                map((value) => this.filterToAllowed(value))
            )
            .subscribe((res) => {
                this.pbxHostList = res;
                if (res.length === 1) {
                    this.f.new_pbx_id.patchValue(res[0].id);
                }
            }, (err) => {
                this.openSnackBar(`Error getting PBX list [${err.status}]`, "dismiss");
            })

        if (environment.trialExtensionsStore === this.data.organisation_id) {
            alert('WARNING: Migration from the holding organisation is not permitted. This could break a lot of things.. turn back now');
            this.migrationAllowed = false;
        }
    }

    filterToAllowed(resp: any) {
        let filtered = new Array<PBXHostListModel>();
        filtered = [];
        if (resp.status === 200 && resp.body !== null) {
            resp.body?.forEach(pbx => {
                if (this.allowedDestinationHosts.includes(pbx.host) && pbx.host !== this.data.currentPBXhost) {
                    filtered.push(pbx);
                }
            })
        } else {
            return filtered;
        }
        return filtered;
    }

    getPbxServicePlanList(pbxhostID: string) {
        this.pbxService.getServicePlanList(pbxhostID)
            .subscribe((res) => {
                this.pbxServicePlanList = [...res?.body]
            }, (err) => {
                console.log(err);
                this.openSnackBar(`${err.error} [${err.status}]`, "dismiss");
            })
    }
    migrateNow(f) {
        console.log('migrate now', f.value);
        if (!f.valid) {return; }

        this.pageStatus.isSubmitting = true;
        this.organisationService.patchMigratePBX(f.value.organisation_id, f.value.new_pbx_id, f.value.service_plan)
            .subscribe((res) => {
                this.openSnackBar('Migration successful', 'dismiss');
                this.pageStatus.isSubmitting = false;
                this.closeModal(true);
            }, (err) => {
                console.log(err);
                this.pageStatus.isSubmitting = false;
                this.openSnackBar(`${err.error} [${err.status}]`, "dismiss");
            })
    }

    closeModal(update?: boolean) {
        update = update ? true : false;
        this.dialogRef.close(update);
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
}
