//core
import { Component, ViewChild, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { SelectedLicenceModel} from '@app/models/license.model';
@Component({
    selector: 'app-select-package-modal',
    templateUrl: './select-package-modal.component.html',
    styleUrls: ['./select-package-modal.component.scss'],
    animations: [

    ]
})
export class SelectPackageModalComponent {


    @ViewChild(SelectPackageModalComponent) pricingModalComponent: SelectPackageModalComponent;

    showApplyToAll = false;
    applyToAll: false;

    public isEnabled = {
        talk: true,
        talk_and_text: true
    }

    constructor(
        private dialogRef: MatDialogRef<SelectPackageModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
    ) {

    }

    ngOnInit() {
        if (this.data?.mode && this.data?.mode === 'checkout') {
            this.showApplyToAll = true;
        }

    }


    closeModal() {
        this.dialogRef.close();
    }

    selectLicence(licence: SelectedLicenceModel) {
        console.log("LICENCE SELECTED:", licence, "Apply to all?: ", this.applyToAll);
        const output = {
            licence: licence,
            applyToAll: this.applyToAll
        }
        this.dialogRef.close(output);
    }

}
