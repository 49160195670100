import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';
import { ConfirmUserUnlinkModalModule } from '@app/components/modals/confirm-user-unlink-modal/confirm-user-unlink-modall.module';


//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { NumberService } from '@app/services/pipcall/number.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';

//declarations
import { UserAdminControlsComponent } from './user-admin-controls.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FlexLayoutModule,
        FormBasicControlsModule,
        ConfirmUserUnlinkModalModule
    ],
    declarations: [UserAdminControlsComponent],
    providers: [HttpProxy, AppInsightService, AppTitleService, NumberService, LicenseDataService,  ModalService, UserService, OrganisationService],
    exports: [UserAdminControlsComponent],
    entryComponents: [UserAdminControlsComponent]
})
export class UserAdminControlsModule {
}
