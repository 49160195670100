import { Component, Input } from '@angular/core';

@Component({
    selector: 'tab-title-lined',
    templateUrl: './tab-title-lined.component.html',
    styleUrls: ['./tab-title-lined.component.scss']
})
export class TabTitleLinedComponent {
    @Input() showLine = true;
}
