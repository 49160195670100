<ng-container [ngSwitch]="version">
  <!-- new implemenation table view -->
  <ng-container *ngSwitchCase="1">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="cto-row-container">
      <div class="avatar-container av-size-30">
        <img appGravatar [firstname]="first_name" [lastname]="last_name" [email]="email">
      </div>

      <div fxLayout="column" fxLayoutAlign="center start">

        <div class="hover-to-show cto-top-row-wrapper clickable" >

          <a  class="overflow-elipsis cto-max-width" style="color: var(--c-url);" href="/admin/user/{{user_id}}/profile" matTooltipPosition="below" matTooltipShowDelay="200"
          matTooltip="Open {{first_name}}">{{first_name}}&nbsp;{{last_name}}</a>
          
          <mat-icon (click)="openUsernDetails(user_id, viewAs, organisation_id)" matSuffix svgIcon="view"
          (click)="$event.stopPropagation()" class="clickable action-icon" matTooltipPosition="below"
          matTooltipShowDelay="200" matTooltip="Quick view"></mat-icon>

          <mat-icon *ngIf="user_id" (click)="impersonateUser(user_id)" matSuffix svgIcon="impersonate"
          (click)="$event.stopPropagation()" class="clickable action-icon" matTooltipPosition="below"
          matTooltipShowDelay="200" matTooltip="Impersonate user"></mat-icon>
        </div>
        
        <div *ngIf="email && !hide_detail" class="cto-bottom-row-wrapper" >
          <span class="overflow-elipsis cto-bottom-row-content cto-max-width cto-wd-230"
          [matTooltip]="email">{{email}}&nbsp;</span>
        </div>
      </div>

    </div>
  </ng-container>


  <ng-container *ngSwitchDefault>

    <!-- <div fxLayout="row" fxLayoutAlign="start center">

      <div class="avatar-container av-size-38" [ngClass]="{'av-size-48':organisation_name}">
        <img appGravatar [firstname]="first_name" [lastname]="last_name" [email]="email">
      </div>

      <div fxLayout="column" style="padding-left:12px;">

        <div style="color: var(--c-url); white-space: nowrap;" class="hover-to-show-copy">

          <span (click)="openUsernDetails(user_id, viewAs, organisation_id)"
            class="clickable overflow-elipsis max-width-name"
            style="color: var(--c-url)">{{first_name}}&nbsp;{{last_name}}</span>

          <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
            class="icon-14" svgIcon="block" matTooltip="This user is blocked"></mat-icon>

          <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(user_id)" class="clickable copy-icon"
            matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy">
          </mat-icon>

        </div>

        <div *ngIf="email">
          <span class="max-width-name overflow-elipsis" style="opacity: 0.6;font-size:0.9em;max-width: 230px;"
            [matTooltip]="email">{{email}}&nbsp;</span>
        </div>

        <div *ngIf="organisation_name" class="hover-to-show-copy">
          <span (click)="openOrgDetails(organisation_name, organisation_id, viewAs)"
            style="max-width: 230px;color: var(--c-url);font-size:0.9em;" class="max-width-name overflow-elipsis"
            [matTooltip]="organisation_name">{{organisation_name}}&nbsp;</span>
          <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(organisation_id)" class="clickable copy-icon"
            matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy">
          </mat-icon>
        </div>

      </div>
    </div> -->
  </ng-container>
</ng-container>