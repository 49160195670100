import {Component, TemplateRef, ViewChild, Inject, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import { MatSnackBar } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { OrganisationTableModel } from '@app/services/pipcall/organisation.service';
import { OrganisationModel } from '@app/models/organisation.model';
import { ConfirmBillingStateModalService} from '@app/components/modals/confirm-billing-state-modal/confirm-billing-state-modal.service';
import { ConfirmBillingStateModalData } from '@app/components/modals/confirm-billing-state-modal/confirm-billing-state-modal.component';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';
import { DdiNumber } from '@app/models/ddi.model';

@Component({
    selector: 'matmenu-organisation-admin-controls',
    templateUrl: './organisation-admin-controls.component.html',
    styleUrls: ['./organisation-admin-controls.component.scss']
})
export class OrganisationAdminControlsComponent {


    // @Input() orgProfile: any;
    @Input() is_blocked: boolean;
    @Input() is_auto_billing_enabled: boolean;
    @Input() organisation_id: string;
    @Output() closeModal = new EventEmitter<boolean>();
    @Output() _refreshData = new EventEmitter<boolean>();

    public viewAs: string; //owner,admin or user. set to owner if matches on return of data.


    public pageStatus = {
        isLoading: true,
        isSubmitting: false
    }
    _destroy$ = new Subject<void>();


    constructor(
        private organisationService: OrganisationService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        private confirmBillingStateModalService: ConfirmBillingStateModalService,
    ) {

    }

    ngOnInit() {
        // handle missing data
        console.log('[Organisation Admin controls].ngOnit()', this.organisation_id)
        this.ref.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        changes['organisation_id']?.currentValue !== changes['organisation_id']?.previousValue ?  this.ref.detectChanges() : null;
        changes['is_auto_billing_enabled']?.currentValue !== changes['is_auto_billing_enabled']?.previousValue ?  this.ref.detectChanges() : null;
        changes['is_blocked']?.currentValue !== changes['is_blocked']?.previousValue ?  this.ref.detectChanges() : null;
    }



    confirmBillingState( organisation_id: string, currentState?: boolean) {
        const data = new ConfirmBillingStateModalData;
        data.isBillingEnabled = currentState;
        data.organisation_id = organisation_id;

        this.confirmBillingStateModalService.openConfirmBillingStatetModal(data, (response: boolean) => {
            if (response) {
                this.openSnackBar('Saved', 'dismiss');
                this.refreshData();
            }
        });

    }
    unblockOrganisation(id) {
        this.submitForm(this.organisationService.patchOrganisationBlocked(id, false));
    }
    blockOrganisation(id) {
        this.submitForm(this.organisationService.patchOrganisationBlocked(id, true));
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.refreshData();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    refreshData(event?) {
        console.log("refresh data EVENT:", event);
        this._refreshData.emit(true);
    }

}

