//core
import { Component, OnInit, Input, isDevMode, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { VerifyService } from '@app/services/pipcall/verify.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { AuthService } from '@app/services/auth-service/auth.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { UserService} from '@app/services/pipcall/user.service';

@Component({
    selector: 'verify-mobile-v3-modal',
    templateUrl: './verify-mobile-v3-modal.component.html',
    styleUrls: ['./verify-mobile-v3-modal.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class VerifyMobileV3ModalComponent {

    @Output() verifiedEmit = new EventEmitter;
    @Output() resendVerifyCode = new EventEmitter; //parent should resend , different for invitation vs admin upgrade

    verificationForm: FormGroup;

    pageStatus = {
        isSubmitting: false,
        resendDisabled: true
    }

    error = {
        isError: false,
        message: ''
    }

    markAsVerified = false;

    sendingMessage: string;
    @ViewChild(VerifyMobileV3ModalComponent) verifyMobileV3ModalComponent: VerifyMobileV3ModalComponent;

    constructor(
        private dialogRef: MatDialogRef<VerifyMobileV3ModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {isOwnerUpdate: boolean, handset: string, user_id: string}, //contains data injected into modal.
        private verifyService: VerifyService,
        private normalizeNumberService: NormalizeNumberService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private _formBuilder: FormBuilder,
        private ref: ChangeDetectorRef,
        private appInsightService: AppInsightService,
        private userService: UserService,
        private snackBar: MatSnackBar) {

        this.verificationForm = this._formBuilder.group({
            user_id: ['', [Validators.required]],
            code: ['',  {updateOn: 'change', validators: [Validators.required]}],
        })
    }


    ngOnInit() {
        this.setFormValues();
        this.disableResendButton(); //disable ofr inital 6 seconds to avoid unwanted presses
    }

    ngAfterViewInit() {

    }

    setFormValues() {
        this.setFormValue(this.verificationForm, 'user_id', this.data?.user_id);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }
    // this called every time when user changed the code
    onCodeChanged(code: string) {
        console.log("code changed", code);
    }

    // this called only if user entered full code
    onCodeCompleted(code: string) {
        this.verificationForm.controls.code.patchValue(code);
        this.verifyCode(this.verificationForm)
    }

    sendCode(): void {
        this.sendingMessage = 'Sending...'
        this.resendVerifyCode.emit(true);

        setTimeout(() => {
            this.sendingMessage = 'Sent!'
        }, 400);

        setTimeout(() => {
            this.sendingMessage = ''
        }, 800);
        this.disableResendButton();
        // this.submitted2 = true; ///change logic behind this. maybe disable for settimeout?
        // this.disableResendButton();
        // const val = { id: id, mobile: mobile }
        // this.triggerSMS.emit(val);
        // this.triggerSentAnimation();
        // this.submitted2 = false;
    }

    verifyCode(f: FormGroup): void {

        this.pageStatus.isSubmitting = true;
        this.userService.postOwnerUpgradeVerify(f.value.user_id, f.value.code)
            .pipe(
                finalize(() => this.pageStatus.isSubmitting = false)
            )
            .subscribe(resp => {
                console.log('verify code', resp);
                if (resp.status === 200 || resp.status === 201) {
                    const verifyResponse = resp.body;

                    if (verifyResponse?.bool === true) {

                        //CONTINUE HERE
                        this.markAsVerified = true;
                        this.verifiedEmit.emit(true);

                    } else if (verifyResponse.bool === false) {
                        this.error.message = 'The code was incorrect';
                        this.error.isError = true;
                        this.ref.detectChanges();
                        console.log("THERE WAS AN ERROR", this.error);
                    }
                }
            }, (err) => {
                console.log(err);
                this.error.message = err?.error  ? err.error : 'An error occured';
                // this.error.message = err?.error?.Message  ? err.error?.Message : 'An error occured';
                this.error.isError = true;
            })

    }


    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                // this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    downloadiosApp() {
        this.appInsightService.logEvent('Download ios clicked', { 'userid': this.authService.getUserId() });
        (window as any).open('https://itunes.apple.com/app/apple-store/id1410293712?pt=1588242&ct=Trialists%20App%20Download&mt=8', "_blank");
    }

    downloadAndroidApp() {
        this.appInsightService.logEvent('Download Android clicked', { 'userid': this.authService.getUserId() });
        (window as any).open('https://play.google.com/store/apps/details?id=com.teliqo.pipcall', "_blank");
    }

    disableResendButton(): void {
        //disable button for 4 seconds
        this.pageStatus.resendDisabled = true;
        this.ref.markForCheck();
        setTimeout(() => {
            this.pageStatus.resendDisabled = false;
        }, 6000);
    }

    closeModal() {
        this.dialogRef.close(this.markAsVerified);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 8000,
        })
    }

}
