//core
import { Component, OnInit, Inject, isDevMode, ChangeDetectorRef, SimpleChanges, HostListener, ViewChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { map, tap, filter, catchError, mergeMap, finalize, first } from 'rxjs/operators';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'
import { CurrencyPipe } from '@angular/common'
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { LicenseModel, Licensedata, ViewLicenseModel } from '@app/models/license.model';
import { isNgTemplate } from '@angular/compiler';
import { LicenseDataService, LicenseType, ContractType } from '@app/services/shared-data.service/license-data.service';
@Component({
    selector: 'app-license-select',
    templateUrl: './license-select.component.html',
    styleUrls: ['./license-select.component.scss'],
    animations: [

    ]
})
export class LicenseSelectComponent {

    // @Input() tariff:any;

    // @Input() disableForm:boolean;
    @Input() existingLicense: LicenseModel; //if the user already has an existing License (edit mode);
    @Input() _existingLicense: ViewLicenseModel; //if the user already has an existing License (edit mode) now using ViewLicenseModel in some places;
    @Input() mode: string;  //'invitation' | 'edit' | 'checkout' | 'activate;
    @Input() isTrialist: boolean; //trialist i.e checkout form
    @Input() disabled: boolean;
    //@Input() _selectedLicenseType: string; //type of selected number (invitation route only);
    @Input() isMobileTypeSelected: boolean; //is the selected type on form a mobile number. (e.g invitation form)
    @Input() _selectedContractType: string;
    @Output() _selectedLicense = new EventEmitter();


    isLicenseLoaded: boolean;
    activeLicenseList: LicenseModel[];
    //form values
    selectedLicenseType: string;
    selectedContractType: string;
    selectedContractTypeHierachy: number;

    licenseOptions: LicenseType[];
    contractOptions: ContractType[];
    // licenseCostPounds:string = '0';
    // licenseCostPence:string = '00';
    // selectedLicenseId:string;
    licenseTypeSubscription: Subscription;
    constructor(
        private currencyPipe: CurrencyPipe,
        private paymentsService: PaymentsService,
        private licenseDataService: LicenseDataService) {
        this.isLicenseLoaded = false;
        this.activeLicenseList = new Array<LicenseModel>();
        this.selectedContractTypeHierachy = 0;
        // this.licenseOptions = Licensedata.licenseOptions;
        // this.contractOptions = Licensedata.contractOptions;
        this.contractOptions = new Array<ContractType>();
        this.licenseOptions = new Array<LicenseType>();
        this.licenseDataService.innit();
    }

    ngOnInit() {
        if (this._existingLicense) {
            this.mapToExistingLicenseModel(this._existingLicense);
        }

        //set active Licenses
        this.licenseDataService.activeLicenseListAsObservable.subscribe((activeLicenseList) => {
            activeLicenseList ? this.activeLicenseList = activeLicenseList : this.isLicenseLoaded = false;
        });

        this.licenseDataService.contractTypesAsObservable.subscribe((contractTypes) => {
            contractTypes ? this.contractOptions = contractTypes : null;
        })

        this.licenseTypeSubscription = this.licenseDataService.licenseTypesAsObservable.subscribe((licenseTypes) => {
            console.log("LICENSE TYPES:", licenseTypes);
            licenseTypes ? this.licenseOptions = licenseTypes : null;
        })

        //check for existing selection heirachy
        this._selectedContractType ? this.selectedContractTypeHierachy = this.contractOptions.find(item => item.value === this._selectedContractType).hierarchy : null;
        console.log("selected contract heirachy:", this.selectedContractTypeHierachy);
        console.log(" this.contractOptions", this.contractOptions);


        console.log("EXISTING LICENSE:", this.existingLicense);
        this.existingLicense ? this.isMobileTypeSelected = this.isMobileLicense(this.existingLicense.type) : null;


        //set existing license
        console.log("SET LICENSE DROPDOWNS");
        this.selectedLicenseType = this.existingLicense?.type;
        this.selectedContractType = this.existingLicense?.contract_type;
    }

    mapToExistingLicenseModel(vl: ViewLicenseModel) {
        const l = new LicenseModel();
        l.id = vl.license_id;
        l.name = vl.license_contract_name;
        l.contract_name = vl.license_contract_name;
        l.contract_type = vl.license_contract_type;
        l.price = vl.license_price;
        l.type = vl.license_type;
        l.valid_from = vl.licensed_at;
        // l.valid_to =
        l.version_number = vl.license_version_number;

        this.existingLicense = l;
    }

    ngOnChanges(changes: SimpleChanges) {

        changes['isMobileTypeSelected']?.currentValue !== changes['isMobileTypeSelected']?.previousValue ? this.selectedLicenseType = changes['_selectedLicenseType']?.currentValue : null; //reset the selected license if type has changed
        this.isTrialist ? this.selectedContractType = 'trial' : null;
    }

    selectionChange(event) {
        const _item = this.activeLicenseList.find(
            item =>
                item.type === this.selectedLicenseType && item.contract_type === this.selectedContractType
        );
        _item ? this.emitValue(_item) : this.emitValue(null);
    }


    emitValue(selectedLicense) {
        if (selectedLicense === null) {
            const output = { id: '', license_type: this.selectedContractType, price: 0.00, display: '' };
            this._selectedLicense.emit(output);
        } else if (this.mode === 'edit' && this.existingLicense.contract_type === this.selectedContractType && this.existingLicense.type === this.selectedLicenseType) {
            //dont emit a selection if the same license type is returned.
            const output = { id: '', license_type: this.selectedContractType, price: 0.00, display: '' };
            this._selectedLicense.emit(output);
        } else {
            const output = { id: selectedLicense.id, license_type: selectedLicense.type, price: selectedLicense.price, display: selectedLicense.name };
            this._selectedLicense.emit(output);
        }
    }


    // returnLandlineOrMobile(selectedType: string) {
    //     //this filter does not require 'basic' context. remove it.
    //     return selectedType ? selectedType.replace('basic_', '') : '';
    // }

    returnBasicOrStandard(selectedType: string) {
        return selectedType?.includes(LicenseDataService.isBasicIdentifier) ? 'basic' : 'standard';
    }

    isBasicLicense(license_type: string) {
        console.log("IS IT BASIC", license_type.includes(LicenseDataService.isBasicIdentifier));
        return license_type.includes(LicenseDataService.isBasicIdentifier);
    }
    // isMobileLicense(license: LicenseModel) {
    //     return license.type.includes(LicenseDataService.isMobileIdentifier);
    // }

    isMobileLicense(license_type: string) {
        return license_type.includes(LicenseDataService.isMobileIdentifier);
    }

    selectedTypeHierachy(license_type: string) {
        return license_type.includes(LicenseDataService.isBasicIdentifier) ? 0 : 1;
    }

    ngOnDestroy() {
        this.licenseTypeSubscription?.unsubscribe();
    }
}
