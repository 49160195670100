//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { UserProfile} from '@app/models/user-profile.model';

@Component({
    selector: 'app-org-tabs-admins',
    templateUrl: './org-admins.component.html',
    styleUrls: ['./org-admins.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OrgTabAdminsComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional
    @Input() organisationUserList: Array<UserProfile>
    @Input() organisation_id: string;


    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }



    //forms
    userAdminForm: FormGroup;



    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
    ) {

        this.userAdminForm = this._formBuilder.group({
            organisation_id: [{ value: '', disabled: false }, Validators.required],
            user_id: ['', {updateOn: 'change', validators: [Validators.required]}]
        });

    }

    ngOnChanges(changes: SimpleChanges) {
        // changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setOrganisationNameFormValues(this.organisationProfile) : null;
        //handle changes to Admins here
    }



    ngOnInit() {

        if ( !this.organisationUserList ) {
            console.log("NO USER LIST FOUND GO GET")
            this.getDetails();
        } else {
            if ( !this.organisationProfile ) {
                console.log("NO ORG PROFILE - GO GETP")
                this.getDetails();
            }
        }

        if (this.organisation_id) {
            this.setFormValue(this.userAdminForm, 'organisation_id',   this.organisation_id);
        }

    }

    // setOrganisationNameFormValues(organisationProfile: OrganisationModel) {
    //     this.setFormValue(this.organisationNameForm, 'id', organisationProfile.id);
    //     this.setFormValue(this.organisationNameForm, 'name', organisationProfile.name);
    // }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;
        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .switchMap((resp) => {
                this.organisationProfile = resp.body;
                return this.organisationService.getOrgUsersById(this.organisationProfile.id);
            })
            .subscribe(resp => {
                this.organisationUserList = resp.body.user_list;
                this.pageStatus.isLoading = false
            },
            (err) => {
                this.pageStatus.isLoading = false
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })


    }



    // saveOrganisationName(f: FormGroup) {
    //     if (f.valid) {
    //         this.submitForm(this.organisationService.patchOrganisationName(f.getRawValue().id, f.getRawValue().name));
    //     }
    // }

    updateUserSelectedEvent(user: UserProfile) {
        console.log("=================///////////////////==================VALUE:", user.id);
        this.setFormValue(this.userAdminForm, 'user_id',   user.id);
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
