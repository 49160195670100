import { Injectable } from '@angular/core';
import { MatDialog } from '@app/material/material-essentials.module';
import { ConfirmBillingStateComponent, ConfirmBillingStateModalData } from './confirm-billing-state-modal.component';

@Injectable()
export class ConfirmBillingStateModalService {
    constructor(
        public dialog: MatDialog
    ) { }


    openConfirmBillingStatetModal(data: ConfirmBillingStateModalData, callBackFunction: Function, width?: string) {
    //choice true = yes & no , false = single option with callback

        const dialogRef = this.dialog.open(ConfirmBillingStateComponent, {
            width: width ? width : '380px',
            data,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => callBackFunction(result));
    }



}
