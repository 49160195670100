import { Component, OnInit, Input, isDevMode, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth.service';

import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { SignupService } from '@app/services/pipcall/signup.service';
import { finalize } from 'rxjs/operators';
//normalizing number
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { TitleCasePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
//forms
import { FormGroup, Validators, FormBuilder, ValidationErrors, FormControl, Form, AbstractControl, FormsModule } from '@angular/forms';

import { fadeInUpOnEnterAnimation, fadeInRightOnEnterAnimation, fadeOutLeftOnLeaveAnimation, fadeInLeftOnEnterAnimation, fadeOutRightOnLeaveAnimation } from 'angular-animations';
import { GA4Service } from '@app/services/google-analytics-service';


export class SignupResponse {
    organisation_signup_request_id: string;
    ddis?: string[];
    organisation_id: string;
    is_valid_code: boolean;
    is_code_expired: boolean;
    access_token: string;
    refresh_token: string;
}
@Component({
    selector: 'app-verify-signup',
    templateUrl: './verify-signup.component.html',
    styleUrls: ['./verify-signup.component.scss'],
    animations: [
        fadeOutLeftOnLeaveAnimation({ duration: 250, translate: '50%' }),
        fadeInRightOnEnterAnimation({ duration: 300, delay: 400, translate: '50%' }),
        fadeOutRightOnLeaveAnimation({ duration: 250, translate: '50%' }),
        fadeInLeftOnEnterAnimation({ duration: 300, delay: 400, translate: '50%' }),
        fadeInUpOnEnterAnimation({ anchor: 'enter' })
    ]
})
export class VerifySignupComponent implements OnInit {

    @Output() cancel = new EventEmitter();

    @Output() verified = new EventEmitter();

    @Input() password: string;
    @Input() signup_request_id: string;
    @Input() email: string;

    verificationForm: FormGroup;
    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        step: 0
    }

    public signUpResponse: SignupResponse;

    constructor(
        public dialog: MatDialog,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private appInsightService: AppInsightService,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private signupService: SignupService,
        private cookieService: CookieService,
        private titlecasePipe: TitleCasePipe,
        private sharedService: SharedService,
        private gA4Service: GA4Service
    ) {
        this.appTitleService.setTitle('PiPcall: Verify');
        this.appInsightService.logPageView('Signup: verify');
        this.verificationForm = this._formBuilder.group({
            password: ['', [Validators.required]],
            code: ['', [Validators.required]],
            signup_request_id: [, [Validators.required]]
        });

        this.signUpResponse = new SignupResponse();
    }


    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.verificationForm.controls['password'].patchValue(changes['password']?.currentValue);
        this.verificationForm.controls['signup_request_id'].patchValue(changes['signup_request_id']?.currentValue);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    clearAllData() {
        this.sharedService.clearSession();
        this.auth.clearCookieData();
        // this.cookieService.delete('viewAs', '/');
        this.cookieService.set('viewAs', '', -1, '/');
        localStorage.clear();
        sessionStorage.clear();
    }

    checkCookie(): void {
        // { 'userid': this.authService.getUserId() }
        if ( this.cookieService.check('referalcookie')) {

            //if the referal page cookie exists, log it
            const referalcookie = this.cookieService.get('referalcookie');
            const decodedQueryString = decodeURIComponent(referalcookie)
            const urlParams = new URLSearchParams(decodedQueryString);

            const refererJSON = {
                email: this.email,
                medium: urlParams.get('utm_medium'),
                campaign: urlParams.get('utm_campaign'),
                source: urlParams.get('utm_source') !== null ? urlParams.get('utm_source') : urlParams.get('referer'),
                term: urlParams.get('utm_term'),
                keywords: urlParams.get('hsa_kw'),
                raw: referalcookie
            }


            this.appInsightService.logEvent('Signup : referal', refererJSON);
        }
    }

    verifyCode() {
        this.clearAllData();
        this.pageStatus.isLoading = true;
        this.pageStatus.isError = false;
        // console.log(" this.verificationForm", this.verificationForm.value)
        this.signupService.postSignupVerificationCodeWithExtensions(this.signup_request_id, this.verificationForm.get('code').value, this.verificationForm.get('password').value)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false })
            )
            .subscribe(
                (resp) => {
                    console.log("VERIFY CODE RESPOSE:", resp);
                    if (resp.status === 200 || resp.status === 201) {
                        this.signUpResponse = resp.body;

                        if (this.signUpResponse.is_valid_code && !this.signUpResponse.is_code_expired && this.signUpResponse.access_token) {

                            const ddis = this.signUpResponse?.ddis; //this is an array of ddis that have been allocated to the organisation

                            const token = this.signUpResponse.access_token;
                            //replace by setting in the API instead
                            this.pageStatus.isSuccess = true;
                            this.gA4Service.sign_up_pipxt_stage2_complete();
                            this.appInsightService.logEvent('Signup : code verified', { 'email': this.email });
                            this.auth.setSession(token, this.signUpResponse?.refresh_token, resp.body?.id_token);
                            this.checkCookie();
                            setTimeout(() => {
                                // this.auth.setSession(token) ? this.navigateToHome(1500) : this.navigateToHome(3000);
                                this.verified.emit(true);
                            }, 5000);
                            //set delay to allow for db to catch up

                            return;
                        } else if (!this.signUpResponse.is_valid_code) {
                            //invalid code error
                            this.pageStatus.errorMessage = "Verification code is invalid";
                            this.pageStatus.isError = true;
                            return;
                        } else if (this.signUpResponse.is_code_expired) {
                            //code has expired
                            this.pageStatus.errorMessage = "Signup request has expired, please go back and resubmit the form";
                            this.pageStatus.isError = true;
                            return;
                        } else {
                            //generic error
                            this.pageStatus.errorMessage = "Something went wrong, signup aborted";
                            this.pageStatus.isError = true;
                            return;
                        }
                    }
                    return;
                },
                (err) => {
                    console.log("response to error", err);
                    if (err.status === 400) {
                        this.pageStatus.errorMessage = '400: There was a conflict when creating the account';
                        this.pageStatus.isError = true;
                        return;
                    } else {
                        this.pageStatus.errorMessage = "Something went wrong, signup aborted";
                        this.pageStatus.isError = true;
                        return;
                    }

                }
            )
    }

    navigateToHome(waittime: number) {
        setTimeout(() => {
            this.router.navigateByUrl('/manage/organisation');
        }, waittime);
    }

    cancelVerification() {
        this.cancel.emit(true);
    }
}
