import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@app/services/auth-service/auth.service';
import { SharedService} from '@app/services/shared-data.service/shared-data.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private sharedService: SharedService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const x = this.authService.isSysAdmin();

        if (x === false) {
            // this.router.navigate(['/accessdenied']);
            this.router.navigate(['/']);
        }

        this.sharedService.changeViewAsRole('sysAdmin');
        return x;
    }
}


