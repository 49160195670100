// eslint-disable-next-line @typescript-eslint/member-ordering
import { CountryCode } from 'libphonenumber-js';
import { Injectable } from '@angular/core';

export interface RegionCodes {
    code: CountryCode; //ISO 3166 2 character Code
    name: string; //country display value
    region: string; //pip region code e.g uk44
    viewValue: string;
    outOfCountryCode: string;
    internationalCode: string;
    internationalMobile: string[];
    displayFormat: string;
    signupAllowed: boolean;
    minMaxMobilelength: [number , number]
}
//length includes +44

// mobileDisplayFormat
// mobileMinMaxLength

// landLineDisplayFormat
// landlineMinMaxLength

export class AreaCodeHelper {

    static list: Array<RegionCodes> = [
        {
            code: null,
            name: "PiPcall reserved",
            viewValue: "UK",
            region: "pip28",
            outOfCountryCode: "0028",
            internationalCode: '28',
            internationalMobile: [],
            displayFormat: 'xxx xxxxxxx xxx',
            signupAllowed: false,
            minMaxMobilelength: [13, 13]
        },
        {
            code: "GB",
            name: "United Kingdom",
            viewValue: "UK",
            region: "uk44",
            outOfCountryCode: "0044",
            internationalCode: '44',
            internationalMobile: ['447'],
            displayFormat: 'xxx xxxx xxxxxx',
            signupAllowed: true,
            minMaxMobilelength: [13, 13]
        },
        {
            code: "ES",
            name: "Spain",
            viewValue: "ESP",
            region: "es34",
            outOfCountryCode: "0034",
            internationalCode: "34",
            internationalMobile: ['346', '347'],
            displayFormat: 'xx xxx xxx',
            signupAllowed: false,
            minMaxMobilelength: [11, 12]
        },
        {
            code: "IL",
            name: "Israel",
            viewValue: "ISR",
            region: "il972",
            outOfCountryCode: "00972",
            internationalCode: "972",
            internationalMobile: ['9725'],
            displayFormat: 'xxx xxx xxxx',
            signupAllowed: false,
            minMaxMobilelength: [13, 13]
        },
        {
            code: "US",
            name: "United States",
            viewValue: "US",
            region: "us1",
            outOfCountryCode: "001",
            internationalCode: "1",
            internationalMobile: ['12', '13', '14', '15', '16', '17', '18', '19'],
            displayFormat: 'xx xxx xxx xxxx',
            signupAllowed: false,
            minMaxMobilelength: [12, 12]
        },
    ];

    static normaliseNumberWithPlus(number: string, region?: string): string {

        if (number?.startsWith("+")) {

            return number;

        } else if (number.startsWith("00")) {

            return number.replace("00", "+");

        }

        return number;
    };


    static normaliseNumberWith00(number: string, region?: string) {
        //if region provided with a 07 style number then convert to out of country  format
        return number.replace(/\s+/g, '').replace('+', '00');
    }

    static getCountryByPhoneNumber(num: string): RegionCodes {
        //provide number with + or 00
        //returns first match from list

        const _num = num.replace(/\s+/g, '').replace('+', '00');

        if ( _num.startsWith('00')) {

            return this.list.find(n => _num.startsWith(n.outOfCountryCode));
        } else {

            return null
        }
    }

    static getCountryByRegionCode(region: string): RegionCodes {
        return this.list.find(n => n.region === region);
    }


    static returnAsInternationalDisplay(number: string): string {
        //provide +xx or 00
        const region = this.getCountryByPhoneNumber(number);
        number = number.replace(/\s+/g, '');
        if (!region) {
            if (number.startsWith('00') && number.length > 7) {
                return number.replace('00', '+');
            } else {
                return number;
            }
        } else {
            //format the number here
            const numberStripped = this.removePlusand00(number).slice(region.internationalCode.length);
            const formattedNumberBody = this.matchStringFormat( numberStripped, region.displayFormat);

            return '+' + region.internationalCode + ' ' + formattedNumberBody;
        }
    }


    static isValidNumber(number: string, isMobile?: boolean): boolean {
        //testing +xx format
        number.startsWith('00') ? number = number.replace('00', '+') : null;  //if number starts with '00', replace 00 with +

        console.log("=====Checknumber=========", number, "=========== Should be Mobile?", isMobile);
        if (number.length < 6) { return false};
        number = number.replace(/\s+/g, ''); // strip whitespace;

        //================== //discover country
        const region = this.getCountryByPhoneNumber(number);
        if (!region) {return false}
        console.log("isValidNumber() - returned region for number:", region);
        //===============================================

        //================== //length is within accepted range
        if (number.length < region.minMaxMobilelength[0] || number.length > region.minMaxMobilelength[1]) {
            console.log("number length is not within accepted range", number.length);
            return false;
        }
        //===============================================

        //===============//mobile specific test=========
        if (isMobile && region) {
            console.log("isMobile is true", region.internationalMobile.find(mformat => number.startsWith(mformat, 1)));
            const mobileFormat = region.internationalMobile.find(mformat => number.startsWith(mformat, 1))
            if ( !mobileFormat ) { return false; }
            console.log("matched a mobile format");
        }
        //===============================================

        console.log("return true");
        return true;
    }





    private static matchStringFormat(numstr: string, format: string) {
        const insertSpaceAt = (str, pos) => `${str.slice(0, pos)} ${str.slice(pos)}`;

        const fm = format.split(" ");

        let returnNumberfm = numstr;

        returnNumberfm = insertSpaceAt(returnNumberfm ,  fm[1].length);
        returnNumberfm = insertSpaceAt(returnNumberfm ,  fm[2].length + fm[1].length + 1);
        return returnNumberfm.trimEnd();


    }

    private static removePlusand00(number: string) {
        // return naked number without 00 or +
        let strippedNumber  = number;
        if (number.startsWith('+')) {
            strippedNumber = number.slice(1);
        } else if (number.startsWith('00')) {
            strippedNumber = number.slice(2);
        }
        return strippedNumber;
    }
}
