import { Component, OnInit, AfterViewInit, ComponentFactoryResolver, Injector, ViewContainerRef, ApplicationRef, ViewChild, OnDestroy } from '@angular/core';
import { ComponentPortal, Portal, TemplatePortal, DomPortalHost, PortalHost, CdkPortal, CdkPortalOutlet } from '@angular/cdk/portal';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { SidenavRightService } from "@app/template/sidenav-right/sidenav-right.service";
import { map, takeUntil, tap } from "rxjs/operators";
import { Observable } from 'rxjs';
import { Subject } from "rxjs";


@Component({
    selector: 'app-sidenav-right',
    templateUrl: './sidenav-right.component.html',
    styleUrls: ['./sidenav-right.component.scss'],
    styles: []
})
export class SideNavRightComponent implements OnInit, AfterViewInit, OnDestroy {

    // @ViewChild('rightSideNav') rightSideNav: any = ElementRef;
    // @ViewChild(MatSidenavContainer) _rsidenavContainer: MatSidenavContainer;


    _destroy$ = new Subject<void>(); //handles unsubscribing events

    _rightSideComponent$ = this.sidenavRightService.getComponentPortal$()
        .pipe(
            map((c: any) => (c ? new ComponentPortal(c) : null)),
            tap((_) => _ ? this.sidenavRightService.setOpenState(true) : null),
            takeUntil(this._destroy$)
        );
    r_opened = false;

    constructor(
        public sidenavRightService: SidenavRightService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
    ) { }

    ngOnInit() {  }

    ngAfterViewInit(): void {
        //
    }

    outputData(event) {
        console.log("OUTPUT EVENT:", event);
    }

    ngOnDestroy(): void {
        this. _rightSideComponent$ = null;
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
