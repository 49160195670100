<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <!-- <span class="pip-modal-close" (click)="closeModal()">X</span> -->
    <span class="pip-modal-title">REGISTER NEW EXTENSION</span>
    <span class="pip-modal-subtitle">Enter SIP Credentials</span>
  </div>

  <div id="pipmodalbody" class="pip-modal-body pip-scroll-shadow" (scroll)="scrollEvent($event)">
    <form [formGroup]="newSipForm" autocomplete="off">
      <div fxLayout="row wrap" class="sip-form" fxLayoutAlign="space-between center">


        <app-ff-org-search-select [hidden]="viewAs !== 'sysAdmin' || enableOrganisationSearch === false" fxFlex="100"
          (_selectedOrganisation)="organisation_selectUpdate($event)">
        </app-ff-org-search-select>


        <ng-container formGroupName=sip_details>

          <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="custom-form-field-v3">
            <input autocomplete="off" type="text" matInput placeholder="Extension" formControlName="sip_extension_number"
              [readonly]="isSubmitting">
            <mat-error
              *ngIf="newSipForm.controls.sip_details.get('sip_extension_number')?.touched && newSipForm.controls.sip_details.get('sip_extension_number')?.errors?.required">
              Extension is required</mat-error>
            <mat-label>Extension</mat-label>
            <status-icons *ngIf="newSipForm.controls.sip_details.get('sip_extension_number')?.touched"
              [iconStatus]="newSipForm.controls.sip_details.get('sip_extension_number').status">
            </status-icons>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="62" fxFlex.lt-md="100" floatLabel="always"  class="custom-form-field-v3">
            <input autocomplete="off" type="text" matInput placeholder="44 " formControlName="sip_extension_cli"
              [readonly]="isSubmitting">
            <mat-icon class="phone-prefix" matPrefix>add</mat-icon>
            <mat-label>Caller Identity</mat-label>
<!-- 
            <mat-error   *ngIf="newSipForm.controls.sip_details.get('sip_extension_cli')?.touched && newSipForm.controls.sip_details.get('sip_extension_cli')?.errors?.required">
              Number is required</mat-error> -->

            <mat-error *ngIf="newSipForm?.controls['sip_details'].get('sip_extension_cli')?.touched && newSipForm?.controls['sip_details'].get('sip_extension_cli')?.errors?.valid">
              number is not valid</mat-error>

              <!-- [style.position]="'absolute'" [style.left.px]="-14" -->
            <status-icons [hasPrefix]="true"
              *ngIf="newSipForm?.controls['sip_details'].get('sip_extension_cli')?.touched"
              [iconStatus]="newSipForm.controls['sip_details'].get('sip_extension_cli').status">
            </status-icons>
            <mat-hint  align="end">Do not use mobile numbers on this form</mat-hint>
          </mat-form-field>



          <mat-form-field appearance="outline" fxFlex="46%" fxFlex.lt-md="100%"  class="custom-form-field-v3">
            <input  autocomplete="off" type="text" matInput placeholder="SIP Username" formControlName="sip_user_name"
              [readonly]="isSubmitting">

            <mat-error
              *ngIf="newSipForm.controls.sip_details.get('sip_user_name')?.touched && newSipForm.controls.sip_details.get('sip_user_name')?.errors?.required">
              SIP Username is required</mat-error>
            <mat-label>SIP Username</mat-label>
            <status-icons *ngIf="newSipForm.controls.sip_details.get('sip_user_name')?.touched"
              [iconStatus]="newSipForm.controls.sip_details.get('sip_user_name').status">
            </status-icons>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="46%" fxFlex.lt-md="100%"  class="custom-form-field-v3">
            <input spellcheck="false" class="obfuscate-key" type="text" matInput placeholder="" formControlName="sip_password"
              [readonly]="isSubmitting">
            <mat-error
              *ngIf="newSipForm.controls.sip_details.get('sip_password')?.touched && newSipForm.controls.sip_details.get('sip_password')?.errors?.required">
              SIP Password is required</mat-error>
            <mat-error *ngIf="newSipForm?.controls.sip_details.get('sip_password')?.hasError('maxlength')">Maximum length 30 characters exceeded</mat-error>
            <mat-label>SIP Password</mat-label>
            <mat-icon class="clickable" matTooltip="This password will be transfered and stored securely." [matTooltipClass]="'tooltipcustom'" matSuffix svgIcon="info">
            </mat-icon>
            <status-icons *ngIf="newSipForm.controls.sip_details.get('sip_password')?.touched"
              [iconStatus]="newSipForm.controls.sip_details.get('sip_password').status">
            </status-icons>
            <mat-hint>Max length 30 characters</mat-hint>
          </mat-form-field>



          <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100"  class="custom-form-field-v3">
            <mat-label>PBX Hostname</mat-label>
            <input matInput aria-label="hostname" [placeholder]="hostnameList.length > 0 ? 'Select existing or enter new' : 'E.g host.mydomain.com'"
              [matAutocomplete]="hostautocomplete" formControlName="sip_host_name" [readonly]="isSubmitting">

            <mat-autocomplete #hostautocomplete="matAutocomplete" (optionSelected)='hostSetVoicemmail($event)'>
              <mat-option *ngFor="let host of filteredHosts" [value]="host.sip_host_name"
                [id]="host.sip_voicemail_number">
                <span>{{host.sip_host_name}}</span>
                <span *ngIf="host.sip_voicemail_number">
                  |
                  <small [style.font-size.%]="60">voicemail: {{host.sip_voicemail_number}}</small>
                </span>

              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="newSipForm.controls.sip_details.get('sip_host_name')?.touched && newSipForm.controls.sip_details.get('sip_host_name')?.errors?.required">
              PBX Hostname is required</mat-error>
            <status-icons *ngIf="newSipForm.controls.sip_details.get('sip_host_name')?.touched"
              [iconStatus]="newSipForm.controls.sip_details.get('sip_host_name').status">
            </status-icons>
   
          </mat-form-field>

          <!-- <mat-form-field appearance="outline" fxFlex="66" fxFlex.lt-md="100" >
            <input readonly="true" autocomplete="off" type="text" matInput placeholder="hostname.yourdomain.com / 123.456.7.8"
              formControlName="sip_host_name" [readonly]="isSubmitting">
            <mat-error
              *ngIf="newSipForm?.controls.sip_host_name?.touched && newSipForm?.controls.sip_host_name?.errors?.required">
              Host is not valid</mat-error>
            <mat-label>Host name</mat-label>
            <status-icons *ngIf="newSipForm?.controls.sip_host_name?.touched"
              [iconStatus]="newSipForm.get('sip_host_name').status">
            </status-icons>
          </mat-form-field> -->


          <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100"  class="custom-form-field-v3">
            <input autocomplete="off" type="text" matInput placeholder="e.g *123"
              formControlName="sip_voicemail_number" [readonly]="isSubmitting">
            <mat-error
              *ngIf="newSipForm?.controls?.sip_details.get('sip_voicemail_number')?.touched && newSipForm?.controls?.sip_details.get('sip_voicemail_number')?.errors?.required">
              Voicemail required</mat-error>

            <status-icons *ngIf="newSipForm.controls.sip_details.get('sip_voicemail_number')?.touched"
              [iconStatus]="newSipForm.controls.sip_details.get('sip_voicemail_number').status">
            </status-icons>
            <mat-label>PBX Voicemail Number</mat-label>
          </mat-form-field>

        </ng-container>
      </div>
    </form>

  </div>
  <div class="pip-modal-footer">
    <span [style.margin-right.px]="20" *ngIf="isSuccess"
      [@enter]="{ value: '', params: { duration: 600, delay: 0 } }">Saved!</span>
    <button mat-flat-button color="accent" [disabled]="isSubmitting || !newSipForm.valid"
      (click)="submitForm(newSipForm)">
      <ng-container *ngIf="!isSubmitting;else spinner">
        Save
      </ng-container>
    </button>

  </div>
</div>

<ng-template #spinner>
  <span class="loading-icon"><div class="loader-spinner"></div></span>
</ng-template>
