<div class="verify-mobile text-center">
  <ng-container *ngIf="!verifySuccess;else success">

    <div class="actions-tray">

      <mat-form-field appearance="outline" [ngClass]="{'dark-mode': darkMode}">
        <input matInput placeholder="CODE" type="tel" autofocus="autofocus"  pattern="[0-9]*" inputmode="numeric" [(ngModel)]="verificationcode" autocomplete="one-time-code"
          (keyup.enter)="verifyCode(verificationcode, id)">

        <span id="notvalidmsg" *ngIf="notValidMsg">Code was not valid</span>
      </mat-form-field>
      
      <button mat-stroked-button [disabled]="resendButtonDisabled" [ngClass]="{'dark-mode': darkMode}"
        (click)="sendCode(id,mobile)" [style.position]="'relative'">
        <ng-container *ngIf="!submitted2; else spinner2">Resend Code</ng-container>
        <ng-template #spinner2><div class="loader-spinner"></div></ng-template>
        <span id="sentmsg" *ngIf="sentmsg" [ngClass]="{'dark-mode': darkMode}" [@fadeInOut]>sent!</span>
      </button>

      <button [ngClass]="{'dark-mode': darkMode}" [disabled]="submitted1" mat-raised-button color="accent"
        (click)="verifyCode(verificationcode, id)">
        <ng-container *ngIf="!submitted1;else spinner1">Verify</ng-container>
        <ng-template #spinner1><div class="loader-spinner"></div></ng-template>
      </button>



    </div>
  </ng-container>
  <ng-template #success>

    Hold tight, we are almost there ....

  </ng-template>
</div>
