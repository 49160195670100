//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';

import { OrganisationModel } from '@app/models/organisation.model';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";

import { UserProfile } from '@app/models/user-profile.model';
import { UserService } from '@app/services/pipcall/user.service';
import { NumberService } from '@app/services/pipcall/number.service';
import { UserDetailUpdate } from '@app/models/user-profile.model';
import { ValidateEmailNotTaken, ValidateEmailWithSMSIdentityExists } from '@app/validators/async-email.validator';
import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { LicenseModel, Licensedata } from '@app/models/license.model';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';


import { DDIBottomSheetComponent } from '@app/components/bottom-sheet-modal/ddi-bsheet-modal/ddi-bsheet-modal.component';
import { OrganisationProfileBottomSheetComponent } from '@app/components/bottom-sheet-modal/organisationprofile-bsheet-modal/organisationprofile-bsheet-modal.component';


@Component({
    selector: 'app-user-profile-tabs-system',
    templateUrl: './user-profile-system.component.html',
    styleUrls: ['./user-profile-system.component.scss'],
    animations: [
    ]
})
export class UserProfileTabSystemComponent {


    @Input() viewAs: string;
    @Input() userProfile: UserProfile;
    @Input() user_id: string; //require either userID or profile
    @Input() organisation_id: string; //required
    @Input() organisationProfile: OrganisationModel; //required

    @Output() updateParent = new EventEmitter();
    @Output() updateParentModal = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();
    public sharedDataSubscription: Subscription;

    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }

    // public organisationProfile: OrganisationModel;

    //forms
    userNameForm: FormGroup;
    userEmailForm: FormGroup;
    userHandsetForm: FormGroup;
    pipnumberAllocationForm: FormGroup
    userNote: FormGroup;

    //licence
    activeLicenseList: LicenseModel[];


    assigningNumbers
    public pipnumberSelectionType: 'existing' | 'new' = 'existing';
    public showNumberForm: false;

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private userService: UserService,
        private signupService: SignupService,
        private normalizeNumberService: NormalizeNumberService,
        private numberService: NumberService,
        private ref: ChangeDetectorRef,
        private licenseDataService: LicenseDataService,
        private modalService: ModalService,
        private bottomSheet: MatBottomSheet
    ) {

        const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');

        this.activeLicenseList = new Array<LicenseModel>();

        this.sharedDataSubscription = this.licenseDataService.activeLicenseListAsObservable.subscribe((activeLicenseList) => {
            console.log("LICENCE RESPONSE", activeLicenseList);
            activeLicenseList ? this.activeLicenseList = activeLicenseList : null;
            this.pageStatus.isLoadingLicence = false;
        });


        this.userEmailForm = this._formBuilder.group({
            id: [{ value: '' }, Validators.required],
            email: ['', {updateOn: 'change', validators: [Validators.email, Validators.required ], asyncValidators: [ValidateEmailNotTaken.createValidator(this.signupService)]}]
        });
        //ADD EMAIL CHECKER TO THIS. DOES IT EXIST ALREADY ?

        this.userHandsetForm = this._formBuilder.group({
            id: [{ value: '' }, Validators.required],
            mobile: ['',  {updateOn: 'change', validators: [Validators.required, PhonenumberValidator.validateNumber], asyncValidators: [ValidateMobileNotTaken.createValidator(this.signupService)]}]
        });

        this.pipnumberAllocationForm = this._formBuilder.group({
            number: ['', Validators.required],
            organisation_id: ['', Validators.required],
            user_id: ['', Validators.required],
            name: ['']
        });

        this.userNote = this._formBuilder.group({
            user_id: ['', Validators.required],
            notes: ['']
        })

        this.organisationProfile = new OrganisationModel();

    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("CHANGES FIRED",  (changes['userProfile']?.currentValue, this.userProfile));
        (changes['userProfile']?.currentValue !== changes['userProfile']?.previousValue) &&  !changes['userProfile']?.isFirstChange() ?  this.setUserFormValues(this.userProfile) : null;
    }



    ngOnInit() {

        if (this.userProfile) {
            // found a profile from parent
            console.log("THERE IS A IUSER PROFILE FOUND", this.userProfile);
            this.pageStatus.isLoading = false;
            this.setUserFormValues(this.userProfile);
        }

        if ( !this.userProfile && this.user_id ) {
            // no profile from parent. get it now
            this.getDetails();
        }

        if ( !this.userProfile && !this.user_id ) {
            //No profile or ID from parent. throw error.
            this.noContent = true;
            this.pageStatus.isLoading = false;
            this.openSnackBar("There was an error geting user details", "dismiss");
        }


    }

    setUserFormValues(userProfile: UserProfile) {
        console.log('[userprofile/systemtab].setUserFormValues()', userProfile);

        // pipnumberAllocation form
        this.setFormValue(this.pipnumberAllocationForm, 'organisation_id', this.organisation_id);
        this.setFormValue(this.pipnumberAllocationForm, 'user_id', userProfile?.id);
        this.setFormValue(this.pipnumberAllocationForm, 'name', userProfile?.first_name);
        this.showNumberForm = false;

        //user notes
        this.setFormValue(this.userNote, 'user_id', userProfile?.id);
        this.setFormValue(this.userNote, 'notes', userProfile?.notes);

        //email form
        this.setFormValue(this.userEmailForm, 'id', userProfile?.id);
        this.setFormValue(this.userEmailForm, 'email', userProfile?.email);

        //handsetform
        this.setFormValue(this.userHandsetForm, 'id', userProfile?.id);

        const currentmobile = userProfile?.mobile ? this.normalizeNumberService.normaliseNumberWithPlus(userProfile?.mobile) : '';
        this.setFormValue(this.userHandsetForm, 'mobile', currentmobile);


    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    cancelForm() {
        this.userHandsetForm.controls.mobile.reset();
        this.setUserFormValues(this.userProfile);
    }


    getDetails() {
        //get profile if one was not passed
        console.log('[userprofile/systemtab].getDetails()');
        this.pageStatus.isLoading = true;

        this.userService.getUser(this.user_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                if (resp.status === 200 ) {
                    this.userProfile = resp?.body;
                    this.setUserFormValues(this.userProfile);
                    this.ref.detectChanges();
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting user details [${err.status}] `, "dismiss");
            })

    }



    openNumberProfile(number: string) {
        const data = {number: null, viewAs: this.viewAs, organisation_id: this.organisation_id, number_id: number}

        const dialogRef = this.bottomSheet.open(DDIBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF Bottom sheet", resp);
            if (resp) {
                this.getDetails();
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });
    }


    openExtensionProfile(number: string) {
        const data = {number: null, viewAs: this.viewAs, organisation_id: this.organisation_id, number_id: number}

        const dialogRef = this.bottomSheet.open(DDIBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF Bottom sheet.", resp);

            if (resp) {
                this.getDetails();
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });
    }

    openOrgDetails(organisation_name: string, organisation_id: string) {
        console.log("OPEN BOTTOM SHEET--------");
        const data = {organisation_name: organisation_name, viewAs: this.viewAs, organisation_id: organisation_id}


        const dialogRef = this.bottomSheet.open(OrganisationProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("DISMISSED ORG")
            if (resp) {
                this.getDetails();
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });

    }

    saveEmail(f: FormGroup) {
        console.log("SAVE USER:", f);

        if (f.valid) {
            return this.submitForm(this.userService.patchUserEmail(f.getRawValue().id, f.value.email));
        }
    }

    saveMobile(f: FormGroup) {

        const mobile = f.value.mobile.replace(/\s/g, "");
        console.log("SAVE USER:", f, mobile);
        if (f.valid) {
            return this.submitForm(this.userService.patchUserMobile(f.getRawValue().id, mobile));
        }
    }

    saveNewNumber(f: FormGroup) {
        console.log(f);

        if (!f.valid) { return; }

        if ( this.pipnumberSelectionType === 'new' && f.value.number?.length > 4 && f.value.number?.length < 9) {
            return  this.submitForm(this.numberService.allocateDdiByAreacode(f.value.number, f.value.user_id, f.value.organisation_id, f.value.name));
        } else if (this.pipnumberSelectionType === 'existing') {
            return  this.submitForm(this.numberService.allocateDdi(f.value.number, f.value.user_id, f.value.organisation_id, f.value.name));
        } else {
            return this.openSnackBar("Something went wrong", "dissmiss");
        }

    }

    saveUserNote( f: FormGroup ) {
        this.submitForm(this.userService.patchUserNote(f.value.user_id, f.value.notes));
    }

    unlinkNumber(service_id: string, displayAsnumber?: number) {

        const displayNumber = displayAsnumber ? displayAsnumber : service_id;

        const data = new ModalConfirmData;
        data.choice = true;
        data.closeButtonLabel = 'Cancel';
        data.title = 'Unlink number';
        data.content = `You are about to unlink ${displayNumber} from the user. Are you sure ? `;
        data.confirmButtonLabel = 'Unlink';

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.submitForm(this.numberService.deallocateDdi(service_id));
            }
        });
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.markForCheck()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }



    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
