<ng-container *ngIf="!pageStatus.isSuccess || pageStatus.isSuccess === null; else verified">
  <form [formGroup]="verificationForm" fxLayout="column" fxLayoutGap="6px"
    [ngClass]="{'readonly-block': pageStatus.isLoading || pageStatus.isSubmitting}">
    <div fxLayout="column">
      <mat-form-field appearance="outline" class="text-center custom-form-field-v3">
        <input matInput type="tel" pattern="[0-9]*" inputmode="numeric" placeholder="Verification code"
          autocomplete="one-time-code" formControlName="code" (keyup.enter)="verifyCode()">
      </mat-form-field>
    </div>
  </form>

  <div class="error-response-box" *ngIf="pageStatus.isError"
    [@enter]="{ value: '', params: { duration: 600, delay: 0 } }"
    [@fadeOutLeftOnLeave]="{ value: '', params: { duration: 100, delay: 0, translate: '50%' } }">
    <mat-icon class="clickable" (click)="pageStatus.isError = false">cancel</mat-icon>{{pageStatus.errorMessage}}
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
    fxLayoutGap="14px">
    <button mat-flat-button (click)="cancelVerification()" class="back-button">Back</button>

    <button id="createAccount" class="submit-button" color="accent" mat-flat-button
      [disabled]="!verificationForm.valid || pageStatus.isSubmitting" (click)="verifyCode()">
      <ng-container *ngIf="!pageStatus.isLoading;else spinning">Verify email</ng-container>
    </button>
  </div>

</ng-container>

<ng-template #verified>
    Account created successfully, logging into Portal... <i class="fas fa-spinner fa-spin"></i>
</ng-template>

<ng-template #spinning><span class="loading-spinner"><i class="fas fa-spinner fa-spin"></i></span>
