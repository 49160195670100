import { Injectable } from '@angular/core';
import { debounceTime, tap, finalize , first, map,  retry} from 'rxjs/operators';
import { Subscription, Observable, throwError, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ContentService, Article } from '@app/services/pipcall/content.service';
import { AuthService} from '@app/services/auth-service/auth.service';


declare let require: any;
const semverLte = require('semver/functions/lte')
const appVersion = require('../../../package.json').version;

export class FAQS {
    articles: Array<Article>;
    categories: Array<string>;
    lastRowKey: number;
    contextList: Array<string>;
}

@Injectable({
    providedIn: 'root',
})
export class HelpCenterContentService {

    //Links to allow in mat-select option. Azure storage table values should match something from this list in order to work.
    public contextList: string[] = []; //populate this from contextLinkLists

    //convert url into a matching context
    private contextLinkLists = [
        { url: 'tab=overview', context: 'overview' },
        { url: 'tab=billing', context: 'billing' },
        { url: 'tab=users', context: 'users' },
        { url: 'tab=pipnumbers', context: 'pipnumbers' },
        { url: 'tab=extensions', context: 'extensions' },
        { url: 'tab=licences', context: 'licences' },
        { url: 'tab=enhancedfeatures', context: 'enhancedfeatures' },
        { url: '/profile', context: 'my_profile' },
    ];


    public faqsAsObservable: Observable<FAQS>;
    public archivedFaqsAsObservable: Observable<Article[]>;
    private permissions: any;
    public _permissions = {
        faqAdmin: false,
        sysAdmin: false
    }

    private faqsList = new BehaviorSubject<FAQS>(null);
    private archivedList = new BehaviorSubject<Article[]>(null);

    contentSubscription: Subscription;

    constructor(private contentService: ContentService, private authService: AuthService) {
        console.log("HELP CENTER SERVICE INITIALIZED==============================");
        //all values
        this.faqsList.next(null);
        this.archivedList.next(null);

        this.faqsAsObservable = this.faqsList.asObservable().filter(resp => !!resp);
        this.archivedFaqsAsObservable = this.archivedList.asObservable().filter(resp => !!resp);
        this.updateContent();

        this.permissions = this.authService.returnPermissions();
        this.permissions.includes("admin:faq") ? this._permissions.faqAdmin = true : this._permissions.faqAdmin = false;
        this.permissions.includes("admin:user") ? this._permissions.sysAdmin = true : this._permissions.sysAdmin = false;

        //for each context in contextlinklist add it to contextList if it doesn't already exist
        this.contextLinkLists.forEach(i => {
            if (!this.contextList.includes(i?.context)) {
                this.contextList.push(i.context);
            }
        }
        );

    }

    public updateContent() {

        this.contentSubscription = this.contentService.getFaqArticles()
            .pipe (
                retry(2)
            )
            .subscribe((resp) => {
                //set categories, last row and article list
                const faqs = new FAQS();

                faqs.lastRowKey = this.returnLastRowKey(resp.body);

                //ensure appversion is in the correct syntax v 0.0.0
                const filtered = [...resp.body].filter(i =>  i?.is_active && semverLte(i?.min_portal_version,  appVersion) && this.adminContentCheck(i?.category.toLowerCase()))

                if ( this._permissions.sysAdmin ) {
                    const archived = [...resp.body].filter(i => i?.is_active === false);
                    this.archivedList.next(archived);
                }


                console.log("========================LAST ROW KEY IS: ", faqs.lastRowKey, resp.body);
                faqs.categories = this.returnCategories(filtered);
                faqs.articles = [...filtered].sort((a, b) => a.title.localeCompare(b.title));
                faqs.contextList = this.contextList;
                this.faqsList.next(faqs);
                this.contentSubscription.unsubscribe();
            }, (err) => {
                console.log(err);
                throwError(err);
            })
    }

    public returnContextForRoute(pageRoute: string): string {
        const context =  this.contextLinkLists.find(i => pageRoute.includes(i.url))?.context;
        return context;
    }



    private adminContentCheck(category: string) {
        //is this sysAdmin only content
        if (!this._permissions?.sysAdmin && category === 'sysadmin-only') {
            return false;
        }
        return true;
    }

    private returnCategories(articles: Article[]): Array<string> {
        //return unique categoies as Array of strings
        const unique = [...new Set(articles.map(item => item?.category.toLowerCase()))];
        return unique;
    }

    private returnLastRowKey(articles: Article[]) {
        return Math.max(...articles.map(a => parseInt(a.RowKey)), 0);
    }

    ngOnDestroy() {
        this.contentSubscription ?  this.contentSubscription.unsubscribe() : '';
    }

}
