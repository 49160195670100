import { NgModule } from '@angular/core';
import {GravatarDirective} from './gravatar.directive';
import { MaterialModule} from '../../material/material.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
    ],
    declarations: [GravatarDirective],
    exports: [GravatarDirective],
    providers: [],
    entryComponents:[GravatarDirective]
})
export class GravatarDirectiveModule { }