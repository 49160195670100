<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <!-- <span class="pip-modal-close" (click)="closeModal()">X</span> -->
    <span class="pip-modal-title">Build your team</span>
    <span class="pip-modal-subtitle">Add a new PiP user</span>
  </div>
  <div id="pipmodalbody" class="pip-modal-body pip-scroll-shadow" (scroll)="scrollEvent($event)" fxLayout="column">


    <div [style.margin-bottom.px]="'30'" [style.padding-top.px]="'15'" [style.font-size.px]="14"
      *ngIf="organisationDetails.is_pipmobile_enabled && !organisationDetails.is_pipxt_enabled">
      Use the form below to create a new PiP user and assign them a Licence and Contract. Your new PiP user will receive
      an email with instructions on how to download and start using the PiPcall app.
    </div>

    <div [style.margin-bottom.px]="'30'" [style.padding-top.px]="'15'" [style.font-size.px]="14"
      *ngIf="organisationDetails.is_pipxt_enabled">
      Use the form below to create a new PiP user and assign them an extension. Your new PiP user will receive
      an email with instructions on how to download and start using the PiPcall app.
    </div>

    <div>

      <!-- {{this.inviteUserForm.controls.allocation.get('allocaion_option_existing').value}} -->
    </div>
    <div fxFlex="100">

      <form [formGroup]="inviteUserForm">

        <div fxLayout="row" fxLayoutAlign="start center" class="form-divider subtitle-12">
          <span><span>1.</span>&nbsp;&nbsp;User Details</span></div>


        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">

          <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3">
            <input type="email" matInput placeholder="Email" autocomplete="email" formControlName="email">
            <mat-icon svgIcon="email" matSuffix></mat-icon>
            <mat-error
              *ngIf="inviteUserForm?.controls.email?.touched && inviteUserForm?.controls.email?.errors?.required">
              Required
              Field</mat-error>
            <mat-error *ngIf="inviteUserForm?.controls.email?.touched && inviteUserForm?.controls.email?.errors?.email">
              not a valid email</mat-error>
            <mat-error *ngIf="inviteUserForm.get('email').status === 'PENDING'">
              checking ...
            </mat-error>
            <mat-error *ngIf="inviteUserForm.get('email').errors && inviteUserForm.get('email').errors.emailTaken">
              User is an Existing PiP user. Contact us on 0330 094 8080 and we can help migrate this user to your
              organisation.
            </mat-error>
            <mat-label>Email</mat-label>

            <status-icons *ngIf="inviteUserForm?.controls.email?.touched"
              [iconStatus]="inviteUserForm.get('email').status">
            </status-icons>
          </mat-form-field>
        </div>


        <div fxLayout="row" fxLayoutAlign="space-between" class="form-divider subtitle-12">
          <div>
            <span [style.font-size.px]="16" [style.font-weight]="600">2.</span>
            &nbsp;&nbsp;Select Number
          </div>

          <!-- <div>
            <mat-button-toggle-group class="small-toggle" [(value)]="numberTypeToggle">
              <mat-button-toggle value="pipmobile">pipmobile</mat-button-toggle>
              <mat-button-toggle value='pipxt'>pipxt</mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->
        </div>


        <div fxLayout="row" fxLayoutAlign="space-between">

          <mat-form-field appearance="outline" fxFlex="32" class="custom-form-field-v3"
            *ngIf="!isselectNumberTypeHidden">
            <mat-select placeholder="select" [(value)]="numberType" (selectionChange)="toggleNumber($event.value)">
              <mat-option *ngIf="organisationDetails.is_pipmobile_enabled" value="new">New PiPmobile</mat-option>
              <!-- <mat-option *ngIf="organisationDetails.is_pipmobile_enabled"  value="existingMobile">Existing PiPmobile ({{existingPiPmobile?.length}})</mat-option> -->
              <mat-option *ngIf="organisationDetails.is_pipxt_enabled" value="existingXt">Existing PiPextensions
                ({{existingPiPxt?.length}})</mat-option>
              <!-- <mat-option *ngIf="organisationDetails.is_pipmobile_enabled" value="import">Import business number
              </mat-option> -->
            </mat-select>
            <mat-label>Number type</mat-label>
            <!-- <status-icons *ngIf="numberType != null" [iconStatus]="inviteUserForm.controls.allocation.status"></status-icons> -->
            <ng-container
              *ngIf="numberType != null && numberType === 'existingXt' && existingPiPxt.length === 0; else elseValid">
              <status-icons [iconStatus]="'INVALID'"></status-icons>
            </ng-container>

            <ng-template #elseValid>
              <status-icons *ngIf="inviteUserForm?.controls.allocation?.touched"
                [iconStatus]="inviteUserForm?.controls.allocation?.status"></status-icons>
            </ng-template>


          </mat-form-field>

          <div [fxFlex]="areaCodeInputWidth">
            <ng-container formGroupName="allocation">

              <!-- import  -->
              <mat-form-field appearance="outline" fxFlex="100" [hidden]="numberType!='import'"
                class="custom-form-field-v3">
                <input type="tel" matInput placeholder="+44" formControlName="cli_override" autocomplete="tel"
                  [readonly]="submitted">
                <mat-label>Enter the UK business number you wish to port</mat-label>
                <mat-error
                  *ngIf="inviteUserForm?.controls?.allocation?.get('cli_override')?.touched && inviteUserForm?.controls?.allocation?.get('cli_override')?.errors?.valid">
                  Not a valid UK number
                </mat-error>
                <mat-error
                  *ngIf="inviteUserForm?.controls.allocation?.get('cli_override')?.touched && inviteUserForm?.controls?.allocation?.get('cli_override')?.errors?.mobileBusinessSame">
                  Business number should not be the same as your mobile
                </mat-error>

                <status-icons
                  *ngIf="inviteUserForm?.controls?.allocation?.get('cli_override')?.touched && numberType ==='import'"
                  [iconStatus]="inviteUserForm.controls?.allocation?.get('cli_override').status"></status-icons>

              </mat-form-field>

              <!-- select new -->
              <mat-form-field [hidden]="numberType!='new'" appearance="outline" fxFlex="100"
                class="custom-form-field-v3">
                <mat-select placeholder="select your business number" formControlName="allocation_option">
                  <mat-option *ngFor="let code of areaCodes" value="{{code.id}}">
                    {{code.name}} {{code.code}}
                  </mat-option>
                </mat-select>
                <mat-label>Number Area code</mat-label>
                <status-icons
                  *ngIf="inviteUserForm?.controls.allocation.get('allocation_option')?.touched && numberType!='import'"
                  [iconStatus]="inviteUserForm.controls.allocation.get('allocation_option').status"></status-icons>
              </mat-form-field>


              <!-- select from existing  -->
              <mat-form-field [hidden]="numberType!='existingMobile' && numberType!='existingXt' " appearance="outline"
                fxFlex="100" class="custom-form-field-v3">

                <mat-select
                  [ngClass]="{'readonly-block': (numberType ===  'existingMobile' && existingPiPmobile.length === 0) || (numberType ===  'existingXt' && existingPiPxt.length === 0)}"
                  formControlName="allocaion_option_existing">


                  <!-- [disabled]="checkIfNumberIsAssigned(number.number)" -->
                  <!-- <ng-container *ngIf="numberType==='existingMobile'">
                      <mat-option *ngFor="let number of existingPiPmobile" [value]="number.number" >
                        {{number.number | normalizeToInternational}}
                      </mat-option>
                    </ng-container> -->

                  <ng-container *ngIf="numberType==='existingXt'">
                    <mat-option *ngFor="let number of existingPiPxt" [value]="number.number">
                      {{number.sip_extension_cli | normalizeToInternational}} ({{number.sip_extension_number}})
                    </mat-option>
                  </ng-container>
                </mat-select>

                <mat-label>
                  <span
                    *ngIf="(numberType === 'existingMobile' && existingPiPmobile.length > 0 )|| (numberType === 'existingXt' && existingPiPxt.length > 0)">Select
                    from available numbers</span>
                  <span
                    *ngIf="(numberType === 'existingMobile' && existingPiPmobile.length === 0) || (numberType === 'existingXt' && existingPiPxt.length == 0)">There
                    are no available numbers</span>
                </mat-label>

                <status-icons
                  *ngIf="inviteUserForm?.controls.allocation.get('allocaion_option_existing')?.touched && (numberType==='existingMobile' || numberType==='existingXt')"
                  [iconStatus]="inviteUserForm.controls.allocation.get('allocaion_option_existing').status">
                </status-icons>
              </mat-form-field>

              <mat-form-field class="disabled" appearance="outline" *ngIf="!numberType" class="custom-form-field-v3">
                <input matInput placeholder="Select number" disabled ngModel [ngModelOptions]="{standalone: true}">
              </mat-form-field>

            </ng-container>

          </div>

        </div>

        <div class="tip-box" matTooltip="Non-geographic numbers and more area codes are available upon request. If you are interested in this, please select any landline for now and then contact support@pipcall.com to discuss pricing and have your number upgraded" align="end"><mat-icon class="mat-hint-icon" svgIcon="info" ></mat-icon>&nbsp;Don't see a number you would like?</div>


        <div
          [hidden]="(!organisationDetails.is_pipmobile_enabled && organisationDetails.is_pipxt_enabled) || numberType === 'existingXt' ">

          <div [ngClass]="{'rowdisabled':(numberType === 'import' && inviteUserForm?.controls.allocation.get('cli_override').valid === false)   || 
      (numberType === 'new' && inviteUserForm?.controls.allocation.get('allocation_option').valid === false) || 
      ((numberType === 'existingMobile' || numberType === 'existingXt') && inviteUserForm?.controls.allocation.get('allocaion_option_existing').valid === false) || 
      !numberType}" fxLayout=" row" fxLayoutAlign="start center" class="form-divider subtitle-12"><span><span
                [style.font-size.px]="16" [style.font-weight]="600">3.</span>&nbsp;&nbsp;Licence</span>
            <!-- &nbsp;&nbsp;(<span *ngIf="isMobileTypeSelected">Text & </span>Talk) -->
            &nbsp;&nbsp;(Talk<span *ngIf="isMobileTypeSelected">&nbsp;&&nbsp;Text</span>)
          </div>


          <!-- [hidden]="organisationDetails.state === 'Trial' || (numberType === 'import' && inviteUserForm?.controls.cli_override.valid === false) || (numberType === 'new' && inviteUserForm?.controls.allocation_option.valid === false) || !numberType" -->
          <!-- <div fxLayout="row" fxLayoutAlign="start center" *ngIf="organisationDetails.state === 'Trial'">
        <mat-form-field appearance="outline" fxFlex="35">
          <input type="text" matInput disabled value='Trial'>
          <status-icons *ngIf="inviteUserForm?.controls.allocation?.valid" [iconStatus]="'VALID'">
          </status-icons>
        </mat-form-field>

      </div> -->

          <div *ngIf="organisationDetails.state === 'Trial'" style="position: relative;">
            <ng-container>
              <span><mat-icon class="blue-check success">check_circle</mat-icon></span>
            </ng-container>
            <span style="padding-left:20px;">Trial</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="organisationDetails.state !== 'Trial'"
            style="position: relative;padding-left:20px;">

            <ng-container *ngIf="inviteUserForm?.controls['selected_license'].valid">
              <span><mat-icon class="blue-check success2">check_circle</mat-icon></span>
            </ng-container>

            <a href="javascript:void(0);" class="no-underline" tabindex="-1"
              *ngIf="organisationDetails.state !== 'Trial'" (click)="openPackageSelect(isMobileTypeSelected)"
              [ngClass]="{'text-licence-gradient' : selectedLicence?.licence_type_display?.includes('Text'), 'talk-licence-gradient' : selectedLicence?.licence_type_display?.includes('Talk') && !selectedLicence?.licence_type_display?.includes('Text')}"
              [disabled]="
        organisationDetails.state === 'Trial' || 
        (numberType === 'import' && inviteUserForm?.controls.allocation.get('cli_override').valid === false)   || 
        (numberType === 'new' && inviteUserForm?.controls.allocation.get('allocation_option').valid === false) || 
        ((numberType === 'existingMobile' || numberType === 'existingXt') && inviteUserForm?.controls.allocation.get('allocaion_option_existing').valid === false) || 
        !numberType">
              <ng-container
                *ngIf="selectedLicence?.display && inviteUserForm.controls['selected_license'].value; else selectIt">{{selectedLicence?.licence_type_display}}
                {{selectedLicence?.display}}</ng-container>
              <ng-template #selectIt>
                <ng-container *ngIf="organisationDetails.state === 'Trial' || 
            (numberType === 'import' && inviteUserForm?.controls.allocation.get('cli_override').valid === false)   || 
            (numberType === 'new' && inviteUserForm?.controls.allocation.get('allocation_option').valid === false) || 
            ((numberType === 'existingMobile' || numberType === 'existingXt') && inviteUserForm?.controls.allocation.get('allocaion_option_existing').valid === false) || 
            !numberType;else selectNotDisabled">-</ng-container>
                <ng-template #selectNotDisabled>Select Licence</ng-template>

              </ng-template>
            </a>
          </div>

        </div>

        <!-- <div
      [hidden]="(!organisationDetails.is_pipmobile_enabled && organisationDetails.is_pipxt_enabled) || numberType === 'existingXt' ">

      <div fxLayout=" row" fxLayoutAlign="start center" class="form-divider subtitle-12"><span><span
            [style.font-size.px]="16" [style.font-weight]="600">3.</span>&nbsp;&nbsp;Select Licence</span>
        &nbsp;&nbsp;(Talk<span *ngIf="isMobileTypeSelected">&nbsp;&&nbsp;Text</span>)
      </div>
     
      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="organisationDetails.state === 'Trial'">
        <mat-form-field appearance="outline" fxFlex="35">
          <input type="text" matInput disabled value='Trial'>
          <status-icons *ngIf="inviteUserForm?.controls.allocation?.valid" [iconStatus]="'VALID'">
          </status-icons>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="organisationDetails.state !== 'Trial'">



      </div>
    </div> -->



      </form>
    </div>

  </div>


  <div class="pip-modal-footer">
    <!-- <button mat-button (click)="debug()">Debug</button> -->
    <!-- <button mat-button  (click)="openTermsConditionsModal()">Terms & Conditions</button> -->
    <!-- <button mat-button  (click)="openPricingModal()">View Pricing</button> -->
    <button mat-raised-button color="accent" (click)="alertInvitation(inviteUserForm, numberType)"
      [style.margin-top.px]="'10'" [disabled]="inviteUserForm?.status != 'VALID'">
      <ng-container *ngIf="!submitted;else loading">Send Invitation</ng-container>
      <ng-template #loading>
        <div class="loader-spinner"></div>
      </ng-template>
    </button>

  </div>

</div>


<mat-menu #contextMenu="matMenu" xPosition="before" class="dark-mode small-mat-menu">
  <ng-template matMenuContent>

    <button mat-menu-item (click)="debugit('active')"> Set to Active</button>
    <button mat-menu-item (click)="debugit('trial')"> Set to Trialist</button>
    <button mat-menu-item (click)="debugit('pipxt')"> Set as PiPxt only</button>
    <button mat-menu-item (click)="debugit('pipmobile')"> Set as PiPMobile only</button>
    <button mat-menu-item (click)="debugit('both')"> Set as Both PiPMobile And PiPXt</button>
    <button mat-menu-item (click)="debugit('console')">Log Form</button>
  </ng-template>
</mat-menu>