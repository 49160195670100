
<div class="pip-modal-content">

  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header"  cdkDrag cdkDragRootElement=".cdk-overlay-pane">


    <span class="pip-modal-title"><mat-icon [style.cursor]="'move'" cdkDragHandle>drag_handle</mat-icon> This is a Title</span>
    <span class="pip-modal-subtitle">The handle is dragable</span>
  </div>

  <div id="pipmodalbody" class="pip-modal-body pip-scroll-shadow" >
    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
    ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
    labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam
    corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit
    qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
    nulla pariatur?
  </div>
  <div class="pip-modal-footer">
    <button mat-flat-button color="primary" (click)="closeModal()">Close</button>
  </div>
</div>



