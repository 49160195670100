

export class UiAvatar {

    private static baseurl = "https%3A%2F%2Fui-avatars.com%2Fapi%2F";
    private static initialColors =  {
        "a": "FF67B0",
        "b": "EC74FF",
        "d": "FC626F",
        "c": "FB4152",
        "e": "FC9257",
        "f": "FFC226",
        "g": "F99025",
        "h": "32977E",
        "i": "00B9CF",
        "j": "3b85c9",
        "k": "30d96e",
        "l": "2ADB46",
        "m": "FFD600",
        "n": "15b9c2",
        "o": "4548a3",
        "p": "3683c9",
        "q": "79E4FC",
        "r": "00CBD7",
        "s": "7B9FFF",
        "t": "ff6070",
        "u": "47CDEB",
        "v": "AFB5C6",
        "w": "4793EB",
        "x": "9CB3EF",
        "y": "FF6BB2",
        "z": "FF6BB2"
    };

    // parameters order to be passed as subdirectories
    // name
    // size
    // background
    // color
    // length
    // font-size
    // rounded
    // uppercase
    // bold
    // format

    // query parameter example "https%3A%2F%2Fui-avatars.com%2Fapi%2F?name=Max+Trev&background=0D8ABC&color=fff&format=svg";
    // Gravatar sub directories example "https%3A%2F%2Fui-avatars.com%2Fapi%2F/R+H/128/e30000"

    public static getuiAvatarUrl(fullname: string) {
        if (!fullname) {
            return;
        }

        const names = fullname.trim().split(" ");
        const fname: string = names[0].charAt(0); //take first letter of firstname
        const lname: string = names.length > 1 ? names[names.length - 1].charAt(0) : ''; //take first letter of lastname if there is a lastname

        return this.setfallBackUrl(fname, lname);
    }

    private static returnColor(fname: string) {
        return this.initialColors[fname] ? this.initialColors[fname] : '878786';
    }
    private static setfallBackUrl(fname: string, lname: string) {
        const background: string = this.returnColor(fname.toLowerCase());
        const color = 'fff';
        const size = '128';
        const parameters = `/${fname}+${lname}/${size}/${background}/${color}`;

        const url = this.baseurl + parameters;

        return url;
    }

}
