<ng-container *ngIf="isLicenseLoaded;else loaded"><i class="fas fa-spinner fa-spin"></i></ng-container>

<ng-template #loaded>

  <ng-container [ngSwitch]="mode">

    <ng-container *ngSwitchCase="'invitation'">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="25px">
        <ng-container  [ngTemplateOutlet]="licenseSelectFormField"></ng-container>
        <ng-container  [ngTemplateOutlet]="contractSelectFormField"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'edit'">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <ng-container [ngTemplateOutlet]="licenseSelectFormField"></ng-container>
        <ng-container [ngTemplateOutlet]="contractSelectFormField"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'checkout'">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <ng-container [ngTemplateOutlet]="licenseSelectFormField"></ng-container>
        <ng-container [ngTemplateOutlet]="contractSelectFormField"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'activate'">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <ng-container [ngTemplateOutlet]="licenseSelectFormFieldActivate"></ng-container>
        <ng-container [ngTemplateOutlet]="contractSelectFormFielActivate"></ng-container>
      </div>
    </ng-container>

  </ng-container>
  

</ng-template>


<!-- <mat-select placeholder="License" [(ngModel)]="selectedLicenseType" (selectionChange)="selectionChange($event)"
  [disabled]="(mode ==='invitation' && !_selectedLicenseType) || (mode==='edit' ) ">
  <mat-option *ngFor="let licenseOption of licenseOptions[returnLandlineOrMobile(_selectedLicenseType)]"
    [value]="licenseOption.value">
    {{licenseOption.display}}</mat-option>
</mat-select> -->

<!-- 
<ng-template #licenseSelectFormField>
  <mat-form-field appearance="outline" fxFlex="auto" fxFlex.xs="100">
    <mat-select placeholder="licence" [(ngModel)]="selectedLicenseType" (selectionChange)="selectionChange($event)"
      [disabled]="(mode ==='invitation' && !_selectedLicenseType) || (mode==='edit' && returnBasicOrStandard(_selectedLicenseType) != 'basic' ) ">
      <mat-option *ngFor="let licenseOption of licenseOptions[returnLandlineOrMobile(_selectedLicenseType)]"
        [value]="licenseOption.value">
        {{licenseOption.display}}</mat-option>
    </mat-select>
    <mat-label>licence</mat-label>
  </mat-form-field>
</ng-template> -->

<!-- disable if no _selectedLicenseType type in invitation -->
<!-- disable if in edit and upgrading a standard contract -->

<ng-template #licenseSelectFormField>
  <mat-form-field appearance="outline" fxFlex="auto" fxFlex.xs="100" [ngClass]="{'mat-form-responsive': mode==='checkout'}" class="custom-form-field-v3">
    <mat-select placeholder="Select licence" [(ngModel)]="selectedLicenseType" (selectionChange)="selectionChange($event)" [disabled]="disabled">
      <mat-option *ngFor="let licenseOption of licenseOptions"
      [hidden]="('invitation' && licenseOption.isMobile != isMobileTypeSelected) || (mode ==='edit' && selectedTypeHierachy(existingLicense.type) > licenseOption.hierarchy )"
      [disabled]="('invitation' && licenseOption.isMobile != isMobileTypeSelected) || (mode ==='edit' && selectedTypeHierachy(existingLicense.type) > licenseOption.hierarchy )"
        [value]="licenseOption.value">
        {{licenseOption.display}}</mat-option>
    </mat-select>
    <mat-label>Select Licence</mat-label>
    <status-icons *ngIf="mode==='invitation' && selectedLicenseType != null"
    [iconStatus]="'VALID'"></status-icons>
  </mat-form-field>
</ng-template>

<ng-template #contractSelectFormField>

  <mat-form-field  appearance="outline" fxFlex="auto" fxFlex.xs="100" [ngClass]="{'mat-form-responsive': mode==='checkout'}"  class="custom-form-field-v3">
    <mat-select placeholder="Contract" [(ngModel)]="selectedContractType" [disabled]="isTrialist || disabled"
      (selectionChange)="selectionChange($event)">
      <mat-option *ngIf="mode === 'invitation' && isTrialist" value="trial">Trial</mat-option>
      <ng-container *ngIf="mode != ('invitation' && !isTrialist) ">
        <mat-option *ngFor="let contractOption of contractOptions" [hidden]="selectedContractTypeHierachy > contractOption.hierarchy" [disabled]="selectedContractTypeHierachy > contractOption.hierarchy" [value]="contractOption.value">
          {{contractOption.display}}</mat-option>
      </ng-container>

    </mat-select>
    <mat-label>Contract</mat-label>
    <status-icons *ngIf="mode==='invitation' && selectedContractType != null"
    [iconStatus]="'VALID'"></status-icons>
  </mat-form-field>

</ng-template>


<ng-template #licenseSelectFormFieldActivate>
  <mat-form-field appearance="outline"  fxFlex="100" [style.font-size.px]="12">
    <mat-select placeholder="licence" [(ngModel)]="selectedLicenseType" (selectionChange)="selectionChange($event)" [disabled]="disabled">
      <mat-option *ngFor="let licenseOption of licenseOptions"
      [hidden]="licenseOption.isMobile != isMobileTypeSelected"
      [disabled]="licenseOption.isMobile != isMobileTypeSelected"
        [value]="licenseOption.value">
        {{licenseOption.display}}</mat-option>
    </mat-select>
    <mat-label>Licence</mat-label>
    <status-icons *ngIf="selectedLicenseType != null && !disabled" [iconStatus]="'VALID'"></status-icons>
    <!-- can we do this better ? change to form instead -->
  </mat-form-field>
</ng-template>

<ng-template #contractSelectFormFielActivate>

  <mat-form-field appearance="outline" fxFlex="100" [style.font-size.px]="12">
    <mat-select placeholder="Contract" [(ngModel)]="selectedContractType" [disabled]="isTrialist || disabled"
      (selectionChange)="selectionChange($event)">
        <mat-option *ngFor="let contractOption of contractOptions" [hidden]="selectedContractTypeHierachy > contractOption.hierarchy" [disabled]="selectedContractTypeHierachy > contractOption.hierarchy" [value]="contractOption.value">
          {{contractOption.display}}</mat-option>
    </mat-select>
    <mat-label>Contract</mat-label>
    <status-icons *ngIf="selectedContractType != null && !disabled" [iconStatus]="'VALID'"></status-icons>
  </mat-form-field>

</ng-template>
