<h5 class="title" mat-dialog-title>Organisation Automatic Billing</h5>
<mat-dialog-content>

  <div style="margin-bottom: 10px;" fxFlex="100" fxLayout="column">

    <div fxFlex="100" >
      <label>Please confirm the automatic billing state. You will need to know the stripe id order to re-enable billing.</label>


      <mat-radio-group class="radio-group" [(ngModel)]="isBillingEnabled">
        <mat-radio-button [value]=true class="radio-button">
          Enable
        </mat-radio-button>

        <mat-radio-button [value]=false class="radio-button">
          Disable
        </mat-radio-button>

      </mat-radio-group>

    </div>

    <div fxFlex="100" [hidden]="!isBillingEnabled">
      <mat-form-field appearance="outline" fxFlex="100" floatLabel="always">
        <input autocomplete="off" type="text" matInput placeholder="cus_************" [disabled]="!isBillingEnabled"
          [(ngModel)]="stripe_id" required>
        <mat-label>Customer stripe id</mat-label>
        <mat-hint>This field is not validated, using an incorrect Stripe ID will cause billing to fail.</mat-hint>
      </mat-form-field>

    </div>


  </div>

</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="closeDialog(false)">
    Cancel
  </button>

  <button *ngIf="isBillingEnabled === false && isBillingEnabled !== null" class="red-delete" style="color:white;" mat-raised-button (click)="disableBilling(organisation_id)"
  tabindex="-1" [disabled]="isBillingEnabled === null || pageStatus.isSubmitting">
  <ng-container *ngIf="!pageStatus.isSubmitting; else loadingSpinner">Disable</ng-container>
</button>


  <button *ngIf="isBillingEnabled" class="green-go" style="color:white;" mat-raised-button (click)="enableBilling(organisation_id, stripe_id)"
    tabindex="-1" [disabled]="stripe_id === null || stripe_id === '' || pageStatus.isSubmitting">
    <ng-container *ngIf="!pageStatus.isSubmitting; else loadingSpinner">Enable</ng-container>
  </button>


</mat-dialog-actions>



<ng-template #loadingSpinner class="text-center">
  <div class="loader-spinner"></div>
</ng-template>
