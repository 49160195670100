<h4 mat-dialog-title class="text-center">
  <div>Provision DDIs

<mat-icon svgIcon="info" [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="
Create DDIs by supplying range
or a single number. Will skip
exising PiP numbers.
e.g 
00447740000001 - 98 will 
register 98 numbers. 
Request will be added to a queue
and may take a moment."></mat-icon> 
</div>

  <!-- <i [matTooltipClass]="'tooltipcustom tooltipmultiline'"  class="fas fa-info info-help"
    matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="
  Create DDIs by supplying range
  or a single number. Will skip
  exising PiP numbers.
  e.g 
  00447740000001 - 98 will 
  register 98 numbers. 
  Request will be added to a queue
  and may take a moment."></i> -->

</h4>

<mat-dialog-content>

  <section class="text-center" *ngIf="!advancedMode">
    <mat-radio-group [(ngModel)]="singleOrRange" (change)="updateRangeTo(newDdiForm)">
      <mat-radio-button class="margin10" value="single">Single</mat-radio-button>
      <mat-radio-button [matTooltipClass]="'tooltipcustom tooltipmultiline'" 
        matTooltip="enter a valid number before selecting range" [disabled]="!validNumber" class="margin10"
        value="range">Range</mat-radio-button>
    </mat-radio-group>
  </section>

  <form novalidate #myForm [formGroup]="newDdiForm">


    <div class="modal-form-wrapper">

      <div class="number-fields">


        <ng-container *ngIf="!advancedMode;else advancedInput">
          <mat-form-field appearance="outline" [ngClass]="{'shorterBox': singleOrRange=='range'}">
            <input   [value]="newDdiForm.get('number').value | removeSpaces" autocomplete="off" type="text" matInput placeholder="Number (0044)" formControlName="number" [readonly]="submitted"
              [style.text-align]="'right'" (keyup)="updateRangeTo(newDdiForm)">
            <!-- <mat-error *ngIf="newDdiForm?.controls.number?.touched && newDdiForm?.controls.number?.errors?.required">
              number
              is required</mat-error> -->
            <mat-error *ngIf="newDdiForm?.controls.number?.touched && newDdiForm?.controls.number?.errors?.pattern">
              number
              is not valid</mat-error>
            <mat-label>Number</mat-label>
          </mat-form-field>

          <mat-form-field *ngIf="singleOrRange=='range'" appearance="outline" class="rangeBox">
            <input autocomplete="off" type="text" matInput placeholder="range" [readonly]="submitted" [style.text-align]="'center'"
              [(ngModel)]="toRange" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateRange()">
            <mat-label>End</mat-label>
          </mat-form-field>


          <div class="text-right count-display">
            <span *ngIf="singleOrRange ==='range'">
              <ng-container *ngIf="isRangeValid.valid;else errormsg"><span>{{isRangeValid.count}} numbers</span>
              </ng-container>
              <ng-template #errormsg><span [style.color]="'red'">{{isRangeValid.errorMsg}}</span></ng-template>
            </span>
          </div>
        </ng-container>
        <ng-template #advancedInput>
            <mat-form-field appearance="outline">
                <input autocomplete="off" type="text" matInput placeholder="0000,000-10,1111 etc" formControlName="numberAdvString" (keyup)="updateAdvRange(newDdiForm)" [readonly]="submitted"
                  [style.text-align]="'right'">
                <mat-label>Advanced Input</mat-label>
              </mat-form-field>    
        </ng-template>

        <!-- <div class="platform-details"> -->
        <mat-form-field appearance="outline">
          <mat-select placeholder="pbx_platform" formControlName="pbx_platform_id">
            <mat-option *ngFor="let item of pbxPlatformList" [value]="item.id">{{item.id}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="newDdiForm?.controls.pbx_platform_id?.touched && newDdiForm?.controls.pbx_platform_id?.errors?.required">
            platform
            is required</mat-error>
          <mat-label>PBX Platform</mat-label>
        </mat-form-field>


        <mat-form-field appearance="outline" class="providerdropdown">
          <mat-select placeholder="provider" formControlName="provider">
            <mat-option *ngFor="let item of providersList" [value]="item.name">{{item.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="newDdiForm?.controls.provider?.touched && newDdiForm?.controls.provider?.errors?.required">
            provider
            is required</mat-error>
          <mat-label>Provider</mat-label>
        </mat-form-field>
        <!-- </div> -->

      </div>

      <div class="bottom-options">
        <mat-checkbox formControlName="is_reserved">
          Reserved
        </mat-checkbox>
        <mat-checkbox formControlName="is_sms_enabled" 
        matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="
        enable SMS on 07 numbers from Gamma only.">
          SMS enabled
        </mat-checkbox>

        <mat-slide-toggle [(ngModel)]="advancedMode" [ngModelOptions]="{standalone: true}" 
          matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="
    This will enable advanced input mode and no validation. 
    Only use this if you know what you are doing, enter comma seperated values or range.
    e.g 00441234567892,00441234567892-99,00441234567999 ">
          Advanced
        </mat-slide-toggle>


      </div>
    </div>
  </form>


  <mat-dialog-actions align="center">
    <ng-container *ngIf="!submitted; else loading">
      <button mat-raised-button [mat-dialog-close]="false">Close</button>

      <button mat-raised-button color="primary" *ngIf="!advancedMode"
        [disabled]="((!isRangeValid.valid && singleOrRange==='range') || !newDdiForm.valid)"
        (click)="validateForm(newDdiForm)">Create
        DDIs</button>

        <button mat-raised-button color="primary" *ngIf="advancedMode"
        [disabled]="false"
        (click)="validateForm(newDdiForm)">Create
        DDIs</button>
    </ng-container>
    <ng-template #loading>
      <i class="fas fa-spinner fa-spin"></i>
    </ng-template>
  </mat-dialog-actions>

</mat-dialog-content>
