import {Component, Inject} from '@angular/core';
import {MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@app/material/material-essentials.module';

@Component({
    selector: 'modal-payment-prompt',
    template: `
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <p>Hope you are enjoying the trial, you can upgrade your account at any time by clicking 'Pay now'.</p>


    <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-flat-button (click)="openPayments()">Pay now</button>
    <button mat-flat-button (click)="closeModal()">Close</button>
    </div>
    </div>
    `,
    styles: [``]
})
export class GoToPaymentPromptModal {

    constructor(
        public dialogRef: MatDialogRef<GoToPaymentPromptModal>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    openPayments() {
        this.dialogRef.close(true);
    }
    closeModal(): void {
        this.dialogRef.close();
    }

}

