import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpProxy } from './http-proxy.service';
import { Observable } from 'rxjs/Rx';


@Injectable()
export class Auth0UserManagement {

    constructor(private httpProxy: HttpProxy) { }

    public passwordReset(user_email: string): Observable<any> {
        const url = `https://${environment.auth0_domain}/dbconnections/change_password`;
        const body = {
            'client_id': environment.auth0_clientID,
            'email': user_email,
            'connection': 'VoxSpan-Users'
        }

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'text' as 'json'
        }

        return this.httpProxy.post(
            url, body, 'response', true
        )
            .map((val) => {
                return val;
            });
    }
}
