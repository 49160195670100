import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, delay, mergeMap, concatMap, flatMap } from 'rxjs/operators';



export interface IRequestOptions {
    body?: any;
    headers?: HttpHeaders | { [header: string]: string | Array<string> };
    observe?: any;
    params?: HttpParams | { [param: string]: string | Array<string> };
    reportProgress?: boolean;
    responseType?: "arraybuffer" | "blob" | "json" | "text";
    withCredentials?: boolean;
}

@Injectable()
export class HttpProxy {
    private log = console;

    constructor(private http: HttpClient, private tokenService: TokenService) {
    }


    checkAccessToken(): Observable<any> {
        //return observable // check the token here// return the valid token
        // console.log('|||=http-proxy.service -||| checkAccessToken====:return this.tokenService.returnAccessToken()');
        return this.tokenService.returnAccessToken();
    }
    // setParams(parameters:HttpParams | { [param: string]: string | Array<string> }){
    //   let  params: {organisation_id:"",pbx_platform_id_list:[],provider_name_list:[],search_string:"",page_size:20,page_number:1};
    //   return params;
    // }
    setOptions(responseType: "response" | "body", bodyContent?: any) {
        const option = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: responseType as 'body', body: bodyContent }
        return option;
    }

    // addHeaders(options) {

    //     if (this.token.get()) {
    //         const token = 'Bearer ' + this.token.get() ;
    //         options.headers = options.headers.append('Authorization', token);
    //     }
    // }

    // addHeaders(options) {
    //     const token = 'Bearer ' + this.tokenService.get() ;
    //     options.headers = options.headers.append('Authorization', token);
    //     return options;
    // }

    _addHeaders(options, access_token) {
        const token = 'Bearer ' + access_token ;
        options.headers = options.headers.append('Authorization', token);
        return true;
    }

    get<T>(url: string, observe: "response" | "body", Authorization?: boolean): Observable<T> {

        const options = this.setOptions(observe);

        if ( Authorization !== true ) {
            return this.http.get<T>(url, options);
        } else {
            return this.checkAccessToken()
                .pipe(
                    map(token => {
                        return this._addHeaders(options, token);
                    })
                )
                .switchMap(
                    (resp) => {
                        // console.log('|||=http-proxy.service -||| get() switchmap on checkAccessToken. continue with API call..');
                        return this.http.get<T>(url, options);
                    }
                )
        }

    }


    delete<T>(url: string, observe: "response" | "body", Authorization?: boolean, body?: any): Observable<T> {
        this.log.info('Delete request...');
        const options = this.setOptions(observe, body);


        if ( Authorization !== true) {
            return this.http.delete<T>(url, options);
        } else {
            return this.checkAccessToken()
                .pipe(
                    map(token => {
                        return this._addHeaders(options, token);
                    })
                )
                .switchMap(
                    (resp) => {{
                        console.log("subscribed DELETE API", resp)
                        return this.http.delete<T>(url, options);
                    }}
                );
        }


    }

    post<T>(url: string, body: any, observe: "response" | "body", Authorization?: boolean) {
        this.log.info('Posting Update');
        const options = this.setOptions(observe);

        if ( Authorization !== true) {
            return this.http.post<T>(url, body, options)
        } else {
            return this.checkAccessToken()
                .pipe(
                    map(token => {
                        return this._addHeaders(options, token);
                    })
                )
                .switchMap(
                    (resp) => {{
                        // console.log('|||=http-proxy.service -||| post() switchmap on checkAccessToken. continue with API call..');
                        return this.http.post<T>(url, body, options)
                    }}
                );
        }
    }

    patch<T>(url: string, body: any, observe: "response" | "body"): Observable<T>   {
        this.log.info('Patching Update');
        const options = this.setOptions(observe);

        return this.checkAccessToken()
            .pipe(
                map(token => {
                    return this._addHeaders(options, token);
                })
            )
            .switchMap(
                (resp) => {{
                    // console.log('|||=http-proxy.service -||| patch() switchmap on checkAccessToken. continue with API call..');
                    return this.http.patch<T>(url, body, options)
                }}
            );

    }

    // patch_legacy(url: string, body: any, observe: "response" | "body", Authorization?: boolean)  {
    //     this.log.info('Patching Update');
    //     const options = this.setOptions(observe);
    //     // if (Authorization === true) { this.addHeaders(options) };
    //     this.addHeaders(options);
    //     return this.http.patch(url, body, options)
    // }

    put<T>(url: string, body: any, observe: "response" | "body", Authorization?: boolean) {
        //handle differently when public route.
        this.log.info('Putting Update');
        const options = this.setOptions(observe);

        if ( Authorization !== true) {
            return this.http.put<T>(url, body, options)
        } else {
            return this.checkAccessToken()
                .pipe(
                    map(token => {
                        return this._addHeaders(options, token);
                    })
                )
                .switchMap(
                    (resp) => {{
                        // console.log('|||=http-proxy.service -||| put() switchmap on checkAccessToken. continue with API call..');
                        return this.http.put<T>(url, body, options)
                    }}
                );
        }
    }

    handleError<T>(operation = 'operation', result?: T) {
        // console.log('|||=http-proxy.service -||| handleError.  return result: ', result);
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            this.log.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
