import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { LicenseService } from '@app/services/pipcall/license.service';
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { NumberService } from '@app/services/pipcall/number.service';

//component
import { OrgTabLicencesComponent } from './org-licences.component'

//modules
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { InviteUserModalModule} from '@app/components/modals/invite-user-modal/invite-user-modal.module';
import { FirstPaymentModalModule } from '@app/components/modals/first-payment-modal/first-payment-modal.module';

// bottom sheet
import { UserProfileBottomSheetModule} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.module';
import { LicenceBottomSheetModule} from '@app/components/bottom-sheet-modal/licence-bsheet-modal/licence-bsheet-modal.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        CustomPipesModule,
        FlexLayoutModule,
        RouterModule,
        GravatarDirectiveModule,
        StatusIconsModule,
        UserProfileBottomSheetModule,
        InviteUserModalModule,
        LicenceBottomSheetModule,
        FirstPaymentModalModule
    ],
    declarations: [OrgTabLicencesComponent],
    exports: [OrgTabLicencesComponent],
    providers: [AppInsightService, OrganisationService, LicenseService, PaymentsService, NumberService],
    entryComponents: []
})
export class OrgTabLicencesModule { }
