import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  constructor() {

  }
  ngOnInit() {

  }
}
