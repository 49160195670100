
//core requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//modules - core
import { GravatarDirectiveModule} from '@app/directives/gravatar/gravatar.module';
import { MaterialModule } from '@app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { StatusIconsModule } from '../../components/status-icons/status-icons.module';
//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';

//declare components
import { DemoComponent } from './demo.component';
import { ExampleModalModule } from './example-modal/example-modal.module';
import { DragableExampleModalModule} from './dragable-example-modal/dragable-example-modal.module';
//import Modules
import { RightMenuContentsTemplateModule} from '@app/components/right-menu-overlay/right-menu-contents-template/contents-template.module';
import { SelectPackageModalModule} from '@app/components/modals/select-package-modal/select-package-modal.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        RouterModule,
        FlexLayoutModule,
        GravatarDirectiveModule,
        CustomPipesModule,
        RightMenuContentsTemplateModule,
        DragableExampleModalModule,
        ExampleModalModule,
        StatusIconsModule,
        SelectPackageModalModule
    ],
    declarations: [DemoComponent],
    providers: [HttpProxy, AppInsightService],
    entryComponents: []
})
export class DemoModule {
}

