//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil, map, filter } from 'rxjs/operators';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";

import { UserProfile } from '@app/models/user-profile.model';
import { UserService } from '@app/services/pipcall/user.service';
import { UserDetailUpdate } from '@app/models/user-profile.model';

import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { InviteUserModalComponentpipxt } from '@app/components/modals/invite-user-modal-pipxt/invite-user-modal.component'
import { LicenseModel, Licensedata } from '@app/models/license.model';
import { PaymentsService, } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { NumberService } from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';
import { InvitationService } from '@app/services/pipcall/invitation.service';

import { UsageEntry } from '@app/models/usage.model';
import { ToolbarDatesService, QueryParameters, ToDate, FromDate, QueryVals } from '@app/services/helpers/toolbar-dates.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';



@Component({
    selector: 'tab-ddi-overview',
    templateUrl: './ddi-overview.component.html',
    styleUrls: ['./ddi-overview.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class DDITabOverviewComponent {


    @Input() viewAs: string;
    @Input() number?: DdiNumber;
    @Input() organisation_id: string; //required
    @Input() number_id: string;
    @Input() availableUserList?: UserProfile[];

    @Output() updateParent = new EventEmitter();
    @Output() updateParentModal = new EventEmitter();
    @Output() closeParentModal = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();
    public sharedDataSubscription: Subscription;

    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }


    //forms
    userNameForm: FormGroup;

    userAllocationForm: FormGroup;
    filteredList: UserProfile[] = [];


    //usage
    public q: QueryParameters;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private userService: UserService,
        private toolbarDatesService: ToolbarDatesService,
        private signupService: SignupService,
        private normalizeNumberService: NormalizeNumberService,
        private numberService: NumberService,
        private ref: ChangeDetectorRef,
        private paymentsService: PaymentsService,
        private licenseDataService: LicenseDataService,
        private modalService: ModalService,
        private invitationService: InvitationService
    ) {

        const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');
    }

    ngOnChanges(changes: SimpleChanges) {
        (changes['number']?.currentValue !== changes['number']?.previousValue) &&  !changes['number']?.isFirstChange() ?  this.setFormValues(this.number) : null;
        (changes['availableUserList']?.currentValue !== changes['availableUserList']?.previousValue) ?  this.setUserList(this.availableUserList) : null;
    }


    ngOnInit() {

        console.log("[ddi overview tab] ngOnInit()", this.number)
        if (this.number) {
            // found a profile from parent
            //this.setFormValues(this.number);
            this.pageStatus.isLoading = false;
            console.log("availableUserList", this.availableUserList)
            this.availableUserList ? this.setUserList(this.availableUserList) : null;
        }

        if ( !this.number ) {
            // no profile from parent. get it now
            this.getDetails();
        }
    }

    setUserList(availableUserList: UserProfile[]) {
        this.filteredList = [];
        availableUserList.forEach(user => {
            if ((!user.organisation_list[0].invitation_id || user.organisation_list[0].is_invitation_accepted) && user.organisation_list[0].ddi_list.filter(x => x.number != null).length === 0 ) {
                this.filteredList.push(user);
            }
        })
        console.log("filtered users:", this.filteredList)
        this.ref.detectChanges();
    }

    setFormValues(number: DdiNumber) {
        //name form
        // this.setFormValue(this.userNameForm, 'last_name', userProfile.last_name);

    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }


    getDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;

        this.numberService.getDdiById(this.number_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                if (resp.status === 200 ) {
                    this.number = resp.body;
                    this.setFormValues(this.number);
                    this.ref.detectChanges();
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting user details [${err.status}] `, "dismiss");
            })


    }

    assignToUser(ddi: DdiNumber , user_id: string, name: string, organisation_id: string) {
        //add loading indicators for number status

        this.numberService.allocateDdi(
            ddi.number, user_id, organisation_id, name
        )
            .pipe(
                finalize(() => this.triggerUpdate())
            ).subscribe((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    this.openSnackBar(`${ddi.sip_extension_number} was assigned to ${name}`, "dismiss");
                    this.filteredList.splice(this.filteredList.findIndex(n => n.id === user_id ), 1);
                    this.triggerUpdate();
                }
            }, (err) => {
                this.openSnackBar('something went wrong', "dismiss");
                console.log(err) })

    }

    openInviteModal(number?: DdiNumber): void {
        number = number ? number : null;

        const data = {
            "organisation_id": number?.organisation_id, "preallocated_number": number
        };

        const dialogRef = this.dialog.open(InviteUserModalComponentpipxt, {
            panelClass: 'pipcall-modal-container',
            data
        });


        dialogRef.componentInstance.updateEmit.subscribe((resp) => {
            console.log("update emit");
            if (resp === true) {
                this.triggerUpdate();
                this.ref.detectChanges();
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            console.log("INVITE CLOSE", result);
            this.triggerUpdate();
            this.ref.detectChanges();
        });
    }

    alertRevokeInvitation(id: string) {

        console.log(id);

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'A user has been invited to use this extension. Are you sure you want to revoke this invitation?',
            confirmButtonLabel: "Revoke",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.revokeInvitation(id);
                return;
            }
        });

    }

    revokeInvitation(id: string) {
        this.invitationService.deleteSingleInvitation(id).subscribe((resp) => {

            if (resp.status === 200 || resp.status === 201) {
                this.triggerUpdate();
                this.ref.detectChanges();
                this.openSnackBar("Invitation was revoked", "dismiss");
                return;
            }
            this.openSnackBar("sorry something went wrong with this request", "dismiss");
        }, (err) => {
            console.log(err)
            this.openSnackBar(`There was an error [${err?.status}]`, "dismiss");
        })
    }


    saveMobile(f: FormGroup) {
        console.log("SAVE USER:", f);

        if (f.valid) {
            return this.submitForm(this.userService.patchUserMobile(f.getRawValue().id, f.value.mobile));
        }
    }

    user_unlink(number: string) {

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'Unlink this user and number',
            confirmButtonLabel: "Unlink",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.numberService.deallocateDdi(number)
                    .pipe(
                        finalize(() => {
                            this.pageStatus.isLoading = false;
                            // this.getDetails();
                            this.triggerUpdate();
                        })
                    )
                    .subscribe((resp) => { }, (err) => { console.log(err); })
                return;
            }
        });


    }

    cancelForm() {
        this.setFormValues(this.number);
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.detectChanges()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
