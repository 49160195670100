import { Injectable } from '@angular/core';
import { MatDialog } from '@app/material/material-essentials.module';
import { AlertComponent, ModalAlertData, AlertType } from './alert/alert.component';
import { ConfirmComponent, ModalConfirmData } from './confirm/confirm.component';

@Injectable()
export class ModalService {
    constructor(
        public dialog: MatDialog
    ) { }

    getAlertTitle(alertType: AlertType) {
        switch (alertType) {
            case AlertType.INFO:
                return 'INFO';
            case AlertType.WARNING:
                return 'WARNING';
            case AlertType.ERROR:
                return 'ERROR';
        }
    }

    openAlertModal(message: string, alertType: AlertType) {
        const dialogRef = this.dialog.open(AlertComponent, {
            width: '300px',
            data: new ModalAlertData({
                title: this.getAlertTitle(alertType),
                content: message,
                closeButtonLabel: 'Close',
                alertType: alertType
            })
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('After Close Modal', result);
        });
    }

    openInfoModal(message: string) {
        this.openAlertModal(message, AlertType.INFO);
    }

    openWarningModal(message: string) {
        this.openAlertModal(message, AlertType.WARNING);
    }

    openErrorModal(message: string) {
        this.openAlertModal(message, AlertType.ERROR);
    }

    openConfirmModal(data: ModalConfirmData, callBackFunction: Function, width?: string) {
    //choice true = yes & no , false = single option with callback

        const dialogRef = this.dialog.open(ConfirmComponent, {
            width: width ? width : '300px',
            data,
            disableClose: data.disableClose
        });

        dialogRef.afterClosed().subscribe(result => callBackFunction(result));
    }



}
