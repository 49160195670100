import { AbstractControl } from '@angular/forms';
import { SignupService } from '@app/services/pipcall/signup.service';
import { Observable, of, timer} from 'rxjs';
import 'rxjs/add/operator/switchMap';
import { map } from 'rxjs/operators';
import { debounceTime,  distinctUntilChanged, switchMap, catchError, finalize, first } from 'rxjs/operators';


//set allowNull to true if you want to allow empty email as valid form (when using email not required)
export class ValidateEmailNotTaken {
    static createValidator(signupService: SignupService, allowNull?: boolean) {
        return (control: AbstractControl) => {
            if (!control.valueChanges || (control.value === '' && allowNull)) {
                return of(null);
            } else {
                return control.valueChanges.pipe(
                    debounceTime(1000), // Adjust debounce time as needed
                    distinctUntilChanged(), // Only emit when value has changed
                    switchMap(value => signupService.checkIfEmailTaken(value)),
                    map(resp => {
                        console.log("Check email not taken", resp);

                        if (resp.status !== 200) {return { emailTaken: false, apiError: true }}
                        return resp.body ? { emailTaken: true } : null;
                    })).pipe(
                    first(),
                    catchError(() => {
                        console.log('API error in check');
                        control.setErrors({ emailTaken: false, apiError: true }); // Set control errors manually
                        return of({ emailTaken: false, apiError: true });
                    })
                );
            }
        };
    }
}



//this variation will only check for emails where has a mobile. i.e 'Does user with SMS identity exist'
//return false if user does not already exist
export class ValidateEmailWithSMSIdentityExists {
    static createValidator(signupService: SignupService, allowNull?: boolean) {
        return (control: AbstractControl) => {
            if (!control.valueChanges || (control.value === '' && allowNull)) {
                return of(null);
            } else {
                return control.valueChanges.pipe(
                    debounceTime(1000), // Adjust debounce time as needed
                    distinctUntilChanged(), // Only emit when value has changed
                    switchMap(value => signupService.checkUserWithSMSIdentityExists(value)),
                    map(resp => {
                        if (resp.status !== 200) {
                            return { emailTaken: false, apiError: true }}
                        return resp.body ? { emailTaken: true } :  null;
                    })).pipe(
                    first(),
                    catchError(() => {
                        console.log('API error in check');
                        control.setErrors({ emailTaken: false, apiError: true }); // Set control errors manually
                        return of({ emailTaken: false, apiError: true });
                    })
                );
            }
        };
    }
}

// export class ValidateEmailNotTakenOriginal {
//     static emailTimeout;
//     static createValidator(signupService: SignupService) {
//         return (control: AbstractControl) => {
//             clearTimeout(this.emailTimeout);
//             return new Promise((resolve, reject) => {
//                 this.emailTimeout = setTimeout(() => {
//                     signupService.checkEmailNotTaken(control.value)
//                         .subscribe(
//                             (response) => {  console.log("check email not taken"); response.bool ? resolve({ emailTaken: true }) : resolve(null); resolve(null);
//                             },
//                             (error)    => {resolve(true)}
//                         );
//                 }, 1400);
//             })

//         };
//     }
// }


// export class ValidateEmailNotTaken {
//     static createValidator(signupService: SignupService) {
//         return (control: AbstractControl) => {
//             return Observable.timer(500).switchMap(()=>{
//                 return signupService.checkEmailNotTaken(control.value)
//                     .delay(500)
//                     .map(res => {
//                         return res.bool ? { emailTaken: true } : null;
//                     })
//                     .catch(err=>Observable.of({emailTaken: true}));
//             });
//         };
//     }
// }

// export class ValidateEmailNotTaken {
//     static createValidator(signupService: SignupService) {
//         return (control: AbstractControl) => {

//                 return signupService.checkEmailNotTaken(control.value)
//                 .pipe(map(res => {
//                     return res.bool ? { emailTaken: true } : null;
//                   })
//                 )

//         };
//     }
// }
