<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header">

    <div>
      <span *ngIf="number?.organisation_name" class="subtitle-16">{{number?.organisation_name}}</span>
      <h5 style="color:var(--c-title)">{{number?.number | normalizeToInternational}}</h5>
    </div>

  </div>

  <div class="pip-modal-body" fxLayout="column" fxLayoutGap="10px">

    <ng-container *ngIf="number?.organisation_id && !number?.user_id; else error ">

      <div fxLayout="column">
        <div>Select user to link number to</div>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div xLayoutAlign="center">
          <mat-icon svgIcon="user" style="margin-bottom: 16px;"></mat-icon>
        </div>
        <app-ff-org-user-select showPipnumbers=true fxFlex="100%" [organisation_id]="number?.organisation_id"
          [selected_user_id]="number?.user_id" (selectedUser)="selectedUserUpdate($event)"
          [disabled]="pageStatus.isSubmitting"></app-ff-org-user-select>
      </div>

      <div fxLayoutAlign="end center" fxLayoutGap="4px">
        <button (click)="closeModal()" mat-flat-button>Cancel</button>
        <button
          [disabled]="userAllocationForm.controls['user_id'].value === '' || userAllocationForm.pristine || !userAllocationForm.valid || pageStatus.isSubmitting"
          mat-flat-button class="save-button" (click)="saveForm(userAllocationForm)">Save</button>
      </div>

    </ng-container>

    <ng-template #error>
     <div>Error: No organisation attached to number. Allocate an organisation before assigning to a user.</div>
    </ng-template>

  </div>

  <div class="pip-modal-footer">
    <!-- <button mat-flat-button color="primary">Test</button> -->
  </div>
</div>

<ng-template #spinner>
  <span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>
</ng-template>
