<ng-container *ngIf="!pageStatus.isError; else notFound">



  <ng-container *ngIf="!pageStatus.isLoading else loadingSpinner">


    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="start center" *ngIf="pageStatus.hasMobile === true">
      <div>User already is set up as a PiP user. </div>&nbsp;<button mat-stroked-button
        (click)="triggerUpdate()">Continue</button>
    </div>

    <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

      <form [formGroup]="inviteUserForm" fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2"
        [ngClass]="{'readonly-block':  pageStatus.isSubmitting}">

        <div fxLayout="column" fxLayoutAlign="start">

          <mat-form-field appearance="outline" class="custom-form-field-v3"
            style="min-width: 100%;margin-bottom: -10px;">
            <input type="email" matInput placeholder="Enter users email" formControlName="email">

            <mat-icon svgIcon="email" matPrefix fxHide.lt-sm></mat-icon>
            <mat-error *ngIf="inviteUserForm?.controls.email.errors?.required">
              Email is required</mat-error>
            <mat-error *ngIf="inviteUserForm?.controls.email.errors?.email">
              Email must be a valid email address</mat-error>
            <mat-error *ngIf="inviteUserForm?.controls.email.errors?.apiError">
              There was a server problem with the check</mat-error>
            <mat-error *ngIf="inviteUserForm?.controls.email?.status === 'PENDING'">
              checking ...
            </mat-error>
            <mat-error
              *ngIf="inviteUserForm?.controls?.email.errors && inviteUserForm?.controls?.email.value !== '' && inviteUserForm?.controls.email.errors.emailTaken">
              Email already in use
            </mat-error>

            <status-icons *ngIf="inviteUserForm?.controls.email.value && inviteUserForm?.controls.email.touched"
              [iconStatus]="inviteUserForm?.controls['email'].status" [hasPrefix]="true">
            </status-icons>
          </mat-form-field>

          <!-- <mat-form-field appearance="outline" class="custom-form-field-v3" style="min-width: 100%" *ngIf="existingPiPxt?.length > 0"> -->
          <mat-form-field appearance="outline" class="custom-form-field-v3" style="min-width: 100%">
            <mat-select placeholder="Select extension" formControlName="preallocated_number"
              (selectionChange)="emitValue($event)">

              <mat-option *ngFor="let num of existingPiPxt" [value]="num?.number">
                {{num?.sip_extension_cli | normalizeToInternational}}
              </mat-option>

              <ng-container *ngIf="existingPiPxt?.length === 0; else elseValid">
                <status-icons [iconStatus]="'INVALID'"></status-icons>
              </ng-container>

              <ng-template #elseValid>
                <status-icons [iconStatus]="inviteUserForm?.controls.preallocated_number?.status"></status-icons>
              </ng-template>

            </mat-select>

            <status-icons *ngIf="inviteUserForm?.controls.preallocated_number.value && inviteUserForm?.controls.preallocated_number.touched"
            [iconStatus]="inviteUserForm?.controls['preallocated_number'].status">
          </status-icons>
          </mat-form-field>


        </div>
        <button *ngIf="existingPiPxt?.length !== 0"
          [disabled]="inviteUserForm?.pristine ||  pageStatus.isSubmitting || inviteUserForm.invalid || inviteUserForm?.pending"
          style="margin-top: 10px;" aria-label="Save Changes" mat-flat-button class="save-button" (click)="sendInvitation()">Send Invitation</button>

        <!-- <div>inviteUserForm?.pristine||{{inviteUserForm?.pristine}}</div>
        <div>inviteUserForm.valid||{{inviteUserForm.valid}}</div>
        <div>inviteUserForm.pending||{{inviteUserForm.pending}}</div>
        <div>inviteUserForm.status||{{inviteUserForm.status}}</div>

        <div>Email</div>

        <div>inviteUserForm?.controls.email.pristine||{{inviteUserForm?.controls.email.pristine}}</div>
        <div>inviteUserForm.controls.email..valid||{{inviteUserForm.controls.email.valid}}</div>
        <div>inviteUserForm.controls.email..pending||{{inviteUserForm.controls.email.pending}}</div>
        <div>inviteUserForm.controls.email..status||{{inviteUserForm.controls.email.status}}</div> -->
      </form>

      <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1"
        style="line-height: 1.4em; padding-top: 16px">
        <span *ngIf="existingPiPxt.length !== 0">We will send an invitation to this email address for the user to
          register a handset and link an extension. You will be able access the PiPcall app once complete.</span>
        <span *ngIf="existingPiPxt.length === 0"><mat-icon svgIcon="warning" color="warn"></mat-icon> There are no available extensions to link to this user, Please contact
          support@pipcall if you require more.</span>
      </div>


    </div>
  </ng-container>
</ng-container>

<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>

<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>