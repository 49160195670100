<ng-container *ngIf="filteredList?.length > 0">


  <!-- <div class="section-divider"></div> -->

  <div class="section-wrapper">

    <div class="section-content">

      <!-- <div class="section-title" fxLayoutAlign="space-between start">
      <div>
      Special
      </div>
    </div> -->

      <table class="table tab-table-basic table-bordered"  [ngClass]="{'disable-actions': viewAs !== 'sysAdmin' && (organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled' || organisationProfile?.state === 'Declined')}">
        <thead>
          <tr class="headers">
            <th style="width: 20px;">&nbsp;</th>
            <th style="width: 300px;">Number (No extension)</th>
            <th>PBX Host name</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <!-- ngRepeat: member in members -->
          <ng-container *ngFor="let number of filteredList">
            <tr>

              <td>
                <mat-icon *ngIf="!number.user_id && number.state === 'Error'" class="status-circle reject-col"
                  [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="{{number.event_data}}"
                  svgIcon="circle">
                </mat-icon>

                <mat-icon *ngIf="number.user_id && number.state === 'Error'" class="status-circle reject-col"
                  [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="{{number.event_data}}"
                  svgIcon="circle-checked">
                </mat-icon>


                <!-- warning -->
                <mat-icon *ngIf="number.state === 'Unknown'" class="status-circle warning-col"
                  [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                  matTooltip="SIP credentials are pending a systems check." svgIcon="circle"></mat-icon>


                <!-- green -->
                <mat-icon *ngIf="!number.user_id && number.state === 'OK'" class="status-circle accept-col"
                  [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                  matTooltip="SIP credentials check was a success. Please assign a user." svgIcon="circle">
                </mat-icon>


                <mat-icon *ngIf="number.user_id && number.state === 'OK'" class="status-circle accept-col"
                  [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="SIP is active."
                  svgIcon="circle-checked">

                </mat-icon>

              </td>
              <td>
                <ng-container *ngIf="number?.sip_extension_number;else noextension">{{number?.sip_extension_number}}
                </ng-container>
                <ng-template #noextension>
                  <span (click)="openExtensionProfile(number)" class="clickable"
                    style="color: var(--c-url)">{{number.number | normalizeToInternational}}</span>

                </ng-template>
              </td>
              <td>
                {{number.sip_host_name}}
              </td>
              <td>
                <ng-container *ngIf="number.user_full_name || number.invitation_email; else unassigned">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <ng-container *ngIf="number.user_id">


                      <div class="usr-avatar-md" fxHide.lt-md>
                        <img appGravatar [firstname]="number.user_first_name" [lastname]="number.user_last_name"
                          [email]="number.user_email">
                      </div>

                      <div fxLayout="column" style="padding-left:12px;">
                        <div class="clickable" style="color: var(--c-url);padding-bottom: 4px;"
                          (click)="openUserProfile(number?.user_id)">{{number.user_first_name}}
                          {{number.user_last_name}}</div>
                        <div>{{number.user_email}}</div>
                      </div>

                    </ng-container>

                    <ng-container *ngIf="!number.user_id && number.invitation_email">


                      <div class="usr-avatar-md" fxHide.lt-md>
                        <div class="empty-user-avatar email-icon-avatar" fxLayout="column"
                          fxLayoutAlign="center center">
                          <mat-icon svgIcon="email"></mat-icon>
                        </div>
                      </div>

                      <div fxLayout="column" style="padding-left:12px;">
                        <div>Pending Invitation</div>
                        <div>{{number.invitation_email}}</div>
                      </div>

                    </ng-container>

                  </div>

                </ng-container>

                <ng-template #unassigned>
                  <div fxLayout="row" fxLayoutAlign="start center">

                    <div class="usr-avatar-md" fxHide.lt-md fxLayout="column" fxLayoutAlign="center center">
                      <div class="empty-user-avatar">

                      </div>
                    </div>

                    <div fxLayout="column" style="padding-left:12px;">
                      <div *ngIf="viewAs !== 'isAdmin' && viewAs !== 'sysAdmin'" style="color:var(--c-placeholder)">
                        -No user-</div>
                      <div *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'" [matMenuTriggerData]="{number: number}"
                        [matMenuTriggerFor]="addUser" class="vertical-algin: middle;" class="adduser clickable">
                        <button mat-flat-button class="blue-button btn-md ">Link User</button>
                      </div>

                    </div>
                  </div>

                  <!-- 
            <div style="height: 40px" fxLayout="row" fxLayoutAlign="start center">
              
              <span *ngIf="viewAs !== 'isAdmin'" style="color:var(--c-placeholder)">-unassigned-</span>

              <span *ngIf="viewAs === 'isAdmin'" [matMenuTriggerData]="{number: number}" [matMenuTriggerFor]="addUser"  class="vertical-algin: middle;" class="adduser clickable">Assign user <span class="vertical-algin: middle;"><mat-icon svgIcon="add"></mat-icon></span>
            </span>

            </div> -->

                </ng-template>
              </td>
              <td class="menu-last-cell">
                <button class="edit-option-button" mat-icon-button [matMenuTriggerData]="{number: number}"
                  [matMenuTriggerFor]="editSip">

                  <mat-icon title="Edit extension" fxHide.lt-md>
                    more_horiz</mat-icon>

                  <mat-icon title="Edit extension" fxHide.gt-sm>
                    more_vert</mat-icon>

                </button>
              </td>
            </tr>
          </ng-container>


        </tbody>
      </table>

    </div>
  </div>

</ng-container>
<mat-menu #editSip="matMenu" class="v3-mat-menu">

  <ng-template matMenuContent let-number="number">

    <button mat-menu-item title="Edit settings for this extension" (click)="openExtensionProfile(number)">
      <mat-icon svgIcon="settings"></mat-icon>
      <span>Edit Settings</span>
    </button>


    <button class="red-item red-item-full" [disabled]="number?.invitation_id || number?.user_id"
      *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'" mat-menu-item title="Delete this extension"
      (click)="deleteNumber(number)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Delete Extension</span>
    </button>

    <button *ngIf="number.invitation_id" mat-menu-item title="Revoke invitation for this extension"
      (click)="alertRevokeInvitation(number.invitation_id)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Revoke Invitation</span>
    </button>
  </ng-template>
</mat-menu>



<mat-menu #addUser="matMenu" class="v3-mat-menu" xPosition="before">

  <ng-template matMenuContent let-number="number">
    <button mat-menu-item title="Invite a user to be assigned number" (click)="openInviteModal(number)">

      <mat-icon>person_add</mat-icon>
      <span>Invite New User</span>
    </button>

    <button mat-menu-item title="Link with existing user" [matMenuTriggerFor]="availableUsers"
      [matMenuTriggerData]="{number: number}">
      <mat-icon>swap_horiz</mat-icon>
      <span>Link Existing</span>
    </button>


  </ng-template>
</mat-menu>

<mat-menu #availableUsers="matMenu" class="v3-mat-menuu">

  <ng-template matMenuContent let-number="number">
    <div mat-menu-item *ngIf="availableUserList?.length === 0">There are no available users</div>

    <ng-container *ngFor="let user of availableUserList">
      <button mat-menu-item title="Link number with user"
        (click)="assignToUser(number, user.id, user.first_name, number.organisation_id)">
        <span>{{user.first_name}} {{user.last_name}}</span>
      </button>
    </ng-container>

  </ng-template>
</mat-menu>
