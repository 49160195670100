<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div class="tab-child-view-container">


      <div class="section-wrapper dark-colors">
        <div class="section-title" fxLayoutAlign="center">

          <div><strong>Get Started</strong></div>

        </div>

        <div class="section-content">
          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px"
            fxLayoutAlign="start">
            <div fxLayout="column" fxFlex="100%" style="text-align: center;padding-left: 10px; padding-right:10px;">
              Welcome to the PiPcall admin portal. Follow the simple steps below to start the trial.
              <!-- <button mat-button (click)="test()">test console</button> -->
            </div>

          </div>

        </div>
      </div>

      <div class="section-wrapper" style="min-height: 600px;">


        <div class="section-content" fxLayout="column">

          <!-- <button (click)="showDebugInfoBox = showDebugInfoBox ? false:true">toggle debug info</button> -->
          <!-- <div *ngIf="showDebugInfoBox">
            <div style="margin-bottom: 10px">Debug Box: this is for testing only.</div>
            <div style="margin-bottom: 10px">Unescaped(organisationProfile?.metadata):<br> {{debug?.unescapedString}}</div>
            <div style="margin-bottom: 10px">-----Formated for return-----</div>
            <div style="margin-bottom: 10px">escape(JSON.stringify(metaObj)): <br>{{debug?.escapeJSONstring}} </div>

          </div> -->
          <mat-accordion class="get-started-accordian" displayMode="flat">

            <mat-expansion-panel [expanded]="stage === 1" (opened)="setStage(1)" class="border-top margin-top-50"
              [disabled]="stage > 1"
              [ngClass]="{'is-disabled': stage > 1, 'is-completed': stage > 1, 'is-pending': stage <= 1}">

              <mat-expansion-panel-header>
                <mat-panel-title class="panel-title" fxLayoutAlign="start center">
                  <div class="circled-number" fxLayoutAlign="center center">
                    <ng-container *ngIf="stage <= 1;else checkIcon">1</ng-container>
                  </div> <span class="panel-title">Tell us about yourself</span>
                </mat-panel-title>
                <mat-panel-description>
                  <span *ngIf="stage > 1" class="completed">Completed ✓</span>
                </mat-panel-description>
              </mat-expansion-panel-header>


              <div class="inner-padding">
                <app-onboard-organisation-details [organisationProfile]="organisationProfile"
                  [organisation_id]="organisation_id" (moveToNextStage)="setStage(this.stage + 1)"
                  [stageId]=1></app-onboard-organisation-details>
              </div>

            </mat-expansion-panel>


            <!-- <mat-expansion-panel [expanded]="stage === 2" (opened)="setStage(2)" [disabled]="stage !== 2"
              [disabled]="stage !== 2"
              [ngClass]="{'is-disabled': stage !== 2, 'is-completed': stage > 2, 'is-pending': stage <= 2}">
              <mat-expansion-panel-header>

                <mat-panel-title class="panel-title" fxLayoutAlign="start center">
                  <div class="circled-number" fxLayoutAlign="center center">
                    <ng-container *ngIf="stage <= 2;else checkIcon">2</ng-container>
                  </div> <span class="panel-title">Add billing contact</span>
                </mat-panel-title>
                <mat-panel-description>
                  <span *ngIf="stage > 2 && !isStageSkipped(2)" class="completed">Completed ✓</span>
                  <span *ngIf="stage > 2 && isStageSkipped(2)" class="completed">Skipped ✓</span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="inner-padding">
                <app-onboard-billing-details [current_user_email]="activeUser?.email"
                  [organisationProfile]="organisationProfile" [organisation_id]="organisation_id" [stageId]=2 (skipStage)="skippedStage(stage)"
                  (moveToNextStage)="setStage(this.stage + 1)"></app-onboard-billing-details>
              </div>

            </mat-expansion-panel> -->


            <mat-expansion-panel [expanded]="stage === 2" (opened)="setStage(2)" [disabled]="stage !== 2"
              [ngClass]="{'is-disabled': stage !== 2, 'is-completed': stage > 2, 'is-pending': stage <= 2}">
              <mat-expansion-panel-header>
                <mat-panel-title class="panel-title" fxLayoutAlign="start center">
                  <div class="circled-number" fxLayoutAlign="center center">
                    <ng-container *ngIf="stage <= 2;else checkIcon">2</ng-container>
                  </div><span class="panel-title">Add PiPcall user/s</span>
                </mat-panel-title>
                <mat-panel-description>
                  <span *ngIf="stage > 2" class="completed">Completed ✓</span>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="inner-padding">
                <app-user-invitations (moveToNextStage)="setStage(this.stage + 1)" [userProfile]="activeUser"
                  [organisation_id]="organisation_id" [stageId]=2></app-user-invitations>

              </div>

            </mat-expansion-panel>

          </mat-accordion>

          <ng-template #checkIcon>
            <mat-icon svgIcon="check"></mat-icon>
          </ng-template>


          <div *ngIf="stage === 3" class="final-message">

            <h5>Thanks, you are ready to go</h5>

            <p>
              You are all ready for your organisation to start making and recieving business calls.
              Select one of the nagivation tabs to explore your admin portal. You can view your team
              by visiting the <a matTooltip="users" class="clickable no-underline"
                (click)="navigationToTab('users')">users tab.</a>
            </p>


          </div>

        </div>
      </div>


    </div>

    <div class="section-divider"></div>
  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>