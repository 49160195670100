import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//modules (imports)
import { MaterialModule} from '@app/material/material.module';
import { CustomPipesModule} from '@app/pipes/pipes.module'

//components (declarations)
import {TenantSearchSelectV2Component} from './ff-tenant-search-select-v2.component'
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { PBXService } from '@app/services/pipcall/pbx.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        MatAutocompleteModule
    ],
    declarations: [TenantSearchSelectV2Component],
    exports: [TenantSearchSelectV2Component],
    providers: [HttpProxy, PBXService],
    entryComponents: []
})
export class TenantSearchSelectV2Module {
}
