//essentials
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//components

import { InvitationComponent } from './invitation-verify/invitation-verify.component';
import { InvitationDetailsUpdateComponent } from './invitation-update-details/invitation-verify-details.component';
import { VerifiedWelcomeComponent } from './verified/welcome/verified-welcome.component';


const routes: Routes = [
    {
        path: 'invitation/:id',
        component: InvitationDetailsUpdateComponent,
        pathMatch: 'full'
    },
    {
        path: 'invitation/:id/sms',
        component: InvitationComponent,
        pathMatch: 'full'
    },
    {
        path: 'invitation/:id/sms/verified',
        component: VerifiedWelcomeComponent,
        data: {
            path: 'invitation',
        }
    },
    {
        path: '**',
        redirectTo: '/pagenotfound',
    }

];

export const VerifyRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
