import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { cartProvision } from '../../models/cart.model';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '../../models/response.model';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';

export class ExpoSignupModel {
    first_name: string;
    last_name: string;
    organisation_name: string;
    email: string;
    mobile: string;
    metadata: string;
}

export class ExpoDataModel {
    expo_id: string;
    state: string;
    first_name: string;
    last_name: string;
    organisation_name: string;
    organisation_id: string;
    sales_user_name: string;
    sales_user_id: string;
    first_launch_date: Date;
    email: string;
    mobile: string;
    is_mobile_verified: boolean;
    metadata: string;
    notes: string;
    FullName: string;
    Id: string;
    created_at: Date;
    created_by: string;
    modified_at: Date;
    modified_by: string;
}

@Injectable()
export class ExpoService {


    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;
    public options;


    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }


    public postNewExpoSignup(expoSignup: ExpoSignupModel): Observable<responseModel> {
        //just recall putCartMobile route again to resendsms
        console.log("[[api]] -> new expo sign up");
        return this.httpProxy.post(this.ApiBaseUrl + `/expo_signups${this.code}`, expoSignup, 'response', true);
    }


    public getExpoSignupByID(expo_signup_request_id: string): Observable<responseModel> {
        console.log("[[api]] -> get expo signup by id");
        return this.httpProxy.get(this.ApiBaseUrl + `/expo_signups/${expo_signup_request_id}${this.code}`, 'response', true);
    }

    public searchExpoSignups(queryParams?: QueryParams): Observable<responseModel> {
        console.log("[[api]] -> get expo search query", queryParams);

        const paginationString = this.portalApiV2HelperService.getPaginationString(queryParams);
        const sortString = this.portalApiV2HelperService.getSortString(queryParams);
        const _searchString = queryParams?.searchString ? queryParams.searchString : '';
        console.log( `/v2/expo_signups/search${this.code}&rest=${paginationString}${sortString}${_searchString}`);
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/expo_signups/search${this.code}&rest=${paginationString}${sortString}${_searchString}`, 'response', true);
    }

    public patchExpoState(mobile: string): Observable<responseModel> {
        console.log("[[api]] -> patch verification state");
        const body = {
            state: 'Migrated'
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/expo_signups/${mobile}/state${this.code}`, body, 'response');
    }

    public patchExpoNotes(Id: string, note: string): Observable<responseModel> {
        console.log("[[api]] -> patch expo note");
        const body = {
            note: note
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/expo_signups/${Id}/notes/${this.code}`, body, 'response');
    }

    public patchSalesUser(Id: string, user_id: string): Observable<responseModel> {
        console.log("[[api]] -> patch sales user");
        const body = {
            sales_user_id: user_id
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/expo_signups/${Id}/salesuser${this.code}`, body, 'response');
    }
}

