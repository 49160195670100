//core
import { Component, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';

@Component({
    templateUrl: './download-links-modal.component.html',
    styleUrls: ['./download-links-modal.component.scss'],
    animations: [

    ]
})
export class DownloadLinksModalComponent {


    @ViewChild(DownloadLinksModalComponent) downloadLinksModalComponent: DownloadLinksModalComponent;

    constructor(
        private dialogRef: MatDialogRef<DownloadLinksModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
        private appInsightService: AppInsightService,
        private authService: AuthService,
    ) {


    }

    downloadiosApp() {
        this.appInsightService.logEvent('Download ios clicked', { 'userid': this.authService.getUserId() });
        (window as any).open('https://itunes.apple.com/app/apple-store/id1410293712?pt=1588242&ct=Trialists%20App%20Download&mt=8', "_blank");
    }

    downloadAndroidApp() {
        this.appInsightService.logEvent('Download Android clicked', { 'userid': this.authService.getUserId() });
        (window as any).open('https://play.google.com/store/apps/details?id=com.teliqo.pipcall', "_blank");
    }

    //onelink
    //http://onelink.to/xchkkk

    closeModal() {
        this.dialogRef.close(true);
    }

    scrollEvent(event) {
        const el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop !== el.clientHeight ? el.classList.add('pip-scroll-shadow-after') : el.classList.remove('pip-scroll-shadow-after'); ;
    }

}

