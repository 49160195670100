import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
//requirements
import { CommonModule } from '@angular/common';
import {StatusIconsComponent} from './status-icons.component';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [StatusIconsComponent],
    exports: [StatusIconsComponent],
    providers: [],
    entryComponents: []
})
export class StatusIconsModule { }
