<div class="pip-modal-content">

  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header" fxLayout="row" fxLayoutAlign="center center">
    <div class="pip-modal-title"><span  *ngIf="!markAsVerified;">Verify Handset</span><span  *ngIf="markAsVerified;">Download App</span></div>
  </div>

  <div class="pip-modal-body" fxLayout="column">

    <ng-container *ngIf="!markAsVerified; else appDownload">

      <div class="margin-top-bottom-30">
        <div style="line-height: 1.4em;">We've just sent a code to <strong>{{data?.handset}}</strong>, enter it below to
          verify your handset.</div>
        <div style="margin-top: 10px;text-align: center;"><span>{{sendingMessage}}</span></div>
        <div class="error-message" *ngIf="error?.isError">{{error?.message}}</div>
      </div>



      <form [formGroup]="verificationForm" fxLayout="column" fxFlex="100%"
        [ngClass]="{'readonly-block': pageStatus.isSubmitting}" style="width:100%; margin-bottom: 20px;">


        <code-input [isCodeHidden]="false" [codeLength]="6" [isFocusingOnLastByClickIfFilled]="true" [initialFocusField]="0" [disabled]="pageStatus.isSubmitting"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)">
    </code-input>
<!--     
        <mat-form-field appearance="outline" floatLabel='never' style="text-align:center;">
          <input type="tel" class="text-center" matInput placeholder="Verification Code" formControlName="code"
            pattern="[0-9]*" inputmode="numeric" autocomplete="one-time-code">
        </mat-form-field> -->


      </form>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%" style="margin-bottom:30px;">

        <button mat-flat-button aria-label="Cancel" class="verification-buttons" [disabled]="pageStatus.isSubmitting"
          (click)="closeModal()">
          <ng-container>Cancel</ng-container>
        </button>

        <!-- <button mat-flat-button aria-label="Resend" class="verification-buttons"
          [disabled]="pageStatus.isSubmitting ||  this.pageStatus.resendDisabled" (click)="sendCode()">
          <ng-container>Resend</ng-container>
        </button> -->

        <button mat-flat-button aria-label="Save Changes" class="save-button"
          [disabled]="verificationForm?.pristine ||  pageStatus.isSubmitting || verificationForm.invalid"
          (click)="verifyCode(verificationForm)">
          <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Submit</ng-container>
        </button>
      </div>

    </ng-container>
    
    <ng-template #appDownload>

      <div class="margin-top-bottom-30">
        <div>Download the PiPcall app to start making calls and note that your handset number, {{data?.handset}} will serve as the login ID.</div>
        </div>


          <div fxLayout="row" fxLayoutAlign="center space-between" fxLayoutGap="14px;" style="padding:10px;">

            <div fxLayout="column" fxLayoutAlign="center" style="padding-right: 20px;">
              
              <div style="padding: 4px;">
                <img src="assets/img/appstore-sm.png" alt="App Store" target="_blank" 
                  class="clickable" (click)="downloadiosApp()" style="width: 140px;">
              </div>
      
              <div style="padding: 4px;">
                <img src="assets/img/googleplay-sm.png" alt="Play Store" class="clickable second-option"
                  (click)="downloadAndroidApp()" style="width: 140px;">
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="text-center" >
                <img style="width: 120px;" src="assets/img/oneclick_appdownload.svg" alt="App Store QR link" >
              </div>
              
            </div>
      
      
          </div>

          <div style="margin-bottom: 18px;"><button style="width: 100%;" mat-flat-button aria-label="Close" color="primary" (click)="closeModal()">Close</button></div>

    </ng-template>
  </div>
</div>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>


<!-- <div class="verify-mobile text-center">
  <ng-container *ngIf="!verifySuccess;else success">

    <div class="actions-tray">

      <mat-form-field appearance="outline" [ngClass]="{'dark-mode': darkMode}">
        <input matInput placeholder="CODE" type="tel" autofocus="autofocus"  pattern="[0-9]*" inputmode="numeric" [(ngModel)]="verificationcode" autocomplete="one-time-code"
          (keyup.enter)="verifyCode(verificationcode, id)">

        <span id="notvalidmsg" *ngIf="notValidMsg">Code was not valid</span>
      </mat-form-field>
      
      <button mat-stroked-button [disabled]="resendButtonDisabled" [ngClass]="{'dark-mode': darkMode}"
        (click)="sendCode(id,mobile)" [style.position]="'relative'">
        <ng-container *ngIf="!submitted2; else spinner2">Resend Code</ng-container>
        <ng-template #spinner2><div class="loader-spinner"></div></ng-template>
        <span id="sentmsg" *ngIf="sentmsg" [ngClass]="{'dark-mode': darkMode}" [@fadeInOut]>sent!</span>
      </button>

      <button [ngClass]="{'dark-mode': darkMode}" [disabled]="submitted1" mat-raised-button color="accent"
        (click)="verifyCode(verificationcode, id)">
        <ng-container *ngIf="!submitted1;else spinner1">Verify</ng-container>
        <ng-template #spinner1><div class="loader-spinner"></div></ng-template>
      </button>



    </div>
  </ng-container>
  <ng-template #success>

    Hold tight, we are almost there ....

  </ng-template>
</div> -->
