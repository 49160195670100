



<button class="admin-3-dot-menu" [matMenuTriggerFor]="adminMenu" [matMenuTriggerData]="{organisation_id: organisation_id, is_blocked: is_blocked, is_auto_billing_enabled: is_auto_billing_enabled }"  mat-icon-button><mat-icon svgIcon="settings"></mat-icon></button>

<mat-menu #adminMenu="matMenu" class="v3-mat-menu" xPosition="before">

  <ng-template matMenuContent  let-organisation_id="organisation_id" let-is_blocked="is_blocked" let-is_auto_billing_enabled="is_auto_billing_enabled"> 

   <button   mat-menu-item matTooltip="Unblock the organisation" *ngIf="is_blocked" (click)="unblockOrganisation(organisation_id)">
      <mat-icon svgIcon="block"></mat-icon>
      <span class="matmenu-btn-label">Unblock organisation</span>
    </button>

    <button  *ngIf="!is_blocked" class="red-item" mat-menu-item matTooltip="Block the organisation" (click)="blockOrganisation(organisation_id)">
      <mat-icon svgIcon="block"></mat-icon>
      <span class="matmenu-btn-label">Block organisation</span>
    </button>
    

    <button *ngIf="is_auto_billing_enabled === false"  mat-menu-item matTooltip="Enable Automatic billing" (click)="confirmBillingState(organisation_id, is_auto_billing_enabled)">
      <mat-icon svgIcon="billing"></mat-icon>
      <span class="matmenu-btn-label">Enable automatic billing</span>
    </button>

    <button class="red-item"  *ngIf="is_auto_billing_enabled === true" mat-menu-item matTooltip="Disable Automatic billing" (click)="confirmBillingState(organisation_id, is_auto_billing_enabled)">
      <mat-icon svgIcon="billing"></mat-icon>
      <span class="matmenu-btn-label">Disable automatic billing</span>
    </button>  


  </ng-template>
</mat-menu>
