import {Component, TemplateRef, Output, ViewChild, Inject, SimpleChanges, EventEmitter} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import { InvoiceLicenseItem, Invoice, AddOnPackageItem, StubItem, AdHocItem } from '@app/models/invoice.model';
import { InvoiceService } from '@app/services/pipcall/invoice.service';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';

import * as FileSaver from 'file-saver';


@Component({
    selector: 'bs-invoice-bsheet-modal',
    templateUrl: './invoice-bsheet-modal.component.html',
    styleUrls: ['./invoice-bsheet-modal.component.scss']
})
export class InvoiceBottomSheetComponent {


    public viewAs: string; //owner,admin or user. set to owner if matches on return of data.
    public isloading = true;
    public notFound: boolean = null;

    invoice_id: string;
    invoice: Invoice;
    invoiceLicenseItems: InvoiceLicenseItem[];
    addOnPackageItems: AddOnPackageItem[];
    licenseStubItems: StubItem[];
    adHocItems: AdHocItem[];


    public pageStatus = {
        activeTab: 'overview',
        isLoading: true,
        noContent: false,
        dataChanged: false
    }

    _destroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {viewAs: string, invoice_id: string , organisation_id: string},
        private _bottomSheetRef: MatBottomSheetRef<InvoiceBottomSheetComponent>,
        private snackBar: MatSnackBar,
        private ref: ChangeDetectorRef,
        private invoiceService: InvoiceService
    ) {

        console.log("DATA:", this.data);

        this.setData();

    }

    ngOnInit() {

        this.pageStatus.isLoading = true;

        //if no invoice_id then set pagestatus to noconteent
        if (!this.invoice_id) {this.pageStatus.noContent = true; this.pageStatus.isLoading = false; return; } else {

            this.getInvoice(this.invoice_id);
        }

    }
    ngOnChanges(changes: SimpleChanges) {

    }

    setData() {
        this.viewAs = this.data?.viewAs;
        this.invoice_id = this.data?.invoice_id;
    }

    getInvoice(id: string) {
        this.pageStatus.isLoading = true;
        this.invoiceService.getInvoiceById(id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false; }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                this.invoice = resp.body;
                this.invoiceLicenseItems = resp.body.invoice_license_items;
                this.addOnPackageItems = resp.body.invoice_add_on_package_items;
                this.licenseStubItems = resp.body.invoice_license_stub_items;
                this.adHocItems = resp.body.invoice_ad_hoc_items;
                this.pageStatus.isLoading = false;
                this.ref.markForCheck();
            },
            (err) => {
                this.openSnackBar("could not get invoice details", "dismiss");
            })
    }





    updateParentEvent(event) {
        console.log("EVENT:", event);
        //to close the parent modal
        if (event === 'close') {this.dismiss()}
    }

    refreshData(event) {
        console.log("refresh data EVENT:", event);
        this.pageStatus.dataChanged = true;

    }

    dismiss() {
        this._bottomSheetRef.dismiss(this.pageStatus.dataChanged);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
}

