import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { responseModel } from '../../models/response.model';

import { debounceTime, tap, finalize , retry} from 'rxjs/operators';
import { Subscription, Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ThrowStmt } from '@angular/compiler';

export class Article {
    title: string;
    description: string;
    tags: string;
    category: string;
    is_active: boolean;
    min_portal_version: string;
    updated_by: string;
    content: string;
    sysadmin_content: string;
    PartitionKey: 'faq_content_en';
    RowKey: string;
    Timestamp: string;
    context_links: string;
}


@Injectable({
    providedIn: 'root',
})
export class ContentService {
    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;
    public options;



    //URLS
    private getArticleURL = 'https://prod-03.uksouth.logic.azure.com:443/workflows/ed5d72257a2f4c169b50292c07231f3a/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=n6jAUc-v5tIcClG0m6VH5rXTOEKU0GJEpAXXGqOejFs';
    private putArticleUpdateURL = "https://prod-01.uksouth.logic.azure.com:443/workflows/b8b8cb9bf22847f1a4d0d507e4868741/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=B6DDEqhEL9SCugxhKbvLPgWX1pS9MRv3FDdkpqfP6e4";
    private postNewArticle = "https://prod-12.uksouth.logic.azure.com:443/workflows/5063d9fedfe84aceac076aade55e7d55/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=LyzEZRcj1s0c1YwQS61ywo1N0JFdj9Q1-yvHV0Zx-IM";
    private deleteArticle = "?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=poD-mvatpM5wbxTqEOpvLuChg4Ww8qstE37J8BT1qnM";
    constructor(private httpProxy: HttpProxy, private http: HttpClient) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
        this.options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            observe: 'response'
        }
    }


    public getFaqArticles(): Observable<HttpResponse<Array<Article>>> {
        console.log("[[api]] -> get faq articles");
        return this.httpProxy.get(this.getArticleURL, 'response');
    }

    public putFaqArticleUpdate(body: Article) {
        console.log("[[api]] -> put faq article update");
        return this.httpProxy.put(this.putArticleUpdateURL, body, 'response');
    }

    public postNewFaqArticle(body: Article) {
        console.log("[[api]] -> post new faq article");
        return this.httpProxy.post(this.postNewArticle, body, 'response');
    }

    public deleteFaqArticle(rowKey: string, updated_by?: string) {
        const body = {
            updated_by: updated_by
        }
        const option = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' as 'body' }

        return this.http.patch('https://prod-22.uksouth.logic.azure.com/workflows/dfcc240ceb574d7e9813e574f9639945/triggers/manual/paths/invoke/partition/faq_content_en/' + rowKey + this.deleteArticle, body, option)
    }

    public restoreFaqArticle(body: Article) {
        console.log("[[api]] -> put restore faq article update");
        body.is_active = true;
        return this.httpProxy.put(this.putArticleUpdateURL, body, 'response');
    }



}
