import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
//requirements
import { CommonModule } from '@angular/common';
import { InviteUserModalComponentpipxt } from './invite-user-modal.component';
import { LicenseSelectModule } from '@app/components/license-select/license-select.module';
import { PricingModalModule } from '@app/components/modals/pricing-modal/pricing-modal.module';
//others
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { FlexLayoutModule } from '@angular/flex-layout'
import { TermsAndConditionsModalModule } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.module'

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        StatusIconsModule,
        FlexLayoutModule,
        LicenseSelectModule,
        PricingModalModule,
        TermsAndConditionsModalModule,
        CustomPipesModule
    ],
    declarations: [InviteUserModalComponentpipxt],
    exports: [InviteUserModalComponentpipxt],
    providers: [HttpProxy],
    entryComponents: [InviteUserModalComponentpipxt]
})
export class InviteUserModalpipxtModule { }
