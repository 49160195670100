//core
import { Component, ViewChild, Inject , ChangeDetectorRef} from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { switchMap, debounceTime, tap, finalize, takeUntil, map, filter } from 'rxjs/operators';
import { Subject, Subscription, Observable, throwError } from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';

import { NumberService } from '@app/services/pipcall/number.service'
import { DdiNumber } from '@app/models/ddi.model';


@Component({
    templateUrl: './allocate-ddi-to-user-modal.component.html',
    styleUrls: ['./allocate-ddi-to-user-modal.component.scss'],
    animations: [

    ]
})
export class AllocateDdiToUserModalComponent {


    number: DdiNumber;
    @ViewChild(AllocateDdiToUserModalComponent) allocateDdiToUserModalComponent: AllocateDdiToUserModalComponent;

    noContent = false;
    _destroy$ = new Subject<void>();


    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }

    userAllocationForm: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<AllocateDdiToUserModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {number: DdiNumber}, //contains data injected into modal.
        private appInsightService: AppInsightService,
        private authService: AuthService,
        private fb: FormBuilder,
        private numberService: NumberService,
        private ref: ChangeDetectorRef,
        private snackBar: MatSnackBar,
    ) {

        this.number = this.data?.number;

        this.userAllocationForm = this.fb.group({
            organisation_id: ['', Validators.required],
            number: ['', Validators.required],
            user_id: ['', Validators.required],
            name: ['', Validators.required],
        });
    }

    ngOnInit() {
        //throw error if no number
        if ( this.number) {
            this.setInitialFormValues(this.number);
        }
    }

    setInitialFormValues(number: DdiNumber) {
        //name form
        this.setFormValue(this.userAllocationForm, 'organisation_id', number.organisation_id);
        this.setFormValue(this.userAllocationForm, 'number', number.number);
        this.setFormValue(this.userAllocationForm, 'user_id', number.user_id);
        this.setFormValue(this.userAllocationForm, 'name', number.user_full_name);

        this.ref.markForCheck();
    }

    cancelForm() {
        this.setInitialFormValues(this.number);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    selectedUserUpdate(event) {
        console.log("selected user...", event);
        if (event?.user_id ) {
            this.userAllocationForm.controls['user_id'].patchValue(event?.user_id, {emitEvent: true});
            this.userAllocationForm.controls['name'].patchValue(event?.name, {emitEvent: true});
            this.userAllocationForm.markAsDirty();
        } {
            this.userAllocationForm.controls[''].patchValue('', {emitEvent: true});
            this.userAllocationForm.controls[''].patchValue('', {emitEvent: true});
            this.userAllocationForm.markAsDirty();
        }
    }

    saveForm(f: FormGroup) {
        console.log(f);
        if (f.valid) {

            return this.submitForm(this.numberService.allocateDdi(
                f.value.number,
                f.value.user_id,
                f.value.organisation_id,
                f.value.name
            ));

        }

    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.markForCheck()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.closeModal(true);
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    closeModal(result?: boolean) {
        this.dialogRef.close(result);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }
}

