import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '../../material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '../../services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//create modal components
import{VerifyMobileComponent } from './verify-mobile.component'
import {VerifyService} from '../../services/pipcall/verify.service';

@NgModule({
    imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
    ],
    declarations: [VerifyMobileComponent],
    exports: [VerifyMobileComponent],
    providers: [HttpProxy,VerifyService],
    entryComponents:[]
})
export class VerifyMobileModule { }