import { environment } from '../../environments/environment';
const namespace = `https://${environment.auth0_domain}`

//............provisioning..........//

export class OrgProvisioningRequest {
    name = '';
    owner = '';
}

//...............Org.............................//

interface IOrganisation {
    id: string;
    name: string;
    state: string;
    owner_user_id: string;
    owner_first_name: string;
    owner_last_name: string;
    owner_full_name: string;
    billing_entity: string;
    region: string;
    is_blocked: boolean;
    trial_end_date: string;
    is_auto_billing_enabled: boolean;
    billing_day: number;
    created_at: string;
    is_pipxt_enabled: boolean;
    is_pipmobile_enabled: boolean;
    initial_set_up_fee: number;
    monthly_service_charge: number;
    metadata: string;
    add_on_package_list: Map<string, string>;
}

export class OrganisationModel implements IOrganisation {
    billing_entity: string;
    id: string;
    name: string;
    state: string;
    owner_user_id: string;
    owner_first_name: string;
    owner_last_name: string;
    owner_full_name: string;
    region: string;
    is_blocked: boolean;
    trial_end_date: string;
    is_auto_billing_enabled: boolean;
    billing_day: number;
    contact_phone_number: string;
    type: string;
    is_pipxt_enabled: boolean;
    is_pipmobile_enabled: boolean;
    initial_set_up_fee: number;
    monthly_service_charge: number;
    notes: string;
    created_at: string;
    metadata: string;
    add_on_package_list: Map<string, string>;
}


export class OrganisationQueryRequest {
    organisation_state_list: string[];
    is_blocked_list: boolean[] = [false];
    search_string: string;
    page_size = 500;
    page_number = 0;
}

export class OrganisationDdiList {
    number: string;
    pbx_platform_id: string;
    organisation_id: string;
    provider: string;
    is_reserved: boolean;
    is_blocked: boolean;
    is_sms_enabled: boolean;
    dnd: boolean;
    label: string;
    cli_override: string;
    no_answer: string;
    user_id: string;
    user_first_name: string;
    user_last_name: string;
    user_full_name: string;
    user_email: string;
    organisation_name: string;
}

export class OrganisationQueryResponse {
    id: string;
    name: string;
    state: string;
    owner_user_id: string;
    owner_first_name:  string;
    owner_last_name:  string;
    owner_full_name:  string;
    is_blocked: boolean;
    is_pipxt_enabled: boolean;
    is_pipmobile_enabled: boolean;
    trial_end_date:  string;
    billing_day: number;
    is_auto_billing_enabled: boolean
    contact_phone_number: string;
    type: string;
    initial_set_up_fee: number;
    monthly_service_charge: number;
    created_at: string;
    notes: string;
    add_on_package_list: string;
    metadata: string;
}


export interface IOrgMetadataV1 {
    version?: string;
    modified_at?: string;
    source?: string;
    onboarding?: {
        stage: number;
        label: string;
    };
    organisation_info?: {
        size: string;
    };
    billing_details?: {
        company_number: string;
        billing_contact_email: string;
        registered_address: string;
    }
}

export class OrgMetaData implements IOrgMetadataV1 {
    version?: string;
    modified_at?: string;
    source?: string;
    onboarding?: {
        stage: number;
        label: string;
    };
    organisation_info?: {
        size: string;
    };
    billing_details?: {
        company_number: string;
        billing_contact_email: string;
        registered_address: string;
    }
}

