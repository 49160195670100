import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout'
import { DragDropModule } from '@angular/cdk/drag-drop';
//create modal components

import {DragableExampleModalComponent} from './dragable-example-modal.component'

@NgModule({
    imports: [
    MaterialModule,
    CommonModule,
    MaterialModule,
    DragDropModule,
    FlexLayoutModule
    ],
    declarations: [DragableExampleModalComponent],
    exports: [DragableExampleModalComponent],
    providers: [],
    entryComponents:[DragableExampleModalComponent]
})
export class DragableExampleModalModule { }