import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {  MatSnackBar } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Subject, Subscription } from "rxjs";
import { map, takeUntil, tap, first, finalize, distinctUntilChanged } from "rxjs/operators";
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service'
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { slideInRightOnEnterAnimation, bounceInUpOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeOutAnimation, collapseAnimation } from 'angular-animations';
@Component({
    selector: 'rm-contents-template',
    templateUrl: './contents-template.component.html',
    styleUrls: ['./contents-template.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        slideInRightOnEnterAnimation({ duration: 200 }),
        bounceInUpOnEnterAnimation(),
        fadeOutOnLeaveAnimation({ duration: 800 }),
        fadeInOnEnterAnimation({ duration: 400 }),
        fadeOutAnimation(),
        collapseAnimation()
    ]

})

export class TemplateRightMenu implements OnInit {

    data: any;
    _destroy$ = new Subject<void>(); //handles unsubscribing events
    public contextDataSubscription: Subscription;


    pageStatus = {
        isLoading: false,
        isSubmitting: false
    }

    viewAs: string;


    constructor(
        private router: Router,
        private sidenavRightService: SidenavRightService,
        private appInsightService: AppInsightService,
        public snackBar: MatSnackBar,
    ) {
        this.innit();
        this.getContextData();
    }

    innit() {
        this.pageStatus.isSubmitting = false;
        this.pageStatus.isLoading = true;
        this.viewAs = 'user'; //default
    }

    ngOnInit() {
        this.appInsightService.logPageView('PiPcall: Help Center');
    }

    getContextData() {
        this.pageStatus.isLoading = true;

        this.contextDataSubscription = this.sidenavRightService.getContextData$()
            .pipe(
                takeUntil(this._destroy$),
                first()
            )
            .subscribe(resp => {
                console.log("context data:", resp);
                this.data = resp;
                this.viewAs = this.data?.viewAs;
                this.pageStatus.isLoading = true;
            }, (err) => {
                console.log("error getting context data");
            })
    }

    close() {
        this.sidenavRightService.setOpenState(false);
    }

    getData() {

    }


    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
        this.contextDataSubscription ? this.contextDataSubscription.unsubscribe() : '';
    }
}

//to open in your component
//import component
//import import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service';
// openTemplateName(userId: string) {
//add context data
//     const data = { user_id: userId, org_id: this.organisationProfile.id, org_state: this.organisationProfile.state};

//     this.sidenavRightService.setContextData(data);
//     this.sidenavRightService.setComponetPortal(ActivateUserPipcalComponentl);

//     this.sidenavRightService.dataChangedAsObservable.subscribe((resp) => {
//         resp ? this.updateProfile.emit(this.organisationProfile.id) : null;
//     });

// }
