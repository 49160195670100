<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter]  class="tab-child-view-container">

      <div class="section-wrapper">

        <div class="section-title" fxLayoutAlign="space-between start">
          <div>Admin users</div>
        </div>
        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex="60%" fxFlexOrder.lt-md="2">

              <p>Use <strong class="title-color">Search user</strong> to find and add more administrators from your organisation. You must always have at least 1 admin and any admin can remove another. You can not however remove yourself. </p>

            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex="40%" >
              <div>
                <app-ff-org-user-search-select fxFlex="300px" [placeholder]="'Search user'" [organisationUserList]="organisationUserList" [simpleLayout]=true [organisation_id]="organisation_id" (_selectedItem)="updateUserSelectedEvent($event)"></app-ff-org-user-search-select>
              </div>
              <button mat-flat-button aria-label="Add Admin User" color="accent" style="width: 100px;" [disabled]="!userAdminForm.valid">
                <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Add admin</ng-container>
              </button>
            </div>


          </div>
        </div>
      </div>

      <div class="section-divider"></div>

      <div class="section-wrapper">

        <div class="section-content">

          <div fxLayout="column" fxFlex="100%">

            <table class="table tab-table-basic table-bordered">
              <thead>
                <tr>
                  <th colspan="2"></th>
                  <th class="cell-width-80">is PiPuser</th>
                  <th class="cell-width-80">Role</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <!-- ngRepeat: member in members -->
                <tr *ngFor="let user of organisationUserList">
                  <td class="profile-image">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div class="usr-avatar-md">
                        <img appGravatar [firstname]="user.first_name" [lastname]="user.last_name" [email]="user.email">
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="color: var(--c-url);padding-bottom: 4px;">{{user.first_name}} {{user.last_name}}</div>
                    <div>{{user.email}}</div>
                  </td>
                  <td>
                    <div><mat-icon *ngIf="user?.mobile" style="color: var(--c-accept)"svgIcon="check" matTooltip="Is a PiPuser"></mat-icon></div>
                  </td>
                  <td>
                    <div>Admin</div>
                  </td>
                  <td class="menu-last-cell">
                    <div>
                      <button class="button-bordered small-icon-btn" mat-icon-button>
                        <mat-icon svgIcon="more-horiz"></mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>


        </div>
      </div>


    </div>

    <!-- <div class="section-divider"></div> -->
  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>
