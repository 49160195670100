<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div class="section-wrapper">
      <tab-title-lined>Invitations&nbsp;<span style="font-size: 0.8em;">({{dataSource?.filteredData.length}})</span></tab-title-lined>


      <div class="section-action">
        <div style="justify-content: flex-end;width:100%;display:flex;">
          <button *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'" mat-flat-button aria-label="Invite user" color="accent" fxFlex="200px" fxFlex.lt-md="100%" (click)="inviteModal()"        [disabled]="organisationProfile?.state !== 'Active' && organisationProfile?.state !== 'Trial'">
            <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">
              <mat-icon svgIcon="add"></mat-icon>&nbsp;Invite User
            </ng-container>
          </button>
        </div>
      </div>


      <div class="section-content">
        <div fxLayout="column" fxFlex="100%" [hidden]="!(dataSource?.data?.length > 0)">


          <table mat-table class="table tab-table-basic clean-spaced clean-add-row-border" [dataSource]="dataSource" matSort>


            <ng-container matColumnDef="first_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let row">


                <div fxLayout="row" fxLayoutAlign="start center">

                 <div class="table-icon-btn-wrapper"><button (click)="$event.stopPropagation()" style="cursor: default" mat-icon-button
                    matTooltip="invitation" class="invitation-email-icon"><mat-icon svgIcon="email_v2"></mat-icon></button>
                </div> 


                  <div fxLayout="column" style="padding-left:12px;" class="email-wrapper" matTooltip="{{row?.email}}">
                    <div>{{row.email}} <span *ngIf="row.email === userProfile?.email">(MYSELF)</span>
                      <button style="margin-left: 10px;" *ngIf="row?.cli_override"
                        [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                        matTooltip="There is a temporary number port on this invitation, validation will be required on accepting the invitation: {{row?.cli_override | normalizeToInternational}}"
                        class="small-button system-blue-button">Number Port</button>
                    </div>
                  </div>

                </div>

              </td>
            </ng-container>


            <ng-container matColumnDef="settings">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row" style="text-align: right;">
                <!-- <mat-icon *ngIf="row?.is_sms_enabled" class="icon-22" svgIcon="message"  matTooltip="Number has SMS functionality enabled"></mat-icon> -->

                <div fxLayout="row" fxLayoutAlign="end center" class="invitation-information">

                  <div matTooltip="The number type allocated to the user" *ngIf="row?.allocation_option">{{row?.allocation_option}} |
                    {{returnNumberAllocationAreaCode(row?.allocation_option)}}</div>

                  <div *ngIf="row?.preallocated_number">

                    <span *ngIf="row?.preallocated_pip_variant==='pipmobile'"
                      [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                      matTooltip="PiPmobile pre-allocation: {{row?.preallocated_number | normalizeToInternational}}">
                      {{row?.preallocated_number | normalizeToInternational}}
                    </span>

                    <span *ngIf="row?.preallocated_pip_variant==='pipxt'"
                      [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                      matTooltip="PiPoffice pre-allocation: {{row?.preallocated_sip_extension_cli | normalizeToInternational}} ({{row?.preallocated_sip_extension_number}})">
                      {{row?.preallocated_sip_extension_cli | normalizeToInternational}}
                      ({{row?.preallocated_sip_extension_number}})
                    </span>
                  </div>


                  <div  *ngIf="row?.allocation_option=== null && row?.preallocated_number === null && row?.preallocated_pip_variant === null && row?.cli_override_request_id !== null">{{row?.cli_override}} | Port</div>


                  <div fxLayout="column" class="list-item-3-dot-wrapper" *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'">
                    <button mat-icon-button class="more-menu" [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                      matTooltip="Edit inviation" [matMenuTriggerFor]="invitationMenu" [matMenuTriggerData]="{invitation: row}"><mat-icon svgIcon="more_vert"></mat-icon></button>
                  </div>
                </div>

              </td>
            </ng-container>

            <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>

          </table>



          <div class="no-data" *ngIf="dataSource.filteredData && dataSource.filteredData.length === 0">No results
            returned</div>

          <mat-paginator itemsPerPageLabel="'items'" [pageSizeOptions]="[5, 10, 25]" [showFirstLastButtons]="true"
            [hidden]="dataSource.data.length < 5">
          </mat-paginator>


        </div>


        <div *ngIf="dataSource?.data?.length === 0">There are no open invitations</div>

      </div>
    </div>


    <!-- <div class="section-divider"></div> -->
  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying invitations
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>


<mat-menu #invitationMenu="matMenu" class="v3-mat-menu" >


  <ng-template matMenuContent let-invitation="invitation">


<ng-container *ngIf="viewAs === 'sysAdmin'">

  <div class="divider">Sys Admin</div>
  

  <button mat-menu-item matToolTip="Copy the url to clipboard" (click)="copyInvitationUrl(invitation?.id)">
    Invitation URL
  <mat-icon style="float:right" svgIcon="copy"></mat-icon>
</button>


<hr class="divider">
</ng-container>

    <button mat-menu-item matTooltip="Revoke invitation"
      class="red-item red-item-full" (click)="confirmRevokeInvitation(invitation?.id)" [disabled]="invitation?.license_id && organisationProfile?.state === 'Active'">
      <mat-icon svgIcon="trash"></mat-icon> Revoke Invitation
    </button>


    <div mat-menu-item class="no-hover" *ngIf="invitation?.license_id && organisationProfile?.state === 'Active'">
     <p>This invitation already has a paid licence linked to it. Contact support if you want to revoke the invitation.</p>
    </div>
    <ng-container>

    </ng-container>

  </ng-template>


</mat-menu>