import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';

export class ConfirmRemoveModalData {
    //placeholder for any data that needs to be passed
    contractException: boolean; //if contract is 1y_monthly then highlight it.

    constructor(data?) {
        if (data) {
            this.contractException = data?.contractException;
        }
    }
}

export class ModalResponse {
    confirm: boolean;
    ddi_action: 'keep' | 'return' | null;
}


@Component({
    selector: 'app-confirm-user-unlink',
    templateUrl: './confirm-user-unlink-modal.component.html',
    styleUrls: ['./confirm-user-unlink-modal.component.scss']
})
export class ConfirmUserUnlinkModalComponent {
    ddi_action: 'keep' | 'return' = null;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ConfirmRemoveModalData,
        private dialogRef: MatDialogRef<ConfirmUserUnlinkModalComponent>,
    ) { }


    closeDialog(confirm: boolean) {

        const response: ModalResponse = {
            confirm: confirm,
            ddi_action: this.ddi_action
        }

        this.dialogRef.close(response);
    }
}


