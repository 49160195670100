<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <span class="subtitle-12">{{organisation_name}}</span>
    <span style="color:var(--c-title);font-size: 22px;">Restore Invoice</span>
  </div>


  <div class="pip-modal-body" class="pip-modal-body" fxLayout="column">

    <div style="margin-top: 20px;" fxLayout="column" fxFlex="100">
  
      <form [formGroup]="updateNotesForm" autocomplete="off" fxLayout="column" fxFlex="100%">

        <mat-form-field floatLabel="always" appearance="outline" fxFlex="100%" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">
          <mat-label>ID of deleted invoice</mat-label>
          <input required autocomplete="off" type="text" matInput placeholder="Invoice id" formControlName="id">
          <status-icons *ngIf="updateNotesForm?.controls['id'].touched"  [iconStatus]="updateNotesForm?.controls['id']?.status">  </status-icons>
        </mat-form-field>

        <mat-form-field  appearance="outline" floatLabel="always" style="width:100%;margin-bottom: 10px;"
        [ngClass]="{'readonly-block': pageStatus.isSubmitting}" >
        <mat-label>Notes</mat-label>
        <textarea required formControlName="notes"  style="min-height: 100px;"  matInput
          placeholder="reason for restoring"></textarea>
       </mat-form-field> 

      </form>
    </div>




  </div>

  <div class="pip-modal-footer">

    <button mat-raised-button color="accent" [disabled]="!updateNotesForm.valid || updateNotesForm.pristine"
      (click)="submitForm(updateNotesForm)">
      <ng-container *ngIf="!pageStatus.isSubmitting;else loadingSpinner">Restore</ng-container>
    </button>

  </div>




</div>



<ng-template #loadingSpinner class="text-center">
  <div class="loader-spinner"></div>
</ng-template>
