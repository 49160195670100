import { NgModule } from '@angular/core';
import { MaterialModule} from '@app/material/material.module';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//requirements
import { CommonModule } from '@angular/common';

//others
import { PricingModalComponent} from './pricing-modal.component';
import { StatusIconsModule} from '@app/components/status-icons/status-icons.module';
import { FlexLayoutModule } from '@angular/flex-layout'
import { LicenceTariffsModule } from '@app/components/view-licence-tarrif/licence-tariffs.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        StatusIconsModule,
        FlexLayoutModule,
        LicenceTariffsModule

    ],
    declarations: [PricingModalComponent],
    exports: [PricingModalComponent],
    providers: [HttpProxy],
    entryComponents: [PricingModalComponent]
})
export class PricingModalModule { }
