import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';


//modules (imports)
import { MaterialModule} from '@app/material/material.module';
import { CustomPipesModule} from '@app/pipes/pipes.module'

//components (declarations)
import {OrgSearchSelectComponent} from './ff-org-search-select.component'
import {MatAutocompleteModule} from '@angular/material/autocomplete';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipesModule,
    MaterialModule,
    MatAutocompleteModule
  ],
  declarations: [OrgSearchSelectComponent],
  exports:[OrgSearchSelectComponent],
  providers: [HttpProxy],
  entryComponents: []
})
export class OrgSearchSelectModule{
}
