import { Injectable } from '@angular/core';
import { MatDialog } from '@app/material/material-essentials.module';
import { ConfirmUserUnlinkModalComponent, ConfirmRemoveModalData } from './confirm-user-unlink-modal.component';

@Injectable()
export class ConfirmUserUnlinkModalService {
    constructor(
        public dialog: MatDialog
    ) { }


    openConfirmRemoveSoftModal(data: ConfirmRemoveModalData, callBackFunction: Function, width?: string) {
    //choice true = yes & no , false = single option with callback

        const dialogRef = this.dialog.open(ConfirmUserUnlinkModalComponent, {
            width: width ? width : '400px',
            data,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => callBackFunction(result));
    }



}
