import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import {InvoiceComponent} from './invoice-modal.component';
import { InvoiceService } from '@app/services/pipcall/invoice.service';
@NgModule({
    imports: [
        CommonModule,
        CustomPipesModule,
        MaterialModule,
        FlexLayoutModule,
    ],
    declarations: [InvoiceComponent],
    exports: [InvoiceComponent],
    providers: [InvoiceService],
    entryComponents: [InvoiceComponent]
})
export class InvoiceModule {
}
