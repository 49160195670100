<div class="stipe-container">

  <div class="existing-card-container">
    <div class="ecc-1">
      <div>Payment Method</div>
      <div><button mat-flat-button>Change</button></div>
    </div>

    <div class="ecc-2">Mastercard</div>

    <div class="ecc-3">
      
      <div class="card-wrapper">
        <span class="card-span">****</span>
        <span class="card-span">****</span>
        <span class="card-span">****</span>
        <span class="card-span">1234</span>
        <div class="existing-card-image-container"><img src=""></div>
      </div>
      <div class="card-expiry-wrapper">02 / 28</div>
      </div>
        

  </div>

  <div class="form-container-checkout" style="width: 100%;">
    <form novalidate [formGroup]="stripeForm">
      
      <div id="card-element" class="field"></div>

      <div id="card-element-mobile" class="field"  style="width:300px"></div>

      <div  class="error-message" *ngIf="!isLoading">{{errorMessage.message}}</div>
    </form>
  </div>


  <div class="payment-button-container-flex" >
    <button mat-button (click)="cancelCheckout.emit()" class="cancel-button">
      Cancel
    </button>

    <button id="paymentSubmit" mat-flat-button  class="paynow-button accent-alt-button" (click)="pay()" [disabled]="isLoading || !checkoutResponse?.stripe_client_secret">
      <ng-container *ngIf="!isLoading;else _isLoadingSpinner">Pay {{checkoutResponse.invoice_total/100 | currency:'GBP':'symbol':'1.2-2'}} <span [style.font-size.px]="10">(inc vat)</span></ng-container>
      <ng-template #_isLoadingSpinner><i class="fas fa-spinner fa-spin"></i></ng-template>  
    </button>
  </div>
</div>
