import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { UserService } from '@app/services/pipcall/user.service';
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { InvitationService } from '@app/services/pipcall/invitation.service';
//component
import { DDITabOverviewComponent } from './ddi-overview.component'


import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';

// modules
import { MdePopoverModule } from '@material-extended/mde';
import { InviteUserModalpipxtModule } from '@app/components/modals/invite-user-modal-pipxt/invite-user-modal.module'


//usage
import { ToolbarDatesService, QueryParameters, ToDate, FromDate, QueryVals } from '@app/services/helpers/toolbar-dates.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule,
        InviteUserModalpipxtModule,
        UniversalComponentsModule
    ],
    declarations: [DDITabOverviewComponent],
    exports: [DDITabOverviewComponent],
    providers: [AppInsightService, OrganisationService, UserService, InvitationService, NormalizeNumberService, SignupService, PhonenumberValidator, PaymentsService, LicenseDataService, ToolbarDatesService],
    entryComponents: []
})
export class DDITabOverviewModule { }
