import { Component, OnInit, Input, HostListener, EventEmitter, SimpleChanges, Output, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth-service/auth.service';
import { FormControl } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { OrganisationModel , OrgMetaData} from '../../models/organisation.model';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { UserProfile } from '@app/models/user-profile.model';
import { DownloadLinksModalComponent } from '@app/components/modals/download-links-modal/download-links-modal.component';
import { MAT_DIALOG_DATA, MatDialog } from '@app/material/material-essentials.module';
import { PricingModalComponent} from '@app/components/modals/pricing-modal/pricing-modal.component';
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service';
import { HelpCenterRightMenuComponent } from '@app/components/right-menu-overlay/help-center-right-menu/help-center-right-menu.component';
import { escape, unescape} from 'safe-string-literal';
export interface IBreadCrumb {
    label: string;
    url: string;
}

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ])
    ]
})
export class ToolbarComponent implements OnInit {


    @Input() isloading: boolean;
    @Input() viewAs: string;
    @Output() navToggle = new EventEmitter<boolean>();

    // @Output() navToggle = new EventEmitter<boolean>();
    // @Output() navOpen = new EventEmitter<boolean>();
    // @Output() navClose = new EventEmitter<boolean>();

    // myProfile: any = '';
    public userProfile: UserProfile;


    public model: any;
    public isDev: boolean;
    public myOrgList: OrganisationModel[];
    public loading: boolean;
    public defaultOrg: OrganisationModel;
    public breadcrumbs: IBreadCrumb[]
    public impersonateMode: boolean;
    public activeOrg: OrganisationModel;
    public isSysAdmin: boolean;

    public searchInput: string;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        public auth: AuthService,
        private activatedRoute: ActivatedRoute,
        private sharedService: SharedService,
        private cookieService: CookieService,
        private sidenavRightService: SidenavRightService
    ) {
        this.isDev = isDevMode();
        this.activeOrg = new OrganisationModel();
        this.isSysAdmin = this.auth.isSysAdmin();
    }



    ngOnInit() {
        // this.sharedService.viewAsObservable.pipe(
        //     distinctUntilChanged()
        // ).subscribe(viewAs => this.viewAs = viewAs);


        if (!this.cookieService.check('impersonateUser') && this.auth.checkCookieItem('_userProfile') && this.cookieService.get('_userProfile') !== '') {
            const _profileCookie = this.cookieService.get('_userProfile');
            setTimeout(() => {
                this.userProfile = JSON.parse(_profileCookie);
            }, 1000);
            // this.userProfile = this.cookieService.get('_userProfile');
        }

        this.sharedService.userProfileAsObservable.subscribe(userProfile => {
            this.userProfile = userProfile;
        });

        this.sharedService.activeOrgAsObservable.subscribe((activeOrg) => {
            this.activeOrg = activeOrg;
        });

        if (this.cookieService.check('impersonateUser')) {
            setTimeout(() => {
                this.impersonateMode = true;
            }, 2000);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        changes['isloading'] ? this.loading = changes['isloading'].currentValue : null;
    }

    openHelpCenter() {

        const url = this.router.url;
        console.log("////URL in toolbar", url);
        const data = { viewAs: this.viewAs, url: url };

        this.sidenavRightService.setContextData(data);
        this.sidenavRightService.setComponetPortal(HelpCenterRightMenuComponent);

        // this.sidenavRightService.dataChangedAsObservable.subscribe((resp) => {
        //     resp ? do something : null;
        // });
        //}
    }

    search(searchTerm: string) {
        this.clearSearch();
        this.router.navigateByUrl('/admin/find?search=' + searchTerm);
    }

    clearSearch() {
        this.searchInput = '';
    }

    openPage(url: string) {
    //when link clicked
        this.router.navigateByUrl(url);
    }

    toggleNavBar() {
        this.navToggle.emit(true);
    }

    signOut() {
        this.sharedService.clearSession();
        this.auth.logout();
    }
    existImpersonate() {
        this.sharedService.resetImpersonateUser();
    }

    navigateToURL(url: string, openInNew?: boolean) {

        const openIn = openInNew ? "_blank" : "_self";
        window.open(url, openIn);
    }

    openDownloadLink() {
        const dialogRef = this.dialog.open(DownloadLinksModalComponent, {
            panelClass: 'pipcall-modal-container',
            width: '420px',
        });

    }
    openPricingModal() {
        const dialogRef = this.dialog.open(PricingModalComponent, {
            panelClass: 'pipcall-modal-container',
            width: '660px',
        });
    }


    switchToView(role: any) {
        //reset impersonate when switch to admin view called.
        switch (role) {
            case 'sysAdmin':
                if (this.cookieService.check('impersonateUser')) {
                    this.sharedService.resetImpersonateUser();
                } else {
                    this.sharedService.changeViewAsRole(role);
                    this.router.navigateByUrl('admin/dashboard');
                }
                break;
            case 'user':
                this.navToggle.emit(true);
                setTimeout(() => {
                    this.sharedService.changeViewAsRole(role);
                    this.router.navigateByUrl('manage/organisation');
                }, 400);
                break;
            default:
                break;
        }
    }

}


