import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components
import {PaymentTermsModalComponent} from './payment-terms-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
    MaterialModule,
    CommonModule,
    MaterialModule,
    DragDropModule
    ],
    declarations: [PaymentTermsModalComponent],
    exports: [PaymentTermsModalComponent],
    providers: [],
    entryComponents:[PaymentTermsModalComponent]
})
export class PaymentTermsModalComponentModule { }