//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { UserProfile} from '@app/models/user-profile.model';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { DdiNumber } from '@app/models/ddi.model';

//bottom sheet
import {    MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UserProfileBottomSheetComponent} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.component';
import { DDIBottomSheetComponent } from '@app/components/bottom-sheet-modal/ddi-bsheet-modal/ddi-bsheet-modal.component';


@Component({
    selector: 'app-org-tabs-numbers',
    templateUrl: './org-pipmobile-numbers.component.html',
    styleUrls: ['./org-pipmobile-numbers.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OrgTabNumbersComponents {


    @Input() viewAs: string; //REQUIRED
    @Input() organisation_id: string; //REQUIRED

    @Input() organisationProfile: OrganisationModel //optional
    @Input() orgDdiList: Array<DdiNumber>; //optional

    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();
    showHelpBox = false;

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }

    dataSource: MatTableDataSource<DdiNumber>;
    displayedColumns: string[] = ['number', 'user_full_name', 'settings'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public numberListSubscription: Subscription;

    //forms
    userAdminForm: FormGroup;

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private bottomSheet: MatBottomSheet,
        private organisationService: OrganisationService,
        private ref: ChangeDetectorRef
    ) {

        this.dataSource = new MatTableDataSource();

        this.userAdminForm = this._formBuilder.group({
            organisation_id: [{ value: '', disabled: false }, Validators.required],
            user_id: ['', {updateOn: 'change', validators: [Validators.required]}]
        });

    }

    ngOnChanges(changes: SimpleChanges) {
        // (changes['orgDdiList']?.currentValue !== changes['orgDdiList']?.previousValue) &&  !changes['orgDdiList']?.isFirstChange() ?  this.setDataSource(this.orgDdiList) : null;
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue && !changes['organisationProfile']?.isFirstChange() ?  this.getDetails() : null;
    }



    ngOnInit() {

        console.log("===========orgDdiList===========", this.orgDdiList);

        this.dataSource.data = [];

        if (this.organisation_id) {
            this.setFormValue(this.userAdminForm, 'organisation_id',   this.organisation_id);
        }

        if ( !this.orgDdiList ) {
            this.getDetails();
        } else {
            if ( !this.organisationProfile ) {
                this.getDetails();
            } else {
                this.setDataSource(this.orgDdiList);
            }
        }
        this.ref.detectChanges();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.sort.sort({ id: 'date', start: 'desc', disableClear: false });
    }

    setDataSource( numbers: Array<DdiNumber>) {
        if ( !numbers || numbers.length === 0 ) {return; }
        this.dataSource.data = numbers.filter(item => item?.pip_variant !== 'pipxt');
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.showHelpBox = this.orgDdiList.some(item =>
            item.pip_variant === 'pipmobile' &&
            !item.user_id &&
            !item.invitation_id
        );
        this.ref.markForCheck();
    }


    // setOrganisationNameFormValues(organisationProfile: OrganisationModel) {
    //     this.setFormValue(this.organisationNameForm, 'id', organisationProfile.id);
    //     this.setFormValue(this.organisationNameForm, 'name', organisationProfile.name);
    // }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getDetails() {
        //get profile if one was not passed

        console.log("GET DETAILS================================")
        this.pageStatus.isLoading = true;

        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .switchMap((resp) => {
                console.log("ORG PROFILE RESTULT", resp);
                this.organisationProfile = resp.body;
                return this.organisationService.getDdiListByOrganisation(this.organisationProfile.id);
            })
            .subscribe(resp => {
                console.log("ORG DDIs result", resp);
                this.orgDdiList = resp?.body?.ddi_list ? resp.body?.ddi_list : [];

                this.setDataSource(this.orgDdiList);
                this.pageStatus.isLoading = false
                this.ref.detectChanges();
            },
            (err) => {
                this.pageStatus.isLoading = false
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })
    }

    // saveOrganisationName(f: FormGroup) {
    //     if (f.valid) {
    //         this.submitForm(this.organisationService.patchOrganisationName(f.getRawValue().id, f.getRawValue().name));
    //     }
    // }

    openNumberProfile(number: DdiNumber) {
        const data = {number: number, viewAs: this.viewAs, organisation_id: this.organisation_id, number_id: number?.number}

        const dialogRef = this.bottomSheet.open(DDIBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF Bottom sheet", resp);
            if (resp) {
                this.getDetails();
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });
    }

    openUserProfile(user_id: string) {
        const data = {user: null, viewAs: this.viewAs, organisation_id: this.organisation_id, user_id : user_id}

        const dialogRef = this.bottomSheet.open(UserProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            if (resp) {
                console.log("AFTER DISMISS OF BS", resp);
                this.getDetails();
                this.triggerUpdate();
            }
        });
    }

    updateUserSelectedEvent(user: UserProfile) {
        console.log("=================///////////////////==================VALUE:", user.id);
        this.setFormValue(this.userAdminForm, 'user_id',   user.id);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
