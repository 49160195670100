<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="text-center">
    <span class="vat-notice">This is not a VAT invoice <span *ngIf="invoice.subtype === 'Pro forma'">| This is not a
        final invoice</span></span>
  </div>
  <div class="pip-modal-header" fxLayout="row" fxLayoutAlign="space-between start">

    <div fxLayout="column">
      <h4 [style.margin-bottom.px]="5">{{invoice.organisation_name}}</h4>
      <span *ngIf="invoice?.state != 'Open'" class="subtype-title">{{invoice.subtype}}</span>
      <span *ngIf="invoice?.state === 'Open'" class="subtype-title">Open</span>
      <div *ngIf="invoice?.state === 'Open' && invoice?.type === 'Interim'" class="open-invoice-info">This is an open,
        mid-month invoice for the current day, listing all licence purchases and upgrades for your subscription. Further
        transactions will be added as required. Payment will then be collected from your credit card overnight. If the
        final total is a refund, however, this will be held-over and offset against your next monthly invoice</div>
    </div>

    <div fxLayout="column">
      <span class="subtitle-10 title-color" [style.margin.px]="0">Date</span>
      <h6 [style.margin.px]="0">{{invoice.date | date: 'dd MMMM yyyy'}}</h6>

      <ng-container *ngIf="invoice.is_paid;else unpaidDueDate">
        <span class="subtitle-10 title-color"  [style.margin.px]="0">Paid at:</span>
        <h6 [style.margin.px]="0">{{invoice.paid_at | date: 'dd MMMM yyyy'}}</h6>
      </ng-container>
      <ng-template #unpaidDueDate>
        <span class="subtitle-10 title-color"  [style.margin.px]="0">Due date:</span>
        <h6 [style.margin.px]="0">{{invoice.due_date | date: 'dd MMMM yyyy'}}</h6>
      </ng-template>


      <span class="subtitle-10 title-color"  [style.margin.px]="0">Type / State</span>
      <h6 [style.margin.px]="0">{{invoice.type}} / {{invoice.state}} </h6>

      <span class="subtitle-10 title-color"  [style.margin.px]="0">Reference</span>
      <h6 [style.margin.px]="0">{{invoice.reference}}</h6>
    </div>

    <!-- <span class="pip-modal-subtitle">Reference: {{invoice.reference}}</span> -->
  </div>

  <div *ngIf="isLoading" class="text-center"  style="width:100%;height:200px;">
    <div class="loading-spinner-1" style="width:90px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
  </div>
    </div>

  <div id="pipmodalbody" *ngIf="!isLoading" class="pip-modal-body pip-scroll-shadow" (scroll)="scrollEvent($event)"
    fxLayout="column">

    <div fxLayout="column" fxFill *ngIf="_invoiceLicenseItems?.length > 0">
      <div fxLayout="row">
        <span class=" invoice-item-header subtitle-10 title-color"  fxFill>Licence items</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of _invoiceLicenseItems">
        <div fxFlex="70">{{item.license_name}} {{item.license_contract_name}}</div>
        <div fxFlex="15" class="text-right">x{{item.quantity}}</div>
        <div fxFlex="15" class="text-right">{{( item.price * item.quantity )/ 100  | currency:'GBP':'symbol':'1.2-2'}}
        </div>
      </div>
    </div>

    <div fxLayout="column" fxFill *ngIf="addOnPackageItems">
      <div fxLayout="row">
        <span class="invoice-item-header subtitle-10 title-color"  fxFill>Add on packages</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of addOnPackageItems">
        <div fxFlex="70">{{item.package_name}}</div>
        <div fxFlex="15" class="text-right">x{{item.quantity}}</div>
        <div fxFlex="15" class="text-right">{{item.net_amount/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
      </div>
    </div>

    <div fxLayout="column" fxFill *ngIf="licenseStubItems">
      <div fxLayout="row">
        <span class="invoice-item-header subtitle-10 title-color" fxFill>licence upgrade stub payments</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of licenseStubItems">
        <div fxFlex="70">Pro rata adjustment - upgrade to {{item.license_name}} {{item.license_contract_name}}</div>
        <div fxFlex="15" class="text-right">x1</div>
        <div fxFlex="15" class="text-right">{{item.net_amount/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
      </div>
    </div>

    <div fxLayout="column" fxFill *ngIf="adHocItems">
      <div fxLayout="row">
        <span class="invoice-item-header subtitle-10 title-color" fxFill>Ad hoc charges</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of adHocItems">
        <div fxFlex="70">{{item.description}}</div>
        <div fxFlex="15" class="text-right">x1</div>
        <div fxFlex="15" class="text-right">{{item.net_amount/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
      </div>
    </div>

    <div class="divider"></div>

    <div fxLayout="column" fxFill>
      <div fxLayout="row" fxFill class="totalnet" fxLayoutAlign="end center">
        <div>Net:&nbsp;</div>
        <div class="text-right" fxFlex="150px">{{invoice?.total_net/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
      </div>

      <div fxLayout="row" fxFill class="totalnet" fxLayoutAlign="end center">
        <div>VAT ({{invoice.vat_rate | percent}}):&nbsp;</div>
        <div class="text-right" fxFlex="150px">{{invoice.total_vat/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
      </div>

      <div fxLayout="row" fxFill class="totalgross" fxLayoutAlign="end center">
        <div class="invoice-total-wrapper">
          <div>Total:</div>
          <div class="text-right" fxFlex="150px">{{invoice.total_gross/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
        </div>
      </div>
    </div>


    <div fxLayout="row" fxFill fxLayoutAlign="end center" [style.margin-top.px]="40" *ngIf="invoice?.notes && viewAs==='sysAdmin'" >
      <!-- //make this sysAdmin only -->
      {{invoice?.notes}}
    </div>

    <!-- <div class="text-center">
      <span class="vat-notice" *ngIf="invoice.type === 'Interim'"></span>
    </div> -->

  </div>
  <div class="pip-modal-footer" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!isLoading">
    <div>
      <button *ngIf="invoice?.reference?.startsWith('INV')" [style.width]="'fit-content'" mat-button title="download
      invoice" color="warn" tabindex="-1" aria-label="Download invoice pdf" (click)="downloadPdf(invoice?.reference)">
        <span class="download-invoice-msg">Download VAT invoice <mat-icon svgIcon="get_app"></mat-icon></span>
      </button>
      
    </div>
    <!-- <div>
      <button mat-flat-button color="primary" (click)="closeModal()">Close</button>
    </div> -->
  </div>
</div>
