import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth-service/auth.service';
import { MatDialog, MatDialogRef } from '@app/material/material-essentials.module';
import { DebugModalModal } from '@app/components/modals/debug-modal/debug-modal.component';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public appversion: string = environment.appversion;
    public isDevMode = false;
    constructor(public auth: AuthService, public dialog: MatDialog) { }

    ngOnInit() {

        if (!environment.production) {
            this.isDevMode = true;
        }
    }
    debugMenu() {
        if (this.auth.getUserId() === 'auth0|5c0e85b193f77545cb57f27c') {
            console.log("Admin user detected. Continue")

            const dialogRef = this.dialog.open(DebugModalModal, {
                panelClass: 'pipcall-modal-container'
            });
        }
    }

}
