import { Component, OnInit } from '@angular/core';
import {AppTitleService} from '../../services/helpers/update-title.service'

@Component({
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundGlobalComponent implements OnInit {

  constructor(private appTitleService:AppTitleService) {
    this.appTitleService.setTitle('page not found');
   }

  ngOnInit() {
  }

}
