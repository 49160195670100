import {Component, TemplateRef, ViewChild, Inject, Input, Output, EventEmitter} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { NumberService } from '@app/services/pipcall/number.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import { MatSnackBar } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { PBXService } from '@app/services/pipcall/pbx.service';
import { MatMenuTrigger } from '@angular/material/menu';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';
import { DdiNumber } from '@app/models/ddi.model';

@Component({
    selector: 'matmenu-ddi-admin-controls',
    templateUrl: './ddi-admin-controls.component.html',
    styleUrls: ['./ddi-admin-controls.component.scss']
})
export class DDIAdminControlsComponent {


    @Input() service_id?: string;
    @Input() pip_variant?: string;
    @Input() isLegacy?: boolean;
    @Output() closeModal = new EventEmitter<boolean>();
    @Output() _refreshData = new EventEmitter<boolean>();

    public viewAs: string; //owner,admin or user. set to owner if matches on return of data.


    public pageStatus = {
        isLoading: true,
        isSubmitting: false
    }
    _destroy$ = new Subject<void>();


    constructor(
        private numberService: NumberService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        private pbxervice: PBXService
    ) {

    }

    ngOnInit() {
        // handle missing data
    }


    propagateNumber(number: string) {
        this.pageStatus.isSubmitting = true;
        this.numberService.propagateNumber(number)
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false;
                    this.ref.markForCheck();
                }),
                takeUntil(this._destroy$)
            )
            .subscribe(
                (resp) => {
                    if (resp.status === 200 || resp.status === 201) {
                        this.openSnackBar(`${number} has been sent for propogation`, "dismiss");
                        setTimeout(() => {
                            this.refreshData();
                        }, 16000);

                    }
                }, (err) => {
                    console.log(err);
                    this.openSnackBar("There was a problem with this request", "dismiss");
                }
            )
    }

    deleteNumberConfirm(number: string) {
        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'This will remove the DDI permanently from PiPcall',
            confirmButtonLabel: "Delete",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.numberService.deleteSingleDDi(number)
                    .subscribe((resp) => {
                        this.openSnackBar(`${number} has been deleted`, "Dismiss");
                        this.refreshData();
                        this.closeModal.emit(true);
                    }, (err) => {
                        this.openSnackBar("An unspecified error occured", "Dismiss");
                    })
            }
        });
    }
    convertToMobilePlus(number: string) {
        if (number.startsWith("00447")) {
            //navigate to mobile plus page
            this.router.navigate([`/admin/build/convert-to-extension/${number}`]);
            this.closeModal.emit(true);
        } else {
            this.openSnackBar("This number is not a UK 07 mobile number", "dismiss");
        }
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    revertToPiPmobile(number) {
        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'This will remove extension settings in SQL and the PBX, it will revert the number back into a PiPmobile DDI. This will also unlink the organisation, unless there is an existing user linked to the number.',
            confirmButtonLabel: "Revert",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.pageStatus.isSubmitting = true;
                // this.mockDataService._postMockQuery(true)
                this.pbxervice.deleteExtensionSettings(number)
                    .subscribe((resp) => {
                        console.log("success", resp);
                        this.openSnackBar('Saved', 'dismiss');
                        this.pageStatus.isSubmitting = false;
                        this.refreshData();
                    },
                    (err) => {
                        this.pageStatus.isSubmitting = false;
                        console.log(err);
                        this.openSnackBar(`An error occured [${err?.status}]`, "dismiss");
                    }
                    );
            }
        });
    }

    refreshData(event?) {
        console.log("refresh data EVENT:", event);
        this._refreshData.emit(true);
    }

}

