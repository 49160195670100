//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { switchMap, debounceTime, tap, finalize, takeUntil, map, filter } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray, MaxLengthValidator } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";
import { PBXService } from '@app/services/pipcall/pbx.service';
import { NumberService } from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';
import { MockDataService } from '@app/services/pipcall/mockdata.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
@Component({
    selector: 'panel-extension-config',
    templateUrl: './extension-config-panel.component.html',
    styleUrls: ['./extension-config-panel.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class ExtensionsConfigPanelComponent {

    @Input() viewAs = 'user' //owner,admin or user. set to owner if matches on return of data.
    @Input() number: DdiNumber;

    @Output() updateParent = new EventEmitter();

    pageStatus = {
        isSubmitting: false,
        isLoading: true
    }


    extPasswordUpdate: FormGroup;



    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _fb: FormBuilder,
        private numberService: NumberService,
        private ref: ChangeDetectorRef,
        private pbxService: PBXService,
        private mockDataService: MockDataService,
        private modalService: ModalService,
        private staticDataService: StaticDataService,
    ) {
        const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');
        const passwordRegex = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[!%*_\-])(?=.*\d).{12,30}$/);

        this.extPasswordUpdate = this._fb.group({
            number:  ['', [Validators.required]],
            sip_password:   ['', [Validators.pattern(passwordRegex)]]
        });
        // , Validators.maxLength(30), Validators.minLength(12)
    }



    ngOnInit() {
        console.log("extension config====..........==============", this.number);
    }

    ngOnChanges(changes: SimpleChanges) {
        (changes['number']?.currentValue !== changes['number']?.previousValue) ?  this.setFormValues(this.number) : null;
    }


    setFormValues(number: DdiNumber) {
        console.log("setFormValues====..........==============", number)

        this.setFormValue(this.extPasswordUpdate, 'number', number?.number);
    }
    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    saveExtensionPassword(f: FormGroup) {
        console.log("SAVE FORM:", f);

        if (f.valid) {
            return this.submitForm(this.pbxService.postUpdatePassword(f.value.number, f.value.sip_password));
        }
    }

    syncExtension(f: FormGroup) {
        const num = this.number?.number;
        return this.submitForm(this.pbxService.syncFromPBX(num));
    }


    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.markForCheck()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`${err?.error} [${err.status}]`, "dismiss");
            }
            );
    }

    cancelForm() {
        this.setFormValues(this.number);
    }

    revertToPiPmobile() {
        const data: ModalConfirmData = {
            title: 'Remove extension settings?',
            content: 'You are about to remove extension settings and revert this DDI back to a PiPmobile. This will also unlink the organisation, unless there is an existing user linked to the number.',
            confirmButtonLabel: "Confirm",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.pageStatus.isSubmitting = true;
                // this.mockDataService._postMockQuery(true)
                this.pbxService.deleteExtensionSettings(this.number?.number)
                    .subscribe((resp) => {
                        console.log("success", resp);
                        this.openSnackBar('Saved', 'dismiss');
                        this.pageStatus.isSubmitting = false;
                        this.triggerUpdate();
                    },
                    (err) => {
                        this.pageStatus.isSubmitting = false;
                        console.log(err);
                        this.openSnackBar(`An error occured [${err?.status}]`, "dismiss");
                    }
                    );
            }
        });

    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
