//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, rotateOutUpRightOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { OrganisationDirectory, DirectoryService} from '@app/services/pipcall/directory.service';
import { Subscription } from 'rxjs';
import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { TitleCasePipe } from '@angular/common';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';
import { NumberService, AreaCode } from '@app/services/pipcall/number.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { VerifyMobileV3ModalComponent } from '@app/components/modals/verify-mobile-v3-modal/verify-mobile-v3-modal.component';
import { MatAccordion } from '@angular/material/expansion';
import { CookieService } from 'ngx-cookie-service';
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { InvitationProvision } from '@app/models/invitation.model';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { UserService, OwnerUpgradeForm } from '@app/services/pipcall/user.service';
import { ValidateMobileNotTakenV2, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { UserProfile } from '@app/models/user-profile.model';
import { SelectPackageModalComponent} from '@app/components/modals/select-package-modal/select-package-modal.component';
import { SelectedLicenceModel } from '@app/models/license.model';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { GA4Service } from '@app/services/google-analytics-service';
@Component({
    selector: 'app-admin-upgrade-to-pipuser',
    templateUrl: './admin-upgrade-to-pipuser.component.html',
    styleUrls: ['./admin-upgrade-to-pipuser.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class AdminUpgradeToPipuserComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel;
    @Input() organisation_id: string;
    @Input() userProfile: UserProfile;
    @Input() mobile?: string;

    // @Input() organisationProfile: OrganisationModel //optional ?


    @Output() updateParent = new EventEmitter();
    @Output() verified = new EventEmitter();

    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        verified: false,
        hasMobile: false,
    }

    panelOpenState = false;
    public areaCodes: Array<AreaCode>;

    //forms
    activateHandset: FormGroup;
    verificationForm: FormGroup;
    public isMobileTypeSelected: boolean;
    public numberType: 'existing' | 'landline' | 'mobile' | '' = '';
    public selectedLicence: SelectedLicenceModel;
    verificationStep = 0;


    public _ownerUpgradeForm = new OwnerUpgradeForm // clone of form

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private appInsightService: AppInsightService,
        private staticDataService: StaticDataService,
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private cookieService: CookieService,
        private normalizeNumberService: NormalizeNumberService,
        private userService: UserService,
        private signupService: SignupService,
        private gA4Service: GA4Service
    ) {
        // const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');

        this.activateHandset = this._formBuilder.group({
            user_id:  ['', [Validators.required]],
            organisation_id: ['', [Validators.required]],
            region: ['0044'],
            mobile: ['', [Validators.required, PhonenumberValidator.validateMobileNumber], [ValidateMobileNotTakenV2.createValidator(this.signupService)]],
            pipnumber: this._formBuilder.group({
                allocation_option: ['', [Validators.required]],
            }),
            selected_license: ['', Validators.required]
        });

        // allocation_option: [{ value: null, disabled: true }, [Validators.required]],

        this.verificationForm = this._formBuilder.group({
            user_id: ['', [Validators.required]],
            code: ['', [Validators.required]],
        })

        this.areaCodes = new Array<AreaCode>();

        this.staticDataService.getAreaCodes().subscribe((resp) => {
            if (resp.status === 200) {
                this.areaCodes = resp.body.areacode_list;
                return;
            } else {
                //else fall back to locally assigning codes
                this.areaCodes = NumberService.getCountryCodes();
            }
        })

    }

    ngOnChanges(changes: SimpleChanges) {
        // changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setFormValues(this.organisationProfile) : null;
    }

    setSelectedLicense() {
        this.organisationProfile?.state === 'Trial' ? this.activateHandset.controls['selected_license'].disable() : this.activateHandset.controls['selected_license'].enable();
    }

    ngOnInit() {

        if (this.userProfile?.mobile) {this.pageStatus.hasMobile = true; }

        if ( !this.organisationProfile || !this.organisation_id || !this.userProfile) {
            this.pageStatus.isError = true;
        } else {
            this.setFormValues();
        }

    }


    setFormValues() {
        this.setFormValue(this.activateHandset, 'user_id', this.userProfile?.id);
        this.setFormValue(this.activateHandset, 'organisation_id', this.organisation_id);
        if (this.mobile) {
            const _mobile = this.mobile.startsWith("0044") ? this.mobile.substring(4) : this.mobile;
            this.setFormValue(this.activateHandset, 'mobile', _mobile);
            this.activateHandset.controls.mobile.updateValueAndValidity();
            this.activateHandset.controls.mobile.markAsDirty();
        }
        this.setSelectedLicense();
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }



    registerHandset(f: FormGroup) {
        if (f.invalid) {return; }
        // return;

        console.log("FIRE REGISTER HANDSET NOW", f);

        const ownerUpgradeForm = new OwnerUpgradeForm();
        ownerUpgradeForm.organisation_id = f.value.organisation_id;
        ownerUpgradeForm.allocation_option = f.value?.pipnumber.allocation_option;
        const newFormat = this.normalizeNumberService.reformatNumberWith00Region(f.value.mobile, f.value.region);

        ownerUpgradeForm.mobile = newFormat;
        ownerUpgradeForm.license_id = null;

        if (this.numberType === "existing") {
            // f.controls['pipnumber'].get('cli_override').patchValue(this.normalizeNumberService.normaliseNumberWith00(f.value.pipnumber.cli_override, f.value?.pipnumber.region));
            ownerUpgradeForm.cli_override = f.value.pipnumber.cli_override;
            ownerUpgradeForm.allocation_option = null
        } else {
            ownerUpgradeForm.allocation_option = f.value.pipnumber.allocation_option;
            // ownerUpgradeForm.cli_override = null;
        }
        this.pageStatus.isSubmitting = true;


        console.log("FINAL FORM: ", f.value);
        this._ownerUpgradeForm = ownerUpgradeForm;
        console.log("=====1======== OWNER UPGRADE FORM: ", ownerUpgradeForm);

        this.userService.postOwnerUpgrade(f.value.user_id, ownerUpgradeForm)
            .subscribe((resp) => {

                if (resp?.body?.owner_upgrade_request_id) {
                    const upgradeID = resp?.body?.owner_upgrade_request_id;
                    this.appInsightService.logEvent('Open Owner upgrade verification window', { 'user_id': f.value.user_id });
                    console.log("RESPONSE FROM REGISTER HANDSET. NOW TRIGGER VERIFY MODAL:", resp);
                    this.gA4Service.sign_up_pipmobile_stage4_started();
                    this.openVerifyModal(f, upgradeID);
                }

                if  (resp.status === 429) {
                    console.log('Too many requests, Please try again later', 'OK');
                }

            }, (err) => {
                //409 . already accepted / no license if active org, number info missing
                //404 invalid area code or license
                //401 user not owner
                //429 too many requests
                if (err?.status === 404) {
                    this.openSnackBar('An invalid licence was provided', 'Dismiss');
                } else if (err?.status === 429) {
                    this.openSnackBar('There were too many requests. Please try again later.', 'OK');
                    this.appInsightService.logEvent('Too many verification requests', { 'user_id': f.value.user_id, 'organisation_id':  f.value.organisation_id, 'mobile': f.value.mobile});
                } else {
                    this.openSnackBar(`There was an error [${err.status}]`, 'dismiss');
                }

                this.pageStatus.isSubmitting = false;
                console.log(err);

            });
    }

    openPackageSelect(numberType: string, id?: string) {
        console.log("=---------isMobileTypeSelected", numberType);

        const _numberType = numberType === 'mobile'  ? 'mobile' : 'landline';

        const data = { numberType: _numberType };

        const dialogRef = this.dialog.open(SelectPackageModalComponent, {
            panelClass: 'pipcall-modal-container',
            data: data
            // width: '680px',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log("result from select: ", result);
            if (result?.licence) {
                //do action on modal close
                this.licenseSelected(result.licence)
            }
        });

    }

    licenseSelected(event) {
        this.selectedLicence = event;
        this.activateHandset.controls['selected_license'].patchValue(event.license_id);
    }


    openVerifyModal(f: FormGroup, owner_upgrade_request_id?: string) {
        this.pageStatus.isSubmitting = true;
        const newFormat = this.normalizeNumberService.reformatNumberWith00Region(f.value.mobile, f.value.region);
        const normalized = this.normalizeNumberService.normaliseNumberWithPlus(newFormat);

        const data = { handset: normalized, user_id: f.value.user_id };

        const dialogRef = this.dialog.open(VerifyMobileV3ModalComponent, {
            panelClass: 'pipcall-modal-container',
            data,
            width: '360px'
        });

        dialogRef.componentInstance.resendVerifyCode.subscribe((bool) => {
            console.log("Resend Verification");
            if (bool) {
                this.userService.postOwnerUpgrade(f.value.user_id, this._ownerUpgradeForm).subscribe((resp) => {}, (err) => {
                    console.log(err);
                    if (err?.status === 429) {
                        this.openSnackBar('There were too many requests. Please try again later.', 'OK');
                    }
                })
            }
        });

        dialogRef.componentInstance.verifiedEmit.subscribe((isVerified) => {
            console.log("IT IS VERIFIED NOW");
            if (isVerified) {
                this.gA4Service.sign_up_success('PiPmobile');
                this.pageStatus.isSubmitting = false;
                this.pageStatus.verified = true;
                this.verified.emit(true);
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.pageStatus.verified = true;
                this.verified.emit(true);
                this.pageStatus.isSubmitting = false;
                this.triggerUpdate();
            } else {
                this.pageStatus.verified = false;
                this.pageStatus.isSubmitting = false;
            }
        });
    }



    triggerUpdate() {
        this.updateParent.emit(true);
    }



    // toggleNumberType(option) {
    //     console.log('toggle number type, ', option);
    //     switch (option) {
    //         case 'existing':
    //             console.log('existing number switch');
    //             // this.activateHandset.controls['pipnumber'].get('cli_override').enable();
    //             this.activateHandset.controls['pipnumber'].get('allocation_option').disable();
    //             break;
    //         case 'landline':
    //             // this.activateHandset.controls['pipnumber'].get('cli_override').disable();
    //             this.activateHandset.controls['pipnumber'].get('allocation_option').enable();
    //             this.activateHandset.controls['pipnumber'].get('allocation_option').patchValue('');
    //             this.activateHandset.controls['pipnumber'].get('allocation_option').markAsPristine();
    //             break;

    //         case 'mobile':
    //             // this.activateHandset.controls['pipnumber'].get('cli_override').disable();
    //             this.activateHandset.controls['pipnumber'].get('allocation_option').enable();
    //             this.activateHandset.controls['pipnumber'].get('allocation_option').patchValue('00447');
    //             this.activateHandset.controls['pipnumber'].get('allocation_option').markAsTouched();
    //             break;
    //     }
    // }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    debugForm(f: FormGroup) {
        console.log(f.value);
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
