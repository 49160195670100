import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmailValidator} from './emailValidator.directive';
import {PhonenumberValidator} from './phonenumberValidator.directive'
import {Auth0Validator} from './auth0Validator.directive'
import {EmailMatchValidation} from './emailMatch.Validator';
import {BusinessnumberValidator} from './businessnumberValidator.directive';

@NgModule({
    declarations: [EmailValidator, PhonenumberValidator, BusinessnumberValidator, Auth0Validator, EmailMatchValidation],
    exports: [EmailValidator, PhonenumberValidator, BusinessnumberValidator, Auth0Validator, EmailMatchValidation],
    imports: [
        CommonModule
    ],
    providers: []
})
export class ValidatorsModule { }
