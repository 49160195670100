<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <!-- <span class="pip-modal-close" (click)="closeModal()">X</span> -->
    <span class="pip-modal-title">New PiPxt Organisation</span>
    <span class="pip-modal-subtitle">Create a new organisation and its first admin user</span>
  </div>

  <div id="pipmodalbody" class="pip-modal-body pip-scroll-shadow" (scroll)="scrollEvent($event)">
    <form [formGroup]="createOrgForm">
      <div class="form-wrapper" fxLayout="column">
        <!-- 
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="40" fxFlex.lt-lg="100"[style.padding.px]="20">
          <div >
            this is some information her
            createOrgForm: {{this.createOrgForm.valid}}<br>
            organisation_details: {{this.createOrgForm.controls['organisation_details'].valid}}<br>
            organisation_details.name: {{this.createOrgForm.controls['organisation_details'].get('name').valid}}<br>
            admin_details: {{this.createOrgForm.controls['admin_details'].valid}}<br>
            fee_details: {{this.createOrgForm.controls['fee_details'].valid}}<br>
          </div>
        </div>  -->


        <div [hidden]="pageStatus.viewTab!=1" fxLayout="column" fxFlex>

          <ng-container formGroupName="organisation_details">
            <div class="form-section-header"><span class="form-section-header-number">1.</span> Organisation details
            </div>

            <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
              <input autocomplete="off" type="text" matInput placeholder="Organisation name" formControlName="name">
              <mat-error
                *ngIf="createOrgForm.controls.organisation_details.get('name')?.touched && createOrgForm.controls.organisation_details.get('name')?.errors?.required">
                Organisation is required</mat-error>
              <mat-label>Organisation name</mat-label>
              <status-icons *ngIf="createOrgForm.controls.organisation_details.get('name')?.touched"
                [iconStatus]="createOrgForm.controls.organisation_details.get('name').status">
              </status-icons>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
              <input autocomplete="off" type="text" matInput placeholder="44 " formControlName="contact_phone_number">
              <mat-error
                *ngIf="createOrgForm.controls.organisation_details.get('contact_phone_number')?.touched && createOrgForm.controls.organisation_details.get('contact_phone_number')?.errors?.required">
                phone number is required</mat-error>
              <mat-label>Contact number</mat-label>
              <mat-icon class="phone-prefix" matPrefix>add</mat-icon>
              <mat-icon svgIcon="info" matTooltipPosition="above" matTooltipShowDelay="100"
              matTooltip="A contact number for the organisation, this will not be used for anything else" matSuffix>
            </mat-icon>

              <status-icons [hasPrefix]="true"
                *ngIf="createOrgForm.controls.organisation_details.get('contact_phone_number')?.touched"
                [iconStatus]="createOrgForm.controls.organisation_details.get('contact_phone_number').status">
              </status-icons>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input id="mat-select-date-custom" formControlName="trial_end_date" class="clickable" autocomplete="false"
                matInput [matDatepicker]="picker" [min]="today" (click)="picker.open()" readonly=readonly
                (dateChange)="trialdDateValueChange($event.value)" placeholder="Choose a date">
              <mat-datepicker #picker></mat-datepicker>
              <mat-icon matSuffix class="clickable" (click)="picker.open()"  class="icon-24" svgIcon="down_arrow"></mat-icon>
              <mat-label>Trial end date</mat-label>
              <status-icons *ngIf="createOrgForm.controls.organisation_details.get('trial_end_date')?.touched"
                [iconStatus]="createOrgForm.controls.organisation_details.get('trial_end_date').status">
              </status-icons>
            </mat-form-field>


            <!-- // Un comment this to add pipxt and pipmobile enabled toggles. will defaulto pipxt only if commented -->
            <!-- <div fxLayout="column" fxLayoutAlign="center end" fxFlex="100" [style.margin-top.px]="20">

              <mat-slide-toggle formControlName=is_pipxt_enabled labelPosition="before" checked=true
                title="is PiPxt enabled for this organisation">
                is PiPxt enabled
              </mat-slide-toggle>

              <mat-slide-toggle formControlName=is_pipmobile_enabled labelPosition="before"
                title="is PiPmobile enabled for this organisation">
                is PiPmobile enabled
              </mat-slide-toggle>

            </div> -->


          </ng-container>
        </div>

        <div [hidden]="pageStatus.viewTab!=2" fxLayout="column" fxFlex=100>
          <div class="form-section-header"><span class="form-section-header-number">2.</span> Admin details
          </div>

          <ng-container formGroupName="admin_details">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
              <mat-form-field appearance="outline" fxFlex="46" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
                <input autocomplete="off" type="text" matInput placeholder="First Name"
                  formControlName="admin_first_name">
                <mat-error
                  *ngIf="createOrgForm.controls.admin_details.get('admin_first_name')?.touched && createOrgForm.controls.admin_details.get('admin_first_name')?.errors?.required">
                  first name is required</mat-error>
                <mat-label>First Name</mat-label>
                <status-icons *ngIf="createOrgForm.controls.admin_details.get('admin_first_name')?.touched"
                  [iconStatus]="createOrgForm.controls.admin_details.get('admin_first_name').status">
                </status-icons>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="46" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
                <input autocomplete="off" type="text" matInput placeholder="Last name" formControlName="admin_last_name"
                  >
                <mat-error
                  *ngIf="createOrgForm.controls.admin_details.get('admin_last_name')?.touched && createOrgForm.controls.admin_details.get('admin_last_name')?.errors?.required">
                  last name is required</mat-error>
                <mat-label>Last Name</mat-label>
                <status-icons *ngIf="createOrgForm.controls.admin_details.get('admin_last_name')?.touched"
                  [iconStatus]="createOrgForm.controls.admin_details.get('admin_last_name').status">
                </status-icons>
              </mat-form-field>
            </div>

            <mat-form-field appearance="outline" fxFlex="46" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
              <input autocomplete="off" type="text" matInput placeholder="Email" formControlName="admin_email"
                >
              <mat-error
                *ngIf="createOrgForm.controls.admin_details.get('admin_email')?.touched && createOrgForm.controls.admin_details.get('admin_email')?.errors?.required">
                Required
                Field</mat-error>
              <mat-error
                *ngIf="createOrgForm.controls.admin_details.get('admin_email')?.touched && createOrgForm.controls.admin_details.get('admin_email')?.errors?.email">
                not a valid email</mat-error>
              <mat-error *ngIf="createOrgForm.controls.admin_details.get('admin_email').status === 'PENDING'">
                checking ...
              </mat-error>
              <mat-error
                *ngIf="createOrgForm.controls.admin_details.get('admin_email').errors && createOrgForm.controls.admin_details.get('admin_email').errors.emailTaken">
                Email already in use.
              </mat-error>
              <mat-label>Email</mat-label>
              <status-icons *ngIf="createOrgForm.controls.admin_details.get('admin_email')?.touched"
                [iconStatus]="createOrgForm.controls.admin_details.get('admin_email').status">
              </status-icons>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" floatLabel="always" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
              <input autocomplete="off" type="text" matInput placeholder="44" formControlName="admin_mobile">
              <mat-label>Mobile</mat-label>
              <mat-icon class="phone-prefix" matPrefix>add</mat-icon>
              <mat-error
                *ngIf="createOrgForm.controls.admin_details.get('admin_mobile')?.touched && createOrgForm.controls.admin_details.get('admin_mobile')?.errors?.valid">
                Number is not a valid UK mobile
              </mat-error>
              <mat-error
                *ngIf="createOrgForm.controls.admin_details.get('admin_mobile')?.touched && createOrgForm.controls.admin_details.get('admin_mobile')?.errors?.required">
                mobile is required</mat-error>
              <mat-error *ngIf="createOrgForm.controls.admin_details.get('admin_mobile').status === 'PENDING'">
                checking ...
              </mat-error>
              <mat-error
                *ngIf="createOrgForm.controls.admin_details.get('admin_mobile').touched && createOrgForm.controls.admin_details.get('admin_mobile').errors?.mobileTaken">
                Mobile already registered with an account.
              </mat-error>
              <mat-icon svgIcon="info"  matTooltipPosition="above" matTooltipShowDelay="100"
                matTooltip="The admins handset for PiPcall, should they choose to assign a number to themselves later" matSuffix>
              </mat-icon>

              <status-icons [hasPrefix]="true" *ngIf="createOrgForm.controls.admin_details.get('admin_mobile')?.touched"
                [iconStatus]="createOrgForm.controls.admin_details.get('admin_mobile').status">
              </status-icons>
            </mat-form-field>

            <!-- <div fxLayout="row" fxLayoutAlign="end" [style.margin-top.px]="5">
              <mat-checkbox checked=true [disabled]="true" matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="
              Will register a Device for the user , ready to be allocated a pipnumber" (change)="isPiPuser($event)">
                Register user PiP device
              </mat-checkbox>
            </div> -->


          </ng-container>
        </div>


        <div [hidden]="pageStatus.viewTab!=3" fxLayout="column" fxFlex=100>
          <div class="form-section-header"><span class="form-section-header-number">3.</span> Fee details
          </div>
          <ng-container formGroupName="fee_details">

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">

              <mat-form-field appearance="outline" fxFlex="45" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
                <input autocomplete="off" type="text" matInput placeholder="Initial set up"
                  formControlName="initial_set_up_fee"  mvndrMatCurrencyFormat
                  [allowNegative]="false" [currencyCode]="'GBP'"
                  [readonly]="createOrgForm.controls.fee_details.get('initial_set_up_fee').disabled"
                  (blur)="updateFeeAmount('initial_set_up_fee', $event)">
                <mat-label>£ Initial set up</mat-label>
                <status-icons *ngIf="createOrgForm.controls.fee_details.get('initial_set_up_fee')?.touched"
                  [iconStatus]="createOrgForm.controls.fee_details.get('initial_set_up_fee').status">
                </status-icons>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="45" [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
                <input autocomplete="off" type="text" matInput placeholder="Monthly service charge"
                  formControlName="monthly_service_charge"  mvndrMatCurrencyFormat
                  [allowNegative]="false" [currencyCode]="'GBP'"
                  [readonly]="createOrgForm.controls.fee_details.get('monthly_service_charge').disabled"
                  (blur)="updateFeeAmount('monthly_service_charge', $event)">
                <mat-label>£ Monthly service charge</mat-label>
                <status-icons *ngIf="createOrgForm.controls.fee_details.get('monthly_service_charge')?.touched"
                  [iconStatus]="createOrgForm.controls.fee_details.get('monthly_service_charge').status">
                </status-icons>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" [style.margin-top.px]="50">
              <mat-checkbox labelPosition="before" (change)="disableFeeEvent($event)">Set fees to £0.00</mat-checkbox>
            </div>
          </ng-container>
        </div>


        <div [hidden]="pageStatus.viewTab!=4" fxLayout="column" fxFlex="100" fxLayoutAlign="center center">

          <div [style.margin-top.px]="20" class="form-section-header">Summary
          </div>
          <div class="summary-screen">

            <div>Organisation name : <span>{{createOrgForm.controls.organisation_details.get('name').value}}</span>
            </div>
            <div>Organisation contact :
              <span>+{{createOrgForm.controls.organisation_details.get('contact_phone_number').value}}</span> </div>
            <div>Enabled services: <span><span class="service"
                  *ngIf="createOrgForm.controls.organisation_details.get('is_pipxt_enabled').value === true">PiPxt</span>
                <span *ngIf="createOrgForm.controls.organisation_details.get('is_pipmobile_enabled').value === true"
                  class="service">PiPmobile</span></span> </div>
            <div>Admin name : <span>{{createOrgForm.controls.admin_details.get('admin_first_name').value}}
                {{createOrgForm.controls.admin_details.get('admin_last_name').value}}</span> </div>
            <div>Admin email : <span>{{createOrgForm.controls.admin_details.get('admin_email').value}}</span> </div>
            <div *ngIf="!createOrgForm.controls.admin_details.get('admin_mobile').disabled">Admin mobile :
              <span>00{{createOrgForm.controls.admin_details.get('admin_mobile').value}}</span> </div>
            <div title="displayed in pence">Initial set-up fee:
              <span>{{createOrgForm.controls.fee_details.get('initial_set_up_fee').value * 1 | currency:'GBP':'symbol':'1.2-2'}}
              </span> </div>
            <div title="displayed in pence">Monthly Service charge:
              <span>{{createOrgForm.controls.fee_details.get('monthly_service_charge').value * 1 | currency:'GBP':'symbol':'1.2-2'}}</span>
            </div>
          </div>

        </div>


        <div [hidden]="pageStatus.viewTab!=5" fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
          <div [style.margin-top.px]="20" class="form-section-header">Success !
          </div>

          <div class="summary-screen text-center">
            The account has been created. <br>
            <button [style.margin-top.px]="20" (click)="closeModal()"
              [routerLink]="['/','admin','organisation', newOrganisation_id ,'profile']" mat-flat-button
              color="accent">Go to organisation profile</button>
          </div>
        </div>

      </div>
    </form>

  </div>
  <div class="pip-modal-footer">
    <!-- <span [style.margin-right.px]="20" *ngIf="pageStatus.isSuccess"
      [@enter]="{ value: '', params: { duration: 600, delay: 0 } }">Saved!</span> -->

    <!-- <button [hidden]="pageStatus.viewTab === 5" mat-button (click)="debug()">printform</button> -->
    <button *ngIf="pageStatus.viewTab > 1 && pageStatus.viewTab < 5" mat-button color="primary"
      (click)=" prevTab()">Back</button>
    <button *ngIf="pageStatus.viewTab < 4" mat-flat-button color="primary" (click)=" nextTab()"
      [disabled]="!checkContinueIsValid()">Continue</button>

    <button *ngIf="pageStatus.viewTab === 4" mat-flat-button color="accent"
      [disabled]="pageStatus.isSubmitting || !createOrgForm.valid" (click)="submitForm()">
      <ng-container *ngIf="!pageStatus.isSubmitting;else spinner">
        Create Organisation
      </ng-container>
    </button>

  </div>
</div>

<ng-template #spinner>
  <span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>
</ng-template>
