<div class="pip-modal-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header" fxLayout="column" fxLayoutAlign="center start">
    <span class="subtitle-16">Gravatar</span>
    <h5 style="color:var(--c-title)">Update profile picture</h5>
  </div>



<div id="pipmodalbody" class="pip-modal-body" fxLayout="row" style="width: 100%;">

  <div fxLayout="column">

    <p>You can create and manage your profile image by making a free account at Gravatar with the same email you signed up to PiPcall. </p>

    <p>Gravatar is used by top brands such as Slack, GitHub and Wordpress, it is a single place to set a globally recognised avatar linked to your public email address. You choose what to display on Gravatar-enabled sites.  </p>

    <div fxLayout="row" style="margin-top:40px;" fxLayoutAlign="center center" >
      
      <div class="clickable" style="padding: 16px;border: 2px solid #1e8cbe;" fxLayout="row" fxLayoutGap="20px;"  fxLayoutAlign="center center" (click)="goToGravatar()">
        <img src="assets/icon/svg/gravatar.svg" style="height:50px;">
        <div><div style="margin-left: 20px; font-size: 30px;" >Sign up to Gravatar</div></div>
      </div>
    </div>
    
  </div>


</div>

</div>