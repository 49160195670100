import { Injectable, isDevMode } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from '../pipcall/user.service'
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '@app/services/token.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { map, delay, mergeMap, concatMap, flatMap } from 'rxjs/operators';
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private cookieService: CookieService, private auth: AuthService, private userService: UserService, private sharedService: SharedService, private tokenService: TokenService ) {
    }

    //previouis version
    // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //     if (this.auth.isAuthenticated()) {
    //         this.sharedService.innit('Auth-guard');
    //         sessionStorage.removeItem('redirect_url');
    //         return true;
    //     } else if (this.cookieService.check('refresh_token')) {
    //         console.log("REFRESH TOKEN EXISTS. USE IT HERE");
    //     } else {
    //         console.log("=================//////CAN ACTIVATE GUARD FIRED -- need to refresh token here and try again ?////======================")
    //         sessionStorage.setItem('redirect_url', state.url);
    //         this.router.navigateByUrl('/');
    //         return false;
    //     }

    //     //else not authenticated.

    //     //if expired redirect to log in and snackbar expired message
    //     // this.cookieService.set('isLoggedIn', '', -1, '/');
    //     //else return false and handle as unauthorized
    //     return false;
    // }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        console.log("==/==CAN ACTIVATE GUARD FIRED");
        if (this.auth.isAuthenticated()) {
            console.log("==/==CAN ACTIVATE this.sharedService.innit");
            this.sharedService.innit('Auth-guard');
            sessionStorage.removeItem('redirect_url');
            return of(true);
        } else if (this.cookieService.check('refresh_token')) {
            return this.checkAccessToken()
                .pipe(
                    map(token => {
                        return token ? true : false;
                    })
                )
        } else {
            console.log('set redirect url in token and return to login');
            sessionStorage.setItem('redirect_url', state.url);
            this.router.navigateByUrl('/');
            return of(false);
        }

        //else not authenticated.

        //if expired redirect to log in and snackbar expired message
        // this.cookieService.set('isLoggedIn', '', -1, '/');
        //else return false and handle as unauthorized
        //return false;
    }

    checkAccessToken(): Observable<any> {
        //return observable // check the token here// return the valid token
        return this.tokenService.returnAccessToken();
    }

}
