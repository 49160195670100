import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from "@angular/core";

//pure true if you want to check for update continously
@Pipe({ name: 'invoiceDueDays' })
export class InvoiceDueDays implements PipeTransform {

    transform(date_due: string, type?: 'value'): string {
        //return string until type set to value 

        let returnString:string = '';

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let due = new Date(date_due);
        due.setHours(0,0,0,0);

        let diffDays = this.daysBetweenDates(today, due)

        if (diffDays === 0) {
            returnString = "due today";
        } else if (diffDays === 1) {
            returnString = "due in 1 day";
        } else if (diffDays >= 2) {
            returnString = "due in " + diffDays + " days";
        } else if (diffDays < 0) {
            let days = -(diffDays);
            let label = diffDays === 1? 'day':'days'; 
            returnString = "overdue by " + days + " " + label;
        } else {
            returnString = ""
        }

        if(type && type === 'value'){
            return diffDays.toString();
        }

        return returnString;
    }

    daysBetweenDates(from, to) {
        //from = today
        //to = due date
        var date1 = new Date(from);
        var date2 = new Date(to);

        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var diffDays = (Math.ceil(timeDiff / (1000 * 3600 * 24)));

        if(from > to) {
            diffDays = diffDays * (-1);
        }
        

        return diffDays;
    }
 
  
}

