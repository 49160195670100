import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { Routes, RouterModule } from '@angular/router'
import { MaterialModule } from '@app/material/material.module'
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValidatorsModule } from '@app/validators/validators.module';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { environment } from '../../../environments/environment';
import { TitleCasePipe } from '@angular/common';

import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { FlexLayoutModule } from '@angular/flex-layout'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//declarations
import { SignupComponent } from './signup.component';
import { VerifySignupComponent } from './verify-signup/verify-signup.component';

//recaptcha
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { GravatarDirectiveModule } from "@app/directives/gravatar/gravatar.module";

import { CookieService } from 'ngx-cookie-service';
import { SignupService } from '@app/services/pipcall/signup.service';

import {TemplateModule} from '@app/template/template.module';
import { GA4Service } from '@app/services/google-analytics-service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TemplateModule,
        MaterialModule,
        ReactiveFormsModule,
        ValidatorsModule,
        CustomPipesModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        StatusIconsModule,
        GravatarDirectiveModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        RouterModule
    ],
    declarations: [SignupComponent, VerifySignupComponent],
    providers: [ AppInsightService, SignupService, TitleCasePipe, GA4Service, CookieService, {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
            siteKey: environment.recaptchaSiteKey,
        } as RecaptchaSettings,
    }],
    exports: [SignupComponent],
    entryComponents: []
})
export class SignupModule {
}
