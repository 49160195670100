<div fxLayout="column" fxFlex="100%" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
    <form [formGroup]="organisationBillingDetails" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">
        <div fxLayout="column" fxFlex="100%">

            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.gt-md="20px">
                <div fxLayout="column" fxFlex="60%" fxFlex.lt-lg="100%" fxFlexOrder="1" fxFlexOrder.lt-lg="2">
                    <mat-form-field appearance="outline" class="custom-form-field-v3">
                        <input type="text" matInput placeholder="Company number" formControlName="company_number">
                        <status-icons
                            *ngIf="organisationBillingDetails?.controls.company_number?.touched && organisationBillingDetails?.controls.company_number?.value !== ''"
                            [iconStatus]="organisationBillingDetails.get('company_number').status">
                        </status-icons>
                        <mat-label>Company Number (Limited Company or list otherise)</mat-label>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="40%" fxFlex.lt-lg="100%" fxFlexOrder="2" fxFlexOrder.lt-lg="1"
                    style="padding-top: 20px;">
                    <div></div>
                </div>
            </div>

            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.gt-md="20px">
                <div fxLayout="column" fxFlex="60%" fxFlex.lt-lg="100%" fxFlexOrder="1" fxFlexOrder.lt-lg="2">

                    <mat-form-field appearance="outline" class="custom-form-field-v3">
                        <input type="text" matInput placeholder="Billing Email" formControlName="billing_contact_email">
                        <status-icons
                            *ngIf="organisationBillingDetails?.controls.billing_contact_email?.touched && organisationBillingDetails?.controls.billing_contact_email?.value !== ''"
                            [iconStatus]="organisationBillingDetails.get('billing_contact_email').status">
                        </status-icons>
                        <mat-label>Billing Email (if different from main contact)</mat-label>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="40%" fxFlex.lt-lg="100%" fxFlexOrder="2" fxFlexOrder.lt-lg="1"
                    style="padding-top: 20px;">
                    <div>Monthly bills will be sent to this address, leave blank if this is same as the admins contact
                        email.</div>
                </div>
            </div>

            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.gt-md="20px">
                <div fxLayout="column" fxFlex="60%" fxFlex.lt-lg="100%" fxFlexOrder="1" fxFlexOrder.lt-lg="2">
                    <mat-form-field appearance="outline" class="custom-form-field-v3">
                        <textarea matInput type="text" matInput placeholder="Registered company address (for invoicing)"
                            formControlName="registered_address" style="min-height: 80px; color: var(--c-title);"></textarea>
                        <status-icons
                            *ngIf="organisationBillingDetails?.controls.registered_address?.touched && organisationBillingDetails?.controls.registered_address?.value !== ''"
                            [iconStatus]="organisationBillingDetails.get('registered_address').status">
                        </status-icons>
                        <mat-label>Registered Company Address</mat-label>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="40%" fxFlex.lt-lg="100%" fxFlexOrder="2" fxFlexOrder.lt-lg="1"
                    style="padding-top: 20px;">
                    <div>Address used for invoicing</div>
                </div>
            </div>


            <div fxLayout="row" fxLayoutGap="10px" style="margin-top: 20px">
                <button mat-flat-button aria-label="Save changes" class="save-button" (click)="saveBillingForm(this.organisationBillingDetails)"
                    [disabled]="organisationBillingDetails?.pristine ||  pageStatus.isSubmitting || organisationBillingDetails.invalid || (f.billing_contact_email.value === '' && f.company_number.value === '' && f.registered_address.value === '')">
                    <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Continue</ng-container>
                </button>

                <button mat-flat-button aria-label="skip section" class="skip-button"
                  [disabled]="pageStatus.isSubmitting" (click)="skipSection()">
                  <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Skip</ng-container>
                </button>

            </div>
        </div>
    </form>

</div>


<ng-template #loadingSpinner>
    <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
        <div style="font-size: 4px;" class="loader-spinner"></div>loading...
    </div>
</ng-template>


<ng-template #notFound>
    <div>
        Error displaying details
    </div>
</ng-template>


<ng-template #savingSpinner>
    <div>
      <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
    </div>
  </ng-template>