import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, FormControl, Validators } from '@angular/forms';
import { parse, format, AsYouType, parseNumber, parsePhoneNumber, parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

// import { NormalizeNumberService, RegionCodes } from '../services//helpers/normalize-number.service';
import { AreaCodeHelper, RegionCodes} from '@app/services/helpers/number-validation-helper.service';
import { parseHostBindings } from '@angular/compiler';



// for help : https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/#_custom_model_form_validator
//https://www.npmjs.com/package/libphonenumber-js

//assumes form has parent with Region control field and default selected value.

@Directive({
    selector: '[validNumber]',
    providers: [
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        { provide: NG_VALIDATORS, useExisting: PhonenumberValidator, multi: true }
    ]
})
export class PhonenumberValidator implements Validator {

    //take a + format number (or a 00 and replace for +)

    public _regionList: Array<RegionCodes>;


    // static regexTest(number: string): boolean {
    //     const validCharacterRegex = /(^0044|^\+?44|^0|^)7\d{9}$/
    //     //contains only numbers or  +
    //     // starts with 447 , 00447, +447, 07 or 7 and is prepended by another 9 digits
    //     return validCharacterRegex.test(number);
    // }

    //mobile only
    static validateNumber(control: AbstractControl): ValidationErrors | null {

        // PhonenumberValidator
        // const _regionList: Array<RegionCodes> = NormalizeNumberService.getRegionList();

        if (control.value) {
            //take a + format number (or a 00 and replace for +)
            const matchedCountry = AreaCodeHelper.getCountryByPhoneNumber(control.value);
            const isValid = AreaCodeHelper.isValidNumber(control.value, true); //is valid  mobile

            if (!matchedCountry === true) {
                return { valid: "Region is not supported" }
            } else if (!isValid === true) {
                return { valid: "Number is not valid" }
            } else {
                return null;
            }

        }
        return null;
    // If no error, return null
    }



    static validateMobileNumber(control: AbstractControl): ValidationErrors | null {
        // recieve number without region
        // PhonenumberValidator
        // const _regionList: Array<RegionCodes> = NormalizeNumberService.getRegionList();

        if (control.value) {
            const numberTrimmed = control.value.trim().replace(/\s+/g, '');

            //region
            let region = '0044'; //default region
            // Access the parent FormGroup to get the region formcontrol
            const parentFormControl = control.parent;
            const regionControl = parentFormControl.get('region');
            regionControl ? region = regionControl.value : null; //override default region if region control exists
            console.log("parent region is ", regionControl.value)

            //if number starts with 0, replace with region code, else append region code at start
            const number = numberTrimmed.startsWith('0') ? numberTrimmed.replace('0', region) : region + numberTrimmed;


            //take a + format number (or a 00 and replace for +)
            const matchedCountry = AreaCodeHelper.getCountryByPhoneNumber(number);
            const isValid = AreaCodeHelper.isValidNumber(number, true); //this constrains to mobile only

            if (!matchedCountry === true) {
                return { valid: "Region is not supported" }
            } else if (!isValid === true) {
                return { valid: "Number is not a valid mobile" }
            } else {
                return null;
            }

        }
        return null;
    // If no error, return null
    }

    validatev2(mobile: AbstractControl): ValidationErrors | null {
        return PhonenumberValidator.validateMobileNumber(mobile);
    }
    validate(mobile: AbstractControl): ValidationErrors | null {
        return PhonenumberValidator.validateNumber(mobile);
    }

}



// this.asYouType = new AsYouType('GB').input('07740352865');
// this.numberformat = format('+447740352865', 'GB', 'National'); //E164 //Original //International
// this.parse = parsePhoneNumber('+447740352865', 'GB')

// console.log("asYoutype",this.asYouType);
// console.log("numberformat",this.numberformat);
// console.log("country",this.parse.country);
// console.log("number",this.parse.number );
// console.log("valid",this.parse.isValid() );


// @Directive({
//     selector: '[validNumber]',
//     providers: [
//         // eslint-disable-next-line @typescript-eslint/no-use-before-define
//         { provide: NG_VALIDATORS, useExisting: PhonenumberValidator, multi: true }
//     ]
// })
// export class PhonenumberValidator implements Validator {

//     public _regionList: Array<RegionCodes>;


//     static regexTest(number: string): boolean {
//         const validCharacterRegex = /(^0044|^\+?44|^0|^)7\d{9}$/
//         //contains only numbers or  +
//         // starts with 447 , 00447, +447, 07 or 7 and is prepended by another 9 digits
//         return validCharacterRegex.test(number);
//     }
//     //mobile only
//     static validateNumber(control: AbstractControl): ValidationErrors | null {

//         // PhonenumberValidator
//         const _regionList: Array<RegionCodes> = NormalizeNumberService.getRegionList();

//         if (control.value) {
//             const numberTrimmed = control.value.trim().replace(/\s+/g, '');
//             if (!PhonenumberValidator.regexTest(numberTrimmed)) {
//                 return { valid: "Number is not valid" }
//             } else {
//                 const region = control.parent.controls["region"].value; //return the selected region as uk44
//                 const matchingRegion = _regionList.find(val => val.Region === region); //check regionList to determin matching entry
//                 const code: CountryCode = matchingRegion ? matchingRegion.Code : 'GB'; //if matching region then return country Code or default to GB if none.
//                 const phonenumber = parsePhoneNumberFromString(numberTrimmed, code);
//                 return phonenumber.isValid() ? null : { valid: "Number is not valid" }
//             }

//         }
//         return null;
//     // If no error, return null
//     }

//     validate(mobile: AbstractControl): ValidationErrors | null {
//         return PhonenumberValidator.validateNumber(mobile);
//     }


// }
