import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { UserService } from '@app/services/pipcall/user.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';

//component
import { UserProfileTabUsageComponent } from './user-profile-usage.component'



// modules
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';
import { MdePopoverModule } from '@material-extended/mde';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule,
        GravatarDirectiveModule,
        modalServiceModule
    ],
    declarations: [UserProfileTabUsageComponent],
    exports: [UserProfileTabUsageComponent],
    providers: [AppInsightService, OrganisationService, UserService, NormalizeNumberService],
    entryComponents: []
})
export class UserProfileTabUsagemModule { }
