import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
export class ConfirmBillingStateModalData {
    //placeholder for any data that needs to be passed
    isBillingEnabled: boolean;
    organisation_id: string;

    constructor(data?) {
        if (data) {
            this.isBillingEnabled = data?.isBillingEnabled;
            this.organisation_id = data?.organisation_id;
        }
    }
}



@Component({
    selector: 'app-confirm-billing-state',
    templateUrl: './confirm-billing-state-modal.component.html',
    styleUrls: ['./confirm-billing-state-modal.component.scss']
})
export class ConfirmBillingStateComponent {
    currentState: boolean;
    isBillingEnabled: boolean;
    stripe_id: string = null;
    organisation_id: string;

    public pageStatus = {
        isSubmitting: false,
        isSubmitted: false,
        isSuccess: false,
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ConfirmBillingStateModalData,
        private organisationService: OrganisationService,
        private dialogRef: MatDialogRef<ConfirmBillingStateComponent>,
        private modalService: ModalService,
        public snackBar: MatSnackBar,
    ) {
        this.organisation_id = data.organisation_id;
        this.currentState = data.isBillingEnabled;
        this.isBillingEnabled = this.currentState ? false : true;
    }




    disableBilling(organisation_id: string) {

        const data: ModalConfirmData = {
            title: '',
            content: 'Are you sure you want to disable the automatic billing? The stripe id will be required in order to re-enable it.',
            confirmButtonLabel: "Confirm",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {

                this.pageStatus.isSubmitting = true;
                this.organisationService.patchOrganisationBilling(organisation_id, false)
                    .subscribe((resp) => {
                        console.log(resp);
                        this.pageStatus.isSubmitting = false;
                        this.openSnackBar('Automatic Billing has been disabled', 'dismiss');
                        this.closeDialog(true);
                    }, (err) => {
                        this.pageStatus.isSubmitting = false;
                        this.openSnackBar('There was an error', 'dismiss');
                        console.log(err);
                    })
                return;
            }
        });

    }

    enableBilling(organisation_id: string, stripe_id: string) {
        this.pageStatus.isSubmitting = true;
        this.organisationService.patchOrganisationBilling(organisation_id, true, stripe_id)
            .subscribe((resp) => {
                console.log(resp);
                this.pageStatus.isSubmitting = false;
                this.openSnackBar('Automatic Billing has been enabled', 'dismiss');
                this.closeDialog(true);
            }, (err) => {
                this.pageStatus.isSubmitting = false;
                this.openSnackBar('There was an error', 'dismiss');
                console.log(err);
            })

        this.closeDialog(true);
    }

    closeDialog(isUpdated: boolean) {
        this.dialogRef.close(isUpdated);
    }


    openSnackBar(message: string, action: string, duration?: number): void {
        const dur = duration ? duration : 3000;
        this.snackBar.open(message, action, {
            duration: dur,
        })
    }
}


