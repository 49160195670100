
import { Pipe, PipeTransform } from '@angular/core';

//for billing data format
@Pipe({ name: 'formatDate' })
export class FormatDate implements PipeTransform {
    transform(date: Date, addDays?: number): string {
        //return date as YYYY-MM-DD, optional increment days

        let _date = new Date(date.getTime());
        let newDateString: string;
        let _newDate = addDays ? new Date(_date.setDate(_date.getDate() + addDays)) : new Date(_date.setDate(_date.getDate())); //new Date with add days

        const year = _date.getFullYear();
        const month = '0' + (_date.getMonth() + 1);
        const day = '0' + _newDate.getDate();

        newDateString = [year, month.slice(-2), day.slice(-2)].join('-');

        return newDateString;
    }

    convertToDateString(datestring: string, addDays?: number): string {

        //converts to date at 00:00 hours

        let newDate = new Date(datestring);
        

        let _newDate = addDays ? new Date(newDate.setDate(newDate.getDate() + addDays)):newDate;

        return _newDate.toString();
    }
}


