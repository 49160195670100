<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <span class="pip-modal-title" style="color: var(--c-accent)">Current PBX</span>
  </div>

  <div class="pip-modal-body" class="pip-modal-body" fxLayout="column" fxLayoutGap="16px">
    <div>
      <div matTooltip="The current PBX">PBX: <b>{{data.currentPBXhost}}</b></div>
      <div matTooltip="The current Tenant">Tenant: <b>{{data.currentTenant}}</b></div>
    </div>

    <div *ngIf="!migrationAllowed" style="margin-top: 8px;">
      <i>Migration from this destination is not currently permitted through this tool.</i>

    </div>

    <div style="margin-top: 16px;" *ngIf="migrationAllowed && pbxHostList.length === 0"><i>No valid destination is currently available</i></div>

    <form [formGroup]="newHostForm" [ngClass]="{'readonly-block': pageStatus.isSubmitting} " fxLayout="column" [hidden]="!migrationAllowed || pbxHostList.length === 0" style="margin-top: 16px;">
      <div class="pip-modal-title" style="font-weight: 700;font-size: 14px; margin-bottom: 10px; color: var(--c-accent)">SELECT NEW PBX</div>

      <mat-form-field appearance="outline" class="custom-form-field-v3" floatLabel="always">
        <mat-select placeholder="Select from available PBX" formControlName="new_pbx_id">
          <mat-option *ngFor="let host of pbxHostList" [value]="host?.id">
            {{host?.host}}
          </mat-option>
        </mat-select>
        <mat-label>PBX Host</mat-label>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-form-field-v3" floatLabel="always">
        <mat-select [placeholder]="f.new_pbx_id.value ? 'Service plan' : 'Select New PBX first'"
          formControlName="service_plan">
          <mat-option *ngFor="let serviceplan of pbxServicePlanList" [value]="serviceplan?.id">
            {{serviceplan?.name}}
          </mat-option>
        </mat-select>
        <mat-label>Service Plan</mat-label>
      </mat-form-field>

      <button [disabled]="!newHostForm.valid || pageStatus.isSubmitting" (click)="migrateNow(newHostForm)"
        mat-flat-button mat-button color="accent" style="height:46px;margin-top: 20px;"
        matTooltip="Migrate the tenant and extensions to a different PBX.">
        <ng-container *ngIf="!pageStatus.isSubmitting; else submtting">Migrate Now</ng-container>
        <ng-template #submtting>Please wait..<div class="loader-spinner"></div></ng-template>
      </button>
    </form>

  </div>



  <div class="pip-modal-footer">

  </div>
</div>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>