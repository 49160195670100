import { NgModule } from '@angular/core';

//requirements
import { MaterialModule } from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout'
//create modal components
import { CreateSipModalComponent } from './create-sip-modal.component';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { OrgSearchSelectModule} from '@app/components/form-field/ff-org-search-select/ff-org-search-select.module';
import { OrganisationService} from '@app/services/pipcall/organisation.service';
@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CustomPipesModule,
        DragDropModule,
        FlexLayoutModule,
        StatusIconsModule,
        OrgSearchSelectModule
    ],
    declarations: [CreateSipModalComponent],
    exports: [CreateSipModalComponent],
    providers: [HttpProxy, OrganisationService],
    entryComponents: [CreateSipModalComponent]
})
export class CreateSipModalModule {}
