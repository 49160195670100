
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { PBXService } from '@app/services/pipcall/pbx.service';
import { NumberService } from '@app/services/pipcall/number.service';
import { OrganisationService} from '@app/services/pipcall/organisation.service';
import { OrgPbxMigrationPanelComponent } from './org-pbx-migration-panel.component'
import { MigratePBXModalModal } from './migrate-option-modal/migrate-pbx-modal.component';

//modules
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        UniversalComponentsModule
    ],
    declarations: [OrgPbxMigrationPanelComponent, MigratePBXModalModal],
    exports: [OrgPbxMigrationPanelComponent, MigratePBXModalModal],
    providers: [AppInsightService, PBXService, NumberService, OrganisationService],
    entryComponents: [MigratePBXModalModal],
    schemas: []
})
export class OrgPbxMigrationPanelModule { }
