import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Create the script element
// const script = document.createElement('script');
// script.type = 'text/javascript';
// script.innerHTML = `
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());
//   gtag('config', 'GTM-5TKMLFQS');
// `;
declare global {
    interface Window {
        dataLayer: any[];
    }
}

const scriptdev = document.createElement('script');
const tagmanager = document.createElement('script');
scriptdev.type = 'text/javascript';
scriptdev.innerHTML = `  window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  'debug_mode': true
});`;
// tagmanager.type = 'text/javascript';
// tagmanager.innerHTML = `
// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//   })(window,document,'script','dataLayer','GTM-5TKMLFQS');</script>
// `;


// scriptdev.innerHTML = `
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());
//   gtag('config', 'GTM-5TKMLFQS', {'debug_mode':true});
// `;


if (environment.production) {
    enableProdMode();
    // document.head.appendChild(script);
    // document.head.prepend(tagmanager);
} else {
    // document.head.prepend(tagmanager);
    document.head.prepend(scriptdev);
}

if (!environment.showConsoleLogs) {
    //clear console logs for production
    if (window) {
        window.console.log = function() {};
    }
}



platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
