<ng-container *ngIf="number_id || number; else notFound">

  <ng-container *ngIf="pageStatus?.isLoading === false; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">

      <div class="section-info-bar system" fxLayoutAlign="space-between center" fxLayout="row">
        <div>Editing as system administrator</div>
        <div><button mat-flat-button matTooltip="Refresh data" (click)="refreshPage()"><mat-icon
              svgIcon="refresh"></mat-icon></button></div>
      </div>


      <div fxLayout="row" fxLayout.lt-md="column" style="gap:20px;">


        <div class="section-wrapper" fxFlex="50%" fxFlex.lt-md="100%">

          <tab-title-lined>
            Allocation
          </tab-title-lined>


          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

              <div fxLayout="column" fxFlex="60%" fxLayoutGap="20px">

                <div class="container-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px"
                  fxLayoutGap.lt-md="20px">
                  <div fxFlex="98px" style="height: 98px;">

                    <ng-container *ngIf="number?.organisation_id; else noorg">
                      <div class="usr-avatar-98">
                        <div fxLayoutAlign="center center" class="avatar-empty av-empty-98 activeorg">
                          <div>
                            <mat-icon class="icon-size-34" svgIcon="business"></mat-icon>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-template #noorg>
                      <div fxLayoutAlign="center center" class="avatar-empty av-empty-98 ">
                        <div>Org</div>
                      </div>
                    </ng-template>


                  </div>

                  <div fxLayout="column" fxFlex="250px">
                    <ng-container>
                      <div><strong>Organisation</strong></div>
                      <div>{{number?.organisation_name}}</div>
                      <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="15px">

                        <button *ngIf="number?.organisation_id && number?.pip_variant === 'pipmobile'"
                          [disabled]="number?.user_id" mat-flat-button class="allocation-control-button red"
                          (click)="organisation_deallocate()">Unlink</button>


                        <span *ngIf="number?.pip_variant === 'pipxt'"
                          matTooltip="Cannot leave extensions in an unlinked state. please use the move option">
                          <button *ngIf="number?.organisation_id" mat-flat-button
                            class="allocation-control-button red readonly-block" disabled=true>Unlink</button>
                          <!-- <button *ngIf="number?.organisation_id" [disabled]="number?.user_id" mat-flat-button
                            class="allocation-control-button red" matTooltip="unlink from organisation" (click)="organisation_deallocate()">Unlink</button> -->
                        </span>

                        <button *ngIf="number?.organisation_id" [disabled]="number?.user_id" button mat-stroked-button
                          matTooltip="Move to another organisation" (click)="organisation_allocate(true)"
                          class="button-default">Move</button>
                      </div>
                      <button *ngIf="!number?.organisation_id" [disabled]="number?.organisation_id" mat-flat-button
                        class="allocation-control-button blue" matTooltip="Link to an organisation"
                        (click)="organisation_allocate()">Link Org</button>
                    </ng-container>
                  </div>



                </div>

                <div class="container-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px"
                  fxLayoutGap.lt-md="20px">
                  <div fxFlex="98px" style="height: 98px;">

                    <ng-container *ngIf="number?.user_id; else nouser">
                      <div class="usr-avatar-98 ">
                        <img appGravatar [firstname]="number?.user_first_name" [lastname]="number?.user_last_name"
                          [email]="number?.user_email">
                      </div>
                    </ng-container>

                    <ng-template #nouser>
                      <div class="usr-avatar-98 ">
                        <div fxLayoutAlign="center center" class="avatar-empty av-empty-98 ">
                          <div>User</div>
                        </div>
                      </div>
                    </ng-template>

                  </div>

                  <div fxLayout="column" fxFlex="250px">
                    <ng-container>
                      <div><strong>User</strong></div>

                      <div [matTooltip]="number?.invitation_email" fxLayout="row" fxLayoutAlign="start center"
                        *ngIf="number?.invitation_email && !number?.user_id"><span
                          class="overflow-ellipsis">{{number?.invitation_email}}</span>
                      </div>

                      <div [matTooltip]="number?.user_full_name" class="overflow-ellipsis">{{number?.user_full_name}}
                      </div>

                      <div [matTooltip]="number?.user_email" class="overflow-ellipsis">{{number?.user_email}}</div>

                      <button *ngIf="number?.user_id" mat-flat-button class="allocation-control-button red"
                        (click)="user_deallocate()">Unlink</button>

                      <button *ngIf="!number?.user_id" mat-flat-button class="allocation-control-button blue"
                        [matMenuTriggerData]="{number: number}" [matMenuTriggerFor]="addUser"
                        [disabled]="!number?.organisation_id">Link User</button>
                    </ng-container>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>



        <div class="section-wrapper" fxFlex="50%" fxFlex.lt-md="100%">
 
          <tab-title-lined>
            Event Log
          </tab-title-lined>


          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

              <div fxLayout="column" fxLayoutAlign="center start">
                <div fxLayout="row">Status:&nbsp;{{number.state}}
                  <div [hidden]="number.state !== 'OK'" class="status-icon">
                    <mat-icon class="icon-12" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
                  </div>
                </div>
                <div>{{number?.event_source}} </div>
                <div>{{number?.event_type}} ({{number?.event_code}})</div>

                <div style="margin-top:10px;">Message</div>
                <div>{{number?.event_data}} </div>
                <div [matTooltip]="number?.event_at">~{{number?.event_at | dateTimeAgo}}</div>


                <div matTooltip="{{number?.created_at}}" style="margin-top:20px;"><i>DDI
                    Created&nbsp;<span>{{number?.created_at | date: 'yyyy-MM-dd HH:mm'}}</span></i></div>
              </div>


            </div>

          </div>
        </div>

      </div>

      <!-- <div class="section-divider"></div> -->

      <div class="section-wrapper">
        <tab-title-lined>
          Service ID
        </tab-title-lined>


        <div class="section-content" fxLayout="column">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2">

              <s-grid-items-container>
                <s-grid-item [labelWidthpx]="120">
                  <div role="label">Service identifier:</div>
                  <div role="value">
                    {{number.number}}&nbsp;&nbsp;
                    <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number.number)"
                      class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                      matTooltip="Copy">
                    </mat-icon>
                  </div>
                </s-grid-item>
              </s-grid-items-container>
            </div>

          </div>

        </div>

      </div>
      <!-- <div class="section-divider"></div> -->
      <div class="section-wrapper">
        <tab-title-lined>
          PiP Variant
        </tab-title-lined>

        <tab-section-action [isHidden]="pipvariant?.pristine">
          <button mat-flat-button *ngIf="!pipvariant?.pristine" style="margin-right: 4px;"
          (click)="cancelForm()">Cancel</button>

        <button mat-flat-button aria-label="Save changes" class="save-button"
          [disabled]="pipvariant?.pristine ||  pageStatus.isSubmitting || pipvariant?.invalid"
          (click)="saveVariantChange(pipvariant)">
          <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
        </button>
        </tab-section-action>

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
            

            <form [formGroup]="pipvariant" fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder="1"
              fxFlexOrder.lt-md="2">

              <div fxLayout="row" fxLayoutAlign="start center" style="gap: 8px;">
              <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex="100">
                <mat-select placeholder="select variant" formControlName="pip_variant">
                  <mat-option value="pipmobile">pipmobile</mat-option>
                  <mat-option value="pipxt">pipxt</mat-option>
                </mat-select>
                <mat-label>PiP Variant</mat-label>
              </mat-form-field>

              <div>
                <mat-icon svgIcon="info" matTooltip="This is a backdoor method to change the variant and will by pass a number of steps. Only use this method if you know what you are doing, otherwise use the prefered 'convert or revert extension' controls."></mat-icon>
              </div>
            </div>
              </form>


          </div>

        </div>

      </div>
      <!-- <div class="section-divider"></div> -->

      <ng-container *ngIf="number?.invitation_id">

        <tab-title-lined>
          Invitation
        </tab-title-lined>

        <div class="section-wrapper">

          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

              <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder="1" fxFlexOrder.lt-md="2">

                <s-grid-items-container>
                  <s-grid-item >
                    <div role="label">Url:</div>
                    <div role="value">~/verify/invitation/{{number.invitation_id}}&nbsp;&nbsp;
                      <mat-icon matSuffix svgIcon="copy" (click)="copyInvitationUrl(number.invitation_id)"
                      class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                      matTooltip="Copy Invitation url">
                    </mat-icon></div>
                  </s-grid-item>
                </s-grid-items-container>


              </div>

              <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder="2" fxFlexOrder.lt-md="1">

                The invitation can be completed at this url, this has been sent to the users email address. For
                integrity of the validation step, this should not be provided to the user directly unless you have
                validated the email by other means.

              </div>
            </div>

          </div>

        </div>

        <!-- <div class="section-divider"></div> -->

      </ng-container>

      <div class="section-wrapper">
        <tab-title-lined>
          Notes & Config&nbsp;<mat-icon style="margin-left: 12px;" svgIcon="compose">
          </mat-icon>
        </tab-title-lined>

        <tab-section-action [isHidden]="ddiUpdate?.pristine">
          <button mat-flat-button *ngIf="!ddiUpdate?.pristine" style="margin-right: 4px;"
          (click)="cancelForm()">Cancel</button>

        <button mat-flat-button aria-label="Save changes" class="save-button"
          [disabled]="ddiUpdate?.pristine ||  pageStatus.isSubmitting || ddiUpdate?.invalid"
          (click)="saveDDiUpdate(ddiUpdate)">
          <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
        </button>
        </tab-section-action>

        <form [formGroup]="ddiUpdate">
          <div class="section-content">


            <div fxLayout="row" fxFlex="100%" fxLayout.lt-lg="column" fxLayoutGap="40px" fxLayoutGap.lt-lg="20px">

              <div fxLayout="column" fxFlex="60%" fxFlex.lt-lg="100%" fxFlexOrder.lt-lg="2">

                <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow" fxFlex="100">
                  <textarea formControlName="notes" style="min-height: 100px;" matInput placeholder=""></textarea>
                </mat-form-field>

              </div>
              <div fxLayout="column" fxFlex="40%"></div>
            </div>


          </div>


          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-lg="column" fxLayoutGap="40px" fxLayoutGap.lt-lg="20px"
              [ngClass]="{'readonly-block': isSubmitting}">

              <div fxLayout="column" fxFlex="60%" fxFlex.lt-lg="100%" fxFlexOrder.lt-lg="2">

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between" fxLayoutGap.gt-lg="40px"
                  [matTooltip]="number.user_id === null ? 'Can only be set if there is a linked user': null">


                  <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow" floatLabel="always"
                    fxFlex="100" [ngClass]="{'readonly-block': number?.user_id === null}">
                    <input type="text" matInput placeholder="label" formControlName="label">
                    <mat-label>Label</mat-label>
                  </mat-form-field>


                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between" fxLayoutGap.gt-lg="40px"
                  *ngIf="number?.pip_variant === 'pipmobile'">

                  <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow" floatLabel="always"
                    fxFlex="100" [ngClass]="{'readonly-block': number.user_id === null}">
                    <input type="text" matInput placeholder="CLI override" formControlName="cli_override">
                    <mat-label>CLI Override</mat-label>
                  </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between" fxLayoutGap.gt-md="40px">


                  <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex="100">
                    <mat-select placeholder="Provider" formControlName="provider">
                      <mat-option *ngFor="let item of providersList" [value]="item?.name">
                        {{item?.name}}</mat-option>
                    </mat-select>
                    <mat-label>Provider</mat-label>
                  </mat-form-field>


                  <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex="100"
                    [ngClass]="{'readonly-block': isSubmitting}">
                    <mat-select placeholder="Pbx" formControlName="pbx_platform_id">
                      <mat-option *ngFor="let item of pbxList" [value]="item.id">{{item.id}}
                      </mat-option>
                    </mat-select>
                    <mat-label>PBX</mat-label>
                  </mat-form-field>

                </div>

              </div>

              <div fxLayout="column" fxFlex="40%" fxFlex.lt-lg="100%" fxFlexOrder.lt-lg="1"
                style="padding-top: 18px;height: 100%;">



                <mat-slide-toggle formControlName="is_sms_enabled">SMS enabled</mat-slide-toggle>

                <mat-slide-toggle formControlName="is_blocked">Blocked</mat-slide-toggle>

                <mat-slide-toggle formControlName="is_reserved">Reserved</mat-slide-toggle>

                <mat-slide-toggle [checked]="number?.dnd" [disabled]="true">Do not disturb (user controlled)
                </mat-slide-toggle>


              </div>

            </div>
          </div>

        </form>
      </div>

      <ng-container
        *ngIf="number?.pip_variant === 'pipmobile' && (number?.cli_override_request_id || number?.cli_override)">

        <!-- <div class="section-divider"></div> -->

        <div class="section-wrapper">

          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px"
              fxLayoutAlign="center center">

              <ng-container *ngIf="number?.cli_override_request_id && !number?.cli_override">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <div>
                    A Temporary number port has been requested on this number. Verification is pending before it can be
                    applied.
                  </div>

                  <div>
                    {{number?.cli_override}}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="number?.cli_override">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <div>
                    There is a CLI override on this number. It will be used as the outgoing display number instead of
                    the
                    user's CLI when making calls.
                  </div>
                  <div>
                    {{number?.cli_override}}
                  </div>
                </div>

              </ng-container>

            </div>
          </div>
        </div>


      </ng-container>



      <ng-container *ngIf="number && number?.pip_variant === 'pipxt' && pbxHostListLoaded">
        <!-- <div class="section-divider"></div> -->

        <ng-container
          *ngIf="(!number?.number.startsWith('00447') || !number?.pbx_tenant_id || !number?.pbx_id);else newExtension">
          <panel-extension-config-legacy [number]="number" [viewAs]="viewAs"
            (updateParent)="triggerUpdate()"></panel-extension-config-legacy>
        </ng-container>


        <ng-template #newExtension>

          <panel-extension-tenant [number]="number" [viewAs]="viewAs"
            (updateParent)="triggerUpdate()"></panel-extension-tenant>

          <!-- <div class="section-divider"></div> -->
          <panel-extension-config [number]="number" [viewAs]="viewAs"
            (updateParent)="triggerUpdate()"></panel-extension-config>
        </ng-template>

      </ng-container>

      <!-- <div class="section-divider"></div>

      <div class="section-wrapper">

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px"
            fxLayoutAlign="center center">

            <button class="delete-button" [disabled]="number?.user_id" (click)="deleteNumberConfirm(number)"
              mat-flat-button matTooltip="Permanently delete this extension" style="min-width: 330px;">Delete</button>

            <button (click)="propagateNumber(number?.number)" mat-flat-button
              matTooltip="Resend the user's data to the telephony platform and app. This is a safe operation so you are free to click it as often as required."
              style="background: var(--c-accept); color: white;width: 330px;">Propagate Changes</button>


          </div>
        </div>
      </div> -->

    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>

<mat-menu #addUser="matMenu" class="v3-mat-menu" xPosition="before">

  <ng-template matMenuContent let-number="number">
    <button mat-menu-item title="Invite a user to be assigned number" (click)="openInvitePiPXTModal(number)"
      *ngIf="number.pip_variant === 'pipxt'">

      <mat-icon>person_add</mat-icon>
      <span>Invite New User</span>
    </button>

    <button *ngIf="viewAs==='sysAdmin'" mat-menu-item title="Link with existing user"
      [matMenuTriggerFor]="availableUsers" [matMenuTriggerData]="{number: number}">
      <mat-icon>swap_horiz</mat-icon>
      <span>Link Existing</span>
    </button>


  </ng-template>
</mat-menu>

<mat-menu #availableUsers="matMenu" class="v3-mat-menu">

  <ng-template matMenuContent let-number="number">
    <ng-container *ngIf="availableUserList?.length === 0 || !availableUserList; else showList">
      <div mat-menu-item>There are no available users </div>
    </ng-container>

    <ng-template #showList>
      <ng-container *ngFor="let user of availableUserList">
        <button mat-menu-item title="Link number with user"
          (click)="assignToUser(number, user.id, user.first_name, number.organisation_id)">
          <span>{{user.first_name}} {{user.last_name}}
            <ng-container *ngIf="user?.organisation_list[0]?.ddi_list[0]?.number !== null;else zero">
              ({{user?.organisation_list[0]?.ddi_list?.length}})
            </ng-container>
            <ng-template #zero>(0)</ng-template>
          </span>
        </button>
      </ng-container>
    </ng-template>

  </ng-template>
</mat-menu>