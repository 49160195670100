
import { ErrorHandler } from '@angular/core';
import { Injectable} from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    }
}
// handle loading chunk failed error due to lazy loading

// https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
