
interface Iinvitation {
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    mobile: string,
    organisation_Id: string,
    allocation_option: string,
    is_org_admin: boolean,
    is_accepted: boolean,
    organisation_name: string,
    invited_by_name: string
    invited_by_id: string;
    cli_override: string;
    cli_override_request_id: string
    invoice_id: string;
    license_id: string;
    licensed_at: string;
    preallocated_pip_variant: string;
    preallocated_sip_extension_cli: string;
    preallocated_sip_extension_number: string;

}

//get existing invitation details
export class InvitationModel implements Iinvitation {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    organisation_Id: string;
    allocation_option: string;
    is_org_admin: boolean;
    is_accepted: boolean;
    organisation_name: string;
    invited_by_name: string;
    invited_by_id: string;
    cli_override: string;
    cli_override_request_id: string;
    invoice_id: string;
    license_id: string;
    licensed_at: string;
    preallocated_number: string;
    preallocated_pip_variant: string;
    preallocated_sip_extension_cli: string;
    preallocated_sip_extension_number: string;
}


//update invitation details by invitee
export class InvitationUpdateDetails {
    first_name: string;
    last_name: string;
    mobile: string;
}




//PROVISION new Invitation-------------

interface ICartInvitationProvision {
    organisation_id: string,
    first_name?: string,
    last_name?: string,
    email: string;
    allocation_option: string;
    is_org_admin: boolean;
    cli_override: string;
    preallocated_number: string;
}
export class InvitationProvision implements ICartInvitationProvision {
    organisation_id: string;
    first_name?: string;
    last_name?: string;
    email: string;
    allocation_option: string;
    is_org_admin = false;
    cli_override: string;
    license_id: string;
    preallocated_number: string;
}






