//core
import { Component, OnInit, SimpleChanges, Inject, isDevMode, HostListener, ViewChild, ElementRef , EventEmitter, Input, Output} from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef, MatDialog } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'
import {LicenseModel, SelectedLicenceModel} from '@app/models/license.model';
import { CurrencyPipe } from '@angular/common'

import { SelectPackageModalComponent} from '@app/components/modals/select-package-modal/select-package-modal.component';

export interface User {
    type: 'User' | 'Invitation';
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    number_info: string;
    is_mobile_license: boolean;
}

export interface ApplyToAll {
    licence_id: string;
    isMobile: boolean;
}
@Component({
    selector: 'app-payment-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    animations: [
    ]
})
export class UserListComponent {

    @Input() tariff: any;

    @Input() lineTotal: any;

    @Input() user: User;

    @Input() licenseList: LicenseModel[];

    @Input() disableForm: boolean;

    @Input() applyToAllEvent: SelectedLicenceModel;

    @Output() _selectedLicense = new EventEmitter();
    @Output() applyToAll = new EventEmitter();
    //consider restore form/selections

    // licenseType:'talk' | 'talk_and_text' | 'basic_talk' | 'basic_talk_and_text' | '' = '';
    // contract_type:'1y_up-front' | '1m_monthly' | '1y_monthly' | '' = '';
    licenseCostPounds = '0';
    licenseCostPence = '00';
    selectedLicenseId: string;
    selectedLicence: SelectedLicenceModel;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
        public dialog: MatDialog,
        private currencyPipe: CurrencyPipe, ) {
        this.selectedLicence = new SelectedLicenceModel();
    }
    ngOnInit() {

    }


    // selectionChange(userid:string, event){
    //     var item = this.licenseList.find(
    //         item =>
    //           item.type === this.licenseType &&
    //           item.contract_type === this.contract_type
    //       );
    //     item? this.emitValue(item.id): this.emitValue(null);
    //     item?  this.convertToCurrencyString(item.price): null;
    // }

    ngOnChanges(changes: SimpleChanges) {
        changes['applyToAllEvent']?.currentValue !== changes['applyToAllEvent']?.previousValue ? this._applyToAllEvent(changes['applyToAllEvent']?.currentValue) : null;
    }

    _applyToAllEvent(licence: SelectedLicenceModel) {
        // if (!this.selectedLicence?.license_id) {
        if (this.user.is_mobile_license && licence?.license_id && licence?.licence_type === 'talk_and_text') {
            this.licenseSelected(licence);
        } else if (this.user.is_mobile_license === false && licence?.license_id && licence?.licence_type === 'talk') {
            this.licenseSelected(licence);
        }
        // }
    }

    openPackageSelect(isMobileTypeSelected: string, id?: string) {
        const numberType = isMobileTypeSelected  ? 'mobile' : 'landline';

        const data = { numberType: numberType, mode: 'checkout' };

        const dialogRef = this.dialog.open(SelectPackageModalComponent, {
            panelClass: 'pipcall-modal-container',
            data: data
            // width: '680px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result?.licence) {
                //do action on modal close
                this.licenseSelected(result.licence, result?.applyToAll);
            }
        });
    }


    licenseSelected(event: SelectedLicenceModel, applyToAll?: boolean) {

        this.selectedLicenseId = event?.license_id;
        const output = {id: this.user.id, license_id: this.selectedLicenseId, type: this.user.type, applyToAll: applyToAll, isMobile: this.user.is_mobile_license, licence: event};

        event?.price ?  this.convertToCurrencyString(event.price) : null;  //update Pricing
        this.selectedLicence = event;
        this._selectedLicense.emit(output);
    }

    convertToCurrencyString(price) {
        const total = this.currencyPipe.transform(price / 100, 'GBP', '', '1.2-2');
        const splitTotal = total.split(".");
        this.licenseCostPence = splitTotal[1];
        this.licenseCostPounds = splitTotal[0];
    }

    // emitValue(value) {
    //     this.selectedLicenseId = value;
    //     let output = {id:this.user.id, license_id:value, type:this.user.type};
    //     this._selectedLicense.emit(output);
    // }



}
