import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '@app/models/response.model';

export class PdfResponse {
    success: string;
    fileUrl: string;
    expiryTime: string;
    message: string;
}

@Injectable()
export class InvoiceService {
    public ApiBaseUrl: string;
    public codeV2 = `?code=${environment.apicode}`
    public options;

    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    getInvoiceById(invoice_id: string): Observable<responseModel> {
        console.log("[[api]] -> get invoice by invoice id");
        return this.httpProxy.get(this.ApiBaseUrl + `/invoices/${invoice_id}` + this.codeV2, 'response', true);
    }

    getInvoiceByOrg(id: string): Observable<responseModel> {
        console.log("[[api]] -> get invoices by org");
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${id}/invoices` + this.codeV2, 'response', true);
    }

    getInvoicePdf(organisation_id: string, id: string): Observable<PdfResponse> {
        console.log("[[api]] -> get invoice pdf");
        // {
        //example response
        //     "success": false,
        //     "fileUrl": null,
        //     "expiryTime": null,
        //     "message": "File doesn't exist"
        //   }
        return this.httpProxy.get(`${this.ApiBaseUrl}/organisations/${organisation_id}/pdf/${id}${this.codeV2}`, 'body', true);
    }

    getInvoices(base_date: string, unit: string, noOfdays: string): Observable<responseModel> {
        console.log("[[api]] -> get invoices");
        return this.httpProxy.get(`${this.ApiBaseUrl}/invoices/from/${base_date}/to/day/${noOfdays}${this.codeV2}`, 'response', true);
    }

    patchInvoiceState(id: string): Observable<responseModel> {
        //interim and monthly, Failed and Unpaid only
        //set invoice state back to Complete for monthly
        //set open for Interim
        console.log("[[api]] -> patch invoice state");
        const body = {};
        return this.httpProxy.patch(this.ApiBaseUrl + `/invoices/${id}/state/active${this.codeV2}`, body, 'response');

    }

    patchInvoiceNote(id: string, notes: string): Observable<responseModel> {
        console.log("[[api]] -> patch invoice note");
        const body = {
            "notes": notes
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/invoices/${id}/notes${this.codeV2}`, body, 'response');
    }

    deleteInvoice(id: string, notes: string): Observable<responseModel>  {
        console.log("[[api]] -> delete invoice");
        const body = {
            "notes": notes
        }
        return this.httpProxy.delete(this.ApiBaseUrl + `/invoices/${id}/unpaid${this.codeV2}`, 'response', true, body);
    }

    postRestoreInvoice(id: string, notes?: string): Observable<responseModel>  {
        console.log("[[api]] -> patch restore invoice");
        const body = {
            "notes": notes
        }
        body.notes = body.notes + ` [restored ${id}]`
        return this.httpProxy.post(this.ApiBaseUrl + `/invoices/${id}/restore${this.codeV2}`, body, 'response', true);
    }
}
