import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components
import { AllocateDdiToUserModalComponent } from './allocate-ddi-to-user-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule } from '@app/pipes/pipes.module';

//services
import { NumberService } from '@app/services/pipcall/number.service'

import { OrgUserSelectModule } from '@app/components/form-field/ff-org-user-select/ff-org-user-select.module'

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule,
        FlexLayoutModule,
        CustomPipesModule,
        OrgUserSelectModule
    ],
    declarations: [AllocateDdiToUserModalComponent],
    exports: [AllocateDdiToUserModalComponent],
    providers: [AppInsightService, AuthService, NumberService],
    entryComponents: [AllocateDdiToUserModalComponent]
})
export class AllocateDdiToUserModalModule { }
