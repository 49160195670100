<div class="container">
    <div class="message text-center">
  
      <div class="display-picture text-center">
          <img src="./assets/img/access-denied.png">
      </div>
      <div class="display-msg">
        <h1>Sorry you dont seem to have permission to access that page !</h1>
        <button mat-raised-button color="warn" routerLink="/redirect">Home</button>
      </div>
  
  
    </div>
  </div>
  