<div class="pip-modal-content">
  <button class="pip-modal-close_btn"  mat-mini-fab color="primary" (click)="closeModal()"aria-label="close modal"> 
    <mat-icon svgIcon="clear"></mat-icon>
  </button> 
  <div class="pip-modal-header">
    <!-- <span class="pip-modal-close" (click)="closeModal()">X</span> -->
    <span class="pip-modal-title">Terms and Conditions</span>
    <!-- <span class="pip-modal-subtitle">This is a subtitle</span> -->
  </div>
  <div id="pipmodalbody" class="pip-modal-body pip-scroll-shadow" (scroll)="scrollEvent($event)">

    <div [style.margin-top.px]="55">
        <h5>
          1. Payment Terms and Conditions
        </h5>
        <p>
          The PiPcall App is provided free of charge subject to the App terms and conditions found at
          <a href="http://www.pipcall.com/apptermsandconditions/" target="_blank" rel="noopener">
            www.pipcall.com/apptermsandconditions/
          </a>
        </p>
      </div>
      <div>
        <h5>
          2. PiP Licences
        </h5>
        <p>
          Our Services are provided according to the PiP Licences purchased by the Organisation. We currently have 4 PiP Licence types:
        </p>
        <table>
          <tbody>
            <tr>
              <th>
                PiP Licence
              </th>
              <th>
                PiP Number Allocated
              </th>
              <th>
                Calls/Texts
              </th>
            </tr>
            <tr>
              <td>
                Talk PAYG
              </td>
              <td>
                01 and 02 Geographic numbers
              </td>
              <td>
                Packages of incoming and outgoing UK call minutes - See section 4 below
              </td>
            </tr>
            <tr>
              <td>
                Talk &amp;Text PAYG
              </td>
              <td>
                07 Mobile number
              </td>
              <td>
                Packages of incoming and outgoing UK minutes and outgoing Texts - See section 4 below
              </td>
            </tr>
            <tr>
              <td>
                Talk Unlimited
              </td>
              <td>
                01 and 02 Geographic numbers
              </td>
              <td>
                Unlimited UK call minutes - See section 5 below
              </td>
            </tr>
            <tr>
              <td>
                Talk &amp; Text Unlimited
              </td>
              <td>
                07 Mobile number
              </td>
              <td>
                Unlimited UK call minutes and Texts - See section 5 below
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          These PiP Licences can each be purchased as an Annual pay upfront, Annual pay monthly or on a Monthly rolling basis and assigned to users in your organisation. The latest pricing and allowances for these PiP Licences can be found on the PiPcall Website at
          <a href="http:// www.pipcall.co./pricing/" target="_blank" rel="noopener">
            www.pipcall.com/pricing/
          </a>
        </p>
      </div>
      <div>
        <h5>
          3. UK Calls and Texts
        </h5>
        <p>
          UK calls are classed as:
        </p>
        <ul>
          <li>
            UK Geographic (starting with 01 or 02)
          </li>
          <li>
            UK Non-geographic (starting with 03)
          </li>
          <li>
            UK Freephone numbers (0800 and 0808)
          </li>
          <li>
            UK Mobile (starting with 07)
          </li>
        </ul>
        <p>
          Calls to premium rate and special numbers are currently blocked.
        </p>
      </div>
      <div>
        <h5>
          4. PAYG PiP Licence Conditions
        </h5>
        <p>
          The PAYG PiP Licences are designed for low usage users in the organisation. The price of each PAYG Licence includes a basic allowance of incoming and outgoing call minutes. The PAYG Talk &amp; Text Licence also has an allowance of outbound texts.
        </p>
        <p>
          Pricing for our PAYG Call Packages and the minutes and Text allowances can be found on the PiPcall website
          <a href="http://www.pipcall.com/pricing/" target="_blank" rel="noopener">
            www.pipcall.com/pricing/
          </a>
        </p>
        <p>
          Purchasing multiple PiP PAYG Licences will effectively &lsquo;pool&rsquo; the minutes and Text allowances across all PAYG users.
        </p>
        <p>
          When the &lsquo;pool&rsquo; of PAYG minutes and Texts have been consumed by users in the organisation, another PAYG licence is automatically purchased as soon as another call minute or outgoing text is made.
        </p>
        <p>
          Purchase of this additional Licence will again add to the &lsquo;pool&rsquo; and allow all other PAYG users in your organisation access to the additional minutes and texts
        </p>
        <p>
          Note that every sixth PAYG package purchased of any one type in any one month billing period is free of charge.
        </p>
        <p>
          Any unused minutes or texts from a PAYG Licence purchased in a particular month will be carried over to the next month at the end of a billing period.
        </p>
        <p>
          All PAYG calls, to international destinations, also consume the PAYG minute allowance.
        </p>
        <p>
          This means that a PAYG user making an international call will consume a minute of the UK geographic allowance plus a minute of the international package. A PAYG user receiving an international call will only consume the UK geographic allowance.
        </p>
      </div>
      <div>
        <h5>
          5. Unlimited PiP Licence Conditions
        </h5>
        <p>
          The unlimited PiP Licences are set for optimum simplicity and have no restrictions on incoming or outgoing minutes or texts if chosen.
        </p>
        <p>
          Unlimited is subject to the usual fair usage rules - see section XX
        </p>
        <p>
          Furthermore when making international calls only the international package is consumed.
        </p>
        <p>
          An Organisation can have a mixture of Unlimited and PAYG licences but the PAYG Licences are separate and cannot take from the Unlimited allowances.
        </p>
      </div>
      <div>
        <h5>
          6. International Destinations
        </h5>
        <p>
          Calls to international destinations are not included in the above PiP Licences.
        </p>
        <p>
          If your users need to call International destinations, the first call made in your organisation will trigger the purchase of an International Call Package containing a set number of minutes to selected popular international destinations.
        </p>
        <p>
          Purchase of this package will allow all other users who are allowed to make international calls in your organisation access to the &lsquo;pool&rsquo; of minutes in the International Call Package.
        </p>
        <p>
          Once an International Call Package has been consumed, another one will be purchased automatically if another call is made. Note that every sixth package purchased in any one month billing period is free of charge. The latest pricing, country list and inclusive minutes for our International Call Package can be found on the PiPcall Website at
          <a href="http://www.pipcall.com/international/" target="_blank" rel="noopener">
            www.pipcall.com/international/
          </a>
        </p>
        <p>
          Any unused minutes from an International Call Package at the end of a billing period will be carried over to the next month.
        </p>
        <p>
          As the Administrator for the Organisation you have the ability to restrict any user from making international calls. It is not possible to send international Texts with PiPcall.
        </p>
      </div>
      <div>
        <h5>
          7. Payment
        </h5>
        You will be responsible for paying your Licence Fees on the terms we make available to your Organisation.
        <p>
          Payment will be made by recurring payments on a valid UK Credit or Debit card that we will hold in the secure server managed by Stripe (www.stripe.com)
        </p>
        <p>
          When you first purchase a PiP Licence or Licences we will take full payment for the first period immediately. This may be the full Annual or first monthly charge as applicable.
        </p>
        <p>
          The date that you purchase your first PiP Licence will now become your billing anniversary and will be used to calculate all PiP Licence and package charges.
        </p>
        <p>
          Your PiP Licences will automatically renew at the end of their term (Annual or Monthly) on your billing anniversary day unless you elect to remove them. Note that this will be subject to the minimum terms applied (Annual or Monthly).
        </p>
        <p>
          On your billing anniversary we will send a statement advising you of the expected charges. We will then take payment 3 days later using the payment method you have registered with us.
        </p>
        <p>
          If you have made no chargeable activity in the billing period we will not send you an invoice. This is likely to happen for Annual pay up front Organisations with no PAYG users and no International calls.
        </p>
      </div>
      <div>
        <h5>
          8. PiP Licence Changes
        </h5>
        <p>
          If you add, remove or upgrade any existing PiP Licences midway through your billing month, we will take a pro-rata payment for the remainder of the month starting at midnight and ending on your billing anniversary.
        </p>
        <p>
          Similarly, if the change results in a credit being raised, this will be applied on the next working day.
        </p>
        <p>
          Note that it is not possible to downgrade an Annual PiP Licence to an Annual Monthly or a Monthly rolling PiP Licence.
        </p>
        <p>
          It is also not possible to downgrade an unlimited PiP Licence to a PAYG PIP Licence.
        </p>
        <p>
          It is permissible to upgrade a Talk Licence to a Talk &amp; Text Licence.
        </p>
        <p>
          It is permissible to upgrade a monthly rolling PiP Licence to an annual PiP Licence.
        </p>
        <p>
          Note that all upgraded PiP Licence periods commence at the time of the upgrade.
        </p>
      </div>
      <div>
        <h5>
          9. Non Payment
        </h5>
        <p>
          In the event of non payment or failure of the registered payment method we will contact you immediately and seek a resolution.
        </p>
        <p>
          We reserve the right to terminate or suspend your access to PiPcall Licences and packages if we have not received payment of the relevant Charges within fourteen (14) days of the due date of such Charges, and we will notify you that we have not received such payment.
        </p>
        <p>
          In such event, we will suspend your Organisation&rsquo;s access to the PiP Licences and Packages and remove the ability to make and receive calls or SMS until such time as your Organisation has paid any outstanding Charges.
        </p>
      </div>
      <div>
        <h5>
          10. Fair Usage
        </h5>
        <p>
          Although we provide an unlimited number of calls and SMS in our Unlimited package, these are bound by the PiPcall fair usage policy which can be found on the PiPcall website at
          <a href="http:// www.pipcall.com/fairusepolicy/" target="_blank" rel="noopener">
            www.pipcall.com/fairusepolicy/
          </a>
        </p>
      </div>
  
  </div>
  <div class="pip-modal-footer">
    <button mat-flat-button color="primary" (click)="closeModal()">Close</button>
  </div>
</div>
