<div class="container">
  <div class="message col-md-12 row text-center">

    <div class="display-picture col-md-6 order-first order-md-last text-center">
        <img src="./assets/img/not-found-robot.png">
    </div>
    <div class="display-msg col-md-6">
      <h1>Page not found !</h1>
 
    </div>


  </div>
</div>
