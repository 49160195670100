import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormControl, Form, AbstractControl } from '@angular/forms';
import {MatSnackBar  } from '@app/material/material-essentials.module';

//normalise number
import { RegionCodes, NormalizeNumberService } from '../../../services/helpers/normalize-number.service';

// import { EmailValidator } from '../../../validators/emailValidator.directive';
import { PhonenumberValidator } from '../../../validators/phonenumberValidator.directive';
import { ValidateEmailNotTaken } from '../../../validators/async-email.validator';
import { ValidateMobileNotTaken, ValidateMobileNotTakenV2 } from '../../../validators/async-mobile.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { InvitationService } from '../../../services/pipcall/invitation.service';
import { InvitationUpdateDetails, InvitationModel } from '../../../models/invitation.model';
import { TitleCasePipe } from '@angular/common';
import { AppTitleService } from '../../../services/helpers/update-title.service';
import { AppInsightService} from '../../../services/helpers/app-insights.service';

@Component({
    selector: 'app-invitation-details-update',
    templateUrl: './invitation-update-details.component.html',
    styleUrls: ['./invitation-update-details.component.scss']
})
export class InvitationDetailsUpdateComponent implements OnInit {

    public loading = true; // start screen as loading.
    public success = false; //set to true if correct data returned.
    public submitted = false;
    public normalizedNumber: string;

    public id: string;
    public termsconditions: boolean; //matcheckbox

    public hideNameFields = false;
    invitationForm: FormGroup;
    public invitationUpdate: InvitationUpdateDetails;
    public invitation: InvitationModel;

    public preallocatedNumber = ''; //mobile should not match this

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private _formBuilder: FormBuilder,
        private normalizeNumberService: NormalizeNumberService,
        private invitationService: InvitationService,
        private snackBar: MatSnackBar,
        private appTitleService: AppTitleService,
        private titlecasePipe: TitleCasePipe,
        private appInsightService: AppInsightService,
        private signupService: SignupService,
        private ref: ChangeDetectorRef
    ) {


        this.invitationUpdate = new InvitationUpdateDetails();
        this.invitation = new InvitationModel();
        this.invitationForm = this._formBuilder.group({
            first_name: ['', [Validators.maxLength(18), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
            last_name: ['', [Validators.maxLength(18), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
            email: ['', [Validators.email, Validators.required]],
            mobile: ['', [Validators.required, PhonenumberValidator.validateMobileNumber], [ValidateMobileNotTakenV2.createValidator(this.signupService)]],
            region: ['0044', Validators.required],
            environment: ['lv01', Validators.required],
            cli_override: ['']
        });
    }


    ngOnInit() {
        this.appTitleService.setTitle('PiPcall: Invitation');
        this.appInsightService.logPageView('Verify: Invitation details');


        this.activatedRoute.params
            .switchMap(routeParams => {
                if (routeParams['id']) {
                    this.id = routeParams['id'];
                    return this.GetInviteDetails(this.id)
                } else {
                    //no id returned. can not continue
                    this.setResultScreen(false);
                    return Observable.throw("Id not found")
                }
            })
            .subscribe(data => {
                if (data.status === 200 && !data.body.is_accepted) {
                    console.log("invite details returned", data);
                    this.invitation = data.body;
                    this.invitationForm.controls.email.patchValue(data.body.email , {emitEvent: false});
                    this.invitationForm.controls.first_name.patchValue(data.body.first_name, {emitEvent: false});
                    this.invitationForm.controls.last_name.patchValue(data.body.last_name,  {emitEvent: false});
                    data?.body?.preallocated_number ? this.preallocatedNumber = data.body.preallocated_number : null;
                    data.body.cli_override ? this.invitationForm.controls.cli_override.patchValue(data.body.cli_override , {emitEvent: false}) : null;
                    this.hideNameFields =  ((data.body?.first_name === null || data.body?.first_name === '') || (data.body?.last_name === null || data.body?.last_name === '')) ? false : true;
                    this.ref.detectChanges();
                    this.invitationForm.markAsPristine();

                    if (data.body.mobile) {
                        this.invitationForm.controls.mobile.setValue(data.body.mobile)
                        this.invitationForm.controls.mobile.markAsTouched();
                    }
                    this.setResultScreen(true);
                } else {
                    this.setResultScreen(false); ;
                    console.log("no data returned. handle this.")
                }
            }, (err) => {
                this.setResultScreen(false);
                //display error message on final screen
                //put up a generic error screen. no id or expired.
            });

    }

    ngOnDestroy() {

    }


    public GetInviteDetails(id: string) {
        return this.invitationService.getInvitationDetails(id);
    }

    SubmitForm(f: FormGroup) {
        console.log(f);
        if (!f.valid) {
            // eslint-disable-next-line guard-for-in
            for (const i in f.controls) {f.controls[i].markAsTouched(); }
            return;
        }

        //normalize the phone number in 00 format.
        // const newFormat = this.normalizeNumberService.normaliseNumberWith00(this.invitationForm.controls.mobile.value, this.invitationForm.controls.region.value);
        const region = this.invitationForm.controls.region.value
        const numberTrimmed = this.invitationForm.controls.mobile.value.trim().replace(/\s+/g, '');
        const newFormat = numberTrimmed.startsWith('0') ? numberTrimmed.replace('0', region) : region + numberTrimmed;
        // this.invitationForm.controls.mobile.setValue(newFormat);
        //titlecase the names
        f.controls.first_name.patchValue(this.transformToTitleCase(f.value.first_name));
        f.controls.last_name.patchValue(this.transformToTitleCase(f.value.last_name));


        this.invitationUpdate.first_name = f.value.first_name.trim();
        this.invitationUpdate.last_name = f.value.last_name.trim();
        // this.invitationUpdate.mobile = f.value.mobile;
        this.invitationUpdate.mobile = newFormat;


        //catch for preallocated number entered by mistake
        if (this.invitationUpdate.mobile === this.preallocatedNumber) {
            return alert('Number should be your handset, not your new businessnumber')
        }

        console.log("invitation verify - SubmitForm", this.invitationUpdate);
        //submit the form values here
        this.submitted = true;
        this.invitationService.putInvitationDetails(this.invitationUpdate, this.id).subscribe((resp) => {
            console.log("resp", resp);
            if (resp.status === 200 || resp.status === 201) {
                this.router.navigate([`./sms`], { relativeTo: this.activatedRoute });
            } else {
                throwError(resp);
            }

        }, (err) => {
            //snackbar error here
            this.submitted = false;
            this.openSnackBar("Sorry, something went wrong. Please contact support if problem persists.", "Dismiss");
        })

        //all good. spinner and that fun.
    }

    setResultScreen(bool: boolean): void {
        //true returned all good
        if (bool) {
            this.loading = false;
            this.success = true;
        } else {
            //false. returned unsuccessfull
            this.loading = false;
            this.success = false;
        }
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    transformToTitleCase(value: string) {
        return this.titlecasePipe.transform(value);
    }



}
