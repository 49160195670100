import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild , ChangeDetectorRef, Renderer2} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { HttpClient } from '@angular/common/http';
import { Subscription, Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import 'rxjs/add/operator/switchMap';
import { debounceTime, distinctUntilChanged, startWith, map, tap, first, delay, switchMap } from 'rxjs/operators';
import { merge, fromEvent , Subject} from "rxjs";
import { finalize } from 'rxjs/operators';
import { OrgMetaData } from '@app/models/organisation.model';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { SignupService } from '@app/services/pipcall/signup.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { ValidateMobileNotTakenV2 } from '@app/validators/async-mobile.validator';
import { ExpoService, ExpoSignupModel } from '@app/services/pipcall/expo.service';

@Component({
    templateUrl: './expo-signup.component.html',
    styleUrls: ['./expo-signup.component.scss'],
    animations: [
    ],
})
export class ExpoSignupComponent implements OnInit {
    isDev = false;
    public viewAs = "sysAdmin"; //owner,admin or user. set to owner if matches on return of data.

    public pageStatus = {
        stage: 0,
        isLoading: true,
        isSubmitting: false
    }

    verificationCheckSubscription: Subscription;

    expoSignupForm: FormGroup;
    modified_at: Date = new Date();
    _modified_at: string = this.modified_at.toISOString();

    metaDefaults: OrgMetaData = {
        modified_at : this._modified_at,
        version : "1.1",
        source : "Midlands Expo 2024-02-22",
        onboarding : {
            stage: 2,
            label: "user_invitations"
        }
    }

    expo_signup_request_id: string = null;

    //fallback values
    signupConfig: any = {
        maxVerificationCheckTime: 120000,
        verificationCheckInterval: 5000,
        verificationNumber: '07588 89 84 85',
        qrCode: 'https://pipcallportalstorage.blob.core.windows.net/files/QR-code-for-expo-signups.png'
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private appInsightService: AppInsightService,
        private fb: FormBuilder,
        private appTitleService: AppTitleService,
        private signupService: SignupService,
        private ref: ChangeDetectorRef,
        private expoService: ExpoService,
        private renderer: Renderer2,
        private http: HttpClient
    ) {
        this.appTitleService.setTitle("admin/expo/signup");
        this.appInsightService.logPageView('Admin: Expo Signup');
        this.isDev = isDevMode();
        this.expoSignupForm = this.fb.group({
            first_name: ['', [Validators.maxLength(80), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
            last_name: ['', [Validators.maxLength(80), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
            organisation_name: ['', [Validators.maxLength(100), Validators.required]],
            email: ['', [Validators.email, Validators.required], [ValidateEmailNotTaken.createValidator(this.signupService)]],
            mobile: ['', [Validators.required, PhonenumberValidator.validateMobileNumber], [ValidateMobileNotTakenV2.createValidator(this.signupService)]],
            metadata: ['', Validators.required],
            region: ['', Validators.required],
        });
    }


    ngOnInit() {
        this.getSignupConfig();
        this.pageStatus.stage = 0; //reset to signup form
        this.pageStatus.isLoading = false;
        this.expoSignupForm.controls['region'].patchValue('0044');
        this.setMetaDefaults();
        this.renderer.setStyle(document.body, 'background-color', '#2b2d42');
        this.ref.detectChanges();
    }

    getSignupConfig() {
        this.http.get<any>('https://pipcallportalstorage.blob.core.windows.net/files/expo-signup-config.json' + '?timestamp=' + new Date().getTime()).subscribe(data => {
            //update dashboardConfig IF these values exist in the json file
            console.log('update signup config,', data)
            data['maxVerificationCheckTime'] ?  this.signupConfig.maxVerificationCheckTime = data['maxVerificationCheckTime'] : null;
            data['verificationCheckInterval'] ?  this.signupConfig.verificationCheckInterval = data['verificationCheckInterval'] : null;
            data['verificationNumber'] ?  this.signupConfig.verificationNumber = data['verificationNumber'] : null;
            data['qrCode'] ?  this.signupConfig.qrCode = data['qrCode'] : null;
        });
        this.ref.detectChanges();
    }

    ngAfterViewInit() {

    }

    triggerVerificationScreen() {
        console.log("triggerVerificationScreen, this is current this.expo_signup_request_id",  this.expo_signup_request_id);
        this.pageStatus.stage = 1;
        this.expo_signup_request_id ?  this.checkVerificationStatus(this.expo_signup_request_id) : null;
    }

    checkVerificationStatus(expo_signup_request_id) {
        console.log("checkVerificationStatus fire every 5 seconds for 2 minutes, using expo_signup_request_id", expo_signup_request_id);

        const intervalId = setInterval(() => {
            if (!this.expo_signup_request_id) {
                console.log("No expo_signup_request_id, clearing interval");
                clearInterval(intervalId); // Stop interval if expo_signup_request_id becomes null
                return;
            }

            this.verificationCheckSubscription = this.expoService.getExpoSignupByID(expo_signup_request_id)
                .subscribe(
                    (resp) => {
                        console.log('response from API', resp?.body);
                        if (resp?.body?.state === "Migrated") {
                            console.log("migrated, now clearing interval and expo_signup_request_id");
                            this.pageStatus.stage = 2;
                            this.expo_signup_request_id = null;
                            this.ref.detectChanges();
                            this.verificationCheckSubscription?.unsubscribe();
                            clearInterval(intervalId); // Stop interval after successful migration
                        }
                    },
                    (err) => {
                        console.log(err);
                        this.verificationCheckSubscription?.unsubscribe();
                        this.expo_signup_request_id = null;
                        clearInterval(intervalId); // Stop interval on error
                    }
                );
        }, this.signupConfig.verificationCheckInterval); // Repeat every 5 seconds

        // Clear interval after 2 minutes
        setTimeout(() => {
            console.log("clearing interval after 2 minutes");
            clearInterval(intervalId);
            this.resetForm();
        }, this.signupConfig.maxVerificationCheckTime); // 2 minutes in milliseconds
    }

    setMetaDefaults() {
        const metaString: string = JSON.stringify(this.metaDefaults);
        this.expoSignupForm.controls['metadata'].patchValue(metaString);

        console.log("------------------------------metaDefaults", JSON.stringify(metaString));
    }
    submitForm(f: FormGroup) {
        if (!f.valid) {return this.openSnackBar("Please fill in all fields correctly", "dismiss")};

        //format mobile
        const mobileTrimmed = f.value.mobile.trim().replace(/\s+/g, '');
        const newFormat = mobileTrimmed.startsWith('0') ? mobileTrimmed.replace('0', f.value.region) : f.value.region + mobileTrimmed;

        const signupData: ExpoSignupModel = {
            first_name: f.value.first_name.trim(),
            last_name: f.value.last_name.trim(),
            organisation_name: f.value.organisation_name.trim(),
            email: f.value.email,
            mobile: newFormat,
            metadata: f.value.metadata
        }

        this.pageStatus.isSubmitting = true;
        this.expoService.postNewExpoSignup(signupData)
            .subscribe((resp) => {
                this.pageStatus.isSubmitting = false;
                this.expo_signup_request_id = resp.body?.expo_signup_request_id;
                this.triggerVerificationScreen();
            }, err => {
                console.log(err);
                this.pageStatus.isSubmitting = false;
                this.openSnackBar(`Error submitting form [${err.status}]`, "dismiss");
                switch (err.status) {
                    case 401: {
                        this.openSnackBar(`Unauthorized [${err.status}]`, "dismiss");
                        break;
                    }
                    case 307: {
                        this.openSnackBar(`${err.error} [${err.status}]`, "dismiss");
                        break;
                    }
                    default: {
                        this.openSnackBar(`Error submitting form [${err.status}]`, "dismiss");
                        break;
                    }
                }
            }
            );
    }

    resetForm() {
        this.expoSignupForm.reset();
        this.expo_signup_request_id = null;
        this.pageStatus.stage = 0;
        this.ngOnInit();
    }

    exitSignup() {
        this.router.navigate(['admin/expo/dashboard']);
    }

    debugForm(any?: any) {
        this.pageStatus.stage = 1;
    }

    forceMigration(mobile: string) {
        this.expoService.patchExpoState(mobile)
            .subscribe((resp) => {
                console.log("forceMigration response", resp);
                this.openSnackBar("Migration forced successfully", "dismiss");
            }, (err) => {
                this.openSnackBar("Error forcing migration", "dismiss");
            });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        this.verificationCheckSubscription?.unsubscribe();
    }
}
