<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">
    <!-- [@fadeOutOnLeave] -->
    <div [@fadeInOnEnter] class="tab-child-view-container">

      <div *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled'"
        class="section-info-bar" fxLayoutAlign="space-between center" fxLayout="row"
        style="background:var(--c-error);color:white;height: 50px;">Account is closed</div>
      <div *ngIf="organisationProfile?.state === 'Declined'" class="section-info-bar"
        fxLayoutAlign="space-between center" fxLayout="row" style="background:var(--c-warn);color:white;height: 50px;">
        Trial has now ended</div>

      <div class="section-wrapper" *ngIf="showHelpBox">
        <div class="section-action">
          <div class="copy-highlighted-background" style="font-size: 14px;flex-grow:1">
            <p>If you wish to link an unused number to a new user, please get in touch at <a
                href="mailto:support@pipcall.com?subject=Link an unused number" target="_blank">PiPcall Support</a>.</p>
          </div>
        </div>
      </div>

      <!-- <div class="section-divider"></div> -->

      <div class="section-wrapper">
        <tab-title-lined>Numbers&nbsp;({{dataSource?.filteredData.length}})</tab-title-lined>

        <div class="section-content">

          <div fxLayout="column" fxFlex="100%"
            *ngIf="dataSource?.data?.length === 0 || dataSource?.data?.length === null">
            <div class="information-banner warning" fxLayoutAlign="start center" fxFlex="100%">
              <div>
                <mat-icon svgIcon="warning"></mat-icon>
              </div>
              <div class="message-body">No numbers exist yet for this organisation. Get started by inviting a user from
                the users tab. {{unusedNumbers()}}
              </div>
            </div>

          </div>

          <div fxLayout="column" fxFlex="100%" [hidden]="!(dataSource?.data?.length > 0)">


            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end" fxLayoutAlign.lt-md="center"
              style="margin-bottom: 20px;">

              <mat-form-field appearance="outline" class="simple-input-v2" fxFlex.lt-md="100%">
                <input role="search" autocomplete="off" matInput placeholder="Search..." aria-label="search"
                  maxlength="150" tabindex="1" (keyup)="applyFilter($event.target.value)">
              </mat-form-field>
            </div>

            <table mat-table class="table tab-table-basic clean-spaced clean-add-row-border" [dataSource]="dataSource"
              matSort
              [ngClass]="{'disable-actions': viewAs !== 'sysAdmin' && (organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled' || organisationProfile?.state === 'Declined')}">

              <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Number </th>
                <td mat-cell *matCellDef="let row">
                  <div fxLayout="row" fxLayoutAlign="start center" class="no-wrap">
                    <mat-icon *ngIf="row.is_blocked" style="color: var(--c-error)" svgIcon="block"></mat-icon>

                    <ng-container *ngIf="!row.is_blocked">
                      <div class="table-icon-btn-wrapper"><button
                          matTooltip="Business Number: {{row.number | normalizeToInternational}}"
                          mat-icon-button><mat-icon svgIcon="phone"></mat-icon></button></div>
                    </ng-container>
                    &nbsp;&nbsp;<span (click)="openNumberProfile(row)" class="number-row clickable">{{row.number |
                      normalizeToInternational}}</span>


                  </div>
                  <button *ngIf="row?.cli_override_request_id" style="margin-top: 8px;" mat-flat-button
                    class="small-button system-blue-button"
                    matTooltip="There is a pending temporary number port on this item. Click to complete the verification"
                    [routerLink]="['/','manage','verify', 'port', row.cli_override_request_id]">Complete Number
                    Port</button>

                  <button *ngIf="row?.cli_override" style="margin-top: 8px;" mat-flat-button
                    class="small-button system-blue-button"
                    matTooltip="There is a number port on this item. During your trial this number will be set as the display for all outgoing calls. We can Port this number into PiPcall after you upgrade your account to active.">{{row?.cli_override
                    | normalizeToInternational}}</button>
                </td>
              </ng-container>


              <ng-container matColumnDef="user_full_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row">

                  <ng-container *ngIf="row.user_full_name || row.invitation_email; else unassigned">

                    <div fxLayout="row" fxLayoutAlign="start center">

                      <ng-container *ngIf="row.user_id">
                        <div class="usr-avatar-md">
                          <img appGravatar [firstname]="row.user_first_name" [lastname]="row.user_last_name"
                            [email]="row.user_email">
                        </div>

                        <div class="linked-user-wrapper">
                          <div class="row1 clickable overflow-elipsis" style="color: var(--c-url);"
                            (click)="openUserProfile(row?.user_id)">{{row.user_first_name}} {{row.user_last_name}}</div>
                          <div class="row2 overflow-elipsis">{{row.user_email}}</div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="!row.user_id && row.invitation_email">


                        <div class="usr-avatar-md">
                          <div class="empty-user-avatar email-icon-avatar" fxLayout="column"
                            fxLayoutAlign="center center">
                            <mat-icon svgIcon="email"></mat-icon>
                          </div>
                        </div>

                        <div fxLayout="column" style="padding-left:12px;">
                          <div>Pending Invitation</div>
                          <div>{{row.invitation_email}}</div>
                        </div>

                      </ng-container>

                    </div>


                  </ng-container>


                  <ng-template #unassigned>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div class="usr-avatar-md" fxHide.lt-md>
                        <div class="empty-user-avatar">

                        </div>
                      </div>

                      <div fxLayout="column" style="padding-left:12px;">
                        <div style="color:var(--c-placeholder)">-No user-</div>
                      </div>

                    </div>
                  </ng-template>

                </td>
              </ng-container>

              <ng-container matColumnDef="settings">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row">
                  <ng-container *ngIf="row?.is_sms_enabled">
                    <span matTooltip="You can control this value from the user view">
                      <span class="sms-enabled-wrapper">
                    <mat-slide-toggle class="slide-toggle" disabled [checked]="true"></mat-slide-toggle>
                    SMS
                  </span>
                    </span>

                  </ng-container>

                  <!-- <mat-icon *ngIf="row?.is_sms_enabled" class="icon-22" svgIcon="message"
                    matTooltip="SMS enabled"></mat-icon> -->
                </td>
              </ng-container>


              <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->

              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>

            </table>


            <div class="no-data" *ngIf="dataSource.filteredData && dataSource.filteredData.length === 0">No results
              returned</div>
            <mat-paginator itemsPerPageLabel="'items'" [pageSizeOptions]="[20, 35, 50]" [showFirstLastButtons]="true">
            </mat-paginator>



          </div>


        </div>
      </div>


    </div>

    <!-- <div class="section-divider"></div> -->
  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>