import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { MockDataService } from '@app/services/pipcall/mockdata.service';
import { ModalService } from '@app/services/modal-service/modal.service';

//component
import { ExtensionsConfigPanelComponent } from './extension-config-panel.component'
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';


import { NumberService } from '@app/services/pipcall/number.service';
import { PBXService } from '@app/services/pipcall/pbx.service';
import { TenantSearchSelectV2Module } from '@app/components/form-field/ff-tenant-search-select-v2/ff-tenant-search-select-v2.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        TenantSearchSelectV2Module,
        UniversalComponentsModule
    ],
    declarations: [ExtensionsConfigPanelComponent],
    exports: [ExtensionsConfigPanelComponent],
    providers: [AppInsightService, NumberService, PhonenumberValidator, PBXService, MockDataService, ModalService],
    entryComponents: []
})
export class ExtensionsConfigPanelModule { }
