import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { cartProvision } from '../../models/cart.model';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '../../models/response.model';
import { observable } from 'rxjs';

//GET
export class SavedQuery {
    Id: string;
    table_id: string;
    title: string;
    description: string;
    query: string;
    organisation_id: string;
    is_protected: boolean;
    is_shared: boolean;
    author: string;
    author_id: string;
    created_at: string;
    modified_at: string;
    settings?: string;
    version?: string;
}

//POST
export class SaveQueryForm {
    table_id: string;
    title:  string;
    description:  string;
    user_id:  string;
    organisation_id:  string;
    is_shared: boolean;
    is_protected: boolean;
    query:  string;
    version?: string;
    settings?: string;
}

@Injectable()
export class UserDataService {


    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;

    public options;

    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }


    //this one is not currently in use
    public getSavedQueryByID(id: string): Observable<responseModel> {
        console.log("[[api]] -> get saved query by id ", id);
        return this.httpProxy.get(this.ApiBaseUrl + '/v2/TableSearchQuery/' + id + this.code, 'response', false)
    }

    //think of adding user-id and making this more flexible like query functionality
    public getSavedQueries(table_id: string, organisation_id: string, is_shared?: boolean, user_id?: string): Observable<responseModel> {
        console.log("[[api]] -> get saved queries ", table_id,  organisation_id);
        let query = '';

        if (is_shared) {
            query =  `&rest=table_id=${table_id}&organisation_id=${organisation_id}&is_shared=true&$sort_by=modified_at`;
        } else if (!is_shared && user_id) {
            console.log("USER QUERY")
            query = `&rest=table_id=${table_id}&organisation_id=${organisation_id}&user_id=${user_id}&is_shared=false&$sort_by=modified_at`;
        } else {
            query = `&rest=table_id=${table_id}&organisation_id=${organisation_id}&$sort_by=modified_at`;
        }

        return this.httpProxy.get(this.ApiBaseUrl + '/v2/TableSearchQuery/search' + this.code + query, 'response', true)
    }


    public postSavedQuery(f: SaveQueryForm): Observable<responseModel> {
        console.log("[[api]] -> post SavedQuery ", f);
        const body = {
            title: f.title,
            table_id: f.table_id,
            description: f.description,
            user_id: f.user_id,
            organisation_id: f.organisation_id,
            is_protected: f.is_protected,
            is_shared: f.is_shared,
            query: f.query,
            version: f?.version,
            settings: f?.settings
        }
        return this.httpProxy.post(this.ApiBaseUrl + '/v2/TableSearchQuery' + this.code, body,  'response', true)
    }

    // public putSavedQuery(query_id: string, query: any): Observable<responseModel> {
    //     //replace existing query
    //     const body = {
    //         query_id: query_id,
    //         user_id: user_id,
    //         organisation_id: organisation_id,
    //         query: query
    //     }
    //     return this.httpProxy.put(this.ApiBaseUrl + '/query/' + query_id + this.code, body,  'response', false)
    // }

    public deleteSavedQuery(query_id: string): Observable<responseModel> {
        return this.httpProxy.delete(this.ApiBaseUrl + '/v2/TableSearchQuery/' + query_id + '/' + this.code,  'response', true)
    }

}


// public _getMOCKSavedQueries(table_id: string, organisation_id: string): Observable<responseModel> {
//     const resp: responseModel = {
//         body: this.mySavedQueries,
//         status: 200,
//         ok: true,
//         statusText: 'test',
//         type: 1,
//         url: null
//     }
//     return Observable.of(resp);
// }
