import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { FirstPaymentModalModule } from '@app/components/modals/first-payment-modal/first-payment-modal.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';
//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { PaymentsService} from '@app/services/pipcall/payments.service';
import { InvoiceService } from '@app/services/pipcall/invoice.service';

//component
import { OrgTabBillingComponent } from './org-billing.component'
import { InvoiceModule } from '@app/components/modals/invoice-modal/invoice-modal.module';
import { InvoiceDeleteModalModule } from '@app/components/modals/delete-invoice-modal/delete-invoice-modal.module';
import { RestoreDeletedInvoiceModalModule } from '@app/components/modals/restore-deleted-invoice-modal/restore-deleted-invoice-modal.module';
import { StripePaymentModalModule} from '@app/components/stripe-payment/stripe-payment.module';

import {UpdateCardDetailsModalModule} from '@app/components/modals/update-card-modal/update-card-modal.module';
import {InvoiceBottomSheetModule } from '@app/components/bottom-sheet-modal/invoice-bsheet-modal/invoice-bsheet-modal.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        InvoiceModule,
        InvoiceDeleteModalModule,
        RestoreDeletedInvoiceModalModule,
        StripePaymentModalModule,
        UpdateCardDetailsModalModule,
        FirstPaymentModalModule,
        InvoiceBottomSheetModule,
        UniversalComponentsModule
    ],
    declarations: [OrgTabBillingComponent],
    exports: [OrgTabBillingComponent],
    providers: [AppInsightService, OrganisationService, PaymentsService, InvoiceService],
    entryComponents: []
})
export class OrgTabBillingModule { }
