import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { FirstPaymentModalModule} from '@app/components/modals/first-payment-modal/first-payment-modal.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

//component
import { OrgTabOverviewComponent } from './org-overview.component'

// bottom sheet
import { UserProfileBottomSheetModule} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        FirstPaymentModalModule,
        UserProfileBottomSheetModule,
        GravatarDirectiveModule,
        UniversalComponentsModule
    ],
    declarations: [OrgTabOverviewComponent],
    exports: [OrgTabOverviewComponent],
    providers: [AppInsightService, OrganisationService],
    entryComponents: []
})
export class OrgTabOverviewModule { }
