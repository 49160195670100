<ng-container [ngSwitch]="version">

  <!-- new implemenation -->
  <ng-container *ngSwitchCase="1">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="cto-row-container">
      <div class="avatar-container av-size-30" *ngIf="organisation_name">
        <div class="avatar-icon-background" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon style="fill: white;height: 18px;width: 18px;" svgIcon="business"></mat-icon>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start">

        <div class="cto-top-row-wrapper hover-to-show clickable">
          
          <a href="/admin/organisation/{{organisation_id}}/profile" class="overflow-elipsis cto-max-width"
            matTooltipPosition="below" matTooltipShowDelay="200"
            matTooltip="Open {{organisation_name}}">{{organisation_name}}</a>


          <mat-icon (click)="openOrgDetails(organisation_name, organisation_id, viewAs)" matSuffix svgIcon="view"class="clickable action-icon" matTooltipPosition="below"
            matTooltipShowDelay="200" matTooltip="Quick view"></mat-icon>
            <!-- //stop propagation xaused issue on other pages?  -->
        </div>

        <div class="cto-bottom-row-wrapper" *ngIf="organisation_name && organisation_state && !hide_detail">

          <span class="overflow-elipsis cto-bottom-row-content cto-max-width cto-wd-230" [matTooltip]="">
            {{organisation_state}}&nbsp;
          </span>

          <div *ngIf="organisation_state === 'Active'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
            <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
              class="icon-14" svgIcon="block"></mat-icon>
          </div>

          <div *ngIf="organisation_state === 'Trial'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
            <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
              class="icon-14" svgIcon="block"></mat-icon>
          </div>

          <div *ngIf="organisation_state=== 'In Arrears'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
            <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
              class="icon-14" svgIcon="block"></mat-icon>
          </div>

        </div>


      </div>

    </div>
  </ng-container>


  <!-- legacy implementation -->
  <ng-container *ngSwitchDefault>
    <!-- <div fxLayout="row" fxLayoutAlign="start center" class="hover-to-show-copy">

      <div class="avatar-container av-size-38" *ngIf="organisation_name">
        <div class="avatar-icon-background" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon style="fill: white;height: 22px;width: 22px;" svgIcon="business"></mat-icon>
        </div>
      </div>

      <div fxLayout="column" style="padding-left:12px;">

        <div style="color: var(--c-url);white-space: nowrap;">

          <span (click)="openOrgDetails(organisation_name, organisation_id, viewAs)"
            class="clickable overflow-elipsis max-width-name" style="color: var(--c-url)">{{organisation_name}}</span>

          <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
            class="icon-14" svgIcon="block"></mat-icon>

          <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(organisation_id)" class="clickable copy-icon"
            matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy">
          </mat-icon>

        </div>

        <div *ngIf="organisation_state">
          <span style="opacity: 0.6;font-size:0.9em;">{{organisation_state}}&nbsp;</span>
          <div *ngIf="organisation_state === 'Active'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
          </div>
          <div *ngIf="organisation_state === 'Trial'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
          </div>
          <div *ngIf="organisation_state=== 'In Arrears'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
          </div>
        </div>

      </div>
    </div> -->
  </ng-container>

</ng-container>