import { Component, AfterViewInit, ElementRef , ViewChild, Input, ViewEncapsulation} from '@angular/core';
@Component({
    selector: 's-grid-item',
    templateUrl: './s-grid-item.component.html',
    styleUrls: ['./s-grid-item.component.scss'],
    animations: [
    ],
    encapsulation: ViewEncapsulation.None
})
export class SGridItemComponent implements AfterViewInit {
    @ViewChild('labelElement', { static: false }) labelElement: ElementRef;
    hasLabelContent = false;

    @Input() labelWidthpx: number;

    ngAfterViewInit() {
        this.checkLabelContent();
    }

    private checkLabelContent() {
        if (this.labelElement) {
            const labelContent = this.labelElement.nativeElement.innerHTML.trim();
            this.hasLabelContent = labelContent !== '';
            console.log('this.hasLabelContent', this.hasLabelContent);
        }
    }

}
