<ng-container *ngIf="!pageStatus.isError; else notFound">
  <div fxLayout="row" fxFlex="100%" fxLayoutAlign="start center" *ngIf="pageStatus.hasMobile === true">
    <div>User already is set up as a PiP user. </div>&nbsp;<button mat-stroked-button
      (click)="triggerUpdate()">Continue</button>
  </div>

  <div fxLayout="column" fxFlex="100%" fxLayoutGap="18px" fxLayoutGap.lt-md="20px"
    *ngIf="pageStatus.hasMobile === false">

    <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%; height: 80px;"
      *ngIf="this.pageStatus.verified">
      Verified! You may now close this window.
    </div>

    <ng-container *ngIf="!this.pageStatus.verified">

      <div>
        <p>Choose your new virtual number below. </p>

        <p><b>Select Your Number:</b> Browse and select from a list of available numbers. </p>
        <p style="display: flex; align-items: center; gap: 10px; font-size: 12px;">Don't see a number you would like? <mat-icon
            class="mat-hint-icon" svgIcon="info"
            matTooltip="Non-geographic numbers and more area codes are available upon request. If you are interested in this, please select any landline for now and then contact support@pipcall.com to discuss pricing and have your number upgraded"></mat-icon>
        </p>
        <!-- <div class="tip-box" matTooltip="Non-geographic numbers and more area codes are available upon request. If you are interested in this, please select any landline for now and then contact support@pipcall.com to discuss pricing and have your number upgraded" align="end"><mat-icon class="mat-hint-icon" svgIcon="info" ></mat-icon>&nbsp;Don't see a number you would like?</div> -->

        <!-- <p> Please provide the 07* number of your mobile handset. This number will be the one that you use for the app's passwordless sign-in.</p> -->

      </div>


      <form [formGroup]="activateHandset" fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2"
        [ngClass]="{'readonly-block':  pageStatus.isSubmitting || verificationStep === 1}">

        <div fxLayout="row" formGroupName="pipnumber" fxFlex="100%">

          <mat-form-field appearance="outline" class="custom-form-field-v3" floatLabel='never' style="width: 100%;">
            <mat-select placeholder="select your number" formControlName="allocation_option">
              <mat-option *ngFor="let code of areaCodes" value="{{code.id}}">
                {{code.name}} {{code.code}}
              </mat-option>
            </mat-select>
            <mat-label>Select Business Number</mat-label>
            <status-icons *ngIf="activateHandset?.controls.pipnumber.get('allocation_option')?.touched"
              [iconStatus]="activateHandset.controls.pipnumber.get('allocation_option').status"></status-icons>
          </mat-form-field>
        </div>


  <div fxLayout="column" [hidden]="activateHandset.controls.pipnumber.get('allocation_option').value === ''">
    <p>Verify your mobile number, this will also be used to log into the app:</p>


  <div class="mobile-container" style="display: flex;flex-direction: row; align-items: center;position: relative;" >

    <mat-form-field appearance="outline"
      class="custom-form-field-v3 number-code-select readonly-block">
      <mat-select formControlName="region">
        <mat-option value="0044" selected>+44</mat-option>
      </mat-select>
      <status-icons *ngIf="activateHandset?.controls.mobile.dirty"
        [iconStatus]="activateHandset?.controls.mobile.status"></status-icons>
    </mat-form-field>

    <mat-form-field appearance="outline" class="custom-form-field-v3 adjustlabel-for-region-field-100"
      style="width: 100%;">
      <mat-label>Verify Handset</mat-label>
      <input type="text" matInput placeholder="Enter handset" formControlName="mobile">
      <mat-error
        *ngIf="cactivateHandset?.controls.mobile?.touched && activateHandset?.controls.mobile?.errors?.required">
        Mobile is required</mat-error>
      <mat-error class="errormsg" *ngIf="activateHandset?.controls?.mobile.status === 'PENDING'">
        checking ...
      </mat-error>
      <mat-error
        *ngIf="activateHandset?.controls.mobile.touched && activateHandset?.controls?.mobile.errors?.mobileTaken">
        Mobile already registered with an account.
      </mat-error>
      <mat-error *ngIf="activateHandset?.controls?.mobile.touched && activateHandset?.controls?.mobile.errors?.valid">
        {{activateHandset?.controls?.mobile.errors?.valid}}
      </mat-error>
      <mat-icon matSuffix svgIcon="info"
        matTooltip="This is the number for the device you would like to install PiPcall on. You will need to verify you own this number before you can use the app."></mat-icon>
    </mat-form-field>
  </div>
</div>


  <!-- <div fxLayout="row" fxFlex="100%" fxLayoutGap="20px" fxLayoutAlign="space-between"> -->


  <!-- <mat-form-field appearance="outline" fxFlex="100%" class="custom-form-field-v3">
          <input type="text" matInput formControlName="mobile" placeholder="Mobile handset +44">
          <mat-error
            *ngIf="activateHandset?.controls.mobile?.touched && activateHandset?.controls.mobile?.errors?.valid">
            Number is not a valid UK mobile. Remember to use +44 format.
          </mat-error>
          <mat-error
            *ngIf="activateHandset?.controls.mobile?.touched && activateHandset?.controls.mobile?.errors?.required">
            Mobile is required</mat-error>
          <mat-error *ngIf="activateHandset.get('mobile').status === 'PENDING'">
            checking ...
          </mat-error>
          <mat-error *ngIf="activateHandset.get('mobile').touched && activateHandset.get('mobile').errors?.mobileTaken">
            Mobile already registered with an account.
          </mat-error>
          <status-icons *ngIf="activateHandset?.controls.mobile?.touched"
            [iconStatus]="activateHandset.get('mobile').status">
          </status-icons>
        </mat-form-field> -->

  <div
    [hidden]="!organisationProfile.is_pipmobile_enabled || organisationProfile.state === 'Trial' || numberType === ''"
    style="margin-top: 14px;">


    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="organisationProfile.state !== 'Trial'"
      style="position: relative;">


      <a href="javascript:void(0);" class="no-underline" tabindex="-1" *ngIf="organisationProfile.state !== 'Trial'"
        (click)="openPackageSelect(numberType)"
        [ngClass]="{'text-licence-gradient' : selectedLicence?.licence_type_display?.includes('Text'), 'talk-licence-gradient' : selectedLicence?.licence_type_display?.includes('Talk') && !selectedLicence?.licence_type_display?.includes('Text')}"
        [disabled]="organisationProfile.state === 'Trial'  || (numberType === 'new' && activateHandset?.controls.pipnumber.get('allocation_option').valid === false) || !numberType || numberType === ''">
        <ng-container
          *ngIf="selectedLicence?.display && activateHandset.controls['selected_license'].value; else selectIt">{{selectedLicence?.licence_type_display}}
          {{selectedLicence?.display}}</ng-container>
        <ng-template #selectIt>

          <ng-container
            *ngIf="organisationProfile.state === 'Trial' || !numberType || numberType === '';else selectNotDisabled">-</ng-container>
          <ng-template #selectNotDisabled>Select Licence</ng-template>

        </ng-template>
      </a>
    </div>

  </div>




  <div>
    <button mat-flat-button aria-label="Save Changes" class="verification-button" color="accent"
      [disabled]="activateHandset?.pristine ||  pageStatus.isSubmitting || activateHandset.invalid || verificationStep === 1 || activateHandset?.pending"
      (click)="registerHandset(activateHandset)">
      <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Verify</ng-container>
    </button>
  </div>


  </form>


</ng-container>
</div>

</ng-container>

<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>

<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>