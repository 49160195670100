//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrgMetaData, OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
import { Subscription } from 'rxjs';
import { ConfirmBillingStateModalService} from '@app/components/modals/confirm-billing-state-modal/confirm-billing-state-modal.service';
import { ConfirmBillingStateModalData } from '@app/components/modals/confirm-billing-state-modal/confirm-billing-state-modal.component';
import { FormatDate } from '@app/pipes/formatDate';
import { escape, unescape} from 'safe-string-literal';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
export interface State {
    name: string;
}

@Component({
    selector: 'app-org-tabs-system',
    templateUrl: './org-system.component.html',
    styleUrls: ['./org-system.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OrgTabSystemComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional ?
    @Input() organisation_id: string;


    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }

    public sharedDataSubscription: Subscription;
    public stateOptions: State[];

    //forms
    organisationStateForm: FormGroup;
    updateTrialDateForm: FormGroup;
    updateFeesForm: FormGroup;
    updateOrgNoteForm: FormGroup;
    updateOrganisationSettingForm: FormGroup

    public onboardState = {
        stage: null,
        completedStage: 3,
        label: null,
        get isShown() {
            return this.stage !== null && this.stage > -1 && this.stage < this.completedStage ? true : false;
        },
        get isCompleted() {
            return this.stage >= this.completedStage ? true : false;
        }
    }

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private staticDataService: StaticDataService,
        private confirmBillingStateModalService: ConfirmBillingStateModalService,
        private ref: ChangeDetectorRef,
        private modalService: ModalService
    ) {

        this.organisationStateForm = this._formBuilder.group({
            id: [{ value: '', disabled: true }, Validators.required],
            state: ['', {updateOn: 'change', validators: [Validators.required]}]
        });



        this.updateTrialDateForm = this._formBuilder.group({
            id: ['', Validators.required],
            trial_end_date: ['', {updateOn: 'change', validators: [Validators.required]}]
        });

        //set in £
        this.updateFeesForm = this._formBuilder.group({
            initial_set_up_fee: [null, {updateOn: 'change', validators: [Validators.required]}],
            monthly_service_charge: [null, {updateOn: 'change', validators: [Validators.required]}]
        });

        this.updateOrgNoteForm = this._formBuilder.group({
            id: ['', Validators.required],
            notes: ['', {updateOn: 'change', validators: [Validators.required]}]
        });

        this.updateOrganisationSettingForm = this._formBuilder.group({
            id: ['', Validators.required],
            is_pipmobile_enabled: [false,  {updateOn: 'change'}],
            is_pipxt_enabled: [false, {updateOn: 'change'}]
        });

        this.sharedDataSubscription = this.staticDataService.organisationStateListAsObservable.subscribe((resp) => {
            this.stateOptions = resp;
        });
    }

    ngOnInit() {
        this.staticDataService.innit();

        if ( !this.organisationProfile ) {
            this.getOrganisationDetails();
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setInitialFormValues(this.organisationProfile) : null;
    }

    setMetaData() {
        let _meta;
        let parseddata;

        try {
            _meta =  unescape(this.organisationProfile?.metadata);
            parseddata = JSON.parse(_meta);
        } catch (error) {
            parseddata = null;
            console.error("Error parsing JSON or it was null");
        }
        const meta: OrgMetaData = this.organisationProfile?.metadata ? parseddata : null;
        // const meta: OrgMetaData = this.organisationProfile?.metadata ? JSON.parse(unescape(this.organisationProfile?.metadata)) : null;
        console.log('THE META DATA: ', meta );
        //why is it displaying only 4
        this.onboardState.stage = meta?.onboarding ? meta.onboarding.stage : null;
        this.onboardState.label = meta?.onboarding ? meta.onboarding.label : null;
    }

    saveNote(f: FormGroup) {
        console.log("note UPDATE", f);
        if (f.invalid) {
            this.openSnackBar('Please fill in all required fields', 'dismiss');
        }
        this.submitForm(this.organisationService.patchOrganisationNote(this.organisationProfile?.id, f.value?.notes));
    }

    patchState(f: FormGroup) {
        console.log("STATE UPDATE", f);
        if (f.invalid) {
            this.openSnackBar('Please fill in all required fields', 'dismiss');
        }
        this.submitForm(this.organisationService.patchOrganisationState(this.organisationProfile?.id, f.value?.state));
    }

    setInitialFormValues(organisationProfile: OrganisationModel) {

        this.setFormValue(this.organisationStateForm, 'id', organisationProfile?.id);
        this.setFormValue(this.organisationStateForm, 'state', organisationProfile?.state);


        this.setFormValue(this.updateTrialDateForm, 'id', organisationProfile?.id);
        this.setFormValue(this.updateTrialDateForm, 'trial_end_date', organisationProfile?.trial_end_date);

        this.setFormValue(this.updateFeesForm, 'initial_set_up_fee', this.organisationProfile?.initial_set_up_fee ? this.organisationProfile?.initial_set_up_fee / 100 : 0.00 );
        this.setFormValue(this.updateFeesForm, 'monthly_service_charge', this.organisationProfile?.monthly_service_charge ? this.organisationProfile?.monthly_service_charge / 100 : 0.00);


        this.setFormValue(this.updateOrganisationSettingForm, 'id', organisationProfile?.id);
        this.setFormValue(this.updateOrganisationSettingForm, 'is_pipmobile_enabled', organisationProfile?.is_pipmobile_enabled);
        this.setFormValue(this.updateOrganisationSettingForm, 'is_pipxt_enabled', organisationProfile?.is_pipxt_enabled);

        this.setFormValue(this.updateOrgNoteForm, 'id', organisationProfile?.id);
        this.setFormValue(this.updateOrgNoteForm, 'notes', organisationProfile?.notes);

        this.setMetaData();
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getOrganisationDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;
        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {

                if (resp.status === 200 ) {
                    this.organisationProfile = resp.body;
                    this.setInitialFormValues(this.organisationProfile);
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })

    }


    trialdDateValueChange(val): void {
        //datepicker value changed
        const formatedDate = new FormatDate().transform(val);
        this.updateTrialDateForm.controls.trial_end_date.patchValue(formatedDate);
        this.updateTrialDateForm.updateValueAndValidity();
        this.updateTrialDateForm.markAsDirty();
    }

    saveTrialDate(f: FormGroup) {
        this.submitForm(this.organisationService.patchOrganisationTrialEnd(this.organisationProfile?.id, f.value?.trial_end_date));
    }

    blockOrganisation() {
        this.submitForm(this.organisationService.patchOrganisationBlocked(this.organisationProfile.id, true));
    }

    unBlockOrganisation() {
        this.submitForm(this.organisationService.patchOrganisationBlocked(this.organisationProfile.id, false));
    }

    updateFeeAmount(control: string, event) {
        //on blur - set correct format for fees.
        this.updateFeesForm.controls[control].patchValue(event.target.value, {emitEvent: true} )
    }

    saveOrgProductSettings(f: FormGroup) {
        this.submitForm(this.organisationService.organisationProductSettings(f.value.id, f.value.is_pipxt_enabled, f.value.is_pipmobile_enabled));
    }

    saveUpdateOganisationFees(f: FormGroup) {
        console.log("SUBMIT : ",   f.value.initial_set_up_fee, " MONTH:",    f.value.monthly_service_charge);
        this.submitForm(this.organisationService.patchOrganisationFees(this.organisationProfile?.id,   f.value.initial_set_up_fee * 100,   f.value.monthly_service_charge * 100));
    }

    confirmBillingState( organisation_id: string, currentState?: boolean) {
        const data = new ConfirmBillingStateModalData;
        data.isBillingEnabled = currentState;
        data.organisation_id = organisation_id;

        this.confirmBillingStateModalService.openConfirmBillingStatetModal(data, (response: boolean) => {

            console.log(" RESPONSE: ", response);

            if (response) {
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            }
        });

    }

    // saveOrganisationName(f: FormGroup) {
    //     if (f.valid) {
    //         this.submitForm(this.organisationService.patchOrganisationName(f.getRawValue().id, f.getRawValue().name));
    //     }
    // }

    cancelForm() {
        this.setInitialFormValues(this.organisationProfile);
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    updateOnboardingState(stage: number) {
        //display confirm dialog here
        const data: ModalConfirmData = {
            title: 'Confirm update',
            content: `Are you sure you wish to update the onboarding stage?  <br><br> New value: ${stage}`,
            confirmButtonLabel: "Confirm",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                const existingMeta: OrgMetaData = this.organisationProfile?.metadata ? JSON.parse(unescape(this.organisationProfile?.metadata)) : null;
                this.pageStatus.isSubmitting = true;
                this.organisationService.patchOnboardingMetaReset(this.organisationProfile?.id, stage, existingMeta).subscribe((resp) => {
                    this.openSnackBar('Saved', 'dismiss');
                    this.pageStatus.isSubmitting = false;
                    this.triggerUpdate();
                },
                (err) => {
                    console.log(err);
                    this.pageStatus.isSubmitting = false;
                    this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
                })
                return;
            }
            console.log('answer was No');
        });


    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    debugState(state) {
        this.organisationProfile.state = state;

    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
