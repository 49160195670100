<ng-container *ngIf="isLoading ||  areaCodes ===null;else loaded">
  loading area codes ....<i class="fas fa-spinner fa-spin"></i>
</ng-container>


<ng-template #loaded>

  <ng-container *ngIf="areaCodes?.length > 0;else nocodes">
    <mat-form-field appearance="outline" class="custom-form-field-v3">
      <mat-select [(ngModel)]="selectedAreacode" (selectionChange)="emitValue($event)" placeholder="Select an areacode">
        <mat-option *ngFor="let areacode of areaCodes" [value]="areacode.id">
          {{areacode.name}} {{areacode.code}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </ng-container>

  <ng-template #nocodes>
    <div><i> could not get the list of area codes right now.</i></div>
  </ng-template>

</ng-template>
