import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { responseModel } from '../../models/response.model';
import { OrganisationModel, OrganisationQueryRequest, OrgMetaData } from '../../models/organisation.model';
import { FormatDate } from '@app/pipes/formatDate';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
import { escape, unescape} from 'safe-string-literal';

export interface PBXServicePlan {
    id: string
    name: string
    tbd: string
    min_charge: string
    connection_charge: string
    inclusive: string
    grace_period: string
    billing: string,
    call_rating: string
}

export interface PBXHostListModel {
    id: string;
    host: string;
}

export interface TenantListModel {
    server_id: number;
    name: string;
    tenantcode: string;
    package_id: number;
    package: string;
    ext_length: number;
    country_id: number;
    country_code: number;
}



@Injectable()
export class PBXService {
    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;

    public options;
    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }


    public postUpdatePassword(number: string, password: string): Observable<responseModel> {
        console.log('[api] -> Post update password');
        const body = {
            password: password
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/v2/ddi/${number}/resetpassword${this.code}`, body, 'response', true);
    }

    public syncFromPBX(number: string): Observable<responseModel> {
        console.log("[[api]] -> sync from pbx password");
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/ddi/${number}/sync` + this.code, 'response', true);
    }

    public deleteExtensionSettings(number: string): Observable<responseModel> {
        console.log("[[api]] -> delete extension settings on pbx");
        return this.httpProxy.delete(this.ApiBaseUrl + `/v2/ddi/${number}/deleteExtension${this.code}`, 'response', true);
    }

    public getPBXList(): Observable<HttpResponse<PBXHostListModel[]>> {
        console.log("[[api]] -> get pbx host list");
        return this.httpProxy.get(this.ApiBaseUrl + '/v2/pbx/list/' + this.code, 'response', true);
    }

    public getServicePlanList(pbxid: string): Observable<HttpResponse<PBXServicePlan[]>> {
        console.log("[[api]] -> get pbx service plan list");
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/pbx/${pbxid}/serviceplans` + this.code, 'response', true);
    }

    public getTenantByPBXList(pbxid: string): Observable<HttpResponse<TenantListModel[]>> {
        console.log("[[api]] -> get tenant by host pbx id");
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/pbx/${pbxid}/tenants` + this.code, 'response', true);
    }


}
