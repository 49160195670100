//core
import { Component, OnInit, Inject, isDevMode, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
// import { Router } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { InvoiceService  } from '@app/services/pipcall/invoice.service';
import { finalize } from 'rxjs/operators';



@Component({
    templateUrl: './restore-deleted-invoice-modal.component.html',
    styleUrls: ['./restore-deleted-invoice-modal.component.scss'],
    animations: []
})
export class RestoreDeletedInvoiceModalComponent {

    public updateNotesForm: FormGroup;
    public organisation_name: string;

    public pageStatus = {
        isSuccess: false,
        isSubmitting: false,

    }


    constructor(
        private dialogRef: MatDialogRef<RestoreDeletedInvoiceModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
        private appInsightService: AppInsightService,
        private _formBuilder: FormBuilder,
        private invoiceService: InvoiceService,
        private snackBar: MatSnackBar) {

        this.updateNotesForm = this._formBuilder.group({
            id: ['', Validators.required],
            notes: ['', Validators.required],
        });

        this.organisation_name = this.data?.organisation_name;
    }


    ngOnInit() {

    }


    openSnackBar(message: string, action: string) {
        const snackBarRef = this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    submitForm(f: FormGroup) {
        console.log('submit restore invoice', f);

        if (!f.valid) {return; }

        this.pageStatus.isSubmitting = true;
        this.invoiceService.postRestoreInvoice(f.value.id, f.value.notes)
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false; }),
            )
            .subscribe((resp) => {
                console.log(resp);
                this.pageStatus.isSuccess = true;
                this.openSnackBar('The invoice has been restored', 'Dismiss');
                this.appInsightService.logEvent('Invoice restored', { 'id': f.value.id, 'note': f.value.note });
                this.closeModal();
            }, (err) => {
                console.log(err)
                if ( err.status === 404 ) { this.openSnackBar(`An invoice with that ID was not found [${err.status}]`, "dismiss");
                } else { this.openSnackBar(`An error has occured [${err.status}]`, "dismiss"); }

            });
    }

    closeModal() {
        this.dialogRef.close(this.pageStatus.isSuccess);
    }

}
