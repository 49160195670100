import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { OrganisationModel } from '@app/models/organisation.model';
import { UserProfile } from '@app/models/user-profile.model';
import { AuthService } from '@app/services/auth-service/auth.service';
import { UserService } from '@app/services/pipcall/user.service';


// https://stackblitz.com/edit/angular-data-sharing-example-dsxnnc?file=app%2FdataService.ts
@Injectable()
export class UserDetailsService {

    //details this service will hold. Combine into single model

    //user preferences
    //ViewAs default for user
    //Darkmode
    //default Org

    //user details
    //is user sys Admin
    //Users Org details
    //User Admin access on org

    //set expiry/time fetched to fetch data again 10mins

    //class to update all data


    private viewAs = new BehaviorSubject<string>('user');
    private defaultOrg = new BehaviorSubject<OrganisationModel>(null);
    private activeOrg = new BehaviorSubject<OrganisationModel>(null);
    private orgList = new BehaviorSubject<Array<OrganisationModel>>(null);

    private userId: string;
    viewAsObservable = this.viewAs.asObservable();
    activeOrgAsObservable = this.activeOrg.asObservable();
    orgListAsObservable = this.orgList.asObservable();


    constructor(
        private authService: AuthService, private userService: UserService) {
    //template view settings
    }

    //organisations
    innit() {


    }

}
