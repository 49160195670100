import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { MdePopoverModule } from '@material-extended/mde';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

//component
import { OrgTabGetStartedComponent } from './org-get-started.component'

//modules
import { OnboardUserInvitationsModule} from './user-invitations/user-invitations.module';
import { OnboardBillingDetailsModule } from './billing-details/billing-details.module';
import { OnboardOrgDetailsModule } from './organisation-details/organisation-details.module';
import { GA4Service } from '@app/services/google-analytics-service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule,
        OnboardUserInvitationsModule,
        OnboardBillingDetailsModule,
        OnboardOrgDetailsModule
    ],
    declarations: [OrgTabGetStartedComponent],
    exports: [OrgTabGetStartedComponent],
    providers: [AppInsightService, GA4Service, OrganisationService ],
    entryComponents: []
})
export class OrgTabGetStartedModule { }
