<form [formGroup]='itemForm'>
  
  <div class="search-wrapper text-center" >

    <mat-form-field appearance="outline" [ngClass]="{'simple-input-v2': simpleLayout}" floatLabel='always' class="custom-form-field-v3">

      <input   matInput
      [placeholder]=placeholder
      aria-label="search"
      [matAutocomplete]="autoComplete"
      formControlName="userInputCtrl"
      [formControl]="userInputCtrl"
      #userInput   maxlength="150" tabindex="1"
      [disabled]="disabled">
  
      <mat-label *ngIf="!simpleLayout">User</mat-label>
      <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selected($event)">

        <mat-option [disabled]=true *ngIf="isLoading" class="is-loading"><span>searching..</span></mat-option>

        <ng-container *ngIf="!isLoading">

          <mat-option *ngFor="let item of dataSource.filteredData" [value]="item.first_name + ' ' + item.last_name"  [id]="item?.id">


              <span>{{item.first_name}}</span>&nbsp;<span>{{item.last_name}}</span>

            </mat-option>
        </ng-container>

        <!-- <mat-option *ngFor="let item of dataSource.filteredData" [value]="item?.first_name"  [id]="item?.id">
          <span>{{item.first_name}}</span>&nbsp;<span>{{item.last_name}}</span>
        </mat-option>
         -->
        
      </mat-autocomplete>

      <button *ngIf="userInput.value !==''" matSuffix tabindex="-1"  mat-icon-button
      aria-label="clear" title="clear"
      (click)="clearValues()">
      <mat-icon svgIcon="delete"></mat-icon></button>      
    </mat-form-field>

  </div>

</form>




