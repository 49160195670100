import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';

//component
import { InvoiceViewComponent } from './invoice-view.component'


import { InvoiceService, PdfResponse } from '@app/services/pipcall/invoice.service';

// modules
import { MdePopoverModule } from '@material-extended/mde';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule,
        GravatarDirectiveModule,
        modalServiceModule,
    ],
    declarations: [InvoiceViewComponent],
    exports: [InvoiceViewComponent],
    providers: [AppInsightService, InvoiceService],
    entryComponents: []
})
export class InvoiceViewModule { }
