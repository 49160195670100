<ng-container *ngIf="licence; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">


      <div class="section-wrapper">

        <div class="section-title" fxLayoutAlign="space-between start">

          <div fxLayout="row" fxLayoutAlign="start center">Licence
          </div>

        </div>

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex.gt-sm="35%" style="overflow: hidden;">

              <div class="container-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px"
                fxLayoutGap.lt-md="10px">

                <div fxFlex="74px">
                  <div fxLayoutAlign="center center" class="s74 item-container-circle activeorg">
                    <div style="height:24px;">
                      <mat-icon class="icon-size-24" svgIcon="business"></mat-icon>
                    </div>
                  </div>

                </div>

                <div fxLayout="column" fxFlex="250px">
                  <ng-container>
                    <div><strong>Organisation</strong></div>
                    <div>{{organisationProfile?.name}}</div>
                    <div [matTooltip]="organisationProfile?.state" fxLayout="row" fxLayoutAlign="start center"
                      *ngIf="organisationProfile?.state"><span
                        class="overflow-ellipsis">Status:&nbsp;{{organisationProfile?.state}}&nbsp;</span>

                      <div [hidden]="organisationProfile?.state !== 'Active'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
                      </div>

                      <div [hidden]="organisationProfile?.state!== 'Trial'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
                      </div>

                      <div [hidden]="organisationProfile?.state !== 'In Arrears'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
                      </div>

                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="vertical-line-divider-container">
                <div class="vertical-line-divider"></div>
              </div>


              <div class="container-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px"
                fxLayoutGap.lt-md="10px">

                <div fxFlex="74px" style="height:74px;">

                  <ng-container
                    *ngIf="(licence?.licensed_user_type === 'User' || licence?.licensed_user_type === 'Invitation'); else nouser">
                    <div class="usr-avatar">
                      <img class="s74" appGravatar [firstname]="licence?.first_name" [lastname]="licence?.last_name"
                        [email]="licence?.email">
                    </div>
                  </ng-container>

                  <ng-template #nouser>

                    <div fxLayoutAlign="center center" class="s74 item-container-circle">
                      <div>User</div>
                    </div>
                  </ng-template>

                </div>

                <div fxLayout="column" fxFlex="250px">
                  <ng-container>
                    <div><strong>User</strong></div>
                    <div [matTooltip]="number?.invitation_email" fxLayout="row" fxLayoutAlign="start center"
                      *ngIf="number?.invitation_email">
                      <mat-icon svgIcon="email" style="margin-right: 10px;"></mat-icon><span
                        class="overflow-ellipsis">{{number?.invitation_email}}</span>
                    </div>
                    <div *ngIf="licence?.first_name" [matTooltip]="licence?.first_name + ' ' + licence?.last_name"
                      class="overflow-ellipsis">{{licence?.first_name}} {{licence?.last_name}}</div>
                    <div [matTooltip]="licence?.email" class="overflow-ellipsis">{{licence?.email}}</div>
                  </ng-container>
                </div>

              </div>


              <ng-container
                *ngIf="licence?.licensed_user_type === 'User' && userProfile && userProfile?.organisation_list[0]?.ddi_list?.length > 0 && userProfile?.organisation_list[0]?.ddi_list[0]?.number != null; else noNumbers">


                <div *ngFor="let nm of userProfile?.organisation_list[0]?.ddi_list">

                  <div class="vertical-line-divider-container">
                    <div class="vertical-line-divider"></div>
                  </div>

                  <div class="container-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px"
                    fxLayoutGap.lt-md="10px">


                    <div fxFlex="74px">
                      <div fxLayoutAlign="center center" class="s74 item-container-circle activenumber">
                        <div style="height:24px;">
                          <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                            <mat-icon class="icon-size-24" svgIcon="phone"></mat-icon>
                          </ng-container>

                          <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                            <mat-icon class="icon-size-24" svgIcon="connect"></mat-icon>
                          </ng-container>

                        </div>
                      </div>
                    </div>

                    <div fxLayout="column" fxFlex="250px">

                      <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                        <div><strong>Number</strong></div>
                        <div>{{nm?.number | normalizeToInternational}}</div>
                      </ng-container>

                      <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                        <div><strong>Extension</strong></div>
                        <div>{{nm?.sip_extension_cli | normalizeToInternational}} ({{nm?.sip_extension_number}})</div>
                      </ng-container>
                    </div>
                  </div>


                </div>

              </ng-container>

              <ng-template #noNumbers>

                <div class="vertical-line-divider-container">
                  <div class="vertical-line-divider"></div>
                </div>

                <div class="container-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px"
                  fxLayoutGap.lt-md="10px">
                  <div fxFlex="74px">

                    <div fxLayoutAlign="center center" class="item-container-circle s74">
                      <div style="height:24px;">
                        <mat-icon style="color: var(--c-dividing-lines)" class="icon-size-24" svgIcon="phone">
                        </mat-icon>
                      </div>
                    </div>


                  </div>

                  <div fxLayout="column" fxFlex="250px">
                    <ng-container>
                      <div>
                        <span *ngIf="licence?.licensed_user_type === 'User'">There are no numbers linked to this
                          user</span>
                        <span *ngIf="licence?.licensed_user_type === 'Invitation'">Number pending acceptance of
                          invitation</span>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </ng-template>

            </div>


            <div fxLayout="column" fxFlex.gt-sm="65%">
              <s-grid-items-container>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Name</div>
                  <div role="value">{{licence?.license_name}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Contract</div>
                  <div role="value">{{licence?.license_contract_name}} {{licence?.license_version_number}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Licensed At</div>
                  <div role="value">{{licence?.licensed_at | date: 'dd/MM/yyyy HH:mm'}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Renew Date</div>
                  <div role="value">{{licence?.next_renewal_date | date: 'dd/MM/yyyy HH:mm'}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Auto Renew</div>
                  <div role="value">{{licence?.allow_renewal}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Price</div>
                  <div role="value"> {{licence?.license_price/100 | currency: 'GBP': 'symbol': '1.2-2'}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Payment Status</div>
                  <div role="value">
                    <span *ngIf="licence?.is_paid" style="color: var(--c-accept)">Paid</span>
                    <span *ngIf="!licence?.is_paid" style="color: var(--c-error)">Payment due</span>
                  </div>
                </s-grid-item>

              </s-grid-items-container>
            </div>

          </div>

        </div>

      </div>

    </div>

    <div class="section-divider"></div>

    <div class="section-wrapper" *ngIf="viewAs === 'sysAdmin' || viewAs === 'isAdmin'">
      <div class="section-title" fxLayoutAlign="space-between start">

        <div fxLayout="row" fxLayoutAlign="start center">Renewal
        </div>

      </div>

      <div class="section-content">

        <div fxLayout="row" fxFlex="60%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
          If you would like to cancel the renewal of this licence, please contact&nbsp;<a
            href="mailto:support@pipcall.com" target="_blank">PiPcall Support</a>.
        </div>



      </div>
    </div>

    <!-- <div class="section-divider"></div>

      <div class="section-wrapper" *ngIf="viewAs === 'sysAdmin' || viewAs === 'isAdmin'">
        <div class="section-title" fxLayoutAlign="space-between start">

          <div fxLayout="row" fxLayoutAlign="start center">Upgrade
          </div>

        </div>

        <div class="section-content">

          <div fxLayout="row" fxFlex="60%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <ng-container *ngIf="isUpgradeValid && licence?.licensed_user_type !== 'Invitation';else noUpgrade">

              <div  fxAlign="start">

                <div >
                  <app-license-select [mode]="'edit'" [_existingLicense]="licence" fxFlex="auto"
                    [isTrialist]="organisationProfile?.state === 'Trial'" (_selectedLicense)="licenseSelected($event)"
                    [_selectedContractType]="licence?.license_contract_type">
                  </app-license-select>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-evenly center">

                  <button style="margin-top:10px; height: 40px;" fxFlex="100" (click)="upgradeLicenseSubmit(this.licenseUpgrade)" mat-flat-button color="accent" [disabled]="!licenseUpgrade.valid">
                    <ng-container *ngIf="!pageStatus.isSubmitting;else loadingSpinner">Upgrade licence</ng-container>
                  </button>
                </div>

              </div>

            </ng-container>
            <ng-template #noUpgrade>
              <div>
                <i *ngIf="licence?.licensed_user_type === 'Invitation'">Invitation must be accepted before making
                  any changes to this licence</i>
                <i *ngIf="licence?.licensed_user_type !== 'Invitation'">There are no available upgrades on this
                  licence</i>
              </div>
            </ng-template>


          </div>

          <div fxLayout="row" fxFlex="40%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px" style="padding-top: 28px;">
            <span *ngIf="isUpgradeValid && licence?.licensed_user_type !== 'Invitation'">
              An Upgrade is available on your licence. Any cost differences will be reflected in an invoice and will be sent to you overnight. <a
              (click)="openPaymentTerms()" href="javascript:void(0)">Read more about available upgrades</a>
            </span>
              
          </div>
        </div>
      </div> -->


  


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>