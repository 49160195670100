import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, FormControl, Validators } from '@angular/forms';

// for help : https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/#_custom_model_form_validator
@Directive({
    selector: '[validEmail]',
    providers: [
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        { provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }
    ]
})
export class EmailValidator implements Validator {

    //to do , allow + in email

    static validateEmail(control: FormControl): ValidationErrors | null {
        const emailRegex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/


        //ultimately i WANT TO CHECK EMAIL AGAINST OUR DATABASE.
        //check type is valid email
        if (control.value) {
            if (!emailRegex.test(control.value)) {
                return {type: "Not a valid email"}
            } else if (control.value && control.value.length < 4 ) {
                return {length: 'Email is too short' };
            }

        }
        return null;
        // If no error, return null

    }
    validate(email: FormControl): ValidationErrors | null {
        return EmailValidator.validateEmail(email);
    }
}

