<!-- <ng-container *ngIf="touched"> -->
    
    <ng-container [ngSwitch]="iconStatus">
        
      <ng-container *ngSwitchCase="'VALID'">
       <span><mat-icon class="blue-check success" [ngClass]="{'hasPrefix':hasPrefix}">check_circle</mat-icon></span>
        <!-- <span><i class="fas fa-check success"></i></span> -->
      </ng-container>
    
      <ng-container *ngSwitchCase="'INVALID'">
        <span><i [ngClass]="{'hasPrefix':hasPrefix}" class="fas fa-exclamation-triangle warning"></i></span>
      </ng-container>
    
      <ng-container *ngSwitchCase="'PENDING'">
            <span><i [ngClass]="{'hasPrefix':hasPrefix}" class="fas fa-spinner fa-spin pending"></i></span>
      </ng-container>
    
      <ng-container *ngSwitchCase="'ERROR'">
            <span><i [ngClass]="{'hasPrefix':hasPrefix}" class="far fa-times-circle error"></i></span>
      </ng-container>
    
    </ng-container>

<!-- </ng-container>  -->