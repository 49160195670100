import {Injectable} from '@angular/core';

@Injectable()
export class BaseUrl {
  public url: string;

  constructor() {
    //http://localhost:4200  etc
    this.url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
  }
}
