//core
import { Component, OnInit, Input, EventEmitter, ElementRef, Output, ViewChild , ChangeDetectorRef, SimpleChanges} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';

import { Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from "rxjs";

import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { DDIBottomSheetComponent } from '@app/components/bottom-sheet-modal/ddi-bsheet-modal/ddi-bsheet-modal.component';


//<cto-number [organisation_id]="row.organisation_id"  [pbx_platform_id]="pbx_platform_id" [viewAs]="viewAs" [is_blocked]="row?.is_blocked" [number_id]="number_id"  (triggerUpdate)="triggerUpdate()"></cto-number>
@Component({
    selector: 'cto-extension',
    templateUrl: './cto-extension.component.html',
    styleUrls: ['./cto-extension.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class ExtensionClickComponent {
    @Input() organisation_id: string;
    @Input() number_id: string;
    @Input() pbx_platform_id: string;
    @Input() viewAs: string;
    @Input() is_blocked: boolean;
    @Input() is_extensionless: boolean;


    @Output()triggerUpdate = new EventEmitter();


    _destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet
    ) {

    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

    }



    openExtensionProfile(number_id: string, viewAs: string, organisation_id: string) {
        console.log("ROW DATA FOR OPEN extension:", number_id, viewAs, organisation_id);

        const data = {number: null, viewAs: this.viewAs, organisation_id: organisation_id, number_id: number_id}

        const dialogRef = this.bottomSheet.open(DDIBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF BS", resp);
            if (resp) {
                this.emitValue(resp);
            }
            // alert(resp)
        });
    }

    emitValue(update: boolean) {
        console.log("EMIT VALUE");
        this.triggerUpdate.emit(update);
    }


    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }
}
