//core
import { Component, OnInit, Inject, isDevMode, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import {VersionCheckService} from '@app/services/version-check.service';
import { HttpClient } from '@angular/common/http';
import { SearchFilterSingle } from '../table-toolbar.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserDataService, SaveQueryForm, SavedQuery } from '@app/services/pipcall/user-data.service';
import { AvailableColumns , SearchSettings } from '../table-toolbar.component';


@Component({
    templateUrl: './save-query-modal.component.html',
    styleUrls: ['./save-query-modal.component.scss'],
    animations: [
    ]
})
export class SaveQueryModalComponent implements OnInit {

    // public organisationName: string;

    public isDisabled = false;
    public queryForm: FormGroup;

    public settings = {}

    constructor(
        private dialogRef: MatDialogRef<SaveQueryModalComponent>,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA)
        public data: {query: SearchFilterSingle[], user_id: string, organisation_id: string, table_id: string, settings: SearchSettings, version: string }, //contains data injected into modal.
        private userDataService: UserDataService
    ) {
        // dialogRef.disableClose = true;
        console.log("DATA: ", data)

        this.queryForm = this._formBuilder.group({
            table_id: ['', [Validators.required]],
            title: ['', [Validators.required, Validators.maxLength(34)]],
            description: ['', [Validators.maxLength(82)]],
            query: ['', Validators.required],
            user_id: ['', Validators.required],
            organisation_id: ['', Validators.required],
            is_shared: [false],
            is_protected: [false],
            settings: [''],
            version: ['']
        });
    }


    ngOnInit() {
        this.queryForm.controls.organisation_id.patchValue(this.data?.organisation_id);
        this.queryForm.controls.user_id.patchValue(this.data?.user_id);
        this.queryForm.controls.table_id.patchValue(this.data?.table_id);
        if (this.data?.settings && this.data?.settings.filteredColumns !== null) {
            const _settings = encodeURIComponent(JSON.stringify(this.data.settings));
            this.queryForm.controls.settings.patchValue(_settings);
        }
        this.queryForm.controls.table_id.patchValue(this.data?.table_id);
        this.queryForm.controls.version.patchValue(this.data?.version);
        //convert query to JSON string and then uri encode it.
        this.queryForm.controls.query.patchValue(encodeURIComponent(JSON.stringify(this.data?.query)));
        // console.group("QUERY: encodeURIComponent",  this.queryForm.controls.query.value);
        console.log("PATCH SETTINGS:", this.queryForm.controls.settings.value);
    }


    public saveQuery(f: FormGroup) {
        console.log("[save-query-modal] saveQuery() ", f.value, f);
        if (f.valid) {
            //call API here
            const saveQueryForm: SaveQueryForm = f.value;
            console.log(f.value);
            this.userDataService.postSavedQuery(saveQueryForm).subscribe((res) => {
                console.log("[save-query-modal].saveQuery() resp: ", res);
                this.openSnackBar("Saved", "OK");
                this.closeModal();
            }, (err) => {
                console.log("[save-query-modal].saveQuery() err: ", err);
                this.openSnackBar("Error Saving Query", "OK");
            });
        }

    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    closeModal() {
        this.dialogRef.close(true);
    }
}
