import { Injectable } from '@angular/core';
import { HttpProxy } from '@app/services/http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '@app/models/response.model';

export class ResetPasswordRequest {
    user_password_request_id: string
    first_name: string
    last_name: string
    email: string
}

@Injectable()
export class LoginService {


    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`
    public options;

    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    public postResetPassword(email: string): Observable<responseModel> {
        console.log("[[api]] -> reset password request");
        const body = {
            email: email
        }
        return this.httpProxy.post(this.ApiBaseUrl + '/users/password_requests' + this.code, body, 'response', false)
    }

    public posVerifytResetPassword(id: string, code: string, password: string): Observable<responseModel> {
        console.log("[[api]] -> verify password reset");
        const body = {
            code: code,
            password: password
        }

        return this.httpProxy.post(this.ApiBaseUrl + `/users/password_requests/${id}/validcode` + this.code, body, 'response', false)
    }

    public postLogin(email: string, password: string): Observable<responseModel> {
        console.log("[[api]] -> email password login");
        //obfuscate password here
        const body = {
            email: email,
            password: password
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/users/login` + this.code, body, 'response', false)
    }
}

