<ng-container [ngSwitch]="version">
  <ng-container *ngSwitchCase="1">
  
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="height: 38px;" class="hover-to-show clickable">

      <div class="avatar-container av-size-30" *ngIf="invitation_email">
        <div class="avatar-icon-background" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon style="fill: white;height: 22px;width: 22px;" svgIcon="email"></mat-icon>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start" >

        <div  style="height: 22px;">

          <span matTooltip="{{invitation_email}}" class="overflow-elipsis max-width-name">{{invitation_email}}</span>

        </div>

        <div *ngIf="invitation_id" style="height: 22px;line-height:1.3em;">
          <span class="max-width-name overflow-elipsis" style="opacity: 0.6;font-size:0.9em;max-width: 230px;">Invitation pending...</span>
        </div>
      </div>

      <mat-icon matSuffix svgIcon="link" (click)="copyInviteURL(invitation_id)" class="clickable action-icon"
      matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy invitation url">
    </mat-icon>
    </div>
  
  </ng-container>

  <ng-container *ngSwitchDefault>

    <div fxLayout="row" fxLayoutAlign="start center" class="hover-to-show-copy">

      <div class="avatar-container av-size-38" *ngIf="invitation_email">
        <div class="avatar-icon-background" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon style="fill: white;height: 22px;width: 22px;" svgIcon="email"></mat-icon>
        </div>
      </div>


      <div fxLayout="column" style="padding-left:12px;">

        <div style="color: var(--c-url); white-space: nowrap;">

          <span class="overflow-elipsis max-width-name" [matTooltip]="invitation_email">{{invitation_email}}</span>

          <mat-icon matSuffix svgIcon="copy" (click)="copyInviteURL(invitation_id)" class="clickable copy-icon"
            matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy invitation url">
          </mat-icon>

        </div>
        <div>

          <div *ngIf="invitation_id">
            <span style="max-width: 230px;" class="max-width-name overflow-elipsis"
              style="opacity: 0.6;font-size:0.9em;">Invitation pending...</span>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
</ng-container>