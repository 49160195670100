<div class="bottom-sheet-content-container">

  <div class="bottom-sheet-action-row">
    
    <div class="close-button-wrapper">

      <ng-container *ngIf="viewAs==='sysAdmin'">
        <div class="go-url clickable" fxLayout="row" fxLayoutAlign="center center" >
          <span [matTooltip]="itemUrl"  (click)="copyInputToClipboard(itemUrl)">{{itemUrl}}</span>
            <button matTooltip="open url" mat-flat-button class="open-in-button" mat-button (click)="openProfileURL(number_id)"><mat-icon style="color:var(--c-title)"svgIcon="open_in_new"></mat-icon></button>
        </div>  
      </ng-container>
      
      <button class="bs-toolbar-close-btn" mat-flat-button color="primary" (click)="dismiss()" mat-button>Close</button>
    </div>
  </div>


  <div class="page-container">
    <div class="m-content-container bottom-sheet-container">

      
      <ng-container *ngIf="number?.pip_variant === 'pipxt'">
        <div class="content-page-title-wrapper">
          <h1>Extension</h1>
          <h2>
            <ng-container *ngIf="number?.sip_extension_cli;else nocli">{{number?.sip_extension_cli | normalizeToInternational}}</ng-container>
            <ng-template #nocli>No SIP cli registered for this extension</ng-template>
            <span *ngIf="number?.sip_extension_number">&nbsp;({{number?.sip_extension_number}})</span>
          </h2>
        </div>
      </ng-container>

      <ng-container *ngIf="number?.pip_variant === 'pipmobile'">
        <div class="content-page-title-wrapper">
          <h1>Number</h1>
          <h2>{{number?.number | normalizeToInternational}}</h2>
        </div>
      </ng-container>

      <div class="content-body">


        <div fxFlex="100%">
          <div class="tabs-header no-overflow"  *ngIf="viewAs === 'sysAdmin'">


            <div *ngIf="viewAs === 'sysAdmin'" [ngClass]="{'active':  pageStatus.activeTab ==='system' }"
              (click)="pageStatus.activeTab = 'system'">System</div>

            <div [ngClass]="{'active':  pageStatus.activeTab ==='overview' }"
              (click)="pageStatus.activeTab = 'overview'">Overview</div>


            <matmenu-ddi-admin-controls *ngIf="viewAs==='sysAdmin'" [pip_variant]="number?.pip_variant"[service_id]="number_id" [isLegacy]="!number?.pbx_tenant_id || !number?.pbx_id" (_refreshData)="refreshData()" (closeModal)="dismiss()"></matmenu-ddi-admin-controls>

          </div>


          <div class="m-content-body-wrapper">

            <ng-container *ngIf="!this.pageStatus.isLoading && !this.pageStatus.noContent; else loading">

              <ng-container *ngIf="pageStatus.activeTab === 'overview'">

                <tab-ddi-overview [number]="number ? number : null" [number_id]="number_id" [viewAs]="viewAs"
                  [availableUserList]="availableUserList" (updateParent)="refreshData($event)"></tab-ddi-overview>

              </ng-container>


              <ng-container *ngIf="pageStatus.activeTab === 'system'">
                <tab-ddi-system-system *ngIf="viewAs === 'sysAdmin'" [organisation_id]="organisation_id ? organisation_id : null"
                  [number]="number ? number : null" [number_id]="number_id" [viewAs]="viewAs" [availableUserList]="availableUserList"
                  (updateParent)="refreshData($event)"
                  (closeParentModal)="closeParentModal($event)"></tab-ddi-system-system>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!this.pageStatus.isLoading && this.pageStatus.noContent">
              No content found..
            </ng-container>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div fxLayoutAlign="center center">
    <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </div>
</ng-template>


