import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//requirements
import { CommonModule } from '@angular/common';
import { FirstPaymentModal } from './first-payment-modal.component';
import { FlexLayoutModule } from '@angular/flex-layout'

//others
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { UserListComponent } from './user-list/user-list.component';

import { CurrencyPipe } from '@angular/common'
//services
import { PaymentsService } from '@app/services/pipcall/payments.service';

// import { NgxStripeModule } from 'ngx-stripe';
// import { environment} from '../../../../environments/environment';
// import { StripePaymentComponent} from './stripe-payment/stripe-payment.component';
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';

import { LicenseSelectModule } from '@app/components/license-select/license-select.module';
import { StripePaymentModalModule } from '@app/components/stripe-payment/stripe-payment.module';
import { PricingModalModule } from '@app/components/modals/pricing-modal/pricing-modal.module';
import { PaymentTermsModalComponentModule } from '@app/components/modals/payment-terms-modal/payment-terms-modal.module';
import { TermsAndConditionsModalModule } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.module'
import { AppInsightService } from '@app/services/helpers/app-insights.service';


import { SelectPackageModalModule} from '@app/components/modals/select-package-modal/select-package-modal.module';
import { GA4Service } from "@app/services/google-analytics-service";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        StatusIconsModule,
        FlexLayoutModule,
        GravatarDirectiveModule,
        modalServiceModule,
        LicenseSelectModule,
        StripePaymentModalModule,
        PricingModalModule,
        TermsAndConditionsModalModule,
        PaymentTermsModalComponentModule,
        SelectPackageModalModule
    ],
    declarations: [FirstPaymentModal, UserListComponent],
    exports: [FirstPaymentModal],
    providers: [HttpProxy, PaymentsService, CurrencyPipe, GA4Service, AppInsightService],
    entryComponents: [FirstPaymentModal, UserListComponent]
})
export class FirstPaymentModalModule { }
