
//core requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//modules - core
import { GravatarDirectiveModule} from '@app/directives/gravatar/gravatar.module';
import { MaterialModule } from '@app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomPipesModule } from '@app/pipes/pipes.module';
//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';

//declare components
import { TemplateRightMenu } from './contents-template.component';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        GravatarDirectiveModule,
        CustomPipesModule
    ],
    declarations: [TemplateRightMenu],
    exports: [TemplateRightMenu],
    providers: [HttpProxy, AppInsightService],
    entryComponents: []
})
export class RightMenuContentsTemplateModule { }
