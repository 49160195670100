<ng-container *ngIf="organisation_id; else notFound">

  <!-- <ng-container *ngIf="pageStatus.isLoading">
    <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
      <div style="font-size: 4px;" class="loader-spinner"></div>loading...
    </div>
  </ng-container> -->

  <ng-container [hidden]="pageStatus?.isLoading">

    <div *ngIf="noContent  === true">Error getting content</div>

    <div [@fadeInOnEnter] class="tab-child-view-container" *ngIf="noContent  === false">

      <div *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled'"
        class="section-info-bar" fxLayoutAlign="space-between center" fxLayout="row"
        style="background:var(--c-error);color:white;height: 50px;">Account is closed</div>
      <div *ngIf="organisationProfile?.state === 'Declined'" class="section-info-bar"
        fxLayoutAlign="space-between center" fxLayout="row" style="background:var(--c-warn);color:white;height: 50px;">
        Trial has now ended</div>


      <ng-container *ngIf="organisationProfile?.state === 'Trial' ">

        <div class="section-wrapper" style='background: #00b6c0;color: white;padding-left: 30px; padding-right: 30px;'>
          <div class="section-title" fxLayoutAlign="space-between start">

            <div style="color: white;">
              <mat-icon svgIcon="info" class="icon-size-24"></mat-icon>&nbsp;In Trial
            </div>

          </div>
          <div class="section-content">
            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

              <p fxFlexLayout="row" fxFlex="40%" fxFlex.lt-md="100%" fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="center">Your organisation is in a Trial. during this time you can invite more users
                to trial PiPcall. Use the Activate PiPcall button to select and purchase licences for your users. </p>


              <div fxLayout="column" fxFlex="20%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1">
                <!-- SHOW IF ACTIVE ORGANISATION . IF TRIAL THAN ONLY SHOW THE ACTIVATE PIPCALL OPTION-->
                <button style="width:100%;" mat-flat-button (click)="openFirstPayment()">Activate PiPcall</button>
              </div>
            </div>

          </div>
        </div>

      </ng-container>


      <ng-container *ngIf="organisationProfile?.state !== 'Trial'  ">


        <div class="section-wrapper">
          <div class="tab-title">Licences&nbsp;<span
              style="font-size: 0.8em;">({{dataSource?.filteredData.length}})</span><span class="tab-title-line"></span>
          </div>

<!-- 
          <mat-form-field appearance="outline" class="simple-input-v2" fxFlex.lt-md="100%">
            <input role="search" autocomplete="off" matInput placeholder="Search..." aria-label="search" maxlength="150"
              tabindex="1" (keyup)="applyFilter($event.target.value)">
          </mat-form-field> -->


        <div class="section-content">
          <div fxLayout="column" fxFlex="100%" [hidden]="!(dataSource?.data?.length > 0)">


            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end" fxLayoutAlign.lt-md="center"
            style="margin-bottom: 20px;">
            
            <mat-form-field appearance="outline" class="simple-input-v2" fxFlex.lt-md="100%">
              <input role="search" autocomplete="off" matInput placeholder="Search..." aria-label="search" maxlength="150"
                tabindex="1" (keyup)="applyFilter($event.target.value)">
            </mat-form-field>
          </div>

            <table mat-table class="table tab-table-basic clean-spaced clean-add-row-border" [dataSource]="dataSource"
              matSort
              [ngClass]="{'disable-actions': viewAs !== 'sysAdmin' && (organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled' || organisationProfile?.state === 'Declined')}">

              <ng-container matColumnDef="licensed_user_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width:40px;"> Type </th>
                <td mat-cell *matCellDef="let data" style="text-align: center;">

                  <mat-icon *ngIf="data.licensed_user_type==='Invitation'" title="Invitation"
                    svgIcon="email"></mat-icon>

                  <mat-icon *ngIf="data.licensed_user_type==='User'" title="pipuser" svgIcon="user"></mat-icon>

                </td>
              </ng-container>

              <ng-container matColumnDef="license_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Licence </th>
                <td mat-cell *matCellDef="let data" class="nowrap-col">

                  <div class="clickable" (click)="openLicence(data)" style="color: var(--c-url)">
                    <span *ngIf="!data.is_paid">*</span>
                    {{data.license_name}} <span *ngIf="viewAs==='sysAdmin'">v{{data.license_version_number}}</span>
                  </div>
                </td>
              </ng-container>


              <ng-container matColumnDef="license_contract_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract </th>
                <td mat-cell *matCellDef="let data" class="nowrap-col"> {{data.license_contract_name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="license_price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
                <td mat-cell *matCellDef="let data" class="nowrap-col">
                  {{data.license_price / 100 | currency:'GBP':'symbol':'1.2-2'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
                <td mat-cell *matCellDef="let data" class="nowrap-col">


                  <div fxLayout="row" fxLayoutAlign="start center">

                    <div class="usr-avatar-md" *ngIf="data?.licensed_user_type != 'Invitation'"
                      style="margin-right: 10px;">
                      <img appGravatar [firstname]="data.first_name" [lastname]="data.last_name" [email]="data.email">
                    </div>
                    <div fxLayout="column">
                      <div *ngIf="data?.licensed_user_type != 'Invitation'" class="clickable"
                        (click)="_openUserProfile_viaEmail(data.email)"
                        style="color: var(--c-url);padding-bottom: 4px;">{{data.first_name}} {{data.last_name}}</div>
                      <div>{{data.email}}</div>
                    </div>

                  </div>

                </td>
              </ng-container>

              <ng-container matColumnDef="allow_renewal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Auto Renew </th>
                <td mat-cell *matCellDef="let data" class="nowrap-col"> <mat-icon style="color: var(--c-accept)"
                    *ngIf="data?.allow_renewal" svgIcon="check" matTooltip="This licence will auto renew"></mat-icon>
                </td>
              </ng-container>



              <ng-container matColumnDef="licensed_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Licensed At </th>
                <td mat-cell *matCellDef="let data" class="nowrap-col"> {{data.licensed_at | date: 'dd/MM/yyyy HH:mm'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="settings">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <div fxLayout="row" fxLayoutAlign="end center">

                    <div style="margin-left: 8px;">

                      <button
                        *ngIf="organisationProfile?.state === 'Active' && (viewAs === 'isAdmin' || viewAs === 'sysAdmin')"
                        class="small-icon-btn" mat-icon-button [matMenuTriggerFor]="licenceMenu"
                        [matMenuTriggerData]="{row: row}">
                        <mat-icon svgIcon="more_vert"></mat-icon>
                      </button>

                    </div>
                  </div>
                </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayCols"
                [hidden]="dataSource.filteredData && dataSource.filteredData.length === 0"></tr>

              <tr mat-row *matRowDef="let row; columns: displayCols;">
              </tr>

            </table>



            <div class="no-data" *ngIf="dataSource.filteredData && dataSource.filteredData.length === 0">No results
              returned</div>

            <mat-paginator itemsPerPageLabel="'items'" [pageSizeOptions]="[10, 25, 50]" [showFirstLastButtons]="true"
              [hidden]="dataSource.data.length < 10">
            </mat-paginator>


          </div>

          <div fxLayout="column" fxFlex="100%" *ngIf="dataSource?.data?.length === 0">
            There are no active licences
          </div>




        </div>


        <div fxLayout="row" fxLayoutAlign="start center"
          *ngIf="showPaymentDue && dataSource?.filteredData?.length !== 0" [style.padding-left.px]="10"
          [style.color]="'grey'" style="margin-top:20px;font-size: 14px;">
          <div>* &nbsp;payment is due on this licence</div>
        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="start center" style="font-size: 14px;" [style.color]="'grey'"
          style="margin-top: 10px;">
          &nbsp;<mat-icon class="icon-size-16" svgIcon="email"></mat-icon>&nbsp; A user has been invited to use this
          licence
        </div> -->

    </div>

  </ng-container>

  </div>


</ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>



<mat-menu #licenceMenu="matMenu" class="v3-mat-menu">


  <ng-template matMenuContent let-row="row">
    <button mat-menu-item matToolTip="Edit licence" (click)="openLicence(row)">
      Edit Licence
    </button>
    <ng-container>

    </ng-container>

  </ng-template>


</mat-menu>