import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@app/material/material-essentials.module';
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service';
// import { AppComp1Component } from './demo.test.component';

//opening Modal
import { ExampleModal } from './example-modal/example-modal.component';
import {DragableExampleModalComponent} from './dragable-example-modal/dragable-example-modal.component';
import { MAT_DIALOG_DATA, MatDialog } from '@app/material/material-essentials.module';

import { TemplateRightMenu} from '@app/components/right-menu-overlay/right-menu-contents-template/contents-template.component';

//forms
import { SignupService } from '@app/services/pipcall/signup.service';
import { FormGroup, Validators, FormBuilder, FormControl, Form, AbstractControl } from '@angular/forms';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { ValidateMobileNotTaken } from '@app/validators/async-mobile.validator';
//normalise number
// import { RegionCodes, NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { AreaCodeHelper, RegionCodes} from '@app/services/helpers/number-validation-helper.service';
import { SelectPackageModalComponent} from '@app/components/modals/select-package-modal/select-package-modal.component';

// import { AuthService} from '@app/services/auth-service/auth.service';

import { TokenService } from '@app/services/token.service';
@Component({
    templateUrl: './demo.component.html',
    styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
    public loadVideos = false;

    public opendRHNav = false;
    demoForm: FormGroup;

    displayNumbers = ['00447741414141' , '0018322484490', '+447741414141', '+18322484490' ];

    areacode: RegionCodes;
    validMobile = false;
    validLandline = false;
    readonly = false;
    formState = 'normal';
    constructor(private snackBar: MatSnackBar, private sidenavRightService: SidenavRightService, public dialog: MatDialog,  private _formBuilder: FormBuilder,  private signupService: SignupService, private tokenService: TokenService ) {
        this.demoForm = this._formBuilder.group({
            mobile: ['', [Validators.required, PhonenumberValidator.validateNumber], [ValidateMobileNotTaken.createValidator(this.signupService)]],
        });
    }

    ngOnInit() {
    }

    ngAfterContentInit() {

    }


    displayDemoSnackbar() {
        const message = "This is a test snackbar notification";
        const action = "Dismiss";
        this.openSnackBar(message, action);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 300000,
        })
    }


    openExampleModal() {

        const dialogRef = this.dialog.open(ExampleModal, {
            panelClass: 'pipcall-modal-container',
            // width: '680px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                //do action on modal close
            }
        });

    }

    openPackageSelect(numberType?: string, id?: string) {
        const data = { numberType: numberType };

        const dialogRef = this.dialog.open(SelectPackageModalComponent, {
            panelClass: 'pipcall-modal-container',
            data: data
            // width: '680px',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log("result from select: ", result);
            if (result?.licence) {
                //do action on modal close

            }
        });

    }

    openDragableExampleModal() {
        const dialogRef = this.dialog.open(DragableExampleModalComponent, {
            panelClass: ['pipcall-modal-container', 'pipcall-modal-container-1080']
        });
    }

    toggleRightNav(event) {
        this.opendRHNav = event;
    // this.rightOverlay.toggleRightNav();
    }

    openRHMenu() {
        const data = { testdata: 'this is some test data' };
        this.sidenavRightService.setContextData(data);
        this.sidenavRightService.setComponetPortal(TemplateRightMenu);

    }


    returnAreaCode(number) {
        this.areacode = AreaCodeHelper.getCountryByPhoneNumber(number);
        this.validMobile = AreaCodeHelper.isValidNumber(number);
        this.validLandline = AreaCodeHelper.isValidNumber(number, true);
    }
    // openNav(){ this.rightOverlay.set(true)}

    // closeNav(){this.rightOverlay.set(false)}

    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    testit() {
        //const url = "https://www.pipcall.com/?utm_source=adwords&utm_medium=ppc&utm_term=google%20voice&utm_campaign=Brand&hsa_cam=11371359033&hsa_mt=e&hsa_ver=3&hsa_src=g&hsa_ad=473124323932&hsa_net=adwords&hsa_tgt=kwd-11791371029&hsa_acc=1418992978&hsa_grp=110913741269&hsa_kw=google%20voice&gclid=EAIaIQobChMIiM-g15jg7gIVJu_tCh10pwvSEAAYASAAEgICD_D_BwE"
        const referercookie = "utm_source%3Dadwords%26utm_medium%3Dppc%26utm_term%3Dgoogle%2520voice%26utm_campaign%3DBrand%26hsa_cam%3D11371359033%26hsa_mt%3De%26hsa_ver%3D3%26hsa_src%3Dg%26hsa_ad%3D473124323932%26hsa_net%3Dadwords%26hsa_tgt%3Dkwd-11791371029%26hsa_acc%3D1418992978%26hsa_grp%3D110913741269%26hsa_kw%3Dgoogle%2520voice%26gclid%3DEAIaIQobChMIiM-g15jg7gIVJu_tCh10pwvSEAAYASAAEgICD_D_BwE"

        const decodedQueryString = decodeURIComponent(referercookie)
        const urlParams = new URLSearchParams(decodedQueryString);

        const refererJSON = {
            referer: urlParams.get('referer'),
            medium: urlParams.get('utm_medium'),
            campaign: urlParams.get('utm_campaign'),
            source: urlParams.get('utm_source'),
            term: urlParams.get('utm_term'),
            keywords: urlParams.get('hsa_kw'),
        }
        // for (const param of urlParams {
        //     console.log(param)
        // }

        console.log(refererJSON);
    }


    refreshTokenTest() {
        this.tokenService.postRefreshToken()
            .subscribe((resp) => {
                console.log("refresh token response: ", resp)
            })

    }
    // openNav(){ this.rightOverlay.set(true)}

    // closeNav(){this.rightOverlay.set(false)}


}
