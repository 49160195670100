//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, rotateOutUpRightOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil, map } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { OrganisationDirectory, DirectoryService} from '@app/services/pipcall/directory.service';
import { Subscription } from 'rxjs';
import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { ValidateEmailNotTaken, ValidateEmailWithSMSIdentityExists } from '@app/validators/async-email.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { TitleCasePipe } from '@angular/common';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';
import { NumberService, AreaCode } from '@app/services/pipcall/number.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { VerifyMobileV3ModalComponent } from '@app/components/modals/verify-mobile-v3-modal/verify-mobile-v3-modal.component';
import { MatAccordion } from '@angular/material/expansion';
import { CookieService } from 'ngx-cookie-service';
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { InvitationProvision } from '@app/models/invitation.model';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { UserService, OwnerUpgradeForm } from '@app/services/pipcall/user.service';
import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { UserProfile } from '@app/models/user-profile.model';
import { SelectPackageModalComponent} from '@app/components/modals/select-package-modal/select-package-modal.component';
import { SelectedLicenceModel } from '@app/models/license.model';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { DdiNumber } from '@app/models/ddi.model';


@Component({
    selector: 'app-admin-upgrade-invitation-to-pipxt',
    templateUrl: './admin-upgrade-invitation-to-pipxt.component.html',
    styleUrls: ['./admin-upgrade-invitation-to-pipxt.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class AdminUpgradeInvitationToPipxtComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel;
    @Input() organisation_id: string;
    @Input() userProfile: UserProfile;

    // @Input() organisationProfile: OrganisationModel //optional ?


    @Output() updateParent = new EventEmitter();

    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        verified: false,
        hasMobile: false,
    }
    numberSubscription: Subscription;
    public inviteUserForm;

    public existingPiPmobile: DdiNumber[];
    public existingPiPxt: DdiNumber[];

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private appInsightService: AppInsightService,
        private staticDataService: StaticDataService,
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private cookieService: CookieService,
        private normalizeNumberService: NormalizeNumberService,
        private userService: UserService,
        private signupService: SignupService,
        private invitationService: InvitationService
    ) {
        this.inviteUserForm = this._formBuilder.group({
            organisation_id: [this.organisation_id, Validators.required],
            first_name: [''],
            last_name: [''],
            email: ['', [Validators.email], [ValidateEmailWithSMSIdentityExists.createValidator(this.signupService, true)]],
            preallocated_number: ['', Validators.required],
            is_org_admin: [false],
            sent: ['']
        })
    }

    // convenience getters for easy access to form fields
    get f() { return this.inviteUserForm.controls; }

    ngOnChanges(changes: SimpleChanges) {
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setFormValues(this.organisationProfile) : null;
    }


    ngOnInit() {


        if (this.userProfile?.mobile) {this.pageStatus.hasMobile = true; }

        if ( !this.organisationProfile || !this.organisation_id || !this.userProfile) {
            this.pageStatus.isError = true;
        } else {
            this.getAvailablePiPnumbers();
            this.setFormValues(this.organisationProfile);
        }

        setTimeout(() => {
            this.inviteUserForm.controls['email'].markAsTouched();
            this.inviteUserForm.controls['email'].updateValueAndValidity();
            this.ref.detectChanges();
        }   , 1500);

    }


    setFormValues(organisationProfile: OrganisationModel) {
        this.setFormValue(this.inviteUserForm, 'organisation_id', this.organisationProfile?.id);
        this.userProfile?.email ? this.setFormValue(this.inviteUserForm, 'email', this.userProfile?.email) : null;
        this.organisationProfile?.owner_user_id === this.userProfile?.id ? this.setFormValue(this.inviteUserForm, 'is_org_admin', true) : null;
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
        console.log("form", form);
        this.ref.detectChanges();
    }

    emitValue(value) {
        this.ref.detectChanges();
        console.log('this form', this.inviteUserForm);

    }

    getAvailablePiPnumbers() {
        console.log("Get available numbers");
        this.pageStatus.isLoading = true;
        this.numberSubscription =  this.organisationService.getDdiListByOrganisation(this.organisationProfile?.id)
            .pipe(
                map((value) => this.filterToAvailable(value))
            )
            .subscribe((resp) => {
                this.existingPiPmobile = resp?.pipMobile;
                this.existingPiPxt = resp?.pipXt;
                this.pageStatus.isLoading = false;
                console.log("CONTINUE this.existingPiPxt", this.existingPiPxt);
                this.ref.detectChanges();
            }
            , (err) => {
                this.pageStatus.isLoading = false;
                this.openSnackBar(`An unspecified error occured [${err.status}]`, "Dismiss");
            });

    }

    filterToAvailable(resp: any) {
        // const availableNumbers = new Array<DdiNumber>();
        const availableNumbers = {
            pipMobile: [],
            pipXt: []
        }
        if (resp.status === 200 && resp.body !== null) {
            resp.body?.ddi_list.forEach(ddiNumber => {
                if (!ddiNumber.user_id && !ddiNumber.invitation_email) {
                    if (ddiNumber?.pip_variant === 'pipmobile') {
                        availableNumbers.pipMobile.push(ddiNumber);
                    } else if (ddiNumber.pip_variant === 'pipxt') {
                        availableNumbers.pipXt.push(ddiNumber);
                    }
                }
            });
        }
        return availableNumbers;
    }

    sendInvitation() {
        if (!this.inviteUserForm.valid) {
            return this.openSnackBar('Please complete all required fields with valid inputs', 'dismiss');
        }

        let invitationCart = new InvitationProvision();  //create new invitation cart
        invitationCart = {
            organisation_id: this.f.organisation_id.value,
            email: this.f.email.value,
            allocation_option: null,
            is_org_admin: this.f.is_org_admin.value,
            cli_override: null,
            license_id: null,
            preallocated_number: this.f.preallocated_number.value,
        }

        if (this.userProfile.email === this.f.email.value) {
            invitationCart.first_name = this.userProfile.first_name;
            invitationCart.last_name = this.userProfile.last_name;
        }

        this.pageStatus.isSubmitting = true;
        this.invitationService.postNewInvite(invitationCart)
            .subscribe((resp) => {
                console.log(resp);
                this.openSnackBar('Invitation sent', 'dismiss');
                this.triggerUpdate();
            }, (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
                this.pageStatus.isSubmitting = false;
            });
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }



    debugForm(f: FormGroup) {
        console.log(f.value);
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
