//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";

import { UserProfile } from '@app/models/user-profile.model';
import { UserService } from '@app/services/pipcall/user.service';
import { UserDetailUpdate } from '@app/models/user-profile.model';

import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';

import { LicenseModel, Licensedata, ViewLicenseModel } from '@app/models/license.model';
import { PaymentsService, } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';

import {LicenseService} from '@app/services/pipcall/license.service';

import { TermsAndConditionsComponent } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { PricingModalComponent } from '@app/components/modals/pricing-modal/pricing-modal.component';
import { PaymentTermsModalComponent } from '@app/components/modals/payment-terms-modal/payment-terms-modal.component';
@Component({
    selector: 'app-licence-details-tabs-overview',
    templateUrl: './licence-details-overview.component.html',
    styleUrls: ['./licence-details-overview.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class LicenceDetailsTabOverviewComponent {


    @Input() viewAs: string;

    @Input() licence: ViewLicenseModel; //require
    @Input() organisationProfile: OrganisationModel; //required

    @Output() updateParent = new EventEmitter();
    @Output() updateParentModal = new EventEmitter();



    noContent = false;
    _destroy$ = new Subject<void>();
    public sharedDataSubscription: Subscription;
    public userProfile: UserProfile;

    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }

    public trialDays: number;


    // upgrade
    licenseUpgrade: FormGroup;
    isUpgradeValid: boolean;
    // activeLicenseList: LicenseModel[]; //list of available licenses
    selectedLicenseType: string; // 'talk' | 'talk_and_text' | 'basic_talk' | 'basic_talk_and_text';
    selectedContractType: string; //'1y_up-front' | '1m_monthly' | '1y_monthly' | 'trial';

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private userService: UserService,
        private signupService: SignupService,
        private normalizeNumberService: NormalizeNumberService,
        private ref: ChangeDetectorRef,
        private paymentsService: PaymentsService,
        private licenseDataService: LicenseDataService,
        private authService: AuthService,
        private appInsightService: AppInsightService,
        private modalService: ModalService,
        private licenseService: LicenseService
    ) {

        this.userProfile = new UserProfile()


        this.licenseUpgrade = this._formBuilder.group({
            user_id: ['', Validators.required],
            organisation_id: ['', Validators.required],
            license_id: ['', Validators.required]
        });

        // this.sharedDataSubscription = this.licenseDataService.activeLicenseListAsObservable.subscribe((activeLicenseList) => {
        //     activeLicenseList ? this.activeLicenseList = activeLicenseList : null;
        //     this.pageStatus.isLoadingLicence = false;
        // });

    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("CHANGES FIRED",  changes);
        (changes['licence']?.currentValue !== changes['licence']?.previousValue) &&  !changes['licence']?.isFirstChange() ?  this.setFormValues(this.licence) : null;
        // (changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue) &&  !changes['organisationProfile']?.isFirstChange() ?  this.ref.markForCheck() : null;
    }



    ngOnInit() {
        // this.activeLicenseList = new Array<LicenseModel>();
        //if licence exists then set form values
        if (this.licence) {
            this.setFormValues(this.licence);
            this.pageStatus.isLoading = false;
        }

    }



    setFormValues(licence: ViewLicenseModel) {
        //name form
        // this.setFormValue(this.userNameForm, 'id', userProfile.id);
        this.licenseUpgrade.controls.organisation_id.patchValue(licence?.organisation_id);
        licence?.licensed_user_type === 'User' ? this.licenseUpgrade.controls.user_id.patchValue(licence?.licensed_user_identifier) : this.licenseUpgrade.controls.user_id.patchValue(null);
        this.licenseUpgrade.controls.license_id.patchValue('');
        this.licenseUpgrade.controls.license_id.patchValue('');
        this.licenseUpgrade.controls["license_id"].updateValueAndValidity();

        this.isUpgradeValid = this.checkIsUpgradeValid(this.licence);

        if (this.licence?.licensed_user_type === 'User') {
            this.userService.getUser(this.licence?.licensed_user_identifier)
                .subscribe((resp) => {
                    this.userProfile = resp.body;
                    this.ref.markForCheck();
                })
        }
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }




    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.markForCheck()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    openPricingModal() {
        const dialogRef = this.dialog.open(PricingModalComponent, {
            panelClass: 'pipcall-modal-container',
        });
    }

    openTermsConditionsModal() {
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            panelClass: 'pipcall-modal-container',
        });
    }

    openPaymentTerms() {

        const dialogRef = this.dialog.open(PaymentTermsModalComponent, {
            hasBackdrop: false,
            panelClass: 'pipcall-modal-container',
            autoFocus: false,
            position: {
                top: '180px',
                left: '180px'
            },
        });

    }

    licenseSelected(event) {
        //output from licence select view
        console.log("EVENT: ", event);
        this.licenseUpgrade.controls.license_id.patchValue(event.id);
        this.licenseUpgrade.markAsDirty();
    }

    setTrialDate(trialEndDate) {
        console.log("TRIAL DATE:", trialEndDate);
        const today = new Date();
        // const createdAt = new Date(this.orgProfile.created_at);
        const trialEnds = new Date(trialEndDate);
        trialEnds.setHours(23, 59, 59, 59);
        const diff = trialEnds.getTime() - today.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1; //added minus 1 to adjust for first day partial.
        console.log("TRIAL ENDS: ", trialEnds >= today ? diffDays : -1)
        return trialEnds >= today ? diffDays : -1;
    }

    checkIsUpgradeValid(licence: ViewLicenseModel): boolean {

        if (licence?.licensed_user_type === "Invitation") { return false };


        const contracttype = licence?.license_id ? licence.license_contract_type : null; //e.g 1y_monthly
        const type = licence?.license_id  ? licence?.license_type  : null; //e.g talk_and_text


        if (!contracttype || !type) { return false };


        const isNotBasic = !type.includes(LicenseDataService.isBasicIdentifier);
        const isAnnualContract = contracttype === LicenseDataService.annualContract;

        console.log("RETURN LICENCE UPGRADE CHECL : isNotBasic:", isNotBasic && 'isAnnualContarct:', isAnnualContract);

        return isNotBasic && isAnnualContract ? false : true;

    }


    cancelForm() {
        this.setFormValues(this.licence);
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
        this.ref.markForCheck();
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
