//core
import { Component, OnInit, Inject, isDevMode, HostListener, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'

import { StripeService, Elements, Element as StripeElement, ElementsOptions, StripeFactoryService, StripeInstance } from "ngx-stripe";

import { FormGroup, NgForm, FormBuilder, Validators } from "@angular/forms";
import { environment } from '../../../environments/environment';
import { map, tap, filter, catchError, mergeMap, finalize } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { slideInUpOnEnterAnimation} from 'angular-animations';
import { AppInsightService} from '@app/services/helpers/app-insights.service';

export interface CheckoutResponse {
    invoice_total: number;
    stripe_client_secret: string;
}


@Component({
    selector: 'app-payment-stripe-payment',
    templateUrl: './stripe-payment.component.html',
    styleUrls: ['./stripe-payment.component.scss'],
    animations: [
        slideInUpOnEnterAnimation()
    ]
})
export class StripePaymentComponent {

    elements: Elements;
    card: StripeElement;

    stripeForm: FormGroup;

    // optional parameters
    elementsOptions: ElementsOptions = {
        locale: 'en'
    };

    @Input() checkoutResponse: CheckoutResponse;
    @Input() mobileView: boolean;
    @Output() checkoutEvent = new EventEmitter(); //boolean, true if successfull checkout
    @Output() cancelCheckout = new EventEmitter(); //cancel event emit


    public errorMessage = {
        status: false,
        message: ''
    }

    isLoading: boolean;

    constructor(
        private snackBar: MatSnackBar,
        private cd: ChangeDetectorRef,
        private stripeService: StripeService,
        private fb: FormBuilder,
        private stripeFactory: StripeFactoryService,
        private appInsightService: AppInsightService
    ) {
        this.isLoading = false;
    }


    ngOnInit() {

        this.stripeForm = this.fb.group({
            name: ['', [Validators.required]]
        });

        this.mountCard();
    }

    mountCard() {
        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                if (!this.card) {
                    this.card = this.elements.create('card', {
                        style: {
                            base: {
                                iconColor: '#808080',
                                color: '#000000',
                                lineHeight: '40px',
                                fontWeight: 500,
                                fontFamily: 'Nunito, Open Sans, Segoe UI, sans-serif',
                                fontSize: '16px',
                                '::placeholder': {
                                    color: '#808080'
                                }
                            },
                            invalid: {
                                iconColor: '#a972a5',
                                color: '#f31717',
                            }
                        }
                    });
                    this.mobileView ? this.card.mount('#card-element-mobile') : this.card.mount('#card-element');
                }
            });
    }

    pay() {
        this.appInsightService.logEvent('Checkout: Make Stripe payment');
        //deprecated for confirmCardPayment. But will continue to work. new method not available in ngx stripe yet
        const clientSecret = this.checkoutResponse.stripe_client_secret;
        this.errorMessage.status = false;
        this.errorMessage.message = '';
        this.isLoading = true;

        this.stripeService.handleCardPayment(clientSecret, this.card)
            .pipe(
                tap(() => {
                    this.isLoading = true;
                })
            )
            .subscribe((resp) => {
                if (resp.error) {
                    //show an error now
                    console.log("ERROR", resp.error['message']);
                    this.errorMessage.message = resp.error['message'];
                    this.errorMessage.status = true;
                    this.isLoading = false;
                    this.appInsightService.logEvent('Checkout: Stripe payment failed');
                } else {
                    // paymentIntent object , id , status == succeeded
                    this.appInsightService.logEvent('Checkout: Stripe payment success');
                    this.checkoutEvent.emit(true);
                    return;
                }
                return ;
            }, (err) => {
                console.log("error:", err);
                this.appInsightService.logEvent('Checkout: Stripe payment failed', {'err': err});
                this.errorMessage.message = "something went wrong with this transaction. You have not been charged."; //check api for status codes toflesh out error message
                this.errorMessage.status = true;
                this.isLoading = false;
            })

    }


    //to add
    //save card
    //update card
    //remove payment method


    ngOnDestroy() {

    }

}
