<div fxLayout="row">

  <button (click)="export()" class="button-bordered small-icon-btn"  matTooltip="export to excel" [matTooltipClass]="'tooltipcustom tooltipmultiline'" tabindex="-1"  mat-icon-button >
    <mat-icon svgIcon="download"></mat-icon>
  </button>

  <button (click)="refresh()"  class="button-bordered small-icon-btn"  matTooltip="refresh data" [matTooltipClass]="'tooltipcustom tooltipmultiline'" tabindex="-1"  mat-icon-button >
    <mat-icon [ngClass]="{'i-spin':isLoading}" svgIcon="refresh"></mat-icon>
  </button>

  <button (click)="reset()"  class="button-bordered small-icon-btn"  matTooltip="Reset form" [matTooltipClass]="'tooltipcustom tooltipmultiline'" tabindex="-1"  mat-icon-button >
    <mat-icon svgIcon="trash"></mat-icon>
  </button>



</div>
<!-- 

<ng-container *ngIf="user.organisation_list[0].id === organisationId && organisationState === 'Trial'; else notTrial">
  <button   (click)="removeUserFromOrg(user.id, user.first_name )" *ngIf="(viewAs === 'isAdmin' || viewAs === 'sysAdmin') && user.organisation_list[0].id === organisationId && user.organisation_list[0].removal_requested === false" class="button-bordered delete-btn" m matTooltip="Request to remove user" [matTooltipClass]="'tooltipcustom tooltipmultiline'" tabindex="-1"   mat-icon-button >
    <mat-icon svgIcon="trash"></mat-icon>
  </button>
</ng-container>
class="button-bordered delete-btn" -->