<h5 class="title" mat-dialog-title>{{ data.title }}</h5>



<mat-dialog-content>
<div [innerHTML]="data.content"></div>
  <!-- <div >
    {{ data.content }}
  </div> -->
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center" [ngClass]="data?.customClass ? data?.customClass : ''">
  <button mat-raised-button [mat-dialog-close]="false" *ngIf="data.choice" tabindex="-1">
    {{ data.closeButtonLabel }}
  </button>
  <button class="confirmation-button" style="color:white;" mat-raised-button color="warn" mat-dialog-close="true" [ngClass]="{'red-delete': data?.confirmButtonLabel === 'Delete' || data?.confirmButtonLabel === 'Remove' || data?.confirmButtonLabel === 'Detach' || data?.confirmButtonLabel === 'Deallocate' }" tabindex="-1">
    {{ data.confirmButtonLabel }}
  </button>
</mat-dialog-actions>