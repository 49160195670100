import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'returnFirst'})
export class ReturnFirst implements PipeTransform {
    transform(numbers: string): string {
        let firstNumber: string = "";
        firstNumber = numbers[Object.keys(numbers)[0]]
        return firstNumber;
      }
}


//return first value in object array