//core
import { Component, OnInit, Inject, isDevMode, ChangeDetectorRef, SimpleChanges, HostListener, ViewChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { map, tap, filter, catchError, mergeMap, finalize, first } from 'rxjs/operators';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'

import { CurrencyPipe } from '@angular/common'
import { PricingListItem, AddonPackageModel } from '@app/models/license.model';
import { LicenseDataService, ContractType, LicenseType } from '@app/services/shared-data.service/license-data.service';

@Component({
    selector: 'app-view-add-on-tariff',
    templateUrl: './view-add-on-tariff.component.html',
    styleUrls: ['./view-add-on-tariff.component.scss'],
    animations: [

    ]
})
export class AddOnTariffsComponent {

    @Input() onDark; //whether to adjust class for on dark background. adds on-dark class

    public pricingList: AddonPackageModel[];
    public isPricingLoaded: boolean;
    @Input() isModeSelect: boolean // whether can select item
    // public  addOnSMSType: string;
    // public  addOnBasicType: string;
    public  addOnIntType: string;

    constructor(
        private licenseDataService: LicenseDataService
    ) {
        this.licenseDataService.innit();
        this.isPricingLoaded = false;

        this.pricingList = new Array<AddonPackageModel>();
        this.licenseDataService.addOnPackageListAsObservable.subscribe((pricingList) => {
            this.pricingList = pricingList ? pricingList : null;
            this.checkContentLoaded();
        });

        // this.addOnSMSType = LicenseDataService.addOnSMSType;
        // this.addOnBasicType =  LicenseDataService.addOnBasicType;
        this.addOnIntType = LicenseDataService.addOnIntType;
    }

    ngOnInit() {

    }


    returnLicenseByType(type: string) {
        const addOnpackage = this.pricingList.find(item => item.type === type );
        return addOnpackage;
    }

    checkContentLoaded() {

        if (this.pricingList?.length > 0) {
            this.isPricingLoaded = true;
        } else {
            this.isPricingLoaded = false;
        }

    }

}
