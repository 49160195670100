//core
import { Component, Input, SimpleChanges, EventEmitter, OnChanges, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
//form essentials
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@app/material/material-essentials.module';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidateEmailNotTaken, ValidateEmailWithSMSIdentityExists } from '@app/validators/async-email.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { InvitationProvision } from '@app/models/invitation.model';
import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { NumberService, AreaCode } from '@app/services/pipcall/number.service';
import { TitleCasePipe } from '@angular/common';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService} from '@app/services/pipcall/organisation.service';
import { distinctUntilChanged, map, finalize } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { TermsAndConditionsComponent } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { PricingModalComponent} from '@app/components/modals/pricing-modal/pricing-modal.component';
import { DdiNumber } from '@app/models/ddi.model';

@Component({
    selector: 'app-invite-user-modal',
    templateUrl: './invite-user-modal.component.html',
    styleUrls: ['./invite-user-modal.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ])
    ]
})
export class InviteUserModalComponentpipxt {

    @Output() updateEmit = new EventEmitter();

    public inviteUserForm; //add invite user model

    public pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isSubmitted: false,
        isSuccess: false,
        isError: false,
        viewTab: 1
    }


    //from Data
    public organisationDetails: OrganisationModel;
    public organisation_id:  string;
    public preallocated_number: DdiNumber;
    public existingPiPmobile: DdiNumber[];
    public existingPiPxt: DdiNumber[];
    numberSubscription: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: any, //contains data injected into modal
        private dialogRef: MatDialogRef<InviteUserModalComponentpipxt>,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private invitationService: InvitationService,
        private numberService: NumberService,
        private signupService: SignupService,
        private titlecasePipe: TitleCasePipe,
        private staticDataService: StaticDataService,
        private normalizeNumberService: NormalizeNumberService,
        private organisationService: OrganisationService
    ) {
        // this.availableExistingNumbers = new Array<DdiNumber>();

        // this.organisationDetails = data?.organisationDetails;
        this.organisation_id = data?.organisation_id;
        this.preallocated_number = data?.preallocated_number;
        console.log("data?.organisation_id", data?.organisation_id)

        this.existingPiPmobile =  new Array<DdiNumber>();
        this.existingPiPxt =  new Array<DdiNumber>();

        this.inviteUserForm = this._formBuilder.group({
            organisation_id: [this.organisation_id, [Validators.required]],
            user_details: this._formBuilder.group({
                is_org_admin: [false, [Validators.required]],
                email: ['', [Validators.email], [ValidateEmailWithSMSIdentityExists.createValidator(this.signupService)]],
            }),
            preallocated_number: [null, Validators.required],
            selected_license: [{value: '', disabled: true}, Validators.required],
        });

        this.getAvailablePiPnumbers()
    }


    ngOnInit() {
        //uncomment when we figure out licensing
        // this.organisationDetails?.state === 'Trial' ? this.inviteUserForm.controls['selected_license'].disable() : this.inviteUserForm.controls['selected_license'].enable();
        this.inviteUserForm.controls.organisation_id.patchValue(this.organisation_id);
        if (this.preallocated_number) { this.inviteUserForm.controls.preallocated_number.patchValue(this.preallocated_number.number)}; //else show select option
        this.onChanges();
    }

    getAvailablePiPnumbers() {
        this.numberSubscription =  this.organisationService.getDdiListByOrganisation(this.organisation_id)
            .pipe(
                map((value) => this.filterToAvailable(value))
            )
            .subscribe((resp) => {
                this.existingPiPmobile = resp.pipMobile;
                this.existingPiPxt = resp.pipXt;

                if ( this.existingPiPxt?.length === 0) {
                    this.inviteUserForm.controls.preallocated_number.disable();
                    this.inviteUserForm.controls.user_details.disable();
                    this.inviteUserForm.disable();
                } else {
                    this.inviteUserForm.controls.preallocated_number.enable();
                }
            })
    }

    filterToAvailable(resp: any) {
        // const availableNumbers = new Array<DdiNumber>();
        const availableNumbers = {
            pipMobile: [],
            pipXt: []
        }
        if (resp.status === 200 && resp.body !== null) {
            resp.body?.ddi_list.forEach(ddiNumber => {
                if (!ddiNumber.user_id && !ddiNumber.invitation_email) {
                    if (ddiNumber.pip_variant === 'pipmobile') {
                        availableNumbers.pipMobile.push(ddiNumber);
                    } else if (ddiNumber.pip_variant === 'pipxt') {
                        availableNumbers.pipXt.push(ddiNumber);
                    }
                }
            });
        }
        return availableNumbers;
    }

    onChanges(): void {
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    closeModal() {
        this.dialogRef.close(this.pageStatus.isSuccess);
    }

    transformToTitleCase(value: string) {
        return this.titlecasePipe.transform(value);
    }


    submitForm(f: FormGroup) {
        console.log("form", f);

        if (f.invalid) { return; }

        this.pageStatus.isSubmitting = true;



        const invitationCart: InvitationProvision = new InvitationProvision();


        invitationCart.email = f.value.user_details.email;
        invitationCart.organisation_id = f.value.organisation_id;
        invitationCart.preallocated_number = f.value.preallocated_number;
        invitationCart.license_id = f.value?.selectedLicense ? f.value?.selectedLicense : null;


        this.invitationService.postNewInvite(invitationCart)
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false; })
            )
            .subscribe((resp) => {
                console.log("[invite-user-modal].submitForm() response", resp);
                if (resp.status === 200 || resp.status === 201) {
                    this.pageStatus.isSuccess = true;
                    this.updateEmit.emit(true); //this is not triggering the update in the parent component
                    this.inviteUserForm.disable();
                    this.openSnackBar("your invitation has been sent", "dismiss");
                } else {
                    throwError(resp);
                }
            }, (err) => {
                console.log(err);
            });

    }

    openPricingModal() {
        const dialogRef = this.dialog.open(PricingModalComponent, {
            panelClass: 'pipcall-modal-container',
        });
    }

    openTermsConditionsModal() {
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            panelClass: 'pipcall-modal-container',
        });
    }

    scrollEvent(event) {
        const el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop !== el.clientHeight ? el.classList.add('pip-scroll-shadow-after') : el.classList.remove('pip-scroll-shadow-after'); ;
    }


    ngOnDestroy() {
        this.numberSubscription?.unsubscribe();
    }

}
