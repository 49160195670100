<div class="cc-v-page-wrap cc-background-animated-wave">

    <div class="cc-wave"></div>
    <div class="cc-wave"></div>
    <div class="cc-wave"></div>
 
    <cc-floating-card [showCopyRight]="true" [showBrandLogo]="true">

        CONTENT WILL GO HERE
        <button class="lg-button" mat-flat-button mat-button color="accent">Sample Button</button>
    
    </cc-floating-card>
</div>