//core
import { Component, OnInit, Input, isDevMode, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';

//form essentials
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@app/material/material-essentials.module';
import { Observable, Subscription, throwError, from, } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StaticDataService, AreaCode } from '@app/services/shared-data.service/staticdata.sevice';


@Component({
    selector: 'app-ff-areacode-list-select',
    templateUrl: './ff-areacode-list-select.component.html',
    styleUrls: ['./ff-areacode-list-select.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class AreacodeListSelectComponent {

    @Output() _selectedAreacode = new EventEmitter();

    public areaCodes: Array<AreaCode>;

    public isLoading = true;
    public areaCodeSubscription: Subscription;

    constructor(
        private staticDataService: StaticDataService,
    ) {
        this.areaCodes = new Array<AreaCode>();

    }

    ngOnInit() {
        this.areaCodeSubscription = this.staticDataService.areaCodeListAsObservable
            .subscribe((resp) => {
                this.areaCodes = resp
                this.areaCodes == null ? this.setAreaCodes() : null;
                this.isLoading = false;
            }, (err) => {
                this.isLoading = false;
            });
    }


    emitValue(event) {
        this._selectedAreacode.emit(event.value);
    }

    setAreaCodes() {
        this.isLoading = true;
        this.staticDataService.setAreaCode();
    }

    ngOnDestroy() {
        this.areaCodeSubscription ? this.areaCodeSubscription.unsubscribe() : null;
    }


}
