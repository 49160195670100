import {Component, TemplateRef, Output, ViewChild, Inject, SimpleChanges, EventEmitter} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { UserProfile } from '@app/models/user-profile.model';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { LicenseService} from '@app/services/pipcall/license.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';
import {LicenseModel, ViewLicenseModel} from '@app/models/license.model' ;



@Component({
    selector: 'bs-licence-bsheet-modal',
    templateUrl: './licence-bsheet-modal.component.html',
    styleUrls: ['./licence-bsheet-modal.component.scss']
})
export class LicenceBottomSheetComponent {


    public viewAs: string; //owner,admin or user. set to owner if matches on return of data.
    public isloading = true;
    public notFound: boolean = null;

    public licence: ViewLicenseModel;
    public organisation_id: string;
    public organisationProfile: OrganisationModel;
    public licensed_user_identifier: string; //use this to return licence in absence of an id


    public pageStatus = {
        activeTab: 'overview',
        isLoading: true,
        noContent: false,
        dataChanged: false
    }

    _destroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {viewAs: string, licence: ViewLicenseModel, organisation_id: string, organisationProfile: OrganisationModel },
        private _bottomSheetRef: MatBottomSheetRef<LicenceBottomSheetComponent>,
        private ref: ChangeDetectorRef,
        private licenceService: LicenseService,
        private organisationService: OrganisationService
    ) {

        console.log("DATA:", this.data);

        this.setData();

    }

    ngOnInit() {
        //handle missing data
        // this.first_name = this.userProfile.first_name;
        // this.last_name = this.userProfile.last_name;

        this.pageStatus.isLoading = true;

        //if no licence then set pagestatus to noconteent
        if (!this.licence || !this.organisation_id) {this.pageStatus.noContent = true; this.pageStatus.isLoading = false; return; } else {

            //if no organisationProfile then get it now
            if (!this.organisationProfile) {
                this.getUserOrganisation(this.organisation_id)
                //get it now
            } else {
                this.pageStatus.isLoading = false;
            }
        }


    }
    ngOnChanges(changes: SimpleChanges) {
        console.log("BOTTOM SHEET ONCHANGE TO DATA--TESTING--- ", changes);
        (changes['data']?.currentValue !== changes['data']?.previousValue) &&  !changes['data']?.isFirstChange() ?  this.setData() : null;
    }

    setData() {
        this.viewAs = this.data.viewAs;
        this.licence = this.data?.licence;
        this.organisation_id = this.data?.organisation_id;
        this.licensed_user_identifier = this.data?.licence.licensed_user_identifier;
    }

    updateLicenceInfo(organisation_id: string, licensed_user_identifier: string) {
        console.log("FIRE updateLicenceInfo in bottomsheet parten.", licensed_user_identifier)

        this.licence = new ViewLicenseModel();

        this.pageStatus.isLoading = true;
        //list of licenced users by organisation
        setTimeout(() => {
            this.licenceService.getOrganisationLicenses(organisation_id)
                .subscribe((resp) => {
                    console.log("returned all org licenced users:", resp);
                    // search resp.body for licensed_user_identifier
                    this.licence = resp.body.find(user => user.licensed_user_identifier === licensed_user_identifier);
                    this.ref.markForCheck();
                    console.log("match with current licence user. update new licence as: ", this.licence);
                    this.pageStatus.isLoading = false;
                }
                , (err) => {
                    console.log("error updating licence informatio:", err);
                });
        }, 3000);
    }

    getLicenceData() {
        //If no licence then get licence data
        //filter to relavent licence and pass to children
    }

    getUserOrganisation(organisation_id: string) {
        this.organisationService.getOrganisationById(organisation_id)
            .subscribe((resp) => {
                this.organisationProfile = resp.body;
                this.ref.markForCheck();
                this.pageStatus.isLoading = false;
            }, (err) => {console.log(`Error getting organisation details [${err.status}]`)})

    }

    updateParentEvent(event) {
        console.log("EVENT:", event);
        //to close the parent modal
        if (event === 'close') {this.dismiss()}
    }

    refreshData(event) {
        console.log("refresh data EVENT:", event);
        this.updateLicenceInfo(this.organisation_id, this.licensed_user_identifier);
        this.pageStatus.dataChanged = true;

    }

    dismiss() {
        this._bottomSheetRef.dismiss(this.pageStatus.dataChanged);
    }
}

