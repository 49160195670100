import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';

import {SideNavRightComponent} from './sidenav-right.component';
// import {SidenavRightService } from './sidenav-right.service';


@NgModule({
    declarations: [
        SideNavRightComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ],
    entryComponents: [SideNavRightComponent],
    exports: [SideNavRightComponent],
    providers: []
})
export class SideNavRightModule { }

