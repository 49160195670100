import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../../models/user-profile.model';
import { responseModel } from '../../models/response.model';
import { HttpHeaders } from '@angular/common/http';
import { UserQueryRequest, UserDetailUpdate } from '../../models/user-profile.model';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';

export class UsersTableModel {
    full_name: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    dnd: boolean;
    is_blocked: boolean;
    notes: string;
    os: string;
    os_version: string;
    carrier: string;
    pipcall_version: string;
    pipcall_build: string;
    installation_id: string;
    model: string;
    dialmode: string;
    device_created_at: Date;
    device_modified_at: Date;
    organisation_id: string;
    organisation_name: string;
    organisation_state: string;
    id: string;
    created_at: Date;
    created_by: string;
    modified_at: Date;
    modified_by: string;
}

export class OwnerUpgradeForm {
    organisation_id: string
    mobile: string
    allocation_option: string
    cli_override: string
    license_id: string
}


// export class GetV2Query {
//     rest?: string;
//     pageSize?: number;
//     page?: number;
//     sort_by?: string;
// }



@Injectable()
export class UserService {
    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;
    public options;

    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService
    ) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    public postUserQuery(searchParams: UserQueryRequest): Observable<responseModel> {
        console.log("[[api]] -> post user search query");
        return this.httpProxy.post(this.ApiBaseUrl + `/users/query${this.code}`, searchParams, 'response', true);
    }

    public postDetachedUserQuery(searchParams: UserQueryRequest): Observable<responseModel> {
        console.log("[[api]] -> post detached user search query");
        return this.httpProxy.post(this.ApiBaseUrl + `/users/deleted/query${this.code}`, searchParams, 'response', true);
    }

    public patchUserDetails(orgId: string, userid: string, userDetailUpdate): Observable<responseModel> {
        console.log("[[api]] -> update users permissions");
        const body = {
            "can_dial_international": userDetailUpdate.can_dial_international,
            "can_use_sms": userDetailUpdate.can_use_sms
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/users/${userid}/permissions${this.code}`, body, 'response');
    }

    public patchUserName(id: string, userDetailUpdate): Observable<responseModel> {
        console.log("[[api]] -> update users name");
        const body = {
            "first_name": userDetailUpdate.first_name,
            "last_name": userDetailUpdate.last_name
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/users/${id}/name${this.code}`, body, 'response');
    }


    public patchUserEmail(id: string, email: string): Observable<responseModel> {
        console.log("[[api]] -> sysadmin - update users email");
        const body = {
            "email": email,
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/users/${id}/email${this.code}`, body, 'response');
    }

    public patchUserMobile(id: string, mobile: string): Observable<responseModel> {

        console.log("[[api]] -> sysadmin - update users mobile");

        if (mobile.startsWith('+44')) {
            // if +44 than convert to 0044
            const find = /\+44/;
            const regex = new RegExp(find, "g");
            mobile = mobile.replace(regex, "0044");
        }

        const body = {
            "mobile": mobile,
        }
        console.log("VALUE SUBMITED TO THE API==============", body)
        return this.httpProxy.patch(this.ApiBaseUrl + `/users/${id}/mobile${this.code}`, body, 'response');
    }


    public patchUpgradeUserLicense(orgId: string, userid: string, licenseId: string): Observable<responseModel> {
        console.log("[[api]] -> upgrade user license");
        const body = {};
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/users/${userid}/licenses/${licenseId}${this.code}`, body, 'response');
    }

    public patchUserNote(userId: string, notes: string): Observable<responseModel> {
        const body = {
            "notes": notes
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/users/${userId}/notes${this.code}`, body, 'response');
    }

    public propagateUser(identifiers: string): Observable<responseModel> {
        console.log("[[api]] -> propagate user");
        const body = {
            "identifiers": identifiers
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/tasks/propagation/users${this.code}`, body, 'response', true);
    }


    public postOrganisationUserRestore(org_id: string, user_id: string): Observable<responseModel> {
        console.log("[[api]] -> restore user");
        const body = {
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${org_id}/users/${user_id}/restore${this.code}`, body, 'response', true);
    }

    public patchConnectUserToOrganisation(old_org_id: string, userId: string, new_org_id: string): Observable<responseModel> {
        const body = { }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${old_org_id}/users/${userId}/to_organisation/${new_org_id}${this.code}`, body, 'response');
    }

    public patchOrgOwnerIsPipuser(org_id: string, userId: string): Observable<responseModel> {
        const body = { }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${org_id}/users/${userId}/is_pipuser${this.code}`, body, 'response');
    }

    // VERSION2 VERIFIED WORKING ========================================================================================================
    public getUser(id: string): Observable<responseModel> {
        console.log("[[api]] -> get user by id", id);
        return this.httpProxy.get<responseModel>(this.ApiBaseUrl + '/users/' + id + this.code, 'response', true);
    }

    public forceLogoutUser(id: string): Observable<responseModel> {
        console.log("[[api]] -> force logout user");
        const body = {}
        return this.httpProxy.post(`${environment.ApiBaseUrl}/users/${id}/forcelogoff`, body, 'response', true);
    }


    public postOwnerUpgrade(user_id: string, upgradeForm: OwnerUpgradeForm): Observable<responseModel> {
        console.log("[[api]] -> owner upgrade");
        return this.httpProxy.post(this.ApiBaseUrl + `/users/${user_id}/upgrade${this.code}`, upgradeForm, 'response', true);
    }

    public postOwnerUpgradeVerify(user_id: string, code: string): Observable<responseModel> {
        console.log("[[api]] -> owner upgrade verify");
        const body = {
            code: code
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/users/${user_id}/upgrade/validcode${this.code}`, body, 'response', true);
    }


    public getUsers_V2(queryParams?: QueryParams): Observable<responseModel> {
        console.log("[[api]] -> get user search query V2", queryParams);

        const paginationString = this.portalApiV2HelperService.getPaginationString(queryParams);
        const sortString = this.portalApiV2HelperService.getSortString(queryParams);
        const _searchString = queryParams?.searchString ? queryParams.searchString : '';
        console.log( `/v2/users/search${this.code}&rest=${paginationString}${sortString}${_searchString}`);
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/users/search${this.code}&rest=${paginationString}${sortString}${_searchString}`, 'response', true);
    }
}
