
import { Pipe, PipeTransform } from '@angular/core';

//for billing data format
@Pipe({name: 'removeSpaces'})
export class RemoveSpaces implements PipeTransform {
    transform(value: string): string {

        let trimmedValue = value? value.replace(/\s*/g, ""):null;

        return trimmedValue;
    }
}
