<div class="pip-modal-content">
  <button class="pip-modal-close_btn"  mat-mini-fab color="primary" (click)="closeModal()"aria-label="close modal"> 
    <mat-icon svgIcon="clear"></mat-icon>
  </button> 
  <div class="pip-modal-header">
      <span class="pip-modal-title">Debug Menu</span>
  </div>

  <div class="pip-modal-body"  class="pip-modal-body pip-scroll-shadow">
<div>
  <button (click)="checkFileExists()">Check for Version File</button>

  <button (click)="checkFileExistsv2()">Check for Version File v2</button>

</div>

  </div>



  <div class="pip-modal-footer">
   
  </div>
</div>




