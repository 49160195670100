//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel, OrgMetaData } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { NumberService, AreaCode } from '@app/services/pipcall/number.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { MatAccordion } from '@angular/material/expansion';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { UserProfile } from '@app/models/user-profile.model';
import { escape, unescape} from 'safe-string-literal';
import { GA4Service } from '@app/services/google-analytics-service';


@Component({
    selector: 'app-org-tabs-get-started',
    templateUrl: './org-get-started.component.html',
    styleUrls: ['./org-get-started.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OrgTabGetStartedComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional ?
    @Input() organisation_id: string;
    @Input() stage: number;
    @Input() completedStage: number;

    @Output() updateParent = new EventEmitter();
    @Output() goToTab = new EventEmitter();

    @ViewChild(MatAccordion) accordion: MatAccordion;

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isLoadingDirectory: false
    }

    panelOpenState = false;
    activeUser: UserProfile;


    // /debugstuff

    showDebugInfoBox = false;
    debug = {
        unescapedString: '',
        escapeJSONstring: '',
        escapedJSONv2string: '',

    }

    skipped: number[] = []; //secions that have been skipped.


    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private appInsightService: AppInsightService,
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private sharedService: SharedService,
        private gA4Service: GA4Service
    ) {
        this.sharedService.userProfileAsObservable.subscribe(resp => {
            this.activeUser = resp;
        })

    }



    ngOnChanges(changes: SimpleChanges) {
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?  this.ref.detectChanges() : null;
    }


    ngOnInit() {
        this.appInsightService.logEvent('Display Get Started Tab', { 'organisation_id': this.organisation_id })
        if ( !this.organisationProfile ) {
            this.getOrganisationDetails();
        }

        //debugging - remove this
        // this.debug.unescapedString = unescape(this.organisationProfile?.metadata);
        // const parseddata = JSON.parse(this.debug.unescapedString);
        // const JSONstringifyNonEscaped = JSON.stringify(parseddata);
        // this.debug.escapeJSONstring = escape(JSONstringifyNonEscaped);
        // const parsedatav2 =  JSON.parse(unescape(this.debug.unescapedString));

    }



    getOrganisationDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;
        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                if (resp.status === 200 ) {
                    this.organisationProfile = resp.body;
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })

    }

    skippedStage(index: number) {
        this.skipped.push(index);
    }
    isStageSkipped(stage) { return this.skipped.includes(stage); }

    setStage(index: number) {
        console.log("set---> stage", index)
        const meta: OrgMetaData = JSON.parse(unescape(this.organisationProfile?.metadata));
        console.log("set---> meta", meta);

        meta.onboarding.stage = index;
        switch (index) {
            case 0: {
                meta.onboarding.label = 'new_signup'
                break;
            }
            case 1: {
                meta.onboarding.label = 'organisation_details'
                break;
            }
            case 2: {
                meta.onboarding.label = 'user_invitations'
                // meta.onboarding.label = 'billing_details'
                break;
            }
            case 3: {
                meta.onboarding.label = 'complete'
                this.gA4Service.sign_up_success('PiPxt');
                // meta.onboarding.label = 'user_invitations'
                break;
            }
            // case 4: {
            //     meta.onboarding.label = 'complete'
            //     break;
            // }
            default: {
                meta.onboarding.label = ''
                break;
            }
        }
        console.log("new meta", meta )
        this.organisationService.patchOrganisationMetadata(this.organisation_id, meta).subscribe(resp => {
            console.log("Updated organisation metadata", meta);

            this.organisationProfile.metadata = escape(JSON.stringify(meta));
            console.log("Updated organisation metadata - escaped string set now", this.organisationProfile.metadata);
            console.log(resp);
            console.log("STAGE", meta.onboarding.stage, index, this.completedStage)
            if (index === this.completedStage) {
                console.log("ONBOARDING IS COMPLETE====UPDATE THE PARENT", )
                this.triggerUpdate(); //completed tutorial
                window.location.reload(); //fix for reload problem. can remove preivous line
            }
        }, err => {console.log(err)});
        this.ref.detectChanges();
        this.stage = index;
    }


    navigationToTab(tab: string) {
        this.goToTab.emit(tab);

    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }


    debugForm(f: FormGroup) {
        console.log(f.value);
    }

    test() {
        const meta = {
            version: "1.0",
            modified_at: "2023-08-20T00:00:00.000Z",
            onboarding: {
                stage: 0,
                label: "signup"
            }
        }
        console.log( 'start');
        const metaString: string = JSON.stringify(meta);

        const escaped = escape(metaString)

        console.log(metaString, ".....");
        console.log(escaped, "escaped");
        // console.log(encodeURIComponent(metaString), "escaped");

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
