import { Component, Input } from '@angular/core';
import { collapseAnimation } from 'angular-animations';

@Component({
    selector: 'tab-section-action',
    templateUrl: './tab-section-action.component.html',
    styleUrls: ['./tab-section-action.component.scss'],
    animations: [
        collapseAnimation()
    ]
})
export class TabSectionActionComponent {
    @Input() isHidden;
}
