import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({ providedIn: "root" })
export class SidenavRightService {

    // rightNavOpened = new EventEmitter<boolean>();
    public openned = new BehaviorSubject<boolean>(false);
    public disableClose = new BehaviorSubject<boolean>(false);
    private data = new BehaviorSubject<any[]>(null);
    private _componentPortal$ = new BehaviorSubject<any>(null);

    private closeEvent = new BehaviorSubject<any>(null);
    private dataChanged = new BehaviorSubject<boolean>(null); //inform subscriber that data has changed and to update any parent data

    // eslint-disable-next-line @typescript-eslint/member-ordering
    closeEventAsObservable = this.closeEvent.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    dataChangedAsObservable = this.dataChanged.asObservable();

    getComponentPortal$() { return this._componentPortal$.asObservable(); }
    setComponetPortal(component: any) { this._componentPortal$.next(component); }

    getContextData$() { return this.data.asObservable(); }
    setContextData(data: any) { this.data.next(data); }

    getOpenState() { return this.openned.asObservable(); }
    setOpenState(isOpen: boolean, data?: any) {
        data ? this.closeEvent.next(data) : this.closeEvent.next(false); //close event with data
        if (!isOpen) {
            //clear data and close
            this.reset();
            this.openned.next(isOpen);
        } else {
            //open it
            this.openned.next(isOpen);
        }
    }

    getDisableState() { return this.disableClose.asObservable(); }
    setDisabledState(isDisabled: boolean) {
        this.disableClose.next(isDisabled);
    }

    markDataChanged(state: boolean) {
        this.dataChanged.next(state);
    }

    init() { }

    private reset() {
        this.setContextData(null);
        this.setComponetPortal(null);
        this.setDisabledState(false);
        this.markDataChanged(null);
    }


}
