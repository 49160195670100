
export class Licensedata {

    // public static licenseOptions = {
    //     'talk': [ { display: 'Basic Geo', value: 'basic_talk' }, { display: 'Standard Geo', value: 'talk' }],
    //     'talk_and_text': [{ display: 'Basic Mobile', value: 'basic_talk_and_text' } , { display: 'Standard Mobile', value: 'talk_and_text' }]
    // };

    // public static contractOptions = [
    //     { display: '1m contract(Monthly)', value: '1m_monthly', hierarchy: 1 },
    //     { display: '12m contract (Monthly)', value: '1y_monthly', hierarchy: 2 },
    //     { display: '12m contract (Annually)', value: '1y_up-front', hierarchy: 3 }
    // ]
}

export class LicenseModel {
    id: string;
    name: string;
    contract_name: string;
    type: string;
    contract_type;
    version_number: string;
    price: number;
    valid_from: string;
    valid_to: string;
}


export class AddonPackageModel {
    id: string;
    name: string;
    type: string;
    version_number: string;
    unit: string;
    quantity: number;
    price: number;
    valid_from: string;
    valid_to: string;
}

export interface UnlicensedUser {
    type: 'User' | 'Invitation';
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    number: string;
    is_mobile_license: boolean;
}


export interface PricingListItem {
    display: string;
    type: string;
    isMobile: boolean;
    isBasic: boolean;
    rolling: { contract_type: string; licence_type: string, licence_type_display: string, display: string; license_id: string, price: number, yearlyPrice: string, monthlyPrice: string, pounds: string, pence: string };
    monthly: { contract_type: string; licence_type: string,  licence_type_display: string, display: string; license_id: string, price: number,  yearlyPrice: string,  monthlyPrice: string, pounds: string, pence: string };
    annually: { contract_type: string; licence_type: string,  licence_type_display: string, display: string; license_id: string, price: number, yearlyPrice: string, monthlyPrice: string, pounds: string, pence: string };
}

export class SelectedLicenceModel {
    contract_type: string;
    licence_type_display: string;
    licence_type: string;
    display: string;
    license_id: string;
    price: number;
    yearlyPrice: string;
    monthlyPrice: string;
    pounds: string;
    pence: string;
}

export class ViewLicenseModel {
    license_id: string;
    license_name: string;
    license_contract_name: string;
    license_type: string;
    license_contract_type: string;
    license_version_number: string;
    license_price: number;
    licensed_at: string;
    licensed_user_type: string;
    is_paid: boolean;
    allow_renewal: boolean;
    next_renewal_date: string;
    organisation_id: string;
    organisation_name: string;
    organisation_state: string;
    billing_day: string;
    licensed_user_identifier: string;
    first_name: string;
    last_name: string;
    email: string;
}

// is_paid (change from is_license_paid)
// licensed_user_identifier  (change from id)
// organisation_state  (new)
// billing_day (new)
// license_price (new)
