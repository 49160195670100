
<mat-toolbar>
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center" class="toolbar-max-width-1440">
          <!-- signup screen toolbar -->
          <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="navigateToHome()">
              <mat-icon class="icon-size-22 main-logo" svgIcon="home"></mat-icon>
          </button>
          <span  class="clickable" routerLink="/">PiPcall Portal</span>
          
          <span class="topbar-spacer"></span>
          
          <!-- <button mat-button routerLinkActive="nav-item-active"
          routerLink="/signup">
              SIGN UP
          </button> -->
          <button mat-button routerLinkActive="nav-item-active"
          routerLink="/login">
              LOGIN
          </button>
      </mat-toolbar-row>
</mat-toolbar>

<div fxLayout="row" class="top-banner" fxLayoutAlign="space-between center">
    <div class="banner-content" fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center">
        <img class="pip-logo" src="/assets/brand/pipcall-logo-white-flat.png">
        <div class="banner-copy-1">The UK’s most advanced mobile service</div>
    </div>

  </div>
