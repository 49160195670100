import { Injectable } from '@angular/core';


export class QueryParams {
    sort_by: string;
    sort_direction: string;
    pageNumber: number;
    pageSize: number;
    pageCount: number;
    totalCount: number;
    searchString: string;
}

@Injectable()
export class PortalApiV2HelperService {
    constructor() {

    }

    public getPaginationString(pagination): string {
        let paginationString = '';

        if (pagination?.pageSize === undefined || pagination?.pageNumber === undefined) {
            return paginationString;
        } else {
            paginationString = `$page=${pagination?.pageNumber}&$pageSize=${pagination?.pageSize}`;
        }
        return paginationString;
    }


    public getSortString(pagination): string {
        let sortString = '';

        if (pagination?.sort_by === undefined || pagination?.sort_direction === undefined) {
            return sortString;
        } else {
            sortString = `&$sort_by[${pagination?.sort_direction}]=${pagination?.sort_by}`;
        }
        return sortString;
    }

    public getSearchString(searchTerm: string, searchColumn: string, operator?: string): string {
        let searchString = '';
        operator = operator === undefined ? 'contains' : operator;
        searchString = `&${searchColumn}[${operator}]=${searchTerm}`
        return searchString;
    }
}



// const paginationString = (pagination?.pageSize !== undefined && pagination?.pageNumber !== undefined) ? `$page=${pagination?.pageNumber}&$pageSize=${pagination?.pageSize}` : '';
// const sortString = (pagination?.sort_by !== undefined && pagination?.sort_direction !== undefined) ? `&$sort_by[${pagination?.sort_direction}]=${pagination?.sort_by}` : '';

//SEARCH ON TYPING
// const searchTerm$ = fromEvent(this.input.nativeElement, 'keyup').pipe(
//     debounceTime(500), // Adjust the debounce time as needed (in milliseconds)
//     distinctUntilChanged(),
//     map(() => {
//         //replace + character with a 00 to properly search phone numbers
//         const search = this.input.nativeElement.value.replace('+', '00');
//         return search;
//     })
// );


//SEARCH ON ENTER
// const searchTerm$ = fromEvent(this.input.nativeElement, 'keyup').pipe(
//     debounceTime(150), // Adjust the debounce time as needed (in milliseconds)
//     filter((e: KeyboardEvent) => e.key === "Enter"), // filter for enter key
//     distinctUntilChanged(),
//     map(() => {
//         //replace + character with a 00 to properly search phone numbers
//         const search = this.input.nativeElement.value.replace('+', '00');
//         return search;
//     })
// );


// searchTerm$.subscribe((resp) => {
//     console.log('search term entered!', resp);
//     //reset the pagination & getData
//     this.setPagination();
//     this.getData(this.pagination);
// });
