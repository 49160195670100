import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormControl, Form, AbstractControl } from '@angular/forms';
import { MatSnackBar  } from '@app/material/material-essentials.module';
import { VerifyService } from '../../../services/pipcall/verify.service';

//normalise number
import { RegionCodes, NormalizeNumberService } from '../../../services/helpers/normalize-number.service';

// import { EmailValidator } from '../../../validators/emailValidator.directive';
import { PhonenumberValidator } from '../../../validators/phonenumberValidator.directive';
import { ValidateEmailNotTaken } from '../../../validators/async-email.validator';
import { ValidateMobileNotTaken } from '../../../validators/async-mobile.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { InvitationService } from '../../../services/pipcall/invitation.service';
import { InvitationUpdateDetails, InvitationModel } from '../../../models/invitation.model';
import { TitleCasePipe } from '@angular/common';
import { AppTitleService } from '../../../services/helpers/update-title.service';
import { AppInsightService} from '../../../services/helpers/app-insights.service';


@Component({
    selector: 'app-invitation-verify',
    templateUrl: './invitation-verify.component.html',
    styleUrls: ['./invitation-verify.component.scss']
})
export class InvitationComponent implements OnInit {

    public id: string;
    public invitation: InvitationModel;

    public loading = true; // start screen as loading.
    public success = false; //set to true if correct data returned.

    public displayUpdateMobile = false; //display the fornm to update mobile
    public normalizedNumber: string; //number normalized for display (as +44)
    public resendCode: InvitationUpdateDetails;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar,
        private verifyService: VerifyService,
        private normalizeNumberService: NormalizeNumberService,
        private appInsightService: AppInsightService,
        private appTitleService: AppTitleService,
        private invitationService: InvitationService) {
    }

    ngOnInit() {
        this.appTitleService.setTitle('PiPcall: Verify');
        this.appInsightService.logPageView('Verify: Invitation');

        this.resendCode = new InvitationUpdateDetails();
        this.activatedRoute.params
            .switchMap(routeParams => {
                if (routeParams['id']) {
                    this.id = routeParams['id'];
                    return this.GetInviteDetails(this.id)
                } else {
                    //no id returned. can not continue
                    this.setResultScreen(false);
                    return;
                }
            })
            .subscribe(data => {
                if (data.status === 200 && !data.body.is_accepted && data.body.mobile != null) {
                    console.log("invite details returned", data);
                    this.invitation = data.body;
                    this.invitation.cli_override;
                    this.normalizedNumber = this.normalizeNumberService.normaliseNumberWithPlus(this.invitation.mobile);

                    this.resendCode.first_name = data.body.first_name;
                    this.resendCode.last_name = data.body.last_name;
                    this.resendCode.mobile = data.body.mobile;
                    this.setResultScreen(true);
                } else {
                    this.setResultScreen(false);
                }
            }, (err) => {
                this.setResultScreen(false);
                //display error message on final screen
                //put up a generic error screen. no id or expired.
            });

    }

    public GetInviteDetails(id: string) {
        return this.invitationService.getInvitationDetails(id);
    }

    setResultScreen(bool: boolean): void {
    //true returned all good
        if (bool) {
            this.loading = false;
            this.success = true;
        } else {
            //false. returned unsuccessfull
            this.loading = false;
            this.success = false;
        }
    }

    triggerSMS(event): void {
        if (event) {
            // this.sendCode(event.id, event.mobile);
            this.sendCodeInvitation(this.resendCode, event.id);
        }
    }

    updateMobileClose(event): void {
        if (event) {
            this.normalizedNumber = this.normalizeNumberService.normaliseNumberWithPlus(event);
            this.invitation.mobile = event;
            this.resendCode.mobile = this.invitation.mobile;
        }

        this.displayUpdateMobile = false;
    }


    sendCodeInvitation(resendCode: InvitationUpdateDetails, id: string): void {
        this.invitationService.putInvitationDetails(resendCode, id).subscribe((resp) => {
            //success
            if (resp.status === 200) {
                this.openSnackBar("We have sent you an sms with a code", "dismiss");
            } else if  (resp.status === 429) {
                console.log('too many requests', 'OK');
            } else {
                this.openSnackBar("There was a problem sending an SMS message", "dismiss");
            }
        }, (err) => {
            if (err.status === 429) {
                this.appInsightService.logEvent('Too many verification requests', { 'invitation_id': id});
                console.log('too many requests', 'OK');
            } else {
                this.openSnackBar("There was a problem sending an SMS message", "dismiss");
            }

        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 8000,
        })
    }

}
