import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found.component';
@NgModule({
    imports: [
        CommonModule,

    ],
    declarations: [PageNotFoundComponent],
    exports: [PageNotFoundComponent],
    providers: [],
    entryComponents: []
})
export class PageNotFoundModule { }