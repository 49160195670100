import { Component, OnInit, ViewChild, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { InvoiceLicenseItem, Invoice, AddOnPackageItem, StubItem, AdHocItem } from '@app/models/invoice.model';
import { InvoiceService, PdfResponse } from '@app/services/pipcall/invoice.service';
import * as FileSaver from 'file-saver';
import { map, takeUntil, tap, finalize } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    templateUrl: './invoice-modal.component.html',
    styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceComponent implements OnInit {

    _destroy$ = new Subject<void>(); //handles unsubscribing events

    public id: string; //invoice id
    public viewAs: string;
    public organisation_id: string;
    invoice: Invoice;
    invoiceLicenseItems: InvoiceLicenseItem[];
    addOnPackageItems: AddOnPackageItem[];
    licenseStubItems: StubItem[];
    adHocItems: AdHocItem[];
    public _invoiceLicenseItems = []; //grouped items

    isLoading = true;

    constructor(
        private dialogRef: MatDialogRef<InvoiceComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: any, //contains data injected into modal.
        private snackBar: MatSnackBar,
        private invoiceService: InvoiceService
    ) {
        this.invoice = new Invoice();
        this.invoiceLicenseItems = new Array<InvoiceLicenseItem>();
        this.addOnPackageItems = new Array<AddOnPackageItem>();
        this.licenseStubItems = new Array<StubItem>();
        this.adHocItems = new Array<AdHocItem>();

        this.id = data.id;
        this.viewAs = data?.viewAs;
        this.organisation_id = data?.organisation_id;
        this.getInvoice(this.id);
    }

    ngOnInit() {

    }

    getInvoice(id: string) {
        this.isLoading = true;
        this.invoiceService.getInvoiceById(id)
            .pipe(
                finalize(() => { }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                this.invoice = resp.body;
                this.invoiceLicenseItems = resp.body.invoice_license_items;
                this.addOnPackageItems = resp.body.invoice_add_on_package_items;
                this.licenseStubItems = resp.body.invoice_license_stub_items;
                this.adHocItems = resp.body.invoice_ad_hoc_items;
                this._invoiceLicenseItems = this.buildInvoice(this.invoiceLicenseItems);
            },
            (err) => {
                this.openSnackBar("could not get invoice details", "dismiss");
            })
    }
    buildInvoice(list: InvoiceLicenseItem[]) {
        // group license items that are identical
        const _newList = [];
        list?.forEach(licenseItem => {

            const itemIndex = _newList.map((item) => { return item.license_id; }).indexOf(licenseItem.license_id);
            // for each item does it already exist in new _list
            //  let matchedItem = list.find(item => item.id === licenseItem.license_id);
            const entry = {
                license_id: licenseItem.license_id,
                quantity: 1,
                license_name: licenseItem.license_name,
                license_contract_name: licenseItem.license_contract_name,
                price: licenseItem.price
            }
            if (itemIndex > -1) {
                //if there is an existing item with same id. only increase the quantity
                _newList[itemIndex].quantity++; //increate the quantity
            } else { _newList.push(entry) }; //if entry already exists, add Quantity to it
        });
        this.isLoading = false;
        return _newList;
    }

    downloadPdf(id: string) {
        console.log('DOWNLOAD:', id);
        this.invoiceService.getInvoicePdf(this.organisation_id, id).subscribe((resp) => {
            console.log("get invoice pdf:", resp)
            resp.success ? FileSaver.saveAs(resp.fileUrl) : this.openSnackBar("Sorry, could not get this pdf at this time. Please try later", "dismiss");
        }, (err) => {
            console.log(err);
            this.openSnackBar("Sorry, could not get this pdf at this time. Please try later", "dismiss")
        })
    }

    closeModal() {
        this.dialogRef.close(true);
    }

    scrollEvent(event) {
        const el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop !== el.clientHeight ? el.classList.add('pip-scroll-shadow-after') : el.classList.remove('pip-scroll-shadow-after');
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }
}
