  <app-toolbar-v2></app-toolbar-v2>

  <div fxLayout="column">


    <div fxLayout="column" class="main-container" fxLayoutAlign="start center">

      <div fxFlex="fxFill" fxLayout="row" fxLayout.lt-lg="column" class="section-container max-width-1440">

        <div class="lr-content-wrapper" fxFlex="50%" fxFlex.lt-lg="100%">

          <ng-container *ngIf="pageStatus.step === 0">

            <h4 class="title-font title-color" ngClass.lt-lg="text-center">Set a new password</h4>

            <div ngClass.lt-lg="text-center">Enter the email address you signed up with.</div>

          </ng-container>

          <ng-container *ngIf="pageStatus.step === 1">

            <h4 class="title-font title-color" ngClass.lt-lg="text-center">Set new password</h4>

            <div ngClass.lt-lg="text-center">Enter the verification code we emailed you and set a new password.</div>

          </ng-container>


          <ng-container *ngIf="pageStatus.step === 2">

            <h4 class="title-font title-color" ngClass.lt-lg="text-center">Success !</h4>

            <div ngClass.lt-lg="text-center">Password updated, logging into Portal... <i class="fas fa-spinner fa-spin"></i></div>
        </ng-container>


        </div>

        <div class="lr-content-wrapper" fxFlex="50%" fxFlex.lt-lg="100%">
          <div fxLayout="column">


            <ng-container *ngIf="pageStatus.step === 0">
              <form [formGroup]="forgotPWForm" fxLayout="column" fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex="100%" class="custom-form-field-v3">
                  <input type="text" matInput placeholder="Email" autocomplete="email" formControlName="email">
                  <mat-icon matSuffix svgIcon="email_v2"></mat-icon>
                  <mat-error
                    *ngIf="forgotPWForm?.controls.email?.touched && forgotPWForm?.controls.email?.errors?.required">
                    email is required</mat-error>
                  <mat-error
                    *ngIf="forgotPWForm?.controls.email?.touched && forgotPWForm?.controls.email?.errors?.email">
                    not a valid email</mat-error>

                  <mat-label>Email</mat-label>

                  <status-icons *ngIf="forgotPWForm?.controls.email?.touched"
                    [iconStatus]="forgotPWForm.get('email').status">
                  </status-icons>
                </mat-form-field>
              </form>



              <div class="error-response-box" *ngIf="pageStatus.isError"
                [@enter]="{ value: '', params: { duration: 150, delay: 0 } }"
                [@fadeOutLeftOnLeave]="{ value: '', params: { duration: 100, delay: 0, translate: '50%' } }">
                <mat-icon class="clickable" (click)="pageStatus.isError = false">cancel</mat-icon>
                {{pageStatus.errorMessage}}
              </div>


              <div fxLayout="row" fxLayoutAlign="end center" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="center center">

                <button class="submit-button" mat-flat-button color="accent"
                  [disabled]="pageStatus.isLoading || !forgotPWForm.valid"
                  (click)="submitForm(forgotPWForm.get('email').value)">
                  <ng-container *ngIf="!pageStatus.isLoading;else spinning">Continue</ng-container>
                  <ng-template #spinning><span class="loading-spinner"><i class="fas fa-spinner fa-spin"></i></span>
                  </ng-template>
                </button>
              </div>

            </ng-container>

            <ng-container *ngIf="pageStatus.step === 1">
              <form [formGroup]="verificationForm" fxLayout="column" fxLayoutGap="10px">
                <mat-form-field appearance="outline"  class="custom-form-field-v3">
                  <input matInput type="tel" pattern="[0-9]*" inputmode="numeric" placeholder="Enter code"
                    formControlName="verificationCode">
                  <mat-label>Verification Code</mat-label>

                  <status-icons *ngIf="verificationForm?.controls.verificationCode?.touched"
                    [iconStatus]="verificationForm.get('verificationCode').status">
                  </status-icons>

                </mat-form-field>

                <mat-form-field appearance="outline"  class="custom-form-field-v3">

                  <input type="{{pwType}}" matInput placeholder="Password" autocomplete="new-password"
                    formControlName="password"
                    title="Password strength: minimum length 6 characters and must include lower case (a-z), upper case (A-Z) and a number (0-9)">
                  <mat-icon matSuffix class="clickable" *ngIf="pwType != 'password'" (click)="pwType = 'password'"
                    title="hide password" (click)="$event.stopPropagation()" svgIcon="view"></mat-icon>
                  <mat-icon matSuffix class="clickable" *ngIf="pwType === 'password'" (click)="pwType = 'text'"
                    title="show password" (click)="$event.stopPropagation()" svgIcon="view_off"></mat-icon>

                  <mat-error
                    *ngIf="verificationForm?.controls.password?.touched && verificationForm?.controls.password?.errors?.required">
                    Required field
                  </mat-error>

                  <mat-error
                    *ngIf="verificationForm?.controls.password?.touched && verificationForm?.controls.password?.errors?.pattern">
                    min length 6 chars, include lower case (a-z), upper case (A-Z) and a number (0-9)
                  </mat-error>

                  <mat-label>Password</mat-label>

                  <status-icons *ngIf="verificationForm?.controls.password?.touched"
                    [iconStatus]="verificationForm.get('password').status">
                  </status-icons>

                </mat-form-field>

                <mat-form-field appearance="outline"  class="custom-form-field-v3">

                  <input type="{{pwType}}" matInput placeholder="Confirm Password" autocomplete="new-password"
                    formControlName="passwordConfirm"
                    title="Password strength: minimum length 6 characters and must include lower case (a-z), upper case (A-Z) and a number (0-9)">
                  <mat-icon matSuffix class="clickable" *ngIf="pwType != 'password'" (click)="pwType = 'password'"
                    title="hide password" (click)="$event.stopPropagation()" svgIcon="view"></mat-icon>
                  <mat-icon matSuffix class="clickable" *ngIf="pwType === 'password'" (click)="pwType = 'text'"
                    title="show password" (click)="$event.stopPropagation()" svgIcon="view_off"></mat-icon>

                  <mat-error
                    *ngIf="verificationForm?.controls.passwordConfirm?.touched && verificationForm?.controls.passwordConfirm?.errors?.required">
                    Required field
                  </mat-error>

                  <mat-error
                    *ngIf="verificationForm?.controls.passwordConfirm?.touched && verificationForm?.controls.passwordConfirm?.errors?.MatchPassword">
                    Passwords do not match
                  </mat-error>

                  <mat-label>Confirm Password</mat-label>

                  <status-icons *ngIf="verificationForm?.controls.passwordConfirm?.touched"
                    [iconStatus]="verificationForm.get('passwordConfirm').status"></status-icons>

                </mat-form-field>

              </form>

              <div class="error-response-box" *ngIf="pageStatus.isError"
                [@enter]="{ value: '', params: { duration: 150, delay: 0 } }"
                [@fadeOutLeftOnLeave]="{ value: '', params: { duration: 100, delay: 0, translate: '50%' } }">
                <mat-icon class="clickable" (click)="pageStatus.isError = false">cancel</mat-icon>
                {{pageStatus.errorMessage}}
              </div>

              <div fxLayout="row" fxLayoutAlign="end center" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="center center">
                <button mat-button (click)="cancelVerification()">Cancel</button>
                <button class="submit-button" color="accent" mat-flat-button
                  [disabled]="pageStatus.isLoading || !verificationForm.valid" (click)="verifyCode(verificationForm)">
                  <ng-container *ngIf="!pageStatus.isLoading;else spinning">Confirm</ng-container>
                </button>
              </div>

            </ng-container>




          </div>

        </div>

      </div>

      <!-- <div fxFlex="fxFill" fxLayout="column" fxLayoutAlign="center center" class="section-container max-width-1440" >
                        <div class="vertical-line"></div>
                       <div class="title-color" >Learn more</div>
                    </div> -->

    </div>


  </div>

  <ng-template #success>
    <mat-card class="no-shadow signup-container">
      <mat-card-header>
        <mat-card-title class="page-title">
          <span class="main-title">Success</span>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        Password updated, logging into Portal... <i class="fas fa-spinner fa-spin"></i>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #spinning><span class="loading-spinner"><i class="fas fa-spinner fa-spin"></i></span>
