<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">

      <div class="section-info-bar system" fxLayoutAlign="space-between center" fxLayout="row">
        Editing as system administrator
      </div>

      <div class="section-wrapper" *ngIf="organisationProfile?.is_pipxt_enabled">

        <tab-title-lined>Onboarding</tab-title-lined>

        <div class="section-content">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <!-- //can make this programatically adjust to length ? -->
            <div [ngClass]="{'active-orange': onboardState.stage === null  || onboardState.stage < 0 }"
              class="round-button" matTooltip="Hidden from view" (click)="updateOnboardingState(-1)">Hidden</div>
            <div [ngClass]="{'active': onboardState.stage === 0}" class="circle"
              matTooltip="Welcome view, user has just signed up and onboard is displayed"
              (click)="updateOnboardingState(0)">0</div>
            <div [ngClass]="{'active': onboardState.stage === 1}" class="circle"
              matTooltip="Enter organisation name and size" (click)="updateOnboardingState(1)">1</div>
            <!-- <div [ngClass]="{'active': onboardState.stage === 2}"  class="circle" matTooltip="Enter billing details" (click)="updateOnboardingState(2)">2</div> -->
            <div [ngClass]="{'active': onboardState.stage === 2}" class="circle" matTooltip="Send invitations to users"
              (click)="updateOnboardingState(2)">2</div>
            <div [ngClass]="{'active': onboardState.isCompleted}" class="round-button" matTooltip="Marked as completed"
              (click)="updateOnboardingState(3)">Complete</div>
          </div>
        </div>
      </div>

      <div class="section-wrapper">

        <tab-title-lined>Products</tab-title-lined>

        <tab-section-action [isHidden]="updateOrganisationSettingForm?.pristine">
          <button mat-flat-button *ngIf="!updateOrganisationSettingForm?.pristine" style="margin-right: 4px;"
          (click)="cancelForm()">Cancel</button>

        <button mat-flat-button aria-label="Save changes" class="save-button"
          [disabled]="updateOrganisationSettingForm?.pristine ||  pageStatus.isSubmitting || updateOrganisationSettingForm?.invalid"
          (click)="saveOrgProductSettings(updateOrganisationSettingForm)">
          <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
        </button>
          </tab-section-action>


        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <form fxLayout="column" [formGroup]="updateOrganisationSettingForm">

              <div>
                <mat-slide-toggle formControlName="is_pipmobile_enabled">
                  Enable PiPmobile</mat-slide-toggle> <i [style.margin-left.px]="20" *ngIf="isSubmitting"
                  class="fas fa-spinner fa-spin"></i>
              </div>

              <div>
                <mat-slide-toggle formControlName="is_pipxt_enabled">
                  Enable Mobile+</mat-slide-toggle><i [style.margin-left.px]="20" *ngIf="isSubmitting"
                  class="fas fa-spinner fa-spin"></i>
              </div>

            </form>
          </div>
        </div>
      </div>

      <div class="section-wrapper">
        <tab-title-lined>Notes&nbsp;<mat-icon svgIcon="compose"></mat-icon></tab-title-lined>

        <tab-section-action [isHidden]="updateOrgNoteForm?.pristine">
          <button mat-flat-button *ngIf="!updateOrgNoteForm?.pristine" style="margin-right: 4px;"
          (click)="cancelForm()">Cancel</button>

        <button mat-flat-button aria-label="Save changes" class="save-button"
          [disabled]="updateOrgNoteForm?.pristine ||  pageStatus.isSubmitting || updateOrgNoteForm?.invalid"
          (click)="saveNote(updateOrgNoteForm)">
          <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
        </button>

        </tab-section-action>

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <form [formGroup]="updateOrgNoteForm" fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%"
              fxFlexOrder.lt-md="2" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">
              <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 inner-shadow">
                <!-- <input type="text" matInput placeholder="Notes" formControlName="notes" [readonly]="isSubmitting" > -->
                <textarea formControlName="notes" style="min-height: 100px;" matInput placeholder=""></textarea>
              </mat-form-field>
            </form>
            <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1" style="padding-top:20px;">
              This note is only visible by system administrators
            </div>
          </div>


        </div>
      </div>




      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">
        <div class="section-wrapper" fxFlex="50%" fxFlex.lt-md="100%">
          <tab-title-lined>State</tab-title-lined>


          <tab-section-action [isHidden]="organisationStateForm?.pristine">

              <button mat-flat-button *ngIf="!organisationStateForm?.pristine" style="margin-right: 4px;"
                (click)="cancelForm()">Cancel</button>

              <button mat-flat-button aria-label="Save changes" class="save-button"
                [disabled]="organisationStateForm?.pristine ||  pageStatus.isSubmitting || organisationStateForm?.invalid"
                (click)="patchState(organisationStateForm)">
                <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
              </button>
   
          </tab-section-action>

          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
              <form [formGroup]='organisationStateForm' fxLayout="column" fxFlex="200px" fxFlex.lt-md="100%"
                fxFlexOrder.lt-md="2" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">
                <mat-form-field appearance="outline" fxFlex class="custom-form-field-v3">
                  <mat-select formControlName="state" placeholder="Select">
                    <mat-option *ngFor="let state of stateOptions" [value]="state.name">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                  <mat-label>State</mat-label>
                </mat-form-field>
              </form>

            </div>
          </div>
        </div>

        <div class="section-wrapper" fxFlex="50%" fxFlex.lt-md="100%">

          <tab-title-lined>Trial End</tab-title-lined>

          <tab-section-action [isHidden]="updateTrialDateForm?.pristine">
              <button mat-flat-button *ngIf="!updateTrialDateForm?.pristine" style="margin-right: 4px;"
                (click)="cancelForm()">Cancel</button>

              <button mat-flat-button aria-label="Save changes" class="save-button"
                [disabled]="updateTrialDateForm?.pristine ||  pageStatus.isSubmitting || updateTrialDateForm.invalid"
                (click)="saveTrialDate(updateTrialDateForm)">
                <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
              </button>
          </tab-section-action>

          <div class="section-content" fxLayoutAlign="space-between start">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

              <form [formGroup]="updateTrialDateForm" fxLayout="column" fxLayout="column" fxFlex="200px"
                fxFlex.lt-md="100%">

                <mat-form-field appearance="outline" class="custom-form-field-v3">

                  <input id="mat-select-custom" formControlName="trial_end_date" class="clickable" autocomplete="false"
                    matInput [matDatepicker]="picker" [min]="today" [value]="trialEndDate" (click)="picker.open()"
                    placeholder="Choose a date" (dateChange)="trialdDateValueChange($event.value)">
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-icon matSuffix class="clickable icon_24" (click)="picker.open()" svgIcon="down_arrow"></mat-icon>
                  <mat-label>Trial end date</mat-label>
                </mat-form-field>


              </form>

              <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1" style="padding-top: 20px;">
                Organisation trial end date
              </div>

            </div>
          </div>
        </div>
      </div>




      <!-- <div class="section-wrapper" fxFlex="50%" fxFlex.lt-md="100%">
          <tab-title-lined>Set-up Fees</tab-title-lined>
          <div class="section-action" [hidden]="updateFeesForm?.pristine">
            <div style="justify-content: flex-end;display:flex;width:100%;">
              <button mat-flat-button *ngIf="!updateFeesForm?.pristine" style="margin-right: 4px;"
                (click)="cancelForm()">Cancel</button>

              <button mat-flat-button aria-label="Save changes" class="save-button"
                [disabled]="updateFeesForm?.pristine ||  pageStatus.isSubmitting || updateFeesForm.invalid"
                (click)="saveUpdateOganisationFees(updateFeesForm)">
                <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
              </button>
            </div>
          </div>

          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">



              <form [formGroup]="updateFeesForm" fxLayout="column">

                <mat-form-field appearance="outline" class="custom-form-field-v3">
                  <input autocomplete="off" type="text" matInput placeholder="Initial set up"
                    formControlName="initial_set_up_fee" mvndrMatCurrencyFormat [allowNegative]="false"
                    [currencyCode]="'GBP'" (blur)="updateFeeAmount('initial_set_up_fee', $event)">
                  <mat-label>£ Initial set up</mat-label>
                </mat-form-field>

                <mat-form-field appearance="outline" class="custom-form-field-v3">
                  <input autocomplete="off" type="text" matInput placeholder="Monthly service charge"
                    formControlName="monthly_service_charge" mvndrMatCurrencyFormat [allowNegative]="false"
                    [currencyCode]="'GBP'" (blur)="updateFeeAmount('monthly_service_charge', $event)">
                  <mat-label>£ Monthly service charge</mat-label>
                </mat-form-field>


              </form>


            </div>
          </div>
        </div> -->



      <ng-container *ngIf="organisationProfile?.is_pipxt_enabled">

        <app-org-pbx-migration-panel-panel [organisation_id]="organisation_id"
          [state]="organisationProfile.state"></app-org-pbx-migration-panel-panel>
      </ng-container>

      <!-- //shared Directories -->
      <app-org-directories-panel [organisation_id]="organisation_id" [viewAs]="viewAs"
        [is_pipxt_enabled]="organisationProfile?.is_pipxt_enabled"></app-org-directories-panel>

    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>