<form [formGroup]='organisationForm'>

  <mat-form-field appearance="outline" [ngClass]="{'readonly-block': disabled}" class="custom-form-field-v3">
    <input 
           matInput
           placeholder="Unassigned"
           aria-label="organisation"
           [matAutocomplete]="autoComplete"
           formControlName="userInputCtrl"
           [formControl]="userInputCtrl"
           #userInput 
           >
<mat-label>Organisation</mat-label>
<!-- [displayWith]="displayFn" -->
    <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option [disabled]=true *ngIf="isLoading" class="is-loading"><span>searching..</span></mat-option>
      <mat-option *ngFor="let org of filteredOrgs" [value]="org.name"  [id]="org.id">
        <span>{{org.name}}</span> | <small>{{org.id}}</small>
      </mat-option>
    </mat-autocomplete>

    <button *ngIf="!disabled" [disabled]="userInput.value===''" matSuffix tabindex="-1"  mat-icon-button
    aria-label="clear organisation" title="clear organisation"
    (click)="clearValues()">
    <mat-icon svgIcon="delete"></mat-icon></button>

  </mat-form-field>

</form>

