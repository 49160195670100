


<button class="admin-3-dot-menu"  [matMenuTriggerFor]="ddiMenu" [matMenuTriggerData]="{service_id: service_id, isLegacy: isLegacy}"  mat-icon-button><mat-icon svgIcon="three-dots-vertical"></mat-icon></button>

<mat-menu #ddiMenu="matMenu" class="v3-mat-menu" xPosition="before">

  <ng-template matMenuContent let-service_id="service_id" let-isLegacy="isLegacy"> 

    <button *ngIf="service_id.startsWith('00447') && pip_variant === 'pipmobile'"  mat-menu-item matTooltip="Convert an 07 DDI into an Extension. This will take you to the form" (click)="convertToMobilePlus(service_id)">
      <mat-icon svgIcon="open_in_new"></mat-icon>
      <span  class="matmenu-btn-label">Convert to Mobile+</span>
    </button>

    <button mat-menu-item matTooltip="Propagate DDI to telephony platform" (click)="propagateNumber(service_id)">
      <mat-icon svgIcon="propagate"></mat-icon>
      <span  class="matmenu-btn-label">Propagate Changes</span>
    </button>

    <hr class="divider">
    <button *ngIf="pip_variant === 'pipxt' && !isLegacy" mat-menu-item matTooltip="Remove the Extension settings and revert back to a PiPmobile DDI. This will also unlink the organisation, unless there is an existing user linked to the number." (click)="revertToPiPmobile(service_id)" class="red-item">
      <mat-icon svgIcon="delete"></mat-icon>
      <span  class="matmenu-btn-label">Revert to PiPmobile</span>
    </button>

    <!-- [disabled]="pip_variant === 'pipxt' && !isLegacy" -->
    <button mat-menu-item matTooltip="Permanently delete this number" (click)="deleteNumberConfirm(service_id)" class="red-item" >
      <mat-icon svgIcon="trash"></mat-icon>
      <span  class="matmenu-btn-label">Delete DDI</span>
    </button>

  </ng-template>
</mat-menu>

