import { AbstractControl } from '@angular/forms';
import { SignupService } from '@app/services/pipcall/signup.service';
import { Observable, of, timer } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import { map } from 'rxjs/operators';

// export class ValidateMobileNotTaken {
//     static createValidator(signupService: SignupService) {
//         return (control: AbstractControl) => {
//             return Observable.timer(500).switchMap(() => {
//                 let region = control.parent.value.region;
//                 return signupService.checkMobileNotTaken(control.value, region)
//                     .delay(500)
//                     .map(res => {
//                         return res.bool ? { mobileTaken: true } : null;
//                     })
//                     .catch(err=>Observable.of({mobileTaken: true}));
//             });
//         };
//     }
// }

//api has changed. will now return true if taken , false if not
export class ValidateMobileNotTaken {
    static createValidator(signupService: SignupService) {
        return (control: AbstractControl) => {
            // const region = control.parent.value.region;
            const region = 'uk44';
            const formatted = control.value.startsWith('44') ? control.value.replace('44', '+44') : control.value;
            return signupService.checkMobileNotTaken(formatted, region)
                .pipe(map(res => {
                    return res ? { mobileTaken: true } : null;
                })
                )
        };
    }
}

export class ValidateMobileNotTakenV2 {
    static createValidator(signupService: SignupService) {
        return (control: AbstractControl) => {
            let number = control.value.replace(/\s+/g, ''); // strip whitespace;

            //region==============================================================================
            let region = '0044'; //default region
            // Access the parent FormGroup to get the region formcontrol
            const parentFormControl = control.parent;
            const regionControl = parentFormControl.get('region');
            regionControl ? region = regionControl.value : null; //override default region if region control exists
            //==============================================================================

            //if number starts with 0, replace with region code, else append region code at start
            number = number.startsWith('0') ? number.replace('0', region) : region + number

            return signupService.checkMobileNotTakenv2(number)
                .pipe(map(res => {
                    return res ? { mobileTaken: true } : null;
                })
                )
        };
    }
}



export class ValidateBusinessNumberNotMobile {
    static createValidator(signupService: SignupService) {
        return (control: AbstractControl) => {

            // const region = control.parent.value.region;
            const region = 'uk44';
            const mobile = control.parent.value.mobile;
            const businessnumber = control.value;
            return signupService.checkBusinessNumberNotMobile(businessnumber, mobile, region)
                .pipe(map(res => {
                    console.log("RES coming back", res);
                    return res ? { mobileBusinessSame: true } : null;
                })
                )
        };
    }
}
