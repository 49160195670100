<div class="usr-panel-row" fxLayout="row wrap" fxLayoutAlign.xs="start center">

  <div class="left-wrapper" fxFlex="40%" fxLayout="row" fxLayoutAlign="start center">
    <div class="usr-avatar">
      <img appGravatar [firstname]="user.first_name" [lastname]="user.last_name" [email]="user.email">
    </div>
    <div class="usr-panel-left" fxLayout="column" fxLayoutAlign="center start">
      <span class="usr-panel-name">
        <ng-container *ngIf="user.type === 'User';else displayEmail">
          <span title="{{user.first_name}} {{user.last_name}}">{{user.first_name}} {{user.last_name}}</span>
        </ng-container>
        <ng-template #displayEmail>
          <!-- <span title="user.email" fxHide fxShow.xs>{{user.email | slice:0:25}}..</span>
          <span title="user.email" fxHide fxShow.sm>{{user.email | slice:0:15}}..</span> -->
          <span title="user.email">{{user.email}}</span>
        </ng-template>
      </span>

      <span class="usr-panel-number">
        <span *ngIf="user.type != 'Invitation'">{{user.number_info}}</span>
        <span *ngIf="user.type === 'Invitation'">Invitation sent</span><br>
        <div class="clickable" fxHide.gt-sm
          [ngClass]="{'text-licence-gradient' : selectedLicence?.licence_type_display?.includes('Text'), 'talk-licence-gradient' : selectedLicence?.licence_type_display?.includes('Talk') && !selectedLicence?.licence_type_display?.includes('Text')}"
          *ngIf="selectedLicence?.license_id" (click)="openPackageSelect(user?.is_mobile_license)">
          {{selectedLicence.licence_type_display}}<wbr> <span style="white-space: nowrap;">{{selectedLicence.display}} <mat-icon svgIcon="swap" class="icon-12" ></mat-icon></span></div>
          <div fxHide.gt-sm><button *ngIf="!selectedLicence?.license_id" [disabled]="disableForm" mat-stroked-button
              class="no-underline btn-sm" tabindex="-1" (click)="openPackageSelect(user?.is_mobile_license)">Select
              Licence</button></div>
      </span>

      <!-- <span class="usr-panel-info" [ngClass]="{'text-orange':user.is_mobile_license}">
        <ng-container *ngIf="user.is_mobile_license;else displayLandline">Talk & Text</ng-container>
        <ng-template #displayLandline>Talk</ng-template>
      </span> -->
    </div>
  </div>

  <div class="usr-panel-right" fxFlex fxLayoutAlign="end center" fxLayoutAlign.xs="center space-between">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start start">
      <div *ngIf="selectedLicence?.license_id" class="clickable" fxHide.lt-md
        [ngClass]="{'text-licence-gradient' : selectedLicence?.licence_type_display?.includes('Text'), 'talk-licence-gradient' : selectedLicence?.licence_type_display?.includes('Talk') && !selectedLicence?.licence_type_display?.includes('Text')}"
        (click)="openPackageSelect(user?.is_mobile_license)">
        {{selectedLicence.licence_type_display}}<wbr>,&nbsp;<span style="white-space: nowrap;">{{selectedLicence.display}} <mat-icon svgIcon="swap" class="icon-12"  ></mat-icon></span></div>
        <button fxHide.lt-md *ngIf="!selectedLicence?.license_id" [disabled]="disableForm" mat-stroked-button
          class="no-underline btn-sm" tabindex="-1" (click)="openPackageSelect(user?.is_mobile_license)">Select
          Licence</button>
      </div>

      <div class="usr-panel-cost" fxFlex="100px" fxLayoutAlign="center center" [class.active]="selectedLicenseId">
        <span class="display-currency">£</span>
        <span class="display-pound">{{licenseCostPounds}}</span>
        <span class="display-pence">{{licenseCostPence}}</span>
        <!-- £{{licenseCost | currency: 'GBP':'':'1.2-2'}} {{licenseCost | currency: 'GBP':'':'1.0-0'}} -->
      </div>


    </div>

  </div>
