import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { ExpoService } from '@app/services/pipcall/expo.service';
import { ExpoDashboardModal} from './dashboard-modal.component';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        CustomPipesModule,
        modalServiceModule
    ],
    declarations: [ExpoDashboardModal],
    exports: [ExpoDashboardModal],
    providers: [ExpoService],
    entryComponents: [ExpoDashboardModal]
})
export class ExpoDashboardModalModule { }
