<ng-container *ngIf="number_id || number; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">


      <div class="section-wrapper">
        <tab-title-lined>
          <ng-container *ngIf="number?.pip_variant=== 'pipmobile'">
            <div>Number</div>
          </ng-container>
          <ng-container *ngIf="number?.pip_variant=== 'pipxt'">
            <div>Extension</div>
          </ng-container>
        </tab-title-lined>

        <div class="section-content" fxLayout="column">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2">

              <s-grid-items-container>
                <s-grid-item [labelWidthpx]="120">
                  <div role="label">Service identifier:</div>
                  <div role="value">
                    {{number.number}}&nbsp;&nbsp;
                    <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number.number)"
                      class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                      matTooltip="Copy">
                    </mat-icon>
                  </div>
                </s-grid-item>
              </s-grid-items-container>


              <!-- <mat-form-field appearance="outline" floatLabel="always" class="custom-form-field-v3">
                <input autocomplete="off" type="text" matInput placeholder="" [value]="number.number" disabled>
                <mat-label>Service identifier</mat-label>
                <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number.number)"
                  class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy">
                </mat-icon>
              </mat-form-field> -->


            </div>

            <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%">

            </div>
          </div>

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px"
            *ngIf="number?.cli_override">

            <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%">

              <mat-form-field appearance="outline" floatLabel="always" class="custom-form-field-v3">
                <input autocomplete="off" type="text" matInput placeholder="" [value]="number?.cli_override" disabled>
                <mat-label>Temporary Number Port</mat-label>
              </mat-form-field>

            </div>

            <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%">

              During your trial this number will be set as the display for all outgoing calls. We can Port this number
              into PiPcall after you upgrade your account to active.

            </div>

          </div>


        </div>

      </div>


      <div class="section-wrapper">
        <tab-title-lined>



          <div fxLayout="row">Organisation&nbsp;
            <span style="font-size: 0.8em;"class="white-space:nowrap;" *ngIf="number?.organisation_id">&nbsp;({{number?.organisation_state}}<div
                [hidden]="number?.organisation_state !== 'Active'" class="status-icon">
                &nbsp;<mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
              </div>
              <div [hidden]="number?.organisation_state!== 'Trial'" class="status-icon">
                &nbsp;<mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
              </div>
              <div [hidden]="number?.organisation_state !== 'In Arrears'" class="status-icon">
                &nbsp;<mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
              </div>)


            </span>
          </div>


        </tab-title-lined>

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2">

              <ng-container *ngIf="number?.organisation_id; else noorganisationattached">

                <s-grid-items-container>
                  <s-grid-item [labelWidthpx]="90">
                    <div role="label">Id:</div>
                    <div role="value">
                      {{number?.organisation_id}}&nbsp;&nbsp;
                      <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number.organisation_id)"
                        class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                        matTooltip="Copy">
                      </mat-icon>
                    </div>
                  </s-grid-item>

                  <s-grid-item [labelWidthpx]="90">
                    <div role="label">Name:</div>
                    <div role="value">
                      <ng-container *ngIf="viewAs !=='sysAdmin'">{{number?.organisation_name}}</ng-container>
                      <ng-container *ngIf="viewAs ==='sysAdmin'"><a style="text-decoration: none;"
                          href="/admin/organisation/{{number?.organisation_id}}/profile"
                          matTooltip="open organisation">{{number?.organisation_name}}</a></ng-container>
                    </div>
                  </s-grid-item>
                </s-grid-items-container>

              </ng-container>

              <ng-template #noorganisationattached>
                <div>Number is not linked to an organisation</div>
              </ng-template>

            </div>

            <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1">

            </div>

          </div>
        </div>
      </div>




      <div class="section-wrapper">
        <tab-title-lined>

          <div>
            <ng-container *ngIf="!number?.user_id && number?.invitation_email; else usertitle">Invitation</ng-container>
            <ng-template #usertitle>User</ng-template>
          </div>
        </tab-title-lined>

        <div class="section-content">


          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2">

              <ng-container *ngIf="number?.user_id; else nouserattached">

                <s-grid-items-container>

                  <s-grid-item [labelWidthpx]="90">
                    <div role="label">Id:</div>
                    <div role="value">
                      {{number?.user_id}}&nbsp;&nbsp;
                      <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number.user_id)"
                        class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                        matTooltip="Copy">
                      </mat-icon>
                    </div>
                  </s-grid-item>

                  <s-grid-item [labelWidthpx]="90">
                    <div role="label">User Name:</div>
                    <div role="value">
                      <ng-container *ngIf="viewAs !=='sysAdmin'">{{number?.user_full_name}}</ng-container>
                      <ng-container *ngIf="viewAs ==='sysAdmin'"><a style="text-decoration: none;"
                          href="/admin/user/{{number?.user_id}}/profile"
                          matTooltip="open user">{{number?.user_full_name}}</a>
                        </ng-container>
                    </div> 
                  </s-grid-item>

                  <s-grid-item  *ngIf="number?.pip_variant === 'pipxt' && number?.user_id && (viewAs === 'sysAdmin' || viewAs === 'isAdmin')">
                    <div role="value">
                    <button matTooltip="Unlink the user" (click)="user_unlink(number?.number)" mat-flat-button class="delete-button-2">Unlink</button> 
                    </div>
                  </s-grid-item>
                  
                </s-grid-items-container>




              </ng-container>

              <ng-template #nouserattached>

                <ng-container *ngIf="number?.invitation_email; else noInvite">
                  <s-grid-items-container>
                  <s-grid-item>
                    <div role="value">A pending invitation
                      has been linked this number.</div>
                  </s-grid-item>
                    <s-grid-item [labelWidthpx]="90">
                      <div role="label">Invitation:</div>
                      <div role="value">
                        {{number?.invitation_email}}&nbsp;&nbsp;
                      </div>
                    </s-grid-item>

                    <s-grid-item>
                      <div role="value">
                        <button matTooltip="Revoke the invitation"
                        *ngIf="number?.invitation_email && (viewAs === 'sysAdmin' || viewAs === 'isAdmin')"
                        (click)="alertRevokeInvitation(number?.invitation_id)" mat-flat-button
                        class="delete-button-2">Revoke</button>
                      </div>
                    </s-grid-item>
                  </s-grid-items-container>

                </ng-container>

                <ng-template #noInvite>
                  <div style="margin-bottom: 10px;">Number is not linked to a user</div>
                  <ng-container *ngIf="!number?.user_id && !number?.invitation_email">
                    <div><button [matMenuTriggerData]="{number: number}" [matMenuTriggerFor]="addUser"
                        [disabled]="number?.sip_host_name== null"
                        *ngIf="number?.pip_variant === 'pipxt' && !number?.user_id && (viewAs === 'sysAdmin' || viewAs === 'isAdmin')"
                        mat-flat-button class="allocation-control-button blue">Link</button></div>
                  </ng-container>
                </ng-template>


              </ng-template>

            </div>



          </div>
        </div>
      </div>


    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>



<mat-menu #addUser="matMenu" class="v3-mat-menu" xPosition="before">

  <ng-template matMenuContent let-number="number">
    <button mat-menu-item title="Invite a user to be assigned number" (click)="openInviteModal(number)">
      <mat-icon>person_add</mat-icon>
      <span>Invite New User</span>
    </button>

    <button mat-menu-item title="Link with existing user" [matMenuTriggerFor]="availableUsers"
      [matMenuTriggerData]="{number: number}" *ngIf="filteredList?.length > 0">
      <mat-icon>swap_horiz</mat-icon>
      <span>Link Existing</span>
    </button>


  </ng-template>
</mat-menu>

<mat-menu #availableUsers="matMenu" class="v3-mat-menu">

  <ng-template matMenuContent let-number="number">
    <ng-container *ngIf="filteredList?.length === 0 || !filteredList; else showList">
      <div mat-menu-item>There are no available users</div>
    </ng-container>

    <ng-template #showList>
      <ng-container *ngFor="let user of filteredList">
        <button mat-menu-item title="Link number with user"
          (click)="assignToUser(number, user.id, user.first_name, number.organisation_id)">
          <span>{{user.first_name}} {{user.last_name}}</span>
        </button>
      </ng-container>
    </ng-template>

  </ng-template>
</mat-menu>