import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef }  from '@app/material/material-essentials.module';


@Component({
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

constructor(){

}

ngOnInit(){

}


}