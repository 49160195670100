import {Component, TemplateRef, ViewChild, Inject} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { UserProfile } from '@app/models/user-profile.model';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { OrganisationModel } from '@app/models/organisation.model';
import { NumberService } from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';
import { OrganisationService} from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject, of } from 'rxjs';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
import { MatSnackBar } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';


import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';

import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'bs-ddi-bsheet-modal',
    templateUrl: './ddi-bsheet-modal.component.html',
    styleUrls: ['./ddi-bsheet-modal.component.scss']
})
export class DDIBottomSheetComponent {


    public viewAs: string; //owner,admin or user. set to owner if matches on return of data.
    public isloading = true;
    public notFound: boolean = null;

    public number: DdiNumber;
    public organisation_id: string;
    public number_id: string;
    public itemUrl = window.location.origin;


    public pageStatus = {
        activeTab: 'overview',
        isLoading: true,
        noContent: false,
        dataChanged: false,
        isSubmitting: false
    }
    _destroy$ = new Subject<void>();

    public availableUserList:  UserProfile[]; //Filtered to users who have no existing number


    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {viewAs: string, organisation_id: string, number: DdiNumber, number_id: string },
        private _bottomSheetRef: MatBottomSheetRef<DDIBottomSheetComponent>,
        private numberService: NumberService,
        private organisationService: OrganisationService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private staticDataService: StaticDataService,
        private modalService: ModalService
    ) {

        console.log("DATA:", this.data);
        this.availableUserList = [];
        this.viewAs = data?.viewAs;
        this.number = data?.number;
        this.organisation_id = data?.organisation_id;
        this.number_id = data?.number_id
        this.itemUrl = window.location.origin + '/admin/ddi/' + this.number_id;
    }

    ngOnInit() {
        // handle missing data
        this.staticDataService.innit();

        this.pageStatus.isLoading = true;

        if (!this.number) {
            console.log("NO number PROFILE . GO AND GET IT.");
            this.getData();
        } else {
            this.pageStatus.isLoading = false; }

        if (this.organisation_id) {
            this.organisationService.getOrgUsersById(this.organisation_id)
                .subscribe((resp) => {
                    if ( resp.status === 200) {
                        this.availableUserList = resp?.body?.user_list;
                        this.ref.detectChanges();
                    }

                },
                (err) => {console.log(`Error getting user details [${err.status}]`)})
        } else {
            this.availableUserList = [];
        }
    }



    getData() {
        if (!this.number_id) {this.pageStatus.noContent = true; this.pageStatus.isLoading = false; return; }

        console.log("GET DATA NOW");
        if (this.viewAs === 'sysAdmin') {
            this.numberService.getDdiById_v2(this.number_id)
                .pipe(
                    finalize(() => { this.pageStatus.isLoading = false; }),
                    takeUntil(this._destroy$),
                )
                .switchMap((resp) => {
                    this.number = resp.body?.data[0];
                    if (!this.number) { this.pageStatus.noContent = true; this.closeParentModal(true); return throwError("No DDI"); }
                    this.pageStatus.noContent = false;
                    return this.number?.organisation_id ? this.organisationService.getOrgUsersById(this.number?.organisation_id) : of(null);
                })
                .subscribe((resp) => {
                    console.log("ddi bottomsheet - getData switchmap resp:", resp);
                    console.log("[ddi profile] getavailableUsers():", resp)
                    this.availableUserList = resp !== null ?  resp?.body?.user_list : [];
                    this.pageStatus.isLoading = false;
                    this.ref.detectChanges();
                    // if ( resp.status === 200) {
                    //     this.availableUserList = resp?.body?.user_list;
                    //     this.ref.detectChanges();
                    //     }
                },
                (err) => {console.log(`Error getting user details [${err.status}]`)})
        } else {
            this.numberService.getDdiById(this.number_id)
                .pipe(
                    finalize(() => { this.pageStatus.isLoading = false; }),
                    takeUntil(this._destroy$),
                )
                .switchMap((resp) => {
                    this.number = resp.body;
                    this.pageStatus.noContent = false;
                    return this.number?.organisation_id ? this.organisationService.getOrgUsersById(this.number?.organisation_id) : of(null);
                })
                .subscribe((resp) => {
                    console.log("ddi bottomsheet - getData switchmap resp:", resp);
                    console.log("[ddi profile] getavailableUsers():", resp)
                    this.availableUserList = resp !== null ?  resp?.body?.user_list : [];
                    this.pageStatus.isLoading = false;
                    this.ref.detectChanges();
                // if ( resp.status === 200) {
                //     this.availableUserList = resp?.body?.user_list;
                //     this.ref.detectChanges();
                //     }
                },
                (err) => {console.log(`Error getting user details [${err.status}]`)})
        }
    }

    openProfileURL(id: string) {
        this._bottomSheetRef.dismiss();
        this.router.navigateByUrl('/admin/ddi/' + id );
    }

    closeParentModal(event) {
        if (event) {
            this.pageStatus.dataChanged = true;
            this.dismiss();
        }

    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    refreshData(event?) {
        console.log("refresh data EVENT:", event);
        this.getData();
        this.pageStatus.dataChanged = true;
        this.ref.detectChanges();
    }


    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}` , "dismiss");
    }

    dismiss() {
        this._bottomSheetRef.dismiss(this.pageStatus.dataChanged);
    }
}

