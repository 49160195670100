<div class="bottom-sheet-content-container">

  <div class="close-button-wrapper">

    <ng-container *ngIf="viewAs==='sysAdmin'">

      <div class="go-url clickable" fxLayout="row" fxLayoutAlign="center center">
        <span [matTooltip]="userUrl" (click)="copyInputToClipboard(userUrl)">{{userUrl}}</span>
        <button matTooltip="open url" mat-flat-button class="open-in-button" mat-button
          (click)="openProfileURL(user_id)"> <mat-icon style="color:var(--c-title)"
            svgIcon="open_in_new"></mat-icon></button>
      </div>


    </ng-container>

    <button class="bs-toolbar-close-bt" mat-flat-button color="primary" (click)="dismiss()" mat-button>Close</button>
  </div>

  <div class="page-container">
    <div class="m-content-container bottom-sheet-container">

      <div class="user-view-avatar-container">
        
        <div class="usr-avatar-68 clickable" style="position: relative;"  (click)="changeProfilePicture()">
          <div class="edit-me-on-hover">
            <p style="text-align: center;padding-top: 26px;color:white;font-size: inherit;">Change</p>
          </div>
          <img appGravatar [firstname]="userProfile?.first_name" [lastname]="userProfile?.last_name"
            [email]="userProfile?.email">
        </div>
        
        
        <div class="content-page-title-wrapper">
          <h1 (click)="copyInputToClipboard(userProfile?.id)" matTooltip="Copy to clipboard">{{userProfile?.id}}</h1>
          <h2 style="margin-bottom:0px;">{{userProfile?.first_name}} {{userProfile?.last_name}}</h2>
        </div>
        
      </div>

      <div class="content-body">


        <div fxFlex="100%">
          <div class="tabs-header no-overflow" *ngIf="viewAs === 'sysAdmin'">


            <div [ngClass]="{'active':  pageStatus.activeTab === 'system' }" (click)="pageStatus.activeTab = 'system'">
              System</div>

            <div [ngClass]="{'active':  pageStatus.activeTab ==='overview' }"
              (click)="pageStatus.activeTab = 'overview'">Overview</div>

            <matmenu-user-admin-controls *ngIf="viewAs==='sysAdmin'" [user_id]="user_id"
              [organisation_id]="organisation_id ? organisation_id : null"
              [organisation_state]="organisationProfile?.state ? organisationProfile?.state  : null"
              [owner_user_id]="organisationProfile?.owner_user_id ? organisationProfile?.owner_user_id  : null"
              [license_id]="userProfile?.organisation_list[0]?.license_id ? userProfile?.organisation_list[0]?.license_id  : null"
              (_refreshData)="refreshData()" (closeModal)="dismiss()"></matmenu-user-admin-controls>
          </div>


          <div class="m-content-body-wrapper">

            <ng-container *ngIf="!this.pageStatus.isLoading && !this.pageStatus.noContent; else loading">

              <ng-container *ngIf="pageStatus.activeTab === 'overview'">
                <app-user-profile-tabs-overview [organisation_id]="organisation_id" [userProfile]="userProfile"
                  [user_id]="user_id" [viewAs]="viewAs" [organisationProfile]="organisationProfile"
                  (updateParent)="refreshData($event)"></app-user-profile-tabs-overview>
              </ng-container>

              <ng-container *ngIf="pageStatus.activeTab === 'system'">
                <app-user-profile-tabs-system *ngIf="viewAs === 'sysAdmin'" [organisation_id]="organisation_id"
                  [userProfile]="userProfile" [user_id]="user_id" [viewAs]="viewAs"
                  [organisationProfile]="organisationProfile" (updateParent)="refreshData($event)"
                  (updateParentModal)="updateParentEvent($event)"></app-user-profile-tabs-system>

              </ng-container>


            </ng-container>

            <ng-container *ngIf="!this.pageStatus.isLoading && this.pageStatus.noContent">
              No user found
            </ng-container>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div fxLayoutAlign="center center">
    <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </div>
</ng-template>