//core
import { Component, OnInit, Input, isDevMode, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';

//form essentials
import { FormControl } from '@angular/forms';
import {MatSnackBar} from '@app/material/material-essentials.module';
import { Observable, Subscription, throwError, from, } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OrganisationModel } from '@app/models/organisation.model';
import { DdiNumber } from '@app/models/ddi.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

@Component({
    selector: 'app-ff-org-pipnumber-select',
    templateUrl: './ff-org-pipnumber-select.component.html',
    styleUrls: ['./ff-org-pipnumber-select.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class OrgPipnumberSelectComponent {
    @Input() organisationId: string;
    @Output() _selectedPipnumber = new EventEmitter();


    public pipnumbers: DdiNumber[];
    public numberListSubscription: Subscription;
    public selectedPipnumber: string;
    public loadingPiPnumbers = true;

    constructor(
        private organisationService: OrganisationService
    ) {


    }

    ngOnInit() {
        this.loadingPiPnumbers = true;
        if (this.organisationId) {
            this.getOrganisationDdiList(this.organisationId);
        }
    }

    getOrganisationDdiList(id: string) {
        //return number list
        console.log('[ff-org-pipnumber].getOrganisationDdiList()', id);
        this.numberListSubscription = this.organisationService.getDdiListByOrganisation(id)
            .subscribe((resp => {
                console.log('[ff-org-pipnumber].getOrganisationDdiList() RESPONSE', resp);
                if (resp.status === 200) {
                    // resp.body?.ddi_list

                    if (resp.body?.ddi_list === null || resp.body?.ddi_list.length === 0 || resp.body === null ) {
                        this.pipnumbers = [];
                    } else {
                        this.pipnumbers =  resp.body?.ddi_list.filter(item => item.user_id === null && item.invitation_id === null);
                    }

                    console.log("result",  this.pipnumbers);
                }
                this.loadingPiPnumbers = false;
            }), (err) => {
                this.loadingPiPnumbers = false;
                console.log(err);
            });
    }

    emitValue(event) {
        this._selectedPipnumber.emit(event.value);
    }

    ngOnDestroy() {
        // this.user_subscription.unsubscribe();
        this.numberListSubscription ? this.numberListSubscription.unsubscribe() : null;
    }

}
