import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { UserService } from '@app/services/pipcall/user.service';
import { NumberService } from '@app/services/pipcall/number.service';

//component
import { OrgTabUserslisComponent } from './org-users-list.component'

//modules
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { OrgTabInvitationPanelModule} from './org-users-invitation-panel/org-users-invitation-panel.module';

// bottom sheet
import { UserProfileBottomSheetModule} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.module';

// import { ConfirmRemoveModalModule } from './confirm-remove/confirm-remove-modal.module';
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';
import { MdePopoverModule } from '@material-extended/mde';


//modal
import { AdminUpgradeToPipuserModulelModule } from '@app/components/modals/admin-upgrade-to-pipuser-modal/admin-upgrade-to-pipuser-modal.module';
import { AdminExtensionInvitationModule } from '@app/components/modals/admin-extension-invitation-modal/admin-extension-invitation-modal.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        GravatarDirectiveModule,
        OrgTabInvitationPanelModule,
        UserProfileBottomSheetModule,
        modalServiceModule,
        MdePopoverModule,
        AdminUpgradeToPipuserModulelModule,
        AdminExtensionInvitationModule,
        UniversalComponentsModule
    ],
    declarations: [OrgTabUserslisComponent],
    exports: [OrgTabUserslisComponent],
    providers: [AppInsightService, OrganisationService, UserService, NumberService],
    entryComponents: []
})
export class OrgTabUserslistModule { }
