<h5 class="title" mat-dialog-title>Remove user: confirm ddi action</h5>
<mat-dialog-content>
  <div style="margin-bottom: 10px;">
    
    <label>Please confirm what should happen to any numbers assigned to this user.</label>
    <mat-radio-group class="radio-group"[(ngModel)]="ddi_action" >
      
      
      <mat-radio-button value="return" class="radio-button" >
        Return number to the PiPcall pool [recommended]
      </mat-radio-button>
      
      <mat-radio-button value="keep" class="radio-button">
        Keep number in the organisation 
      </mat-radio-button>
      
    </mat-radio-group>
    
  </div>

  <div *ngIf="data?.contractException">
   <i style="color:var(--c-error);">Warning: This user has a yearly contract, paid monthly. If you continue then the remaining payments will not be collected. Make sure that this is what you want to do. </i>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="closeDialog(false)"  >
    Cancel
  </button>

  <button class="red-delete" style="color:white;" mat-raised-button (click)="closeDialog(true)" tabindex="-1" [disabled]="ddi_action === null">
    Remove User
  </button>


</mat-dialog-actions>
