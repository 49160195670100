//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { switchMap, debounceTime, tap, finalize, takeUntil, map, filter } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray, MaxLengthValidator } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";

import { NumberService } from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';

@Component({
    selector: 'panel-extension-config-legacy',
    templateUrl: './extension-config-panel-legacy.component.html',
    styleUrls: ['./extension-config-panel-legacy.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class ExtensionsConfigPanelComponent {

    @Input() viewAs = 'user' //owner,admin or user. set to owner if matches on return of data.
    @Input() number: DdiNumber;

    @Output() updateParent = new EventEmitter();

    pageStatus = {
        isSubmitting: false,
        isLoading: true
    }

    ddiUpdate: FormGroup;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _fb: FormBuilder,
        private numberService: NumberService,
        private ref: ChangeDetectorRef,
    ) {
        const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');
        this.ddiUpdate = this._fb.group({
            sip_extension_number: ['', Validators.required],
            sip_extension_cli: ['', Validators.required],
            sip_user_name: ['', Validators.required],
            sip_password: ['', Validators.maxLength(30)],
            sip_host_name: ['', Validators.required],
            sip_voicemail_number: [''],
            label: ['']
        });


    }

    get f() { return this.ddiUpdate.controls; } //convinience getter for easy access to form fields

    ngOnInit() {
        console.log("extension config====..........==============", this.number)
    }

    ngOnChanges(changes: SimpleChanges) {
        (changes['number']?.currentValue !== changes['number']?.previousValue) ?  this.setFormValues(this.number) : null;
    }


    setFormValues(number: DdiNumber) {
        console.log("setFormValues====..........==============", number)
        this.setFormValue(this.ddiUpdate, 'sip_extension_number', number.sip_extension_number);
        this.setFormValue(this.ddiUpdate, 'sip_extension_cli', number.sip_extension_cli);
        this.setFormValue(this.ddiUpdate, 'sip_user_name', number.sip_user_name);
        this.setFormValue(this.ddiUpdate, 'sip_password', number.sip_password);
        this.setFormValue(this.ddiUpdate, 'sip_host_name', number.sip_host_name);
        this.setFormValue(this.ddiUpdate, 'sip_voicemail_number', number.sip_voicemail_number);
        this.setFormValue(this.ddiUpdate, 'label', number.label);
    }
    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    saveExtension(f: FormGroup) {
        console.log("SAVE FORM:", f);

        if (f.valid) {
            return this.submitForm(this.numberService.patchPipxtUpdate(this.number?.number, f.value));
        }
    }


    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.markForCheck()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    cancelForm() {
        this.setFormValues(this.number);
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
