import { Injectable } from '@angular/core';
import { FormatDate } from '@app/pipes/formatDate';



export class QueryVals {
    unit: string;
    days: string;
    toDate: string;
}

export class ToDate {
    value: Date;
    display: string;
}

export class FromDate {
    value: Date;
    display: string;
}

export class QueryParameters {
    query: QueryVals;
    toDate: ToDate;
    fromDate: FromDate;
}

@Injectable()
export class ToolbarDatesService {

    public q: QueryParameters;

    constructor() {
        this.q = new QueryParameters();
        this.q.query = new QueryVals();
        this.q.query = new QueryVals();
        this.q.toDate = new ToDate();
        this.q.fromDate = new FromDate();
    }

    public returnDefaultQueryParameters(noOfDays?: number): QueryParameters {


        //datetoolbar value
        const today = new Date();
        this.q.toDate.value = today; //set default starting value as today

        const _today = new Date();

        let _noOfdays = -30; //default to -30 days

        if (noOfDays === -1 || noOfDays === 0) {
            // override if value provdied by api
            _noOfdays = 0;
        }

        this.q.fromDate.value = new Date(_today.setDate(_today.getDate() + _noOfdays)); //set default start date as either noOfdays or -30 days ago

        //set query
        this.q.query.unit = 'day';
        this.q.query.days = '0';
        this.q.query.toDate = new FormatDate().transform(this.q.toDate.value, 1); //add 1 day

        //update days
        this.q.query.days = this.daysBetweenDates(this.q.fromDate.value, this.q.toDate.value);

        //set display values for toolbar button
        this.q.fromDate.display = new FormatDate().transform(this.q.fromDate.value);
        this.q.toDate.display = new FormatDate().transform(this.q.toDate.value);

        //handle max date on return
        return this.q;
    }

    public setFromDate(e): QueryParameters {
        this.q.fromDate.value = e; //set value
        this.q.fromDate.display = new FormatDate().transform(e); //set form display
        this.q.query.days = this.daysBetweenDates(this.q.fromDate.value, this.q.toDate.value);
        return this.q;
    }

    public setToDate(e): QueryParameters {
        this.q.query.toDate = new FormatDate().transform(e, 1); //minus 1 day for Query
        this.q.toDate.value = e; //set value
        this.q.toDate.display = new FormatDate().transform(e); //set form display
        this.q.query.days = this.daysBetweenDates(this.q.fromDate.value, this.q.toDate.value);
        return this.q;
    }


    public daysBetweenDates(from, to) {
        const date1 = new Date(from);
        const date2 = new Date(to);
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = -(Math.ceil(timeDiff / (1000 * 3600 * 24)));

        this.q.query.days = diffDays === 0 ? '-1' : diffDays.toString() // set Q
        return diffDays === 0 ? '-1' : diffDays.toString(); //never return 0
    }

    public returnQValue(): QueryParameters {
        return this.q;
    }
}
