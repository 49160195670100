//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";

import { UserProfile } from '@app/models/user-profile.model';
import { UserService } from '@app/services/pipcall/user.service';
import { NumberService } from '@app/services/pipcall/number.service';


import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';

import { LicenseModel, Licensedata } from '@app/models/license.model';
import { PaymentsService, } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { OrgUserDdiList } from '@app/models/user-profile.model';
// import { ddiListModel } from '@app/components/user-profile-deprecated/user-profile.component';

@Component({
    selector: 'app-user-profile-tabs-usage',
    templateUrl: './user-profile-usage.component.html',
    styleUrls: ['./user-profile-usage.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class UserProfileTabUsageComponent {


    @Input() viewAs: string;
    @Input() userProfile: UserProfile;
    @Input() user_id: string; //require either userID or profile
    @Input() organisation_id: string; //required
    @Input() organisationProfile: OrganisationModel; //required

    @Output() updateParent = new EventEmitter();
    @Output() updateParentModal = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();
    public sharedDataSubscription: Subscription;

    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }

    // public organisationProfile: OrganisationModel;

    //controls for usage
    selectedPiPmobileNumber: string = null;
    usersPiPmobileList: OrgUserDdiList[];

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private userService: UserService,
        private signupService: SignupService,
        private normalizeNumberService: NormalizeNumberService,
        private numberService: NumberService,
        private ref: ChangeDetectorRef,
        private paymentsService: PaymentsService,
        private licenseDataService: LicenseDataService,
        private modalService: ModalService
    ) {

        this.organisationProfile = new OrganisationModel();

    }

    ngOnChanges(changes: SimpleChanges) {
        // (changes['userProfile']?.currentValue !== changes['userProfile']?.previousValue) &&  !changes['userProfile']?.isFirstChange() ?  this.setUserFormValues(this.userProfile) : null;
        (changes['userProfile']?.currentValue !== changes['userProfile']?.previousValue)  ?  this.setUserFormValues(this.userProfile) : null;
    }



    ngOnInit() {

        if (this.userProfile) {
            // found a profile from parent
            // console.log("THERE IS A IUSER PROFILE FOUND", this.userProfile);
            this.pageStatus.isLoading = false;
            // this.setUserFormValues(this.userProfile);
        }

        if ( !this.userProfile && this.user_id ) {
            // no profile from parent. get it now
            this.getDetails();
        }

        if ( !this.userProfile && !this.user_id ) {
            //No profile or ID from parent. throw error.
            this.noContent = true;
            this.pageStatus.isLoading = false;
            this.openSnackBar("There was an error geting user details", "dismiss");
        }


    }

    setUserFormValues(userProfile: UserProfile) {
        console.log("====FIRE");

        //set usage numnbers. filter out pipoffice
        this.usersPiPmobileList = new Array<OrgUserDdiList>();

        userProfile?.organisation_list[0]?.ddi_list.forEach(nm => {
            if (nm?.number !== null && nm?.pip_variant === 'pipmobile') {
                this.usersPiPmobileList.push(nm);
            }
        });
        console.log("====list",   this.usersPiPmobileList);
        this.selectedPiPmobileNumber =  this.usersPiPmobileList?.length > 0 ? this.usersPiPmobileList[0]?.number : null;
        console.log("====selected",   this.selectedPiPmobileNumber);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }


    getDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;

        this.userService.getUser(this.user_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                if (resp.status === 200 ) {
                    this.userProfile = resp.body;
                    this.setUserFormValues(this.userProfile);
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting user details [${err.status}] `, "dismiss");
            })

    }



    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.markForCheck()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
