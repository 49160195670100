import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '@app/models/response.model';


@Injectable()
export class LicenseService {
    public ApiBaseUrl: string;
    public codeV2 = `?code=${environment.apicode}`
    public options;

    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    public getUserLicenses(baseDate: string, noOfdays: string): Observable<responseModel> {
        console.log("[[api]] -> get user licenses");
        return this.httpProxy.get(this.ApiBaseUrl + `/licenses/from/${baseDate}/to/day/${noOfdays}` + this.codeV2, 'response', true);
    }

    public getOrganisationLicenses(orgId: string): Observable<responseModel> {
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${orgId}/licenses${this.codeV2}`, 'response', true);
    }


    public patchLicenceRenewalDate(userId: string, organisationId: string, licenceId: string, allow_renewal: boolean, next_renewal_date: string) {
        console.log("[[api]] -> patch license renewal date");
        const body = {
            "allow_renewal" : allow_renewal,
            "next_renewal_date" : next_renewal_date
        };
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${organisationId}/users/${userId}/licenses/${licenceId}/renewal${this.codeV2}`, body, 'response')
    }
}
