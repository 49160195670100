import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';


//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { NumberService } from '@app/services/pipcall/number.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { PBXService } from '@app/services/pipcall/pbx.service';
//declarations
import { DDIAdminControlsComponent } from './ddi-admin-controls.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FlexLayoutModule,
        FormBasicControlsModule,
    ],
    declarations: [DDIAdminControlsComponent],
    providers: [HttpProxy, AppInsightService, AppTitleService, NumberService, ModalService, PBXService],
    exports: [DDIAdminControlsComponent],
    entryComponents: [DDIAdminControlsComponent]
})
export class DDIAdminControlsModule {
}
