// core
import { Component, ViewChild, OnInit, Inject, isDevMode, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
// form essentials
import { FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';

// services

// normalise number
import { NormalizeTo00 } from '@app/pipes/formatNumber';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { RegionCodes, NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { FormatDate } from '@app/pipes/formatDate';
import { SignupService } from '@app/services/pipcall/signup.service';
import { fadeInUpOnEnterAnimation } from 'angular-animations';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { NewOrganisation} from '@app/services/pipcall/organisation.service';
import { OrganisationService} from '@app/services/pipcall/organisation.service';

import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
@Component({
    selector: 'create-organisation-modal',
    templateUrl: './create-organisation-modal.component.html',
    styleUrls: ['./create-organisation-modal.component.scss'],
    animations: [
        fadeInUpOnEnterAnimation({ anchor: 'enter' })
    ]
})
export class CreateOrganisationModalComponent {

    //essentials
    public isDev: boolean;
    public submitted = false;
    public createOrgForm: FormGroup;

    public pageStatus = {
        isLoading: true,
        isSubmitting: false,
        isSubmitted: false,
        isSuccess: false,
        viewTab: 1
    }

    // trialdate
    public today = new Date();
    public newOrganisation_id = '';

    constructor(
        private dialogRef: MatDialogRef<CreateOrganisationModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: any, //contains data injected into modal.
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private router: Router,
        private normalizeTo00: NormalizeTo00,
        private signupService: SignupService,
        private organisationService: OrganisationService
    ) {
        this.isDev = isDevMode();

        this.createOrgForm = this._formBuilder.group({
            organisation_details: this._formBuilder.group({
                name: ['', Validators.required],
                contact_phone_number: ['44', [Validators.required, Validators.pattern('^([0-9]{12})$')]],
                trial_end_date: ['', Validators.required],
                is_pipxt_enabled: [true, Validators.required],
                is_pipmobile_enabled: [false, Validators.required]
            }),
            admin_details: this._formBuilder.group({
                admin_first_name: ['', [Validators.required, Validators.pattern(`^[a-zA-Z]+((['-][a-zA-Z ])?[a-zA-Z ]*)*$`)]],
                admin_last_name: ['', [Validators.required, Validators.pattern(`^[a-zA-Z]+((['-][a-zA-Z ])?[a-zA-Z ]*)*$`)]],
                admin_email: ['', [Validators.email, Validators.required], [ValidateEmailNotTaken.createValidator(this.signupService)]],
                admin_mobile: [{value: '44', disabled: false}, [Validators.required, Validators.pattern('^([0-9]{12})$')], [ValidateMobileNotTaken.createValidator(this.signupService)]],
            }),
            fee_details: this._formBuilder.group({
                initial_set_up_fee: [null, Validators.required],
                monthly_service_charge: [null, Validators.required]
            }),
            is_pip_user: [true]

        });

        this.pageStatus.viewTab = 1;



    }
    ngOnInit() {

        // console.log("FORM:", this.createOrgForm.value);
        setTimeout(() => {
            this.pageStatus.isLoading = false;
        }, 3000);

    }

    nextTab() {
        if (this.pageStatus.viewTab < 4) {
            this.pageStatus.viewTab++;
        }
    }
    prevTab() {
        if (this.pageStatus.viewTab > 1) {
            this.pageStatus.viewTab--;
        }
    }

    checkContinueIsValid(): boolean {
        if (this.pageStatus.viewTab === 1 && this.createOrgForm.controls.organisation_details.valid) {return true};
        if (this.pageStatus.viewTab === 2 && this.createOrgForm.controls.admin_details.valid) {return true};
        if (this.pageStatus.viewTab === 3 && (this.createOrgForm.controls.fee_details.valid || this.createOrgForm.controls.fee_details.status === 'DISABLED')) {
            return true
        };
        return false;
    }

    updateFeeAmount(control: string, event) {

        this.createOrgForm.controls.fee_details.get(control).patchValue(event.target.value, {emitEvent: false} )

    }

    isPiPuser(event) {
        console.log(event);
        if (event.checked === true) {
            this.createOrgForm.controls.is_pip_user.patchValue(true);
            this.createOrgForm.controls.admin_details.get('admin_mobile').enable();
        } else {
            this.createOrgForm.controls.admin_details.get('admin_mobile').disable();
            this.createOrgForm.controls.is_pip_user.patchValue(false);
            this.createOrgForm.controls.admin_details.get("admin_mobile").patchValue('');
        }
    }
    trialdDateValueChange(val): void {
        //datepicker value changed
        const formatedDate = new FormatDate().transform(val);
        this.createOrgForm.controls.organisation_details.get('trial_end_date').patchValue(formatedDate);
        this.createOrgForm.controls.organisation_details.get('trial_end_date').updateValueAndValidity();
        this.createOrgForm.controls.organisation_details.get('trial_end_date').markAsDirty();
    }

    submitForm() {
        this.pageStatus.isSubmitting = true;
        console.log("SUBMIT FORM:", this.createOrgForm.value);
        const f = this.createOrgForm.value;
        // this.pageStatus.isSubmitting = true;
        if (!this.createOrgForm.valid) {
            this.openSnackBar("form is not valid", "dismiss");
            return; }

        const body: NewOrganisation = {
            name: f.organisation_details.name.trim(),
            contact_phone_number: f.organisation_details.contact_phone_number ? '+' + f.organisation_details.contact_phone_number : '',
            trial_end_date: f.organisation_details.trial_end_date,
            is_pipxt_enabled: f.organisation_details.is_pipxt_enabled,
            is_pipmobile_enabled: f.organisation_details.is_pipmobile_enabled,
            admin_first_name: f.admin_details.admin_first_name.trim(),
            admin_last_name: f.admin_details.admin_last_name.trim(),
            admin_email: f.admin_details.admin_email,
            admin_mobile: f.admin_details?.admin_mobile ?  f.admin_details?.admin_mobile.replace('44', '0044') : '',
            initial_set_up_fee: f?.fee_details?.initial_set_up_fee ? f?.fee_details.initial_set_up_fee * 100 : 0 ,
            monthly_service_charge: f?.fee_details?.monthly_service_charge ? f?.fee_details.monthly_service_charge * 100 : 0
        }


        console.log("NEW Organisation", body);

        this.organisationService.postCreateNewOrganisation(body)
            .pipe(
                tap(() => this.pageStatus.isSubmitting = true),
                finalize(() => this.pageStatus.isSubmitting = false)
            )
            .subscribe((resp) => {
                this.pageStatus.isSuccess = true;
                this.pageStatus.viewTab = 5;
                this.newOrganisation_id = resp?.body.organisation_id;
            },
            (err) => {
                console.log(err);
                this.pageStatus.isSubmitting = true;
                this.openSnackBar(`Error: [${err?.status}, ${err?.message}]`, 'Dismiss');
            })

        //handle error route
    }

    closeModal(dataChanged?: boolean) {
        this.dialogRef.close(dataChanged);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    scrollEvent(event) {
        const el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop !== el.clientHeight ? el.classList.add('pip-scroll-shadow-after') : el.classList.remove('pip-scroll-shadow-after');
    }

    ngOnDestroy() {

    }

    disableFeeEvent (event) {
        console.log(event.checked);
        if ( event.checked === true) {
            this.createOrgForm.controls.fee_details.disable();
            this.createOrgForm.controls.fee_details.reset();
        } else {
            this.createOrgForm.controls.fee_details.enable();
        }
    }

    debug() {
        console.log(this.createOrgForm);

        console.log("values", this.createOrgForm.value);
    }

}
