import { Component, Input } from '@angular/core';
import { collapseAnimation } from 'angular-animations';

@Component({
    selector: 's-grid-items-container',
    templateUrl: './s-grid-items-container.component.html',
    styleUrls: ['./s-grid-items-container.component.scss'],
    animations: [
        collapseAnimation()
    ]
})
export class SGridItemsContainerComponent {

}
