import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ShorthandPropertyAssignment } from 'typescript';

export class ModalConfirmData {
    title: string;
    content: string;
    confirmButtonLabel: string;
    closeButtonLabel: string;
    choice: boolean;
    disableClose: boolean;
    customClass?: string;

    constructor(data?) {
        if (data) {
            this.title = data?.title;
            this.content = data?.content;
            this.confirmButtonLabel = data?.confirmButtonLabel;
            this.closeButtonLabel = data?.closeButtonLabel;
            this.choice = data?.choice;
            this.customClass = data?.customClass;
        }
    }
}

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ModalConfirmData
    ) {
    }

}


