//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel, OrgMetaData } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { OrganisationDirectory, DirectoryService} from '@app/services/pipcall/directory.service';
import { Subscription } from 'rxjs';
// import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { TitleCasePipe } from '@angular/common';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';


import { AuthService } from '@app/services/auth-service/auth.service';
import { MatAccordion } from '@angular/material/expansion';
import { CookieService } from 'ngx-cookie-service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { NormalizeToInternational } from '@app/pipes/formatNumber';
import { escape, unescape} from 'safe-string-literal';

@Component({
    selector: 'app-onboard-billing-details',
    templateUrl: './billing-details.component.html',
    styleUrls: ['./billing-details.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OnboardBillingDetailsComponent {
    @Input() stageId: number;

    @Input() organisation_id: string;
    @Input() current_user_email: string;
    @Input() organisationProfile: OrganisationModel;
    @Output() moveToNextStage = new EventEmitter();
    @Output() skipStage = new EventEmitter();

    pageStatus = {
        isLoading: true,
        error: false,
        isSubmitting: false
    }
    organisationBillingDetails: FormGroup;
    subscription: Subscription;


    constructor(
        public dialog: MatDialog,
        private organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private appInsightService: AppInsightService,
        private staticDataService: StaticDataService,
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private sharedService: SharedService,
    ) {

        this.organisationBillingDetails = this._formBuilder.group({
            organisation_id: ['', [Validators.required]],
            company_number: [''],
            billing_contact_email: ['', [Validators.email]],
            registered_address: ['']
        });
    }
    // convenience getters for easy access to form fields
    get f() { return this.organisationBillingDetails.controls; }

    ngOnChanges(changes: SimpleChanges) {
        // changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setFormValues(this.organisationProfile) : null;
        changes['organisation_id']?.currentValue !== changes['organisation_id']?.previousValue ?   this.setFormValue(this.organisationBillingDetails, 'organisation_id', changes['organisation_id']?.currentValue) : null;
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setFormValues( this.organisationProfile) : null;
    }

    ngOnInit() {
        //get name of current user for updated by
        this.sharedService.innit('onboarding billing details');
        this.organisationBillingDetails.updateValueAndValidity();
    }

    setFormValues(organisationProfile: OrganisationModel) {
        this.setFormValue(this.organisationBillingDetails, 'organisation_id', organisationProfile.id);

        // ==========GET META / HANDLE IF EMPTY=====
        let _meta;
        let parseddata;
        try {
            _meta =  unescape(this.organisationProfile?.metadata);
            parseddata = JSON.parse(_meta);
        } catch (error) {
            // Handle the error here
            parseddata = null;
            console.error("Error parsing JSON:", error);
        }
        const meta: OrgMetaData = parseddata ?  parseddata : null;
        // ===============


        meta.billing_details.company_number ? this.setFormValue(this.organisationBillingDetails, 'company_number', meta.billing_details.company_number) : null;
        meta.billing_details.billing_contact_email ? this.setFormValue(this.organisationBillingDetails, 'billing_contact_email', meta.billing_details.billing_contact_email) : null;
        meta.billing_details.registered_address ? this.setFormValue(this.organisationBillingDetails, 'registered_address', meta.billing_details.registered_address) : null;
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    saveBillingForm(f: FormGroup) {
        if (f.invalid) { return; }
        console.log("update organisation details ------------", this.organisationProfile?.metadata)
        this.appInsightService.logEvent('Welcome | update billing details', { 'organisation_id': this.organisation_id });
        this.pageStatus.isSubmitting = true;

        const meta: OrgMetaData = JSON.parse(unescape(this.organisationProfile?.metadata));
        // Initialize billing if it doesn't exist
        meta.billing_details = {
            company_number: f.value.company_number,
            billing_contact_email: f.value.billing_contact_email,
            registered_address: f.value.registered_address
        };

        console.log("------------->SEND THESE VALUES", meta)
        this.organisationService.patchOrganisationMetadata(f.value.organisation_id, meta)
            .subscribe(resp => {
                this.openSnackBar('Saved', 'dismiss');
                this.pageStatus.isSubmitting = false;
                console.log("success : update billing details, now move to next stage ------------")
                this.organisationProfile.metadata = escape(JSON.stringify(meta));
                this.nextSection();
            }, (err) => {
                console.log(err);
            })
    }

    skipSection() {
        this.skipStage.emit(this.stageId);
        this.nextSection();
    }
    nextSection() {
        this.moveToNextStage.emit(true);
    }


    ngOnDestroy() {
        // if (this._destroy$ && !this._destroy$.closed) {
        //     this._destroy$.next();
        //     this._destroy$.complete();
        // }
    }
}
