import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Md5 } from './md5'; //hashing
import { UiAvatar } from './uiavatar';
import { L } from '@angular/cdk/keycodes';
@Directive({
    selector: '[appGravatar]'
})
export class GravatarDirective implements OnInit {

    @Input() set fullname(value: string) {
        this.updateName(value);
    }

    @Input() set email(value: string) {
        this.updateEmail(value);
    }
    @Input() set firstname(value: string) {
        this.updateNameFirst(value);
    }

    @Input() set lastname(value: string) {
        this.updateNameLast(value);
    }

    @Input() set coloroverride(value: string) {
        this.updateColorOverride(value);
    }

    _fallback = '';
    _fullname = '';
    _firstname = '';
    _lastname = '';
    _coloroverride = '';
    _emailHashed;

    // https://en.gravatar.com/site/implement/images/
    // https://eu.ui-avatars.com/api/?name=Max+Trev&background=0D8ABC&color=fff

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        if (this.el) {
            this.el.nativeElement.src = `//www.gravatar.com/avatar/`;
        }
        this.setAvatar();
    }

    updateName(value: string) {
        this._fullname = value;
        this.setAvatar();
    }

    updateColorOverride(value: string) {
        this._coloroverride = value;
        this.setAvatar();
    }

    updateNameFirst(value: string) {
        this._firstname = value;
        this._fullname = this._firstname + ' ' + this._lastname;
        this.setAvatar();
    }

    updateNameLast(value: string) {
        this._lastname = value;
        this._fullname = this._firstname + ' ' + this._lastname;
        this.setAvatar();
    }

    updateEmail(email: string): void {
        if (!this.el.nativeElement) {
            return;
        }
        // // Split the email into local and domain parts
        // const [localPart, domainPart] = email.split('@');

        // // Remove anything after and including the '+' in the local part
        // const strippedLocalPart = localPart.split('+')[0];

        // // Construct the modified email
        // const modifiedEmail = strippedLocalPart + '@' + domainPart;

        // // Hash the modified email
        // console.log("_emailHashed GRAVATAR ====================", modifiedEmail);
        // // Hash the modified email
        // this._emailHashed = modifiedEmail ? Md5.hashStr(modifiedEmail.trim().toLowerCase()) : null;

        this._emailHashed = email ? Md5.hashStr(email.trim().toLowerCase()) : null;
        this.setAvatar();
    }

    setAvatar(): void {
        this._fallback = this._fullname.trim() !== '' ? UiAvatar.getuiAvatarUrl(this._fullname) : 'mp';
        this.el.nativeElement.src = `//www.gravatar.com/avatar/${this._emailHashed}?d=${this._fallback}`;
    }
}
