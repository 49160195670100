import { Injectable } from '@angular/core';
// import { HttpProxy } from './http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TestService {


    constructor(private http: HttpClient) {
    }

    public testHttp(): Observable<any> {
        const url = 'https://api.ipify.org?format=json'
        return this.http.get<any>(url);
    }

}
