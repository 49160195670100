import { NgModule } from '@angular/core';
//requirements
import { CommonModule } from '@angular/common';

import {PageNotFoundGlobalComponent} from './page-not-found.component'
import {PageNotFoundModule} from '../../components/page-not-found/page-not-found.module';
import {AppInsightService} from '../../services/helpers/app-insights.service';


@NgModule({
    imports: [
    CommonModule,
    PageNotFoundModule
    ],
    declarations: [PageNotFoundGlobalComponent],
    exports: [PageNotFoundGlobalComponent],
    providers: [AppInsightService],
    entryComponents:[]
})
export class PageNotFoundGlobalModule { }