import { Injectable, ModuleWithProviders, NgModule, ApplicationRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { OrganisationModel } from '@app/models/organisation.model';
import { UserProfile, OrganisationUser } from '@app/models/user-profile.model';
import { AuthService } from '@app/services/auth-service/auth.service';
import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import 'rxjs/add/operator/switchMap';
import { debounce } from 'rxjs-compat/operator/debounce';
import { debounceTime, tap, finalize } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TokenService } from '@app/services/token.service';
import { of } from 'rxjs/observable/of';
import { first, take } from 'rxjs/operators';

// https://stackblitz.com/edit/angular-data-sharing-example-dsxnnc?file=app%2FdataService.ts
@Injectable({
    providedIn: 'root'
})
export class SharedService {

    public checkoutEventAsObservable: Observable<any>;
    public viewAsObservable: Observable<any>;
    public activeOrgAsObservable: Observable<any>;
    public orgListAsObservable: Observable<any>;
    public userProfileAsObservable: Observable<any>;

    private user_id: string;

    private viewAsRole = new BehaviorSubject<string>('user'); // user //isAdmin //sysAdmin
    private validRoles: string[] = ['user', 'isAdmin', 'sysAdmin', 'customer_admin', 'system_admin'];

    private isSysAdmin = false;
    // private permissions:string[] = ['user']; //user isAdmin sysAdmin

    private _impersonateUser = '';

    private activeOrg = new BehaviorSubject<OrganisationModel>(null);
    private orgList = new BehaviorSubject<Array<OrganisationUser>>(null);
    private userProfile = new BehaviorSubject<UserProfile>(null);
    private checkoutEvent = new BehaviorSubject<boolean>(false);

    //subscribable events
    //role = user isAdmin sysAdmin

    private gettingData = false;

    // private refreshIntervalID: any;
    private tokenExpiresAt: any;

    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private organisationService: OrganisationService,
        private cookieService: CookieService,
        private ref: ApplicationRef,
        private tokenService: TokenService
    ) {

        this.user_id = '';
        this.isSysAdmin = false;
        this.viewAsRole.next('user');
        this.activeOrg.next(null);
        this.orgList.next(null);
        this.userProfile.next(null);
        this.checkoutEvent.next(null);
        this.gettingData = false;
        this._impersonateUser = '';

        this.checkoutEventAsObservable = this.checkoutEvent.asObservable().filter(resp => !!resp);
        this.viewAsObservable = this.viewAsRole.asObservable().filter(resp => !!resp);
        this.activeOrgAsObservable = this.activeOrg.asObservable().filter(resp => !!resp);
        this.orgListAsObservable = this.orgList.asObservable().filter(resp => !!resp);
        this.userProfileAsObservable = this.userProfile.asObservable().filter(resp => !!resp);
    }


    innit(location?: string) {

        //return cookie values
        const viewAsRole = this.cookieService.check("viewAs") ? this.cookieService.get("viewAs") : ''; //return stored viewAs setting if it exists in co0kie
        console.log("cookie", this.cookieService.get("viewAs"))
        console.log("///=//Shared data innit:", location);
        console.log("//=//viewAsRole:", viewAsRole);

        console.log('set the cookie now');

        if (viewAsRole && this.validRoles.includes(viewAsRole)) { this.changeViewAsRole(viewAsRole); } else { this.cookieService.set('viewAs', '', -1, '/')}//If cookie value exists then set role to that value now else clear the cookie

        this._impersonateUser = this.cookieService.check('impersonateUser') ? this.cookieService.get('impersonateUser') : '';  //check for imperonateUser setting

        this.updateData();

        // if (this.cookieService.check('expires_at') && !this.tokenExpiresAt) {
        //     const dur = this.setExpiresAt()
        //     this.setNextRefreshTokenCheck(dur);
        // }


    }

    // setExpiresAt(): number {
    //     //accesss Token expiry
    //     const e = Number(this.cookieService.get('expires_at'));
    //     const expiresAt = new Date(e * 1000); //datetime of expiry
    //     this.tokenExpiresAt = expiresAt;

    //     const currentDate = new Date();
    //     const duration = this.tokenExpiresAt.valueOf() - currentDate.valueOf();
    //     const durationUntil = duration - 300000; //5 minutes before expiry
    //     console.log("EXPIRES AT ", this.tokenExpiresAt, "CURRENT DATE", currentDate, "DURATION until in MS:", duration);

    //     return durationUntil
    // }

    // setNextRefreshTokenCheck(ms) {
    //     //refresh the token 5 minutes before access token expires
    //     setTimeout(() => {
    //         console.log("...............REFRESH THE TOKEN EARLY NOW...................");
    //         this.tokenExpiresAt = null;
    //         const setnextCheck: Subscription = this.tokenService.refreshToken()
    //             .pipe(
    //                 first(),
    //             )
    //             .subscribe((resp) => {
    //                 //this needs fixing. sometimes logs individual letters ? execute only once. unsubscribe  immediately?
    //                 console.log("REFRESH TOKEN RESPONSE: ", resp);

    //                 if (resp) {
    //                     const dur = this.setExpiresAt();
    //                     this.setNextRefreshTokenCheck(dur);
    //                 } else {
    //                     this.authService.logout();
    //                 }

    //                 setnextCheck.unsubscribe();

    //             }, (err) => {
    //                 console.log(err);
    //                 this.authService.logout();
    //                 setnextCheck.unsubscribe();
    //             });
    //     }, ms);
    // }



    changeViewAsRole(role: string) {
    //valid scenarios
        console.log("//=//Change view as role - role:", role);
        console.log("//=//isSysAdmin:", this.isSysAdmin);
        console.log('//=//this.isSysAdmin && role === sysAdmin condition:', this.isSysAdmin && role === 'sysAdmin')

        if (this.isSysAdmin && role === 'sysAdmin') {
            this.setViewAsRole('sysAdmin');
        } else {

            if (this.isCustomerAdmin()) { this.setViewAsRole('isAdmin') } else {
                this.setViewAsRole('user');
            }

        }

    }

    updateData() {
        console.log("[shared service].updateData()");
        this.isSysAdmin = this.authService.is_system_admin(); //set the highest permissin level.

        if (this._impersonateUser && this.isSysAdmin) { this.changeViewAsRole('user'); } //impersonate user, set viewAs to user

        if (
            !this.activeOrg.value || !this.orgList.value || !this.userProfile.value) {
            !this.gettingData ? this.getUserProfile() : ''; //only get data once.
        }

        // setTimeout(() => {
        //     this.updateData()
        //     //check valid every 3 minutes. (data expires after 8)
        // }, 180000);
    }

    forceRefresh(location?: string) {
        this.markAsExpired(); //this is not longer working - exp is not being tested anymore?
        // this.innit(location);
        this.getUserProfile(); //replaced innit with get usser data
    }

    markAsExpired() {
        sessionStorage.removeItem('_exp');
    }

    clearSession() {
        console.log("[sharedService].clearSession()");
        sessionStorage.clear();
        this.userProfile.next(null);
        this.orgList.next(null);
        this.activeOrg.next(null);
        this.viewAsRole.next('user');
        this.user_id = '';
    }

    getUserProfile() {0
        console.log("[sharedService].getUserProfile()");

        this.user_id = this.authService.getUserId();

        if (!this.user_id) {
            // this.refreshIntervalID ? clearInterval(this.refreshIntervalID) : '';
            const refreshtoken_: Subscription = this.tokenService.refreshToken().subscribe((resp) => {
                refreshtoken_.unsubscribe();
                // this.getUserProfile();
                window.location.reload(); //refresh the page

            }, (err) => {
                refreshtoken_.unsubscribe();
                return this.authService.logout();
            })
            //need to refresh token first
            // return this.authService.logout();
        }  //reathuenticate if no userId

        if (this.user_id && this.gettingData === false) {
            this.gettingData = true;
            const getUserProfile: Subscription = this.userService.getUser(this.user_id)
                .pipe(
                    first()
                )
                .switchMap((resp) => {
                    // this.setExpiresTime(8);
                    this.setOrgList(resp?.body?.organisation_list);
                    this.setUserProfile(resp?.body);
                    return this.organisationService.getOrganisationById(resp?.body?.organisation_list[0].id) //added this line to get extra org info. Consider added that to the user call instead
                })
                .subscribe((resp) => {
                    this.setOrganisation(resp?.body);

                    if (this.isCustomerAdmin() && this.viewAsRole.value === 'user') {
                        this.changeViewAsRole('isAdmin');
                    }; //elevate user to owner if applicable
                    getUserProfile.unsubscribe();
                    this.gettingData = false;
                }, (err) => {
                    this.gettingData = false;
                    //could not get user data. Abort.
                    alert("I had trouble communicating with database, returning to homepage");
                    // this.refreshIntervalID ? clearInterval(this.refreshIntervalID) : '';
                    this.authService.logout();
                    getUserProfile.unsubscribe();
                })
        }

    }


    setOrganisation(organisationProfile: OrganisationModel) {
        console.log("[sharedService].setOrganisation()", organisationProfile);
        this.activeOrg.next(organisationProfile); //set org
    }

    setOrgList(orgList: Array<OrganisationUser>) {
        this.orgList.next(orgList);
    }

    setUserProfile(userProfile: UserProfile) {
        this.userProfile.next(userProfile);
        this.cookieService.set('_userProfile', JSON.stringify(userProfile), 1, '/');
    }


    //mark checkout event as happened. (hide upgrade button).
    updateCheckoutEvent(val: boolean) {
        this.checkoutEvent.next(val);
    }

    public impersonateUser(user_id: string) {
        this.cookieService.set('viewAs', '', -1, '/');   //clear viewAs data
        this.cookieService.set('_userProfile', '', -1, '/');  //clear userprofile data
        this.clearSession();

        this.cookieService.set('impersonateUser', user_id, 24, '/');

        this.user_id = user_id;

        this.forceRefresh();

        //set viewAS to user and then refresh the data

        // setTimeout(() => {
        //   this.changeViewAsRole('user');
        // }, 1400);


        setTimeout(() => {
            window.location.href = '/manage/organisation';
        }, 800);

    }

    public resetImpersonateUser() {
    //restore view to original user

        this.cookieService.set('impersonateUser', '', -1, '/');
        this.cookieService.delete('impersonateUser', '/');

        this.cookieService.delete('viewAs', '/');
        this.cookieService.delete('_userProfile', '/');
        this.userProfile.next(null);
        this.orgList.next(null);
        this.activeOrg.next(null);

        this.user_id = this.authService.getUserId();

        // this.forceRefresh();

        setTimeout(() => {
            window.location.href = '/admin/dashboard';
        }, 800);
    }


    private setViewAsRole(role: string) {
        console.log('setViewAsRole cookie', role);
        //sysAdmin isAdmin user

        this.cookieService.set('viewAs', '', -1, '/'); //delete existing
        // this.cookieService.set('viewAs', role, 24, '/'); //REMOVED THE SETTING OF THIS COOKIE TO DEBUG TEMPLATE NAVBAR ISSUE. IS THIS EVEN REQUIRED ANYMORE? TEST REMOVING viewAs cookie altogether
        this.viewAsRole.next(role); //emit change
    }

    // private isCustomerAdmin(): boolean {
    //     let isAdmin = false;

    //     if (this.user_id && this.activeOrg.value) {
    //         //CHECK USER DETAILS IF isAdmin
    //         isAdmin = this.user_id === this.activeOrg.value.owner_user_id ? true : false;
    //     } else {
    //         isAdmin = false;
    //     }
    //     return isAdmin;
    // }

    // new checker for multiple admins
    private isCustomerAdmin(): boolean {
        let isAdmin = false;

        if (this.user_id && (this.userProfile?.value?.organisation_list[0]?.isAdmin || this.user_id === this.activeOrg?.value?.owner_user_id)  ) {
            isAdmin = true;
        } else {
            isAdmin = false;
        }
        return isAdmin;
    }

    // private setExpiresTime(minutes: number) {
    //     const expiresAs = new Date().getTime() + minutes * 60000; //8 mins from now
    //     sessionStorage.setItem("_exp", expiresAs.toString());
    // }

    private isValid(): boolean {
        return this.tokenService.isValid();
        //this was originally used to update the shared data every 8 minutes so it is not stale. Need to rethink this as it is causing the authentication to fail.

        // console.log("DEBUG STEP: sessionStorageGetItem _exp", sessionStorage.getItem('_exp'))
        // if (!sessionStorage.check('_exp')) { return true}; //debug step added. Ignore if there is no session storage item

        // const expiresAt = sessionStorage.getItem('_exp') ? parseInt(sessionStorage.getItem('_exp')) : 0;
        // const now = (new Date().getTime())
        // console.log('[shared service].isValid() is token valid?:', expiresAt ? now < expiresAt : false);

        // return expiresAt ? now < expiresAt : false;
    }

}


