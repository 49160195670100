import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

//component
import { OrgTabAdminsComponent } from './org-admins.component'

//modules
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { OrgUserSearchSelectModule } from '@app/components/form-field/ff-org-user-search-select/ff-org-user-search-select.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        GravatarDirectiveModule,
        OrgUserSearchSelectModule

    ],
    declarations: [OrgTabAdminsComponent],
    exports: [OrgTabAdminsComponent],
    providers: [AppInsightService, OrganisationService],
    entryComponents: []
})
export class OrgTabAminsModule { }
