import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule} from '@app/material/material.module';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//requirements
import { PaymentsService } from '@app/services/pipcall/payments.service';
//others
import {StatusIconsModule} from '@app/components/status-icons/status-icons.module';
import { FlexLayoutModule } from '@angular/flex-layout'
import {LicenseSelectComponent} from './license-select.component'
import { LicenseDataService} from '@app/services/shared-data.service/license-data.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        StatusIconsModule,
        FlexLayoutModule
    ],
    declarations: [LicenseSelectComponent],
    exports: [LicenseSelectComponent],
    providers: [HttpProxy, PaymentsService, LicenseDataService],
    entryComponents: []
})
export class LicenseSelectModule { }
