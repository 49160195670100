<div fxFlexLayout="row" fxLayoutAlign="space-between center">
    <div fxFlexLayout="row" fxLayoutAlign="start center">
        <!-- dont delete this div -->
        <!-- Filter touched: {{pageStatus.filterIsDirty}} || {{pageStatus.table_state}} -->
    </div>
    <div fxFlexLayout="row" fxLayoutAlign="end center" class="table-tools-wrapper">
        <button (click)="toggleFilter()" matTooltip="Filter table" [matTooltipClass]="'tooltipcustom tooltipmultiline'"
            class="table-filter-icon" mat-icon-button><mat-icon svgIcon="search"></mat-icon></button>
        <button class="table-filter-icon" mat-icon-button #colMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="columnMenu" matTooltip="View columns"
            [matTooltipClass]="'tooltipcustom tooltipmultiline'"><mat-icon svgIcon="view_column"></mat-icon></button>
        <button (click)="refreshData()" matTooltip="Refresh data" [matTooltipClass]="'tooltipcustom tooltipmultiline'"
            class="table-filter-icon" mat-icon-button><mat-icon svgIcon="refresh"></mat-icon></button>
        <button (click)="downloadData()" [disabled]="pageStatus.filterIsDirty" matTooltip="Export to excel"
            [matTooltipClass]="'tooltipcustom tooltipmultiline'" class="table-filter-icon" mat-icon-button><mat-icon
                svgIcon="download"></mat-icon></button>

        <div class="pipe-divider"></div>
        <button class="table-filter-icon" (click)="getSavedQueries()" mat-icon-button #savedMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="savedQueryMenu" matTooltip="Saved queries"
            [matTooltipClass]="'tooltipcustom tooltipmultiline'"><mat-icon svgIcon="dynamic_form"></mat-icon></button>

        <button *ngIf="isFilterVisible && showSaveOption" [disabled]="pageStatus.filterIsDirty"
            (click)="saveQueryMenu($event, searchArray)" class="table-filter-icon" mat-icon-button
            matTooltip="Save new query" [matTooltipClass]="'tooltipcustom tooltipmultiline'"><mat-icon
                svgIcon="save"></mat-icon></button>
    </div>
    <!-- <button class="table-filter-icon" mat-icon-button><mat-icon  matTooltip="Filter table" [matTooltipClass]="'tooltipcustom tooltipmultiline'"  svgIcon="filter_list" ></mat-icon></button> -->
</div>

<div fxLayout="column" style="width:100%;margin-top: 12px;" *ngIf="isFilterVisible" class="filter-controls">

    <ng-container *ngFor="let searchBar of searchArray;  let i = index">

        <div class="search-wrapper text-center" fxLayoutGap="20px" style="margin-bottom: 6px;" fxLayout="row"
            fxLayoutAlign="end center" fxFlex="100%">

            <mat-checkbox matTooltip="Include in Apply all" [checked]="searchBar.isChecked" color="accent"
                (change)="checkboxUpdate($event.checked, i)">
            </mat-checkbox>

            <mat-form-field appearance="outline" class="simple-input-v2" [ngClass]="{'readonly-block': isDisabled}">
                <mat-select placeholder="filter Column" [(ngModel)]="searchBar.col" (selectionChange)="updateTypeOnSelectionChange(searchBar.col, i)">

                    <mat-option *ngFor="let col of _availableColumns" [value]="col.value"
                        style="height:40px;font-size: 14px;" [hidden]="col.type === 'nofilter'">
                        {{col.viewValue}}
                    </mat-option>

                </mat-select>

            </mat-form-field>


            <!--availableOperators.isType matches one (or is any) of the selected column (searchBar.col) type in availableColumns   -->
            <mat-form-field appearance="outline" class="simple-input-v2" [ngClass]="{'readonly-block': isDisabled}"
                style="width:136px;">
                <mat-select placeholder="Search operator" [(ngModel)]="searchBar.operator"
                    (selectionChange)="filterTouched()">

                    <mat-option *ngFor="let col of availableOperators" [value]="col.value"
                        [hidden]="!isOperatorTypeValid(searchBar?.col, col?.isType)"
                        style="height:40px;font-size: 14px;" [matTooltip]="col?.tooltip" matTooltipShowDelay="600">
                        {{col.viewValue}}
                    </mat-option>

                </mat-select>

            </mat-form-field>



            <mat-form-field appearance="outline" class="simple-input-v2"
                [ngClass]="{'readonly-block': isDisabled, 'active': searchBar.isActive && !pageStatus.filterIsDirty}"
                fxFlex>

                <input autofocus #input role="search" type="text" matInput placeholder="Filter..." maxlength="150"
                    tabindex="1" [(ngModel)]="searchBar.value" (keyup.enter)="applySingleLine(i)"
                    [matAutocomplete]="auto" [hidden]="isColumnOfType('boolean', searchBar.col) || searchBar?.operator === 'isnull' || searchBar?.operator === 'isnotnull'"
                    (input)="filterTouched()">

                <mat-select [hidden]="!isColumnOfType('boolean', searchBar.col) && searchBar?.operator !== 'isnull' && searchBar?.operator !== 'isnotnull'" [(ngModel)]="searchBar.value"
                    (selectionChange)="filterTouched()">
                    <mat-option [value]="'true'">True</mat-option>
                    <mat-option [value]="'false'">False</mat-option>
                </mat-select>

                <button [hidden]="!isColumnOfType('date', searchBar.col)" matSuffix mat-icon-button aria-label="date"
                    style="font-size: 18px;height: 28px;width: 28px;top: -2px;" matTooltip="Use datepicker"
                    [matDatepicker]="picker" (click)="picker.open()">
                    <mat-icon class="icon-size-24" color="primary" svgIcon="date"></mat-icon>
                    <mat-datepicker #picker></mat-datepicker>
                </button>

                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange(filterTouched())">

                    <ng-container *ngIf="isAutoComplete('pip_variant', searchBar.col)">
                        <mat-option *ngFor="let variant of pipvariants" [value]="variant">
                            {{variant}}
                        </mat-option>
                    </ng-container>

                    <ng-container *ngIf="isAutoComplete('organisation_state', searchBar.col)">
                        <mat-option *ngFor="let state of states" [value]="state">
                            {{state}}
                        </mat-option>
                    </ng-container>

                    <ng-container *ngIf="isAutoComplete('invitation_state', searchBar.col)">
                        <mat-option value="Invited">Invited</mat-option>
                        <mat-option value="EmailClicked">EmailClicked</mat-option>
                        <mat-option value="Completed">Completed</mat-option>
                        <mat-option value="Launched">Launched</mat-option>
                    </ng-container>

                    <ng-container *ngIf="isAutoComplete('expo_state', searchBar.col)">
                        <mat-option value="New">New</mat-option>
                        <mat-option value="Migrated">Migrated</mat-option>
                        <mat-option value="Deleted">Deleted</mat-option>
                    </ng-container>

                    <ng-container *ngIf="isAutoComplete('pbx_platform', searchBar.col)">
                        <mat-option *ngFor="let pbx of pbxPlatforms" [value]="pbx">
                            {{pbx}}
                        </mat-option>
                    </ng-container>

                    <ng-container *ngIf="isAutoComplete('provider', searchBar.col)">
                        <mat-option *ngFor="let provider of providers" [value]="provider">
                            {{provider}}
                        </mat-option>
                    </ng-container>

                </mat-autocomplete>

            </mat-form-field>


            <mat-form-field class="hidden-form-date-field"
                style="visibility: hidden; width:0px; height:0px; margin-right:0px;">
                <input matInput [matDatepicker]="picker" (dateChange)="updateDateFormat($event.value, i)"
                    (dateChange)="filterTouched()">
                <!-- <mat-datepicker #picker (change)="filterTouched()"></mat-datepicker> -->
            </mat-form-field>
            <!-- <button mat-raised-button (click)="picker.open()">Open</button> -->

            <button mat-flat-button class="applybutton" (click)="applySingleLine(i)"
                [ngClass]="{'active': searchBar.isActive && !pageStatus.filterIsDirty}" matTooltip="Apply this filter"
                [matTooltipClass]="'tooltipcustom tooltipmultiline'"><ng-container
                    *ngIf="(!searchBar.isActive || pageStatus.filterIsDirty); else applied;">Apply</ng-container><ng-template
                    #applied>Applied</ng-template></button>
            <button mat-icon-button class="filter-control-icon" (click)="removeLine(i)" matTooltip="Remove filter"
                [matTooltipClass]="'tooltipcustom tooltipmultiline'"><mat-icon svgIcon="remove"></mat-icon></button>
            <button mat-icon-button class="filter-control-icon" (click)="addLine()" matTooltip="Add filter"
                [matTooltipClass]="'tooltipcustom tooltipmultiline'"><mat-icon svgIcon="add"></mat-icon></button>

        </div>
    </ng-container>

    <div *ngIf="searchArray.length > 1" style="margin-top: 8px;" fxLayoutAlign="end">
        <button matTooltip="Apply all filters" [matTooltipClass]="'tooltipcustom tooltipmultiline'"
            class="applyAllButton" style="height: 40px;width: 220px;" mat-flat-button (click)="filterNow()">Apply
            All</button>
    </div>
</div>





<mat-menu #columnMenu="matMenu" xPosition="before">

    <div class="mat-menu-top-title" (click)="$event.stopPropagation()" fxLayout="row"
        fxLayoutAlign="space-between center" fxLayoutGap="12px">
        <div>Show Columns</div>
        <button (click)="colMenuTrigger.closeMenu()" mat-icon-button style="margin-left: 20px;"><mat-icon
                svgIcon="clear"></mat-icon>
        </button>
    </div>


    <section class="smaller-mat-list checkbox" style="margin-bottom: -8px;">
        <button (click)="$event.stopPropagation()" (click)="resetColSelection()" mat-flat-button style="text-align: left;font-size: 14px;">Reset</button>
        <mat-checkbox (click)="$event.stopPropagation()" (change)="filterColumnSelectAllUpdate($event.checked)"
            class="mat-list-checkbox" [(ngModel)]="columnsShowAll" labelPosition="before">
            Show All</mat-checkbox>
    </section>


    <mat-selection-list class="smaller-mat-list" [(ngModel)]="_filteredColumns"
        (ngModelChange)="filterColumnUpdate()">

        <mat-list-option *ngFor="let col of _availableColumns" togglePosition="before"
            (click)="$event.stopPropagation()" [value]="col">{{col.viewValue}}</mat-list-option>

    </mat-selection-list>
</mat-menu>

<mat-menu #savedQueryMenu="matMenu" xPosition="before" class="saved-query-wrapper load-query-menu">

    <div class="mat-menu-top-title large-width" (click)="$event.stopPropagation()" fxLayout="row"
        fxLayoutAlign="space-between center" fxLayoutGap="12px">
        <div>Saved</div>
        <button (click)="savedMenuTrigger.closeMenu()" mat-icon-button style="margin-left: 20px;"><mat-icon
                svgIcon="clear"></mat-icon>
        </button>
    </div>

    <div class="nosaved" *ngIf="myQueries?.length < 1 && sharedQueries?.length < 1">No saved queries</div>

    <section class="smaller-mat-list" fxLayout="column" fxLayoutAlign="start" *ngIf="myQueries?.length > 0">
        <div fxLayout="row">
            <div class="queries-group-title">My Saved</div><span class="queries-group-title-line"></span>
        </div>

        <ng-container *ngFor="let q of myQueries; let i = index">
            <div (click)="$event.stopPropagation()" (click)="loadSavedQuery(myQueries, q?.Id)"
                class="saved-query-container clickable">
                <div class="sq-title">{{q?.title}}</div>
                <div class="sq-description">{{q?.description}}</div>
                <div class="sq-toolbar" fxFlexLayout="row" fxLayoutAlign="space-between center">
                    <div class="author-desc" fxLayout="column">
                        <div>{{q?.user?.first_name}} {{q?.user?.last_name}}</div>
                        <div>{{q?.created_at | date: 'yyyy-MM-dd HH:mm'}}</div>
                    </div>
                    <button (click)="$event.stopPropagation()" (click)="deleteSavedQuery(q?.Id, q)" mat-icon-button
                        matTooltip="Delete this query"><mat-icon svgIcon="trash"></mat-icon></button>
                </div>
            </div>
        </ng-container>

    </section>


    <section class="smaller-mat-list" fxLayout="column" fxLayoutAlign="start" *ngIf="sharedQueries?.length > 0">
        <div fxLayout="row">
            <div class="queries-group-title">Shared&nbsp;<span
                    *ngIf="pageStatus.loadingUserQuery || pageStatus.loadingSharedQuery">
                    <div class="loader-spinner" style="vertical-align: middle;display: inline-block;"></div>
                </span></div><span class="queries-group-title-line"></span>
        </div>

        <ng-container *ngFor="let q of sharedQueries; let i = index">
            <div (click)="$event.stopPropagation()" (click)="loadSavedQuery(sharedQueries, q?.Id)"
                class="saved-query-container clickable">
                <div class="sq-title">{{q?.title}}</div>
                <div class="sq-description">{{q?.description}}</div>
                <div class="sq-toolbar" fxFlexLayout="row" fxLayoutAlign="space-between center">
                    <div class="author-desc" fxLayout="column">
                        <div>{{q?.user?.first_name}} {{q?.user?.last_name}}</div>
                        <div>{{q?.created_at | date: 'yyyy-MM-dd HH:mm'}}</div>
                    </div>
                    <div>
                        <button *ngIf="q?.is_protected" [ngClass]="{'readonly': q?.user_id === user_id}" (click)="$event.stopPropagation()"
                            mat-icon-button
                            matTooltip="Only the author ({{q?.user?.first_name}} {{q?.user?.last_name}}) can delete. "><mat-icon
                                svgIcon="lock"></mat-icon></button>

                        <button *ngIf="!q?.is_protected || q?.user_id === user_id" [ngClass]="{'readonly': q?.is_protected && q?.user_id !== user_id}" (click)="$event.stopPropagation()"
                            (click)="deleteSavedQuery(q?.Id, q)" mat-icon-button matTooltip="Delete this query"><mat-icon
                                svgIcon="trash"></mat-icon></button>
                
                    </div>
                </div>
            </div>
        </ng-container>

    </section>


    <!-- <mat-selection-list class="smaller-mat-list" [(ngModel)]="_filteredColumns"
        (ngModelChange)="filterColumnUpdate($event)">

        <mat-list-option *ngFor="let col of _availableColumns" togglePosition="before"
            (click)="$event.stopPropagation()" [value]="col">{{col.viewValue}}</mat-list-option>

    </mat-selection-list> -->
</mat-menu>


<!-- //replace with ngtempalte -->