import {
    trigger, state, style, transition,
    animate, group, query, stagger, keyframes
} from '@angular/animations';

export const CustomAnimations = [
    trigger('fadeOut', [
        state('void', style({
            opacity: 0
        })),
        transition('void <=> *', animate(400)),
    ]),
    trigger('fadeInOut', [
        state('void', style({ opacity: 0 })),
        transition('* <=> *', animate(250)),
    ]),
    trigger('slideOutBottom', [
        state('void', style({ 'bottom': '-200px' })),
        transition(':leave', animate('200ms ease-in-out')),
        transition('void <=> *', animate('200ms ease-in-out'))
    ]),
    trigger('slideInBottom', [
        state('void', style({ 'bottom': '-200px' })),
        transition(':enter', animate('200ms ease-in-out')),
        transition('void => *', animate('200ms ease-in-out'))
    ]),
    trigger('slideOut', [
        state('void', style({ 'height': '0px' , opacity: 0 })),
        transition(':leave', animate('200ms ease-in-out')),
        transition('* => void', animate('200ms ease-in-out'))
    ]),
    trigger('slideIn', [
        state('void', style({ 'height': '0px' , opacity: 0 })),
        transition(':enter', animate('300ms ease-in-out')),
        transition('void => *', animate('300ms ease-in-out'))
    ]),
    trigger('slideContentMargin', [
        state('expanded', style({
            marginLeft: '240px'
        })),
        state('minimized', style({
            marginLeft: '50px'
        })),
        transition('* => *', animate('200ms ease-in-out'))
    ])
]


//usage
// passing a state into animation
// [@slideUpDown]="someDiv.state"

//import into component and provide animations
// animations: [
//     CustomAnimations
// ]

//handling start and end events
// (@itemAnim.start)="animStart($event)"
// (@itemAnim.done)


//void state if for ngIf false , animate out
