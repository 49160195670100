<div class="cc-v-page-wrap cc-background-animated-wave large-size">




    <div class="mobile-view-nav" *ngIf="isMobileView">
        <ng-container *ngTemplateOutlet="stepsNaDisplay"></ng-container>
    </div>

    <cc-floating-card [showCopyRight]="false" [cardSize]="'large'" [noInnerContainer]="true">

        <div class="cc-content-container-stepform">
            <div class="cc-main-content-stepform">


                <div class="l-side-container background-image">
                    <div class="l-side-content">
                        <ng-container *ngTemplateOutlet="logoDisplay"></ng-container>
                        <ng-container *ngTemplateOutlet="stepsNaDisplay"></ng-container>
                    </div>
                </div>


                <form [formGroup]="signUpForm" class="r-content-container"
                    [ngClass]="{'readonly-block' : pageStatus.isSubmitting}">
                    <div class="r-side-content">

                        <div class="r-side-step-container" [hidden]="pageStatus.step !== 0" [@fadeInOnEnter]>
                            <h4>Start Your Free 14-Day Trial</h4>
                            <p>Sign up now and enjoy full access to all features. Please fill in your details below.</p>
                            <div class="main-content">
                                <div formGroupName="personalInfo" style="display:flex;flex-direction: column;">

                                    <div class="formfield-x2-container">

                                        <mat-form-field appearance="outline" class="custom-form-field-v3">
                                            <input type="text" matInput placeholder="First name"
                                                autocomplete="given-name" formControlName="first_name">
                                            <mat-label>First name</mat-label>
                                            <mat-error
                                                *ngIf="f?.personalInfo?.get('first_name')?.touched &&f?.personalInfo?.get('first_name')?.errors?.required">
                                                Last name
                                                is required</mat-error>
                                            <status-icons *ngIf="f?.personalInfo?.get('first_name')?.touched"
                                                [iconStatus]="f?.personalInfo?.get('first_name')?.status">
                                            </status-icons>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" class="custom-form-field-v3">
                                            <input type="text" matInput placeholder="First name"
                                                autocomplete="family-name" formControlName="last_name">
                                            <mat-label>Last name</mat-label>
                                            <mat-error
                                                *ngIf="f?.personalInfo?.get('last_name')?.touched &&f?.personalInfo?.get('last_name')?.errors?.required">
                                                Last name
                                                is required</mat-error>
                                            <status-icons *ngIf="f?.personalInfo?.get('last_name')?.touched"
                                                [iconStatus]="f?.personalInfo?.get('last_name')?.status">
                                            </status-icons>
                                        </mat-form-field>
                                    </div>

                                    <mat-form-field appearance="outline" class="custom-form-field-v3">
                                        <input type="text" matInput placeholder="Organisation name" autocomplete="off"
                                            formControlName="organisation_name">
                                        <mat-label>Organisation name</mat-label>
                                        <mat-error *ngIf="f?.personalInfo?.get('organisation_name')?.errors?.required">
                                            Required
                                            field</mat-error>
                                        <status-icons *ngIf="f?.personalInfo?.get('organisation_name')?.touched"
                                            [iconStatus]="f?.personalInfo?.get('organisation_name').status"></status-icons>
                                    </mat-form-field>


                                    <div
                                        style="display: flex;flex-direction: row; align-items: center;position: relative;">
                                        <mat-form-field appearance="outline" class="custom-form-field-v3 number-code-select readonly-block">
                                            <mat-select formControlName="region">
                                                <mat-option value="0044" selected>+44</mat-option>
                                            </mat-select>
                                            <status-icons *ngIf="contactPhoneNumber.dirty"
                                                [iconStatus]="contactPhoneNumber.status"></status-icons>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline"
                                            class="custom-form-field-v3 adjustlabel-for-region-field-100"
                                            style="width: 100%;">
                                            <mat-label>Mobile</mat-label>
                                            <input type="text" matInput placeholder="Enter mobile"
                                                formControlName="contact_phone_number">
                                            <mat-error
                                                *ngIf="contactPhoneNumber?.touched && contactPhoneNumber?.errors?.required">
                                                Mobile is required</mat-error>
                                            <mat-error class="errormsg" *ngIf="contactPhoneNumber.status === 'PENDING'">
                                                checking ...
                                            </mat-error>
                                            <mat-error
                                                *ngIf="contactPhoneNumber.touched && contactPhoneNumber.errors?.mobileTaken">
                                                Mobile already registered with an account.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="contactPhoneNumber.touched && contactPhoneNumber.errors?.valid">
                                                {{contactPhoneNumber.errors?.valid}}
                                            </mat-error>
                                            <mat-icon matSuffix svgIcon="info" class="info-icon"
                                                matTooltip="This is the number for the device you would like to install PiPcall on. You will need to verify you own this number before you can use the app."></mat-icon>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="button-tray">
                                    <button class="next-button action-button " mat-flat-button color="accent"
                                        (click)="$event.preventDefault()" (click)="next()"
                                        [disabled]="!f.personalInfo.valid">Continue</button>
                                </div>
                            </div>

                        </div>

                        <div class="r-side-step-container" [hidden]="pageStatus.step !== 1" [@fadeInOnEnter]>
                            <h4>Set Up Your Login</h4>
                            <p>Create your account credentials which you will use to access your portal.</p>
                            <div class="main-content">
                                <div formGroupName="loginCredentials" style="display:flex;flex-direction: column;">

                                    <mat-form-field appearance="outline" class="custom-form-field-v3">
                                        <input type="text" matInput placeholder="Email" autocomplete="off"
                                            formControlName="email">
                                        <mat-label>Email</mat-label>
                                        <mat-error *ngIf="f?.loginCredentials?.get('email')?.errors?.required">
                                            Required
                                            field</mat-error>
                                        <mat-error
                                            *ngIf="f?.loginCredentials?.get('email')?.touched && f?.loginCredentials?.get('email')?.errors?.pattern">
                                            Not a valid email</mat-error>
                                        <mat-error *ngIf="f?.loginCredentials?.get('email')?.status === 'PENDING'">
                                            checking ...
                                        </mat-error>
                                        <mat-error
                                            *ngIf="f?.loginCredentials?.get('email')?.errors && f?.loginCredentials?.get('email')?.errors.emailTaken">
                                            Email already in use.
                                        </mat-error>
                                        <status-icons *ngIf="f?.loginCredentials?.get('email')?.touched"
                                            [iconStatus]="f?.loginCredentials?.get('email').status"></status-icons>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="custom-form-field-v3">

                                        <input type="{{pwType}}" matInput placeholder="Password"
                                            autocomplete="new-password" formControlName="password"
                                            title="Password strength: minimum length 8 characters and must include lower case (a-z), upper case (A-Z) and a number (0-9)">
                                        <mat-icon matSuffix class="clickable" style="color:var(--c-title);"
                                            *ngIf="pwType != 'password'" (click)="pwType = 'password'"
                                            title="hide password" (click)="$event.stopPropagation()"
                                            svgIcon="view"></mat-icon>
                                        <mat-icon matSuffix class="clickable" style="color:var(--c-title);"
                                            *ngIf="pwType === 'password'" (click)="pwType = 'text'"
                                            title="show password" (click)="$event.stopPropagation()"
                                            svgIcon="view_off"></mat-icon>

                                        <mat-error
                                            *ngIf="f?.loginCredentials.get('password')?.touched && f?.loginCredentials.get('password')?.errors?.required">
                                            Required field
                                        </mat-error>

                                        <mat-error
                                            *ngIf="f?.loginCredentials.get('password').touched && f?.loginCredentials.get('password').errors?.pattern">
                                            min length 8 chars, include lower case (a-z), upper case (A-Z) and a number
                                            (0-9)
                                        </mat-error>

                                        <mat-label>Password</mat-label>

                                        <status-icons *ngIf="f?.loginCredentials.get('password').touched"
                                            [iconStatus]="f?.loginCredentials.get('password').status">
                                        </status-icons>

                                    </mat-form-field>

                                    <!-- <mat-form-field appearance="outline" class="custom-form-field-v3">

                                        <input type="{{pwType}}" matInput placeholder="Confirm Password"
                                            autocomplete="new-password" formControlName="passwordConfirm"
                                            title="Password strength: minimum length 6 characters and must include lower case (a-z), upper case (A-Z) and a number (0-9)">


                                        <mat-icon matSuffix class="clickable" *ngIf="pwType != 'password'"
                                            (click)="pwType = 'password'" title="hide password"
                                            (click)="$event.stopPropagation()" svgIcon="view"></mat-icon>
                                        <mat-icon matSuffix class="clickable" *ngIf="pwType === 'password'"
                                            (click)="pwType = 'text'" title="show password"
                                            (click)="$event.stopPropagation()" svgIcon="view_off"></mat-icon>

                                        <mat-error
                                            *ngIf="f?.loginCredentials.get('passwordConfirm')?.touched && f?.loginCredentials.get('passwordConfirm')?.errors?.required">
                                            Required field
                                        </mat-error>

                                        <mat-error
                                            *ngIf="f?.loginCredentials.get('passwordConfirm')?.touched && f?.loginCredentials.get('passwordConfirm')?.errors?.MatchPassword">
                                            Passwords do not match
                                        </mat-error>

                                        <mat-label>Confirm Password</mat-label>

                                        <status-icons *ngIf="f?.loginCredentials.get('passwordConfirm')?.touched"
                                            [iconStatus]="f?.loginCredentials.get('passwordConfirm')?.status"></status-icons>
                                    </mat-form-field> -->

                                    <section class="bottom-options">
                                        <p> By signing up to the PiPcall
                                            Portal via this form you are creating a customer account with us, and your
                                            organisation will
                                            therefore be bound by the <a class="nowrap-col" target="_blank"
                                                href="https://www.pipcall.com/policies/corporatecustomercontract">Corporate
                                                Customer Contract</a>.
                                            Your personal use of the PiPcall Portal will also be bound by the <a
                                                class="nowrap-col" target="_blank"
                                                href="https://www.pipcall.com/policies/endusertermsandconditions">End-User
                                                Term
                                                and Conditions</a>.</p>
                                        <mat-checkbox class="i-agree-box" labelPosition="before"
                                            formControlName="termsconditions">
                                            I agree to the statement above
                                        </mat-checkbox>

                                        <re-captcha size="invisible" badge="bottomright" #invisible
                                            (resolved)="resolveRecaptcha($event)"></re-captcha>

                                    </section>

                                </div>
                                <div class="button-tray">
                                    <button class="action-button" button mat-stroked-button
                                        (click)="prev()" (click)="$event.preventDefault()"
                                        [disabled]="pageStatus?.isSubmitting">Back</button>

                                    <button class="next-button action-button" mat-flat-button color="accent"
                                        [disabled]="!f?.loginCredentials.valid || pageStatus?.isSubmitting"
                                        (click)="$event.preventDefault()" (click)="triggerRecaptcha()">
                                        <ng-container
                                            *ngIf="!pageStatus.isSubmitting; else spinner">Continue</ng-container>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="r-side-step-container" [hidden]="pageStatus.step !== 2" [@fadeInOnEnter]>
                            <h4>Verify Your Email Address</h4>
                            <p>A verification code has been sent to&nbsp;<wbr>{{f?.loginCredentials.get('email').value}}. Please enter the 6-digit code below to proceed.
                            </p>
                            <div class="main-content">
                                <div>
                                    <form [formGroup]="verificationForm" fxLayout="column" fxLayoutGap="6px"
                                        [ngClass]="{'readonly-block': pageStatus.isLoading || pageStatus.isSubmitting}">
                                        <div fxLayout="column">

                                            <code-input *ngIf="pageStatus.step === 2" [isCodeHidden]="false"
                                                [isFocusingOnLastByClickIfFilled]="true" [codeLength]="6"
                                                [initialFocusField]="0" [disabled]="pageStatus.isSubmitting"
                                                (codeChanged)="onCodeChanged($event)"
                                                (codeCompleted)="onCodeCompleted($event)">
                                            </code-input>

                                            <!-- <mat-form-field appearance="outline"
                                                class="text-center custom-form-field-v3">
                                                <input matInput type="tel" pattern="[0-9]*" inputmode="numeric"
                                                    placeholder="Verification code" autocomplete="one-time-code"
                                                    formControlName="code">
                                            </mat-form-field> -->
                                        </div>
                                    </form>

                                    <div class="error-response-box" *ngIf="pageStatus.isError"
                                        [@enter]="{ value: '', params: { duration: 600, delay: 0 } }"
                                        [@fadeOutLeftOnLeave]="{ value: '', params: { duration: 100, delay: 0, translate: '50%' } }">
                                        <mat-icon class="clickable"
                                            (click)="pageStatus.isError = false">cancel</mat-icon>{{pageStatus.errorMessage}}
                                    </div>
                                </div>

                                <div class="button-tray">
                                    <button class="action-button " button mat-stroked-button
                                        (click)="$event.preventDefault()" (click)="cancelVerification()"
                                        [disabled]="pageStatus?.isSubmitting">Back</button>
                                    <button [disabled]="!verificationForm.valid || pageStatus?.isSubmitting"
                                        class="next-button action-button" mat-flat-button color="accent"
                                        (click)="$event.preventDefault()" (click)="verifyCode()">
                                        <ng-container
                                            *ngIf="!pageStatus.isSubmitting; else spinner">Verify</ng-container>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="r-side-step-container" [hidden]="pageStatus.step !== 3" [@fadeInOnEnter]>
                            <h4>Success!</h4>

                            <div class="main-content">
                                <p style="margin-top: 124px;">
                                    Logging into Portal... <i class="fas fa-spinner fa-spin"></i>
                                </p>
                            </div>
                        </div>

                        <ng-container *ngIf="isMobileView; then logoDisplay"></ng-container>
                        <div class="copyrightmessage">{{copyrightMessage}}</div>

                    </div>

                </form>


            </div>
        </div>

    </cc-floating-card>

    <div class="app-version">
        <span class="text-right version-details"> Version: {{appversion}} <span *ngIf="isDev">(DEV)</span></span>
    </div>

    <div class="cc-wave"></div>
    <div class="cc-wave"></div>
    <div class="cc-wave"></div>
</div>

<ng-template #logoDisplay>
    <div class="logo-container">
        <div class="logo-content">
            <div class="logo"><a href="https://www.mobilepbxpro.com/"><img style="width:40px" src="/assets/brand/mobilepbx-pro-logo-solo.png">MobilePBX Pro</a></div>
            <div class="subcontent">a <b>PiPcall</b> product</div>
        </div>
    </div>
</ng-template>

<ng-template #stepsNaDisplay>
    <div class="steps-list-container">
        <ul class="list-unstyled steps-list">
            <li [ngClass]="{'active' : pageStatus.step === 0}">
                <div class="num-icon"><span *ngIf="pageStatus.step < 1; else check">1</span></div>
                <div style="margin-left: 10px;" *ngIf="!isMobileView">Your details</div>
            </li>
            <li [ngClass]="{'active' : pageStatus.step === 1}">
                <div class="num-icon"><span *ngIf="pageStatus.step < 2; else check">2</span></div>
                <div style="margin-left: 10px;" *ngIf="!isMobileView">Login credentials</div>
            </li>
            <li [ngClass]="{'active' : pageStatus.step === 2}">
                <div class="num-icon"><span *ngIf="pageStatus.step < 3; else check">3</span></div>
                <div style="margin-left: 10px;" *ngIf="!isMobileView">Verify email</div>
            </li>
            <ng-template #check><mat-icon svgIcon="check_circle_fill" class="check-icon"></mat-icon></ng-template>
        </ul>
    </div>
</ng-template>

<ng-template #loading>
    <div fxLayoutAlign="center center">
        <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
            <div class="bounce4"></div>
        </div>
    </div>
</ng-template>


<ng-template #spinner>
    <div>
        <span class="loading-icon">
            <div class="loader-spinner"></div>
        </span>
    </div>
</ng-template>