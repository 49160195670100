


<button class="admin-3-dot-menu" [matMenuTriggerFor]="ddiMenu" [matMenuTriggerData]="{user_id: user_id, name: name, organisation_id: organisation_id, organisation_state: organisation_state}"  mat-icon-button><mat-icon svgIcon="three-dots-vertical"></mat-icon></button>

<mat-menu #ddiMenu="matMenu" class="v3-mat-menu" xPosition="before">

  <ng-template matMenuContent let-user_id="user_id" let-name="name" let-organisation_id="organisation_id"> 

    <button  mat-menu-item  (click)="impersonateUser(user_id)">
      <mat-icon svgIcon="open_in_new"></mat-icon>
      <span  class="matmenu-btn-label">Impersonate user</span>
    </button>

    <button mat-menu-item matTooltip="Propagate User to telephony platform"  (click)="propagateUser(user_id)">
      <mat-icon svgIcon="propagate"></mat-icon>
      <span  class="matmenu-btn-label">Propagate Changes</span>
    </button>

    <button mat-menu-item matTooltip="Force log out the user from the app"  (click)="forceLogoutUser(user_id)">
      <mat-icon svgIcon="logout-2"></mat-icon>
      <span  class="matmenu-btn-label">Force logout</span>
    </button>

    <hr class="divider">

    <button  mat-menu-item class="red-item"   [disabled]="user_id=== owner_user_id"  [matTooltip]="user_id === owner_user_id ? 'Owners cannot be removed from the organisation.' : 'Unlink and remove the user from this organisation'" (click)="unlinkUserFromOrganisation(user_id)">
      <mat-icon svgIcon="delete"></mat-icon>
      <span  class="matmenu-btn-label">Unlink Organisation</span>
    </button>


  </ng-template>
</mat-menu>

