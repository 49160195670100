<ng-container *ngIf="!showAsMenu;else _showAsMenu">
  <mat-form-field appearance="outline" class="formfield-item" [ngClass]="{'readonly-block': disabled}" class="custom-form-field-v3">
    <span class="loading-icon" *ngIf="isLoading"><i class="fas fa-spinner fa-spin"></i></span>

    <mat-select [ngModel]="selected_user_id" (selectionChange)="emitValue($event.value)" placeholder="Unassigned">

      <ng-container *ngIf="!returnUsersWithNoPiP; else showUsersWithNoPiP">
        <mat-option *ngFor="let user of orgUsers" [value]="user.id">
          {{user.first_name}} {{user.last_name}} <span *ngIf="showPipnumbers">({{user.organisation_list[0]?.ddi_list?.length}})</span>
        </mat-option>
        <mat-option *ngIf="orgUsers?.length === 0" [disabled]=true>There are no users available</mat-option>
      </ng-container>

      <ng-template #showUsersWithNoPiP>
        <mat-option *ngFor="let user of orgUsers_noPiP" [value]="user.id">
          {{user.first_name}} {{user.last_name}} <span *ngIf="showPipnumbers">(<ng-container *ngIf="user.organisation_list[0]?.ddi_list[0]?.number === null; else showNumber">0</ng-container>
            <ng-template #showNumber>{{user.organisation_list[0]?.ddi_list?.length}}</ng-template>)</span>
        </mat-option>
        <mat-option *ngIf="orgUsers_noPiP?.length === 0" [disabled]=true>There are no users available</mat-option>
      </ng-template>

    </mat-select>
    <mat-label>
        Select user
    </mat-label>
  </mat-form-field>
</ng-container>


<!-- //list for menu item selection -->
<ng-template #_showAsMenu>
  <div>
    <button *ngFor="let user of orgUsers" mat-menu-item (click)="emitValue(user.id)">{{user.first_name}}
      {{user.last_name}}</button>
      <button class="text-center" mat-menu-item (click)="emitValue('')">Clear selection</button>
  </div>
</ng-template>
