import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatIconModule, MatDividerModule, MatButtonModule } from '@app/material/material-essentials.module';
import { MaterialModule } from '@app/material/material.module';
import { ConfirmUserUnlinkModalComponent } from './confirm-user-unlink-modal.component';
import { ConfirmUserUnlinkModalService} from './confirm-user-unlink-modal.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatDividerModule,
        MatButtonModule,
        FormsModule
    ],
    declarations: [ConfirmUserUnlinkModalComponent],
    entryComponents: [ConfirmUserUnlinkModalComponent],
    exports: [ConfirmUserUnlinkModalComponent],
    providers: [ConfirmUserUnlinkModalService]
})
export class ConfirmUserUnlinkModalModule { }
