//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil , first} from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { UserProfile} from '@app/models/user-profile.model';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { DdiNumber } from '@app/models/ddi.model';

//bottom sheet
import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UserProfileBottomSheetComponent} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.component';
import { DDIBottomSheetComponent } from '@app/components/bottom-sheet-modal/ddi-bsheet-modal/ddi-bsheet-modal.component';

import { InvitationService } from '@app/services/pipcall/invitation.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import { InviteUserModalComponentpipxt } from '@app/components/modals/invite-user-modal-pipxt/invite-user-modal.component'
import { NumberService} from '@app/services/pipcall/number.service';
import { CreateSipModalComponent } from '@app/components/modals/create-sip-modal/create-sip-modal.component';


@Component({
    selector: 'app-org-pending-pipoffice',
    templateUrl: './pending-pipoffice.component.html',
    styleUrls: ['./pending-pipoffice.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class PendingPipofficeComponents {

    @Input() viewAs: string; //REQUIRED
    @Input() organisation_id: string; //REQUIRED
    @Input() orgDdiList: Array<DdiNumber>;  //REQUIRED

    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }


    filteredList: Array<DdiNumber>;

    public numberListSubscription: Subscription;


    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private bottomSheet: MatBottomSheet,
        private organisationService: OrganisationService,
        private ref: ChangeDetectorRef,
        private invitationService: InvitationService,
        private numberService: NumberService,
        private modalService: ModalService
    ) {
        this.filteredList = new Array<DdiNumber>();
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("[extensions pending tab].ngOnChanges()", changes);
        // (changes['orgDdiList']?.currentValue !== changes['orgDdiList']?.previousValue) && !changes['orgDdiList']?.isFirstChange() ?  this.setDataSource(this.orgDdiList) : null;
        changes['orgDdiList']?.currentValue !== changes['orgDdiList']?.previousValue && !changes['orgDdiList']?.isFirstChange() ?  this.setDataSource(this.orgDdiList) : null;
    }


    ngOnInit() {
        console.log("===========orgDdiList=========== IN CHILD", this.orgDdiList);
        this.setDataSource(this.orgDdiList)
    }



    setDataSource( numbers: Array<DdiNumber>) {
        console.log("[extensions pending tab].setDataSource()");
        this.filteredList = [];
        numbers !== null ? this.filteredList = numbers.filter(item => item.pip_variant === 'pipxt' && (item?.sip_host_name === null || (item?.is_pipxt_extensionless === false && item?.sip_extension_number === null)) ) : '';
    }



    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }


    openExtensionProfile(number: DdiNumber) {
        const data = {number: number, viewAs: this.viewAs, organisation_id: this.organisation_id, number_id: number?.number}

        const dialogRef = this.bottomSheet.open(DDIBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF Bottom sheet xxxx", resp);
            if (resp) {
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });
    }

    openUserProfile(user_id: string) {
        const data = {user: null, viewAs: this.viewAs, organisation_id: this.organisation_id, user_id : user_id}

        const dialogRef = this.bottomSheet.open(UserProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            if (resp) {
                this.triggerUpdate();
            }
        });
    }


    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    alertRevokeInvitation(id: string) {

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'Remove this invitation',
            confirmButtonLabel: "Confirm",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.revokeInvitation(id);
                return;
            }
        });

    }

    revokeInvitation(id: string) {
        this.invitationService.deleteSingleInvitation(id)
            .subscribe((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    const index = this.filteredList.findIndex(val => val.invitation_id === id)
                    this.filteredList[index].invitation_id = '';
                    this.filteredList[index].invitation_email = '';
                    this.openSnackBar("Invitation was revoked", "dismiss");
                    return;
                }
                this.openSnackBar("sorry something went wrong with this request", "dismiss");
            }, (err) => {
                console.log(err)
                this.openSnackBar("sorry something went wrong with this request", "dismiss");
            })
    }

    deleteNumber(ddi: DdiNumber) {
        console.log("DELETE NUMBER", ddi);
        //add confirm dialogue

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: `Delete extension ${ddi.sip_extension_number}?`,
            confirmButtonLabel: "Delete",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.numberService.deleteSingleDDi(ddi.number).subscribe((resp) => {
                    if (resp.status === 200 || resp.status === 201) {
                        this.triggerUpdate();
                        this.openSnackBar("Extension has been deleted", "dismiss");
                    }
                })
            }
        });


    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
