// core
import { Component, ViewChild, OnInit, Inject, isDevMode, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
// form essentials
import { FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';

// validation
import { SipProvisioningRequest } from '@app/models/ddi.model';

// services
import { NumberService } from '@app/services/pipcall/number.service';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';

// normalise number
import { NormalizeTo00 } from '@app/pipes/formatNumber';

import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { RegionCodes, NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { fadeInUpOnEnterAnimation } from 'angular-animations';
import { OrganisationService, HostnameList} from '@app/services/pipcall/organisation.service';
import { OrganisationDdiList} from '@app/models/organisation.model';
import { OrgSearchSelectComponent} from '@app/components/form-field/ff-org-search-select/ff-org-search-select.component';
import { responseModel } from '@app/models/response.model';
@Component({
    selector: 'create-sip-modal',
    templateUrl: './create-sip-modal.component.html',
    styleUrls: ['./create-sip-modal.component.scss'],
    animations: [
        fadeInUpOnEnterAnimation({ anchor: 'enter' })
    ]
})
export class CreateSipModalComponent {

    public newSipForm: FormGroup;

    // public submitted: boolean = false;
    public isSubmitting = false;
    public isSuccess = false;
    public gettingList = false;
    public validNumber = false;
    public newSipRequest: SipProvisioningRequest;
    public provisionSipSubsciption: Subscription;
    public viewAs: string;

    public enableOrganisationSearch = true; //whether or not to show the sysAdmin organisation Search field
    // @Output() updateSipList = new EventEmitter();

    @ViewChild('orgSearchSelectComponent') orgSearchSelectComponent: OrgSearchSelectComponent;

    filteredHosts: HostnameList[] = [];
    hostnameList: HostnameList[] = [];

    constructor(
        private dialogRef: MatDialogRef<CreateSipModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: any, // contains data injected into modal.
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private router: Router,
        private numberService: NumberService,
        private staticDataService: StaticDataService,
        private normalizeTo00: NormalizeTo00,
        private organisationService: OrganisationService

    ) {
        this.newSipRequest = new SipProvisioningRequest();

        // add number number to ext   sip_extension_cli: ['44', [Validators.required, Validators.pattern('^([0-9]{12})$')]],
        //(?!447)^([0-9]{12})$ //excludes 447 numbers
        this.newSipForm = this._formBuilder.group({
            organisation_id: ['', Validators.required],
            sip_details: this._formBuilder.group({
                sip_extension_cli: ['44', [Validators.required, Validators.pattern('(?!^447)^[0-9]{12}$')]],
                sip_extension_number: ['', [Validators.required]],
                sip_user_name: ['', [Validators.required, Validators.minLength(3)]],
                sip_password: ['', [Validators.maxLength(30), Validators.required]],
                sip_host_name: [{value: '' , disabled: true}, Validators.required],
                sip_voicemail_number: [{value: '' , disabled: true}, Validators.required]
            })
        });

        this.newSipForm.controls['sip_details'].disable();
    }
    ngOnInit() {
        // this.data //anything you want to do with injected data
        this.viewAs = this.data?.viewAs;



        this.newSipForm
            .controls['organisation_id']
            .valueChanges
            .subscribe(value => {
                if (value) {
                    this.getHostnames(value);
                    this.newSipForm.controls['sip_details'].reset();
                    this.newSipForm.controls['sip_details'].get('sip_extension_cli').patchValue('44',  { emitEvent: false })
                    this.newSipForm.controls['sip_details'].enable();
                } else {
                    this.newSipForm.controls['sip_details'].reset();
                    this.newSipForm.controls['sip_details'].disable();
                    this.clearHostList();
                }
            });

        if (this.data?.organisation_id) {
            console.log("=========ORG ID =======", this.data?.organisation_id);
            this.enableOrganisationSearch = false;
            this.enableOrganisationSearch = this.data?.enableOrganisationSearch;
            this.newSipForm.controls.organisation_id.patchValue( this.data?.organisation_id);
        }


        this.newSipForm
            .controls['sip_details']
            .get('sip_host_name')
            .valueChanges
            .pipe(
                map(
                    value =>  this._filterHost(value)
                )
            )
            .subscribe((resp) => {
                console.log("value change response", resp);
                this.filteredHosts = resp;
            }, (err) => {
                console.log("err", err);
                this.filteredHosts = [];
            });
    }


    submitForm(f: FormGroup) {
        if ( f.invalid) { return; }

        this.isSuccess = false;
        this.isSubmitting = true;

        this.newSipRequest.organisation_id = f.value.organisation_id;

        this.newSipRequest.sip_user_name = f.value.sip_details.sip_user_name;  //same as extension usually
        this.newSipRequest.sip_password = f.value.sip_details.sip_password;
        this.newSipRequest.sip_host_name = f.value.sip_details.sip_host_name;
        this.newSipRequest.sip_extension_number = f.value.sip_details.sip_extension_number; //short
        this.newSipRequest.sip_voicemail_number = f.value.sip_details.sip_voicemail_number; //short

        this.newSipRequest.sip_extension_cli = f.value.sip_details.sip_extension_cli;  //long number
        //format the exension cli
        this.newSipRequest.sip_extension_cli = '00' + this.newSipRequest.sip_extension_cli.replace(/\s/g, '');

        const hostNamePair: HostnameList = {
            sip_host_name:  f.value.sip_host_name,
            sip_voicemail_number : f.value.sip_voicemail_number
        }

        this.provisionSipSubsciption = this.numberService
            .provisionSip(this.newSipRequest)
            .pipe(
                tap(() => {
                    this.isSubmitting = true;
                }),
                finalize(() => {
                    this.isSubmitting = false;
                })
            )
            .subscribe((resp) => {
                this.dialogRef.close(true);
                this.isSuccess = true;
                this.clearFormValues(f);

                if ( !this.hostnameList.indexOf(hostNamePair)) {
                    this.hostnameList.push(hostNamePair)
                }
                this.newSipRequest = new SipProvisioningRequest(); //reset
            }, (err) => {
                this.openSnackBar('There was a problem with this request', 'Dismiss');
            });
    }


    // insertSpace(filterValue: string, formControl: FormControl) {
    //     let newValue = filterValue;
    //     if (filterValue.startsWith('44') && !filterValue.startsWith('44 ')) {
    //         newValue = filterValue.replace('44', '44 '); //only replaces first match
    //     }

    //     if (newValue !== filterValue) {
    //         formControl.patchValue(newValue, { emitEvent: false });
    //     }
    // }

    organisation_selectUpdate(event) {
        event ? this.newSipForm.controls['organisation_id'].patchValue(event, { emitEvent: true }) : this.newSipForm.controls['organisation_id'].patchValue('', { emitEvent: true });
        this.newSipForm.markAsDirty();
    }

    clearHostList() {
        this.filteredHosts = [];
        this.hostnameList = [];
    }

    clearFormValues(form: FormGroup) {
        form.reset();
        if (this.viewAs === 'sysAdmin') { this.orgSearchSelectComponent.clearValues();
        } else {
            this.data?.organisation_id ?  this.newSipForm.controls.organisation_id.patchValue( this.data?.organisation_id) : '';
            this.newSipForm.controls['sip_details'].enable();
        }
    }

    closeModal(dataChanged?: boolean) {
        this.dialogRef.close(dataChanged);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    scrollEvent(event) {
        const el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop !== el.clientHeight ? el.classList.add('pip-scroll-shadow-after') : el.classList.remove('pip-scroll-shadow-after');
    }

    ngOnDestroy() {
        if (this.provisionSipSubsciption) {
            this.provisionSipSubsciption.unsubscribe();
        }
    }
    hostSetVoicemmail(event) {
        this.newSipForm.controls['sip_details'].get('sip_voicemail_number').markAllAsTouched();
        this.newSipForm.controls['sip_details'].get('sip_voicemail_number').setValue(event.option.id, { emitEvent: true });
    }
    getHostnames(organisation_id: string) {
        organisation_id ? this.organisationService.getHostnamesforOrganisation(organisation_id)
            .subscribe((resp) => {
                this.hostnameList = resp;
                this.filteredHosts = resp;
            }) : '';
    }

    private _filterHost(value: string): HostnameList[] {
        if (!value) {
            this.newSipForm.controls['sip_details'].get('sip_voicemail_number').patchValue('', { emitEvent: true });
            this.newSipForm.controls['sip_details'].get('sip_voicemail_number').disable();
        } else {
            this.newSipForm.controls['sip_details'].get('sip_voicemail_number').enable();
        }
        const filterValue = value?.toLowerCase();
        return this.hostnameList.filter(item => item.sip_host_name.toLowerCase().includes(filterValue));
    }


}
