//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel, OrgMetaData } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { OrganisationDirectory, DirectoryService} from '@app/services/pipcall/directory.service';
import { Subscription } from 'rxjs';
// import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { TitleCasePipe } from '@angular/common';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';

import { UserProfile } from '@app/models/user-profile.model';
import { NumberService, AreaCode } from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';

import { AuthService } from '@app/services/auth-service/auth.service';
import { MatAccordion } from '@angular/material/expansion';
import { CookieService } from 'ngx-cookie-service';
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { InvitationProvision } from '@app/models/invitation.model';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { NormalizeToInternational } from '@app/pipes/formatNumber';
import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { escape, unescape} from 'safe-string-literal';
@Component({
    selector: 'app-onboard-organisation-details',
    templateUrl: './organisation-details.component.html',
    styleUrls: ['./organisation-details.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OnboardOrgDetailsComponent {
    @Input() stageId: number;
    @Input() organisation_id: string;
    @Input() organisationProfile: OrganisationModel;
    @Output() moveToNextStage = new EventEmitter();

    pageStatus = {
        error: false,
        isLoading: true,
        isSubmitting: false
    }
    updateOrganisationDetails: FormGroup;
    subscription: Subscription;
    showContactNumber = false;

    constructor(
        public dialog: MatDialog,
        private organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private appInsightService: AppInsightService,
        private staticDataService: StaticDataService,
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private cookieService: CookieService,
        private invitationService: InvitationService,
        private normalizeToInternational: NormalizeToInternational,
        private sharedService: SharedService,
        private signupService: SignupService
    ) {
        const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');
        this.updateOrganisationDetails = this._formBuilder.group({
            organisation_id: ['', [Validators.required]],
            name: ['', [Validators.pattern(regex), Validators.minLength(3), Validators.maxLength(32), Validators.required]],
            contact_phone_number: ['', [Validators.required, Validators.pattern('^([0-9]{12})$')]],
            organisation_size: ['', [Validators.required]]
        });
    }


    // convenience getters for easy access to form fields
    get f() { return this.updateOrganisationDetails.controls; }


    ngOnChanges(changes: SimpleChanges) {
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setFormValues(this.organisationProfile) : null;
    }

    ngOnInit() {
        //get name of current user for updated by
        this.sharedService.innit('onboarding organisation details');
    }
    setFormValues(organisationProfile: OrganisationModel) {
        this.setFormValue(this.updateOrganisationDetails, 'organisation_id', organisationProfile.id);
        organisationProfile.contact_phone_number ? this.setFormValue(this.updateOrganisationDetails, 'contact_phone_number', organisationProfile?.contact_phone_number) : null;
        if ((!this.updateOrganisationDetails.controls['contact_phone_number'].value || !this.updateOrganisationDetails.controls['contact_phone_number'].valid) && organisationProfile.is_pipxt_enabled) {
            this.showContactNumber = true;
            this.updateOrganisationDetails.controls['contact_phone_number'].markAsTouched();
        }
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    SaveOrganisationDetails(f: FormGroup) {
        if (f.invalid) { return; }
        console.log("update organisation details ------------", this.organisationProfile?.metadata)
        this.appInsightService.logEvent('Welcome | updated details', { 'organisation_id': this.organisation_id });
        this.pageStatus.isSubmitting = true;


        // ==========GET META / HANDLE IF EMPTY=====
        let _meta;
        let parseddata;
        try {
            _meta =  unescape(this.organisationProfile?.metadata);
            parseddata = JSON.parse(_meta);
        } catch (error) {
            // Handle the error here
            parseddata = null;
            console.error("Error parsing JSON:", error);
        }
        const meta: OrgMetaData = parseddata ?  parseddata : null;
        // ===============
        console.log("meta data 1: ", meta);

        // Initialize organisation_info if it doesn't exist
        if (!meta.organisation_info) {
            meta.organisation_info = {size: f.value.organisation_size};
        }
        // meta.organisation_info.size = f.value.organisation_size;
        console.log("meta data 2: ", meta);

        this.organisationService.putOrganisationDetails(f.value.organisation_id, f.value.name, f.value.contact_phone_number, meta)
            .subscribe(resp => {
                this.pageStatus.isSubmitting = false;
                this.openSnackBar('Saved', 'dismiss');
                //UPDATE THE METADATA STAGE NOW
                console.log("success : update organisation details, now move to next stage ------------1/2")
                this.organisationProfile.metadata = escape(JSON.stringify(meta));
                console.log("success : update organisation details, now move to next stage ------------2/2")
                this.moveToNextStage.emit(true);
            }, (err) => {
                console.log(err);
            })
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        // if (this._destroy$ && !this._destroy$.closed) {
        //     this._destroy$.next();
        //     this._destroy$.complete();
        // }
    }
}
