//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, collapseAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { OrganisationDirectory, DirectoryService} from '@app/services/pipcall/directory.service';
import { Subscription } from 'rxjs';
import { FirstPaymentModal } from '@app/components/modals/first-payment-modal/first-payment-modal.component';
import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UserProfileBottomSheetComponent} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.component';

@Component({
    selector: 'app-org-tabs-overview',
    templateUrl: './org-overview.component.html',
    styleUrls: ['./org-overview.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation(),
        collapseAnimation()
    ]
})
export class OrgTabOverviewComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional ?
    @Input() organisation_id: string;


    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isLoadingDirectory: false
    }

    trialDays: number;
    directoryConnections: Array<OrganisationDirectory>;

    //forms
    organisationNameForm: FormGroup;
    organisationContactNumberForm: FormGroup

    //subscription
    directorySubscription: Subscription;

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private directoryService: DirectoryService,
        private organisationService: OrganisationService,
        public dialog: MatDialog,
        private appInsightService: AppInsightService,
        private ref: ChangeDetectorRef,
        private bottomSheet: MatBottomSheet,
    ) {

        this.organisationNameForm = this._formBuilder.group({
            id: [{ value: '', disabled: true }, Validators.required],
            name: ['', {updateOn: 'change', validators: [Validators.required]}]
        });

        this.organisationContactNumberForm = this._formBuilder.group({
            id: [{ value: '', disabled: true }, Validators.required],
            name: ['', {updateOn: 'change', validators: [Validators.required]}],
            contact_phone_number:  [{ value: '', disabled: false },  [Validators.required, BusinessnumberValidator.validateNumber]]
        });


        this.directoryConnections = new Array<OrganisationDirectory>();
    }

    ngOnChanges(changes: SimpleChanges) {
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setOrganisationNameFormValues(this.organisationProfile) : null;
    }



    ngOnInit() {

        if ( !this.organisationProfile ) {
            this.getOrganisationDetails();
        } else {
            this.updateDirectory();
        }
    }


    setOrganisationNameFormValues(organisationProfile: OrganisationModel) {
        this.setFormValue(this.organisationNameForm, 'id', organisationProfile.id);
        this.setFormValue(this.organisationNameForm, 'name', organisationProfile.name);

        this.setFormValue(this.organisationContactNumberForm, 'id', organisationProfile.id);
        this.setFormValue(this.organisationContactNumberForm, 'name', organisationProfile.name);
        this.setFormValue(this.organisationContactNumberForm, 'contact_phone_number', organisationProfile?.contact_phone_number);

        this.trialDays = this.setTrialDate(this.organisationProfile?.trial_end_date);
        //set form permissions
        if ((organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Declined' || organisationProfile?.state === 'Cancelled') && this.viewAs !== 'sysAdmin') {
            this.organisationNameForm.disable();
        }
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getOrganisationDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;
        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {

                if (resp.status === 200 ) {
                    this.organisationProfile = resp.body;

                    this.setOrganisationNameFormValues(this.organisationProfile);
                    this.updateDirectory();
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })

    }

    setTrialDate(trialEndDate) {
        console.log("TRIAL DATE:", trialEndDate);
        const today = new Date();
        // const createdAt = new Date(this.orgProfile.created_at);
        const trialEnds = new Date(trialEndDate);
        trialEnds.setHours(23, 59, 59, 59);
        const diff = trialEnds.getTime() - today.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1; //added minus 1 to adjust for first day partial.
        console.log("TRIAL ENDS: ", trialEnds >= today ? diffDays : -1)
        return trialEnds >= today ? diffDays : -1;
    }

    cancelForm() {
        this.organisationNameForm.reset();
        this.setOrganisationNameFormValues(this.organisationProfile);
    }

    // saveOrganisationContactNumber(f: FormGroup) {
    //     if (f.valid) {
    //         this.submitForm(this.organisationService.patchOrganisationContactNumber());
    //     }
    // }

    SaveOrganisationName(f: FormGroup) {
        console.log('form', f.getRawValue());

        if (f.invalid) { return this.openSnackBar('form is invalid or has some missing values', 'dismiss'); }
        if (f.valid) {
            this.submitForm(this.organisationService.patchOrganisationName(f.getRawValue().id, f.getRawValue().name));
        }
    }

    SaveOrganisationDetails(f: FormGroup) {
        if (f.invalid) { return; }

        this.appInsightService.logEvent('Organisation overview | update details', { 'organisation_id': this.organisation_id });
        this.pageStatus.isSubmitting = true;
        this.submitForm(this.organisationService.putOrganisationDetails(f.getRawValue().id, f.getRawValue().name, f.value.contact_phone_number))
    }

    openFirstPayment() {
        const dialogRef = this.dialog.open(FirstPaymentModal, {
            panelClass: 'pipcall-fullscreen-modal-container',
            data: {organisation_id: this.organisation_id}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.updateParent.emit(true);
                window.location.reload();
            }
        });
    }

    goToDirectorySetup(organisation_id: string) {
        if (this.viewAs === 'sysAdmin') {
            this.router.navigateByUrl(`/admin/organisation/${organisation_id}/directory`);
        } else if (
            this.viewAs === 'isAdmin'
        ) {
            //route does not yet exist
            this.router.navigateByUrl(`/manage/users/${organisation_id}/directory`);

        }
    }

    updateDirectory() {
        return;
        if (!this.organisationProfile?.id || !this.organisationProfile.is_pipxt_enabled) {return; }

        this.pageStatus.isLoadingDirectory = true;
        this.directorySubscription = this.directoryService.getListOfDirectoryByOrg(this.organisationProfile?.id)
            .subscribe((resp) => {
                this.directoryConnections = resp.body;
                this.pageStatus.isLoadingDirectory = false;
                this.ref.detectChanges();
            }, (err) => {
                console.log('directory could not be updated', err);
                this.directoryConnections = null;
                this.pageStatus.isLoadingDirectory = false;
                this.ref.detectChanges();
            })
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    openAdminUser(user_id: string) {
        const data = {user: null, viewAs: this.viewAs, organisation_id: this.organisation_id, user_id : user_id}

        const dialogRef = this.bottomSheet.open(UserProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF BS", resp);
            this.ref.markForCheck();
            this.triggerUpdate();
            // alert(resp)
        });
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }



    triggerUpdate() {
        this.updateParent.emit(true);
        this.ref.detectChanges();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
