import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { cartProvision } from '../../models/cart.model';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '../../models/response.model';



@Injectable()
export class VerifyService {


    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;
    public options;


    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }



    public verifyCode(id: string, code: string, route: 'invitations' | 'carts'): Observable<responseModel> {
        console.log("[[api]] -> verify code");
        const body = {
            code: code
        }
        // return this.httpProxy.post(this.ApiBaseUrl + `/${route}/${id}/validcode${this.code}`, body, 'response', false);
        return this.httpProxy.post(this.ApiBaseUrl + `/${route}/${id}/validcode${this.code}`, body, 'response', false);
    }



}

