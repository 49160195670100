//core
import { Component, OnInit, Inject, isDevMode, ChangeDetectorRef, SimpleChanges, HostListener, ViewChild, ElementRef, EventEmitter, Input, Output, resolveForwardRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { map, tap, filter, catchError, mergeMap, finalize, first } from 'rxjs/operators';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CurrencyPipe } from '@angular/common'
import { PricingListItem } from '@app/models/license.model';
import { LicenseDataService, ContractType, LicenseType } from '@app/services/shared-data.service/license-data.service';

@Component({
    selector: 'app-view-licence-tariff',
    templateUrl: './view-licence-tariff.component.html',
    styleUrls: ['./view-licence-tariff.component.scss'],
    animations: [

    ]
})
export class LicenceTariffsComponent {


    @Input() isMobile: boolean; //will switch between mobile and standard display.
    @Input() onDark: boolean; //add the on-dark class to container
    @Input() isModeSelect: boolean // whether can select item
    @Input() numberType: 'mobile' | 'landline' | null;
    @Output() selectedLicense = new EventEmitter(); //still to implement


    public pricingList: PricingListItem[];
    public isPricingLoaded: boolean;
    public contractTypes: ContractType[];

    public intervalTypes = ['rolling']; //what types to show
    // public intervalTypes = ['annually', 'monthly', 'rolling'];

    public isEnabled = {
        talk: true,
        talk_and_text: true
    }

    constructor(
        private licenseDataService: LicenseDataService,
        public breakpointObserver: BreakpointObserver,
    ) {
        this.licenseDataService.innit();
        this.isPricingLoaded = false;
        this.contractTypes = new Array<ContractType>();
        this.pricingList = new Array<PricingListItem>();
    }

    ngOnInit() {
        console.log("numberType", this.numberType);
        if (this.numberType && this.numberType === 'landline') {
            this.isEnabled.talk = true;
            this.isEnabled.talk_and_text = false;
        } else if (this.numberType && this.numberType === 'mobile') {
            this.isEnabled.talk = false;
            this.isEnabled.talk_and_text = true;
        } else {
            this.isEnabled.talk = true;
            this.isEnabled.talk_and_text = true;
        }

        this.licenseDataService.pricingListAsObservable
            .subscribe((pricingList) => {
                console.log("RETURNED PRICE LIST:", pricingList);

                this.pricingList = pricingList ? pricingList : null;
                console.log("PRICING LIST:", this.pricingList)
                this.checkContentLoaded();
            });

        this.licenseDataService.contractTypesAsObservable.subscribe((resp) => {
            this.contractTypes = resp ? resp.sort((a, b) => (a.hierarchy < b.hierarchy) ? 1 : -1) : null; //sort in correct order
            this.contractTypes = resp ? resp : null;
            this.checkContentLoaded();
        });
    }


    checkContentLoaded() {
        if (this.pricingList?.length > 0 && this.contractTypes?.length > 0) {
            this.isPricingLoaded = true;
        } else {
            this.isPricingLoaded = false;
        }
    }

    selectLicence(licence: any) {
        this.selectedLicense.emit(licence);
    }

}
