<div fxLayout="column" fxFlex="100%" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">


    <form [formGroup]="updateOrganisationDetails" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">
        <div fxLayout="column" fxFlex="100%">

            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.gt-md="40px" fxLayoutGap.lt-lg="20px">

                <div fxLayout.lt-lg="column" fxLayout.gt-md="row" fxLayoutAlign="space-between"
                    fxLayoutAlign.lt-lg="start" fxLayoutGap.gt-md="20px" fxFlexOrder.gt-md="1" fxFlexOrder.lt-lg="2" fxFlex="60%" fxFlex.lt-lg="100%">

                    <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex.gt-md="70">
                        <input type="text" matInput placeholder="Enter organisation name" formControlName="name">
                        <mat-error
                            *ngIf="f?.name?.touched && f?.name?.errors?.pattern">
                            Invalid character</mat-error>
                        <mat-error
                            *ngIf="f.name?.touched && f.name?.errors?.maxLength">
                            max length 32 characters</mat-error>
                        <status-icons *ngIf="f.name?.touched"
                            [iconStatus]="updateOrganisationDetails.get('name').status">
                        </status-icons>
                        <mat-label>Organisation name</mat-label>
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex.gt-md="30">
                        <mat-select placeholder="Size" formControlName="organisation_size">
                            <mat-option value="1-5 users">
                                <span>1-5 users</span>
                            </mat-option>
                            <mat-option value="6-15 users">
                                <span>6-15 users</span>
                            </mat-option>
                            <mat-option value="16-29 users users">
                                <span>16-29 users</span>
                            </mat-option>
                            <mat-option value="30+ users">
                                <span>30+ users</span>
                            </mat-option>
                        </mat-select>
                        <mat-label>Size</mat-label>
                    </mat-form-field>

                </div>


                <div fxLayout="column" fxFlex="40%" fxFlex.lt-lg="100%" style="padding-top: 20px;" fxFlexOrder.gt-md="2"
                    fxFlexOrder.lt-lg="1">
                    <div>
                        Start by giving your organisation a name. This will be the name visible to any users you invite
                        to
                        your team.
                    </div>
                </div>

            </div>

            <div *ngIf="showContactNumber" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.gt-md="40px" fxLayoutGap.lt-lg="20px">
                
                <div fxLayout.lt-lg="column" fxLayout.gt-md="row" fxLayoutAlign="space-between"
                    fxLayoutAlign.lt-lg="start" fxLayoutGap.gt-md="20px" fxFlexOrder.gt-md="1" fxFlexOrder.lt-lg="2" fxFlex="60%" fxFlex.lt-lg="100%" >

                    <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex="100%">
                        <input type="text" matInput placeholder="Enter business contact number"
                          formControlName="contact_phone_number">
                        <status-icons *ngIf="f?.contact_phone_number?.touched"
                          [iconStatus]="updateOrganisationDetails.get('contact_phone_number').status">
                        </status-icons>
                        <mat-label>Contact number</mat-label>
                      </mat-form-field>
      
                    
                </div>

                <div fxLayout="column" fxFlex="40%" fxFlex.lt-lg="100%" style="padding-top: 20px;" fxFlexOrder.gt-md="2"
                    fxFlexOrder.lt-lg="1">
                    <div>
                        Please provide a contact number we can reach you on.
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" style="margin-top: 20px">
                <button mat-flat-button aria-label="Save changes" class="save-button"
                    [disabled]="updateOrganisationDetails?.pristine ||  pageStatus.isSubmitting || updateOrganisationDetails.invalid"
                    (click)="SaveOrganisationDetails(updateOrganisationDetails)">
                    <div><ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Continue</ng-container></div>
                </button>
            </div>
        </div>
    </form>


    <ng-template #loadingSpinner>
        <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
            <div style="font-size: 4px;" class="loader-spinner"></div>loading...
        </div>
    </ng-template>


    <ng-template #savingSpinner>
      <div>
        <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
      </div>
    </ng-template>
    <!-- 
  <div fxLayout="column" fxFlex="100%" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
    
    <div fxLayout="row"  fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

    <form [formGroup]="updateOrganisationDetails" fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%"
      fxFlexOrder.lt-md="2" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">

      <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between"
        fxLayoutGap.gt-sm="20px">

        <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex.gt-sm="70">
          <input type="text" matInput placeholder="Enter organisation name" formControlName="name">
          <mat-error
            *ngIf="updateOrganisationDetails?.controls.name?.touched && updateOrganisationDetails?.controls.name?.errors?.pattern">
            Invalid character</mat-error>
          <mat-error
            *ngIf="updateOrganisationDetails?.controls.name?.touched && updateOrganisationDetails?.controls.name?.errors?.maxLength">
            max length 32 characters</mat-error>
          <status-icons *ngIf="updateOrganisationDetails?.controls.name?.touched"
            [iconStatus]="updateOrganisationDetails.get('name').status">
          </status-icons>
          <mat-label>Organisation name</mat-label>
        </mat-form-field>


        <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex.gt-sm="30">
          <mat-select placeholder="Size" formControlName="organisation_size">
            <mat-option value="1-5 users">
              <span>1-5 users</span>
            </mat-option>
            <mat-option value="6-15 users">
              <span>6-15 users</span>
            </mat-option>
            <mat-option value="16-29 users users">
              <span>16-29 users</span>
            </mat-option>
            <mat-option value="30+ users">
              <span>30+ users</span>
            </mat-option>
          </mat-select>
          <mat-label>Size</mat-label>
        </mat-form-field>


      </div>


      <button mat-flat-button aria-label="Save changes" class="save-button"
        [disabled]="updateOrganisationDetails?.pristine ||  pageStatus.isSubmitting || updateOrganisationDetails.invalid"
        (click)="SaveOrganisationDetails(updateOrganisationDetails)">
        <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Continue</ng-container>
      </button>

    </form>

    <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1" style="margin-top: 15px;">
      Start by giving your organisation a name. This will be the name visible to any users you invite to
      your team. We also need a contact number we can reach you on should we ever need to discuss your
      account.
    </div>
  </div>

</div> -->