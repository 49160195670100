//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import { InvoiceService, PdfResponse } from '@app/services/pipcall/invoice.service';
import * as FileSaver from 'file-saver';
import { InvoiceLicenseItem, Invoice, AddOnPackageItem, StubItem, AdHocItem } from '@app/models/invoice.model';

@Component({
    selector: 'app-invoice-tabs-view',
    templateUrl: './invoice-view.component.html',
    styleUrls: ['./invoice-view.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class InvoiceViewComponent {


    @Input() viewAs: string;
    @Input() invoice: Invoice;
    @Input() invoiceLicenseItems: InvoiceLicenseItem[];
    @Input() addOnPackageItems: AddOnPackageItem[];
    @Input() licenseStubItems: StubItem[];
    @Input() adHocItems: AdHocItem[];

    @Output() updateParent = new EventEmitter();
    @Output() updateParentModal = new EventEmitter();


    public _invoiceLicenseItems = []; //grouped items


    noContent = false;
    _destroy$ = new Subject<void>();


    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }


    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private ref: ChangeDetectorRef,
        private invoiceService: InvoiceService
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        (changes['invoiceLicenseItems']?.currentValue !== changes['invoiceLicenseItems']?.previousValue) &&  !changes['invoiceLicenseItems']?.isFirstChange() ?  this.setFormValues(this.invoiceLicenseItems) : null;
        // (changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue) &&  !changes['organisationProfile']?.isFirstChange() ?  this.ref.markForCheck() : null;
    }



    ngOnInit() {
        if (this.invoice) {
            this.setFormValues(this.invoiceLicenseItems);
            this.pageStatus.isLoading = false;
        }

    }


    setFormValues(invoiceLicenseItems: InvoiceLicenseItem[]) {
        this._invoiceLicenseItems = this.buildInvoice(invoiceLicenseItems);

    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    buildInvoice(list: InvoiceLicenseItem[]) {
        // group license items that are identical
        const _newList = [];
        list?.forEach(licenseItem => {

            const itemIndex = _newList.map((item) => { return item.license_id; }).indexOf(licenseItem.license_id);
            // for each item does it already exist in new _list
            //  let matchedItem = list.find(item => item.id === licenseItem.license_id);
            const entry = {
                license_id: licenseItem.license_id,
                quantity: 1,
                license_name: licenseItem.license_name,
                license_contract_name: licenseItem.license_contract_name,
                price: licenseItem.price
            }
            if (itemIndex > -1) {
                //if there is an existing item with same id. only increase the quantity
                _newList[itemIndex].quantity++; //increate the quantity
            } else { _newList.push(entry) }; //if entry already exists, add Quantity to it
        });
        this.pageStatus.isLoading = false;
        return _newList;
    }

    downloadPdf(id: string) {
        console.log('DOWNLOAD:', id);
        this.invoiceService.getInvoicePdf(this.invoice.organisation_id, id).subscribe((resp) => {
            console.log("get invoice pdf:", resp)
            resp.success ? FileSaver.saveAs(resp.fileUrl) : this.openSnackBar("Sorry, could not get this pdf at this time. Please try later", "dismiss");
        }, (err) => {
            console.log(err);
            this.openSnackBar("Sorry, could not get this pdf at this time. Please try later", "dismiss")
        })
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
        this.ref.markForCheck();
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
