import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '../../../services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';

//create modal components
import { CreateDdiModalComponent} from './create-ddi-modal.component';


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomPipesModule
    ],
    declarations: [CreateDdiModalComponent],
    exports: [CreateDdiModalComponent],
    providers: [HttpProxy],
    entryComponents: [CreateDdiModalComponent]
})
export class CreateDdiModalModule { }
