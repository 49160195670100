import { environment } from '../../../environments/environment';

interface AuthConfiguration {
    clientID: string,
    domain: string,
    scope: string,
    namespace: string,
    audience: string
}

export const myConfig: AuthConfiguration = {
    clientID: environment.auth0_clientID,
    domain: environment.auth0_domain,
    scope: 'openid profile admin:user',
    namespace: `https://${environment.auth0_domain}`,
    audience: environment.auth0_audience
};

