import { NgModule } from '@angular/core';
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { GoToPaymentPromptModal } from './root-modal-message.component';
import { FlexLayoutModule } from '@angular/flex-layout';
@NgModule({
    imports: [
        MaterialModule,
        DragDropModule,
        CommonModule,
        FlexLayoutModule
    ],
    declarations: [GoToPaymentPromptModal],
    exports: [],
    providers: [],
    entryComponents:[GoToPaymentPromptModal]
})
export class RootModalMessageModule { }