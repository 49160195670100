//core
import { Component, OnInit, Input, EventEmitter, ElementRef, Output, ViewChild , ChangeDetectorRef, SimpleChanges} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';

import { Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from "rxjs";

import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';

import { InvoiceListModel } from '@app/models/invoice.model';
import { InvoiceBottomSheetComponent } from '@app/components/bottom-sheet-modal/invoice-bsheet-modal/invoice-bsheet-modal.component';
//<cto-invoice [invoice]="row" [viewAs]="viewAs"   (triggerUpdate)="triggerUpdate()"></cto-invoice>

@Component({
    selector: 'cto-invoice',
    templateUrl: './cto-invoice.component.html',
    styleUrls: ['./cto-invoice.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class InvoiceClickComponent {
    @Input() invoice: InvoiceListModel;
    @Input() viewAs: string;
    @Input() version?: number; //if no version show default

    @Output()triggerUpdate = new EventEmitter();


    _destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet
    ) {

    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        // changes['organisation_name']?.currentValue !== changes['organisation_name']?.previousValue ? this.getOrgUserList() : null;
    }


    openInvoice(invoice_id: string, viewAs: string) {
        const data = {invoice_id: invoice_id, viewAs: this.viewAs}

        const dialogRef = this.bottomSheet.open(InvoiceBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF Invoice BOTTOM SHEET---------------------------------lsksj", resp);

            if (resp) {
                this.emitValue(resp);
            }
        });
    }

    emitValue(update: boolean) {
        console.log("EMIT VALUE");
        this.triggerUpdate.emit(update);
    }

    isOverdue(datestring) {
        const date = new Date(datestring);
        const today = new Date();
        //remove hours
        date.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        //is less than or equal

        return date <= today;
    }

    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }
}
