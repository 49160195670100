import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components
import { InvoiceDeleteModalComponent } from './delete-invoice-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceService } from '@app/services/pipcall/invoice.service';
@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [InvoiceDeleteModalComponent],
    exports: [InvoiceDeleteModalComponent],
    providers: [AppInsightService, AuthService, InvoiceService],
    entryComponents: [InvoiceDeleteModalComponent]
})
export class InvoiceDeleteModalModule { }
