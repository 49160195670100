<div fxLayout="column" fxLayoutAlign="space-between none" class="main-container">
  <button  class="pip-rightnav-close_btn" mat-mini-fab (click)="close()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="main-content">

      Help Center Here
  </div>


</div>




<ng-template #loadingSpinner class="text-center">
  <div class="loader-spinner"></div>
</ng-template>
