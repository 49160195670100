import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule } from '@app/pipes/pipes.module'
//services
import { HttpProxy } from '@app/services/http-proxy.service';
import {StatusIconsModule} from '@app/components/status-icons/status-icons.module';
import { UpdateCardModalComponent } from './update-card-modal.component'
import { UserService} from '@app/services/pipcall/user.service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { StripePaymentModalModule} from '@app/components/stripe-payment/stripe-payment.module';
import { PaymentsService} from '@app/services/pipcall/payments.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        CustomPipesModule,
        FlexLayoutModule,
        StatusIconsModule,
        StripePaymentModalModule
    ],
    declarations: [UpdateCardModalComponent],
    exports: [UpdateCardModalComponent],
    providers: [HttpProxy, UserService, SignupService, PaymentsService],
    entryComponents: [UpdateCardModalComponent]
})
export class UpdateCardDetailsModalModule { }
