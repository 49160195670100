<div class="pip-modal-content">
    <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
        <mat-icon svgIcon="clear"></mat-icon>
    </button>
    <div class="pip-modal-header">
        <span class="pip-modal-title">
            <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
                <h4 class="modal-header-title">Expo Signup Details</h4>
                <div class="status-title"><span *ngIf="d.state === 'New'">Pending verification</span><span
                        *ngIf="d.state === 'Migrated'">Migrated</span></div>
            </div>
        </span>
    </div>

    <div class="pip-modal-body" class="pip-modal-body">
        <div class="detail-section" fxLayout="column">
            <div fxLayoutAlign="row" fxLayoutAlign="start center">
                <div class="item-heading">Name:</div>
                <div class="item-value">
                    <mat-form-field appearance="outline" class="custom-form-field-v3 ">
                        <input type="text" matInput placeholder="placeholder" value="{{d.first_name}} {{d.last_name}}"
                            [disabled]=true>
                    </mat-form-field>

                </div>
            </div>

            <div fxLayoutAlign="row" fxLayoutAlign="start center">
                <div class="item-heading">Organisation:</div>
                <div class="item-value">
                    <mat-form-field appearance="outline" class="custom-form-field-v3 ">
                        <input type="text" matInput placeholder="placeholder" value="{{d.organisation_name}}"
                            matTooltip="{{d.organisation_name}}" [disabled]=true>
                        <mat-icon matSuffix svgIcon="open_in_new" class="clickable action-icon"
                            matTooltipShowDelay="200" matTooltip="Open organisation in new window"
                            (click)="openOrganisation(d.organisation_id)" *ngIf="d.organisation_id"></mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayoutAlign="row" fxLayoutAlign="start center">
                <div class="item-heading">Email:</div>
                <div class="item-value">
                    <mat-form-field appearance="outline" class="custom-form-field-v3 ">
                        <input type="text" matInput placeholder="placeholder" value="{{d.email}}" [disabled]=true>
                        <mat-icon matSuffix svgIcon="copy" class="clickable action-icon" matTooltipShowDelay="200"
                            matTooltip="Copy email" (click)="copyInputToClipboard(d.email)"></mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayoutAlign="row" fxLayoutAlign="start center">
                <div class="item-heading">Mobile:</div>
                <div class="item-value">
                    <mat-form-field appearance="outline" class="custom-form-field-v3 ">
                        <input type="text" matInput placeholder="placeholder"
                            value="{{d.mobile | normalizeToInternational}}" [disabled]=true>
                        <mat-icon matSuffix svgIcon="copy" class="clickable action-icon" matTooltipShowDelay="200"
                            matTooltip="Copy mobile" (click)="copyInputToClipboard(d.mobile)"></mat-icon>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div fxLayoutAlign="row" fxLayoutAlign="start center" class="status-panel">
            <div class="item-heading">Status:</div>
            <div class="item-value">
                <div style="width: 100%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                    <div class="status-icon active" matTooltip="Initial sign up form competed">Registered</div>
                    <div class="status-icon" [ngClass]="{'active': d.is_mobile_verified}">Verified</div>
                    <div class="status-icon" [ngClass]="{'active': d.state === 'Migrated'}">Migrated</div>
                    <div class="status-icon" [ngClass]="{'active': d.first_launch_date !== null}">App launched</div>
                </div>
            </div>
        </div>


        <div fxLayoutAlign="row" fxLayoutAlign="start center" class="salesperson-panel">
            <div class="item-heading">Sales person:</div>
            <div class="item-value" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center"
                fxLayoutGap.gt-xs="20px" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center"
                fxLayoutGap.lt-sm="-10px">
                <mat-form-field appearance="outline" class="custom-form-field-v3 ">
                    <input type="text" matInput placeholder="" value="{{d.sales_user_name}}" [disabled]=true>
                </mat-form-field>

                <button class="claim-button" mat-flat-button color="accent" (click)="confirmSalesPerson()">Claim
                    sale</button>
            </div>
        </div>

        <div fxLayoutAlign="row" fxLayoutAlign="start center" class="notes-panel">
            <div class="item-heading">Notes:</div>
            <form [formGroup]="_updateNote" class="item-value" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field appearance="outline" class="custom-form-field-v3 ">
                    <textarea formControlName="note" matInput placeholder="start typing..."
                        class="note-input"></textarea>
                    <!-- <mat-hint>Max 82 characters</mat-hint> -->
                </mat-form-field>
            </form>
        </div>

        <!-- <div>
            <button mat-flat-button (click)="emitChange()">test</button>
        </div> -->

    </div>


    <div class="pip-modal-footer" style="padding-top: 10px;">

        <ng-container *ngIf="_updateNote.dirty;">
            <div fxLayoutAlign="row" fxLayoutAlign="center" fxLayoutGap="8px">
                <button mat-flat-button class="cancel-button" (click)="cancelNote()">Cancel</button>
                <button (click)="saveNote(_updateNote)" [disabled]="!_updateNote.valid || pageStatus.isSubmitting"
                    mat-flat-button class="save-button">
                    <ng-container *ngIf="!pageStatus?.isSubmitting; else savingSpinner">Save note</ng-container>
                </button>
            </div>
        </ng-container>


        <ng-container *ngIf="!_updateNote.dirty && d.state === 'New'">
            <div fxLayoutAlign="row" fxLayoutAlign="center">
                <button [disabled]="!d.mobile || pageStatus?.isSubmitting" mat-flat-button class="force-migration-btn"
                    (click)="confirmForceMigrate()"><ng-container
                        *ngIf="!pageStatus?.isSubmitting; else savingSpinner">Force migration</ng-container></button>
            </div>
        </ng-container>


    </div>
</div>

<ng-template #savingSpinner>
    <div>
        <div class="loader-spinner"></div>
    </div>
</ng-template>