<ng-container *ngIf="layoutCheckReturned">

  <!--############################## custom layout with no side nav ############################-->
  <ng-container *ngIf="isCustomLayout; else templateLayout">
    <router-outlet></router-outlet>
  </ng-container>

  <ng-template #templateLayout>


    <app-toolbar class="toptoolbar-custom dark-background fixed-toolbar" [viewAs]="viewAs" [isloading]="isLoading"
      [style.width.%]="100" (navToggle)="appDrawer.toggle()"> </app-toolbar>


    <mat-sidenav-container>

      <!-- left nav bar. Only showing for sysadmin portal -->
      <!-- *ngIf="viewAs && viewAs === 'sysAdmin'" -->
      <mat-sidenav *ngIf="viewAs && viewAs === 'sysAdmin'" [fixedTopGap]="58" fixedInViewport="true"
        class="navigation-menu" #appDrawer [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="!(isHandset$ | async)" disableClose=false position="start">
        <app-sidenav [isHandSet]="(isHandset$ | async)" (closeNavOnEvent)="closeSideNav()"></app-sidenav>
      </mat-sidenav>

      <!-- right sidenav with dynamic content using cdk portal -->
      <mat-sidenav [fixedTopGap]="58" fixedInViewport="true" class="navigation-menu-right" #rightSideNav mode="over"
        position="end" opened="{{r_opened}}" (openedChange)="r_opened = $event" [disableClose]="disableCloseRightnav">

        <app-sidenav-right></app-sidenav-right>

      </mat-sidenav>

      <mat-sidenav-content style="margin-top:58px;" class="main-content" [ngClass]="{'cc-background-animated-wave large-size': isAnimatedBackdrop1}">
        <div class="router-outlet-templatelayout">
          <!-- <app-toolbar class="toptoolbar-custom dark-background" [isloading]="isLoading" [style.width.%]="100"> </app-toolbar> -->
          <router-outlet (activate)="componentAdded($event)"></router-outlet>
          <app-footer></app-footer>
        </div>

        <ng-container *ngIf="isAnimatedBackdrop1">
          <div class="cc-wave"></div>
          <div class="cc-wave"></div>
          <div class="cc-wave"></div>
        </ng-container>
      </mat-sidenav-content>

    </mat-sidenav-container>


  </ng-template>


</ng-container>