import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSortable, MatSnackBar, MatDialog, MatSort, MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent, MatSelectionList, MatList, Sort } from '@app/material/material-essentials.module';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { FormBuilder, FormGroup } from '@angular/forms';

import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { OrganisationModel } from '@app/models/organisation.model';
import { UserProfile } from '@app/models/user-profile.model';

import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';


@Component({
    selector: 'app-ff-org-user-search-select',
    templateUrl: './ff-org-user-search-select.component.html',
    styleUrls: ['./ff-org-user-search-select.component.scss']
})
export class OrgUserSearchSelectComponent implements OnInit {


    @Input() placeholder: string;
    @Input() organisation_id: string;
    @Input() organisationUserList: Array<UserProfile>; //OPTIONAL
    @Input() disabled: boolean;

    @Input() simpleLayout: boolean; //slim searcg bar
    @Output() _selectedItem = new EventEmitter();

    //settings
    @Input() FilterOutisAdmin: boolean; //exclude admins from results


    isLoading = false;
    _destroy$ = new Subject<void>();


    public dataSource: MatTableDataSource<UserProfile>;

    //filter rules
    public filteredValues = {
        isAdmin: null,
        mobile: "",
    };

    itemForm: FormGroup;
    userInputCtrl = new FormControl();
    @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoComplete') matAutocomplete: MatAutocomplete;


    constructor(
        public organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private fb: FormBuilder
    ) {
        this.dataSource = new MatTableDataSource();
        this.dataSource.filterPredicate = this.customFilterPredicate();

        this.itemForm = this.fb.group({
            userInputCtrl: ''
        })

    }

    ngOnInit() {

        if (!this.organisationUserList) {
            this.getData();
        } else {
            this.dataSource.data = this.organisationUserList
            this.applyFilter();
            this.isLoading = false;
        }

        this.itemForm
            .get('userInputCtrl')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => this.isLoading = true),
            )
            .subscribe((resp) => {
                console.log("value change response", resp);
                this.applyFilter(resp);
            }, (err) => {
                // this.filteredItems = [];
                this.isLoading = false;
                console.log("ERROR", err);
            });
    }


    ngAfterViewInit() {  }

    ngOnChanges(changes: SimpleChanges) {
        (changes['organisation_id']?.currentValue !== changes['organisation_id']?.previousValue ) && !changes['organisation_id'].isFirstChange() ? this.getData() : null;
        (changes['organisationUserList']?.currentValue !== changes['organisationUserList']?.previousValue) && !changes['organisationUserList'].isFirstChange() ?  this.dataSource.data = this.organisationUserList : null;
    }


    getData() {
        if (this.organisation_id) {
            this.dataSource.data = [];
            this.isLoading = true;
            this.organisationService.getOrgUsersById(this.organisation_id)
                .pipe(
                    finalize(() => { this.isLoading = false; }),
                    takeUntil(this._destroy$),
                )
                .subscribe((resp) => {
                    this.dataSource.data = resp.body.user_list;
                    this.applyFilter();
                    this.isLoading = false;
                }, (err) => {
                    console.log(err)
                    this.isLoading = false;
                })
        }
    }


    customFilterPredicate() {
        const myFilterPredicate = (data: UserProfile, filter: string): boolean => {


            let isGlobalMatch = !this.itemForm.get('userInputCtrl').value;

            if ( this.itemForm.get('userInputCtrl').value) {
                // search all text fields for string match
                isGlobalMatch = this.searchGlobalMatch([data?.first_name, data?.last_name, data?.email, data.id], this.itemForm.get('userInputCtrl').value);
            } else { return false}
            if (!isGlobalMatch) {
                //actiate only on search
                return; }


            // apply all custom filters ( if any )
            const filteredValues = JSON.parse(filter);


            //return true to declare a match
            return true;
        }
        return myFilterPredicate;
    }

    searchGlobalMatch(fieldsToSearch: any[], searchString) {
        searchString = searchString?.toLowerCase();
        let isGlobalMatch = false;

        fieldsToSearch.forEach((field, i) => {
            if (field != null) {
        field?.toString().trim().toLowerCase().indexOf(searchString) !== -1 ? isGlobalMatch = true : '';
            }
        })
        return isGlobalMatch;
    }

    applyFilter(searchString?: string) {
        console.log("APPLY SEARCH FILTER:", searchString);
        this.dataSource.filter = JSON.stringify(this.filteredValues);

        setTimeout(() => {
            this.isLoading = false;
        }, 300);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        console.log("SELECT EVENT ID:", event.option?.id);

        const item = this.dataSource.data.find(data => data.id === event.option?.id);
        this._selectedItem.emit(item);

    }

    public clearValues() {
        this.itemForm.get('userInputCtrl').setValue('', { emitEvent: false });
        this._selectedItem.emit('');
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
