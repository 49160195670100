
import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, FormControl, Validators, FormGroup } from '@angular/forms';

// for help : https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/#_custom_model_form_validator
@Directive({
    selector: '[matchEmail]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: EmailMatchValidation, multi: true }
    ]
})
export class EmailMatchValidation {

    validate(confirmEmail: AbstractControl): ValidationErrors | null {
        return EmailMatchValidation.MatchEmail(confirmEmail);
    }


    static MatchEmail(AC: AbstractControl) {
        let confirmEmail = AC.value;

        if (confirmEmail) {
            let email = AC.parent.value["email"];
            console.log("email confirm", email);
            if(email.toLowerCase() != confirmEmail.toLowerCase()) {
                     return {MatchEmail:"Emails do not match"}
                 } else {
                     return null
                 }
        }

        // console.log("email",email);
        // let email = AC.get('email').value; // to get value in input tag
        // let confirmEmail = AC.get('confirmEmail').value; // to get value in input tag
        //  if(email != confirmEmail) {
        //      console.log('false');
        //      AC.get('confirmEmail').setErrors( {MatchEmail: true} )
        //  } else {
        //      console.log('true');
        //      return null
        //  }
        return null;
    }

}
