import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService} from './shared-data.service';
import { LicenseDataService} from './license-data.service';
import { CookieService } from 'ngx-cookie-service';
import { StaticDataService} from './staticdata.sevice'
import { RouterModule} from '@angular/router';
import { TokenService } from '@app/services/token.service';

@NgModule({})
export class SharedDataServiceModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedDataServiceModule,
            providers: [SharedService, LicenseDataService, CookieService, TokenService, StaticDataService, RouterModule]
        };
    }
}
