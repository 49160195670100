<div fxLayout="column" fxFlex="100%" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">


    <ng-container *ngIf="extensionsList?.length > 0; else noExtensions">

        <div fxLayout="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div> Add users to your extensions, who will have access to the PiPcall app during your trial. Users will
                receive an email with instructions to create their PiPcall accounts and access the app. Can also be
                added later from the users tab. </div>

        </div>
        <form [formGroup]="dynamicForm">
            <div fxLayout="column" fxFlex="100%">
                Extensions
                <ng-container *ngFor="let extension of t.controls; let i = index">
                    <div [formGroup]="extension" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px" [ngClass.lt-lg]="'add-margin-bottom'"
                        style="position: relative;">

                        <div class="sent-icon">
                            <mat-icon
                            *ngIf="extension.controls.sent.value === 'sent' || extension.controls.sent.value === 'completed'"
                            [@bounceInLeftOnEnter] svgIcon="paper-plane-sent"></mat-icon>
                        </div>
                        <div  [ngClass.lt-sm]="'mobile-view-field'" [matTooltip]="extension.controls.display_cli.value"  matTooltipPosition="above">
                            <input type="hidden" name="name" [value]="extension.number" hidden>
                            <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block"
                            style="width: 200px;">
                            <input type="text" matInput tabindex="-1" formControlName="display_cli" readonly>
                            <mat-icon svgIcon="phone" matPrefix ></mat-icon>
                            <status-icons
                            *ngIf="extension.controls.email.touched && extension.controls.email.value !== '' && extension.controls.sent.value === '' "
                            [hasPrefix]="'true'" [iconStatus]="extension.controls.email.status"></status-icons>
                        </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline" class="custom-form-field-v3 emailForm"
                                [ngClass]="{'readonly-block': pageStatus.isSubmitting || extension.controls.sent.value !== ''}">
                                <input class="overflow-elipsis" type="email" matInput placeholder="Enter users email" formControlName="email" matTooltip="{{extension.controls.email.value}}" matTooltipClass="do-not-truncate-tooltip">
                                <!-- <mat-icon svgIcon="email" matPrefix fxHide.lt-sm></mat-icon> -->
                                <mat-error *ngIf="extension.controls.email.errors?.required">
                                    Email is required</mat-error>
                                <mat-error *ngIf="extension.controls.email.errors?.email">
                                    Email must be a valid email address</mat-error>
                                <mat-error *ngIf="extension.controls.email.status === 'PENDING'">
                                    checking ...
                                </mat-error>
                                <mat-error
                                    *ngIf="extension.controls.email.errors && extension.controls.email.value !== '' && extension.controls.email.errors.emailTaken">
                                    Email already in use
                                </mat-error>
                                <mat-error
                                    *ngIf="extension.controls.email.errors && extension.controls.email.value !== '' && extension.controls.email.errors?.apiError">
                                    There was an error trying to verify email, please try again later.
                                </mat-error>
                                
                                <mat-hint fxHide.gt-sm fxShow.lt-md align="end"><button class="smallBtn" *ngIf="i === 0 && !pageStatus.isSubmitting && extension.controls.email.value === '' && extension.controls.sent.value === '' && !userProfile?.mobile && !userProfile?.organisation_list[0]?.invitation_id" color="accent" (click)="addMyself(i)" mat-flat-button>Add myself</button></mat-hint>
                            </mat-form-field>
                        </div>

                        <div [ngClass.lt-md]="'mobile-view-right-indicator'">
                            <div *ngIf="i === 0 && !pageStatus.isSubmitting && extension.controls.email.value === '' && extension.controls.sent.value === '' && !userProfile?.mobile && !userProfile?.organisation_list[0]?.invitation_id"> 
                                <button fxHide.lt-md fxShow.gt-sm mat-flat-button color="accent" (click)="addMyself(i)">Add Myself</button>
                            </div>
                            <ng-container
                                *ngIf="pageStatus.isSubmitting && extension.controls.sent.value === 'sending' && extension.controls.email.value !== '' && extension.controls.email.valid">
                                <ng-container *ngTemplateOutlet="savingSpinner"></ng-container>
                            </ng-container>

                            <div  [@fadeOutOnLeave]  *ngIf="extension.controls.sent.value === 'sent'" style="color: var(--c-body)">Sent
                            </div>
                        </div>
                    </div>

                </ng-container>

            </div>
        </form>

        <button mat-flat-button color="accent" (click)="sendInvitations()"
            [disabled]="!isValidFormWithEmail() || dynamicForm?.pristine || pageStatus.isSubmitting || pageStatus?.invitationsSent === true">Send Invitations&nbsp;&nbsp;
            <mat-icon svgIcon="send-mail"></mat-icon></button>


        <!-- <div>
            <div>xExtensions: {{extensionsList?.length}}</div>
            <div>xFormControls: {{t.controls?.length}}</div>
            <div>Admin email: {{userProfile?.email}}</div>
            <div>Admin mobile: {{userProfile?.mobile}} // handle when this is not null</div>
            <div>Valid form: [{{dynamicForm?.valid}}]</div>
            <div>// include a title field on email input. use overflow elipses for long emails</div>
            <div>// check the user for existing mobile first ! dont show add button if there is one present.</div>
            <button mat-button (click)="debugForm()">Debug Form</button>
        </div> -->

        <!-- <div *ngFor="let frm of t.controls;let i = index">{{i+1}} Form Status: {{frm.status}} | is Form Valid:{{frm.valid}}
            <button mat-flat-button (click)="frm.controls.sent.setValue('sent')">set to sent</button>
        </div>
      -->
    </ng-container>

    <ng-template #noExtensions>

        <div>
            <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
                <div class="bounce4"></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center" fxFlex="100%" style="text-align: center;">
            Please wait while we build your system. Check back here in a few minutes.<br>
            If you need to speak to us, please call us on 0330 094 8086.
        </div>

    </ng-template>


</div>


<ng-template #loadingSpinner>
    <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
        <div style="font-size: 4px;" class="loader-spinner"></div>loading...
    </div>
</ng-template>

<ng-template #savingSpinner>
    <div>
        <div class="loader-spinner"></div> Sending...
    </div>
</ng-template>