//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { OrganisationDirectory, DirectoryService , DirectoryType} from '@app/services/pipcall/directory.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service';
import { HelpCenterRightMenuComponent } from '@app/components/right-menu-overlay/help-center-right-menu/help-center-right-menu.component';
import { escape, unescape} from 'safe-string-literal';

@Component({

    selector: 'app-org-directories-panel',
    templateUrl: './org-directories-panel.component.html',
    styleUrls: ['./org-directories-panel.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class  OrgDirectoriesPanelComponent {


    @Input() viewAs: string;
    @Input() organisation_id: string;
    @Input() is_pipxt_enabled: boolean;
    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        errorMessage: ''
    }


    //directories
    public directoryConnections: Array<OrganisationDirectory>;
    public availableDirectoryTypes: Array<DirectoryType>;
    public notFound: boolean = null;

    connectionForm: FormGroup;
    public showAddNewForm: false;
    public configs = [];


    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private appInsightService: AppInsightService,
        private directoryService: DirectoryService,
        private sidenavRightService: SidenavRightService
    ) {

        this.connectionForm = this._formBuilder.group({
            organisation_id: [null, Validators.required],
            directory_type_id: [null, Validators.required],
            priority: [1, Validators.required],
            configuration_json: ['', Validators.required],
        });

        this.pageStatus.isLoading = true;
        // this.pageStatus.isConnectionLoading = true;
        this.availableDirectoryTypes = new Array<DirectoryType>();
        this.directoryConnections = new Array<OrganisationDirectory>();
        this.getDirectoryTypes();

        //add production and dev ids
        this.configs = [
            {id: '37952501-DCD5-EB11-A7AD-281878FB26E2', sample: `{"baseUrl":"https://example.domain.com/","apiKey":"xxxxxxxxyour-api-keyxxxxxxx", "tenant":"xxServerNumberHerexx"}` },
            {id: 'C6D0FD2B-FDDF-EB11-A7AD-00155D07CBC6', sample: `{"baseUrl":"https://example.domain.com/","apiKey":"xxxxxxxxyour-api-keyxxxxxxx", "tenant":"xxServerNumberHerexx"}` }
        ];
    }

    ngOnInit() {

        if ( !this.is_pipxt_enabled ) {
            this.pageStatus.isLoading = false;
        } else {


            this.resetConnectionForm();

            if (this.organisation_id) {
                this.getDirectoryData();
                this.setFormValues();
            } else {
                this.handleNoOrganisationId();
            }
        }


    }

    ngOnChanges(changes: SimpleChanges) {
    }


    ngAfterViewInit() {}

    getDirectoryTypes() {
        this.directoryService.getListOfDirectoryTypes()
            .subscribe((resp) => {
                this.availableDirectoryTypes = resp.body;
            })
    }

    getDirectoryData() {
        this.pageStatus.isLoading = true;
        this.directoryConnections = [];
        this.directoryService.getListOfDirectoryByOrg(this.organisation_id)
            .subscribe((resp) => {
                if (resp.status === 200) {
                    ///directory
                    this.directoryConnections = resp.body;
                    this.pageStatus.isLoading = false;
                    this.ref.detectChanges();
                // this.pageStatus.isConnectionLoading   = false;
                }
            },
            (err) => {
                console.log(err);
                this.directoryConnections = null;
                this.handleNoOrganisationId();
                this.ref.detectChanges();
            }
            )
    }
    handleNoOrganisationId() {
        //handle no content here //update isloading
        this.pageStatus.isLoading = false;
        this.pageStatus.isError = true;
        this.pageStatus.errorMessage = 'Could not get directory information';
    }

    resetConnectionForm() {
        this.connectionForm.reset();
        this.connectionForm.controls.organisation_id.patchValue(this.organisation_id);
        this.connectionForm.controls.directory_type_id.patchValue('');
        this.connectionForm.controls.priority.patchValue(1);
        this.connectionForm.controls.configuration_json.patchValue('');
    }

    setFormValues() {
        this.setFormValue(this.connectionForm, 'organisation_id', this.organisation_id);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    switchDirectoryTypeEvent(value) {
        this.connectionForm.controls.directory_type_id.patchValue(value);
        this.connectionForm.controls.organisation_id.patchValue(this.organisation_id);

        const config =  this.configs.find(i => i.id === value);
        config ? this.connectionForm.controls.configuration_json.patchValue(config.sample) : '';
    }

    toggleAddNewForm(val) {
        this.resetConnectionForm();
        this.showAddNewForm = val;
    }

    returnUnescapeJSON(val) {
        return val ? unescape(val) : val;
    }
    saveNewConnection(f: FormGroup) {
        if (f.status !== 'VALID') {return};

        // const escapedContent = escape(f.value.configuration_json)
        this.pageStatus.isSubmitting = true;
        this.directoryService.postOrganisationDirectory(f.value.organisation_id, f.value.directory_type_id, f.value.priority, f.value.configuration_json)
            .pipe(
                tap(() => this.pageStatus.isSubmitting = true),
                finalize(() => this.pageStatus.isSubmitting = false)
            )
            .subscribe((resp) => {
                this.toggleAddNewForm(false);
                this.ngOnInit();
            }, (err) => {
                console.log(err);
                this.openSnackBar('There was an error saving this connection.', "dismiss");
            })
    }

    setUpdatedJson(json: string, index: number) {
        this.directoryConnections[index].configuration_json = json;
        this.directoryConnections[index].touched = true;
    }

    updateConnection(directoryConnections: OrganisationDirectory, index: number) {

        this.directoryService.updateOrganisationDirectory(directoryConnections.id, directoryConnections.priority, directoryConnections.configuration_json)
            .pipe(
                tap(() => this.pageStatus.isSubmitting = true),
                finalize(() => this.pageStatus.isSubmitting = false)
            )
            .subscribe(
                (resp) => {
                    console.log(resp);
                    this.directoryConnections[index].touched = false;
                    this.openSnackBar("Updated", "dismiss");
                },
                (err) => {
                    console.log(err);
                    this.openSnackBar("There was a problem updating this connection", "dismiss");
                })
    }

    confirmDelete(id: string) {

        const data: ModalConfirmData = {
            title: 'Confirm delete',
            content: 'Are you sure you want to delete this connection?',
            confirmButtonLabel: "Delete",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: true
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.appInsightService.logEvent('Delete shared directory connection', { 'id': id });
                this.deleteConnection(id);
                return;
            }
            console.log('answer was No');
        });

    }
    deleteConnection(id) {

        this.directoryService.deleteDirectoryConnection(id)
            .pipe(
                tap(() => this.pageStatus.isSubmitting = true),
                finalize(() => this.pageStatus.isSubmitting = false)
            )
            .subscribe((resp) => {
                this.ngOnInit();
                this.openSnackBar("Connection deleted", "dismiss");
            }, (err) => {
                console.log(err);
                this.openSnackBar("Something went wrong trying to delete connection", "dismiss")
            })
    }

    cancelForm () {
        this.resetConnectionForm();
        this.getDirectoryData();
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    openHelpCenter() {

        const url = this.router.url;
        console.log("////URL in toolbar", url);
        const data = { viewAs: this.viewAs, article: '124' };

        this.sidenavRightService.setContextData(data);
        this.sidenavRightService.setComponetPortal(HelpCenterRightMenuComponent);

        // this.sidenavRightService.dataChangedAsObservable.subscribe((resp) => {
        //     resp ? do something : null;
        // });
        //}
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
