//core
import { Component, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';

@Component({
    templateUrl: './update-userprofile-picture-modal.component.html',
    styleUrls: ['./update-userprofile-picture-modal.component.scss'],
    animations: [

    ]
})
export class UpdateUserProfilePictureModalComponent {


    @ViewChild(UpdateUserProfilePictureModalComponent) updateUserProfilePictureModalComponent: UpdateUserProfilePictureModalComponent;

    constructor(
        private dialogRef: MatDialogRef<UpdateUserProfilePictureModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
        private appInsightService: AppInsightService,
        private authService: AuthService,
    ) {

    }


    goToGravatar(){
        window.open('https://gravatar.com/', '_blank');
    }

    closeModal() {
        this.dialogRef.close(true);
    }



}

