import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { responseModel } from '../../models/response.model';
import { InvitationUpdateDetails, InvitationProvision } from '../../models/invitation.model';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
@Injectable()
export class InvitationService {
    public ApiBaseUrl: string;
    public codeV2 = `?code=${environment.apicode}`
    public options;

    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    //retrieve invite info
    public getInvitationDetails(id: string): Observable<responseModel> {
        console.log("[[api]] -> get invitation details");
        return this.httpProxy.get(this.ApiBaseUrl + `/invitations/${id}${this.codeV2}`, 'response', false);
    }

    //after invitee submits his/her details and hits submit, triggers SMS send for verification also
    public putInvitationDetails(body: InvitationUpdateDetails, id: string): Observable<responseModel> {
        console.log("[[api]] -> update invitation details");
        return this.httpProxy.put(this.ApiBaseUrl + `/invitations/${id}${this.codeV2}`, body, 'response', false);
    }

    public postNewInvite(body: InvitationProvision): Observable<responseModel> {
        console.log("[[api]] -> new invitation");
        return this.httpProxy.post(`${this.ApiBaseUrl}/invitations${this.codeV2}`, body, 'response', true)
    }

    public getListOfInvites(id: string): Observable<responseModel> {
        console.log("[[api]] -> get list of invitations");
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${id}/invitations${this.codeV2}`, 'response', true);
    }

    public deleteSingleInvitation(id: string): Observable<responseModel> {
        console.log("[[api]] -> delete invitation");
        return this.httpProxy.delete(this.ApiBaseUrl + `/invitations/${id}${this.codeV2}`, 'response', true);
    }
    //Version2 ========================================================================================================

    public getInvitationsReport(queryParams?: QueryParams): Observable<responseModel> {

        console.log("[[api]] -> get invitations search query", queryParams);

        const paginationString = this.portalApiV2HelperService.getPaginationString(queryParams);
        const sortString = this.portalApiV2HelperService.getSortString(queryParams);
        const _searchString = queryParams?.searchString ? queryParams.searchString : '';
        console.log( `/v2/invitations/search${this.codeV2}&rest=${paginationString}${sortString}${_searchString}`);
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/invitations/search${this.codeV2}&rest=${paginationString}${sortString}${_searchString}`, 'response', true);
    }
}




