//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges , ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { UserService } from '@app/services/pipcall/user.service';
import { NumberService } from '@app/services/pipcall/number.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { UserProfile} from '@app/models/user-profile.model';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
//bottom sheet
import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UserProfileBottomSheetComponent} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { AdminUpgradeToPipuserModuleComponent } from '@app/components/modals/admin-upgrade-to-pipuser-modal/admin-upgrade-to-pipuser-modal.component';
import { AdminExtensionInvitationComponent } from '@app/components/modals/admin-extension-invitation-modal/admin-extension-invitation-modal.component';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import {OrgTabInvitationPanelComponent} from './org-users-invitation-panel/org-users-invitation-panel.component';
@Component({
    selector: 'app-org-users-list',
    templateUrl: './org-users-list.component.html',
    styleUrls: ['./org-users-list.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class  OrgTabUserslisComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional
    @Input() organisationUserList: Array<UserProfile>
    @Input() organisation_id: string;


    @Output() updateParent = new EventEmitter();

    activeUserId: string;

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }

    dataSource: MatTableDataSource<UserProfile>;
    displayedColumns: string[] = ['first_name',  'edit'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(OrgTabInvitationPanelComponent) invitationsPanel: OrgTabInvitationPanelComponent;

    //forms

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private bottomSheet: MatBottomSheet,
        private ref: ChangeDetectorRef,
        private userService: UserService,
        private appInsightService: AppInsightService,
        private numberService: NumberService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private modalService: ModalService,
        private sharedService: SharedService
    ) {
        this.dataSource = new MatTableDataSource();
    }

    ngOnChanges(changes: SimpleChanges) {
        (changes['organisationUserList']?.currentValue !== changes['organisationUserList']?.previousValue) &&  !changes['organisationUserList']?.isFirstChange() ?  this.setDataSource(this.organisationUserList) : null;
        // changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setOrganisationNameFormValues(this.organisationProfile) : null;
        //handle changes to Admins here
    }



    ngOnInit() {

        // if (this.organisation_id) {
        //     this.setFormValue(this.userAdminForm, 'organisation_id',   this.organisation_id);
        // }
        this.appInsightService.logPageView('PiPcall: Customer view');
        this.activeUserId = this.authService.getUserId();

        if ( !this.organisationUserList || !this.organisationProfile ) {
            console.log("No details passed to component. go get them")
            this.getDetails();
        } else {
            this.setDataSource(this.organisationUserList);
        }
        this.ref.detectChanges();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.sort.sort({ id: 'date', start: 'desc', disableClear: false });
    }

    // setOrganisationNameFormValues(organisationProfile: OrganisationModel) {
    //     this.setFormValue(this.organisationNameForm, 'id', organisationProfile.id);
    //     this.setFormValue(this.organisationNameForm, 'name', organisationProfile.name);
    // }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;
        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .switchMap((resp) => {
                this.organisationProfile = resp.body;
                return this.organisationService.getOrgUsersById(this.organisationProfile.id);
            })
            .subscribe(resp => {
                this.organisationUserList = resp.body.user_list;
                this.setDataSource(this.organisationUserList);
                this.pageStatus.isLoading = false;
                this.ref.markForCheck();
            },
            (err) => {
                this.pageStatus.isLoading = false;
                this.ref.markForCheck();
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })


    }



    // saveOrganisationName(f: FormGroup) {
    //     if (f.valid) {
    //         this.submitForm(this.organisationService.patchOrganisationName(f.getRawValue().id, f.getRawValue().name));
    //     }
    // }

    setDataSource( users: Array<UserProfile>) {
        this.dataSource.data = users;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.ref.markForCheck();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }


    openUserProfile(user: UserProfile) {
        const data = {user: user, viewAs: this.viewAs, organisation_id: this.organisation_id, user_id : user.id}

        const dialogRef = this.bottomSheet.open(UserProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF BS", resp);
            this.ref.markForCheck();
            this.triggerUpdate();
            // alert(resp)
        });
    }

    impersonateUser(userid: string) {
        if (!userid) {return this.openSnackBar("No user id", "dismiss")}
        this.sharedService.impersonateUser(userid);
    }
    propagateUser(userid: string) {
        if (!userid) {return this.openSnackBar("No user id", "dismiss")}
        this.userService.propagateUser(userid).subscribe(
            (resp) => {
                console.log(resp);
                if (resp.status === 200 || resp.status === 201) {
                    this.openSnackBar(`${userid} has been sent for propogation`, "dismiss");
                }
            }, (err) => {
                console.log(err);
                this.openSnackBar(`There was a problem with this request [${err.status}]`, "dismiss");
            }
        )
    }

    forceLogout(id: string) {
        this.userService.forceLogoutUser(id).subscribe((resp) => {
            console.log(resp);
            this.openSnackBar("Request to logout user was queued", "dismiss")
        }, (err) => {
            console.log(err);
            this.openSnackBar(`${err.status} : Sorry this request failed`, "dismiss")
        });
    }


    setSettings(user: UserProfile, setInternational: boolean, setSMS: boolean) {
        const orgid = this.organisationProfile?.id;
        const userid = user.id;

        const userDetailsUpdate = {
            can_dial_international: setInternational,
            can_use_sms: setSMS
        }

        this.appInsightService.logEvent('User permissions updated', { 'userid': userid, 'update': userDetailsUpdate });
        this.pageStatus.isSubmitting = true;

        this.userService.patchUserDetails(orgid, userid, userDetailsUpdate)
            .pipe(
                finalize(() => this.pageStatus.isSubmitting = false),
                takeUntil(this._destroy$)
            ).subscribe((resp) => {
                this.triggerUpdate();
                this.openSnackBar('Saved', "dismiss");
            }, (err) => {
                this.openSnackBar(err.error['Message'], "dismiss");
            })
    }


    removeUserFromOrg(user: UserProfile) {

        const name = user?.first_name + ' ' + user?.last_name;
        const data = new ModalConfirmData;
        data.choice = true;
        data.closeButtonLabel = 'Cancel';

        if (this.organisationProfile?.state === 'Trial') {


            data.title = 'Permanently remove user';
            data.content = `You are about to remove ${name} from the organisation. They will no longer have access to PiPcall. Are you sure ? `;
            data.confirmButtonLabel = 'Remove';

            this.modalService.openConfirmModal(data, (answer: boolean) => {
                if (answer) {
                    return this.submitForm(this.organisationService.deleteOrganisationUserTrial( this.organisationProfile?.id, user?.id));
                }
            });


        } else if (this.organisationProfile?.state === 'Active' || this.organisationProfile?.state === 'In Arrears') {

            data.title = 'Request user removal';
            data.content = `You are about to request to permanently remove ${name} from the organisation. Ths will go to our support team to action. Are you sure?. `;
            data.confirmButtonLabel = 'Request Removal';
            data.customClass = 'red-button'
            const removed_by_id = this.authService.getUserId();

            this.modalService.openConfirmModal(data, (answer: boolean) => {
                if (answer) {
                    return this.submitForm(this.organisationService.patchRemoveUserRequest(this.organisationProfile?.id, user?.id, removed_by_id));
                }
            });

        }
    }

    cancelRemoveRequest(id: string) {
        const removed_by_id = this.authService.getUserId();
        const data = new ModalConfirmData;
        data.choice = true;
        data.closeButtonLabel = 'Back';
        data.title = 'Cancel Removal Request';
        data.content = `You are about to withdraw the removal request. Are you sure ? `;
        data.confirmButtonLabel = 'Confirm';

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.submitForm(this.organisationService.patchRemoveUserRequest( this.organisationProfile?.id, id, removed_by_id, false));
            }
        });
    }

    //pipnumber allocation.
    deallocatePiP(ddi: string) {
        this.pageStatus.isSubmitting = true;
        this.submitForm(this.numberService.deallocateDdi(ddi));
    }

    activatePipuser(userProfile: UserProfile) {
        const data = {
            userProfile: userProfile,
            organisationProfile:  this.organisationProfile,
            organisation_id:  this.organisationProfile?.id,
            viewAs: this.viewAs
        }

        const dialogRef = this.dialog.open(AdminUpgradeToPipuserModuleComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            //refresh the view if needed
            if (result) {
                console.log("RESULT FROM MODAL:", result)

                this.triggerUpdate();
            }
        });
    }
    activatePipxtUser(userProfile: UserProfile) {
        if (!this.organisationProfile?.is_pipxt_enabled) {
            return;
        }

        const data = {
            userProfile: userProfile,
            organisationProfile:  this.organisationProfile,
            organisation_id:  this.organisationProfile?.id,
            viewAs: this.viewAs
        }

        const dialogRef = this.dialog.open(AdminExtensionInvitationComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            //refresh the view if needed
            if (result) {
                console.log("[org-users-list].activatePipXTuser()", result)

                this.triggerUpdate();
            }
        });
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    triggerUpdate() {
        this.updateParent.emit(true);
        this.invitationsPanel.getDetails();
        this.ref.detectChanges();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
