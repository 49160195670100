//core
import { Component, OnInit, Input, isDevMode, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { VerifyService } from '../../services/pipcall/verify.service';
import { NormalizeNumberService } from '../../services/helpers/normalize-number.service';
import { MatSnackBar } from '@app/material/material-essentials.module';


@Component({
    selector: 'verify-mobile',
    templateUrl: './verify-mobile.component.html',
    styleUrls: ['./verify-mobile.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class VerifyMobileComponent {

    @Input() id: string;
    @Input() mobile: string;

    @Input() darkMode: boolean;

    // @Output() successEvent = new EventEmitter();
    @Output() triggerSMS = new EventEmitter();

    @Input() verifyRoute: 'invitations' | 'carts';
    //invitations or carts.


    public verificationcode: string;
    public isDev: boolean;


    public submitted1 = false; //verify
    public submitted2 = false; //resend
    public sentmsg = false;
    public notValidMsg = false;
    public verifySuccess = false;
    public allocatedNumber = ""; //info to be returned by api on success
    public resendButtonDisabled = false;
    public use_sms = 'false';


    public message: {
        cart_id?: string,
        invitation_id?: string,
        allocated_number: string,
        bool: Boolean,
        cli_override: string,
        cli_override_request_id: string,
        is_forwarding_business_number: boolean
        organisation_id: string,
        use_sms: string,
        pip_variant: string,
        sip_extension_cli: string;
        sip_extension_number: string;
    }

    constructor(private verifyService: VerifyService,
        private normalizeNumberService: NormalizeNumberService,  private activatedRoute: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) {
        this.isDev = isDevMode();

    }


    ngOnInit() {
        this.disableResendButton(); //disable ofr inital 6 seconds to avoid unwanted presses
    }

    ngAfterViewInit() {

    }

    sendCode(id: string, mobile: string): void {
        this.submitted2 = true; ///change logic behind this. maybe disable for settimeout?
        this.disableResendButton();
        const val = { id: id, mobile: mobile }
        this.triggerSMS.emit(val);
        this.triggerSentAnimation();
        this.submitted2 = false;
    }

    verifyCode(verificationcode: string, id: string): void {
        console.log("code", verificationcode, id);
        this.notValidMsg = false;
        this.submitted1 = true;

        if (verificationcode === "9999") {
            //this is for testing
            this.allocatedNumber = "+440000000000";
            this.verifySuccess = true;
            this.navigateToFinal();
            return;
        }

        if (!verificationcode) {
            this.submitted1 = false;
            this.notValidMsg = true;
            return;
        }

        this.verifyService.verifyCode(id, verificationcode, this.verifyRoute).subscribe((resp) => {
            console.log("verify code::", id, verificationcode, this.verifyRoute);
            console.log("resp body", resp);
            //use sms returns 1 or 0

            if ((resp.status === 200 || resp.status === 201 ) && resp.body.bool) {
                console.log("sucess route");
                this.submitted1 = false;
                this.verifySuccess = true;
                this.use_sms = resp.body.use_sms ? resp.body.use_sms : 0;

                if ( resp.body.pip_variant === 'pipxt') {
                    this.allocatedNumber = resp.body.sip_extension_cli.replace('00', '+ ') + ' (' + resp.body.sip_extension_number + ')'
                } else {
                    this.allocatedNumber = resp.body.allocated_number;
                }
                this.navigateToFinal();
            } else  if (resp.status === 200 && resp.body.bool === false) {
                //code failed
                this.notValidMsg = true;
                this.submitted1 = false;
            } else if (resp.status === 404) {
                this.openSnackBar("The requested Business number is not currently available for auto assignment, call our support team on 0330 094 8080 and we can complete this part of the set up for you. ", "dismiss");
                this.submitted1 = false;
                return;
            } else {
                this.notValidMsg = true;
            }
        }, (err) => {
            if (err.status === 404) {
                this.openSnackBar("The requested Business number is not currently available for auto assignment, call our support team on 0330 094 8080 and we can complete this part of the set up for you. ", "dismiss");
                return;
            } else if (err.status === 400) {
                //code is not valid
                this.notValidMsg = true;
                this.openSnackBar(err?.error, "dismiss");
            } else {
                this.openSnackBar("We had trouble completing your sign up, call our support team on 0330 094 8080 and we can complete this part of the set up for you. ", "dismiss");
            }
            console.log(err);
            this.submitted1 = false;
        });
    }

    triggerSentAnimation() {
        this.submitted2 = false;
        this.sentmsg = true;
        setTimeout(() => {
            return this.sentmsg = false;
        }, 1000);
    }

    disableResendButton(): void {
        //disable button for 4 seconds
        this.resendButtonDisabled = true;
        setTimeout(() => {
            this.resendButtonDisabled = false;
        }, 6000);

    }


    navigateToFinal() {
        console.log("allocated number", this.allocatedNumber);
        if (this.verifyRoute === 'invitations') {
            return  this.router.navigate(['./verified'], { queryParams: { pip: this.allocatedNumber, source: 'invitation' }, relativeTo: this.activatedRoute });
        } else if (this.verifyRoute === 'carts') {
            return this.router.navigate(['./verified'], { queryParams: { pip: this.allocatedNumber, source: 'cart' }, relativeTo: this.activatedRoute });
        }

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 8000,
        })
    }

}
