
<form [formGroup]='searchForm'>

  <mat-form-field  appearance="outline" class="custom-form-field-v3 outline-simple-ff background-white"
  [ngClass]="{'readonly-block':pageStatus.isLoading}">

    <input   matInput
   [placeholder]="pageStatus.isLoading ? 'loading content..' : 'Search for keywords'"
    aria-label="search"
    [matAutocomplete]="autoComplete"
    formControlName="userInputCtrl"
    [formControl]="userInputCtrl"
    #userInput   maxlength="150" tabindex="1">


    <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selected($event)">

      <mat-option [disabled]=true *ngIf="pageStatus.isLoading" class="is-loading"><span>searching..</span></mat-option>

      <ng-container *ngIf="!pageStatus.isLoading">

        <mat-option *ngFor="let article of dataSource.filteredData" [value]=""  [id]="article?.RowKey" class="mat-option-container">
          <div fxLayout="row">
            <div fxFlex="80">
              <div class="mat-option-faq-title">{{article?.title}}</div>
              <div class="mat-option-faq-description">{{article?.description}}</div>
            </div>
            <div fxFlex="20">
              <div class="mat-option-faq-category">{{article?.category}}</div>
            </div>
          </div>
          </mat-option>
      </ng-container>

    </mat-autocomplete>

 
  </mat-form-field>
</form>
