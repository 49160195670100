//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges , ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatSortable, MatSnackBar, MatDialog, MatSort, MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent, MatSelectionList, MatList, Sort } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, map, finalize, takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { UserProfile} from '@app/models/user-profile.model';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';

//bottom sheet popup
import { MatBottomSheet, MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UserProfileBottomSheetComponent} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.component';
import { LicenceBottomSheetComponent} from '@app/components/bottom-sheet-modal/licence-bsheet-modal/licence-bsheet-modal.component';

//licences
import { ViewLicenseModel } from '@app/models/license.model';
import { LicenseService } from '@app/services/pipcall/license.service';

import { InvitationService } from '@app/services/pipcall/invitation.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import { InviteUserModalComponent } from '@app/components/modals/invite-user-modal/invite-user-modal.component'
import { DdiNumber } from '@app/models/ddi.model';
import { FirstPaymentModal } from '@app/components/modals/first-payment-modal/first-payment-modal.component';
@Component({
    selector: 'app-org-licences',
    templateUrl: './org-licences.component.html',
    styleUrls: ['./org-licences.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class  OrgTabLicencesComponent implements OnInit {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional
    @Input() organisationUserList: Array<UserProfile>
    @Input() organisation_id: string;


    @Output() updateParent = new EventEmitter();

    @ViewChild(MatSort, { static: false }) sort: MatSort; //static false - only access in afterViewInit
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }

    public dataSource: MatTableDataSource<ViewLicenseModel>;
    displayCols: string[]; //set in ngOnInit
    displayedColumns: string[] = ['licensed_user_type',  'license_name', 'license_contract_name', 'license_price', 'first_name', 'licensed_at', 'allow_renewal', 'settings' ];
    displayedColumnsMobile: string[] = ['licensed_user_type',  'license_name',  'license_price', 'settings' ];

    private breakpointSubscription: Subscription;
    public showPaymentDue = false; //show the description


    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private bottomSheet: MatBottomSheet,
        private licenseService: LicenseService,
        private ref: ChangeDetectorRef,
        private invitationService: InvitationService,
        private breakpointObserver: BreakpointObserver,
        private modalService: ModalService) {
        this.dataSource = new MatTableDataSource();
    }

    ngOnChanges(changes: SimpleChanges) {

    }



    ngOnInit() {

        this.displayCols = [...this.displayedColumns]
        if ( this.organisation_id ) {
            console.log("GET DETAILS NOW");
            this.getDetails();
        } else {
            //Error
            this.noContent = true;
            this.pageStatus.isLoading = false;
            this.openSnackBar('Could not get licence data at this time', 'Dismiss');
        }


        this.breakpointSubscription = this.breakpointObserver
            .observe(['(max-width: 959px)']) // You can define more breakpoints here if needed
            .subscribe((state: BreakpointState) => {
                console.log("MOBILE BREAK POINT")
                if (state.matches) {
                    this.displayCols = this.displayedColumnsMobile;
                } else {
                    this.displayCols = [...this.displayedColumns]
                }
            });

    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator;
    }



    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;

        this.dataSource.data = [];

        this.licenseService
            .getOrganisationLicenses(this.organisation_id)
            .pipe(
                map(resp => this.removeDuplicate(resp)),
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$),
            )
            .subscribe(
                (resp) => {
                    console.log("DATA:", resp);
                    this.dataSource = new MatTableDataSource(resp);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;

                    //set default sort state
                    const sortState: Sort = { active: 'licensed_at', direction: 'desc' };
                    this.sort.active = sortState.active;
                    this.sort.direction = sortState.direction;
                    this.sort.sortChange.emit(sortState);

                    this.pageStatus.isLoading = false;
                    this.checkPaymentDue(this.dataSource.data);
                },

                (err) => {
                    console.log("erro", err);
                    this.openSnackBar("Sorry there was a problem with this request", "Dismiss");
                })

    }

    removeDuplicate(data) {
        const newdata = new Array<ViewLicenseModel>();

        if (data?.body && (data.status === 200 || data?.status === 201)) {

            data.body.forEach(l => {
                // push only IF
                // this row is unpaid OR !(this row is paid AND (there is a row that has same User AND is UNPAID ))
                if (l.is_paid === false || !(l.is_paid === true && data.body.filter(d => d.licensed_user_identifier === l.licensed_user_identifier && d.is_paid === false).length > 0)) {
                    newdata.push(l);
                }
            });

        }

        return newdata;
    }

    checkPaymentDue(data: ViewLicenseModel[]) {
        data.forEach(license => {
            if (!license.is_paid) {
                this.showPaymentDue = true;
            }
        });
    }


    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }


    _openUserProfile_viaEmail(email: string) {
        console.log("USER LIST:", this.organisationUserList, email);
        const user = this.organisationUserList.find(u => u?.email === email);
        console.log("USER RETURNED", user);
        if (user) {this.openUserProfile(user?.id)}
    }

    openFirstPayment() {
        const dialogRef = this.dialog.open(FirstPaymentModal, {
            panelClass: 'pipcall-fullscreen-modal-container',
            data: {organisation_id: this.organisation_id}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.updateParent.emit(true);
                window.location.reload();
            }
        });
    }

    openUserProfile(user_id: string) {
        const data = {viewAs: this.viewAs, organisation_id: this.organisation_id, user_id : user_id}

        console.log("USER ID:", user_id);
        const dialogRef = this.bottomSheet.open(UserProfileBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {

            this.ref.markForCheck();
            if (resp) {
                console.log("AFTER DISMISS OF BS", resp);
                this.getDetails();
                this.triggerUpdate();
            }
            // alert(resp)
        });
    }

    openInviteModal(number?: DdiNumber): void {
        number = number ? number : null;

        const invited_by_id = 'auth0|0000000000000000000';
        const data = {
            "organisationDetails": this.organisationProfile,
            "invited_by_id": invited_by_id
        };

        const dialogRef = this.dialog.open(InviteUserModalComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log("INVITE CLOSE", result);
            if (result === true) {
                this.triggerUpdate();
                this.ref.markForCheck();
            }
        });
    }

    openLicence(licence: ViewLicenseModel) {
        const data = {licence: licence, viewAs: this.viewAs, organisation_id: this.organisation_id, organisationProfile : this.organisationProfile}

        const dialogRef = this.bottomSheet.open(LicenceBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        // const updatedLicences = dialogRef.instance._updateDdiList.subscribe((event) => {
        //     console.log(TEST_);
        // });


        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF LICENCE BOTTOM SHEET---------------------------------lsksj", resp);

            if (resp) {
                this.triggerUpdate();
            }
        });
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }


    triggerUpdate() {
        this.getDetails()
        // this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
        if (this.breakpointSubscription) {
            this.breakpointSubscription.unsubscribe();
        }
    }

}
