<div fxLayout="row" fxLayoutAlign="start center" class="hover-to-show">

  <div class="avatar-container av-size-30" *ngIf="invoice?.id">

    <div class="invoice-container" fxLayout="row" fxLayoutAlign="center center"
      [ngClass]="{'green': invoice?.state === 'Complete' || invoice?.state === 'Paid', 'warning':invoice?.state === 'Failed', 'amber':isOverdue(invoice?.due_date)}">

      <ng-container *ngIf="invoice?.is_paid;else notPaid">
        <mat-icon svgIcon="invoice_check" class="invoice-icon" style="color: var(--c-accept)"></mat-icon>
      </ng-container>

      <ng-template #notPaid>
        <mat-icon *ngIf="invoice.state != 'Failed'" svgIcon="invoice" class="invoice-icon"></mat-icon>
        <mat-icon *ngIf="invoice.state === 'Failed'" svgIcon="invoice_warn" class="invoice-icon"></mat-icon>
      </ng-template>
    </div>
  </div>


  <div fxLayout="column" style="padding-left:12px;">

    <div style="white-space: nowrap;max-width: 230px;" class="overflow-elipsis">
      {{invoice?.id}}
    </div>

    <div>

      <div *ngIf="invoice?.state">
        <span style="max-width: 230px;" class="max-width-name overflow-elipsis" style="opacity: 0.8;font-size:0.9em;"
          [ngClass]="{'red': invoice?.state === 'Failed', 'green': invoice?.state === 'Open'}">{{invoice?.state}}</span>
      </div>
    </div>
  </div>


  <mat-icon matSuffix svgIcon="view" (click)="openInvoice(invoice?.id, viewAs)" class="clickable action-icon"
    matTooltipPosition="below" matTooltipShowDelay="200">
  </mat-icon>

  <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(invoice?.id)" class="clickable action-icon"
    matTooltipPosition="below" matTooltipShowDelay="200">
  </mat-icon>

</div>