//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { UserProfile} from '@app/models/user-profile.model';
import { Subject , Observable, Subscription, throwError} from "rxjs";
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { InvitationService  } from '@app/services/pipcall/invitation.service';
import { InvitationModel } from '@app/models/invitation.model';
import { AreaCode } from '@app/services/pipcall/number.service';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
import { InviteUserModalComponent } from '@app/components/modals/invite-user-modal/invite-user-modal.component';
import { InviteUserModalComponentpipxt} from '@app/components/modals/invite-user-modal-pipxt/invite-user-modal.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
@Component({
    selector: 'app-org-users-invitation-panel',
    templateUrl: './org-users-invitation-panel.component.html',
    styleUrls: ['./org-users-invitation-panel.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class  OrgTabInvitationPanelComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional
    // @Input() organisationUserList: Array<UserProfile>
    @Input() organisation_id: string;


    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
    }

    dataSource: MatTableDataSource<InvitationModel>;
    displayedColumns: string[] = ['first_name',  'settings'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    organisationInvitations: Array<InvitationModel>;

    //forms

    //DELETE USER
    userAdminForm: FormGroup;
    _subsciption2: Subscription
    userProfile: UserProfile //current active user

    public areaCodes: Array<AreaCode>;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private invitationService: InvitationService,
        private staticDataService: StaticDataService,
        private modalService: ModalService,
        private ref: ChangeDetectorRef,
        private sharedService: SharedService
    ) {
        this.dataSource = new MatTableDataSource();
        this.areaCodes = new Array<AreaCode>();

        // this.userAdminForm = this._formBuilder.group({
        //     organisation_id: [{ value: '', disabled: false }, Validators.required],
        //     user_id: ['', {updateOn: 'change', validators: [Validators.required]}]
        // });

        this.staticDataService.getAreaCodes().subscribe((resp) => {
            if (resp.status === 200) {
                this.areaCodes = resp.body.areacode_list;
                console.log(this.areaCodes);
                return;
            }
        })


        this._subsciption2 = this.sharedService.userProfileAsObservable.first().subscribe(userProfile => {
            this.userProfile = userProfile;
        });

    }

    ngOnChanges(changes: SimpleChanges) {
    }



    ngOnInit() {
        if (this.organisation_id) {
            this.getDetails();
        }

    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.sort.sort({ id: 'date', start: 'desc', disableClear: false });
    }


    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    public getDetails() {
        //get list of invitations
        this.pageStatus.isLoading = true;
        this.invitationService.getListOfInvites(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false,   this.ref.markForCheck(); }),
                takeUntil(this._destroy$)
            ) .subscribe(resp => {
                this.organisationInvitations = resp?.body?.invitation_list ? resp?.body?.invitation_list : [];
                this.setDataSource(this.organisationInvitations);
                this.ref.markForCheck();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
                this.ref.markForCheck();
            })
    }



    returnNumberAllocationAreaCode(allocation: string) {
        const match = this.areaCodes.find(item => item.id === allocation);
        return match?.name;
    }

    setDataSource( invitations: Array<InvitationModel>) {
        this.dataSource.data = invitations;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
                this.ref.markForCheck();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    copyInvitationUrl(id: string) {
        let urlString: string;
        if (window.location.hostname === 'localhost') {
            urlString = window.location.protocol + window.location.hostname + ':' + window.location.port + '/verify/invitation/' + id
        } else {
            urlString = window.location.protocol + window.location.hostname + '/verify/invitation/' + id
        }
        this.copyInputToClipboard(urlString);
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    inviteModal(): void {
        console.log("INVITE USER");
        const invited_by_id = 'auth0|0000000000000000000';
        const data = {
            "organisationDetails": this.organisationProfile,
            "invited_by_id": invited_by_id
        };

        const dialogRef = this.dialog.open(InviteUserModalComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getDetails();
            }
        });
    }

    inviteModalPiPxt(): void {
        const data = {
            "organisation_id": this.organisationProfile.id
        };

        const dialogRef = this.dialog.open(InviteUserModalComponentpipxt, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result === true) {
                this.getDetails();
            }
        });
    }

    confirmRevokeInvitation(id: string) {

        const data: ModalConfirmData = {
            title: '',
            content: 'Are you sure you want to remove this invitation?',
            confirmButtonLabel: "Revoke",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.revokeInvitation(id);
                return;
            }
        });

    }

    revokeInvitation(id: string) {
        this.pageStatus.isLoading = true;

        this.invitationService.deleteSingleInvitation(id)
            .pipe(
                finalize(() => {
                    this.pageStatus.isLoading = false;
                })
            )
            .subscribe((resp) => {
                this.openSnackBar("Invitation has been deleted", "dismiss");
                this.triggerUpdate();
                this.getDetails();
            }, (err) => {
                if (err.status === 409) {
                    this.openSnackBar("This invitation has been paid for, please contact support@pipcall.com if you wish to remove it. ", "dismiss", 8888000)
                    return;
                }
                console.log(err)
                this.openSnackBar(`sorry something went wrong with this request [${err?.status}]`, "dismiss");
            })
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string, duration?: number): void {
        const dur = duration ? duration : 3000;
        this.snackBar.open(message, action, {
            duration: dur,
        })
    }

    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
