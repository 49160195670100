import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';

import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import {responseModel} from '../../models/response.model';
import { delay } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Injectable()
export class MockDataService {

    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`
    public options;

    constructor(private httpProxy: HttpProxy) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    //example when passing a type of response
    public _postMockQuery(pass: boolean): Observable<HttpResponse<any>> {
        console.log('mocking post query');
        if (!pass) {
            const errorMessage = 'Simulated error response';
            return throwError(errorMessage);
        }

        const resp: HttpResponse<any> = {
            body: [{}],
            status: 200,
            ok: true,
            statusText: 'test message from mock data',
            type: HttpEventType.Response,
            url: null,
            clone: null,
            headers: null
        }
        //set a random delay between 1 and 5 seconds
        const delayTime = Math.floor(Math.random() * 4000) + 1000;
        console.log('delay', delayTime);
        return of(resp).pipe(
            delay(delayTime)
        );

    }


    public postMockQuery(pass: boolean): Observable<responseModel> {
        console.log('mocking post query');
        if (!pass) {
            const errorMessage = 'Simulated error response';
            return throwError(errorMessage);
        }

        const resp: responseModel = {
            body: {},
            status: 200,
            ok: true,
            statusText: 'test message from mock data',
            type: 1,
            url: null
        }

        const delayTime = Math.floor(Math.random() * 4000) + 1000;
        console.log('delay', delayTime);
        return of(resp).pipe(
            delay(delayTime)
        );

    }

}

// HttpErrorResponse
// error
// :
// {error: 'DID is already reserved.'}
// headers
// :
// HttpHeaders {normalizedNames: Map(0), lazyUpdate: null, headers: Map(0)}
// message
// :
// "Http failure response for https://voxspan-dv02-portal-api-01-i1.azurewebsites.net/api/v2/ddi/convert?code=8DetrepsI8ZDW1rYC5oHJ30h4j1eLJfie67f1inheYC_AzFukuNFdg==: 500 Internal Server Error"
// name
// :
// "HttpErrorResponse"
// ok
// :
// false
// status
// :
// 500
// statusText
// :
// "Internal Server Error"
// url
// :
// "https:
