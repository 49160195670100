import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { MdePopoverModule } from '@material-extended/mde';


//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { CookieService } from 'ngx-cookie-service'
import { UserService } from '@app/services/pipcall/user.service';

//component
import { OnboardOrgDetailsComponent } from './organisation-details.component'


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule
    ],
    declarations: [OnboardOrgDetailsComponent],
    exports: [OnboardOrgDetailsComponent],
    providers: [AppInsightService, OrganisationService, CookieService, UserService ],
    entryComponents: []
})
export class OnboardOrgDetailsModule { }
