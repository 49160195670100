<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">


      <div *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled'"
        class="section-info-bar" fxLayoutAlign="space-between center" fxLayout="row"
        style="background:var(--c-error);color:white;height: 50px;">Account is closed</div>
      <div *ngIf="organisationProfile?.state === 'Declined'" class="section-info-bar"
        fxLayoutAlign="space-between center" fxLayout="row" style="background:var(--c-warn);color:white;height: 50px;">
        Trial has now ended</div>


      <!-- <div class="section-wrapper">

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div class="copy-highlighted-background" fxFlex="100%" style="margin-bottom: 20px;">
              <p>Users who are members of your organisation are displayed below. Use <strong>Invite User</strong> to add
                more. Note that open invitations are licence in the same manner as users.</p>
            </div>

          </div>
        </div>
      </div> -->




      <app-org-users-invitation-panel [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
        [viewAs]="viewAs" (updateParent)="triggerUpdate()"></app-org-users-invitation-panel>


      <div class="section-wrapper">
        <tab-title-lined>Users&nbsp;<span
            style="font-size: 0.8em;">({{dataSource?.filteredData.length}})</span></tab-title-lined>

        <div class="section-content">
          <div fxLayout="column" style="overflow:auto" [hidden]="!(dataSource?.data?.length > 0)">

            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end" fxLayoutAlign.lt-md="center"
              style="margin-bottom: 20px;">
              <mat-form-field appearance="outline" class="simple-input-v2" fxFlex.lt-md="100%">
                <input role="search" autocomplete="off" matInput placeholder="Search..." aria-label="search"
                  maxlength="150" tabindex="1" (keyup)="applyFilter($event.target.value)">
              </mat-form-field>
            </div>

            <table mat-table class="table tab-table-basic clean-spaced clean-add-row-border" [dataSource]="dataSource"
              matSort
              [ngClass]="{'disable-actions': viewAs !== 'sysAdmin' && (organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled' || organisationProfile?.state === 'Declined')}">

              <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row">

                  <div fxLayout="row" fxLayoutAlign="start center">

                    <div class="usr-avatar-md">
                      <img appGravatar [firstname]="row.first_name" [lastname]="row.last_name" [email]="row.email">
                    </div>

                    <div fxLayout="column" class="user-profile-wrapper clickable" >
                      <div class="user-title-row">{{row.first_name}} {{row.last_name}}&nbsp;
                      </div>
                      <div class="user-email-row">
                        <ng-container *ngIf="row?.organisation_list[0]?.removal_requested;else showEmail">
                          <button class="removal-indicator"
                            matTooltip="You have requested the removal of this user. This will be carried out by our support team.">Removal
                            Requested</button>
                        </ng-container>
                        <ng-template #showEmail>{{row.email}}</ng-template>
                      </div>
                    </div>

                  </div>

                </td>
              </ng-container>



              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <div fxLayout="row" fxLayoutAlign="end center">

                    <div style="margin-left: 8px;"
                      *ngIf="!row?.mobile && (row?.id === activeUserId || viewAs === 'sysAdmin')">

                      <button
                        *ngIf="organisationProfile?.is_pipmobile_enabled && !organisationProfile?.is_pipxt_enabled && !row?.organisation_list[0]?.invitation_id"
                        class="accent-alt-button" mat-flat-button
                        [disabled]="organisationProfile?.state !== 'Active' && organisationProfile?.state !== 'Trial'"
                        [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="Activate a PiPcall license"
                        style="font-size: 1em" (click)="activatePipuser(row)">
                        Activate handset
                      </button>

                      <button *ngIf="organisationProfile?.is_pipxt_enabled && !row?.organisation_list[0]?.invitation_id"
                        class="accent-alt-button" mat-flat-button
                        [disabled]="organisationProfile?.state !== 'Active' && organisationProfile?.state !== 'Trial'"
                        [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="Activate a PiPcall license"
                        style="font-size: 1em" (click)="activatePipxtUser(row)">
                        Activate handset
                      </button>


                      <ng-container
                        *ngIf="!row.mobile && row.organisation_list[0].invitation_id && row.organisation_list[0].is_invitation_accepted === true">
                        <span class="problem-detected-msg"> <i>There was a problem detected. Please contact
                            support@pipcall.com</i></span>
                      </ng-container>

                    </div>

                    <div class="check-email-msg hide-on-mobile"
                      *ngIf="!row?.mobile && row?.organisation_list[0]?.invitation_id && row?.organisation_list[0]?.is_invitation_accepted === false">
                      <i>Check email to verify PiPcall account </i>
                    </div>

                    <ng-container
                      *ngTemplateOutlet="userNumbers;context:{'user':row, 'ddi_list': row.organisation_list[0].ddi_list}"></ng-container>

                    <div fxLayout="column" class="list-item-3-dot-wrapper"
                      *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'">
                      <button mat-icon-button (click)="stopPropagation($event)" class="more-menu" [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                        matTooltip="Edit user" [matMenuTriggerFor]="userMenu"
                        [matMenuTriggerData]="{user: row}"><mat-icon svgIcon="more_vert"></mat-icon></button>
                    </div>

                  </div>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickable" (click)="openUserProfile(row)">
              </tr>

            </table>

            <!-- //if no users in first placec rather then filter then hide rable and display banner -->
            <div class="no-data" *ngIf="dataSource.filteredData && dataSource.filteredData.length === 0">No results
              returned</div>

            <mat-paginator itemsPerPageLabel="'items'" [pageSizeOptions]="[10, 25, 50]" [showFirstLastButtons]="true"
              [hidden]="dataSource.data.length < 10">
            </mat-paginator>


          </div>



        </div>
      </div>


    </div>

  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>



<mat-menu #userMenu="matMenu" class="v3-mat-menu">

  <ng-template matMenuContent let-user="user">

    <button mat-menu-item (click)="openUserProfile(user)">
      Open
    </button>

    <ng-container *ngIf="user?.mobile">

      <hr class="divider">

      <div mat-menu-item (click)="$event.stopPropagation()">
        <mat-slide-toggle #smsEnabled (change)="setSettings(user, internationalEnabled._checked, $event.checked)"
          [checked]="user?.organisation_list[0]?.can_use_sms">
          SMS
        </mat-slide-toggle>
      </div>


      <div mat-menu-item (click)="$event.stopPropagation()"
        *ngIf="organisationProfile?.state === 'Trial' || organisationProfile?.state === 'Declined'">
        <mat-slide-toggle disabled checked=false>International Calling</mat-slide-toggle>
      </div>

      <div mat-menu-item (click)="$event.stopPropagation()"
        *ngIf="organisationProfile?.state !== 'Trial' && organisationProfile?.state !== 'Declined'">
        <mat-slide-toggle #internationalEnabled (change)="setSettings(user, $event.checked, smsEnabled._checked)"
          [checked]="user?.organisation_list[0]?.can_dial_international">
          International Calling
        </mat-slide-toggle>
      </div>

    </ng-container>
    <ng-container *ngIf="viewAs === 'sysAdmin'">

      <div class="divider">Sys Admin</div>

      <button mat-menu-item matToolTip="Impersonate the user" (click)="impersonateUser(user?.id)">
        <mat-icon svgIcon="impersonate"></mat-icon> Impersonate
      </button>

      <button mat-menu-item matToolTip="Propagate the user" (click)="propagateUser(user?.id)">
        <mat-icon svgIcon="sync"></mat-icon> Propagate User
      </button>

      <button mat-menu-item matToolTip="Force logout this user" (click)="forceLogout(user?.id)">
        <mat-icon svgIcon="logout-2"></mat-icon> Force Logout
      </button>

    </ng-container>

    <hr class="divider">

    <ng-container *ngIf="organisationProfile?.state != 'Trial'; else TrialOrg">
      <!-- active org-->

      <!-- removal requested already -->
      <button mat-menu-item *ngIf="user?.organisation_list[0]?.removal_requested"
        (click)="cancelRemoveRequest(user?.id)">Cancel Removal Request</button>

      <button *ngIf="!user?.organisation_list[0]?.removal_requested" (click)="removeUserFromOrg(user)" mat-menu-item
        matTooltip="Remove {{user?.first_name}} {{user?.last_name}} from the organisation"
        class="red-item red-item-full">
        <mat-icon svgIcon="trash"></mat-icon>
        Request Removal
      </button>

      <!-- noremoval requested already -->

    </ng-container>
    <ng-template #TrialOrg>
      <!-- Trial org-->

      <button (click)="removeUserFromOrg(user)" mat-menu-item *ngIf="user.id !== activeUserId && user.id !== organisationProfile?.owner_user_id"
        matTooltip="Request Removal of {{user?.first_name}} {{user?.last_name}} from the organisation"
        class="red-item red-item-full">
        <mat-icon svgIcon="trash"></mat-icon>
        Delete User
      </button>

    </ng-template>


    <ng-container>

    </ng-container>

  </ng-template>


</mat-menu>


<ng-template #userNumbers let-user="user" let-ddi_list="ddi_list">

  <div fxLayout="row" class="table-icons-wrapper" style="justify-content: end;">

    <ng-container *ngIf="user?.organisation_list[0]?.isAdmin || user?.organisation_list[0]?.owner_user_id === user?.id">
      <div class="table-icon-btn-wrapper"><button (click)="$event.stopPropagation()" style="cursor: default"
          mat-icon-button matTooltip="Admin user"><mat-icon svgIcon="user"></mat-icon></button>
      </div>
    </ng-container>

    <!-- If multiple business numbers exist -->
    <ng-container *ngIf="ddi_list.length > 1">
      <div class="multi-button-wrapper" fxLayout="row"
        matTooltip="Multiple business numbers &#13;  {{ddi_list[0].number | normalizeToInternational}} &#13;{{ddi_list[1]?.number | normalizeToInternational}} &#13;{{ddi_list[2]?.number | normalizeToInternational}} &#13;{{ddi_list[3]?.number | normalizeToInternational}} &#13;{{ddi_list[4]?.number | normalizeToInternational}}">
        <div class="table-icon-btn-wrapper"><button mat-icon-button><mat-icon svgIcon="phone"></mat-icon></button></div>
        <div class="table-icon-btn-wrapper btn-multi-overlap"><button (click)="event.stopPropagation()"
            mat-icon-button><mat-icon svgIcon="phone"></mat-icon></button></div>
      </div>
    </ng-container>

    <!-- If user has no pip number -->
    <ng-container *ngIf="ddi_list[0].number && ddi_list.length === 0">
      <div class="table-icon-btn-wrapper empty-item"><button (click)="$event.stopPropagation()" style="cursor: default"
          mat-icon-button matTooltip="No business number has been linked"><mat-icon svgIcon="phone"></mat-icon></button>
      </div>
    </ng-container>

    <!-- user has number and first number is a pipnumber -->
    <ng-container *ngIf="ddi_list[0].number && ddi_list[0].pip_variant === 'pipmobile' && ddi_list.length === 1">
      <div class="table-icon-btn-wrapper"><button
          matTooltip="Business Number: {{ddi_list[0].number | normalizeToInternational}}" mat-icon-button><mat-icon
            svgIcon="phone"></mat-icon></button></div>
    </ng-container>


    <!-- user has number and first number is a extension -->
    <ng-container *ngIf="ddi_list[0].number && ddi_list[0].pip_variant === 'pipxt'  && ddi_list.length === 1">
      <div class="table-icon-btn-wrapper"><button
          matTooltip="Business Number: {{ddi_list[0].number | normalizeToInternational}}" mat-icon-button><mat-icon
            svgIcon="connect"></mat-icon></button></div>
    </ng-container>

    <!-- user device-->
    <ng-container *ngIf="!user?.mobile">
      <div class="table-icon-btn-wrapper empty-icon-item"><button (click)="$event.stopPropagation()"
          style="cursor: default" style="cursor: pointer;" matTooltip="No handset has been registered"
          mat-icon-button><mat-icon svgIcon="device"></mat-icon></button></div>
    </ng-container>
    <ng-container *ngIf="user?.mobile">
      <div class="table-icon-btn-wrapper">
        <button (click)="$event.stopPropagation()" style="cursor: default"
          matTooltip="Handset: ** ****** {{user?.mobile | slice:10}} &#13;OS:{{user?.os}} &#13;OS Ver:{{user?.os_version}} &#13;Carrier:{{user?.carrier}} &#13;PiPcall ver::{{user?.pipcall_version}} &#13;Build:{{user?.pipcall_build}}&#13;Created at::{{user?.device_created_at | date:' yyyy MMMM dd HH:mm'}} &#13;Modified at::{{user?.device_modified_at | date:'yyyy MMMM dd HH:mm'}}"
          mat-icon-button>
          <mat-icon svgIcon="device"></mat-icon>
        </button>
      </div>
    </ng-container>

  </div>



</ng-template>