//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { distinctUntilChanged, map, delay } from 'rxjs/operators';
import { Subject , of, Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { OrganisationDirectory, DirectoryService} from '@app/services/pipcall/directory.service';
import { Subscription } from 'rxjs';
// import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { ValidateEmailNotTaken, ValidateEmailWithSMSIdentityExists } from '@app/validators/async-email.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { TitleCasePipe } from '@angular/common';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';

import { UserProfile } from '@app/models/user-profile.model';
import { NumberService, AreaCode } from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';

import { AuthService } from '@app/services/auth-service/auth.service';
import { MatAccordion } from '@angular/material/expansion';
import { CookieService } from 'ngx-cookie-service';
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { InvitationProvision } from '@app/models/invitation.model';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
// import { UserService, OwnerUpgradeForm } from '@app/services/pipcall/user.service';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
// import { UserProfile } from '@app/models/user-profile.model';
import { NormalizeToInternational } from '@app/pipes/formatNumber';
import { rotateInUpRightAnimation, rotateInUpRightOnEnterAnimation, bounceInLeftOnEnterAnimation} from 'angular-animations';
import { responseModel } from '@app/models/response.model';


@Component({
    selector: 'app-user-invitations',
    templateUrl: './user-invitations.component.html',
    styleUrls: ['./user-invitations.component.scss'],
    animations: [
        bounceInLeftOnEnterAnimation(),
        rotateInUpRightOnEnterAnimation(),
        rotateInUpRightAnimation(),
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class OnboardUserInvitationsComponent {
    @Input() stageId: number;
    @Input() organisation_id: string;
    @Input() userProfile: UserProfile;
    @Output() moveToNextStage = new EventEmitter();

    dynamicForm: FormGroup;

    extensionsList: DdiNumber[]; //list of extensions
    pageStatus = {
        isLoading: true,
        error: false,
        isSubmitting: false,
        invitationsSent: false
    }
    subscription: Subscription;


    // mockdata: DdiNumber[] = [
    //     {
    //         "number": "00280000000400",
    //         "pbx_platform_id": "LV01-UK44",
    //         "organisation_id": "E6108C9A-B941-EE11-9937-6045BD0EF84F",
    //         "provider": "PiPxt Host",
    //         "pip_variant": "pipxt",
    //         "state": "OK",
    //         "is_reserved": true,
    //         "is_blocked": false,
    //         "is_sms_enabled": false,
    //         "sip_extension_number": "201",
    //         "sip_extension_cli": "00442003364360",
    //         "sip_user_name": "758201",
    //         "sip_host_name": "pbx01.pipoffice.com",
    //         "sip_voicemail_number": "*123",
    //         "is_pipxt_extensionless": false,
    //         "notes": null,
    //         "created_at": "2023-08-30T12:55:11.2108440Z",
    //         "event_source": "Telephony Platform",
    //         "event_type": "PiP service update pass",
    //         "event_code": "0",
    //         "event_at": "2023-09-13T14:55:39Z",
    //         "event_data": "DDI 00280000000400 updated and SIP credentials retained",
    //         "dnd": false,
    //         "label": "PiPxt",
    //         "cli_override": null,
    //         "no_answer": "voicemail",
    //         "sort": null,
    //         "user_id": "auth0|64e60a9797d2d665727e865b",
    //         "user_first_name": "Andrew",
    //         "user_last_name": "Mitchell",
    //         "user_full_name": "Andrew Mitchell",
    //         "user_email": "accounts@amcleaningcompany.org",
    //         "organisation_name": "A and M Cleaning Co",
    //         "organisation_state": "Active",
    //         "cli_override_request_id": null,
    //         "invitation_id": null,
    //         "invitation_email": null
    //     },
    //     {
    //         "number": "00280000000403",
    //         "pbx_platform_id": "LV01-UK44",
    //         "organisation_id": "E6108C9A-B941-EE11-9937-6045BD0EF84F",
    //         "provider": "PiPxt Host",
    //         "pip_variant": "pipxt",
    //         "state": "OK",
    //         "is_reserved": true,
    //         "is_blocked": false,
    //         "is_sms_enabled": false,
    //         "sip_extension_number": "201",
    //         "sip_extension_cli": "00441603364362",
    //         "sip_user_name": "758201",
    //         "sip_host_name": "pbx01.pipoffice.com",
    //         "sip_voicemail_number": "*123",
    //         "is_pipxt_extensionless": false,
    //         "notes": null,
    //         "created_at": "2023-08-30T12:55:11.2108440Z",
    //         "event_source": "Telephony Platform",
    //         "event_type": "PiP service update pass",
    //         "event_code": "0",
    //         "event_at": "2023-09-13T14:55:39Z",
    //         "event_data": "DDI 00280000000400 updated and SIP credentials retained",
    //         "dnd": false,
    //         "label": "PiPxt",
    //         "cli_override": null,
    //         "no_answer": "voicemail",
    //         "sort": null,
    //         "user_id": "auth0|64e60a9797d2d665727e865b",
    //         "user_first_name": "Andrew",
    //         "user_last_name": "Mitchell",
    //         "user_full_name": "Andrew Mitchell",
    //         "user_email": "accounts@amcleaningcompany.org",
    //         "organisation_name": "A and M Cleaning Co",
    //         "organisation_state": "Active",
    //         "cli_override_request_id": null,
    //         "invitation_id": null,
    //         "invitation_email": null
    //     },
    //     {
    //         "number": "00280000000404",
    //         "pbx_platform_id": "LV01-UK44",
    //         "organisation_id": "E6108C9A-B941-EE11-9937-6045BD0EF84F",
    //         "provider": "PiPxt Host",
    //         "pip_variant": "pipxt",
    //         "state": "OK",
    //         "is_reserved": true,
    //         "is_blocked": false,
    //         "is_sms_enabled": false,
    //         "sip_extension_number": "201",
    //         "sip_extension_cli": "00441603364364",
    //         "sip_user_name": "758201",
    //         "sip_host_name": "pbx01.pipoffice.com",
    //         "sip_voicemail_number": "*123",
    //         "is_pipxt_extensionless": false,
    //         "notes": null,
    //         "created_at": "2023-08-30T12:55:11.2108440Z",
    //         "event_source": "Telephony Platform",
    //         "event_type": "PiP service update pass",
    //         "event_code": "0",
    //         "event_at": "2023-09-13T14:55:39Z",
    //         "event_data": "DDI 00280000000400 updated and SIP credentials retained",
    //         "dnd": false,
    //         "label": "PiPxt",
    //         "cli_override": null,
    //         "no_answer": "voicemail",
    //         "sort": null,
    //         "user_id": "auth0|64e60a9797d2d665727e865b",
    //         "user_first_name": "Andrew",
    //         "user_last_name": "Mitchell",
    //         "user_full_name": "Andrew Mitchell",
    //         "user_email": "accounts@amcleaningcompany.org",
    //         "organisation_name": "A and M Cleaning Co",
    //         "organisation_state": "Active",
    //         "cli_override_request_id": null,
    //         "invitation_id": "as",
    //         "invitation_email": null
    //     }
    // ]
    constructor(
        public dialog: MatDialog,
        private organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private appInsightService: AppInsightService,
        private staticDataService: StaticDataService,
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private cookieService: CookieService,
        private invitationService: InvitationService,
        private normalizeToInternational: NormalizeToInternational,
        private sharedService: SharedService,
        private signupService: SignupService
    ) {
        this.extensionsList =  new Array<DdiNumber>();

        this.dynamicForm = this._formBuilder.group({
            no_of_extensions:  ['', Validators.required],
            invitations: new FormArray([])
        });
    }

    ngOnInit() {
        //get name of current user for updated by
        this.sharedService.innit('onboarding');

        if (this.organisation_id) {
            console.log("GET ORGANISATION DDI===================================1:");
            this.organisationService.getDdiListByOrganisation(this.organisation_id)
                .pipe(
                    map((value) => value.body?.ddi_list?.filter((ddi) => ddi?.pip_variant === 'pipxt' && ddi?.invitation_id === null && ddi?.user_id === null)),
                )
                .subscribe((resp) => {
                    console.log("GET ORGANISATION DDI==================================RESULT=:", resp);
                    this.extensionsList = resp;

                    //debug step - add numbers to list ------------REMOVE FOR FINAL TESTING
                    //this.extensionsList = [...this.mockdata]
                    //-----------   ---------------------------------

                    console.log('this.extensionsList', this.extensionsList);
                    this.setNumberofExtensions(this.extensionsList);
                    //if extension list is empty set to check again in a 2 minites.

                }, (err) => {
                    console.log('getDdiListByOrganisation error', err);

                    // this.openSnackBar(`An unspecified error occured [${err.status}]`, "Dismiss");
                })

            this.pageStatus.isLoading = false;
        } else {
            //display error message
        }
    }


    // convenience getters for easy access to form fields
    get f() { return this.dynamicForm.controls; }
    get t() { return this.f.invitations as FormArray; }

    setNumberofExtensions(extensionList: DdiNumber[]) {
        // const numberOfInvitations = extensionList?.length;
        this.f.no_of_extensions.patchValue(extensionList?.length);

        if (this.f.no_of_extensions.value > 0) {
            extensionList.forEach(extension => {

                const num = this.normalizeToInternational.transform(extension.sip_extension_cli);
                this.t.push(this._formBuilder.group({
                    first_name: [''],
                    last_name: [''],
                    organisation_id: [this.organisation_id, Validators.required],
                    email: ['', [Validators.email], [ValidateEmailWithSMSIdentityExists.createValidator(this.signupService, true)]],
                    display_cli: [num],
                    preallocated_number: [extension.number, Validators.required],
                    is_org_admin: [false],
                    sent: ['']
                }));
                //sending //sent //completed //error
            });
        };
        this.ref.detectChanges();
    }

    addMyself(index) {

        const emailToCheck = this.userProfile.email;  // Replace with the email you want to check

        const emailExists = this.t.controls.some((control) => {
            const emailControl = control.get('email');
            return emailControl.value === emailToCheck;
        });

        if (emailExists) {
            alert('You have already added this email');
        } else {
            console.log('Email does not already exist in the FormArray. ok to continue');
            const _emailControl = this.t.controls[index].get('email');
            _emailControl.setValue(this.userProfile.email);
            _emailControl.markAsTouched();
            _emailControl.markAsDirty();
            this.t.updateValueAndValidity();
            setTimeout(() => this.ref.detectChanges(), 100);
        }
    }

    isValidFormWithEmail(): boolean {
        //Form should be valid and there should be at least 1 email
        return this.t.controls.some(control => control.get('email')?.valid && control.get('email')?.value !== '') && this.dynamicForm.valid;
    }
    hasDuplicateEmail(formControls: AbstractControl[]) {
        //check if there are duplicate emails

        const emailValues = formControls
            .map((control) => control.get('email').value)
            .filter((email) => email.trim() !== ''); // Filter out empty values
        const uniqueEmailValues = new Set(emailValues);

        return emailValues.length !== uniqueEmailValues.size;
    }

    sendInvitationsAPI(organisation_id: string, email: string): Observable<responseModel> {
        //used for mock api call
        console.log('[user-invitations.component.ts] sendInvitationsAPI()', email)
        const resp: responseModel = {
            body: {},
            status: 200,
            ok: true,
            statusText: 'test',
            type: 1,
            url: null
        }
        const delayTime = Math.random() * 4000; // Random delay up to 4 seconds
        return of(resp).pipe(
            delay(delayTime)
        );
    }

    sendInvitations() {
        console.log('[user-invitations.component.ts] sendInvitations()', this.t.controls)
        const isDuplicateEmail = this.hasDuplicateEmail(this.t.controls);

        if (isDuplicateEmail) {
            return alert('Please correct duplicate email values');
        }
        this.pageStatus.isSubmitting = true;
        let completedLoops = 0;

        this.appInsightService.logEvent('Welcome | updated invitations', { 'organisation_id': this.organisation_id });

        this.t.controls.forEach((control, index) => {  //loop through each form control
            const email = control.get('email').value;

            if (email !== '') { //if email is not empty
                control.get('sent').patchValue('sending');
                this.sendInvitation(control, index, email)
                    .subscribe(
                        (resp) => this.handleInvitationResponse(resp, control, index, email, ++completedLoops),
                        (err) => this.handleInvitationError(err, control, index, email, ++completedLoops)
                    );
            } else {
                completedLoops++;
            }


        })
        this.checkFinalization(completedLoops);
    }

    sendInvitation(control, index, email) {
        console.log('[user-invitations.component.ts] sendInvitation()', email);

        const isAdmin = this.userProfile.email === email ? true : control.get('is_org_admin').value;
        let invitationCart = new InvitationProvision();  //create new invitation cart

        invitationCart = {
            email: email,
            organisation_id: control.get('organisation_id').value,
            preallocated_number: control.get('preallocated_number').value,
            license_id: null,
            is_org_admin: isAdmin,
            allocation_option: null,
            cli_override: null
        }

        if (isAdmin) {
            invitationCart.first_name = this.userProfile.first_name;
            invitationCart.last_name = this.userProfile.last_name;
        }

        console.log('[user-invitations.component.ts] sendInvitation() inviation cart', invitationCart);
        // return this.sendInvitationsAPI(this.organisation_id, email); //test code for debugging.
        return this.invitationService.postNewInvite(invitationCart);
    }

    handleInvitationResponse(resp, control, index, email, completedLoops) {
        console.log("Response from invitation sent", index, resp, email);
        control.get('sent').patchValue('sent');
        setTimeout( () => {
            control.get('sent').patchValue('completed');
        }, 2000);
        this.checkFinalization(completedLoops);
    }

    handleInvitationError(err, control, index, email, completedLoops) {
        console.log("Error sending invitation for email", email, err);
        control.get('sent').patchValue('error');
        //need to add error handling here. //TODO
        //SHOW WHICH EMAILS FAIILED TO SEND
        //DONT ALLOW CONTINUE IF ANY ERRORS
        this.checkFinalization(completedLoops);
    }

    checkFinalization(completedLoops) {
        if (completedLoops === this.t.controls.length) {
            this.finalizeForm();
        }
    }

    finalizeForm() {
        this.pageStatus.isSubmitting = false;
        this.pageStatus.invitationsSent = true;
        this.openSnackBar('Invitations sent', 'dismiss');
        setTimeout(() => {
            this.moveToNextStage.emit(true);
        }, 2200);
        this.ref.detectChanges();

    }

    //TEST ERROR HANDLING
    //can ADD fiurst name last name to admin invitation as we know it. // check the userprofile for this.
    // const invitationCart: InvitationProvision = new InvitationProvision();  //create new invitation cart
    // invitationCart.email = control.get('email').value; //set email
    // invitationCart.organisation_id = control.get('organisation_id').value; //set organisation id
    // invitationCart.preallocated_number = control.get('preallocated_number').value; //set preallocated number
    // invitationCart.is_org_admin = control.get('is_org_admin').value; //set is org admin


    debugForm() {
        console.log("DEBUG FORM:", this.dynamicForm);
        console.log(JSON.stringify(this.dynamicForm.value, null, 4));
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
}
