import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


//modules (imports)
import { MaterialModule } from '@app/material/material.module';

import { CCFloatingCardComponent } from './cc-floating-card.component';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule
    ],
    declarations: [CCFloatingCardComponent],
    providers: [],
    exports: [CCFloatingCardComponent],
    entryComponents: []
})
export class CCFloatingCardModule {
}
