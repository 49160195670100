import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatDialog } from '@app/material/material-essentials.module';

import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge, fromEvent, Subject } from "rxjs";
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { OrganisationModel } from '@app/models/organisation.model';

import { Subscription, of,  Observable, throwError } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, distinctUntilChanged, startWith, map, tap, delay, switchMap, takeUntil, finalize } from 'rxjs/operators';

import { PBXService, TenantListModel } from '@app/services/pipcall/pbx.service';

@Component({
    selector: 'app-ff-tenant-search-select-v2',
    templateUrl: './ff-tenant-search-select-v2.component.html',
    styleUrls: ['./ff-tenant-search-select-v2.component.scss']
})
export class TenantSearchSelectV2Component implements OnInit {

    // Form defaults
    @Input() label = 'Tenant'; //can override the label
    @Input() floatLabel: string; //always, never, auto or null
    @Input() placeholder = 'Tenant ID from PBX'

    @Input() filterToPBX: string; //filter to a specific PBX Host ID
    @Input() preselected_id?: string; //enter a preselected value of the tenantcode
    @Output() _selectedValueUpdate = new EventEmitter(); //output the selected value modal

    @Input() readonly: boolean;


    public filteredResults: any[] = [];

    tenantList: TenantListModel[] = [];

    pageStatus = {
        isLoading: false
    }

    @ViewChild('autoComplete') matAutocomplete: MatAutocomplete;
    @ViewChild('userSearchInput') userSearchInput: ElementRef<HTMLInputElement>;
    selectedObject: any;

    constructor(
        public organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private ref: ChangeDetectorRef,
        private pbxService: PBXService
    ) {
    }

    ngOnInit() {
        console.log('filterToPBX', this.filterToPBX);
        this.filterToPBX ? this.updateTenantList(this.filterToPBX) : null;
    }

    updateTenantList(pbxhostid) {
        this.pbxService.getTenantByPBXList(pbxhostid)
            .subscribe((resp) => {
                this.tenantList = resp.body ?  resp.body : [];
                this.filteredResults = [...this.tenantList];
                this.preselected_id ? this.setPreselected(this.preselected_id) : null;
                console.log("tenant list", this.tenantList);
            });
    }

    ngAfterViewInit() {


        fromEvent(this.userSearchInput.nativeElement, 'blur')
            .subscribe((resp) => {
                //handle clicking off the input without selecting an option
                console.log("onblur event", resp);
                this.userSearchInput.nativeElement.value = this.selectedObject ? this.selectedObject?.tenantcode + ' - ' + this.selectedObject?.name : '';
            });

        fromEvent(this.userSearchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(600),
                distinctUntilChanged(),
                tap(() => this.pageStatus.isLoading = true),
            )
            .switchMap((event: any) => {
                return this.returnfilteredValues(event?.target?.value)
            })
            .subscribe((resp) => {
                this.filteredResults = resp ? resp : [...this.tenantList];
                this.pageStatus.isLoading = false;
            }, (err) => {
                this.filteredResults = [];
                this.pageStatus.isLoading = false;
            });


    }

    ngOnChanges(changes: SimpleChanges) {
        (changes['readonly']?.currentValue !== changes['readonly']?.previousValue && !changes['readonly'].firstChange) ? this.ref.detectChanges() : null;
        (changes['filterToPBX']?.currentValue !== changes['filterToPBX']?.previousValue && !changes['filterToPBX'].firstChange) ?  this.updateTenantList(changes['filterToPBX']?.currentValue) : null;
    }

    setPreselected(tenantcode: string) {
        if (tenantcode) {
            const selectedTenant = this.tenantList.find((t) => t.tenantcode === tenantcode);
            this.selectedObject = selectedTenant;
            this.filteredResults = [selectedTenant];
            this.userSearchInput.nativeElement.value = selectedTenant?.tenantcode + ' - ' + selectedTenant?.name;
        }
    }

    returnfilteredValues(value: string): Observable<any> {
        if (!value) { return Observable.of(null)};
        const filterValue = value?.toLowerCase();
        const results = this.tenantList.filter(option => option?.name.toLowerCase().includes(filterValue) || option?.tenantcode.toLowerCase().includes(filterValue));
        return of(results)
    }

    optionSelected(event: MatAutocompleteSelectedEvent): void {
        this._selectedValueUpdate.emit(event.option.id); //id is entire data object
        this.updateSelectionToParent(event.option.id)
    }

    public returnSelectedOrganisation(): any {
        //use to return current selection to parent component
        return this.selectedObject;
    }

    clearValues() {
        this.userSearchInput.nativeElement.value = '';
        this.filteredResults = [...this.tenantList];
        this.updateSelectionToParent('')
    }

    updateSelectionToParent(value?: any) {
        value ? this._selectedValueUpdate.emit(value) : this._selectedValueUpdate.emit('');
        this.selectedObject = value ? value : null;
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
