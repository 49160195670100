import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//requirements
// import { PaymentsService } from '@app/services/pipcall/payments.service';
//others
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { FlexLayoutModule } from '@angular/flex-layout'
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { LicenceTariffsComponent } from './view-licence-tariff/view-licence-tariff.component';
import { AddOnTariffsComponent } from './view-add-on-tariff/view-add-on-tariff.component';
import { MdePopoverModule } from '@material-extended/mde';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        StatusIconsModule,
        FlexLayoutModule,
        MdePopoverModule
    ],
    declarations: [LicenceTariffsComponent, AddOnTariffsComponent],
    exports: [LicenceTariffsComponent, AddOnTariffsComponent],
    providers: [HttpProxy, LicenseDataService],
    entryComponents: []
})
export class LicenceTariffsModule { }
