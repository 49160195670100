import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, FormControl, Validators } from '@angular/forms';
import { parse, format, AsYouType, parseNumber, parsePhoneNumber, parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

import { NormalizeNumberService } from '../services//helpers/normalize-number.service';
import { parseHostBindings } from '@angular/compiler';
import { AreaCodeHelper, RegionCodes} from '@app/services/helpers/number-validation-helper.service';
// for help : https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/#_custom_model_form_validator
//https://www.npmjs.com/package/libphonenumber-js

//assumes form has parent with Region control field and default selected value.

@Directive({
    selector: '[validBusinessNumber]',
    providers: [
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        { provide: NG_VALIDATORS, useExisting: BusinessnumberValidator, multi: true }
    ]
})
export class BusinessnumberValidator implements Validator {

    // public _regionList: Array<RegionCodes>;

    validate(cli_override: AbstractControl): ValidationErrors | null {
        return BusinessnumberValidator.validateNumber(cli_override);
    }


    // eslint-disable-next-line @typescript-eslint/member-ordering
    static validateNumber(control: AbstractControl): ValidationErrors | null {

        // BusinessnumberValidator
        // const _regionList: Array<RegionCodes> = NormalizeNumberService.getRegionList();
        // console.log("VALIDATE NUMBER: ", control.value);

        if (control.value) {

            const numberTrimmed = control.value.trim().replace(/\s+/g, '');

            if (!BusinessnumberValidator.regexTest(numberTrimmed)) {
                // console.log("RETIURN NOT VALID:")
                return { valid: "Number is not valid" }
            } else {
                // console.log("DO IT HERE");
                const region = 'uk44'; //return the selected region as uk44

                //const matchingRegion = _regionList.find(val => val.Region === region); //check regionList to determin matching entry
                const matchingCountry = AreaCodeHelper.getCountryByRegionCode(region);

                // console.log("MATCHING COUNTRY", matchingCountry);

                const code: CountryCode = matchingCountry.code ? matchingCountry.code : 'GB'; //if matching region then return country Code or default to GB if none.

                // console.log("CODE", code);

                const phonenumber = parsePhoneNumberFromString(numberTrimmed, code);
                //console.log("RETURN it here: ", phonenumber.isValid() ? null : { valid: "Number is not valid" })
                return phonenumber.isValid() ? null : { valid: "Number is not valid" }
            }

        }
        // console.log("RETURN NULL");
        return null;
    // If no error, return null
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static regexTest(number: string): boolean {
        const validCharacterRegex = /(^0044|^\+?44|^0|^)\d{10}$/
        //contains only numbers or  +
        // starts with 447 , 00447, +447, 07 or 7 and is prepended by another 9 digits
        return validCharacterRegex.test(number);
    }

}




// this.asYouType = new AsYouType('GB').input('07740352865');
// this.numberformat = format('+447740352865', 'GB', 'National'); //E164 //Original //International
// this.parse = parsePhoneNumber('+447740352865', 'GB')

// console.log("asYoutype",this.asYouType);
// console.log("numberformat",this.numberformat);
// console.log("country",this.parse.country);
// console.log("number",this.parse.number );
// console.log("valid",this.parse.isValid() );
