import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { UserService } from '@app/services/pipcall/user.service';
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { SignupService } from '@app/services/pipcall/signup.service';

import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import {LicenseService} from '@app/services/pipcall/license.service';
//component
import { LicenceDetailsTabSystemComponent } from './licence-details-system.component'


import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';

// modules
import { MdePopoverModule } from '@material-extended/mde';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';

import { LicenseSelectModule } from '@app/components/license-select/license-select.module';
import { PricingModalModule } from '@app/components/modals/pricing-modal/pricing-modal.module';
import { TermsAndConditionsModalModule } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.module'
import { PaymentTermsModalComponentModule } from '@app/components/modals/payment-terms-modal/payment-terms-modal.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule,
        GravatarDirectiveModule,
        modalServiceModule,
        LicenseSelectModule,
        PricingModalModule,
        TermsAndConditionsModalModule,
        PaymentTermsModalComponentModule
    ],
    declarations: [LicenceDetailsTabSystemComponent],
    exports: [LicenceDetailsTabSystemComponent],
    providers: [AppInsightService, OrganisationService, UserService, NormalizeNumberService, LicenseService, SignupService, PhonenumberValidator, PaymentsService, LicenseDataService],
    entryComponents: []
})
export class LicenceDetailsTabSystemModule { }
