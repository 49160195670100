
export class Invoice {
    id: string;
    organisation_id: string
    type: string;
    subtype: string;
    reference: string;
    total_net: number;
    total_vat: number;
    total_gross: number;
    total_paid: number;
    paid_at: string;
    state: string;
    state_reason: string;
    xero_invoice_id: string;
    xero_invoice_number: string;
    created_at: string;
    organisation_name: string;
    vat_rate: number;
    due_date: string;
    is_paid: boolean;
    notes: string;
    date: string;
    payment_fee_amount: number;
    invoice_license_items: Map<string, any>;
    invoice_add_on_package_items: Map<string, any>;
    invoice_license_stub_items: Map<string, any>;
    invoice_ad_hoc_items: Map<string, any>
}
//used by list all invoices
//due_days_until is not returned by api, it is calculated
export class InvoiceListModel {
    id: string;
    organisation_id: string;
    organisation_name: string;
    type: string;
    subtype: string;
    reference: string;
    total_net: number;
    total_vat: number;
    total_gross: number;
    total_paid: number;
    paid_at: string;
    state: string;
    state_reason: string;
    notes: string;
    is_paid: boolean;
    payment_fee_amount: number;
    date: string;
    due_date: string;
    days_until_due: number;
    xero_invoice_id: string;
    xero_invoice_number: string;
    created_at: string;
}

export class InvoiceLicenseItem {
    id: string;
    invoice_id: string;
    license_id: string;
    licensee_type: string;
    user_id: string;
    invitation_id: string;
    license_name: string;
    license_contract_name: string;
    price: number;
    first_name: string;
    last_name: string;
    email: string;
}


export class AddOnPackageItem {
    id: string;
    invoice_id: string
    add_on_package_id: string
    package_name: string
    package_type: string
    package_version_number: string
    package_quantity: number;
    package_price: number;
    package_unit: string
    quantity: number;
    net_amount: number;
    carry_over: number;
}

export class AdHocItem {
    id: string;
    invoice_id: string;
    description: string;
    net_amount: number;
    is_vatable: boolean;
}

export class StubItem {
    id: string;
    invoice_id: string;
    license_id: string;
    user_id: string;
    billable_days: number;
    billable_fraction: number;
    charge_amount: number;
    refund_amount: number;
    net_amount: number;
    license_name: string;
    license_contract_name: string;
    price: number;
    first_name: string;
    last_name: string;
    email: string;
}

