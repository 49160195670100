//core
import { Component, OnInit, Inject, isDevMode, Output, EventEmitter } from '@angular/core';
import {MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

//form essentials
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

//validation
import { PhonenumberValidator } from '../../../validators/phonenumberValidator.directive';
import { DdiProvisioningRequest } from '../../../models/ddi.model';

//services
import { NumberService } from '../../../services/pipcall/number.service';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';


//normalise number
import { RegionCodes, NormalizeNumberService } from '../../../services/helpers/normalize-number.service';

//models
import { PbxPlatform, Providers } from '../../../models/ddi.model';

import {NormalizeTo00} from '../../../pipes/formatNumber';

@Component({
    selector: 'create-ddi-modal',
    templateUrl: './create-ddi-modal.component.html',
    styleUrls: ['./create-ddi-modal.component.scss']
})
export class CreateDdiModalComponent {

    public isDev: boolean;
    public newDdiForm: FormGroup;
    public submitted = false;
    public validNumber = false;
    public newDdiRequest: DdiProvisioningRequest;
    public advancedMode = false;
    @Output() updateDdiList = new EventEmitter();

    //range calcs
    public singleOrRange = "single"; //range
    public toRange: string; //3 number to range
    public isRangeValid = {
        valid: false,
        errorMsg: "",
        count: 0
    }

    //static data
    public pbxPlatformList: PbxPlatform[];
    public providersList: Providers[];
    private providerSubscribe: Subscription;
    private pbxSubscription: Subscription

    private numberRegister: Subscription;


    constructor(
        private dialogRef: MatDialogRef<CreateDdiModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: any, //contains data injected into modal.
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private router: Router,
        private ref: ChangeDetectorRef,
        private numberService: NumberService,
        private staticDataService: StaticDataService,
        private normalizeTo00: NormalizeTo00

    ) {
        this.isDev = isDevMode();
        this.newDdiRequest = new DdiProvisioningRequest();
        this.newDdiForm = this._formBuilder.group({
            number: ['', [Validators.required, Validators.pattern('^(0)([0-9]{13})$')]],
            numberAdvString: ['', [Validators.minLength(14)]],
            pbx_platform_id: ['LV01-UK44', Validators.required],
            provider: ['Gamma', Validators.required],
            is_reserved: [false],
            is_sms_enabled: [false]
        });

        //set defaults for range
        this.toRange = '';
        this.getPbxList();
        this.getProviderList();
    }
    ngOnInit() {
        //this.data //anything you want to do with injected data
    }


    updateRangeTo(f: FormGroup) {
        const number =  f.controls.number.value;
        if (number.length > 12 && number?.startsWith('+44')) {
            f.controls.number.patchValue(this.normalizeTo00.transform(number, 'uk44'));
        }

        this.validNumber = f.controls.number.status === "VALID" ? true : false;

        if (f.controls.number.status === "VALID" && this.singleOrRange === "range") {
            //if number valid & range is selected then update 'To Range'. else clear it.
            this.toRange = f.controls.number.value.substring(f.controls.number.value.length - 3);
            this.validateRange();
            return;
        }
        this.toRange = '';
        this.validateRange();
    }

    updateAdvRange(f: FormGroup) {
        const val = f.controls.numberAdvString.value;
        if (val.length > 12) {
            const find = /\+44/;
            const regex = new RegExp(find, "g");
            const replaced = val.replace(regex, "0044");
            f.controls.numberAdvString.patchValue(replaced);
        }
    }

    getPbxList() {
        this.pbxSubscription = this.staticDataService.getPbxPlatforms().subscribe((resp) => {
            if (resp.status === 200) {
                this.pbxPlatformList = resp.body.pbx_platform_list;
                console.log(this.pbxPlatformList);
            }
        })
    }

    getProviderList() {
        this.providerSubscribe = this.staticDataService.getProviders().subscribe((resp) => {
            if (resp.status === 200) {
                this.providersList = resp.body.provider_list;
                console.log(this.providersList);
            }
        })
    }


    validateRange() {
        //on change to toRange, validate it and count
        if (this.toRange) {

            const from = this.newDdiForm.controls.number.value;
            const parsedStart = parseInt(from.substring(from.length - this.toRange.length));
            const parsedEnd = parseInt(this.toRange);
            const count = parsedEnd - parsedStart + 1;

            if (isNaN(parsedEnd) || isNaN(parsedStart)) { this.setRangeStatus(false, 0, "invalid characters"); return; }

            if (count < 1) {
                //end number cannot be less then start
                //display error
                this.setRangeStatus(false, 0, "range end must be greater then start.")
                return;
            } else {
                //count is valid
                this.setRangeStatus(true, count)
            }
        } else {
            this.setRangeStatus(false, 0);
        }

    }

    setRangeStatus(valid: boolean, count: number, msg?: string) {
        this.isRangeValid.valid = valid;
        this.isRangeValid.count = count;
        this.isRangeValid.errorMsg = msg ? msg : "";
    }


    validateForm(f: FormGroup) {
        if (this.advancedMode) {
            //treat as advanced //skip validation
            this.registerDdi(f.value.numberAdvString, f);
        } else if (f.valid) {
            let numberRangeString;
            if (this.singleOrRange === "range" && this.isRangeValid.valid) {
                if (this.isRangeValid.count > 500) {
                    alert("range cannot exceed 500 numbers at a time");
                    return;
                }
                numberRangeString = f.controls.number.value + '-' + this.toRange;
                this.registerDdi(numberRangeString, f);
            } else if (this.singleOrRange === "single") {
                numberRangeString = f.controls.number.value;
                this.registerDdi(numberRangeString, f);
            }
        } else {
            this.openSnackBar("there is a problem with the form", "dismiss");
            this.submitted = false;
        }
    }

    registerDdi(numberstring: string, f: FormGroup) {

        this.submitted = true;
        this.newDdiRequest.number = numberstring;
        this.newDdiRequest.is_reserved = f.controls.is_reserved.value;
        this.newDdiRequest.pbx_platform_id = f.controls.pbx_platform_id.value;
        this.newDdiRequest.provider = f.controls.provider.value;
        this.newDdiRequest.is_sms_enabled = f.controls.is_sms_enabled.value;

        this.numberRegister = this.numberService.provisionDdi(this.newDdiRequest).subscribe((resp) => {
            this.submitted = false;
            this.updateDdiList.emit(true);
            this.openSnackBar(`Your request for ${resp} ddi/ddis has been sent for processing`, "Dismiss");
        }, (err) => {

            this.submitted = false;
            this.openSnackBar("There was a problem with this request", "Dismiss");
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    ngOnDestroy() {
        this.providerSubscribe.unsubscribe();
        this.pbxSubscription.unsubscribe();
        this.numberRegister ? this.numberRegister.unsubscribe() : null;
    }


}
