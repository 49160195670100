//core
import { Component, ViewChild, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';

@Component({
    templateUrl: './payment-terms-modal.component.html',
    // styleUrls: ['./payment-terms-modal.component.component.scss'],
    animations: [

    ]
})
export class PaymentTermsModalComponent { 

    // data?.firstpayment

    firstPayment:boolean = false;

    @ViewChild(PaymentTermsModalComponent) paymentTermsModalComponent: PaymentTermsModalComponent;

    constructor(
        private dialogRef: MatDialogRef<PaymentTermsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
    ) {

        this.firstPayment = this.data?.firstPayment? this.data.firstPayment:false;
    }



    closeModal() {
        this.dialogRef.close(true);
    }

    scrollEvent(event) {
        let el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop != el.clientHeight ? el.classList.add('pip-scroll-shadow-after'): el.classList.remove('pip-scroll-shadow-after');;
    }

}
