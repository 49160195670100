import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//declarations
import { TabTitleLinedComponent } from './tab-title-lined/tab-title-lined.component'
import { TabSectionActionComponent } from './tab-section-action/tab-section-action.component'
import { SGridItemComponent} from './s-grid-item/s-grid-item.component'
import { SGridItemsContainerComponent } from './s-grid-items-container/s-grid-item-container.component'

@NgModule({
    declarations: [
        TabTitleLinedComponent,
        TabSectionActionComponent,
        SGridItemComponent,
        SGridItemsContainerComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TabTitleLinedComponent,
        TabSectionActionComponent,
        SGridItemComponent,
        SGridItemsContainerComponent
    ]
})
export class UniversalComponentsModule { }
