//core
import { Component, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { Invoice } from '@app/models/invoice.model';
import { InvoiceService } from '@app/services/pipcall/invoice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { slideInRightOnEnterAnimation, bounceInUpOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeOutAnimation, collapseAnimation } from 'angular-animations';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { map, takeUntil, tap, finalize } from "rxjs/operators";
import { throwError, of } from 'rxjs';

@Component({
    templateUrl: './delete-invoice-modal.component.html',
    styleUrls: ['./delete-invoice-modal.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        slideInRightOnEnterAnimation({ duration: 200 }),
        bounceInUpOnEnterAnimation(),
        fadeOutOnLeaveAnimation({ duration: 800 }),
        fadeInOnEnterAnimation({ duration: 400 }),
        fadeOutAnimation(),
        collapseAnimation()
    ]
})
export class InvoiceDeleteModalComponent {


    @ViewChild(InvoiceDeleteModalComponent) invoiceDeleteModalComponent: InvoiceDeleteModalComponent;

    invoiceItem: Invoice;
    viewAs: string;

    invoiceNotesForm: FormGroup;
    showSavedMessage = false;
    public pageStatus = {
        isSubmitting: false,
        isSubmitted: false,
        isSuccess: false,
    }

    constructor(
        private dialogRef: MatDialogRef<InvoiceDeleteModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
        private appInsightService: AppInsightService,
        private authService: AuthService,
        private fb: FormBuilder,
        public snackBar: MatSnackBar,
        private invoiceService: InvoiceService
    ) {
        this.invoiceItem = new Invoice();

        this.invoiceItem = data?.invoice;
        this.viewAs = data?.viewAs;

        this.invoiceNotesForm = this.fb.group({
            invoice_id: [null, Validators.required],
            notes: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.invoiceNotesForm.patchValue({invoice_id: this.invoiceItem.id})
        this.invoiceNotesForm.patchValue({notes: this.invoiceItem.notes})
    }

    deleteInvoice(f: FormGroup) {
        this.pageStatus.isSubmitting = true;

        this.invoiceService.deleteInvoice(f.value.invoice_id, f.value.notes)
            .pipe(
                finalize(() => {this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("invoice deleted:", resp.body);
                this.appInsightService.logEvent('delete invoice', {'invoice_id': f.value.invoice_id});
                this.pageStatus.isSubmitted = true;
                this.pageStatus.isSuccess = true;
                //close the modal after deletion
                this.openSnackBar("The invoice has been deleted", "dismiss");
                this.closeModal();
            },
            (err) => {
                this.openSnackBar("something went wrong with this request", "dismiss");
                console.log(err);
            }
            );

    }

    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar("copied to clipboard", "dismiss");
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    closeModal() {
        this.dialogRef.close(this.pageStatus.isSuccess);
    }

    scrollEvent(event) {
        const el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop !== el.clientHeight ? el.classList.add('pip-scroll-shadow-after') : el.classList.remove('pip-scroll-shadow-after'); ;
    }

}

