import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//create modal components
import {OrgUserSelectComponent} from './ff-org-user-select.component'


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [OrgUserSelectComponent],
    exports: [OrgUserSelectComponent],
    providers: [HttpProxy],
    entryComponents: []
})
export class OrgUserSelectModule { }
