import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minuteSeconds'
  })
  export class MinuteSecondsPipe implements PipeTransform {
  
      transform(value: number): string {
         const minutes: number = Math.floor(value / 60);
         return minutes.toString().padStart(2, '0') + ':' + 
             (value - minutes * 60).toString().padStart(2, '0');
      }
  }

  @Pipe({
    name: 'SecondsToHHMMSS'
  })
  export class SecondsToHHMMSSPipe implements PipeTransform{

    transform(value: number): string {
      return this.convertToHourMinutesSeconds(value)
    }

     convertToHourMinutesSeconds(n){

      const day = Math.floor(n / (24 * 3600)) 
  
      n = n % (24 * 3600) 
      let _hour = Math.floor(n / 3600) + (day*24);
      let splitArray = Array.from(_hour.toString());
      const hour = splitArray.length < 2 ? _hour.toString().padStart(2, '0'):_hour.toString();
  
      n %= 3600
      const minutes = Math.floor(n / 60).toString().padStart(2, '0');
  
      n %= 60
      const seconds = Math.floor(n).toString().padStart(2, '0'); 
   
      const timestring = hour + ':' + minutes + ':' + seconds;
      return timestring;
    }

  }

  //usage example. 
  //Convert seconds into minute+seconds , 127 into 02:07
//e.g {{valueInSeconds | minuteSeconds}} 