//core
import { Component, OnInit, Inject, isDevMode, HostListener, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'

//services



@Component({
    templateUrl: './example-modal.component.html',
    styleUrls: ['./example-modal.component.scss'],
    animations: [

    ]
})
export class ExampleModal {

    constructor(
        private dialogRef: MatDialogRef<ExampleModal>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
    ) {
        // dialogRef.disableClose = true; //disable closing of modal
    }

    ngAfterViewChecked() {
        // this.scroll.reset()
    }

    ngOnInit() {

    }

    closeModal() {
        this.dialogRef.close(true);
    }

    scrollEvent(event) {
        let el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop != el.clientHeight ? el.classList.add('pip-scroll-shadow-after'): el.classList.remove('pip-scroll-shadow-after');;
    }


}