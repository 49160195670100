<div class="pip-modal-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <!-- <div class="pip-modal-header" [style.margin-bottom.px]="'30'"> -->
    <!-- <span class="pip-modal-close" (click)="closeModal()">X</span> -->
    <!-- <h3 class="text-center">Download the PiPcall app</h3> -->
    <!-- <span class="pip-modal-title" style="color: white;">Download the PiPcall app</span> -->
  <!-- </div> -->

  <div id="pipmodalbody" class="pip-modal-body" fxLayout="row" style="width: 100%;">

    <div fxLayout="column" fxLayoutAlign="center center">

      <div class="phone-image">
        <img src="assets/img/twin-phones-v3.png" alt="pipcall app">
      </div>

      <!-- <div fxLayout="row" fxLayoutAlign="center center">
        <div style="padding: 10px;">
          <img src="assets/img/appstore-sm.png" alt="App Store" target="_blank" 
            class="clickable" (click)="downloadiosApp()">
        </div>
  
        <div style="padding: 10px;">
          <img src="assets/img/googleplay-sm.png" alt="Play Store" class="clickable second-option" 
            (click)="downloadAndroidApp()">
        </div>
      </div> -->
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="content-right">
      <div class="download-title">
        Download the PiPcall app
      </div>
      <div class="download-links" fxLayout="column" fxLayoutAlign="center center">
        <div class="text-center" >
          <img src="assets/img/oneclick_appdownload.svg" alt="App Store QR link" >
        </div>

        <div style="padding: 10px;">
          <img src="assets/img/appstore-sm.png" alt="App Store" target="_blank" 
            class="clickable" (click)="downloadiosApp()">
        </div>

        <div style="padding: 10px;">
          <img src="assets/img/googleplay-sm.png" alt="Play Store" class="clickable second-option"
            (click)="downloadAndroidApp()">
        </div>

      </div>


      <!-- <div class="download-links"  fxLayout="column" fxLayoutAlign="center center">
      contacts-phone-clay
      <div class="text-center">
        <img src="assets/img/oneclick_appdownload.svg" alt="App Store QR link" [style.margin-bottom.px]="20">
      </div>

      <div class="text-center">
        <img src="assets/img/appstore-sm.png" alt="App Store" target="_blank" [style.margin-bottom.px]="20"
          class="clickable" (click)="downloadiosApp()">
      </div>

      <div class="text-center">
        <img src="assets/img/googleplay-sm.png" alt="Play Store" class="clickable second-option"
          (click)="downloadAndroidApp()">
      </div>

    </div> -->

    </div>

    <!-- <div class="pip-modal-footer">
     
    </div> -->

  </div>
