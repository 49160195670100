//core
import { Component, ViewChild, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';

@Component({
    selector: 'app-pricing-modal',
    templateUrl: './pricing-modal.component.html',
    styleUrls: ['./pricing-modal.component.scss'],
    animations: [

    ]
})
export class PricingModalComponent {


    @ViewChild(PricingModalComponent) pricingModalComponent: PricingModalComponent;

    licenseToggle = 'license';

    includeAddOns = false;

    constructor(
        private dialogRef: MatDialogRef<PricingModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
    ) {}



    closeModal() {
        this.dialogRef.close(true);
    }



}
