//core
import { Component, OnInit, Input, isDevMode, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { Observable, Subscription, throwError, from, } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { UserProfile } from '@app/models/user-profile.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { Subject } from "rxjs";


@Component({
    selector: 'app-ff-org-user-select',
    templateUrl: './ff-org-user-select.component.html',
    styleUrls: ['./ff-org-user-select.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(500)),
        ]),

    ]
})
export class OrgUserSelectComponent {
    @Input() organisation_id: string;
    @Input() selected_user_id: string;
    @Input() viewAs: string;
    @Input() disabled: boolean;
    @Input() showAsMenu: string; //optional. to show user select as a menu
    @Input() showPipnumbers: boolean; // optionally show the amount of pipnumbers a user has in brackets
    @Input() returnUsersWithNoPiP: boolean; //only show a list of users with no pipnumbers for this organisation
    @Output()selectedUser = new EventEmitter();



    public orgUsers: UserProfile[];
    public orgUsers_noPiP: UserProfile[];
    public isLoading: boolean;
    _destroy$ = new Subject<void>();

    constructor(
        private organisationService: OrganisationService
    ) {

    }

    ngOnInit() {
        this.isLoading = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        changes['organisation_id']?.currentValue !== changes['organisation_id']?.previousValue ? this.getOrgUserList() : null;

    }

    getOrgUserList() {
        if (this.organisation_id) {
            this.isLoading = true;
            this.organisationService.getOrgUsersById(this.organisation_id)
                .pipe(
                    finalize(() => { this.isLoading = false }),
                    takeUntil(this._destroy$)
                )
                .subscribe((resp) => {
                    if (resp.status === 200) {
                        this.orgUsers = resp.body.user_list;
                        this.orgUsers_noPiP = resp.body.user_list?.filter(user => user.organisation_list[0]?.ddi_list.filter(n => n.number != null).length === 0);
                        if (this.showAsMenu && this.selected_user_id) {
                            this.emitValue(this.selected_user_id);
                        }
                    }
                }, (err) => {
                    console.log(err);
                })
        } else {
            this.orgUsers = [];
        }
    }

    emitValue(user_id: string) {
        console.log("EMIT VALUE:", user_id);
        const user = this.orgUsers.filter(_user => _user.id === user_id);
        console.log("user VALUE:", user);
        const output = {
            user_id: user_id,
            name: ''
        }

        if (user_id) {
            output.user_id = user_id;
            output.name = user[0]?.first_name + ' ' + user[0]?.last_name;
        }
        console.log("OUTPUT:", output);
        this.selectedUser.emit(output);
    }



    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }
}
