//core
import { Component, ViewChild, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';

@Component({
    selector: 'app-payment-terms-and-conditions',
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss'],
    animations: [

    ]
})
export class TermsAndConditionsComponent { 


    @ViewChild(TermsAndConditionsComponent) termsAndConditionsComponent: TermsAndConditionsComponent;

    constructor(
        private dialogRef: MatDialogRef<TermsAndConditionsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
    ) {}



    closeModal() {
        this.dialogRef.close(true);
    }

    scrollEvent(event) {
        let el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop != el.clientHeight ? el.classList.add('pip-scroll-shadow-after'): el.classList.remove('pip-scroll-shadow-after');;
    }

}
