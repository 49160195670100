
import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, FormControl, Validators, FormGroup } from '@angular/forms';

// for help : https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/#_custom_model_form_validator
@Directive({
    selector: '[matchPassword]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: PasswordMatchValidation, multi: true }
    ]
})
export class PasswordMatchValidation {

    validate(confirmPassword: AbstractControl): ValidationErrors | null {
        return PasswordMatchValidation.MatchPassword(confirmPassword);
    }

    static MatchPassword(AC: AbstractControl) {
        let confirmPassword = AC.value;

        if (confirmPassword) {
            let password = AC.parent.value["password"];
            if(password != confirmPassword) {
                     return {MatchPassword:"Passwords do not match"}
                 } else {
                     return null
                 }
        }
        return null;
    }

}
