import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//modules (imports)
import { MaterialModule} from '@app/material/material.module';
import { CustomPipesModule} from '@app/pipes/pipes.module'
import { GravatarDirectiveModule} from '@app/directives/gravatar/gravatar.module';

//components (declarations)
import {OrgUserSearchSelectComponent} from './ff-org-user-search-select.component'
import {MatAutocompleteModule} from '@angular/material/autocomplete';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        MatAutocompleteModule,
        GravatarDirectiveModule
    ],
    declarations: [OrgUserSearchSelectComponent],
    exports: [OrgUserSearchSelectComponent],
    providers: [HttpProxy],
    entryComponents: []
})
export class OrgUserSearchSelectModule {
}
