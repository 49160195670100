//core
import { Component, OnInit, Inject, isDevMode, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import {VersionCheckService} from '@app/services/version-check.service';
import { HttpClient } from '@angular/common/http';

@Component({
    templateUrl: './debug-modal.component.html',
    styleUrls: ['./debug-modal.component.scss'],
    animations: [
    ]
})
export class DebugModalModal {



    // public organisationName: string;


    constructor(
        private dialogRef: MatDialogRef<DebugModalModal>,
        private versionCheckService: VersionCheckService,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA)
        public data: any, //contains data injected into modal.
    ) {
        dialogRef.disableClose = true;
    }


    ngOnInit() {

    }
    checkFileExists() {
        const url = '/assets/version.json';

        this.http.get(url + '?t=' + new Date().getTime())
            .first()
            .subscribe(
                (response: any) => {
                    console.log("RESPONSE FROM CHECK FILE:", response);

                },
                (err) => {
                    console.error(err, 'Could not get version file');
                }
            );
    }

    checkFileExistsv2() {
        const url = '/assets/version.json';

        this.http.get(url)
            .first()
            .subscribe(
                (response: any) => {
                    console.log("RESPONSE FROM CHECK FILE:", response);

                },
                (err) => {
                    console.error(err, 'Could not get version file');
                }
            );
    }

    closeModal() {
        this.dialogRef.close(true);
    }
}
