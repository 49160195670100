import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../../models/user-profile.model';
import { responseModel } from '../../models/response.model';
import { PbxPlatform, Providers } from '../../models/ddi.model';
import { ManagementReportModel } from '../..//models/management-report';
import { AuthService } from '../auth-service/auth.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PBXService, PBXHostListModel } from '@app/services/pipcall/pbx.service';
export class AreaCode {
    code: string;
    name: string;
    id: string;
}

export interface State {
    name: string;
}


@Injectable()
export class StaticDataService {


    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;
    public options;
    private isSysAdmin: boolean;

    private areaCodeList = new BehaviorSubject<AreaCode[]>(null);
    private pbxList = new BehaviorSubject<PbxPlatform[]>(null);
    private pbxHostList = new BehaviorSubject<PBXHostListModel[]>(null);
    private providerList = new BehaviorSubject<Providers[]>(null);
    private organisationStateList = new BehaviorSubject<State[]>(null);

    areaCodeListAsObservable = this.areaCodeList.asObservable();
    pbxListAsObservable = this.pbxList.asObservable();
    providerListAsObservable = this.providerList.asObservable();
    organisationStateListAsObservable = this.organisationStateList.asObservable();
    pbxHostListAsObservable = this.pbxHostList.asObservable();


    constructor(private httpProxy: HttpProxy, private authService: AuthService, private pbxService: PBXService) {
        this.isSysAdmin = this.authService.isSysAdmin();
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    //to do, add expiry , dont fetch again if already has data.

    innit() {
        !this.areaCodeList.value ? this.setAreaCode() : '';
        (!this.providerList.value && this.isSysAdmin) ? this.setProviderList() : '';
        (!this.pbxList.value && this.isSysAdmin) ? this.setPbxList() : '';
        (!this.organisationStateList.value && this.isSysAdmin) ? this.setOrganisationState() : ';';
        (!this.pbxHostList.value && this.isSysAdmin) ? this.setPbxHostList() : ';';
    }


    setAreaCode() {
        console.log('get area code');

        this.getAreaCodes()
            .subscribe((resp) => {
                if (resp.status === 200) {
                    this.areaCodeList.next(resp.body.areacode_list)
                }
            })
    }

    setProviderList() {
        console.log('get providers list');
        this.getProviders()
            .subscribe((resp) => {
                if (resp.status === 200) {
                    this.providerList.next(resp.body.provider_list)
                }
            })
    }

    setPbxList() {
        console.log('get pbx list');
        this.getPbxPlatforms()
            .subscribe((resp) => {
                if (resp.status === 200) {
                    this.pbxList.next(resp?.body.pbx_platform_list)
                }
            })
    }

    setPbxHostList() {
        console.log('get pbx host list');
        this.getPbxHostPlatforms()
            .subscribe((resp) => {
                if (resp.status === 200) {
                    this.pbxHostList.next(resp?.body)
                }
            }, (err) => {
                console.log("Error getting pbxHostList", err);
            })
    }

    setOrganisationState() {
        console.log('get organisation states');
        this.getOrganisationStates()
            .subscribe((resp) => {
                if (resp.status === 200) {
                    console.log(resp.body?.organisation_state_list);
                    this.organisationStateList.next(resp.body?.organisation_state_list)
                }
            })
    }

    public getPbxPlatforms(): Observable<responseModel> {
        return this.httpProxy.get<responseModel>(this.ApiBaseUrl + `/pbxs${this.code}`, 'response', true );
    }

    public getPbxHostPlatforms(): Observable<responseModel> {
        return this.pbxService.getPBXList();
    }

    public getProviders(): Observable<responseModel> {
        return this.httpProxy.get<responseModel>(this.ApiBaseUrl + `/providers${this.code}`, 'response', true );
    }

    public getAreaCodes(): Observable<responseModel> {
        return this.httpProxy.get<responseModel>(this.ApiBaseUrl + `/areacodes${this.code}`, 'response', true );
    }

    public getOrganisationStates(): Observable<responseModel> {
        return this.httpProxy.get<responseModel>(this.ApiBaseUrl + `/organisation_states${this.code}`, 'response', true );
    }



}
