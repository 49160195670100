import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { MatSnackBar, MatDialog } from '@app/material/material-essentials.module';

import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { FormBuilder, FormGroup } from '@angular/forms';

import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { OrganisationModel } from '@app/models/organisation.model';

import { Subscription, Observable, throwError } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';

@Component({
    selector: 'app-ff-org-search-select',
    templateUrl: './ff-org-search-select.component.html',
    styleUrls: ['./ff-org-search-select.component.scss']
})
export class OrgSearchSelectComponent implements OnInit {

    @Input() selected_organisation_id: string;
    @Input() selected_organisation_name: string;
    @Input() disabled: boolean; // not working
    @Output() _selectedOrganisation = new EventEmitter();


    public organisationList: OrganisationModel[]; //list of orgs
    public filteredOrgs: OrganisationModel[] = [];

    isLoading = false;

    //controls
    organisationSearchControl = new FormControl();
    organisationForm: FormGroup;
    userInputCtrl = new FormControl();


    @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoComplete') matAutocomplete: MatAutocomplete;


    constructor(
        public organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private fb: FormBuilder
    ) {

        this.organisationList = new Array<OrganisationModel>();
        this.organisationForm = this.fb.group({
            userInputCtrl: ''
        })

    }

    ngOnInit() {
        this.organisationForm
            .get('userInputCtrl')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => this.isLoading = true),
                switchMap(
                    value => this.filterOrgs(value)
                        .pipe(
                            finalize(() => this.isLoading = false),
                        )
                )
            )
            .subscribe((resp) => {
                console.log("value change response", resp);
                if (resp?.body?.organisation_list) {
                    resp.body.organisation_list.sort((a, b) => a.name.localeCompare(b.name));
                    this.filteredOrgs = resp.body.organisation_list;
                } else {
                    this.filteredOrgs = [];
                }
            }, (err) => {
                this.filteredOrgs = [];
            });

        this.disabled ? this.updateDisabledState(this.disabled) : null;
    }

    // this.userInput.nativeElement.value = '';

    ngAfterViewInit() { this.selected_organisation_name ? this.updateSelectionFromParent(this.selected_organisation_name) : null; }

    ngOnChanges(changes: SimpleChanges) {
        (changes['selected_organisation_name']?.currentValue !== changes['selected_organisation_name']?.previousValue && !changes['selected_organisation_name'].firstChange) ? this.updateSelectionFromParent(changes['selected_organisation_name']?.currentValue) : null;
        (changes['disabled']?.currentValue !== changes['disabled']?.previousValue && !changes['disabled'].firstChange) ? this.updateDisabledState(changes['disabled']?.currentValue) : null;

    }

    updateDisabledState(isDisabled: boolean) {
        isDisabled === true ? this.userInputCtrl.disable() : this.userInputCtrl.enable();
    }

    updateSelectionFromParent(value?: string) { this.organisationForm.get('userInputCtrl').patchValue(value, { emitEvent: false }); }


    selected(event: MatAutocompleteSelectedEvent): void { this._selectedOrganisation.emit(event.option.id); }


    filterOrgs(value: string) {
        console.log("FILTER ORGS", value);
        const isGuid = new RegExp(/^[A-Z0-9]{8}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{12}$/i);

        if (!value) {
            return Observable.of(null);
        }
        if (value && isGuid.test(value)) {
            return this.organisationService.getOrganisationById(value)
                .catch(err => {
                    const resp = { body: { organisation_list: [] } }
                    return Observable.of(resp);
                })
        } else {
            const filterValue = value?.toLowerCase();
            const searchparams = {
                "organisation_state_list": [],
                "is_blocked_list": [true, false],
                "search_string": filterValue,
                "page_size": 15,
                "page_number": 0
            }
            return this.organisationService.postOrganisationQuery(searchparams)
        }

    }

    toggleOrgSearch(isEnabled: boolean) {

    }

    clearValues() {
        // this.userInput.nativeElement.value = '';
        this.organisationForm.get('userInputCtrl').setValue('', { emitEvent: false });
        this.filteredOrgs = [];
        this._selectedOrganisation.emit('');
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    // displayFn(organisation: OrganisationModel): string {
    //     return organisation ? organisation.name : '';
    // }

}
