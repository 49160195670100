
import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { InvitationService  } from '@app/services/pipcall/invitation.service';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
//component
import { OrgTabInvitationPanelComponent } from './org-users-invitation-panel.component'

//modules
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { AreaCode } from '@app/services/pipcall/number.service';
import { InviteUserModalModule } from '@app/components/modals/invite-user-modal/invite-user-modal.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        GravatarDirectiveModule,
        InviteUserModalModule,
        UniversalComponentsModule
    ],
    declarations: [OrgTabInvitationPanelComponent],
    exports: [OrgTabInvitationPanelComponent],
    providers: [AppInsightService, OrganisationService, InvitationService, AreaCode, StaticDataService],
    entryComponents: []
})
export class OrgTabInvitationPanelModule { }
