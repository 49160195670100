import { Injectable} from '@angular/core';
import { CookieService} from 'ngx-cookie-service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, filter, catchError, debounceTime, tap, finalize, takeUntil, take } from 'rxjs/operators';
import { myConfig } from '@app/services/auth-service/auth.conf';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as jwt_decode from "jwt-decode";
import { of } from 'rxjs/observable/of';
import { first } from 'rxjs/operators';
//To inject access Token into all http calls

@Injectable()
export class TokenService {

    private isRefreshingToken = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private cookieService: CookieService,  private router: Router, private http: HttpClient) {
    }

    returnAccessToken(): Observable<string> {

        if (this.cookieService.check('access_token') && this.cookieService.get('access_token') !== '') {
            //check if the access token is valid
            if (!this.isValid) {
                //if the token is not valid then refresh it}
                return this.handleRefreshToken()
            }

            return of(this.cookieService.get('access_token'));
        } else if (this.cookieService.check('refresh_token')) {
            if (this.cookieService.get('refresh_token') === '') {
                //remove the refresh token cookie
                console.log("refresh token is empty, delete it");
                this.cookieService.delete('refresh_token');
                return of(null);
            }
            return this.handleRefreshToken()
        } else {
            return of(null);
        }
    }

    get(): string {
        return this.cookieService.check('access_token') && this.cookieService.get('access_token') !== '' ? this.cookieService.get('access_token') : null;
    }


    public refreshToken(): Observable<string> {
        return this.handleRefreshToken();
    }

    private handleRefreshToken(): Observable<string> {
        console.log('[token.service].handleRefreshToken()');
        console.log("check if already refreshing token", this.isRefreshingToken)

        // refreshtoken = this.cookieService.check('refresh_token')
        if (!this.isRefreshingToken) {
            console.log("refresh the token")
            this.isRefreshingToken = true;
            this.refreshTokenSubject.next(null);

            console.log("[token.service].postRefreshToken() now")
            const token = this.cookieService.get('refresh_token');
            const body = {
                grant_type: 'refresh_token',
                client_id: myConfig.clientID,
                refresh_token: token
            }
            const option = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'body' as 'body' };
            this.http.post(`https://${myConfig.domain}/oauth/token`, body, option)
                .subscribe((resp: any) => {
                    console.log("[token service].postRefreshToken() response: ", resp)
                    if (!resp?.access_token) {
                        this.refreshTokenSubject.next(null);
                        this.cookieService.delete('refresh_token');
                        return of(null);
                    } else {
                        this.refreshTokenSubject.next(resp?.access_token)
                    }
                    this.isRefreshingToken = false;
                    return this.setSession(resp?.access_token, resp?.refresh_token) ? resp?.access_token ? of([resp?.access_token]) : of(null) : null;
                }, (err) => {
                    console.log('error refreshing token', err);
                    this.refreshTokenSubject.next(null);
                    //delete refresh token
                    this.cookieService.delete('refresh_token');
                    this.isRefreshingToken = false;

                    window.location.reload();

                    return throwError(err);
                    // return of(null);
                });

            // return this.postRefreshToken()
            //     .pipe(
            //         switchMap((resp: any) => {
            //             console.log("[token service].postRefreshToken() response: ", resp)
            //         //this.setSession(resp?.access_token, resp?.refresh_token);
            //         resp?.access_token ? this.refreshTokenSubject.next(resp?.access_token) : this.refreshTokenSubject.next(null);
            //         this.isRefreshingToken = false;
            //         return  this.setSession(resp?.access_token, resp?.refresh_token) ? resp?.access_token ? of([resp?.access_token]) : of(null) : null;
            //         })
            //     )
        }

        //can use take(1) instead of first if this doesn't work.
        return this.refreshTokenSubject.pipe(
            filter(resp => resp !== null),
            first(),
            switchMap((resp) => {
                console.log("[token service] this.isRefreshingToken -> refreshTokenSubject.pipe() response: ", resp);
                if (resp !== null) { this.isRefreshingToken = false}
                return resp
            }) //return the access token
        );

    }

    public isValid(): boolean {
        //checks the token expiry time against the current time
        //if the token is expired then return false
        //if the token is not expired then return true
        //if the token does not exist then return false
        const access_token = (this.cookieService.check('access_token') && this.cookieService.get('access_token') !== '') ? this.cookieService.get('access_token') :  null;
        const decodedACToken = access_token ? this.returnTokenPayload(access_token) : null;
        const expiresAt = decodedACToken.exp;
        const now = new Date().getTime() / 1000; //epoch time in seconds
        return access_token && expiresAt > now ? true : false;
    }

    public setSession(access_token: string, refresh_token?: string): Observable<boolean> {
        const decodedACToken = this.returnTokenPayload(access_token);
        const user_id = decodedACToken.sub;
        const expiresAt = decodedACToken.exp; //epoch time in seconds

        //set date to expire access_token cookie in correct format
        const expiresAtCookie = new Date(0);
        expiresAtCookie.setUTCSeconds(expiresAt);

        this.cookieService.set('access_token', access_token, expiresAtCookie, '/');
        this.cookieService.set('isLoggedIn', 'true', expiresAtCookie, '/');
        this.cookieService.set('expires_at', expiresAt, expiresAtCookie, '/');
        this.cookieService.set('refresh_token', refresh_token, 30, '/'); //30 days //update the refresh token

        //set ID in cookie
        user_id ?  this.cookieService.set('user_id', user_id, 30, '/') : null;

        return of(true);
    }


    public postRefreshToken() {
        // console.log("[[api]] -> Post refresh token from token service");
        console.log("[token.service].postRefreshToken()")
        const token = this.cookieService.get('refresh_token');
        const body = {
            grant_type: 'refresh_token',
            client_id: myConfig.clientID,
            refresh_token: token
        }
        const option = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'body' as 'body' };

        return this.http.post(`https://${myConfig.domain}/oauth/token`, body, option)
    }

    public clearCookie(cb?: any) {
        console.log("[token.service].clearCookies()")
        this.cookieService.set('access_token', '', -1, '/');
        this.cookieService.set('isLoggedIn', '', -1, '/');
        this.cookieService.set('_userProfile', '', -1, '/');
        this.cookieService.set('viewAs', '', -1, '/');
        this.cookieService.set('impersonateUser', '', -1, '/');
        this.cookieService.set('refresh_token', '', -1, '/'); //testing - clear refresh token.
        return cb;
    }

    public clearAllStorage(cb?: any) {
        console.log("[token.service].clearAllStorage()")
        this.clearCookie();
        localStorage.clear();
        sessionStorage.clear();
        return cb;
    }


    private returnTokenPayload(token) {
        return this.getDecodedAccessToken(token);
    }
    private getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}


// _get(): string {
//     //does the access token exist and is it vaid ?
//     //is yes then return it
//     //else if it does not exist, does a valid refresh token exist ?
//     //if yes then get a new access token and return it
//     //else log the user out and drop a message about expired session

//     console.log("==============SET TOKEN CHECK FIRED=====================", this.cookieService.check('access_token'));
//     // this.cookieService.check('access_token');

//     if (this.cookieService.check('access_token')) {
//         return this.cookieService.get('access_token');
//     } else if (this.cookieService.check('refresh_token')) {

//         //get a new access token
//         this.handleRefreshToken();

//     } else {
//         //log the user out
//         //return an error
//         this.clearCookie(this.router.navigateByUrl('/'));
//         // setTimeout(() => {
//         //     window.location.reload();
//         // }, 200);

//         throw new Error('Session Expired');
//     }


//     // return this.cookieService.get('access_token');
// }
