//core
import { Component, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { UserProfile } from '@app/models/user-profile.model';
import { OrganisationModel } from '@app/models/organisation.model';


@Component({
    templateUrl: './admin-upgrade-to-pipuser-modal.component.html',
    styleUrls: ['./admin-upgrade-to-pipuser-modal.component.scss'],
    animations: [

    ]
})
export class AdminUpgradeToPipuserModuleComponent {


    @ViewChild(AdminUpgradeToPipuserModuleComponent) adminUpgradeToPipuserModuleComponent: AdminUpgradeToPipuserModuleComponent;


    userProfile: UserProfile;
    organisationProfile: OrganisationModel;
    organisation_id: string;


    // userProfile , organisationProfile, organisation_id
    constructor(
        private dialogRef: MatDialogRef<AdminUpgradeToPipuserModuleComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {userProfile: UserProfile, organisationProfile: OrganisationModel, organisation_id: string, mobile?: string, disableClose?: boolean} , //contains data injected into modal.
        private appInsightService: AppInsightService,
        private authService: AuthService,
    ) {


    }

    handsetRegisterReturn(event) {
        console.log("RETURNED EVENT", event);

    }

    updateStatus(event) {
        this.data.disableClose = event ? false : true;
    }


    closeModal(event?: boolean) {

        event =  event ? event : false;
        this.dialogRef.close(event);
    }


}

