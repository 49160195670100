<div class="section-wrapper">

  <tab-title-lined>
    PBX Tenant
  </tab-title-lined>


  <div class="section-content">

    <div fxLayout="row" fxFlex="100%" fxLayout.lt-lg="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

      <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1">

        <s-grid-items-container>
          <s-grid-item>
            <div role="label">Host</div>
            <div role="Value">{{number?.sip_host_name}}</div>
          </s-grid-item>
        </s-grid-items-container>
        <!-- 
        <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block inner-shadow" fxFlex="100">
          <input matInput title="Host" [value]="number?.sip_host_name">
          <mat-label>Host</mat-label>
        </mat-form-field> -->

        <app-ff-tenant-search-select-v2 *ngIf="selectedPBXHost?.host" [floatLabel]="'always'"
          [readonly]="selectedPBXHost?.host ? false : true"
          [placeholder]="selectedPBXHost?.host ? 'Tenant ID from PBX' : 'Select PBX Host first'"
          [filterToPBX]="selectedPBXHost?.id" [readonly]="true"
          [preselected_id]="number?.sip_user_name ? number.sip_user_name.slice(0, 3) : null"
          (_selectedValueUpdate)="updateSelectedTenant($event)"></app-ff-tenant-search-select-v2>

      </div>


      <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2" style="padding-top: 40px;"
        fxHide.lt-md>
      </div>

    </div>




  </div>


</div>



<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>