<ng-container *ngIf="filteredList?.length > 0">


<!-- <div class="section-divider"></div> -->

<div class="section-wrapper">

  <div class="section-content">

    <div class="section-title" fxLayoutAlign="space-between start">
      <div>
        <mat-icon svgIcon="warning"></mat-icon>&nbsp;Needs Review
      </div>
    </div>

    <table class="table tab-table-basic table-bordered">
      <thead>
        <tr class="headers">
          <th style="width: 20px;">&nbsp;</th>
          <th>Service identifier / Ext</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <!-- ngRepeat: member in members -->
        <ng-container *ngFor="let number of filteredList">
          <tr>

            <td>
              <mat-icon *ngIf="!number.user_id && number.state === 'Error'" class="status-circle reject-col"
              [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="{{number.event_data}}"
              svgIcon="circle">
            </mat-icon>

            <mat-icon *ngIf="number.user_id && number.state === 'Error'" class="status-circle reject-col"
              [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="{{number.event_data}}"
              svgIcon="circle-checked">
            </mat-icon>


            <!-- warning -->
            <mat-icon *ngIf="number.state === 'Unknown'" class="status-circle warning-col"
              [matTooltipClass]="'tooltipcustom tooltipmultiline'"
              matTooltip="SIP credentials are pending a systems check." svgIcon="circle"></mat-icon>


            <!-- green -->
            <mat-icon *ngIf="!number.user_id && number.state === 'OK'" class="status-circle accept-col"
              [matTooltipClass]="'tooltipcustom tooltipmultiline'"
              matTooltip="SIP credentials check was a success. Please assign a user." svgIcon="circle">
            </mat-icon>


            <mat-icon *ngIf="number.user_id && number.state === 'OK'" class="status-circle accept-col"
              [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="SIP is active."
              svgIcon="circle-checked">

            </mat-icon>

            </td>
            <td>
              <ng-container *ngIf="number?.sip_extension_number;else noextension">{{number?.sip_extension_number}}
              </ng-container>
              <ng-template #noextension>
                <span (click)="openExtensionProfile(number)" class="clickable"
                  style="color: var(--c-url)">{{number.number | normalizeToInternational}}</span>

              </ng-template>
            </td>
            <td>
              <ng-container
                *ngIf="number?.is_pipxt_extensionless === false && (number?.sip_host_name === null || number?.sip_extension_number === null)">
                PBX settings are incomplete for this extension</ng-container>
              <ng-container *ngIf="number?.is_pipxt_extensionless === true && number?.sip_host_name === null">PBX
                settings are incomplete for this extensionless number</ng-container>
            </td>
            <td class="menu-last-cell">
              <button class="edit-option-button" mat-icon-button [matMenuTriggerData]="{number: number}"
                [matMenuTriggerFor]="editSip">

                <mat-icon title="Edit extension" fxHide.lt-md>
                  more_horiz</mat-icon>

                <mat-icon title="Edit extension" fxHide.gt-sm>
                  more_vert</mat-icon>

              </button>
            </td>
          </tr>
        </ng-container>


      </tbody>
    </table>

  </div>
</div>

</ng-container>
<mat-menu #editSip="matMenu" class="v3-mat-menu">

  <ng-template matMenuContent let-number="number">

    <button mat-menu-item title="Edit settings for this extension" (click)="openExtensionProfile(number)">
      <mat-icon svgIcon="settings"></mat-icon>
      <span>Edit Settings</span>
    </button>


    <button class="red-item red-item-full" [disabled]="number?.invitation_id || number?.user_id"
      *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'" mat-menu-item title="Delete this extension"
      (click)="deleteNumber(number)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Delete Extension</span>
    </button>

    <button  class="red-item red-item-full"  *ngIf="number.invitation_id" mat-menu-item title="Revoke invitation for this extension"
      (click)="alertRevokeInvitation(number.invitation_id)" >
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Revoke Invitation</span>
    </button>

  </ng-template>
</mat-menu>
