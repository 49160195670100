//core
import { Component, OnInit, Input, isDevMode, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-form-basic-controls',
    templateUrl: './form-basic-controls.component.html',
    styleUrls: ['./form-basic-controls.component.scss'],
})
export class FormBasicControlsComponent {

    @Input() hideRefresh: boolean;
    @Input() hideExport: boolean;
    @Input() hideReset: boolean;
    @Input() isLoading: boolean;

    @Output() refreshData = new EventEmitter();
    @Output() resetForm = new EventEmitter();
    @Output() exportData = new EventEmitter();


    constructor() {
    }


    ngOnInit() {

    }

    export() {
        this.exportData.emit();
    }

    refresh() {
        this.refreshData.emit();
    }

    reset() {
        this.resetForm.emit();
    }

}
