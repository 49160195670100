<div class="stipe-container" fxLayout="column" fxLayoutAlign="center end" fxLayoutAlign.lt-lg="center center" >

  <div class="form-container-checkout" style="width: 100%;">
    <form novalidate [formGroup]="stripeForm">
      <div id="card-element" class="field"></div>
      <!-- style="width:400px" -->
      <div id="card-element-mobile" class="field"  style="width:300px"></div>
      <div  class="error-message" *ngIf="!isLoading">{{errorMessage.message}}</div>
    </form>
  </div>

 
  <div class="paymentbuttons" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="center center" >
    <button mat-button (click)="cancelCheckout.emit()">
      Cancel
    </button>
    <button id="paymentSubmit" mat-flat-button  class="large-button accent-alt-button" (click)="pay()" [disabled]="isLoading">
      <ng-container *ngIf="!isLoading;else _isLoadingSpinner">Pay {{checkoutResponse.invoice_total/100 | currency:'GBP':'symbol':'1.2-2'}} <span [style.font-size.px]="10">(inc vat)</span></ng-container>
      <ng-template #_isLoadingSpinner><i class="fas fa-spinner fa-spin"></i></ng-template>  
    </button>
  </div>
</div>
