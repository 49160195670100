<mat-form-field appearance="outline" [ngClass]="{'readonly-block': readonly}" class="custom-form-field-v3"
  [floatLabel]='floatLabel'>
  <input matInput [placeholder]="placeholder" aria-label="organisation" [matAutocomplete]="autoComplete"  [matAutocompletePosition]="'below'"
    #userSearchInput>
  <mat-label>{{label}}</mat-label>

  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="optionSelected($event)">
    <mat-option [disabled]=true *ngIf="pageStatus.isLoading" class="is-loading"><span>searching..</span></mat-option>

    <mat-option *ngFor="let res of filteredResults" value="{{res?.tenantcode}} - {{res?.name}}" [id]="res"
      [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="
      {{res?.name}}
      {{res?.tenantcode}}">
      {{res?.tenantcode}} - {{res?.name}}
    </mat-option>

  </mat-autocomplete>


  <mat-icon class="clear-icon" title="clear" *ngIf="!readonly && userSearchInput?.value !==''" svgIcon="delete"
    matSuffix tabindex="-1" (click)="clearValues()"></mat-icon>
    <mat-hint align="end">3 digit tenant ID from PBX host</mat-hint>
</mat-form-field>