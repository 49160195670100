<div fxLayout="column" fxLayoutAlign="start start" class="main-container">
  <button class="pip-rightnav-close_btn right-side" mat-mini-fab (click)="close()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>


  <div class="side-menu-top-title">
    Help Center <span style="color: orange;">BETA</span>
  </div>


  <!-- <div style="font-size: 12px;">viewAs: {{viewAs}}</div>
  <div style="font-size: 12px;">pageRoute: {{pageRoute}}</div> -->

  <div class="rm-search-container" [hidden]="pageStatus.view === 3">
    <div class="refresh-row" fxLayout="row" fxLayoutAlign="start end">
      <button (click)="reset()" mat-flat-button class="refresh-button">
        <mat-icon svgIcon="refresh"></mat-icon>&nbsp;start again
      </button>
    </div>
    <div class="rm-search-title">How can we help you?</div>
    <div class="rm-search-desc">Tell us about the issue to get solutions and support for pipcall-admin-portal.</div>
    <div class="rm-search-search-bar" fxLayout="row">

      <app-help-center-search style="width:100%;" tabIndex="1" [viewAs]="viewAs" (selectedArticle)="selectedArticleUpdate($event)">
      </app-help-center-search>

    </div>
  </div>


  <div class="sidemenu-content-container" fxLayout="column">

    <ng-container *ngIf="!pageStatus.isLoading === true && !pageStatus.gettingContextData === true;else loadingSMcontent">


      <!-- <div class="sm-content-item-wrapper">
      <div class="content-item-title">Example article</div>

      <div fxLayout="column" fxLayoutAlign="start">
          <p>This is some interesting content.</p>
      </div>
    </div> -->
      <ng-container *ngIf="pageStatus.view === 1">
        <div class="sm-content-item-wrapper">


          <div *ngIf="contextualArticles?.length > 0" class="content-item-title">Do you need help with one of these?</div>

          <div fxLayout="column" fxLayoutAlign="start">

            <div fxLayout="column" class="article-wrapper" *ngFor="let item of contextualArticles"
              (click)="loadFAQitem(item?.RowKey)">
              <div class="article-small-title">{{item?.category}}</div>
              <div style="color: var(--c-url);" class="article-title">{{item?.title}}</div>
              <div class="article-desc">{{item?.description}} </div>
            </div>


          </div>

        </div>

        <div class="sm-content-item-wrapper">
          <div class="content-item-title">Support resources</div>
          <div fxLayout="column" fxLayoutAlign="start">


            <a title="Get support"  (click)="navigateToURL('https://www.pipcall.com/support', true)" href="#">Get Support <mat-icon  style="vertical-align:top;margin-left: 8px;" class="icon-size-18" svgIcon="open_link">
              </mat-icon></a>

            <button (click)="loadFAQitem('1')" class="url-btn clickable">Glossary</button>

          </div>
        </div>

        <div class="text-center" *ngIf="this._permissions.faqAdmin && viewAs === 'sysAdmin'">
          <button class="blue-button" (click)="editArticle('new')" mat-flat-button>Create New Article</button>
        </div>

      </ng-container>


      <ng-container *ngIf="pageStatus.view === 2">

        <!-- the loaded FAQ item -->
        <div class="sm-content-item-wrapper">

          <div class="content-item-title" fxLayout="row" fxLayoutAlign="space-between center"><div>Selected article: {{activeFAQitem?.RowKey}} </div> <button (click)="stepBack()" mat-flat-button>Back</button></div>

          <div class="loaded-article-wrapper">


            <div class="a-title" fxLayout="column" fxLayoutAlign="start">
              <p>{{activeFAQitem?.title}}</p>
            </div>

            <div class="a-desc" fxLayout="column" fxLayoutAlign="start">
              <div [innerHTML]="activeFAQitem?.content | unescape:'recursive' | safeHtml"></div>
            </div>

            <!-- <div fxLayout="column" fxLayoutAlign="start" class="updated-by">Updated at {{currentArticle.Timestamp |  date: 'yyyy-MM-dd HH:mm'}} by
              {{currentArticle.updated_by}}</div> -->

            <div class="a-sysAdmin-content" fxLayout="column" fxLayoutAlign="start" *ngIf="viewAs === 'sysAdmin' && activeFAQitem?.sysadmin_content">
              <p><strong>sysAdmin Note&nbsp;<br></strong>{{activeFAQitem?.sysadmin_content}}</p>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" style="margin-top:20px;">
              <div class="updated-by">Updated at {{activeFAQitem?.Timestamp |  date: 'yyyy-MM-dd HH:mm'}} by
                {{activeFAQitem?.updated_by}}</div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" style="margin-top:20px;" fxLayoutGap="10px" *ngIf="this._permissions.faqAdmin">

            <button (click)="deleteArticle(activeFAQitem)" mat-icon-button matTooltip="Delete article"> <mat-icon class="clickable" svgIcon="trash"></mat-icon></button>

            <button class="blue-button"  (click)="editArticle('edit')" mat-flat-button>Edit</button>
          </div>
        </div>

      </ng-container>


      <ng-container *ngIf="pageStatus.view === 3">
        <div class="sm-content-item-wrapper" *ngIf="this._permissions.faqAdmin">
        <!-- <div class="content-item-title" fxLayout="row" fxLayoutAlign="space-between center"><div> </div> <button (click)="stepBack()" mat-flat-button>Back</button></div> -->  
        <hc-edit-panel [toolbarLocation]="'bottom'" [mode]="editMode" [faqList]="faqList" [article]="activeFAQitem" [viewAs]="viewAs" (cancel)="cancelEdit($event)">
        </hc-edit-panel>
      </div>
      </ng-container>



    </ng-container>
    <ng-template #loadingSMcontent>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div style="margin-bottom: 10px;">please wait a moment</div>
        <div>
          <div class="loader-spinner"></div>
        </div>
      </div>
    </ng-template>
  </div>

</div>




<ng-template #loadingSpinner class="text-center">
  <div class="loader-spinner"></div>
</ng-template>
