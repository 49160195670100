//core
import { Component, Input, SimpleChanges, OnChanges, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
//form essentials
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@app/material/material-essentials.module';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { SignupService } from '@app/services/pipcall/signup.service';
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { InvitationProvision } from '@app/models/invitation.model';
import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { NumberService, AreaCode } from '@app/services/pipcall/number.service';
import { TitleCasePipe } from '@angular/common';
import { StaticDataService} from '@app/services/shared-data.service/staticdata.sevice';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService} from '@app/services/pipcall/organisation.service';
import { distinctUntilChanged, map , filter, defaultIfEmpty} from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { TermsAndConditionsComponent } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { PricingModalComponent} from '@app/components/modals/pricing-modal/pricing-modal.component';
import { DdiNumber } from '@app/models/ddi.model';
import { SelectPackageModalComponent} from '@app/components/modals/select-package-modal/select-package-modal.component';
import { SelectedLicenceModel } from '@app/models/license.model';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { InvitationModel } from '@app/models/invitation.model';
import { nextTick } from 'process';
@Component({
    selector: 'app-invite-user-modal',
    templateUrl: './invite-user-modal.component.html',
    styleUrls: ['./invite-user-modal.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ])
    ]
})
export class InviteUserModalComponent {

    public name;
    public email;
    public inviteUserForm; //add invite user model
    public areaCodes: Array<AreaCode>;
    public submitted = false;
    public numberType: 'existingMobile' | 'new' | 'import' | 'existingXt' = null;
    public organisationDetails: OrganisationModel;
    public isMobileTypeSelected: boolean;
    public isPiPxt = false;
    public existingPiPmobile: DdiNumber[];
    public existingPiPxt: DdiNumber[];
    public selectedLicence: SelectedLicenceModel;
    public existingInvitations: InvitationModel[];
    public isselectNumberTypeHidden = false;
    public areaCodeInputWidth = 63; //default width 63 or 100
    numberSubscription: Subscription;
    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: any, //contains data injected into modal
        private dialogRef: MatDialogRef<InviteUserModalComponent>,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private invitationService: InvitationService,
        private numberService: NumberService,
        private signupService: SignupService,
        private titlecasePipe: TitleCasePipe,
        private staticDataService: StaticDataService,
        private normalizeNumberService: NormalizeNumberService,
        private organisationService: OrganisationService,
        private modalService: ModalService
    ) {
        // this.availableExistingNumbers = new Array<DdiNumber>();
        this.existingPiPmobile =  new Array<DdiNumber>();
        this.existingPiPxt =  new Array<DdiNumber>();
        this.selectedLicence = new SelectedLicenceModel();
        this.existingInvitations =   new Array<InvitationModel>();

        this.organisationDetails = data.organisationDetails;
        this.isPiPxt = data?.isPiPxt ? data?.isPiPxt : false;
        this.getAvailablePiPnumbers();

        this.inviteUserForm = this._formBuilder.group({
            is_org_admin: [false, [Validators.required]],
            email: ['', [Validators.email, Validators.required], [ValidateEmailNotTaken.createValidator(this.signupService)]],
            organisation_id: [this.organisationDetails.id, [Validators.required]],
            allocation: this._formBuilder.group({
                allocation_option: [null, Validators.required],
                allocaion_option_existing: [null, Validators.required],
                cli_override: [{ value: '', disabled: true }, [Validators.required, BusinessnumberValidator.validateNumber]]
            }),
            selected_license: ['', Validators.required],
        });
        // region: ['uk44'],
        // invited_by_user_id: [this.data.invited_by_id, [Validators.required]],
        // cli_override: [{ value: '', disabled: true }, [Validators.required, BusinessnumberValidator.validateNumber]],
        this.areaCodes = new Array<AreaCode>();

        this.staticDataService.getAreaCodes().subscribe((resp) => {
            if (resp.status === 200) {
                this.areaCodes = resp.body.areacode_list;
                return;
            } else {
                //else fall back to locally assigning codes
                this.areaCodes = NumberService.getCountryCodes();
            }
        })

    }



    ngOnInit() {
        this.setSelectedLicense();
        if ( this.organisationDetails.is_pipxt_enabled && !this.organisationDetails.is_pipmobile_enabled ) { this.numberType = "existingXt", this.toggleNumber('existingXt')};
        if ( this.organisationDetails.is_pipmobile_enabled && !this.organisationDetails.is_pipxt_enabled ) {
            this.numberType = "new", this.toggleNumber('new')
            this.isselectNumberTypeHidden = true;
            this.areaCodeInputWidth = 100;
        };
        this.onChanges();
    }


    setSelectedLicense() {
        this.organisationDetails?.state === 'Trial' ? this.inviteUserForm.controls['selected_license'].disable() : this.inviteUserForm.controls['selected_license'].enable();
    }

    getAvailablePiPnumbers() {
        console.log("Get available numbers");
        // .pipe(
        //     map((value) => this.filterToAvailable(value))
        // )
        this.numberSubscription =  this.organisationService.getDdiListByOrganisation(this.organisationDetails?.id)
            .pipe(
                map((value) => this.filterToAvailable(value))
            )
            .switchMap((resp) => {
                console.log("are THERE ANY AVAILABLE NUMBERS", resp);

                this.existingPiPmobile = resp.pipMobile;
                this.existingPiPxt = resp.pipXt;

                return  this.invitationService.getListOfInvites(this.organisationDetails.id);
            }).subscribe((resp) => {
                console.log('null resp', resp);
                return;
                this.existingInvitations = resp.body.invitation_list;
                if (this.existingInvitations) { this.filterPipNumbers(); }
            }, (err) => {
                this.openSnackBar(`An unspecified error occured [${err.status}]`, "Dismiss");
            })

    }

    filterPipNumbers() {

        if (!this.existingInvitations) {return};

        this.existingPiPmobile.forEach((item, i) => {
            if (this.existingInvitations.findIndex(n => n.preallocated_number === item.number) > -1) {
                this.existingPiPmobile.splice(i, 1);
            }
        });

    }

    filterToAvailable(resp: any) {
        const availableNumbers = {
            pipMobile: [],
            pipXt: []
        }
        if (resp.status === 200 && resp.body !== null) {
            resp.body?.ddi_list.forEach(ddiNumber => {
                if (!ddiNumber.user_id && !ddiNumber?.invitation_id) {

                    if (ddiNumber.pip_variant === 'pipmobile') {
                        availableNumbers.pipMobile.push(ddiNumber);
                    } else if (ddiNumber.pip_variant === 'pipxt') {
                        availableNumbers.pipXt.push(ddiNumber);
                    }
                }
            });
        }

        return availableNumbers;
    }


    openPackageSelect(isMobileTypeSelected: string, id?: string) {
        console.log("=---------isMobileTypeSelected", isMobileTypeSelected);

        const numberType = isMobileTypeSelected  ? 'mobile' : 'landline';

        const data = { numberType: numberType };

        const dialogRef = this.dialog.open(SelectPackageModalComponent, {
            panelClass: 'pipcall-modal-container',
            data: data
            // width: '680px',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log("result from select: ", result);
            if (result?.licence) {
                //do action on modal close
                this.licenseSelected(result.licence)
            }
        });

    }

    onChanges(): void {
        // this.inviteUserForm.valueChanges.subscribe(val => {
        //     console.log("FORM VALUE CHANGE:", val)
        // });
        console.log("=====================ON CHANGES=======");

        this.inviteUserForm.controls.allocation.get('cli_override').valueChanges
            .subscribe(val => {
                console.log("UPDATE CLI OVERRIDE VALUE", val)
                // this.selectedAllocationType = this.returnCliAllocation(val);
                this.isMobileTypeSelected = this.isMobileSelected(val);
                console.log("this.isMobileTypeSelected set to",   this.isMobileTypeSelected );
            });

        this.inviteUserForm.controls.allocation.get('allocation_option').valueChanges
            .subscribe(val => {
                // this.selectedAllocationType = this.returnCliAllocation(val);
                console.log("UPDATE allocation_option VALUE", val)
                this.isMobileTypeSelected = this.isMobileSelected(val);
                this.inviteUserForm.controls['selected_license'].patchValue(''); //reset licence
                this.selectedLicence = null;
            });

        this.inviteUserForm.controls.allocation.get('allocaion_option_existing').valueChanges
            .subscribe(val => {
                // this.selectedAllocationType = this.returnCliAllocation(val);
                console.log("UPDATE allocaion_option_existing VALUE", val)
                this.isMobileTypeSelected = this.isMobileSelected(val);
            });
    }

    toggleNumber(option) {
        this.resetAllocationOptions();
        switch (option) {
            case 'import':
                this.inviteUserForm.controls.allocation.get('cli_override').enable();
                this.inviteUserForm.controls.allocation.get('allocation_option').disable();
                this.inviteUserForm.controls.allocation.get('allocaion_option_existing').disable();
                this.setSelectedLicense();
                break;
            case 'new':
                this.inviteUserForm.controls.allocation.get('cli_override').disable();
                this.inviteUserForm.controls.allocation.get('allocation_option').enable();
                this.inviteUserForm.controls.allocation.get('allocaion_option_existing').disable();
                this.setSelectedLicense();
                break;
            case 'existingMobile':
                this.inviteUserForm.controls.allocation.get('cli_override').disable();
                this.inviteUserForm.controls.allocation.get('allocation_option').disable();
                this.inviteUserForm.controls.allocation.get('allocaion_option_existing').enable();
                this.setSelectedLicense();
                break;
            case 'existingXt':
                this.inviteUserForm.controls.allocation.get('cli_override').disable();
                this.inviteUserForm.controls.allocation.get('allocation_option').disable();
                this.inviteUserForm.controls.allocation.get('allocaion_option_existing').enable();
                this.inviteUserForm.controls.selected_license.disable();
                break;
        }

    }

    resetAllocationOptions() {
        this.inviteUserForm.controls.allocation.get('allocation_option').patchValue(null, {emitEvent: false});
        this.inviteUserForm.controls.allocation.get('allocaion_option_existing').patchValue(null, {emitEvent: false});
        this.inviteUserForm.controls.allocation.get('cli_override').patchValue('', {emitEvent: false});
    }


    closeModal(success?: boolean) {
        this.dialogRef.close(success);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    transformToTitleCase(value: string) {
        return this.titlecasePipe.transform(value);
    }

    licenseSelected(event) {
        console.log("EVENT---: ", event);
        this.selectedLicence = event;
        this.inviteUserForm.controls['selected_license'].patchValue(event.license_id);
        console.log("UPDATED: value ");
    }

    showForm() {
        console.log(this.inviteUserForm);
    }

    returnCliAllocation(number: string) {
        if (this.numberType === 'import') {
            return number?.startsWith('00447') || number?.startsWith('+447') || number?.startsWith('07') ? 'talk_and_text' : 'talk';
        } else if (this.numberType === 'new') {
            return number === '00447' ? 'talk_and_text' : 'talk';
        }
    }

    isMobileSelected(number: string) {
        console.log("isMobileSelected check():" , number);

        if (!number) {return false; }

        if (this.numberType === 'import' || this.numberType === 'existingMobile' ) {

            console.log("CHECK isMobile", number?.startsWith('00447') || number?.startsWith('+447') || number?.startsWith('07') ? true : false);
            return number?.startsWith('00447') || number?.startsWith('+447') || number?.startsWith('07') ? true : false;
        } else if (this.numberType === 'new') {
            return number === '00447' ? true : false;
        }
    }



    alertInvitation(f: FormGroup, allocationType: string) {


        if (f.controls['selected_license'].disabled) {
            return this.submitForm(f, allocationType);
        } else {



            const titlecopy = 'Purchasing Licence';
            const data: ModalConfirmData = {
                title: titlecopy,
                content: `By sending this invitation you are purchasing a licence for the new user, payment for which will be taken from your registered credit card overnight.  The new user service will be provided under the terms and conditions of the <a target="_blank" href="https://www.pipcall.com/policies/corporatecustomercontract">Corporate Customer Contract</a> that was previously accepted on behalf of your organisation<br><br><strong>I understand that I am purchasing a new license for this user under the terms of the PiPcall Corporate Customer Contract.</strong>`,
                confirmButtonLabel: "Accept",
                closeButtonLabel: "Cancel",
                choice: true,
                disableClose: true
            }

            this.modalService.openConfirmModal(data, (answer: boolean) => {
                if (answer) {

                    this.submitForm(f, allocationType);
                    return;
                }
                console.log('answer was No');
            },
            '380px' );
        }
    }

    submitForm(f: FormGroup, allocationType: string) {
        console.log("submit form", f);

        if (f.invalid) { return; }

        this.submitted = true;


        if (this.numberType === "import") {
            f.controls.allocation?.get('cli_override').patchValue(this.normalizeNumberService.normaliseNumberWith00(f.value.allocation.cli_override, 'uk44'));
        }

        const invitationCart: InvitationProvision = new InvitationProvision();

        invitationCart.email = f.value.email;

        console.log("SWITCH ALLOCATION TYPE");
        switch (allocationType) {
            case 'new':
                invitationCart.allocation_option = f.value.allocation.allocation_option;
                invitationCart.preallocated_number = null;
                invitationCart.cli_override = null;
                break;
            case 'existingMobile':
                invitationCart.allocation_option = null;
                invitationCart.preallocated_number = f.value.allocation.allocaion_option_existing;
                invitationCart.cli_override = null;
                break;
            case 'existingXt':
                invitationCart.allocation_option = null;
                invitationCart.preallocated_number = f.value.allocation.allocaion_option_existing;
                invitationCart.cli_override = null;
                break;
            case 'import':
                invitationCart.allocation_option = null;
                invitationCart.preallocated_number = null;
                invitationCart.cli_override = f.value.allocation.cli_override;
                break;
            default:
                return this.openSnackBar('There was a problem with the form, it was not submitted', 'Dismiss');
                break;
        }

        invitationCart.cli_override = f.value.allocation.cli_override ? f.value.allocation.cli_override : null;
        invitationCart.organisation_id = f.value.organisation_id;
        invitationCart.license_id = f.value.selected_license;
        // invitationCart.invited_by_user_id = f.value.invited_by_user_id;


        console.log("user invite form final", invitationCart);

        this.invitationService.postNewInvite(invitationCart).subscribe((resp) => {
            console.log("resp", resp);
            if (resp.status === 200 || resp.status === 201) {
                this.closeModal(true);
                this.openSnackBar("your invitation has been sent", "dismiss");
                this.submitted = false;
            } else {
                throwError(resp);
            }
        }, (err) => {
            this.submitted = false;
            console.log(err);
        });

    }

    openPricingModal() {
        const dialogRef = this.dialog.open(PricingModalComponent, {
            panelClass: 'pipcall-modal-container',
        });
    }

    openTermsConditionsModal() {
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            panelClass: 'pipcall-modal-container',
        });
    }

    scrollEvent(event) {
        const el: HTMLElement = event.target;
        el.scrollTop > 0 && el.scrollHeight - el.scrollTop !== el.clientHeight ? el.classList.add('pip-scroll-shadow-after') : el.classList.remove('pip-scroll-shadow-after'); ;
    }

    ngOnDestroy() {
        this.numberSubscription?.unsubscribe();
    }

    debugit(action: string ) {
        switch (action) {
            case 'active':
                this.organisationDetails.state = 'Active';
                break;
            case 'trial':
                this.organisationDetails.state = 'Trial';
                break;
            case 'pipxt':
                this.organisationDetails.is_pipxt_enabled = true;
                this.organisationDetails.is_pipmobile_enabled = false;
                break;
            case 'pipmobile':
                this.organisationDetails.is_pipxt_enabled = false;
                this.organisationDetails.is_pipmobile_enabled = true;
                break;
            case 'both':
                this.organisationDetails.is_pipxt_enabled = true;
                this.organisationDetails.is_pipmobile_enabled = true;
                break;
            case 'console':
                console.log(this.inviteUserForm);
                console.log(this.inviteUserForm.controls.allocation);
                break;
        }
        console.log(this.organisationDetails);
    }

    debug() {
        console.log(this.inviteUserForm);
    }
}
