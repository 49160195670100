<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <span class="subtitle-12">{{invoiceItem.organisation_name}}</span>
    <span style="color:var(--c-title);font-size: 22px;">Reason for deletion</span>
  </div>

  <div class="pip-modal-body" class="pip-modal-body pip-scroll-shadow">
    <div style="margin-top: 20px;" class="item-detail-label clickable" (click)="copyInputToClipboard(invoiceNotesForm.value.invoice_id)">{{invoiceNotesForm.value.invoice_id}}</div>
    <div >
      This invoice has not yet been paid and will be permanently deleted. No money will be collected.
    </div>
    <div style="margin-top: 20px;">


      <form [formGroup]="invoiceNotesForm" fxLayout.lt-md="column" fxLayout="row" fxFlex="100%">

       <mat-form-field  appearance="outline" floatLabel="always" style="width:100%;margin-bottom: 10px;"
        [ngClass]="{'readonly-block': pageStatus.isSubmitting}" *ngIf="viewAs === 'sysAdmin'">
        <mat-label>Reason</mat-label>
        <textarea formControlName="notes"  style="min-height: 100px;"  matInput
          placeholder=""></textarea>
       </mat-form-field> 

      </form>

    </div>

  </div>

  <div class="pip-modal-footer">
    <div class="text-center" [style.color]="'grey'" [style.display]="'inline-block'" *ngIf="showSavedMessage" [@fadeInOnEnter]
    [@bounceInUpOnEnter]="showSavedMessage" [@fadeOutOnLeave]="!showSavedMessage">
    saved!
  </div>
    <button mat-raised-button class="delete-button" [disabled]="!invoiceNotesForm.valid"    (click)="deleteInvoice(invoiceNotesForm)">   <ng-container *ngIf="!pageStatus.isSubmitting;else loadingSpinner">Delete</ng-container></button>
  </div>
</div>



<ng-template #loadingSpinner class="text-center">
  <div class="loader-spinner"></div>
</ng-template>
