import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';

//component
import { ExtensionsConfigPanelComponent } from './extension-config-panel-legacy.component'
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';


import { NumberService } from '@app/services/pipcall/number.service';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        UniversalComponentsModule
    ],
    declarations: [ExtensionsConfigPanelComponent],
    exports: [ExtensionsConfigPanelComponent],
    providers: [AppInsightService, NumberService, PhonenumberValidator],
    entryComponents: []
})
export class ExtensionsConfigPanelLegacyModule { }
