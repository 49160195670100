import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { cartProvision } from '@app/models/cart.model';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';

import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { map } from 'rxjs/operators';
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';

@Component({
    templateUrl: './verified-welcome.component.html',
    styleUrls: ['./verified-welcome.component.scss']
})
export class VerifiedWelcomeComponent implements OnInit {

    public id: string;
    public cart: cartProvision;
    public pipnumber: string;
    public businessnumber: string;
    public path: string; //cart invitation or cli
    public source: string;
    public loading = true; // start screen as loading.
    public success = false; //set to true if correct data returned.

    constructor(
        private activatedRoute: ActivatedRoute,
        private appTitleService: AppTitleService,
        private appInsightService: AppInsightService,
        private auth: AuthService,
        private router: Router) {
        this.appTitleService.setTitle('PiPcall: Verified');
    }


    ngOnInit() {
        this.appInsightService.logPageView('Signup: verified');

        this.activatedRoute.queryParams.subscribe(data => {
            this.source = data['source'];
            this.pipnumber = data['pip'];
            this.businessnumber = data['bn'];
            this.setResultScreen(true);
        }, (err) => {
            this.setResultScreen(false);
        })


    }

    setResultScreen(bool: boolean): void {
    //true returned all good
        if (bool) {
            this.loading = false;
            this.success = true;
        } else {
            //false. returned unsuccessfull
            this.loading = false;
            this.success = false;
        }
    }


    continueToPortal(): void {
    //set local storage item
        sessionStorage.setItem('redirect_url', '/redirect');

        this.router.navigateByUrl('/');
    // this.auth.login(); //deprecated
    }

}
